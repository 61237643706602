"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var td_constants_1 = require("../data-layer/shared/models/td.constants");
var i0 = require("@angular/core");
var UtilityService = /** @class */ (function () {
    function UtilityService(locale) {
        this.locale = locale;
        this.getTextWidth = function (text, font, minWidth, maxWidth, extraWidth) {
            // if given, use cached canvas for better performance
            // else, create new canvas
            var canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement('canvas'));
            var context = canvas.getContext('2d');
            context.font = font;
            var metrics = context.measureText(text);
            var calcWidth = metrics.width + extraWidth;
            if (calcWidth < minWidth) {
                calcWidth = minWidth;
            }
            else if (calcWidth > maxWidth) {
                calcWidth = maxWidth;
            }
            return calcWidth;
        };
        this.datePipe = new common_1.DatePipe(this.locale);
    }
    // IMPORTANT: You need initialize properties in child classes, to generate constructor code
    UtilityService.prototype.fillFromJSON = function (obj, jsonObj) {
        // get all property names from current object
        var ownPropNames = Object.getOwnPropertyNames(obj).toString();
        var getOwnPropName = function (propName) {
            var match = new RegExp('\\b' + propName + '\\b', 'i').exec(ownPropNames);
            return match && match.length > 0 ? match[0] : '';
        };
        for (var jsonPropName in jsonObj) {
            if (jsonObj.hasOwnProperty(jsonPropName)) {
                var propName = getOwnPropName(jsonPropName);
                if (propName !== '') {
                    obj[propName] = jsonObj[jsonPropName];
                }
            }
        }
    };
    UtilityService.prototype.tryParseInt = function (data, defaultValue) {
        var returnValue = defaultValue;
        if (data) {
            if (data.length > 0) {
                if (!isNaN(data)) {
                    returnValue = parseInt(data, 10);
                }
            }
        }
        return returnValue;
    };
    UtilityService.prototype.objIsEmpty = function (obj) {
        return (!obj) || (Object.keys(obj).length === 0); // returns 0 if empty or an integer > 0 if non-empty
    };
    UtilityService.prototype.valueIsEmpty = function (value) {
        return (value === undefined) || (value === null) || (value === '');
    };
    UtilityService.prototype.getFormatedDateTime = function (date) {
        if (date) {
            return this.datePipe.transform(date, 'short');
        }
        else {
            return '';
        }
    };
    UtilityService.prototype.convertValueToDate = function (value) {
        return new Date(value);
    };
    UtilityService.prototype.addElClass = function (elementId, className) {
        var element = document.getElementById(elementId);
        if (element) {
            element.classList.add(className);
        }
    };
    UtilityService.prototype.removeElClass = function (elementId, className) {
        var element = document.getElementById(elementId);
        if (element) {
            element.classList.remove(className);
        }
    };
    UtilityService.prototype.getElOuterHeight = function (elementId) {
        if (document.getElementById(elementId)) {
            return document.getElementById(elementId).offsetHeight;
        }
        else {
            return 0;
        }
    };
    UtilityService.prototype.getElementsHeightSum = function (elementIds) {
        var _this = this;
        return elementIds.map(function (value) { return _this.getElOuterHeight(value); }).reduce(function (heightSum, value) { return heightSum + value; }, 0);
    };
    UtilityService.prototype.setElOuterHeight = function (elementId, value) {
        if (document.getElementById(elementId)) {
            document.getElementById(elementId).style.height = value;
        }
    };
    UtilityService.prototype.setElOverflowX = function (value) {
        document.documentElement.style.overflowX = value;
    };
    UtilityService.prototype.setElOverflowY = function (value) {
        document.documentElement.style.overflowY = value;
    };
    UtilityService.prototype.copyPropertyValues = function (objFrom, objTo, forceAdd) {
        for (var _i = 0, _a = Object.entries(objFrom); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (objTo.hasOwnProperty(key) || forceAdd) {
                objTo[key] = value;
            }
        }
    };
    UtilityService.prototype.compareObjects = function (obj1, obj2) {
        for (var propName in obj1) {
            if ({}.hasOwnProperty.call(obj1, propName)) {
                if (obj1[propName] !== obj2[propName]) {
                    return false;
                }
            }
        }
        for (var propName in obj2) {
            if ({}.hasOwnProperty.call(obj2, propName)) {
                if (obj1[propName] !== obj2[propName]) {
                    return false;
                }
            }
        }
        return true;
    };
    UtilityService.prototype.applyUTCTimeZone = function (date) {
        if (date) {
            var nowUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
            return date = new Date(nowUTC);
        }
        else {
            return null;
        }
    };
    UtilityService.prototype.highlightContent = function (sourceText, highlightText) {
        if (sourceText && highlightText && (highlightText.length > 0)) {
            var highlightWords = highlightText.split(" ").filter(function (word) { return word !== ""; });
            var index_1 = 0;
            var highlightStrings_1 = highlightWords
                .map(function (word) {
                var highlightString = [sourceText.toLowerCase().indexOf(word.toLowerCase(), index_1), word.length];
                index_1 = index_1 + word.length;
                return highlightString;
            })
                .filter(function (word) { return word[0] >= 0; })
                .sort(function (word) { return word[0]; });
            if (!highlightStrings_1.length) {
                return sourceText;
            }
            else {
                // TODO: investigate how to apply "class" in "innerHTML"
                var span_1 = '<b>'; //"<span class='highlight-search-text'>";
                var highlights_1 = [];
                highlightStrings_1.forEach(function (str, index) {
                    if (index === 0) {
                        highlights_1.push(sourceText.slice(0, str[0]));
                    }
                    highlights_1.push(span_1);
                    highlights_1.push(sourceText.slice(str[0], str[0] + str[1]));
                    highlights_1.push('</b>' /*'</span>'*/);
                    if (index === highlightStrings_1.length - 1) {
                        highlights_1.push(sourceText.slice(str[0] + str[1]));
                    }
                    else {
                        highlights_1.push(sourceText.slice(str[0] + str[1], highlightStrings_1[index + 1][0]));
                    }
                });
                return highlights_1.join('');
            }
        }
        else {
            return sourceText;
        }
    };
    UtilityService.prototype.extractQueryParamValueFromString = function (string, queryParam) {
        var result = null;
        if (string.includes('=') && string.includes(queryParam)) {
            var queryParamString = string.split('?')[1];
            var queryParams = queryParamString.split('&');
            var targetParam = queryParams.find(function (entry) { return entry.startsWith(queryParam); });
            result = targetParam.split('=')[1];
        }
        return result;
    };
    UtilityService.prototype.dateRangeAllowed = function (date) {
        return date >= td_constants_1.TD_MIN_DATE_VALUE;
    };
    UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(i0.ɵɵinject(i0.LOCALE_ID)); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
exports.UtilityService = UtilityService;
// to guarantee type safety of "stringly typed" property names in code
exports.nameOf = function () { return function (name) { return name; }; };
