"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./td-toolbar-button.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i3 = require("devextreme-angular/core");
var i4 = require("devextreme-angular/ui/button");
var i5 = require("@angular/platform-browser");
var i6 = require("./td-toolbar-button.component");
var styles_TdToolbarButtonComponent = [i0.styles];
var RenderType_TdToolbarButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TdToolbarButtonComponent, data: {} });
exports.RenderType_TdToolbarButtonComponent = RenderType_TdToolbarButtonComponent;
function View_TdToolbarButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "dx-button", [["class", "toolbar-button tertiary-button toolbar-button-margin"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.btnClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DxButtonComponent_0, i2.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(4, 7323648, null, 0, i4.DxButtonComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { disabled: [0, "disabled"], focusStateEnabled: [1, "focusStateEnabled"], hint: [2, "hint"], icon: [3, "icon"], text: [4, "text"], visible: [5, "visible"] }, { onClick: "onClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonState.disabled; var currVal_1 = false; var currVal_2 = _co.hint; var currVal_3 = _co.icon; var currVal_4 = _co.text; var currVal_5 = _co.buttonState.visible; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
exports.View_TdToolbarButtonComponent_0 = View_TdToolbarButtonComponent_0;
function View_TdToolbarButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-toolbar-button", [], null, null, null, View_TdToolbarButtonComponent_0, RenderType_TdToolbarButtonComponent)), i1.ɵdid(1, 49152, null, 0, i6.TdToolbarButtonComponent, [], null, null)], null, null); }
exports.View_TdToolbarButtonComponent_Host_0 = View_TdToolbarButtonComponent_Host_0;
var TdToolbarButtonComponentNgFactory = i1.ɵccf("td-toolbar-button", i6.TdToolbarButtonComponent, View_TdToolbarButtonComponent_Host_0, { text: "text", icon: "icon", hint: "hint", buttonState: "buttonState" }, { btnClick: "btnClick" }, []);
exports.TdToolbarButtonComponentNgFactory = TdToolbarButtonComponentNgFactory;
