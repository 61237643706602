export enum ReservationStateImgPaths {
  './assets/img/order/booking-status/undetermined.png' = 0,
  './assets/img/order/booking-status/okOriginal.png' = 1,
  './assets/img/order/booking-status/okIdentical.png' = 2,
  './assets/img/order/booking-status/okAlternative.png' = 3,
  './assets/img/order/booking-status/okIdenticalForAlternative.png' = 4,
  './assets/img/order/booking-status/warningOriginal.png' = 5,
  './assets/img/order/booking-status/warningIdentical.png' = 6,
  './assets/img/order/booking-status/warningAlternative.png' = 7,
  './assets/img/order/booking-status/warningIdenticalForAlternative.png' = 8,
  './assets/img/order/booking-status/Error.svg' = 9
}

export enum ReservationStateGridImgPaths {
  './assets/img/order/booking-status/undetermined.png' = 0,
  './assets/img/order/booking-status/16x16/okOriginal.png' = 1,
  './assets/img/order/booking-status/16x16/okIdentical.png' = 2,
  './assets/img/order/booking-status/16x16/okAlternative.png' = 3,
  './assets/img/order/booking-status/16x16/okIdenticalForAlternative.png' = 4,
  './assets/img/order/booking-status/16x16/warningOriginal.png' = 5,
  './assets/img/order/booking-status/16x16/warningIdentical.png' = 6,
  './assets/img/order/booking-status/16x16/warningAlternative.png' = 7,
  './assets/img/order/booking-status/16x16/warningIdenticalForAlternative.png' = 8,
  './assets/img/order/booking-status/Error.svg' = 9
}

export enum OperationOrderStateImgPaths {
  './assets/img/Warning_Orange.svg' = 0,
  './assets/img/Error.svg' = 1,
  '' = 2,
  './assets/img/Approved.svg' = 3
}
