"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FactoryActionTypes;
(function (FactoryActionTypes) {
    FactoryActionTypes["LoadAllFactories"] = "[Factory] Load All Factories";
    FactoryActionTypes["SetAllFactories"] = "[Factory] Set All Factories";
    FactoryActionTypes["LoadUserFactories"] = "[Factory] Load User Factories";
    FactoryActionTypes["SetUserFactories"] = "[Factory] Set User Factories";
    FactoryActionTypes["LoadCustomerFactories"] = "[Factory] Load Customer Factories";
    FactoryActionTypes["SetCustomerFactories"] = "[Factory] Set Customer Factories";
    FactoryActionTypes["SetCustomerFactoriesFromAll"] = "[Factory] Set Customer Factories From All";
})(FactoryActionTypes = exports.FactoryActionTypes || (exports.FactoryActionTypes = {}));
var LoadAllFactories = /** @class */ (function () {
    function LoadAllFactories() {
        this.type = FactoryActionTypes.LoadAllFactories;
    }
    return LoadAllFactories;
}());
exports.LoadAllFactories = LoadAllFactories;
var SetAllFactories = /** @class */ (function () {
    function SetAllFactories(payload) {
        this.payload = payload;
        this.type = FactoryActionTypes.SetAllFactories;
    }
    return SetAllFactories;
}());
exports.SetAllFactories = SetAllFactories;
var LoadUserFactories = /** @class */ (function () {
    function LoadUserFactories() {
        this.type = FactoryActionTypes.LoadUserFactories;
    }
    return LoadUserFactories;
}());
exports.LoadUserFactories = LoadUserFactories;
var SetUserFactories = /** @class */ (function () {
    function SetUserFactories(payload) {
        this.payload = payload;
        this.type = FactoryActionTypes.SetUserFactories;
    }
    return SetUserFactories;
}());
exports.SetUserFactories = SetUserFactories;
var LoadCustomerFactories = /** @class */ (function () {
    function LoadCustomerFactories(payload) {
        this.payload = payload;
        this.type = FactoryActionTypes.LoadCustomerFactories;
    }
    return LoadCustomerFactories;
}());
exports.LoadCustomerFactories = LoadCustomerFactories;
var SetCustomerFactories = /** @class */ (function () {
    function SetCustomerFactories(payload) {
        this.payload = payload;
        this.type = FactoryActionTypes.SetCustomerFactories;
    }
    return SetCustomerFactories;
}());
exports.SetCustomerFactories = SetCustomerFactories;
var SetCustomerFactoriesFromAll = /** @class */ (function () {
    function SetCustomerFactoriesFromAll() {
        this.type = FactoryActionTypes.SetCustomerFactoriesFromAll;
    }
    return SetCustomerFactoriesFromAll;
}());
exports.SetCustomerFactoriesFromAll = SetCustomerFactoriesFromAll;
