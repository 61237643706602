"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var server_session_actions_1 = require("@core/data-layer/server-session/store/server-session.actions");
var server_session_selectors_1 = require("@core/data-layer/server-session/store/server-session.selectors");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var LicenseInitializer = /** @class */ (function () {
    function LicenseInitializer(store) {
        this.store = store;
    }
    LicenseInitializer.prototype.initializeLicense = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.store.dispatch(new server_session_actions_1.LoadLicense());
            _this.licenseSubscription = _this.store.pipe(store_1.select(server_session_selectors_1.selectLicense)).subscribe(function (license) {
                if (license) {
                    if (_this.licenseSubscription) {
                        _this.licenseSubscription.unsubscribe();
                    }
                    resolve(license);
                }
            });
        });
    };
    LicenseInitializer.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LicenseInitializer_Factory() { return new LicenseInitializer(i0.ɵɵinject(i1.Store)); }, token: LicenseInitializer, providedIn: "root" });
    return LicenseInitializer;
}());
exports.LicenseInitializer = LicenseInitializer;
