"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var server_settings_component_1 = require("./components/server-settings/server-settings.component");
var server_session_actions_1 = require("@core/data-layer/server-session/store/server-session.actions");
var td_search_filters_component_1 = require("@shared/components/td-search-filters/td-search-filters.component");
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(store) {
        this.store = store;
        this.visibleChange = new core_1.EventEmitter();
        this.serverSettingsTemplate = 'serverSettings';
        this.searchFiltersTemplate = 'searchFilters';
        this.settingsTabs = [
            {
                title: 'settings.searchFilters',
                template: this.searchFiltersTemplate
            },
            {
                title: 'settings.serverSettings',
                template: this.serverSettingsTemplate
            }
        ];
    }
    SettingsComponent.prototype.apply = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.settingsChanged) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.serverSettings.saveServerOptions().then(function () { return _this.store.dispatch(new server_session_actions_1.LoadServerDesktopOptions()); })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.filtersChanged) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.searchFilters.saveSearchFilters()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.visible = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingsComponent.prototype.cancel = function () {
        this.visible = false;
    };
    SettingsComponent.prototype.resetToDefault = function (template) {
        if (template === this.searchFiltersTemplate) {
            this.searchFilters.resetToDefault();
        }
        else if (template === this.serverSettingsTemplate) {
            this.serverSettings.resetToDefault();
        }
    };
    return SettingsComponent;
}());
exports.SettingsComponent = SettingsComponent;
