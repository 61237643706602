"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var router_1 = require("@angular/router");
var environment_1 = require("src/environments/environment");
var logger_service_1 = require("@core/logger/logger.service");
var td_popup_service_1 = require("@shared/components/td-popup/services/td-popup.service");
var td_load_panel_service_1 = require("@shared/components/td-load-panel/services/td-load-panel.service");
var app_constants_1 = require("@shared/models/app.constants");
var error_report_manager_service_1 = require("@core/error-report/services/error-report-manager.service");
var log = new logger_service_1.Logger('ErrorHandlerInterceptor');
/**
 * Adds a default error handler to all requests.
 */
var ErrorHandlerInterceptor = /** @class */ (function () {
    function ErrorHandlerInterceptor(authService, errorReportManagerService, router, popupService, loadPanelService) {
        this.authService = authService;
        this.errorReportManagerService = errorReportManagerService;
        this.router = router;
        this.popupService = popupService;
        this.loadPanelService = loadPanelService;
    }
    ErrorHandlerInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        if (request.headers.get(app_constants_1.SKIP_HEADER) !== null) {
            var newHeaders = request.headers.delete(app_constants_1.SKIP_HEADER);
            var newRequest = request.clone({ headers: newHeaders });
            return next.handle(newRequest);
        }
        else {
            return next.handle(request).pipe(operators_1.catchError(function (error) { return _this.errorHandler(error, request.method, request.body); }));
        }
    };
    // Customize the default error handler here if needed
    ErrorHandlerInterceptor.prototype.errorHandler = function (response, method, body) {
        this.loadPanelService.hideLoadPanel();
        if (environment_1.environment.logHttpErrors) {
            this.errorReportManagerService.handleError(response.error, response.error ? ('Error code: ' + response.error.code + '; Error message: ' + response.error.message) : '', this.router.url, response.name, response.message, response.error ? response.error.traceId : '');
            log.error('Request error', response);
            if ((method === 'POST') && (body !== null)) {
                log.error('Error with params:', body);
            }
        }
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            this.authService.startSignout();
            location.reload(true);
        }
        else if (response.status === 400 && response.url.indexOf('mediaIdentifier=thumbnail') !== -1) {
            return rxjs_1.throwError(response);
        }
        else if ((response.status >= 400) && (response.status <= 500)) {
            this.popupService.showError('appMessages.technicalError', true, response.error ? response.error.traceId : '');
        }
        else if (response.status === 0) {
            // show error popup if there is no connection to the backend
            this.popupService.showError('appMessages.communicationError', true, response.error ? response.error.traceId : '');
        }
        return rxjs_1.throwError(response);
    };
    return ErrorHandlerInterceptor;
}());
exports.ErrorHandlerInterceptor = ErrorHandlerInterceptor;
