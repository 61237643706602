<dx-button
  (onClick)="btnClick.emit()"
  class="toolbar-button tertiary-button toolbar-button-margin"
  [focusStateEnabled]="false"
  [text]="text"
  [icon]="icon"
  [hint]="hint"
  [disabled]="buttonState.disabled"
  [visible]="buttonState.visible">
</dx-button>
