import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { UserModel } from '@core/data-layer/user/models/user.model';
import { UserSessionServiceInterface } from './user-session.service.interface';
import { ProfileDataModel } from '../models/profile-data.model';
import { UserDesktopOptionsModel } from '../models/user-desktop-options.model';

@Injectable({
  providedIn: 'root'
})
export class HttpUserSessionService implements UserSessionServiceInterface {

  constructor(
    private http: HttpClient
  ) { }

  profileDataUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.userProfileDataCtrl;
  }

  desktopDataUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.desktopDataCtrl;
  }

  getAll(): Observable<UserModel[]> {
    // todo: call real backend API
    return this.http.get<UserModel[]>(`/users`);
  }

  getById(id: number): Observable<UserModel> {
    // todo: call real backend API
    return this.http.get<UserModel>(`/users/${id}`);
  }

  getProfileData(): Observable<ProfileDataModel> {
    return this.http.get<ProfileDataModel>(`${this.profileDataUrl()}`);
  }

  getUserOptions(): Observable<UserDesktopOptionsModel> {
    return this.http.get<UserDesktopOptionsModel>(`${this.desktopDataUrl()}${API_HUB_PATHS.getUserOptions}`);
  }

  saveUserOptions(userDesktopOptions: UserDesktopOptionsModel): Observable<null> {
    return this.http.post<null>(`${this.desktopDataUrl()}${API_HUB_PATHS.saveUserOptions}`, userDesktopOptions);
  }

  getDefaultUserOptions(): Observable<UserDesktopOptionsModel> {
    return this.http.get<UserDesktopOptionsModel>(`${this.desktopDataUrl()}${API_HUB_PATHS.getDefaultUserOptions}`);
  }

  saveDefaultUserOptions(userDesktopOptions: UserDesktopOptionsModel): Observable<null> {
    return this.http.post<null>(`${this.desktopDataUrl()}${API_HUB_PATHS.saveDefaultUserOptions}`, userDesktopOptions);
  }

}
