"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var production_overview_constants_1 = require("../models/production-overview.constants");
var i0 = require("@angular/core");
var InfoOverviewHelperService = /** @class */ (function () {
    function InfoOverviewHelperService() {
    }
    InfoOverviewHelperService.prototype.adjustPOData = function (poData) {
        if (poData) {
            if (poData.userList) {
                // to avoid caching of user's pictures we are forcing every request for the image to use a different URL
                for (var _i = 0, _a = poData.userList; _i < _a.length; _i++) {
                    var user = _a[_i];
                    if (user.userPicturePath) {
                        user.userPicturePath = user.userPicturePath + '?' + (new Date()).getTime();
                    }
                }
            }
            // split machines into two arrays: "Sterilizers", "Washers", "Endoscopes" and "Dryers"
            if (poData.machineList) {
                for (var _b = 0, _c = poData.machineList; _b < _c.length; _b++) {
                    var machine = _c[_b];
                    // to avoid caching of machine's pictures we are forcing every request for the image to use a different URL
                    if (machine.statusImagePath) {
                        machine.statusImagePath = machine.statusImagePath + '?' + (new Date()).getTime();
                    }
                    if (machine.machType === production_overview_constants_1.POConst.MachineType.STERILIZER) {
                        poData.sterilizersList.push(machine);
                    }
                    else if (machine.machType === production_overview_constants_1.POConst.MachineType.WASHER) {
                        poData.washersList.push(machine);
                    }
                    else if (machine.machType === production_overview_constants_1.POConst.MachineType.ENDO) {
                        poData.endoscopesList.push(machine);
                    }
                    else if (machine.machType === production_overview_constants_1.POConst.MachineType.DRYER) {
                        poData.dryersList.push(machine);
                    }
                }
            }
            return poData;
        }
        else {
            return null;
        }
    };
    InfoOverviewHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InfoOverviewHelperService_Factory() { return new InfoOverviewHelperService(); }, token: InfoOverviewHelperService, providedIn: "root" });
    return InfoOverviewHelperService;
}());
exports.InfoOverviewHelperService = InfoOverviewHelperService;
