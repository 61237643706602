"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var customer_selectors_1 = require("@core/data-layer/customer/store/customer.selectors");
var custActions = require("@core/data-layer/customer/store/customer.actions");
var factActions = require("@core/data-layer/factory/store/factory.actions");
var userSessionActions = require("@core/data-layer/user-session/store/user-session.actions");
var td_load_panel_service_1 = require("@app/shared/components/td-load-panel/services/td-load-panel.service");
var info_overview_actions_1 = require("@app/dashboard/data-layer/info-overview/store/info-overview.actions");
var utility_service_1 = require("@core/utility/utility.service");
var app_constants_1 = require("@shared/models/app.constants");
var AuthCustomersComponent = /** @class */ (function () {
    function AuthCustomersComponent(authService, operationService, orderService, orderTemplateService, router, activatedRoute, store, loadPanelService, utilityService) {
        this.authService = authService;
        this.operationService = operationService;
        this.orderService = orderService;
        this.orderTemplateService = orderTemplateService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.store = store;
        this.loadPanelService = loadPanelService;
        this.utilityService = utilityService;
        this.subscriptions = [];
    }
    AuthCustomersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadPanelService.showLoadPanel('appMessages.acquiringCustomers');
        this.subscriptions.push(this.authService.authStatus$().subscribe(function (status) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!status) return [3 /*break*/, 3];
                        this.dispatchLoadCommandsToStore();
                        this.customers$ = this.store.select(customer_selectors_1.selectUserCustomers);
                        _a = this.determineCustomerSelectionFlow;
                        return [4 /*yield*/, this.getUserCustomers()];
                    case 1:
                        _b = [_c.sent()];
                        return [4 /*yield*/, this.getQueryParams()];
                    case 2:
                        _a.apply(this, _b.concat([_c.sent()]));
                        this.redirectToPreviousPage();
                        _c.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); }));
    };
    AuthCustomersComponent.prototype.ngOnDestroy = function () {
        this.loadPanelService.hideLoadPanel();
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    AuthCustomersComponent.prototype.onCustomerSelected = function (customer) {
        this.selectCustomerInStore(customer);
    };
    /** START SECTION: DISPATCHING STORE DATA **/
    AuthCustomersComponent.prototype.dispatchLoadCommandsToStore = function () {
        this.store.dispatch(new userSessionActions.LoadProfileData());
        this.store.dispatch(new userSessionActions.LoadUserOptions());
        this.store.dispatch(new custActions.LoadUserCustomers());
    };
    /** END SECTION: DISPATCHING STORE DATA **/
    /** START SECTION: COLLECT CUSTOMERS ASSIGNED TO LOGGED IN USER **/
    AuthCustomersComponent.prototype.getUserCustomers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.subscriptions.push(_this.customers$.subscribe(function (customerList) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (this.authService.isAuthenticated() && customerList) {
                                    resolve(customerList);
                                }
                                return [2 /*return*/];
                            });
                        }); }));
                    })];
            });
        });
    };
    /** END SECTION: COLLECT CUSTOMERS ASSIGNED TO LOGGED IN USER **/
    /** START SECTION: DEALING WITH QUERY PARAMS **/
    AuthCustomersComponent.prototype.getQueryParams = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.subscriptions.push(_this.activatedRoute.queryParams.subscribe(function (params) {
                            var paramsWithoutCancelProp = _this.removeCancelPropFromReturnUrlParam(params);
                            _this.updateUrlParams(paramsWithoutCancelProp);
                            resolve(paramsWithoutCancelProp);
                        }));
                    })];
            });
        });
    };
    AuthCustomersComponent.prototype.removeCancelPropFromReturnUrlParam = function (params) {
        if (params['returnUrl'] && params['returnUrl'].indexOf('?cancel=') !== -1) {
            params = { 'returnUrl': params['returnUrl'].split('?cancel=')[0] };
        }
        return params;
    };
    AuthCustomersComponent.prototype.updateUrlParams = function (newParams) {
        this.router.navigate([], {
            queryParams: newParams,
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
    };
    /** END SECTION: DEALING WITH QUERY PARAMS **/
    AuthCustomersComponent.prototype.determineCustomerSelectionFlow = function (customerList, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var customerSelectedFromReturnUrlParams, customerSelectedFromEntity;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        customerSelectedFromReturnUrlParams = this.selectCustomerFromReturnUrlParams(customerList, params);
                        if (!!customerSelectedFromReturnUrlParams) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.selectCustomerFromSpecificEntity(customerList, params)];
                    case 1:
                        customerSelectedFromEntity = _a.sent();
                        if (!customerSelectedFromEntity) {
                            this.selectCustomerBasedOnCustomerList(customerList);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    /** START SECTION: SELECT CUSTOMER BASED ON CUSTOMER ID IN RETURN URL **/
    AuthCustomersComponent.prototype.selectCustomerFromReturnUrlParams = function (customerList, params) {
        var customerId = this.getCustomerIdFromParams(params);
        return this.selectCustomerInCustomerList(customerList, customerId);
    };
    AuthCustomersComponent.prototype.getCustomerIdFromParams = function (params) {
        if (params['returnUrl']) {
            var targetValue = this.utilityService.extractQueryParamValueFromString(params['returnUrl'], 'customerId');
            return Number(targetValue);
        }
        return 0;
    };
    /** END SECTION: SELECT CUSTOMER BASED ON CUSTOMER ID IN RETURN URL **/
    /** START SECTION: SELECT CUSTOMER BASED ON ENTITY **/
    AuthCustomersComponent.prototype.selectCustomerFromSpecificEntity = function (customerList, params) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.getSpecificEntityCustomerId(params).subscribe(function (customerId) {
                var customerSelected = _this.selectCustomerInCustomerList(customerList, customerId);
                resolve(customerSelected);
            });
        });
    };
    AuthCustomersComponent.prototype.getSpecificEntityCustomerId = function (params) {
        var splitParams = params['returnUrl'].split('/');
        var routePath = splitParams[0];
        var entityId = splitParams[1];
        return (routePath && routePath.length > 0) && (entityId && entityId.length > 0) ? this.getEntityRequest(routePath, Number(entityId)) : rxjs_1.of(null);
    };
    AuthCustomersComponent.prototype.getEntityRequest = function (route, entityId) {
        switch (route) {
            case app_constants_1.ROUTE_PATHS.operations: return this.operationService.getOperationCustomer(entityId);
            case app_constants_1.ROUTE_PATHS.orders: return this.orderService.getOrderCustomer(entityId);
            case app_constants_1.ROUTE_PATHS.orderTemplates: return this.orderTemplateService.getOrderTemplateCustomer(entityId);
        }
    };
    /** END SECTION: SELECT CUSTOMER BASED ON ENTITY **/
    /** START SECTION: SELECT CUSTOMER BASED ON CUSTOMER LIST **/
    AuthCustomersComponent.prototype.selectCustomerBasedOnCustomerList = function (customerList) {
        // no customers linked to current user - execute logout method
        if (customerList.length === 0) {
            this.errorNavigateTo('/', td_constants_1.TD_AUTH_ERRORS.noCust);
        }
        // there is only one customer, that can be selected for current user - select this customer and redirect to "search" page
        else if (customerList.length === 1) {
            this.selectCustomerInStore(customerList[0]);
        }
        // there are more then one customers linked to current user - show list of custommers
        else {
            this.loadPanelService.hideLoadPanel();
        }
    };
    AuthCustomersComponent.prototype.errorNavigateTo = function (url, errorCode) {
        this.router.navigate([url], {
            queryParams: {
                error: errorCode
            },
            queryParamsHandling: 'merge'
        });
    };
    /** END SECTION: SELECT CUSTOMER BASED ON CUSTOMER LIST **/
    /** START SECTION: FUNCTIONS SHARED BETWEEN ALL 3 CUSTOMER SELECTION APPROACHES **/
    AuthCustomersComponent.prototype.selectCustomerInCustomerList = function (customerList, desiredCustomerId) {
        var desiredCustomer = customerList.find(function (entry) { return entry.keyId === desiredCustomerId; });
        if (desiredCustomer) {
            this.selectCustomerInStore(desiredCustomer);
        }
        return desiredCustomer !== undefined;
    };
    AuthCustomersComponent.prototype.selectCustomerInStore = function (cust) {
        this.store.dispatch(new factActions.LoadCustomerFactories(cust.keyId));
        this.store.dispatch(new factActions.LoadUserFactories());
        this.store.dispatch(new custActions.SetCurrentCustomer(cust));
        if (cust.cuSiteKeyId) {
            this.store.dispatch(new custActions.LoadSiteCustomers(cust.cuSiteKeyId));
        }
        this.store.dispatch(new info_overview_actions_1.LoadIOConfigurations());
    };
    AuthCustomersComponent.prototype.redirectToPreviousPage = function () {
        var _this = this;
        this.subscriptions.push(this.store.select(customer_selectors_1.selectCurrentCustomer).subscribe(function (cust) {
            if (cust !== null) {
                _this.router.navigateByUrl(_this.activatedRoute.snapshot.queryParams[td_constants_1.TD_QUERY_PARAMS.returnUrl]);
            }
        }));
    };
    return AuthCustomersComponent;
}());
exports.AuthCustomersComponent = AuthCustomersComponent;
