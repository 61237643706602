<div class="row mt-3">
  <div class="col d-flex justify-content-end confirm-button-panel">
    <div *ngIf="resetButtonVisible" class="reset-button-container mr-5">
      <dx-button
        #buttonReset        
        id="resetButton"
        class="td-button tertiary-button ml-0 mr-3"
        (mouseup)="buttonReset.element.nativeElement.blur()"
        (onClick)="reset()">
        {{ resetButtonTranslateString | translate }}
      </dx-button>
    </div>
    <div class="popup-action-buttons">
      <dx-button
        id="confirmButton"
        class="td-button success-button ml-0 mr-3"
        [disabled]="disableSaveButton"
        (onClick)="confirmChanges()">
        {{ confirmButtonTranslateString | translate}}
      </dx-button>
      <dx-button
        id="cancelButton"
        class="td-button secondary-button ml-0 mr-0"
        (onClick)="cancelChanges()">
        {{ cancelButtonTranslateString | translate }}
      </dx-button>
    </div>
  </div>
</div>
