"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var app_constants_1 = require("@shared/models/app.constants");
var language_service_1 = require("@core/language/services/language.service");
var i0 = require("@angular/core");
var i1 = require("../../../language/services/language.service");
var OperationDataHelperService = /** @class */ (function () {
    function OperationDataHelperService(languageService) {
        this.languageService = languageService;
    }
    /**
     * @description converts [OperationDataModel][Object] to [OperationDataViewModel][Object]
     * @param operation: [OperationDataModel][Object]
     * @returns [OperationDataViewModel][Object]
     */
    OperationDataHelperService.prototype.convertOperationDataToViewModel = function (operation) {
        return tslib_1.__assign({}, operation, { typeText: operation.opt ? operation.opt.code + " " + operation.opt.name : '', statusText: this.languageService.translateEnumValue(app_constants_1.OPERATION_STATUS, operation.status), roomText: operation.room ? operation.room.id + " - " + (operation.room.name || '') : '', patientText: operation.pat ? operation.pat.id + " - " + (operation.pat.name || '') : '', doctorText: operation.doct ? operation.doct.initials + " - " + operation.doct.name : '', startTime: operation.startTime ? new Date(operation.startTime) : null, endTime: operation.endTime ? new Date(operation.endTime) : null, actualStartTime: operation.actualStartTime ? new Date(operation.actualStartTime) : null, actualEndTime: operation.actualEndTime ? new Date(operation.actualEndTime) : null, bookingStatusText: this.translateBookingStatusEnum(operation), bookingModeText: this.translateBookingModeEnum(operation) });
    };
    OperationDataHelperService.prototype.translateBookingStatusEnum = function (operation) {
        var bookingStatus;
        if (this.isBookingUndetermined(operation)) {
            bookingStatus = td_enumerations_1.OrderBookingStatus.Undetermined;
        }
        else {
            bookingStatus = +operation.bookingStatus;
        }
        return this.languageService.translateEnumValue(app_constants_1.ORDER_BOOKING_STATUS, bookingStatus);
    };
    OperationDataHelperService.prototype.translateBookingModeEnum = function (operation) {
        var bookingMode;
        if (this.isBookingUndetermined(operation)) {
            bookingMode = td_enumerations_1.OrderBookingMode.Undetermined;
        }
        else {
            if ((operation.status === td_enumerations_1.OperationStatus.Accepted) && this.doesCustomerAllowBooking(operation.cust)) {
                if (this.canActivateOperationBooking(operation.refOpDOrderList)) {
                    bookingMode = td_enumerations_1.OrderBookingMode.Inactive;
                }
                else {
                    bookingMode = td_enumerations_1.OrderBookingMode.Active;
                }
            }
            else {
                bookingMode = td_enumerations_1.OrderBookingMode.Undetermined;
            }
        }
        return this.languageService.translateEnumValue(app_constants_1.ORDER_BOOKING_MODE, bookingMode);
    };
    OperationDataHelperService.prototype.isBookingUndetermined = function (operation) {
        return ([td_enumerations_1.OperationStatus.Started, td_enumerations_1.OperationStatus.Done, td_enumerations_1.OperationStatus.Cancelled].includes(operation.status) ||
            (operation.prefListOrderMode !== td_enumerations_1.PrefListOrderCreateMode.Immediate));
    };
    OperationDataHelperService.prototype.doesCustomerAllowBooking = function (customer) {
        return (!!customer) && customer.traceability &&
            [td_enumerations_1.CustOrderBookingMode.Default, td_enumerations_1.CustOrderBookingMode.Manual, td_enumerations_1.CustOrderBookingMode.Automation].includes(+customer.orderBookingMode);
    };
    OperationDataHelperService.prototype.canActivateOperationBooking = function (orderList) {
        return orderList.some(function (order) {
            return (!!order.reqDelTime) &&
                (order.orgStatus <= td_enumerations_1.OrderOrgStatus.Sent) &&
                (order.bookingMode === td_enumerations_1.OrderBookingMode.Inactive);
        });
    };
    OperationDataHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperationDataHelperService_Factory() { return new OperationDataHelperService(i0.ɵɵinject(i1.LanguageService)); }, token: OperationDataHelperService, providedIn: "root" });
    return OperationDataHelperService;
}());
exports.OperationDataHelperService = OperationDataHelperService;
