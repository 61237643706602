import { Injectable } from '@angular/core';
import { UserManagerSettings } from 'oidc-client';

import { AuthConfigurationService } from '@core/authentication/services/auth-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigurationInitializer {

  constructor(
    private authConfigurationService: AuthConfigurationService
  ) { }

  initializeAuthOptions(): Promise<UserManagerSettings> {
    return this.authConfigurationService.getAuthOptions();
  }

}
