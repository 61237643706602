"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".landing-page-text[_ngcontent-%COMP%]{font-size:var(--font-small)}.landing-header[_ngcontent-%COMP%]{font-size:var(--font-large);font-weight:var(--font-weight-bold)}.landing-login-button[_ngcontent-%COMP%]     .dx-button-content img{margin-top:-1px}.landing-login-button[_ngcontent-%COMP%]     .dx-button-content span{margin-bottom:2px}"];
exports.styles = styles;
