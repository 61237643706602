import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import DataSource from 'devextreme/data/data_source';

import { TD_MAX_INT32_VALUE, TD_VALUES } from '@core/data-layer/shared/models/td.constants';
import { GridLoadOptionsInterface, GridLoadResultInterface } from '../models/grid-data-request.interface';
import { OrderByArgsModel, BaseGridArgsModel } from '@core/data-layer/shared/models/base-grid-args.model';
import { FuzzySearchSortOrder } from '@core/data-layer/shared/models/td.enumerations';
import { GridColumnConfigurationViewInterface } from '@core/table-info/models/grid-structure-view.interface';

@Injectable({
  providedIn: 'root'
})
export class GridDataHelperService {

  requestGridData(
    requestArgs: BaseGridArgsModel,
    getData: (requestArgs: any, keyId?: number) => Observable<any>,
    keyId?: number,
    afterLoaded?: (loadResult?: GridLoadResultInterface, columnsConfigurations?: GridColumnConfigurationViewInterface[]) => void,
    keyField?: any
  ): DataSource {
    return new DataSource({
      key: keyField,
      requireTotalCount: true,
      load: (loadOptions: GridLoadOptionsInterface) => {
        if (loadOptions.sort) {
          const orderByFields = new Array<OrderByArgsModel>();
          loadOptions.sort.forEach(sortFieldOptions => {
            orderByFields.push(new OrderByArgsModel(sortFieldOptions.selector, (sortFieldOptions.desc ? FuzzySearchSortOrder.Desc : FuzzySearchSortOrder.Asc)));
          });
          requestArgs.orderByFields = Object.assign([], orderByFields);
        }
        if (requestArgs.paginationArgs.pageRowCount !== TD_MAX_INT32_VALUE - 1) {
          if (loadOptions.take) {
            requestArgs.paginationArgs.pageRowCount = loadOptions.take;
          }
          else {
            requestArgs.paginationArgs.pageRowCount = TD_VALUES.detailsGridRowsPerPage;
          }
          requestArgs.paginationArgs.startingRow = loadOptions.skip;
        }
        return getData(requestArgs, keyId)
          .toPromise()
          .then(result => {
            const loadResult: GridLoadResultInterface = {
              data: this.getDataSet(result),
              totalCount: this.getTotalCount(result)
            };
            if (afterLoaded) {
              afterLoaded(loadResult, result.columnsConfigurations ? result.columnsConfigurations : undefined);
            }
            return loadResult;
          });
      },
      byKey: (dataRow) => {
        return of(dataRow);
      }
    });
  }

  getDataSet(result: any): Array<object> {
    if (result.value) {
      // For ApiDataResponse<SearchResultViewModel> result
      return result.value.dataSet.mappedRows;
    }
    else if (result.dataSet.mappedRows) {
      // For SearchResultViewModel and ExtendedSearchResultViewModel result
      return result.dataSet.mappedRows;
    }
    else {
      // For GridDataSetResponse<any> result
      return result.dataSet;
    }
  }

  getTotalCount(result: any): number {
    if (result.value) {
      // For ApiDataResponse<SearchResultViewModel> result
      return result.value.paginationArgs.totalCountOfRows;
    }
    else if (result.dataSet.mappedRows) {
      // For SearchResultViewModel and ExtendedSearchResultViewModel result
      return result.paginationArgs.totalCountOfRows;
    }
    else {
      // For GridDataSetResponse<any> result
      return result.totalCountOfRows;
    }
  }
}
