import { Injectable } from '@angular/core';

import { nameOf } from '@core/utility/utility.service';
import { OperationSearchType } from '@core/data-layer/shared/models/td.enumerations';
import { OperationDataModel } from '@core/data-layer/operation-data/models/operation-data.model';

@Injectable({
  providedIn: 'root'
})
export class OperationFilterService {

  getScheduledOperationListFilter(fromDate: Date, toDate: Date, searchType: OperationSearchType, customerKeyId: number): any[] {
    const nameOfOperation = nameOf<OperationDataModel>();
    let filter: any[] = [];
    if (searchType === OperationSearchType.Date) {
      if (fromDate) {
        filter = [[nameOfOperation('endTime'), '>=', fromDate.toISOString()]];
      }
      if (toDate) {
        if (filter.length > 0) {
          filter = [...filter, 'and'];
        }
        filter = [...filter, [nameOfOperation('startTime'), '<=', toDate.toISOString()]];
      }
    }
    else {
      // if (BarType === cOperation) {
      //  operationFilter = [nameOfOperation('keyId'), '=', searchText];
      //}
    }
    if (customerKeyId > 0) {
      if (filter.length > 0) {
        filter = [...filter, 'and'];
      }
      filter = [...filter, [nameOfOperation('custKeyId'), '=', customerKeyId]];
    }
    return filter;
  }

}
