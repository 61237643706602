import { Injectable, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { UserManager, User, Profile } from 'oidc-client';

import { AuthenticationServiceInterface } from './authentication.service.interface';
import { AuthConfigurationService } from './auth-configuration.service';
import { AppState } from '@core/data-layer/app.state';
import * as custActions from '@core/data-layer/customer/store/customer.actions';
import * as factActions from '@core/data-layer/factory/store/factory.actions';
import * as ordActions from '@core/data-layer/order/store/order.actions';
import * as userSessionActions from '@core/data-layer/user-session/store/user-session.actions';
import { selectCurrentCustomer } from '@core/data-layer/customer/store/customer.selectors';
import { selectReturnUrl } from '@core/data-layer/server-session/store/server-session.selectors';
import { LoadIOConfigurations } from '@app/dashboard/data-layer/info-overview/store/info-overview.actions';
import { SetLicense, SetSessionEnded, SetReturnUrl } from '@core/data-layer/server-session/store/server-session.actions';
import { TD_ERROR_TYPES, TD_AUTH_ERRORS, TD_QUERY_PARAMS, TD_SYSTEM_USER_INITIALS } from '@core/data-layer/shared/models/td.constants';
import { ErrorReportManagerService } from '@core/error-report/services/error-report-manager.service';
import { TdPopupService } from '@shared/components/td-popup/services/td-popup.service';
import { ApiDataResponse } from '@core/data-layer/shared/models/api-response.model';
import { TdPopupButtonType } from '@shared/components/td-popup/models/td-popup-button.interface';
import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { PasswordVerificationMethod } from '@core/data-layer/shared/models/td.enumerations';
import { PopupType } from '@shared/components/td-popup/models/td-popup.model';

@Injectable()
export class HttpAuthenticationService implements AuthenticationServiceInterface {

  private authStatusSource = new BehaviorSubject<boolean>(false);
  private authPopup = true;
  private signingOut: boolean;
  private manager: UserManager;
  private user: User | null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authConfig: AuthConfigurationService,
    private store: Store<AppState>,
    private errorReportManagerService: ErrorReportManagerService,
    private popupService: TdPopupService,
    private injector: Injector,
    private http: HttpClient,
    private translate: TranslateService
  ) {
  }

  private getUser() {
    this.manager.getUser().then(user => {
      this.user = user;
      if (this.user) {
        this.store.pipe(select(selectCurrentCustomer)).subscribe(cust => {
          if (cust) {
            this.authStatusSource.next(this.isAuthenticated());
          }
          else {
            this.navigateToAuthCustomers();
          }
        });
      }
      else {
        this.clearSigninInfo();
      }
    });
  }

  initUserManager(authPopup = true) {
    this.authPopup = authPopup;
    this.manager = new UserManager(this.authConfig.getUserManagerSettings());
    // we are subscribing for silent refresh of token and as a result - updating current user info
    this.manager.events.addUserLoaded(args => {
      this.getUser();
    });
    // in case user press F5 in browser, we should reload user info
    this.getUser();
  }

  startSignin(redirectUrl?: string) {
    redirectUrl = redirectUrl ? redirectUrl : this.router.url;
    this.store.dispatch(new SetReturnUrl(redirectUrl));
    if (this.authPopup) {
      this.manager.signinPopup()
        .then(user => {
          this.user = user;
          this.navigateToAuthCustomers();
        })
        .catch((reason) => {
          this.handleSignInError(reason);
          this.navigateToReturnUrl();
        });
    }
    else {
      this.manager.signinRedirect()
        .catch((reason) => {
          this.handleSignInError(reason);
          this.navigateToReturnUrl();
        });
    }
  }

  handleSignInError(error: Error) {
    // We can't intercept third party library http request, so we only can react on the error message returned from the UserManager
    const corsError = 'Network Error';
    const internalServerError = '500';
    if (error && (error.message.includes(corsError) || error.message.includes(internalServerError))) {
      this.errorReportManagerService.handleError(error, '', this.router.url, error.name, error.message);
      this.popupService.showError('appMessages.technicalErrorContactSupport', true);
    }
  }

  async completeSignin() {
    // check either error should be shown
    const error = this.activatedRoute.snapshot.queryParams[TD_QUERY_PARAMS.error];
    if (error) {
      if ((error === TD_AUTH_ERRORS.accessDenied) && (this.activatedRoute.snapshot.queryParams[TD_QUERY_PARAMS.state])) {
        if (this.authPopup) {
          window.close();
        }
        else {
          this.store.pipe(select(selectReturnUrl)).subscribe(url => {
            if (url.includes('?cancel=')) {
              const cancelledLoginUrl = url.split('?cancel=')[1];
              this.store.dispatch(new SetReturnUrl(cancelledLoginUrl));
            }
            this.navigateToReturnUrl();
          });
          
        }
      }
    }
    else {
      if (this.authPopup) {
        await this.manager.signinPopupCallback();
      }
      else {
        this.user = await this.manager.signinRedirectCallback();
        this.handlePasswordExpires();
      }
    }
  }

  startSignout(redirectToLogin = false) {
    this.signingOut = true;
    if (this.authPopup) {
      this.manager.signoutPopup()
        .then(() => {
          this.finalizeSignOut(false);
        })
        .catch(() => {
          this.finalizeSignOut();
        });
    }
    else {
      if (redirectToLogin) {
        this.manager.createSigninRequest()
          .then(signinRequest => {
            const umSettings = this.authConfig.getUserManagerSettings();
            this.manager.signoutRedirect({
              extraQueryParams: {
                returnUrl: signinRequest.url.replace(umSettings.authority, '')
              },
            });
          })
          .catch(() => {
            this.finalizeSignOut();
          });
      }
      else {
        this.manager.signoutRedirect()
          .catch(() => {
            this.finalizeSignOut();
          });
      }
    }
  }

  async completeSignout() {
    if (this.authPopup) {
      await this.manager.signoutPopupCallback();
    }
    else {
      await this.manager.signoutRedirectCallback();
      this.finalizeSignOut();
    }
  }

  finalizeSignOut(cleanUpOidc = true) {
    if (cleanUpOidc) {
      this.cleanUpOidcTokens();
    }
    this.clearSigninInfo();
    this.authStatusSource.next(this.isAuthenticated());
    this.signingOut = false;
    this.store.dispatch(new SetSessionEnded(true));
    this.store.dispatch(new SetLicense(null));
    this.router.navigate(['/error'], { queryParams: { type: TD_ERROR_TYPES.sessionEnded } });
  }

  clearSigninInfo() {
    this.user = null;
    this.store.dispatch(new custActions.SetCurrentCustomer(null));
    this.store.dispatch(new custActions.SetUserCustomers(null));
    this.store.dispatch(new factActions.SetCustomerFactoriesFromAll());
    this.store.dispatch(new userSessionActions.SetProfileData(null));
    this.store.dispatch(new userSessionActions.SetUserOptions(null));
    this.store.dispatch(new ordActions.SetCustomerOpenOrders(null));
    this.store.dispatch(new ordActions.SetUserLastOpenOrder(null));
    this.store.dispatch(new LoadIOConfigurations());
  }

  cleanUpOidcTokens() {
    // clear OIDC LocalStorage rubbish
    this.manager.settings.stateStore.getAllKeys().then(keys => {
      keys.forEach(key => {
        this.manager.settings.stateStore.remove(key);
      })
    });
    // clear OIDC SessionStorage rubbish
    this.manager.settings.userStore.getAllKeys().then(keys => {
      keys.forEach(key => {
        this.manager.settings.userStore.remove(key);
      })
    });
    //this.manager.clearStaleState();
  }

  navigateToReturnUrl() {
    this.cleanUpOidcTokens();
    this.store.pipe(select(selectReturnUrl)).subscribe(url => {
      this.navigateTo(url);
    });
  }

  navigateToAuthCustomers() {
    this.store.pipe(select(selectReturnUrl)).subscribe(url => {
      this.navigateTo('/auth-customers', url);
    });
  }

  navigateTo(url: string, returnUrl = '') {
    this.authStatusSource.next(this.isAuthenticated());
    if (returnUrl) {
      this.router.navigate([url], { queryParams: { returnUrl } });
    }
    else {
      this.router.navigateByUrl(url);
    }
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  authStatus$(): Observable<boolean> {
    return this.authStatusSource.asObservable();
  }

  getSigningOut(): boolean {
    return this.signingOut;
  }

  getClaims(): Profile {
    return !!this.user ? this.user.profile : null;
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  getUserName(): string {
    return !!this.user && !!this.user.profile ? this.user.profile.name : '';
  }

  getUserInitials(): string {
    return !!this.user && !!this.user.profile ? this.user.profile.initials : '';
  }

  getUserId(): string {
    return !!this.user && !!this.user.profile ? this.user.profile.user_id : '';
  }

  navigateToChangePassword() {
    this.router.navigate(['/changePassword'], { queryParams: { returnUrl: this.router.url } });
  }

  handlePasswordExpires(): void {
    const sessionHelperService = this.injector.get<SessionHelperService>(SessionHelperService);
    if ((sessionHelperService.getPassVerifMethod() === PasswordVerificationMethod.TDOC) &&
        (this.getUserInitials() !== TD_SYSTEM_USER_INITIALS))
    {
      this.http.get<ApiDataResponse<number>>(`${API_HUB_PATHS.apiUrl}${API_HUB_PATHS.passwordExpire}`).subscribe(
        response => {
          if (response !== null) {
            this.popupService.showCustomWithButtons(
              this.translate.instant('appMessages.passwordExpireWarning'),
              this.translate.instant('appMessages.passwordExpireInDays').replace('%d', response),
              PopupType.Information,
              [
                {
                  text: 'button.askMeLater',
                  type: TdPopupButtonType.Secondary
                },
                {
                  text: 'settings.changePassword',
                  type: TdPopupButtonType.Success,
                  callback: () => {
                    this.router.navigate(['/changePassword'], { queryParams: {returnUrl: this.router.url}});
                  }
                }
              ]
            ).then(() => {
              this.navigateToAuthCustomers();
            });
          }
          else {
            this.navigateToAuthCustomers();
          }
        }
      );
    }
  }
}
