"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var search_helper_service_1 = require("./search-helper.service");
var HttpSearchService = /** @class */ (function () {
    function HttpSearchService(http, searchHelperService) {
        this.http = http;
        this.searchHelperService = searchHelperService;
    }
    HttpSearchService.prototype.searchUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.searchCtrl;
    };
    HttpSearchService.prototype.search = function (searchArgs) {
        var _this = this;
        this.searchHelperService.applyColumnsConfigurations(searchArgs);
        return this.http.post(this.searchUrl() + api_hub_constants_1.API_HUB_PATHS.search, searchArgs)
            .pipe(operators_1.map(function (response) {
            var responseView = {
                error: response.error,
                hasError: response.hasError,
                message: response.message,
                value: _this.searchHelperService.convertSearchResultToViewModel(searchArgs, response.value)
            };
            return responseView;
        }));
    };
    return HttpSearchService;
}());
exports.HttpSearchService = HttpSearchService;
