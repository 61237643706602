<div class="row page-container">
  <div class="col-sm-12">

    <div class="row page-title">
      {{ 'appCommon.search' | translate }}
    </div>

    <div class="row page-details">
      <div class="col-sm-12" id="search-container">
        <div class="row">
          <label for="searchText">{{ 'search.searchFor' | translate }}</label>
        </div>
        <div class="row">
          <div>
            <div class="row input-with-button">
              <dx-text-box id="searchText" [(value)]="searchText" (keyup.enter)="setSearchParameters()" width="300"></dx-text-box>
              <div class="col">
                <dx-button (onClick)="setSearchParameters()"
                           class="action-icon-button tertiary-button"
                           icon="search">
                </dx-button>
              </div>
            </div>
          </div>
          <div class="pl-1" *ngIf="sessionHelperService.loggedIn()">
            <td-search-filters-button></td-search-filters-button>
          </div>
        </div>
        </div>
    </div>

    <div class="row page-main-block">
      <dx-tab-panel #tabPanel
                    id="searchTabPanel"
                    [dataSource]="searchTabs"
                    [visible]="tabPanelVisible"
                    [focusStateEnabled]="false"
                    (onTitleClick)="clickTabTitle()">

        <div *dxTemplate="let tab of 'title'" class="tab-title d-flex align-items-center justify-content-center">
          <img [src]="tab.iconPath" class="mr-1">
          <span [ngClass]="searchTabs.indexOf(tab) === tabPanel.selectedIndex ? 'active-tab' : 'inactive-tab'">
             {{tab.title}}{{(tab.data && (tab.data._totalCount >= 0)) ? ' (' + tab.data._totalCount + ')' : ''}}
          </span>
        </div>

        <div class="mt-1" *dxTemplate="let tab of 'products'">
          <td-data-grid #productsGrid
                        [gridAlias]="gridIdentifiers.productListGrid"
                        [data]="tab.data"
                        [mainTable]="modelNames.productModel"
                        [gridStructure]="productGridStructure"
                        [masterDetail]="masterDetailTemplate"
                        [enableMasterDetail]="true"
                        [remoteOperations]="true"
                        [highlightText]="searchText"
                        [columnChooser]="true"
                        (refreshGridData)="searchProducts()"
                        keyExpression="keyId"
                        (rowExpand)="searchSerials($event)"
                        [templates]="{
                        media: mediaTemplate,
                        product: productDetailsTemplate,
                        quantity: quantityTemplate,
                        add: addProductTemplate
                      }">
          </td-data-grid>
        </div>
        <div class="mt-1" *dxTemplate="let tab of 'items'">
          <td-data-grid #itemsGrid
                        [gridAlias]="gridIdentifiers.itemListGrid"
                        [data]="tab.data"
                        [mainTable]="modelNames.itemModel"
                        [gridStructure]="itemGridStructure"
                        [remoteOperations]="true"
                        [highlightText]="searchText"
                        [columnChooser]="true"
                        (refreshGridData)="searchItems()"
                        [templates]="{
                        media: mediaTemplate,
                        item: itemDetailsTemplate,
                        quantity: quantityTemplate,
                        add: addItemTemplate
                      }">
          </td-data-grid>
        </div>
        <div class="mt-1" *dxTemplate="let tab of 'endoscopes'">
          <td-data-grid #endoscopesGrid
                        [gridAlias]="gridIdentifiers.endoscopeListGrid"
                        [data]="tab.data"
                        [mainTable]="modelNames.productModel"
                        [gridStructure]="endoscopeGridStructure"
                        [masterDetail]="masterDetailTemplate"
                        [enableMasterDetail]="true"
                        [remoteOperations]="true"
                        [highlightText]="searchText"
                        [columnChooser]="true"
                        (refreshGridData)="searchEndoscopes()"
                        keyExpression="keyId"
                        (rowExpand)="searchSerials($event)"
                        [templates]="{
                        media: mediaTemplate,
                        product: productDetailsTemplate,
                        quantity: quantityTemplate,
                        add: addProductTemplate
                      }">
          </td-data-grid>
        </div>
        <div class="mt-1" *dxTemplate="let tab of 'units'">
          <td-data-grid #unitsGrid
                        [gridAlias]="gridIdentifiers.unitListGrid"
                        [data]="tab.data"
                        [mainTable]="modelNames.unitModel"
                        [gridStructure]="unitGridStructure"
                        [remoteOperations]="true"
                        [highlightText]="searchText"
                        [columnChooser]="true"
                        (refreshGridData)="searchUnits()"
                        [templates]="{
                        media: mediaTemplate,
                        keyId: unitDetailsTemplate,
                        fastTrack: fastTrackTemplate
                      }">
          </td-data-grid>
        </div>
      </dx-tab-panel>
    </div>
  </div>
</div>

<ng-template #fastTrackTemplate let-item="item">
  <div class="cell-center" *ngIf="item.data[dynamicFields.unitIsFastTracked]">
    <dx-button class="grid-action-button"
               (onClick)="showFastTrackInfo(item.data.keyId)"
               hint="{{ 'calcColumns.fastTrack' | translate }}"
               icon=".\assets\img\fastTrackActive.png">
    </dx-button>
  </div>
</ng-template>

<ng-template #mediaTemplate let-item="item">
  <ng-container *ngIf="tabPanel && (item.data[dynamicFields.pictureKeyId] || item.data[dynamicFields.autoPictureKeyId])">
    <td-grid-media-wrapper
      [picsKeyId]="item.data[dynamicFields.pictureKeyId] || item.data[dynamicFields.autoPictureKeyId]"
      [keyId]="item.data.keyId"
      [mediaIndex]="item.rowIndex"
      [mediaItem]="item.data"
      [tableType]="activeTableType"
      [mediaSeries]="item.data.ProdKeepSeri"
      [tabIndex]="tabPanel.selectedIndex">
    </td-grid-media-wrapper>
  </ng-container>
</ng-template>

<ng-template #quantityTemplate let-item="item">
  <dx-number-box class="grid-number-box"
                 height="26"
                 [showSpinButtons]="true"
                 [min]="1"
                 [max]="maxQuantityValue"
                 (onValueChanged)="checkQuantity($event, item)"
                 [value]="item.data.quantity"
                 [disabled]="!sessionHelperService.userLastOpenOrder">
  </dx-number-box>
</ng-template>

<ng-template #addProductTemplate let-item="item">
  <dx-button class="grid-action-button" (onClick)="addToCart(tableTypes.Product, item.data.keyId, item)"
             hint="{{ 'search.addToCart' | translate }}" icon=".\assets\img\addToShoppingCart.png" [disabled]="!sessionHelperService.userLastOpenOrder">
  </dx-button>
</ng-template>

<ng-template #addItemTemplate let-item="item">
  <dx-button class="grid-action-button" (onClick)="addToCart(tableTypes.Item, item.data.keyId, item)"
             hint="{{ 'search.addToCart' | translate }}" icon=".\assets\img\addToShoppingCart.png" [disabled]="!sessionHelperService.userLastOpenOrder">
  </dx-button>
</ng-template>

<ng-template #productDetailsTemplate let-item="item">
  <a [routerLink]="['/products', item.data.keyId]" [state]="{ picsKeyId: item.data[dynamicFields.pictureKeyId] }"><span [innerHTML]="highlightSearchText(item.data.product)"></span></a>
</ng-template>

<ng-template #itemDetailsTemplate let-item="item">
  <a [routerLink]="['/items', item.data.keyId]" [state]="{ picsKeyId: item.data[dynamicFields.pictureKeyId] }"><span [innerHTML]="highlightSearchText(item.data.item)"></span></a>
</ng-template>

<ng-template #unitDetailsTemplate let-item="item">
  <a [routerLink]="['/units', item.data.keyId]" [state]="{ picsKeyId: item.data[dynamicFields.pictureKeyId] }"><span [innerHTML]="highlightSearchText(item.data.keyId.toString())"></span></a>
</ng-template>

<ng-template #masterDetailTemplate let-item="item">
  <ng-container>
    <div class="serialNumberHeader">
      {{ 'appCommon.serialNumber' | translate }}
    </div>
    <div class="serialNumberContent">
      <span *ngFor="let serial of articleSerialsData[item.data.keyId]; last as isLast" class="serialNumberElement">
        <a [routerLink]="" (click)="showSerialNumberInfo(serial)">
          <span [innerHTML]="highlightSearchText(serial.serialNo)"></span>
        </a>
        <span *ngIf="!isLast">,&nbsp;</span>
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #serialInfoTemplate>
  <td-serial-number-info [serialInfo]="serialInfo"></td-serial-number-info>
</ng-template>
