"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./remove-unit.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("../../../../shared/components/td-data-grid/td-data-grid.component.ngfactory");
var i4 = require("../../../../shared/components/td-data-grid/td-data-grid.component");
var i5 = require("@angular/platform-browser");
var i6 = require("../../../../shared/services/session-helper.service");
var i7 = require("../../../../core/utility/utility.service");
var i8 = require("../../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i9 = require("devextreme-angular/core");
var i10 = require("devextreme-angular/ui/button");
var i11 = require("../../../../../../node_modules/devextreme-angular/ui/check-box/devextreme-angular-ui-check-box.ngfactory");
var i12 = require("@angular/forms");
var i13 = require("devextreme-angular/ui/check-box");
var i14 = require("./remove-unit.component");
var i15 = require("../../../../core/search/services/search-manager.service");
var i16 = require("../../../../core/search/filters/unit-filter.service");
var styles_RemoveUnitComponent = [i0.styles];
var RenderType_RemoveUnitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RemoveUnitComponent, data: {} });
exports.RenderType_RemoveUnitComponent = RenderType_RemoveUnitComponent;
function View_RemoveUnitComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "d-flex flex-column justify-content-between remove-unit-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "popup-headline"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ": ", " - ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "remove-unit-grid grid-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "td-data-grid", [], null, null, null, i3.View_TdDataGridComponent_0, i3.RenderType_TdDataGridComponent)), i1.ɵdid(7, 4833280, null, 0, i4.TdDataGridComponent, [i5.DomSanitizer, "TABLE_INFO_SERVICE", i6.SessionHelperService, i7.UtilityService, i2.TranslateService], { mainTable: [0, "mainTable"], gridStructure: [1, "gridStructure"], data: [2, "data"], templates: [3, "templates"], gridAlias: [4, "gridAlias"] }, null), i1.ɵpod(8, { select: 0, status: 1 }), (_l()(), i1.ɵeld(9, 0, null, null, 15, "div", [["class", "row"], ["id", "button-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 14, "div", [["class", "col d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "dx-button", [["class", "td-button secondary-button mr-3"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.popupService.cancelPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DxButtonComponent_0, i8.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i9.DxTemplateHost, i9.DxTemplateHost, []), i1.ɵprd(512, null, i9.WatcherHelper, i9.WatcherHelper, []), i1.ɵprd(512, null, i9.NestedOptionHost, i9.NestedOptionHost, []), i1.ɵdid(15, 7323648, null, 0, i10.DxButtonComponent, [i1.ElementRef, i1.NgZone, i9.DxTemplateHost, i9.WatcherHelper, i9.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], null, { onClick: "onClick" }), (_l()(), i1.ɵted(16, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 6, "dx-button", [["class", "td-button danger-button"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.confirmRemoveUnit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DxButtonComponent_0, i8.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i9.DxTemplateHost, i9.DxTemplateHost, []), i1.ɵprd(512, null, i9.WatcherHelper, i9.WatcherHelper, []), i1.ɵprd(512, null, i9.NestedOptionHost, i9.NestedOptionHost, []), i1.ɵdid(22, 7323648, null, 0, i10.DxButtonComponent, [i1.ElementRef, i1.NgZone, i9.DxTemplateHost, i9.WatcherHelper, i9.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { disabled: [0, "disabled"] }, { onClick: "onClick" }), (_l()(), i1.ɵted(23, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.TD_MODEL_NAMES.unitModel; var currVal_4 = _co.removeUnitGridStructure; var currVal_5 = _co.unitsToRemove; var currVal_6 = _ck(_v, 8, 0, i1.ɵnov(_v.parent, 2), i1.ɵnov(_v.parent, 3)); var currVal_7 = _co.TD_DESKTOP_IDENTIFIERS.operationRemoveUnitListGrid; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); _ck(_v, 15, 0); var currVal_9 = !_co.unitSelected; _ck(_v, 22, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("appCommon.product")); var currVal_1 = _co.articleUpForRemoval.product; var currVal_2 = _co.articleUpForRemoval.item.text; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_8 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("button.cancel")); _ck(_v, 16, 0, currVal_8); var currVal_10 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("operationDetails.remove")); _ck(_v, 23, 0, currVal_10); }); }
function View_RemoveUnitComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "dx-check-box", [], null, [[null, "valueChange"], [null, "onValueChanged"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_v.context.item.data[_co.TD_DYNAMIC_FIELDS.select] = $event) !== false);
        ad = (pd_2 && ad);
    } if (("onValueChanged" === en)) {
        var pd_3 = (_co.checkUnitSelected() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i11.View_DxCheckBoxComponent_0, i11.RenderType_DxCheckBoxComponent)), i1.ɵprd(5120, null, i12.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i13.DxCheckBoxComponent]), i1.ɵprd(512, null, i9.DxTemplateHost, i9.DxTemplateHost, []), i1.ɵprd(512, null, i9.WatcherHelper, i9.WatcherHelper, []), i1.ɵprd(512, null, i9.IterableDifferHelper, i9.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i9.NestedOptionHost, i9.NestedOptionHost, []), i1.ɵdid(6, 7323648, null, 0, i13.DxCheckBoxComponent, [i1.ElementRef, i1.NgZone, i9.DxTemplateHost, i9.WatcherHelper, i9.IterableDifferHelper, i9.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { value: [0, "value"] }, { onValueChanged: "onValueChanged", valueChange: "valueChange", onBlur: "onBlur" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.item.data[_co.TD_DYNAMIC_FIELDS.select]; _ck(_v, 6, 0, currVal_0); }, null); }
function View_RemoveUnitComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", "\n"]))], null, function (_ck, _v) { var currVal_0 = _v.context.item.data.status; _ck(_v, 0, 0, currVal_0); }); }
function View_RemoveUnitComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { removeUnitPopupTemplate: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["removeUnitPopupTemplate", 2]], null, 0, null, View_RemoveUnitComponent_1)), (_l()(), i1.ɵand(0, [["removeUnitCheckboxTemplate", 2]], null, 0, null, View_RemoveUnitComponent_2)), (_l()(), i1.ɵand(0, [["removeUnitStatusTemplate", 2]], null, 0, null, View_RemoveUnitComponent_3))], null, null); }
exports.View_RemoveUnitComponent_0 = View_RemoveUnitComponent_0;
function View_RemoveUnitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-remove-unit", [], null, null, null, View_RemoveUnitComponent_0, RenderType_RemoveUnitComponent)), i1.ɵdid(1, 114688, null, 0, i14.RemoveUnitComponent, ["OPERATION_DATA_SERVICE", i15.SearchManagerService, i16.UnitFilterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_RemoveUnitComponent_Host_0 = View_RemoveUnitComponent_Host_0;
var RemoveUnitComponentNgFactory = i1.ɵccf("td-remove-unit", i14.RemoveUnitComponent, View_RemoveUnitComponent_Host_0, { articleUpForRemoval: "articleUpForRemoval", operationKeyId: "operationKeyId" }, { popupResolved: "popupResolved" }, []);
exports.RemoveUnitComponentNgFactory = RemoveUnitComponentNgFactory;
