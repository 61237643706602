"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".custTitle[_ngcontent-%COMP%]{color:var(--color-gray-2);font:20px Verdana}.auth-customers[_ngcontent-%COMP%]   .list-group[_ngcontent-%COMP%]{font-family:var(--font-family)}.auth-customers[_ngcontent-%COMP%]   .list-group-item[_ngcontent-%COMP%]{flex:1;cursor:pointer;border:none;border-bottom:var(--border-1)}.auth-customers[_ngcontent-%COMP%]   .list-group-item[_ngcontent-%COMP%]:hover{background-color:var(--color-gray-5)}"];
exports.styles = styles;
