import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { SESSION_STORAGE, StorageService } from 'angular-webstorage-service';

import { LoggerService } from '@core/logger/logger.service';

const AUTH_CONFIG_STORAGE_KEY = 'AuthConfiguration';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigurationService {

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
  ) {}

  getAuthOptions(): Promise<UserManagerSettings> {
    this.storage.remove(AUTH_CONFIG_STORAGE_KEY);
    const config = this.storage.get(AUTH_CONFIG_STORAGE_KEY);
    if (config) {
      return null;
    }
    else {
      return this.http.get<UserManagerSettings>(`${window.location.origin}/AuthOptions`)
        .toPromise()
        .then(configuration => {
          this.storage.set(AUTH_CONFIG_STORAGE_KEY, configuration);
          this.loggerService.log('Auth configuration retrieved successfully');
          return configuration;
        });
    }
  }

  getUserManagerSettings(): UserManagerSettings {
    return {
      ...this.storage.get(AUTH_CONFIG_STORAGE_KEY),
      userStore: new WebStorageStateStore({ store: window.localStorage })
    }
  }
}
