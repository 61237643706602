import {
  IncludeItemSerialUsage,
  FuzzySearchTextMatchType,
  SearchAutoPictureMode,
  SpecialSearchType,
  WebDisplayGeneralProductsMode,
  WebDisplayCustomerProductsMode,
  ItemInstrumentType,
  UseOfUsedSerials,
  UseOfUnusedSerials
} from '@core/data-layer/shared/models/td.enumerations';
import { BaseGridArgsModel } from '@core/data-layer/shared/models/base-grid-args.model';
import { GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';

export class FuzzySearchArgsModel {
  // Hold xml condition for ComboBox-filter or Quick-filter.
  // Takes to account ONLY string-type fields that are using "like" and "not like" operators.
  xmlCondition: string;
  // Hold sql condition based on TcxFilterControl.FilterText (take to account NOT string-type fields).
  currentFilterText: string;
  // List of fields to be returned from Lucene engine.
  resultFields: string[];
}

export class GridFilterSearchArgsModel {
  // Field name
  fieldName: string;
  // Field value
  fieldValue: any;

  constructor(fieldName: string, fieldValue: any) {
    this.fieldName = fieldName;
    this.fieldValue = fieldValue;
  }
}

// These properties are used when searching for item serial numbers in Scanner Client
// Set when searching for item serial numbers in Scanner Client. Used when MainTable = TSerial.
export class ExtraItemSerialSearchArgsModel {
  // Causes search to include information about where an item serial number is last used.
  includeItemSerialUsage: IncludeItemSerialUsage;
  // Location option
  allowUsedSerials: UseOfUsedSerials;
  // Location option
  allowUnusedSerials: UseOfUnusedSerials;
  // The unit being counted, if any - only used when IncludeItemSerialUsage = True
  countedUnit: number;
  // The product serial number being counted, if any - only used when IncludeItemSerialUsage = True
  countedProductSerialKeyId: number;
}

// Extra parameter(s) for "SpecialSearchType.CustomerUnits"
export class ExtraCustomerUnitsSearchArgsModel {
  // Customer key id
  customerKeyId: number;
}

// Extra parameter(s) for "SpecialSearchType.ProductsBasedOnItem".
export class ExtraProductsBasedOnItemSearchArgsModel {
  // Item key id
  itemKeyId: number;
  // Is composite item
  composite: boolean;
  // View directly
  directly: boolean;
}

// Extra parameter(s) for "SpecialSearchType.WebProductsBasedOnItem".
export class ExtraWebProductsBasedOnItemSearchArgsModel extends ExtraProductsBasedOnItemSearchArgsModel {
  // Factory key id
  factoryKeyId: number;
}

// Extra parameter(s) for "SpecialSearchType.WebBaseProdItem" and "SpecialSearchType.WebProdWithSerials".
export class ExtraWebArticlesSearchArgsModel {
  // Order factory key id(s).
  orderFactoryList: number[];
  // Customer factory key id(s).
  customerFactoryList: number[];

  // Equal to ServerDesktopOptions.DisplayGeneralProductsMode.
  generalProductsMode: WebDisplayGeneralProductsMode;
  // Equal to ServerDesktopOptions.DisplayCustomerProductsMode.
  customerProductsMode: WebDisplayCustomerProductsMode;

  // Equal to UserDesktopOptions.Search_OrderableFromCurrentFactory.
  orderableFromCurrentFactory: boolean;
  // Equal to UserDesktopOptions.ProdFiltMethodGeneral.
  productsFilterMethodIsGeneral: boolean;
  // Equal to UserDesktopOptions.ProdFiltMethodCustomer.
  productsFilterMethodIsCustomer: boolean;
  // Equal to UserDesktopOptions.ProdFiltMethodCustSite.
  productsFilterMethodIsCustomerSite: boolean;
  // Equal to User ProfileData.System_CanSeeAllProductsInWeb.
  userCanSeeAllProducts: boolean;

  // User Customer key id.
  userCustomerKeyId: number;
  // User Customer customer site key id.
  userCustomerCustomerSiteKeyId: number;

  // Item instrument type.
  itemInstrumentType: ItemInstrumentType;
  // Product key id.
  productKeyId: number;

  // Used to determine which type of search for products(endoscopes) is currently used - full or reduced.
  reducedSearch: boolean;
}

// Extra parameter(s) for "SpecialSearchType.IncubatorLog".
export class ExtraIncubatorLogSearchArgsModel {
  // To date time
  toDate: Date;
  // From date time
  fromDate: Date;
  // Machine key id
  machineKeyId: number;
}

// Extra parameter(s) for "SpecialSearchType.PrefLists"
export class ExtraPrefListsSearchArgsModel {
  // Specialty id(s)
  specialtyIds: number[];
  // Preference list id(s)
  preferenceListIds: number[];
}

// Extra parameter(s) for "SpecialSearchType.WebScheduledOperation"
export class ExtraWebScheduledOperationSearchArgsModel {
  // Flag: user can change default values.
  userCanChangeDefaults: boolean;
}

// Extra parameter(s) for "SpecialSearchType.WebOperationUnits"
export class ExtraWebOperationUnitsSearchArgsModel {
  // Operation key id.
  operationKeyId: number;
  // Factory key id(s).
  factoryKeyIds: number[];
}

// Extra parameter(s) for "SpecialSearchType.WebOperationProducts"
export class ExtraWebOperationProductsSearchArgsModel {
  // Operation key id.
  operationKeyId: number;
  // Item type.
  itemType: ItemInstrumentType;
}

// Extra parameter(s) for "SpecialSearchType.WebOperationItems"
export class ExtraWebOperationItemsSearchArgsModel {
  // Operation key id.
  operationKeyId: number;
}

export class SearchArgsModel extends BaseGridArgsModel {
  // Unique request id
  requestId: number;
  // The search controller (UI component) that is actually making the search request. Using on Scanner Client.
  searchControllerId: number;
  // Search type
  searchType: SpecialSearchType;
  // Parameters that are using in Lucene engine.
  fuzzySearchArgs: FuzzySearchArgsModel;
  // View common data
  viewCommonData: boolean;
  // Current factory id
  currentFactoryKeyId: number;
  // The text that should be searched for in "SearchTextFields".
  searchText: string;
  // Where conditions that are specified in code and must be applied to the database query. Field names must be in qualified field format.
  criterias: any[];
  // We are using this "FilterIsSet = true" for case when we set UI filter dirrectly in "WhereExtra" property.
  filterIsSet = false;
  // Use fuzzy search in ComboBox-filter and Grid-filter
  useFuzzySearchInFilters: boolean;
  // Show all result
  showAllResult: boolean;
  // The filter text from the predefined filters in the search form.
  preDefinedDropDownFilterText: string;
  // The Text match type to use for GridFilterArgs.
  gridFilterTextMatchType: FuzzySearchTextMatchType;
  // The filter parameters from the "quick" filter grid in the top of the search frame.
  gridFilterArgsList: GridFilterSearchArgsModel[];
  // Determines if and how we include PicsPicKeyId for tables that support it
  autoPictureMode: SearchAutoPictureMode;
  // Identifier can be used to distinguish 2 different searches in the same table
  identifier: number;

  // These properties are used when searching for item serial numbers in Scanner Client
  // Set when searching for item serial numbers in Scanner Client. Used when MainTable = TSerial.
  extraItemSerialArgs: ExtraItemSerialSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.CustomerUnits"
  extraCustomerUnitsArgs: ExtraCustomerUnitsSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.ProductsBasedOnItem"
  extraProductsBasedOnItemArgs: ExtraProductsBasedOnItemSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.WebProductsBasedOnItem"
  extraWebProductsBasedOnItemArgs: ExtraWebProductsBasedOnItemSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.WebBaseProdItem" and "SpecialSearchType.WebProdWithSerials".
  extraWebArticlesArgs: ExtraWebArticlesSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.IncubatorLog".
  extraIncubatorLogArgs: ExtraIncubatorLogSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.PrefLists"
  extraPrefListsArgs: ExtraPrefListsSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.WebScheduledOperation" search
  extraWebScheduledOperationArgs: ExtraWebScheduledOperationSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.WebOperationUnits" search
  extraWebOperationUnitsArgs: ExtraWebOperationUnitsSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.WebOperationProducts" search
  extraWebOperationProductsArgs: ExtraWebOperationProductsSearchArgsModel;

  // Extra parameter(s) for "SpecialSearchType.WebOperationItems" search
  extraWebOperationItemsArgs: ExtraWebOperationItemsSearchArgsModel;

  constructor(mainTable: string, gridStructure: GridStructureViewInterface) {
    super(mainTable, gridStructure);
    this.fuzzySearchArgs = new FuzzySearchArgsModel();
    this.gridFilterArgsList = new Array<GridFilterSearchArgsModel>();
    this.extraItemSerialArgs = new ExtraItemSerialSearchArgsModel();
    this.extraCustomerUnitsArgs = new ExtraCustomerUnitsSearchArgsModel();
    this.extraProductsBasedOnItemArgs = new ExtraProductsBasedOnItemSearchArgsModel();
    this.extraWebProductsBasedOnItemArgs = new ExtraWebProductsBasedOnItemSearchArgsModel();
    this.extraWebArticlesArgs = new ExtraWebArticlesSearchArgsModel();
    this.extraIncubatorLogArgs = new ExtraIncubatorLogSearchArgsModel();
    this.extraPrefListsArgs = new ExtraPrefListsSearchArgsModel();
    this.extraWebScheduledOperationArgs = new ExtraWebScheduledOperationSearchArgsModel();
    this.extraWebOperationUnitsArgs = new ExtraWebOperationUnitsSearchArgsModel();
    this.extraWebOperationProductsArgs = new ExtraWebOperationProductsSearchArgsModel();
    this.extraWebOperationItemsArgs = new ExtraWebOperationItemsSearchArgsModel();
  }
}
