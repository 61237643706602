"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./td-search-filters-button.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i3 = require("@angular/common");
var i4 = require("devextreme-angular/core");
var i5 = require("devextreme-angular/ui/button");
var i6 = require("@angular/platform-browser");
var i7 = require("@ngx-translate/core");
var i8 = require("../td-search-filters/td-search-filters.component.ngfactory");
var i9 = require("../td-search-filters/td-search-filters.component");
var i10 = require("../../services/session-helper.service");
var i11 = require("@ngrx/store");
var i12 = require("../../services/search-filters-helper.service");
var i13 = require("../td-confirm-button-panel/td-confirm-button-panel.component.ngfactory");
var i14 = require("../td-confirm-button-panel/td-confirm-button-panel.component");
var i15 = require("./td-search-filters-button.component");
var i16 = require("../td-popup/services/td-popup.service");
var styles_TdSearchFiltersButtonComponent = [i0.styles];
var RenderType_TdSearchFiltersButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TdSearchFiltersButtonComponent, data: {} });
exports.RenderType_TdSearchFiltersButtonComponent = RenderType_TdSearchFiltersButtonComponent;
function View_TdSearchFiltersButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "filter-button-overlay"], ["src", ".\\assets\\img\\SearchFilterOverlay.svg"]], null, null, null, null, null))], null, null); }
function View_TdSearchFiltersButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "dx-button", [["class", "action-icon-button"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.defineSearchFilters() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DxButtonComponent_0, i2.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(8, 7323648, null, 0, i5.DxButtonComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { hint: [0, "hint"] }, { onClick: "onClick" }), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "div", [["class", "filter-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["class", "filter-button-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdSearchFiltersButtonComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "action-icon-button"; var currVal_1 = (_co.darkMode ? "tertiary-button-dark-mode" : "tertiary-button"); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("search.searchFilters")), ""); _ck(_v, 8, 0, currVal_2); var currVal_4 = _co.activeFilterIcon; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, ".\\assets\\img\\", _co.filterIcon, ""); _ck(_v, 11, 0, currVal_3); }); }
function View_TdSearchFiltersButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-search-filters", [], null, null, null, i8.View_TdSearchFiltersComponent_0, i8.RenderType_TdSearchFiltersComponent)), i1.ɵdid(1, 245760, [[2, 4], ["searchFilters", 4]], 0, i9.TdSearchFiltersComponent, ["USER_SESSION_SERVICE", i10.SessionHelperService, i11.Store, i12.SearchFiltersHelperService], { defaultFilters: [0, "defaultFilters"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "td-confirm-button-panel", [], null, [[null, "confirmEvent"], [null, "resetEvent"], [null, "cancelEvent"], [null, "clearEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("confirmEvent" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("resetEvent" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).resetToDefault() !== false);
        ad = (pd_1 && ad);
    } if (("cancelEvent" === en)) {
        var pd_2 = (_co.cancel() !== false);
        ad = (pd_2 && ad);
    } if (("clearEvent" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).clear() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i13.View_TdConfirmButtonPanelComponent_0, i13.RenderType_TdConfirmButtonPanelComponent)), i1.ɵdid(3, 114688, null, 0, i14.TdConfirmButtonPanelComponent, [], { disableSaveButton: [0, "disableSaveButton"], clearButtonVisible: [1, "clearButtonVisible"], resetButtonVisible: [2, "resetButtonVisible"] }, { confirmEvent: "confirmEvent", cancelEvent: "cancelEvent", resetEvent: "resetEvent", clearEvent: "clearEvent" })], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); var currVal_1 = !i1.ɵnov(_v, 1).filtersChanged; var currVal_2 = true; var currVal_3 = true; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_TdSearchFiltersButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { searchFiltersTemplate: 0 }), i1.ɵqud(671088640, 2, { searchFilters: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdSearchFiltersButtonComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["searchFiltersTemplate", 2]], null, 0, null, View_TdSearchFiltersButtonComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionHelperService.loggedIn(); _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_TdSearchFiltersButtonComponent_0 = View_TdSearchFiltersButtonComponent_0;
function View_TdSearchFiltersButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-search-filters-button", [], null, null, null, View_TdSearchFiltersButtonComponent_0, RenderType_TdSearchFiltersButtonComponent)), i1.ɵdid(1, 180224, null, 0, i15.TdSearchFiltersButtonComponent, [i10.SessionHelperService, i16.TdPopupService, i12.SearchFiltersHelperService], null, null)], null, null); }
exports.View_TdSearchFiltersButtonComponent_Host_0 = View_TdSearchFiltersButtonComponent_Host_0;
var TdSearchFiltersButtonComponentNgFactory = i1.ɵccf("td-search-filters-button", i15.TdSearchFiltersButtonComponent, View_TdSearchFiltersButtonComponent_Host_0, { darkMode: "darkMode" }, {}, []);
exports.TdSearchFiltersButtonComponentNgFactory = TdSearchFiltersButtonComponentNgFactory;
