import { Params } from '@angular/router';
import { TD_QUERY_PARAMS } from '@core/data-layer/shared/models/td.constants';
import { SearchParam, SearchParamBase, SearchParamTypes } from './search-param.models';

export interface SearchParamBoolean extends SearchParamBase {  
  type: SearchParamTypes;  
  value?: boolean;
}
export function addBooleanPropToParams(property: SearchParam, params: Params): Params {
  params[property.urlParamName] = (property.value as boolean).toString();
  return params;
}

export function extractBooleanFromParams(paramName: string, params: Params): string {
  return params[TD_QUERY_PARAMS[paramName]] ? JSON.parse(params[TD_QUERY_PARAMS[paramName]]) : undefined;
}