"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var userSessionActions = require("./user-session.actions");
exports.initialUserSessionState = {
    authUser: null,
    profileData: null,
    userOptions: null,
    defaultUserOptions: null
};
function userSessionReducer(state, action) {
    if (state === void 0) { state = exports.initialUserSessionState; }
    switch (action.type) {
        case userSessionActions.UserSessionActionTypes.LoadProfileData:
            return handleLoadProfileData(state, action);
        case userSessionActions.UserSessionActionTypes.SetProfileData:
            return handleSetProfileData(state, action);
        case userSessionActions.UserSessionActionTypes.LoadUserOptions:
            return handleLoadUserOptions(state, action);
        case userSessionActions.UserSessionActionTypes.SetUserOptions:
            return handleSetUserOptions(state, action);
        case userSessionActions.UserSessionActionTypes.LoadDefaultUserOptions:
            return handleLoadDefaultUserOptions(state, action);
        case userSessionActions.UserSessionActionTypes.SetDefaultUserOptions:
            return handleSetDefaultUserOptions(state, action);
        default:
            return state;
    }
}
exports.userSessionReducer = userSessionReducer;
function handleLoadProfileData(state, action) {
    return state;
}
function handleSetProfileData(state, action) {
    return Object.assign({}, state, { profileData: action.payload });
}
function handleLoadUserOptions(state, action) {
    return state;
}
function handleSetUserOptions(state, action) {
    return Object.assign({}, state, { userOptions: action.payload });
}
function handleLoadDefaultUserOptions(state, action) {
    return state;
}
function handleSetDefaultUserOptions(state, action) {
    return Object.assign({}, state, { defaultUserOptions: action.payload });
}
