<div id="orderCartContainer" class="row">

  <ng-container *ngIf="expanded; else collapsedCart">
    <div id="expandedCart" class="col-sm-12 pt-2">
      <div id="cartIconArea" class="row pb-2">
        <div class="col">
          <img src=".\assets\img\shoppingCart.png" />
          <span class="header-cart align-middle pl-1">
            {{ 'cart.cart' | translate }}
          </span>
        </div>
        <div>
          <dx-button
            class="action-icon-button tertiary-button"
            hint="{{ 'cart.collapse' | translate }}"
            (onClick)="toggleExpandCart()">
              <img src=".\assets\img\RightBlueTriangle.svg">
          </dx-button>
        </div>
      </div>
      <div id="orderNumberArea" class="row pb-2">
        <div class="col d-flex align-items-center">
          {{ 'cart.orderNumber' | translate }}
        </div>
        <div>
          <div class="row">
            <div class="col">

              <!--Fix for Bug 19484 - Todo: more investigation to find root cause which could be related to DevExpress-->
              <ng-container *ngIf="sessionHelperService.customerOpenOrders">
                <dx-lookup
                  [dataSource]="sessionHelperService.customerOpenOrders"
                  [value]="lastOpenOrderKeyId()"
                  valueExpr="keyId"
                  displayExpr="orgRefNo"
                  width="100"
                  height="30"
                  [showPopupTitle]="false"
                  [closeOnOutsideClick]="true"
                  searchPlaceholder=""
                  [showCancelButton]="false"
                  [noDataText]="'cart.noMatchesFound' | translate"
                  [popupWidth]="160"
                  (onValueChanged)="selectedOrderChanged($event)"
                >
                </dx-lookup>
              </ng-container>

            </div>
            <div class="pl-1">
              <dx-button
                class="action-icon-button tertiary-button"
                hint="{{ 'cart.createNewOrder' | translate }}"
                (onClick)="createNewOrder()">
                  <img src=".\assets\img\add.svg">
              </dx-button>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-2">
        <ng-container *ngIf="sessionHelperService.userLastOpenOrder && sessionHelperService.userLastOpenOrder.lines.length > 0; else noOrderLines">
          <td-data-grid #orderCartGrid
                        [height]="gridOptions?.height"
                        [gridAlias]="gridIdentifiers.orderCartGrid"
                        [data]="sessionHelperService.userLastOpenOrder.lines"
                        [mainTable]="orderCartMainTable"
                        [gridStructure]="orderCartGridStructure"
                        [remoteOperations]="false"
                        [focusRowEnabled]="true"
                        [pagination]="{ enabled: false, pageIndex: 0, pageSize: 10 }"
                        [scrolling]="{ useNative: false, mode: 'standard', showScrollbar: gridOptions?.showScrollbar }"
                        [width]="280"
                        keyExpression="oLnLine"
                        (rowPrepared)="gridRowPrepared($event)"
                        (contentReady)="gridContentReady()"
                        [templates]="{
                      article: articleTemplate,
                      quantity: quantityTemplate,
                      delete: deleteTemplate
                    }">
          </td-data-grid>

          <ng-template #articleTemplate let-item="item">
            <a [routerLink]="[(item.data.oLnRefType === tableTypes.Item ? '/items' : '/products'), item.data.oLnRefKeyId]">{{item.data.oLnArticle + ' ' + item.data.itemText}}</a>
          </ng-template>

          <ng-template #quantityTemplate let-item="item">
            <dx-number-box class="grid-number-box"
                           height="26"
                           [showSpinButtons]="true"
                           [min]="1"
                           [max]="maxQuantityValue"
                           format="#"
                           (onValueChanged)="updateLine($event, item.data)"
                           [(value)]="item.data.oLnOrgUnitCount">
            </dx-number-box>
          </ng-template>

          <ng-template #deleteTemplate let-item="item">
            <dx-button class="grid-action-button" (onClick)="deleteLine(item.data)"
                       hint="{{ 'cart.removeArticle' | translate }}" icon=".\assets\img\delete.png">
            </dx-button>
          </ng-template>
        </ng-container>
        <ng-template #noOrderLines>
          {{ 'cart.noOrderLines' | translate }}
        </ng-template>
      </div>
      <div id="viewOrderBtnArea" class="row float-right pb-2">
        <dx-button [routerLink]="['/orders', lastOpenOrderKeyId()]"
                   class="td-button tertiary-button"
                   [text]="'cart.viewOrder' | translate"
                   [disabled]="!sessionHelperService.userLastOpenOrder">
        </dx-button>
      </div>
    </div>
  </ng-container>

  <ng-template #collapsedCart>
    <div id="collapsedCart" class="col-sm-12 pt-2">
      <dx-button
        class="action-icon-button tertiary-button"
        hint="{{ 'cart.expand' | translate }}"
        (onClick)="toggleExpandCart()">
          <img src=".\assets\img\slideLeft.png">
      </dx-button>
      <span class="vertical-text header-cart textEllipsis">
        {{ 'cart.viewCartOrderNo' | translate }} {{sessionHelperService.userLastOpenOrder ? sessionHelperService.userLastOpenOrder.details.orgRefNo : ''}}
      </span>
      <div>
        <img class="collapsed-cart-img pb-2" src=".\assets\img\shoppingCart.png" />
      </div>
    </div>
  </ng-template>

</div>
