"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var td_popup_model_1 = require("../models/td-popup.model");
var core_2 = require("@ngx-translate/core");
var td_load_panel_service_1 = require("../../td-load-panel/services/td-load-panel.service");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("../../td-load-panel/services/td-load-panel.service");
var TdPopupService = /** @class */ (function () {
    function TdPopupService(translateService, loadPanelService) {
        this.translateService = translateService;
        this.loadPanelService = loadPanelService;
        this.popupParams = new td_popup_model_1.TdPopupModel();
        this.displayPopup = new rxjs_1.BehaviorSubject(false);
    }
    TdPopupService.prototype.closePopup = function () {
        this.popupParams.resolvePopup();
        this.displayPopup.next(false);
    };
    TdPopupService.prototype.confirmPopup = function () {
        this.popupParams.comfirm();
        this.closePopup();
    };
    TdPopupService.prototype.cancelPopup = function () {
        this.popupParams.cancel();
        this.closePopup();
    };
    TdPopupService.prototype.showInfo = function (message, translateMessage) {
        if (translateMessage === void 0) { translateMessage = false; }
        return this.showPopup('popup.info', message, null, td_popup_model_1.PopupType.Information, translateMessage);
    };
    TdPopupService.prototype.showCustomInfo = function (title, message, translateMessage) {
        if (translateMessage === void 0) { translateMessage = false; }
        return this.showPopup(title, message, null, td_popup_model_1.PopupType.Information, translateMessage);
    };
    TdPopupService.prototype.confirmDialog = function (title, message, translateMessage) {
        if (translateMessage === void 0) { translateMessage = false; }
        return this.showPopup(title, message, null, td_popup_model_1.PopupType.Confirmation, translateMessage);
    };
    TdPopupService.prototype.showError = function (message, translateMessage, referenceId) {
        if (translateMessage === void 0) { translateMessage = false; }
        if (referenceId === void 0) { referenceId = ''; }
        return this.showPopup('appCommon.error', message, null, td_popup_model_1.PopupType.Error, translateMessage, referenceId);
    };
    TdPopupService.prototype.showTemplate = function (title, content, titleIconPath) {
        return this.showPopup(title, '', content, td_popup_model_1.PopupType.Template, null, null, titleIconPath);
    };
    /**
     * Function for generating a customized popup, with customizable title icon, icon type and buttons
     * @param title: string - The title of the popup. Is automatically translated
     * @param message: string - The message of the popup. Will be translated if "translateMessage" is set to true (default is false)
     * @param iconType: PopupType - Determines the icon that will be displayed above the popup message
     * @param buttons: TdPopupButtonInterface[] - An array of buttons that will be added to the popup
     * @param translateMessage: boolean - decides whether the message property should be translated (default is false)
     * @param titleIconPath: string - a path to an image used as an icon in the title bar (default is null)
     *
     * @returns popup as a Promise<boolean>
     */
    TdPopupService.prototype.showCustomWithButtons = function (title, message, iconType, buttons, translateMessage, titleIconPath) {
        if (iconType === void 0) { iconType = td_popup_model_1.PopupType.Information; }
        if (translateMessage === void 0) { translateMessage = false; }
        if (titleIconPath === void 0) { titleIconPath = null; }
        return this.showPopup(title, message, null, td_popup_model_1.PopupType.CustomWithButtons, translateMessage, null, titleIconPath, buttons, iconType);
    };
    TdPopupService.prototype.showPopup = function (title, message, template, type, translateMessage, referenceId, titleIconPath, buttons, iconType) {
        if (type === void 0) { type = td_popup_model_1.PopupType.Information; }
        if (translateMessage === void 0) { translateMessage = false; }
        if (referenceId === void 0) { referenceId = ''; }
        if (titleIconPath === void 0) { titleIconPath = null; }
        if (buttons === void 0) { buttons = null; }
        if (iconType === void 0) { iconType = null; }
        this.loadPanelService.hideLoadPanel();
        title = this.translateService.instant(title);
        message = (translateMessage ? this.translateService.instant(message) : message).replace('|', '<br/>');
        if (referenceId) {
            message = message + '<br/>' + this.translateService.instant('appCommon.referenceId') + ': ' + referenceId;
        }
        this.popupParams = new td_popup_model_1.TdPopupModel(true, title, message, template, type, titleIconPath, buttons, iconType);
        this.displayPopup.next(true);
        return this.popupParams.response;
    };
    TdPopupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TdPopupService_Factory() { return new TdPopupService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.TdLoadPanelService)); }, token: TdPopupService, providedIn: "root" });
    return TdPopupService;
}());
exports.TdPopupService = TdPopupService;
