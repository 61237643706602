"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var oidc_client_1 = require("oidc-client");
var angular_webstorage_service_1 = require("angular-webstorage-service");
var logger_service_1 = require("@core/logger/logger.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../logger/logger.service");
var i3 = require("angular-webstorage-service");
var AUTH_CONFIG_STORAGE_KEY = 'AuthConfiguration';
var AuthConfigurationService = /** @class */ (function () {
    function AuthConfigurationService(http, loggerService, storage) {
        this.http = http;
        this.loggerService = loggerService;
        this.storage = storage;
    }
    AuthConfigurationService.prototype.getAuthOptions = function () {
        var _this = this;
        this.storage.remove(AUTH_CONFIG_STORAGE_KEY);
        var config = this.storage.get(AUTH_CONFIG_STORAGE_KEY);
        if (config) {
            return null;
        }
        else {
            return this.http.get(window.location.origin + "/AuthOptions")
                .toPromise()
                .then(function (configuration) {
                _this.storage.set(AUTH_CONFIG_STORAGE_KEY, configuration);
                _this.loggerService.log('Auth configuration retrieved successfully');
                return configuration;
            });
        }
    };
    AuthConfigurationService.prototype.getUserManagerSettings = function () {
        return tslib_1.__assign({}, this.storage.get(AUTH_CONFIG_STORAGE_KEY), { userStore: new oidc_client_1.WebStorageStateStore({ store: window.localStorage }) });
    };
    AuthConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthConfigurationService_Factory() { return new AuthConfigurationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoggerService), i0.ɵɵinject(i3.SESSION_STORAGE)); }, token: AuthConfigurationService, providedIn: "root" });
    return AuthConfigurationService;
}());
exports.AuthConfigurationService = AuthConfigurationService;
