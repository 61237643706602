"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var TdGridMediaWrapperComponent = /** @class */ (function () {
    function TdGridMediaWrapperComponent(mediaService) {
        this.mediaService = mediaService;
    }
    TdGridMediaWrapperComponent.prototype.ngOnInit = function () {
        this.keyId = this.keyId ? this.keyId : this.mediaItem.keyId;
        this.mediaTitle = this.mediaTitle ? this.mediaTitle : this.determineMediaTitle();
        this.buttonId = this.generateIdForMedia();
    };
    TdGridMediaWrapperComponent.prototype.mediaHoverEnter = function () {
        var _this = this;
        if (!this.popOverIsLoading) {
            this.popOverTarget = "#" + this.buttonId;
            this.popOverVisible = true;
            this.popOverIsLoading = true;
            this.mediaService.getMediaThumbnail(this.picsKeyId).subscribe(function (thumbnail) {
                if (thumbnail) {
                    _this.popOverImg = thumbnail;
                }
                else {
                    _this.noThumbnailExists = true;
                }
                _this.popOverIsLoading = false;
            });
        }
    };
    TdGridMediaWrapperComponent.prototype.mediaHoverExit = function () {
        this.popOverVisible = false;
    };
    TdGridMediaWrapperComponent.prototype.showMediaViewer = function () {
        this.displayMediaViewer = true;
    };
    TdGridMediaWrapperComponent.prototype.determineMediaTitle = function () {
        if (this.mediaItem.product) {
            return this.mediaItem.product + " - " + this.mediaItem.item.text;
        }
        else if (this.mediaItem.item) {
            return this.mediaItem.item + " - " + this.mediaItem.text;
        }
        else if (this.mediaItem.oLnLine) {
            return this.mediaItem.oLnArticle + " - " + this.mediaItem.oLnArticleText;
        }
        else if (this.mediaItem.refItem) {
            return this.mediaItem.refItem.item + " - " + this.mediaItem.refItem.text;
        }
    };
    TdGridMediaWrapperComponent.prototype.generateIdForMedia = function () {
        return "mediaButton-" + this.tabIndex + "-" + this.mediaIndex + "-" + this.keyId + "-" + this.picsKeyId;
    };
    return TdGridMediaWrapperComponent;
}());
exports.TdGridMediaWrapperComponent = TdGridMediaWrapperComponent;
