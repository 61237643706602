"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var td_popup_service_1 = require("@shared/components/td-popup/services/td-popup.service");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("../components/td-popup/services/td-popup.service");
var UnitHelperService = /** @class */ (function () {
    function UnitHelperService(unitService, translateService, popupService) {
        this.unitService = unitService;
        this.translateService = translateService;
        this.popupService = popupService;
    }
    UnitHelperService.prototype.showFastTrackInfo = function (unitKeyId) {
        var _this = this;
        this.unitService.getFastTrackInfo(unitKeyId).subscribe(function (fastTrackInfo) {
            var fastTrackMessage = '';
            if (fastTrackInfo.fastTrackCodes && fastTrackInfo.fastTrackCodes.length > 0) {
                fastTrackMessage = _this.translateService.instant('appCommon.codes') + ':';
                fastTrackInfo.fastTrackCodes.forEach(function (fastTrackValue) {
                    fastTrackMessage = fastTrackMessage + '<br/>' + fastTrackValue;
                });
            }
            if (fastTrackInfo.fastTrackPlans && fastTrackInfo.fastTrackPlans.length > 0) {
                if (fastTrackMessage) {
                    fastTrackMessage = fastTrackMessage + '<br/><br/>';
                }
                fastTrackMessage = fastTrackMessage + _this.translateService.instant('appCommon.plans') + ':';
                fastTrackInfo.fastTrackPlans.forEach(function (fastTrackValue) {
                    fastTrackMessage = fastTrackMessage + '<br/>' + fastTrackValue;
                });
            }
            _this.popupService.showCustomInfo('appMessages.unitIsFastTracked', fastTrackMessage);
        });
    };
    UnitHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnitHelperService_Factory() { return new UnitHelperService(i0.ɵɵinject("UNIT_SERVICE"), i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.TdPopupService)); }, token: UnitHelperService, providedIn: "root" });
    return UnitHelperService;
}());
exports.UnitHelperService = UnitHelperService;
