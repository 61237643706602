"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TdConfirmButtonPanelComponent = /** @class */ (function () {
    function TdConfirmButtonPanelComponent() {
        this.disableSaveButton = false;
        this.clearButtonVisible = false;
        this.resetButtonVisible = false;
        this.confirmButtonTranslateString = 'button.ok';
        this.cancelButtonTranslateString = 'button.cancel';
        this.resetButtonTranslateString = 'button.resetToDefault';
        this.clearButtonTranslateString = 'button.clear';
        this.confirmEvent = new core_1.EventEmitter();
        this.cancelEvent = new core_1.EventEmitter();
        this.resetEvent = new core_1.EventEmitter();
        this.clearEvent = new core_1.EventEmitter();
    }
    TdConfirmButtonPanelComponent.prototype.ngOnInit = function () { };
    TdConfirmButtonPanelComponent.prototype.confirmChanges = function () {
        this.confirmEvent.emit();
    };
    TdConfirmButtonPanelComponent.prototype.cancelChanges = function () {
        this.cancelEvent.emit();
    };
    TdConfirmButtonPanelComponent.prototype.clear = function () {
        this.clearEvent.emit();
    };
    TdConfirmButtonPanelComponent.prototype.reset = function () {
        this.resetEvent.emit();
    };
    return TdConfirmButtonPanelComponent;
}());
exports.TdConfirmButtonPanelComponent = TdConfirmButtonPanelComponent;
