import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { SearchServiceInterface } from './search.service.interface';
import { SearchArgsModel } from '../models/search-args.model';
import { SearchResultModel } from '../models/search-result.model';
import { SearchResultViewModel } from '../models/search-result-view.model';
import { ApiDataResponse } from '@core/data-layer/shared/models/api-response.model';
import { SearchHelperService } from './search-helper.service';

@Injectable()
export class HttpSearchService implements SearchServiceInterface {

  constructor(
    private http: HttpClient,
    private searchHelperService: SearchHelperService
  ) { }

  searchUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.searchCtrl;
  }

  search(searchArgs: SearchArgsModel): Observable<ApiDataResponse<SearchResultViewModel>> {
    this.searchHelperService.applyColumnsConfigurations(searchArgs);
    return this.http.post<ApiDataResponse<SearchResultModel>>(this.searchUrl() + API_HUB_PATHS.search, searchArgs)
      .pipe(
        map(response => {
          const responseView: ApiDataResponse<SearchResultViewModel> = {
            error: response.error,
            hasError: response.hasError,
            message: response.message,
            value: this.searchHelperService.convertSearchResultToViewModel(searchArgs, response.value)
          };
          return responseView;
        })
      );
  }

}
