import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DxTabPanelComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';

import { TabsInterface } from '@shared/models/tabs.interface';
import { OperationDataServiceInterface } from '@core/data-layer/operation-data/services/operation-data.service.interface';
import { BaseComponent } from '@shared/components/base/base.component';
import { OperationDataViewModel } from '@core/data-layer/operation-data/models/operation-data-view.model';
import { ControlState } from '@shared/models/constrol-state.model';
import { GridStructureViewInterface, GridColumnConfigurationViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { SearchManagerService } from '@core/search/services/search-manager.service';
import { SearchArgsModel } from '@core/search/models/search-args.model';
import { TabHelperService } from '@shared/services/tab-helper.service';
import { GridLoadResultInterface } from '@shared/models/grid-data-request.interface';
import { TableTypes, WebOrderMode } from '@core/data-layer/shared/models/td.enumerations';
import { PropertyTypes } from '@core/table-info/models/grid-structure.interface';
import {
  TD_DESKTOP_IDENTIFIERS,
  TD_MODEL_NAMES,
  TD_DYNAMIC_FIELDS,
  TD_SYSTEM_MEDIA_SERIES,
  TD_ACTION_FIELDS
} from '@core/data-layer/shared/models/td.constants';
import {
  OperationStatus,
  WebEditOprationArticlesMode,
  WebStartStopOperationMode,
  ReservationStatus,
  OrderSuppStatus,
  ItemInstrumentType
} from '@core/data-layer/shared/models/td.enumerations';
import { OrderModel } from '@core/data-layer/order/models/order.model';
import { ApiResponse } from '@core/data-layer/shared/models/api-response.model';
import { NO_ERROR_CODE } from '@core/data-layer/shared/models/td.constants';
import { OperationsSharedService } from '../services/operations-shared.service';

@Component({
  selector: 'td-operation-details',
  templateUrl: 'operation-details.component.html',
  styleUrls: ['operation-details.component.less']
})

export class OperationDetailsComponent extends BaseComponent implements OnInit {

  @ViewChild('tabPanel', { static: false }) tabPanel: DxTabPanelComponent;

  operationKeyId: number;
  operation: OperationDataViewModel;

  operationTabs: TabsInterface[];
  productsTabTemplate = 'products';
  itemsTabTemplate = 'items';
  endoscopesTabTemplate = 'endoscopes';

  startOpBtnState: ControlState = new ControlState();
  stopOpBtnState: ControlState = new ControlState();
  addUnitDisabled: boolean;
  addUnitVisible: boolean;
  removeUnitDisabled: boolean;
  unitsToRemove: any[];
  articleUpForRemoval: any;
  showRemoveUnit = false;
  bookingEnabled: boolean;
  allowAddOrder = false;

  @ViewChild('removeUnitPopupTemplate', { static: false}) removeUnitPopupTemplate: TemplateRef<any>;

  productsGridStructure: GridStructureViewInterface;
  itemsGridStructure: GridStructureViewInterface;
  endoscopesGridStructure: GridStructureViewInterface;
  removeUnitGridStructure: GridStructureViewInterface;

  TD_MODEL_NAMES = TD_MODEL_NAMES;
  TD_DESKTOP_IDENTIFIERS = TD_DESKTOP_IDENTIFIERS;
  TD_DYNAMIC_FIELDS = TD_DYNAMIC_FIELDS;
  TD_SYSTEM_MEDIA_SERIES = TD_SYSTEM_MEDIA_SERIES;
  TableTypes = TableTypes;
  desiredActiveTabIndex: number;

  constructor(
    @Inject('OPERATION_DATA_SERVICE') private operationService: OperationDataServiceInterface,
    private activatedRoute: ActivatedRoute,
    private searchManagerService: SearchManagerService,
    private tabHelperService: TabHelperService,
    private operationsSharedService: OperationsSharedService
  ) {
    super();
  }

  async ngOnInit() {
    super.ngOnInit();
    await this.initGrid(TD_DESKTOP_IDENTIFIERS.operationProductListGrid, TD_MODEL_NAMES.productModel);
    await this.initGrid(TD_DESKTOP_IDENTIFIERS.operationItemListGrid, TD_MODEL_NAMES.itemModel);
    await this.initGrid(TD_DESKTOP_IDENTIFIERS.operationEndoscopeListGrid, TD_MODEL_NAMES.productModel);
    this.bookingEnabled = this.sessionHelperService.getBookingEnabled();
    this.getOperation();
  }

  private initGrid(identifier: string, model: string): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(identifier, model).subscribe(gridStructure => {
        if (gridStructure) {
          // add custom "media" column
          if (this.sessionHelperService.canViewMedia()) {
            this.gridColumnHelperService.addCustomMediaColumn(gridStructure.columnsConfigurations);
          }
          else {
            // remove custom "media" column
            this.gridColumnHelperService.removeColumn(gridStructure.columnsConfigurations, TD_ACTION_FIELDS.media);
          }
          if (identifier === TD_DESKTOP_IDENTIFIERS.operationItemListGrid) {
            this.gridColumnHelperService.setColumnProperty(
              gridStructure.columnsConfigurations,
              TD_DYNAMIC_FIELDS.olnOrgOrdered,
              { style: { alignment: 'right' } }
            );
          }
          else {
            this.addRemoveUnitColumn(gridStructure.columnsConfigurations);
          }
          this.setGridStructure(identifier, gridStructure);
        }
        resolve(true);
      });
    });
  }

  private addRemoveUnitColumn(columnsConfigurations: GridColumnConfigurationViewInterface[]) {
    if (this.allowEditReferenceToUnits()) {
      // add custom "remove unit" column
      const removeUnitColumn: GridColumnConfigurationViewInterface = {
        dataField: TD_ACTION_FIELDS.removeUnit,
        dataType: PropertyTypes.typeUndefined,
        visible: true,
        width: '30',
        displayName: '',
        style: {
          alignment: 'center',
        }
      };
      this.gridColumnHelperService.addCustomColumn(columnsConfigurations, removeUnitColumn);
    }
  }

  private setGridStructure(identifier: string, gridStructure: GridStructureViewInterface) {
    switch (identifier) {
      case TD_DESKTOP_IDENTIFIERS.operationProductListGrid:
        this.productsGridStructure = gridStructure;
        break;
      case TD_DESKTOP_IDENTIFIERS.operationItemListGrid:
        this.itemsGridStructure = gridStructure;
        break;
      case TD_DESKTOP_IDENTIFIERS.operationEndoscopeListGrid:
        this.endoscopesGridStructure = gridStructure;
        break;
    }
  }

  private getOperation(): void {
    this.activatedRoute.params.subscribe(params => {
      this.operationKeyId = +params.id;
      this.loadOperation();
    });
  }

  loadOperation(recentItemTypeAdded?: ItemInstrumentType) {
    if (recentItemTypeAdded !== undefined) {
      this.desiredActiveTabIndex = recentItemTypeAdded === ItemInstrumentType.Instrument ? 0 : 2;
    }
    this.loadPanelService.showLoadPanel();
    this.operationService.getOperationData(this.operationKeyId, this.sessionHelperService.currentCustomer.keyId).subscribe(operationResponse => {
      this.loadPanelService.hideLoadPanel();
      if (operationResponse) {
        this.allowAddOrder = (this.sessionHelperService.serverOptions.orderMode === WebOrderMode.On) && (operationResponse.status === OperationStatus.Accepted || operationResponse.status === OperationStatus.Planned);
        this.operation = operationResponse;
        this.initOperationTabs();
        this.titleService.setTitle(this.getTitleIdentifier());
        this.updateControls();
      }
    });
  }

  getProductsData(itemType: ItemInstrumentType, template: string): DataSource {
    return this.searchManagerService.requestSearchData(
      this.getProductsRequestArgs(itemType),
      '',
      this.afterDataLoaded(template));
  }

  getItemsData(): DataSource {
    return this.searchManagerService.requestSearchData(
      this.getItemsRequestArgs(),
      '',
      this.afterDataLoaded(this.itemsTabTemplate));
  }

  getProductsRequestArgs(itemType: ItemInstrumentType): SearchArgsModel {
    return this.searchManagerService.initWebOperationProductsSearchRequestArgs(this.productsGridStructure, this.operationKeyId, itemType);
  }

  getItemsRequestArgs(): SearchArgsModel {
    return this.searchManagerService.initWebOperationItemsSearchRequestArgs(this.itemsGridStructure, this.operationKeyId);
  }

  afterDataLoaded(templateName: string): (loadResult: GridLoadResultInterface, columnsConfigurations?: GridColumnConfigurationViewInterface[]) => void {
    return (loadResult) => {
      this.tabHelperService.setTabState(this.operationTabs, templateName, !loadResult.totalCount, this.tabPanel, this.desiredActiveTabIndex);
      if ([this.productsTabTemplate, this.endoscopesTabTemplate].includes(templateName)) {
        return loadResult.data.map(product => (product[TD_DYNAMIC_FIELDS.unitList] = product[TD_DYNAMIC_FIELDS.unitList].split(',')));
      }
    };
  }

  private initOperationTabs(): void {
    this.operationTabs = [
      {
        title: this.translateInstant('search.products'),
        data: this.getTabData(this.productsTabTemplate),
        template: this.productsTabTemplate
      },
      {
        title: this.translateInstant('search.items'),
        data: this.getTabData(this.itemsTabTemplate),
        template: this.itemsTabTemplate
      },
      {
        title: this.translateInstant('search.endoscopes'),
        data: this.getTabData(this.endoscopesTabTemplate),
        template: this.endoscopesTabTemplate
      }
    ];
  }

  allowEditReferenceToUnits(checkOperationStatus = false): boolean {
    let allowEdit = this.sessionHelperService.serverOptions.editOprationArticlesMode === WebEditOprationArticlesMode.AllowEdit;
    if (checkOperationStatus) {
      allowEdit = allowEdit && ![OperationStatus.Done, OperationStatus.Cancelled].includes(this.operation.status);
    }
    return allowEdit;
  }

  updateControls() {
    this.startOpBtnState.disabled = this.operation.status !== OperationStatus.Accepted;
    this.stopOpBtnState.disabled = this.operation.status !== OperationStatus.Started;
    this.removeUnitDisabled = (this.operation.status !== OperationStatus.Started) ||
      (this.sessionHelperService.serverOptions.editOprationArticlesMode === WebEditOprationArticlesMode.NotAllowEdit);
    this.addUnitDisabled = this.operation.status !== OperationStatus.Started;
    this.addUnitVisible = this.allowEditReferenceToUnits(true);
    if (!this.addUnitVisible) {
      this.removeRemoveUnitColumn(this.productsGridStructure);
      this.removeRemoveUnitColumn(this.endoscopesGridStructure);
    }
    this.startOpBtnState.visible =
      (this.sessionHelperService.serverOptions.startStopOperationMode === WebStartStopOperationMode.Allow);
    this.stopOpBtnState.visible = this.startOpBtnState.visible;
  }

  getTitleIdentifier(): string {
    const title = this.translateInstant('appCommon.operation');
    return this.operation ? `${title} ${this.operation.ident}` : title;
  }

  refreshOperation() {
    this.showRemoveUnit = false;
    this.loadOperation();
  }

  refreshGridData(tab: TabsInterface) {
    tab.data = this.getTabData(tab.template);
  }

  getTabData(template: string): DataSource {
    switch (template) {
      case this.productsTabTemplate:
        return this.getProductsData(ItemInstrumentType.Instrument, template);
      case this.itemsTabTemplate:
        return this.getItemsData();
      case this.endoscopesTabTemplate:
        return this.getProductsData(ItemInstrumentType.Endoscope, template);
    }
  }

  createOrder() {
    this.loadPanelService.showLoadPanel();
    this.operationsSharedService.addOrderToOperation(this.operationKeyId, this.operation.custStokKeyId, this.operation.startTime);
  }

  startOperation() {
    if (this.checkOperationStatus('start')) {
      const activeOrdersRefNo = this.getActiveOrdersRefNo(this.operation.refOpDOrderList);
      if (activeOrdersRefNo.length > 0) {
        let activeOrdersAsString = '';
        activeOrdersRefNo.forEach(entry => {
          activeOrdersAsString += `${entry} <br/>`;
        });
        const popupTitle: string = this.translateInstant('operationDetails.startOperation');
        const popupMessage = this.translateInstant('operationDetails.ordersAssociatedWithOperation').replace(/\|/g, '<br/>').replace('%s', activeOrdersAsString);
        this.popupService.confirmDialog(popupTitle, popupMessage).then(confirmed => {
          if (confirmed) {
            this.performStartOperation();
          }
        });
      }
      else {
        this.performStartOperation();
      }
    }
  }

  stopOperation() {
    if (this.checkOperationStatus('end')) {
      const undeliveredOrders: OrderModel[] = [];
      this.operation.refOpDOrderList.forEach(order => {
        if (order.suppStatus < OrderSuppStatus.Delivered) {
          undeliveredOrders.push(order);
        }
      });
      if (undeliveredOrders.length > 0) {
        const popupTitle: string = this.translateInstant('operationDetails.stopOperation');
        this.popupService.confirmDialog(popupTitle, 'operationDetails.cancelUndeliveredOrders', true).then(confirmed => {
          if (confirmed) {
            this.performStopOperation(true);
          }
          else {
            this.performStopOperation();
          }
        });
      }
      else {
        this.performStopOperation();
      }
    }
  }

  performStartOperation() {
    this.operationService.startOperation(this.operationKeyId).subscribe(response => {
      this.handleOperationStatusChangeResponse(response);
    });
  }

  performStopOperation(cancelOrders = false) {
    this.operationService.stopOperation(this.operationKeyId, cancelOrders).subscribe(response => {
      this.handleOperationStatusChangeResponse(response);
    })
  }

  checkOperationStatus(attemptedTask: string): boolean {
    const status = this.operation.status;
    if (attemptedTask === 'start' && status === OperationStatus.Started) {
      this.popupService.showError('operationDetails.alreadyStarted', true);
      return false;
    }
    else if (status === OperationStatus.Done) {
      this.popupService.showError('operationDetails.alreadyEnded', true);
      return false;
    }
    else {
      return true;
    }
  }

  getActiveOrdersRefNo(refOpDOrderList: OrderModel[]): number[] {
    const result: number[] = [];
    refOpDOrderList.forEach(order => {
      if (order.ordProductReservationList.length > 0) {
        const reservation = order.ordProductReservationList.find(reserv => reserv.status === ReservationStatus.Active);
        if (reservation) {
          result.push(order.orgRefNo);
        }
      }
    });
    return result;
  }

  handleOperationStatusChangeResponse(response: ApiResponse) {
    if (response.error !== NO_ERROR_CODE) {
      this.popupService.showInfo(response.message);
    }
    this.refreshOperation();
  }

  removeUnit(data: any) {
    this.articleUpForRemoval = data;
    this.showRemoveUnit = true;
  }

  removeUnitPopupResolved(errorMessage?: string) {
    this.showRemoveUnit = false;
    if (errorMessage) {
      if (errorMessage === 'refresh') {
        this.refreshOperation();
      } else {
        this.popupService.showInfo(errorMessage).then(() => {
          this.refreshOperation();
        });
      }
    }
  }

  private removeRemoveUnitColumn(gridStructure: GridStructureViewInterface): void {
    if (gridStructure) {
      this.gridColumnHelperService.removeColumn(gridStructure.columnsConfigurations, TD_ACTION_FIELDS.removeUnit);
    }
  }
}
