"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var order_actions_1 = require("./order.actions");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var OrderEffects = /** @class */ (function () {
    function OrderEffects(actions$, orderService) {
        var _this = this;
        this.actions$ = actions$;
        this.orderService = orderService;
        this.loadCustomerOpenOrders$ = this.actions$
            .pipe(effects_1.ofType(order_actions_1.OrderActionTypes.LoadCustomerOpenOrders), operators_1.mergeMap(function (act) {
            return _this.orderService.getOrdersByCustomerAndOrgStatus(act.payload, td_enumerations_1.OrderOrgStatus.Open)
                .pipe(operators_1.map(function (response) { return ({ type: order_actions_1.OrderActionTypes.SetCustomerOpenOrders, payload: response }); }), operators_1.catchError(function () { return rxjs_1.of({ type: order_actions_1.OrderActionTypes.SetCustomerOpenOrders, payload: [] }); }));
        }));
        this.loadUserLastOpenOrder$ = this.actions$
            .pipe(effects_1.ofType(order_actions_1.OrderActionTypes.LoadUserLastOpenOrder), operators_1.mergeMap(function (act) {
            return _this.orderService.getOrder(act.orderKeyId, act.customerKeyId)
                .pipe(operators_1.map(function (response) { return ({ type: order_actions_1.OrderActionTypes.SetUserLastOpenOrder, payload: response.value }); }), operators_1.catchError(function () { return rxjs_1.of({ type: order_actions_1.OrderActionTypes.SetUserLastOpenOrder, payload: null }); }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], OrderEffects.prototype, "loadCustomerOpenOrders$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], OrderEffects.prototype, "loadUserLastOpenOrder$", void 0);
    return OrderEffects;
}());
exports.OrderEffects = OrderEffects;
