import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserIdleService, UserIdleConfig } from 'angular-user-idle';

import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';
import { SessionHelperService } from './shared/services/session-helper.service';
import { AppState } from '@core/data-layer/app.state';
import { selectLicense, selectSessionEnded } from '@core/data-layer/server-session/store/server-session.selectors';
import { TD_ERROR_TYPES, TD_DEFAULT_USER_IDLE_CONFIG, TD_QUERY_PARAMS } from '@core/data-layer/shared/models/td.constants';
import { LicenseBindingServiceInterface } from './core/license/services/license-binding.service.interface';
import { POConst } from './dashboard/models/production-overview.constants';
import { SetSessionEnded } from '@core/data-layer/server-session/store/server-session.actions';
import { TdPopupService } from './shared/components/td-popup/services/td-popup.service';
import { PreloadMediaService } from '@core/media/services/preload-media.service';

@Component({
  selector: 'td-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {

  sessionEnded = false;
  authenticationPage = false;
  allowOrderCart = false;
  licenseBinded = false;
  sessionEndedSubscription: Subscription;
  licenseSubscription: Subscription;
  displayPopup = false;

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    @Inject('LICENSE_BINDING_SERVICE') private licenseBindingService: LicenseBindingServiceInterface,
    public sessionHelperService: SessionHelperService,
    private router: Router,
    private store: Store<AppState>,
    private userIdle: UserIdleService,
    public popupService: TdPopupService,
    private cdRef: ChangeDetectorRef,
    private preloadMediaService: PreloadMediaService
  ) {
    this.sessionEndedSubscription = this.store.pipe(select(selectSessionEnded)).subscribe(ended => {
      this.sessionEnded = ended;
    });
    router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      this.authenticationPage = event.url.includes('/signin-callback') || event.url.includes('/signout-callback');
      this.setupLicenseBinding(event.url);
    });
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.allowOrderCart = this.sessionHelperService.urlAllowsOrderCart(event.url);
    });
  }

  ngOnInit() {
    this.authService.initUserManager(this.sessionHelperService.clientConfiguration.authPopup);
    this.preloadImages();
    this.popupService.displayPopup.subscribe(change => {
      this.displayPopup = change;
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.sessionEndedSubscription) {
      this.sessionEndedSubscription.unsubscribe();
    }
    if (this.licenseSubscription) {
      this.licenseSubscription.unsubscribe();
    }
  }

  setupLicenseBinding(url: string) {
    // we don't setup license binding for:
    // - authentication page(s);
    // - production-overview page shown from TDAdmin and ScannerClient.
    if (!this.licenseBinded && !this.authenticationPage && !url.includes(POConst.TDOC_APP_TYPE + '=')) {
      this.licenseBinded = true;
      this.licenseBindingService.startConnectionAndLoadLicense();
      this.checkAvailabilityOfLicense();
      this.setupUserIdleConfiguration();
      this.setupUserIdleWatching();
    }
  }

  setupUserIdleConfiguration(): void {
    if (this.sessionHelperService.clientConfiguration.sessionTimeout > 0) {
      const idleConfig = new UserIdleConfig();
      // set session timeout (in seconds)
      idleConfig.idle = this.sessionHelperService.clientConfiguration.sessionTimeout * 60;
      // how often system should check end of session (in seconds)
      idleConfig.ping = idleConfig.idle / TD_DEFAULT_USER_IDLE_CONFIG.frequencyOfPing;
      // how long should we wait after "idle-time" passed and only then redirect to SessionEnded page (in seconds)
      idleConfig.timeout = TD_DEFAULT_USER_IDLE_CONFIG.timeout; // we should set value bigger then 0, otherwise it will take default not 0
      this.userIdle.setConfigValues(idleConfig);
    }
  }

  setupUserIdleWatching(): void {
    // start watching for user inactivity
    this.userIdle.startWatching();
    // start watching when user idle is starting
    this.userIdle.onTimerStart().subscribe(count => {
      // TODO: we should show popup with counter that inform user how many seconds left till user will be auto-logout
    });
    // start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      if (this.authService.isAuthenticated()) {
        this.authService.startSignout();
      }
      this.navigateToSessionEndedPage();
    });
  }

  checkAvailabilityOfLicense(): void {
    // check availability of license
    this.licenseSubscription = this.store.pipe(select(selectLicense)).subscribe(license => {
      if (license) {
        // we are redirecting to home page only when it is NOT related to "missed module in license"
        if (this.router.url.includes('/error') && !this.router.url.includes(TD_QUERY_PARAMS.missedModule + '=')) {
          this.router.navigate(['/']);
        }
      }
      else if (this.sessionEnded) {
        this.store.dispatch(new SetSessionEnded(false));
        this.navigateToSessionEndedPage();
      }
    });
  }

  navigateToSessionEndedPage() {
    this.licenseBindingService.stopConnection();
    this.router.navigate(['/error'], { skipLocationChange: true, queryParams: { type: TD_ERROR_TYPES.sessionEnded } });
  }

  preloadImages(): void {
    this.preloadMediaService.preloadAllImages();
  }
}
