<div class="margin10 auth-customers">
    <div class="pl-4 pr-4">
        <div class="paddingB20 page-title">{{'appCommon.selectCustomer' | translate}}</div>
        <ul class="list-group d-inline-flex flex-column"
          *ngIf="!loadPanelService.loadPanelParams.visible">
          <li class="list-group-item"
            *ngFor="let customer of customers$ | async"
            (click)="onCustomerSelected(customer)">
            {{ customer.name }}
          </li>
        </ul>
    </div>
</div>
