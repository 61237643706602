"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#orderCartContainer[_ngcontent-%COMP%]{padding:0;background-color:var(--color-cart-background)}#orderCartContainer[_ngcontent-%COMP%]     .dx-lookup{text-align:right;line-height:1}#expandedCart[_ngcontent-%COMP%]{width:300px}#collapsedCart[_ngcontent-%COMP%]{width:50px;height:320px}#orderCartContainer[_ngcontent-%COMP%]   .col-sm-12[_ngcontent-%COMP%]{padding-left:10px;padding-right:10px}.header-cart[_ngcontent-%COMP%]{font-size:var(--font-medium);font-weight:700;white-space:nowrap}.vertical-text[_ngcontent-%COMP%]{position:absolute;width:230px;top:150px;right:-90px;-webkit-transform:rotate(270deg);-moz-transform:rotate(270deg);-o-transform:rotate(270deg);-ms-transform:rotate(270deg);ms-transform:rotate(270deg);transform:rotate(270deg)}.collapsed-cart-img[_ngcontent-%COMP%]{position:absolute;bottom:0;padding-left:3px}  .dx-popup-content .dx-lookup-search-wrapper{padding:5px}  .dx-popup-content .dx-empty-message{text-align:center}  .dx-lookup-popup-search .dx-list{height:calc(100% - 50px)}  .dx-lookup-popup-wrapper .dx-list-item-content{text-align:right}"];
exports.styles = styles;
