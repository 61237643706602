"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProdOverviewData = /** @class */ (function () {
    function ProdOverviewData() {
        this.machineList = null;
        this.userList = null;
        this.kpiAmountDict = null;
        this.sterilizersList = null;
        this.washersList = null;
        this.endoscopesList = null;
        this.dryersList = null;
        this.lastUpdate = null;
        this.hasConnection = null;
        this.sterilizersList = new Array();
        this.washersList = new Array();
        this.endoscopesList = new Array();
        this.dryersList = new Array();
    }
    return ProdOverviewData;
}());
exports.ProdOverviewData = ProdOverviewData;
