export class ErrorReportModel {
  message: string;
  time: Date;
  deviceInfo: string;
  browserURL: string;
  exceptionClass: string;
  exceptionMessage: string;
  stacktrace?: string;
  traceId?: string;

  constructor(
    message: string,
    browserURL: string,
    exceptionClass: string,
    exceptionMessage: string,
    stacktrace?: string,
    traceId?: string
  ) {
    this.message = message;
    this.time = new Date();
    this.deviceInfo = window.navigator.userAgent;
    this.browserURL = browserURL;
    this.exceptionClass = exceptionClass;
    this.exceptionMessage = exceptionMessage;
    if (stacktrace) {
      this.stacktrace = stacktrace;
    }
    if (traceId) {
      this.traceId = traceId;
    }
  }
}
