"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./td-loading.component");
var i2 = require("../td-load-panel/services/td-load-panel.service");
var styles_LoadingComponent = [];
var RenderType_LoadingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingComponent, data: {} });
exports.RenderType_LoadingComponent = RenderType_LoadingComponent;
function View_LoadingComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
exports.View_LoadingComponent_0 = View_LoadingComponent_0;
function View_LoadingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "td-loading", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i0.ɵdid(1, 180224, null, 0, i1.LoadingComponent, [i2.TdLoadPanelService], null, null)], null, null); }
exports.View_LoadingComponent_Host_0 = View_LoadingComponent_Host_0;
var LoadingComponentNgFactory = i0.ɵccf("td-loading", i1.LoadingComponent, View_LoadingComponent_Host_0, {}, {}, []);
exports.LoadingComponentNgFactory = LoadingComponentNgFactory;
