import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { UnitServiceInterface } from './unit.service.interface';
import { UnitFastTrackInfoModel } from '../models/unit-fast-track-info.model';
import { UnitModel } from '../models/unit.model';
import { UnitViewModel } from '../models/unit-view.model';
import { UnitHelperService } from './unit-helper.service';
import { StandardCommentModel } from '@core/data-layer/standard-comment/models/standard-comment.model';
import { StandardCommentLinkModel } from '@core/data-layer/standard-comment-link/models/standard-comment-link.model';
import { StandardCommentLinkArgsModel } from '../models/comment-args.model';
import { ApiResponse, ApiDataResponse } from '@core/data-layer/shared/models/api-response.model';
import { FastTrackArgsInterface } from '../models/fast-track-args.interface';
import { BaseGridArgsModel } from '@core/data-layer/shared/models/base-grid-args.model';
import { GridDataSetResponse } from '@core/data-layer/shared/models/grid-data-response.interface';
import { EventModel } from '@core/data-layer/event/models/event.model';
import { UtilityService } from '@core/utility/utility.service';
import { FastTrackCodeModel } from '@core/data-layer/fast-track-code/models/fast-track-code.model';

@Injectable()
export class HttpUnitService implements UnitServiceInterface {

  constructor(
    private http: HttpClient,
    private unitHelperService: UnitHelperService,
    private utilityService: UtilityService
  ) { }

  unitsUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.unitsCtrl;
  }

  getUnit(unitKeyId: number): Observable<UnitViewModel> {
    return this.http.get<UnitModel>(this.unitsUrl() + `(${unitKeyId})` + API_HUB_PATHS.getDetails).pipe(
      map(response => this.unitHelperService.convertUnitToViewModel(response))
    );
  }

  searchByUnit(unitKeyId: number): Observable<UnitModel[]> {
    return this.http.get<UnitModel[]>(`${this.unitsUrl()}${API_HUB_PATHS.searchByUnit}(${unitKeyId})`);
  }

  getFastTrackInfo(unitKeyId: number): Observable<UnitFastTrackInfoModel> {
    return this.http.get<UnitFastTrackInfoModel>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.getFastTrackInfo}`);
  }

  getFastTrackCodes(): Observable<Array<FastTrackCodeModel>> {
    return this.http.get<Array<FastTrackCodeModel>>(this.unitsUrl() + API_HUB_PATHS.fastTrackCodes);
  }

  addFastTrackCode(unitKeyId: number, customerKeyId: number, fastTrackCodeKeyId: number): Observable<ApiResponse> {
    const params: FastTrackArgsInterface = { customerKeyId, fastTrackCodeKeyId };
    return this.http.post<ApiResponse>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.addFastTrackCode}`, params);
  }

  cancelFastTrackCode(unitKeyId: number, fastTrackCodeKeyId: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.cancelFastTrackCode}(${fastTrackCodeKeyId})`, null);
  }

  customerCanFastTrack(unitKeyId: number, customerKeyId: number, fastTrackCodeKeyId: number): Observable<ApiDataResponse<boolean>> {
    const params: FastTrackArgsInterface = { customerKeyId, fastTrackCodeKeyId };
    return this.http.post<ApiDataResponse<boolean>>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.customerCanFastTrack}`, params);
  }

  hasFastTrackCode(unitKeyId: number, fastTrackCodeKeyId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.hasFastTrackCode}(${fastTrackCodeKeyId})`);
  }

  getCommentTypes(factoryKeyId: number): Observable<StandardCommentModel[]> {
    return this.http.get<StandardCommentModel[]>(`${this.unitsUrl()}${API_HUB_PATHS.getStandardCommentTypes}?factoryKeyId=${factoryKeyId}`);
  }

  getComments(unitKeyId: number): Observable<StandardCommentLinkModel[]> {
    return this.http.get<StandardCommentLinkModel[]>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.getStandardComments}`);
  }

  addComment(unitKeyId: number, commentArgs: StandardCommentLinkArgsModel): Observable<number> {
    return this.http.post<number>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.addStandardComment}`, commentArgs);
  }

  getEventList(requestArgs: BaseGridArgsModel, unitKeyId: number): Observable<GridDataSetResponse<EventModel>> {
    return this.http.post<GridDataSetResponse<EventModel>>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.eventList}`, requestArgs).pipe(
      map(response => {
        response.dataSet.forEach(event => {
          event.time = this.utilityService.convertValueToDate(event.time);
        });
        return response;
      })
    );
  }

  unitHasAwaitingIndicator(unitKeyId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.unitsUrl()}(${unitKeyId})${API_HUB_PATHS.awaitingIndicator}`);
  }
}
