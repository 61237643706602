"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var data_source_1 = require("devextreme/data/data_source");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var base_grid_args_model_1 = require("@core/data-layer/shared/models/base-grid-args.model");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var i0 = require("@angular/core");
var GridDataHelperService = /** @class */ (function () {
    function GridDataHelperService() {
    }
    GridDataHelperService.prototype.requestGridData = function (requestArgs, getData, keyId, afterLoaded, keyField) {
        var _this = this;
        return new data_source_1.default({
            key: keyField,
            requireTotalCount: true,
            load: function (loadOptions) {
                if (loadOptions.sort) {
                    var orderByFields_1 = new Array();
                    loadOptions.sort.forEach(function (sortFieldOptions) {
                        orderByFields_1.push(new base_grid_args_model_1.OrderByArgsModel(sortFieldOptions.selector, (sortFieldOptions.desc ? td_enumerations_1.FuzzySearchSortOrder.Desc : td_enumerations_1.FuzzySearchSortOrder.Asc)));
                    });
                    requestArgs.orderByFields = Object.assign([], orderByFields_1);
                }
                if (requestArgs.paginationArgs.pageRowCount !== td_constants_1.TD_MAX_INT32_VALUE - 1) {
                    if (loadOptions.take) {
                        requestArgs.paginationArgs.pageRowCount = loadOptions.take;
                    }
                    else {
                        requestArgs.paginationArgs.pageRowCount = td_constants_1.TD_VALUES.detailsGridRowsPerPage;
                    }
                    requestArgs.paginationArgs.startingRow = loadOptions.skip;
                }
                return getData(requestArgs, keyId)
                    .toPromise()
                    .then(function (result) {
                    var loadResult = {
                        data: _this.getDataSet(result),
                        totalCount: _this.getTotalCount(result)
                    };
                    if (afterLoaded) {
                        afterLoaded(loadResult, result.columnsConfigurations ? result.columnsConfigurations : undefined);
                    }
                    return loadResult;
                });
            },
            byKey: function (dataRow) {
                return rxjs_1.of(dataRow);
            }
        });
    };
    GridDataHelperService.prototype.getDataSet = function (result) {
        if (result.value) {
            // For ApiDataResponse<SearchResultViewModel> result
            return result.value.dataSet.mappedRows;
        }
        else if (result.dataSet.mappedRows) {
            // For SearchResultViewModel and ExtendedSearchResultViewModel result
            return result.dataSet.mappedRows;
        }
        else {
            // For GridDataSetResponse<any> result
            return result.dataSet;
        }
    };
    GridDataHelperService.prototype.getTotalCount = function (result) {
        if (result.value) {
            // For ApiDataResponse<SearchResultViewModel> result
            return result.value.paginationArgs.totalCountOfRows;
        }
        else if (result.dataSet.mappedRows) {
            // For SearchResultViewModel and ExtendedSearchResultViewModel result
            return result.paginationArgs.totalCountOfRows;
        }
        else {
            // For GridDataSetResponse<any> result
            return result.totalCountOfRows;
        }
    };
    GridDataHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GridDataHelperService_Factory() { return new GridDataHelperService(); }, token: GridDataHelperService, providedIn: "root" });
    return GridDataHelperService;
}());
exports.GridDataHelperService = GridDataHelperService;
