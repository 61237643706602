import { Component, Input } from '@angular/core';

@Component({
  selector: 'td-details-property',
  templateUrl: './td-details-property.component.html',
  styleUrls: ['./td-details-property.component.less']
})
export class TdDetailsPropertyComponent {

  @Input() id: string;
  @Input() caption: string;
  @Input() value: string;
}
