"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var platform_browser_1 = require("@angular/platform-browser");
var angular_user_idle_1 = require("angular-user-idle");
var core_2 = require("@ngx-translate/core");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent(licenseService, licenseBindingService, activatedRoute, userIdle, titleService, translateService) {
        this.licenseService = licenseService;
        this.licenseBindingService = licenseBindingService;
        this.activatedRoute = activatedRoute;
        this.userIdle = userIdle;
        this.titleService = titleService;
        this.translateService = translateService;
        this.noAvailableLicense = false;
        this.sessionEnded = false;
        this.moduleNotIncluded = false;
    }
    ErrorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(function (queryParams) {
            _this.showErrorBlock();
        });
    };
    ErrorComponent.prototype.ngOnDestroy = function () {
        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
        }
    };
    ErrorComponent.prototype.showErrorBlock = function () {
        var _this = this;
        // check either error should be shown
        var errorType = this.activatedRoute.snapshot.queryParams[td_constants_1.TD_QUERY_PARAMS.type];
        if (errorType) {
            this.sessionEnded = false;
            this.noAvailableLicense = false;
            switch (errorType) {
                case td_constants_1.TD_ERROR_TYPES.noAvailableLicense:
                    this.titleService.setTitle(this.translateService.instant('license.noLicense'));
                    this.licenseService.getLicenseBindings().subscribe(function (bindings) {
                        _this.maximumCount = bindings.maximum;
                        _this.remainingCount = bindings.remaining;
                    });
                    this.noAvailableLicense = true;
                    break;
                case td_constants_1.TD_ERROR_TYPES.sessionEnded:
                    this.titleService.setTitle(this.translateService.instant('session.tDocSessionEnded'));
                    this.sessionEnded = true;
                    break;
            }
        }
        // validation for missed modules in license
        this.moduleNotIncluded = false;
        var missedModule = this.activatedRoute.snapshot.queryParams[td_constants_1.TD_QUERY_PARAMS.missedModule];
        if (missedModule) {
            switch (missedModule) {
                case td_constants_1.TD_MODULES.operation:
                    this.titleService.setTitle(this.translateService.instant('license.noLicense'));
                    this.missedPageName = this.translateService.instant('appCommon.scheduledOperations');
                    this.moduleNotIncluded = true;
                    break;
            }
        }
    };
    ErrorComponent.prototype.tryAgain = function () {
        this.newSession();
    };
    ErrorComponent.prototype.newSession = function () {
        this.licenseBindingService.startConnectionAndLoadLicense();
        this.userIdle.resetTimer();
    };
    return ErrorComponent;
}());
exports.ErrorComponent = ErrorComponent;
