import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TD_HELP_ARTICLES } from '@app/core/data-layer/shared/models/td.constants';
import { LanguageService } from '@app/core/language/services/language.service';

@Component({
  selector: 'td-help',
  templateUrl: 'help.component.html',
  styleUrls: ['help.component.less']
})

export class HelpComponent implements OnInit {
  
  @Input() set routerUrl(url: string) {
    this.updateHelpArticleTarget(url);
  }
  currentHelpArticleId: number = TD_HELP_ARTICLES[0].articleId;
  allHelpArticles = TD_HELP_ARTICLES;
  helpProjectUrl: string;

  constructor(
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.setHelpLanguage();
   }

  help(): void {    
    window.open(`${this.helpProjectUrl}${this.currentHelpArticleId}`, '_blank');
  }

  private setHelpLanguage(): void {
    const desiredLanguage = this.languageService.getCurrentLanguage();
    const helpProjectBaseUrl = 'Help';
    const helpProjectDefaultArticle = 'Default.htm#cshid=';
    let helpProjectLanguage = this.selectProjectPathFromCulture(desiredLanguage);
    this.helpProjectUrl = `${helpProjectBaseUrl}/${helpProjectLanguage}/${helpProjectDefaultArticle}`;    
  }

  private selectProjectPathFromCulture(culture: string): string {
    switch (culture) {
      case 'en-US': return 'us';
      /**
       * These matches are disabled, as we do not currently have the help projected approved in any other languages.
       * When any new language is accepted and added to the web project, we should append a case for it here
       */
      // case 'da-DK': return 'dk';
      // case 'de-DE': return 'de';
      // case 'fr-FR': return 'fr';
      // case 'sv-SE': return 'se';
      default: return 'us';
    }
  }

  private updateHelpArticleTarget(url: string): void {
    const splitUrl = url.split('/');
    let targetUrl = splitUrl.length > 2 ? `${splitUrl[1]}/:id` : splitUrl[1];
    if (targetUrl.indexOf('?')) {
      targetUrl = targetUrl.split('?')[0];
    }
    const newTarget = this.allHelpArticles.find(entry => entry.url === targetUrl);
    if (newTarget) {
      this.currentHelpArticleId = newTarget.articleId;
    }
  }
}