"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./header.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/router");
var i4 = require("@ngx-translate/core");
var i5 = require("../shared/components/td-search-filters-button/td-search-filters-button.component.ngfactory");
var i6 = require("../shared/components/td-search-filters-button/td-search-filters-button.component");
var i7 = require("../shared/services/session-helper.service");
var i8 = require("../shared/components/td-popup/services/td-popup.service");
var i9 = require("../shared/services/search-filters-helper.service");
var i10 = require("../../../node_modules/devextreme-angular/ui/text-box/devextreme-angular-ui-text-box.ngfactory");
var i11 = require("@angular/forms");
var i12 = require("devextreme-angular/ui/text-box");
var i13 = require("devextreme-angular/core");
var i14 = require("@angular/platform-browser");
var i15 = require("../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i16 = require("devextreme-angular/ui/button");
var i17 = require("../../../node_modules/devextreme-angular/ui/drop-down-button/devextreme-angular-ui-drop-down-button.ngfactory");
var i18 = require("devextreme-angular/ui/drop-down-button");
var i19 = require("../help/help.component.ngfactory");
var i20 = require("../help/help.component");
var i21 = require("../core/language/services/language.service");
var i22 = require("../settings/settings.component.ngfactory");
var i23 = require("../settings/settings.component");
var i24 = require("@ngrx/store");
var i25 = require("./header.component");
var i26 = require("angular-user-idle");
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
exports.RenderType_HeaderComponent = RenderType_HeaderComponent;
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "navbar-nav d-flex mr-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "]))], null, null); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["class", "nav-link cursorPointer"], ["id", "linkOrders"], ["routerLink", "orders"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "bold-nav-item": 0 }), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav-link cursorPointer"; var currVal_3 = _ck(_v, 4, 0, (_co.activePage == _co.allActivePages.Orders)); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = "orders"; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("appCommon.orders")); _ck(_v, 6, 0, currVal_5); }); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["class", "nav-link cursorPointer"], ["id", "linkTemplates"], ["routerLink", "order-templates"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "bold-nav-item": 0 }), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav-link cursorPointer"; var currVal_3 = _ck(_v, 4, 0, (_co.activePage == _co.allActivePages.Templates)); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = "order-templates"; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("appCommon.templates")); _ck(_v, 6, 0, currVal_5); }); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["class", "nav-link cursorPointer"], ["id", "linkOperations"], ["routerLink", "operations"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "bold-nav-item": 0 }), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav-link cursorPointer"; var currVal_3 = _ck(_v, 4, 0, (_co.activePage == _co.allActivePages.Operations)); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = "operations"; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("appCommon.scheduledOperations")); _ck(_v, 6, 0, currVal_5); }); }
function View_HeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "nav-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["class", "nav-link cursorPointer"], ["id", "linkPO"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "bold-nav-item": 0 }), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(6, { IOCKEYID: 0 }), i1.ɵpad(7, 1), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav-link cursorPointer"; var currVal_3 = _ck(_v, 4, 0, (_co.activePage == _co.allActivePages.ProductionOverview)); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _ck(_v, 6, 0, _co.defaultIOConfigId); var currVal_5 = _ck(_v, 7, 0, "production-overview"); _ck(_v, 5, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("appCommon.prodOverview")); _ck(_v, 8, 0, currVal_6); }); }
function View_HeaderComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pl-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td-search-filters-button", [], null, null, null, i5.View_TdSearchFiltersButtonComponent_0, i5.RenderType_TdSearchFiltersButtonComponent)), i1.ɵdid(2, 180224, null, 0, i6.TdSearchFiltersButtonComponent, [i7.SessionHelperService, i8.TdPopupService, i9.SearchFiltersHelperService], { darkMode: [0, "darkMode"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "d-flex align-items-center justify-content-center flex-grow-1"], ["id", "searchDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "dx-text-box", [["class", "search-box"], ["id", "searchTextBox"], ["width", "25%"]], null, [[null, "valueChange"], [null, "keyup.enter"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.searchText = $event) !== false);
        ad = (pd_2 && ad);
    } if (("keyup.enter" === en)) {
        var pd_3 = (_co.submitSearch() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i10.View_DxTextBoxComponent_0, i10.RenderType_DxTextBoxComponent)), i1.ɵprd(5120, null, i11.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i12.DxTextBoxComponent]), i1.ɵprd(512, null, i13.DxTemplateHost, i13.DxTemplateHost, []), i1.ɵprd(512, null, i13.WatcherHelper, i13.WatcherHelper, []), i1.ɵprd(512, null, i13.IterableDifferHelper, i13.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i13.NestedOptionHost, i13.NestedOptionHost, []), i1.ɵdid(7, 7323648, [["headerSearchBox", 4]], 1, i12.DxTextBoxComponent, [i1.ElementRef, i1.NgZone, i13.DxTemplateHost, i13.WatcherHelper, i13.IterableDifferHelper, i13.NestedOptionHost, i14.TransferState, i1.PLATFORM_ID], { placeholder: [0, "placeholder"], value: [1, "value"], width: [2, "width"] }, { valueChange: "valueChange", onBlur: "onBlur" }), i1.ɵqud(603979776, 2, { buttonsChildren: 1 }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 5, "dx-button", [["class", "inputfield-button action-icon-button"], ["icon", "search"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.submitSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_DxButtonComponent_0, i15.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i13.DxTemplateHost, i13.DxTemplateHost, []), i1.ɵprd(512, null, i13.WatcherHelper, i13.WatcherHelper, []), i1.ɵprd(512, null, i13.NestedOptionHost, i13.NestedOptionHost, []), i1.ɵdid(14, 7323648, null, 0, i16.DxButtonComponent, [i1.ElementRef, i1.NgZone, i13.DxTemplateHost, i13.WatcherHelper, i13.NestedOptionHost, i14.TransferState, i1.PLATFORM_ID], { hint: [0, "hint"], icon: [1, "icon"] }, { onClick: "onClick" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_9)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform("appCommon.searchPlaceholder")), ""); var currVal_1 = _co.searchText; var currVal_2 = "25%"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("appCommon.search")), ""); var currVal_4 = "search"; _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_5 = _co.sessionHelperService.loggedIn(); _ck(_v, 17, 0, currVal_5); }, null); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "ul", [["class", "navbar-nav d-flex mr-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_7)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_8)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionHelperService.canViewOrder(); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.sessionHelperService.canViewOrder() && _co.sessionHelperService.license.stockExtended); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.sessionHelperService.canViewOperation(); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.sessionHelperService.canViewProductionOverview(); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.allowSearch; _ck(_v, 11, 0, currVal_4); }, null); }
function View_HeaderComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "user-profile-picture"], ["src", ".\\assets\\img\\default-user.svg"]], null, null, null, null, null))], null, null); }
function View_HeaderComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "user-profile-picture"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userProfilePicture; _ck(_v, 0, 0, currVal_0); }); }
function View_HeaderComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "dx-icon dx-icon-", _v.parent.context.$implicit.icon, " dx-list-item-icon"); _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "dx-icon dx-list-item-icon"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["title", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "align-middle dx-list-item-icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_15)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_16)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.value === 0); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.value === 1); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_HeaderComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "li", [["class", "nav-item d-flex align-items-center mr-3 header-user-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "d-flex cursorPointer align-items-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerDropDownSelect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "d-flex flex-column user-data-menu align-items-end mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "user-initials"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "user-customer"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_12)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_13)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 8, "dx-drop-down-button", [["class", "drop-down-button"], ["displayExpr", "name"], ["height", "100%"], ["hoverStateEnabled", "false"], ["id", "userName"], ["itemTemplate", "listItem"], ["keyExpr", "id"], ["stylingMode", "text"]], null, [[null, "onItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onItemClick" === en)) {
        var pd_0 = (_co.dropDownSelect($event.itemData.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_DxDropDownButtonComponent_0, i17.RenderType_DxDropDownButtonComponent)), i1.ɵprd(512, null, i13.DxTemplateHost, i13.DxTemplateHost, []), i1.ɵprd(512, null, i13.WatcherHelper, i13.WatcherHelper, []), i1.ɵprd(512, null, i13.IterableDifferHelper, i13.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i13.NestedOptionHost, i13.NestedOptionHost, []), i1.ɵdid(16, 7323648, [[1, 4], ["userNameElement", 4]], 1, i18.DxDropDownButtonComponent, [i1.ElementRef, i1.NgZone, i13.DxTemplateHost, i13.WatcherHelper, i13.IterableDifferHelper, i13.NestedOptionHost, i14.TransferState, i1.PLATFORM_ID], { displayExpr: [0, "displayExpr"], height: [1, "height"], hoverStateEnabled: [2, "hoverStateEnabled"], items: [3, "items"], itemTemplate: [4, "itemTemplate"], keyExpr: [5, "keyExpr"], stylingMode: [6, "stylingMode"], useSelectMode: [7, "useSelectMode"] }, { onItemClick: "onItemClick" }), i1.ɵqud(603979776, 3, { itemsChildren: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_14)), i1.ɵdid(19, 16384, null, 0, i13.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i13.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.userProfilePicture; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.userProfilePicture; _ck(_v, 10, 0, currVal_3); var currVal_4 = "name"; var currVal_5 = "100%"; var currVal_6 = "false"; var currVal_7 = _co.profileSettings; var currVal_8 = "listItem"; var currVal_9 = "id"; var currVal_10 = "text"; var currVal_11 = false; _ck(_v, 16, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = "listItem"; _ck(_v, 19, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userInitials; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.userCustomer; _ck(_v, 6, 0, currVal_1); }); }
function View_HeaderComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "nav-item d-flex align-items-center mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "dx-button", [["class", "header-icon-button tertiary-button-dark-mode"], ["icon", ".\\assets\\img\\Log_out.svg"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_DxButtonComponent_0, i15.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i13.DxTemplateHost, i13.DxTemplateHost, []), i1.ɵprd(512, null, i13.WatcherHelper, i13.WatcherHelper, []), i1.ɵprd(512, null, i13.NestedOptionHost, i13.NestedOptionHost, []), i1.ɵdid(5, 7323648, null, 0, i16.DxButtonComponent, [i1.ElementRef, i1.NgZone, i13.DxTemplateHost, i13.WatcherHelper, i13.NestedOptionHost, i14.TransferState, i1.PLATFORM_ID], { hint: [0, "hint"], icon: [1, "icon"] }, { onClick: "onClick" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("appCommon.logout")), ""); var currVal_1 = ".\\assets\\img\\Log_out.svg"; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_HeaderComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "nav-item d-flex align-items-center mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "dx-button", [["class", "header-icon-button tertiary-button-dark-mode"], ["icon", ".\\assets\\img\\Log_in.svg"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_DxButtonComponent_0, i15.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i13.DxTemplateHost, i13.DxTemplateHost, []), i1.ɵprd(512, null, i13.WatcherHelper, i13.WatcherHelper, []), i1.ɵprd(512, null, i13.NestedOptionHost, i13.NestedOptionHost, []), i1.ɵdid(5, 7323648, null, 0, i16.DxButtonComponent, [i1.ElementRef, i1.NgZone, i13.DxTemplateHost, i13.WatcherHelper, i13.NestedOptionHost, i14.TransferState, i1.PLATFORM_ID], { hint: [0, "hint"], icon: [1, "icon"] }, { onClick: "onClick" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("appCommon.login")), ""); var currVal_1 = ".\\assets\\img\\Log_in.svg"; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_HeaderComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ul", [["class", "navbar-nav d-flex ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_11)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_17)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_18)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "li", [["class", "nav-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td-help", [], null, null, null, i19.View_HelpComponent_0, i19.RenderType_HelpComponent)), i1.ɵdid(9, 114688, null, 0, i20.HelpComponent, [i21.LanguageService], { routerUrl: [0, "routerUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionHelperService.isAuthenticated; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.sessionHelperService.isAuthenticated; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.sessionHelperService.isAuthenticated; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.routerUrl; _ck(_v, 9, 0, currVal_3); }, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "nav", [["class", "navbar navbar-expand"], ["id", "headerNavBar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "d-flex flex-column w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "d-flex flex-row"], ["id", "divBottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "cursorPointer w-auto h-auto d-flex align-items-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Logo"], ["class", "img-fluid"], ["id", "imgLogo"], ["src", ".\\assets\\img\\Program_icon_T-DOC.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_10)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noActionsHeader; _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.noActionsHeader; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.noActionsHeader; _ck(_v, 10, 0, currVal_2); }, null); }
function View_HeaderComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-settings", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.settingsVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i22.View_SettingsComponent_0, i22.RenderType_SettingsComponent)), i1.ɵdid(1, 49152, null, 0, i23.SettingsComponent, [i24.Store], { visible: [0, "visible"] }, { visibleChange: "visibleChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settingsVisible; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { userDropDown: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_19)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.headerHidden; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.settingsVisible; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_HeaderComponent_0 = View_HeaderComponent_0;
function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i25.HeaderComponent, ["AUTH_SERVICE", "LICENSE_BINDING_SERVICE", "MEDIA_SERVICE", i7.SessionHelperService, i3.Router, i3.ActivatedRoute, i26.UserIdleService, i4.TranslateService, i8.TdPopupService, i24.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HeaderComponent_Host_0 = View_HeaderComponent_Host_0;
var HeaderComponentNgFactory = i1.ɵccf("td-header", i25.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
exports.HeaderComponentNgFactory = HeaderComponentNgFactory;
