import { Params } from "@angular/router";
import { TD_QUERY_PARAMS } from "@app/core/data-layer/shared/models/td.constants";
import { RecentSearchInterface } from "@app/core/search/models/recent-search.interface";
import { SearchParamsModel, SearchParamTypes } from "./param-models";
import { ParamSearchFactoryInterface } from "./param-search-factory.interface";

export class ArticleSearchFactory implements ParamSearchFactoryInterface {

  createSearchModel(): SearchParamsModel {
    return {
      url: '/search',
      properties: [
        {
          urlParamName: TD_QUERY_PARAMS.text,
          type: SearchParamTypes.String
        }
      ]
    }
  }

  /**
   * NB: This is never used - As you cannot access search directly, only by performing a search
   * But to obey the interface, a method is built for it
   * @returns search model with empty string
   */
  createDefaultSearchModel(): SearchParamsModel {
    return {
      url: '/search',
      properties: [
        {
          urlParamName: TD_QUERY_PARAMS.text,
          type: SearchParamTypes.String
        }
      ]
    }
  }

  getRecentSearchAsParams(recentSearch: RecentSearchInterface): Params {
    throw new Error("Method not implemented.");
  }

  /**
   * NB: This is never used - As you cannot access search directly, only by performing a search
   * But to obey the interface, a method is built for it
   * @returns default search params model
   */
  getEmptyParamsSearchModel(): SearchParamsModel {
    return this.createDefaultSearchModel();
  }
}
