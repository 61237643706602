import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { ServerSettingsComponent } from './components/server-settings/server-settings.component';
import { AppState } from '@core/data-layer/app.state';
import { LoadServerDesktopOptions } from '@core/data-layer/server-session/store/server-session.actions';
import { TdSearchFiltersComponent } from '@shared/components/td-search-filters/td-search-filters.component';

@Component({
  selector: 'td-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent {

  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('serverSettings', { static: false }) serverSettings: ServerSettingsComponent;
  @ViewChild('searchFilters', { static: false }) searchFilters: TdSearchFiltersComponent;

  settingsChanged: boolean;
  filtersChanged: boolean;

  serverSettingsTemplate = 'serverSettings';
  searchFiltersTemplate = 'searchFilters';

  settingsTabs = [
    {
      title: 'settings.searchFilters',
      template: this.searchFiltersTemplate
    },
    {
    title: 'settings.serverSettings',
    template: this.serverSettingsTemplate
    }
  ];

  constructor(private store: Store<AppState>) { }

  async apply() {
    if (this.settingsChanged) {
      await this.serverSettings.saveServerOptions().then( () => this.store.dispatch(new LoadServerDesktopOptions()));
    }
    if (this.filtersChanged) {
      await this.searchFilters.saveSearchFilters();
    }
    this.visible = false;
  }

  cancel() {
    this.visible = false;
  }

  resetToDefault(template: string) {
    if (template === this.searchFiltersTemplate) {
      this.searchFilters.resetToDefault();
    }
    else if (template === this.serverSettingsTemplate) {
      this.serverSettings.resetToDefault();
    }
  }

}
