import { Injectable } from '@angular/core';

import { POConst } from '../models/production-overview.constants';
import { ProdOverviewData } from '../models/po-data.model';

@Injectable({
  providedIn: 'root'
})
export class InfoOverviewHelperService {

  adjustPOData(poData: ProdOverviewData): ProdOverviewData {
    if (poData) {
      if (poData.userList) {
        // to avoid caching of user's pictures we are forcing every request for the image to use a different URL
        for (const user of poData.userList) {
          if (user.userPicturePath) {
            user.userPicturePath = user.userPicturePath + '?' + (new Date()).getTime();
          }
        }
      }

      // split machines into two arrays: "Sterilizers", "Washers", "Endoscopes" and "Dryers"
      if (poData.machineList) {
        for (const machine of poData.machineList) {
          // to avoid caching of machine's pictures we are forcing every request for the image to use a different URL
          if (machine.statusImagePath) {
            machine.statusImagePath = machine.statusImagePath + '?' + (new Date()).getTime();
          }

          if (machine.machType === POConst.MachineType.STERILIZER) {
            poData.sterilizersList.push(machine);
          }
          else if (machine.machType === POConst.MachineType.WASHER) {
            poData.washersList.push(machine);
          }
          else if (machine.machType === POConst.MachineType.ENDO) {
            poData.endoscopesList.push(machine);
          }
          else if (machine.machType === POConst.MachineType.DRYER) {
            poData.dryersList.push(machine);
          }
        }
      }

      return poData;
    }
    else {
      return null;
    }
  }
}
