"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./td-details-property.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./td-details-property.component");
var styles_TdDetailsPropertyComponent = [i0.styles];
var RenderType_TdDetailsPropertyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TdDetailsPropertyComponent, data: {} });
exports.RenderType_TdDetailsPropertyComponent = RenderType_TdDetailsPropertyComponent;
function View_TdDetailsPropertyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row detail-property"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["for", "id"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.caption; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.id; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.value; _ck(_v, 5, 0, currVal_2); }); }
exports.View_TdDetailsPropertyComponent_0 = View_TdDetailsPropertyComponent_0;
function View_TdDetailsPropertyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-details-property", [], null, null, null, View_TdDetailsPropertyComponent_0, RenderType_TdDetailsPropertyComponent)), i1.ɵdid(1, 49152, null, 0, i2.TdDetailsPropertyComponent, [], null, null)], null, null); }
exports.View_TdDetailsPropertyComponent_Host_0 = View_TdDetailsPropertyComponent_Host_0;
var TdDetailsPropertyComponentNgFactory = i1.ɵccf("td-details-property", i2.TdDetailsPropertyComponent, View_TdDetailsPropertyComponent_Host_0, { id: "id", caption: "caption", value: "value" }, {}, []);
exports.TdDetailsPropertyComponentNgFactory = TdDetailsPropertyComponentNgFactory;
