"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var factoryActions = require("@core/data-layer/factory/store/factory.actions");
var factory_selectors_1 = require("@core/data-layer/factory/store/factory.selectors");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var FactoriesInitializer = /** @class */ (function () {
    function FactoriesInitializer(store) {
        this.store = store;
    }
    FactoriesInitializer.prototype.initializeAllFactories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.store.dispatch(new factoryActions.LoadAllFactories());
            _this.store.pipe(store_1.select(factory_selectors_1.selectAllFactories)).subscribe(function (factories) {
                if (factories) {
                    resolve(factories);
                }
            });
        });
    };
    FactoriesInitializer.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FactoriesInitializer_Factory() { return new FactoriesInitializer(i0.ɵɵinject(i1.Store)); }, token: FactoriesInitializer, providedIn: "root" });
    return FactoriesInitializer;
}());
exports.FactoriesInitializer = FactoriesInitializer;
