import { Component, Input, TemplateRef, Output, EventEmitter, ViewChild, Inject, OnInit, SimpleChanges, OnChanges, AfterViewInit, ChangeDetectionStrategy, SecurityContext, ElementRef } from '@angular/core';
import { DxListComponent } from 'devextreme-angular/ui/list';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxTreeListComponent } from 'devextreme-angular/ui/tree-list';
import { TranslateService } from '@ngx-translate/core';

import { GridPaginationInterface } from './models/grid-pagination.interface';
import { TableInfoServiceInterface } from '@core/table-info/services/table-info.service.interface';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { PropertyTypes } from '@core/table-info/models/grid-structure.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { OrderByArgsModel } from '@core/data-layer/shared/models/base-grid-args.model';
import { FuzzySearchSortOrder } from '@core/data-layer/shared/models/td.enumerations';
import { UtilityService } from '@core/utility/utility.service';
import { TD_DESKTOP_IDENTIFIERS, TD_VALUES } from '@core/data-layer/shared/models/td.constants';
import { GRID_TEMPLATES } from '@shared/models/app.constants';

@Component({
  selector: 'td-data-grid',
  templateUrl: './td-data-grid.component.html',
  styleUrls: ['./td-data-grid.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TdDataGridComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() mainTable: string;
  @Input() gridStructure: GridStructureViewInterface;
  @Input() data: any[];
  @Input() templates?: TemplateRef<any>[];
  @Input() pagination?: GridPaginationInterface;
  @Input() scrolling?: any = { mode: 'standard'}; // mode: 'standard' | 'virtual' | 'infinite'
  @Input() selection?: any = { mode: 'none' }; // mode: 'single' | 'multiple' | 'none'
  @Input() gridAlias: string;
  @Input() columnChooser?: boolean;
  @Input() remoteOperations?: boolean;
  @Input() highlightText?: string;
  @Input() masterDetail?: TemplateRef<any>;
  @Input() enableMasterDetail?: boolean;
  @Input() hideColumnHeaders: boolean;
  @Input() reload: boolean;
  @Input() enableStateStoring = true;
  @Input() keyExpression?: string;
  @Input() hoverStateEnabled?: boolean = false;
  @Input() wordWrapEnabled?: boolean = false;
  @Input() width?: string;
  @Input() height?: string;
  @Input() showBorders?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() noDataTranslationTerm?: string = 'appCommon.noData';
  // Inputs related to keyboard navigation
  @Input() focusRowEnabled? = false;
  @Input() focusedRowKey?: number;

  @Output() refreshGridData?: EventEmitter<null> = new EventEmitter();
  @Output() selectedRowChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowPrepared?: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() contentReady?: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() rowExpand?: EventEmitter<any> = new EventEmitter<any>();
  @Output() enterPressedOnFocusedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() escapeKeyUpWhileGridFocused: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dataGrid', { static: false }) grid: DxDataGridComponent;
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;
  @ViewChild(DxPopupComponent, { static: false }) popup: DxPopupComponent;
  @ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;
  @ViewChild('columnSelectorContainer', {static: false}) columnSelectorContainer: ElementRef;

  displaySettings = false;

  propertyTypes = PropertyTypes;
  GRID_TEMPLATES = GRID_TEMPLATES;

  // loadGridStateFunc;
  // saveGridStateFunc;
  loadGridStateFunc = this.loadGridState.bind(this);
  saveGridStateFunc = this.saveGridState.bind(this);

  // variable for keyboard navigation
  focusedRow: any;
  focusedRowKeyForDataGrid: number;

  /*
    Storing grid settings externally is not possible yet. Still awaiting response from DevExpress Support team on how this is intended to work in new version of Angular.
    For now, we store grid settings only in localStorage. Outcommented lines are related to external storing and should not be deleted
  */
  constructor(
    private sanitizer: DomSanitizer,
    @Inject('TABLE_INFO_SERVICE') private tableInfoService: TableInfoServiceInterface,
    public sessionHelperService: SessionHelperService,
    private utilityService: UtilityService,
    private translateService: TranslateService
  ) {
    this.cellTemplateHighlights = this.cellTemplateHighlights.bind(this);
  }

  ngOnInit(): void {
    this.determinePageSize();
    this.hideColumnHeaders = this.hideColumnHeaders ? this.hideColumnHeaders : false;
  }

  ngAfterViewInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['reload'] && this.grid) {
      this.grid.instance.repaint();
    }
    if (changes['gridStructure'] && changes['gridStructure'].currentValue) {      
      this.checkForCustomTemplate();
    }
    if (changes['data']) {
      if (this.grid && this.grid.instance) {
        this.grid.instance.collapseAll(-1);
      }
    }
    if (changes['focusRowEnabled'] && this.focusRowEnabled) {
      this.focusedRowKeyForDataGrid = this.focusedRowKey;
      if (this.grid && this.grid.instance && this.grid.instance.isRowFocused(this.focusedRowKey)) {
        this.grid.instance.refresh();
      }
      if (this.grid && this.grid.instance) {
        this.grid.instance.focus();
      }
    }
    if (changes['disabled'] && this.grid && this.grid.instance)  {
      const element = this.grid.instance.element();
      if (this.disabled) {
        element.classList.add('disabled-overlay');
      } else {
        element.classList.remove('disabled-overlay');
      }
    }
  }

  gridContentReady(e): void {
    if (this.columnSelectorContainer) {
      const pagerElement = e.element.getElementsByClassName('dx-pages') as HTMLCollectionOf<HTMLElement>;
      const size = this.columnSelectorContainer.nativeElement.offsetWidth + 11;
      // there should be only one 'dx-pages' per each grid
      if (pagerElement && pagerElement.length === 1) {
        pagerElement[0].style.marginRight = size + 'px';
      }
    }
    if (this.contentReady) {
      this.contentReady.emit(e);
    }
  }

  gridSelectionChanged(selectedRows): void {
    if (this.selectedRowChange) {
      this.selectedRowChange.emit(selectedRows.selectedRowKeys);
    }
  }

  gridRowPrepared(e: Object): void {
    if (this.rowPrepared) {
      this.rowPrepared.emit(e);
    }
  }

  private checkForCustomTemplate(): void {
    let templateKeys: string[];
    if (this.templates) {
      templateKeys = Object.keys(this.templates);
    }
    this.gridStructure.columnsConfigurations.forEach(columnConfig => {
      if (templateKeys) {
        columnConfig.cellTemplate = templateKeys.indexOf(columnConfig.dataField) > -1 ? GRID_TEMPLATES.customTemplate : null;
      }
      if (!columnConfig.cellTemplate && (columnConfig.dataType === PropertyTypes.typeDate)) {
        columnConfig.cellTemplate = 'dateTemplate';
      }
    });
  }

  public refreshData(): void {
    if (this.refreshGridData) {
      this.refreshGridData.emit();
    }
  }

  public openSettings(): void {
    this.displaySettings = true;
  }

  public columnChooserClosed(): void {
    this.displaySettings = false;
  }

  public loadGridState(): Promise<null> {
    return new Promise(resolve => {
      this.tableInfoService.getComponentState(this.gridAlias).subscribe((response) => {
        resolve();
      });
    });
  }

  public saveGridState(state): Promise<null> {
    return new Promise(resolve => {
      this.tableInfoService.saveComponentState(this.gridAlias, JSON.stringify(state)).subscribe(() => {
        resolve();
      });
    });
  }

  public getSortedColumns(): OrderByArgsModel[] {
    const orderByArgsList: OrderByArgsModel[] = [];
    const state = this.grid.instance.state();
    state.columns.forEach(column => {
      if (column.sortOrder) {
        if (column.sortOrder === 'asc') {
          orderByArgsList.push(new OrderByArgsModel(column.dataField, FuzzySearchSortOrder.Asc))
        }
        else {
          orderByArgsList.push(new OrderByArgsModel(column.dataField, FuzzySearchSortOrder.Desc))
        }
      }
    });
    return orderByArgsList;
  }

  public onExpandClick(e, data) {
    if (this.grid.instance.isRowExpanded(data.data[this.keyExpression])) {
      this.grid.instance.collapseRow(data.data[this.keyExpression]);
    }
    else {
      if (this.rowExpand) {
        this.rowExpand.emit(data.data[this.keyExpression]);
      }
      this.grid.instance.expandRow(data.data[this.keyExpression]);
    }
  }

  cellTemplateHighlights(cellElement, cellInfo) {
    const textWithHighlights = this.utilityService.highlightContent(cellInfo.text, this.highlightText);
    cellElement.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(textWithHighlights));
  }

  getDataPropertyValue(data: object, propertyToRetrieve: string) {
    return propertyToRetrieve.split('.').reduce(
      function (previous: object, current: string) {
        return previous && previous[current]; // get inner property if `previous` is defined else get `previous` and return
      }, data) // set initial value as object
  }

  valueIsDateType(data: object, propertyToRetrieve: string): boolean {
    const value = this.getDataPropertyValue(data, propertyToRetrieve);
    return value instanceof Date;
  }

  /*
    When the enter key is released, the focused row is emitted to the parent
  */
  enterKeyUp(): void {
    if (this.focusRowEnabled) {
      this.enterPressedOnFocusedRow.emit(this.focusedRow);
    }
  }

  /*
    When the escape key is released, focus row is disabled and event emitted
  */
  escapeKeyUp(): void {
    if (this.focusRowEnabled) {
      this.escapeKeyUpWhileGridFocused.emit();
    }
  }

  /*
    When a new row is focused, it's value is stored in the "focusedRow" property
  */
  focusedRowChanged(e: any): void {
    if (e.row) {
      this.focusedRow = e.row.data;
    }
  }

  /*
    Determines the page size, depending on the identifier of the grid
  */
  determinePageSize(): void {
    let pageSize;
    switch (this.gridAlias) {
      case TD_DESKTOP_IDENTIFIERS.allOrdersListGrid:
      case TD_DESKTOP_IDENTIFIERS.openOrdersListGrid:
      case TD_DESKTOP_IDENTIFIERS.sentOrdersListGrid:
      case TD_DESKTOP_IDENTIFIERS.deliveredOrdersListGrid:
      case TD_DESKTOP_IDENTIFIERS.productListGrid:
      case TD_DESKTOP_IDENTIFIERS.endoscopeListGrid:
      case TD_DESKTOP_IDENTIFIERS.itemListGrid:
      case TD_DESKTOP_IDENTIFIERS.unitListGrid:
      case TD_DESKTOP_IDENTIFIERS.orderTemplateListGrid:
      case TD_DESKTOP_IDENTIFIERS.scheduledOperationListGrid:
        pageSize = TD_VALUES.pureGridRowsPerPage;
        break;
      default:
        pageSize = TD_VALUES.detailsGridRowsPerPage;
        break;
    }
    this.pagination = this.pagination ? this.pagination : { enabled: true, pageIndex: 0, pageSize: pageSize };
  }

  formatPagerTranslation(text: string): string {
    return text.replace('%s', '{0}').replace('%s', '{1}').replace('%s', '{2}');
  }
}
