"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
function extractNumberFromParams(paramName, params) {
    return params[td_constants_1.TD_QUERY_PARAMS[paramName]] ? Number(params[td_constants_1.TD_QUERY_PARAMS[paramName]]) : undefined;
}
exports.extractNumberFromParams = extractNumberFromParams;
function addNumberPropToParams(property, params) {
    params[property.urlParamName] = property.value.toString();
    return params;
}
exports.addNumberPropToParams = addNumberPropToParams;
