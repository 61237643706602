"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".header-icon-button[_ngcontent-%COMP%]{border:0}.header-icon-button[_ngcontent-%COMP%]     .dx-button-content{padding:0}.header-icon-button[_ngcontent-%COMP%]     .dx-button-content .dx-icon{width:24px;height:24px}"];
exports.styles = styles;
