import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { SubstitutionInfoModel, SubstitutionArticleListModel } from '../models/substitution-info.model';
import { SubstitutionsServiceInterface } from './substitutions.service.interface';
import { OrderLineIdenticalArgsModel, SaveAlternativesArgsModel, SaveIdenticalBlockedStateArgsModel } from '../models/substitution-args.model';


@Injectable()
export class HttpSubstitutionsService implements SubstitutionsServiceInterface {

  constructor(
    private http: HttpClient
  ) { }

  substitutionsUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.substitutionsCtrl;
  }

  getOrderTemplateSubstitutionArticles(orderTemplateKeyId: number): Observable<SubstitutionInfoModel> {
    return this.http.get<SubstitutionInfoModel>(`${this.substitutionsUrl()}(${orderTemplateKeyId})${API_HUB_PATHS.orderTemplateSubstitutionArticles}`);
  }

  getOrderSubstitutionArticles(orderKeyId: number): Observable<SubstitutionInfoModel> {
    return this.http.get<SubstitutionInfoModel>(`${this.substitutionsUrl()}(${orderKeyId})${API_HUB_PATHS.orderSubstitutionArticles}`);
  }

  getOrderLineIdenticalArticles(ordLineIdenticalArgs: OrderLineIdenticalArgsModel): Observable<SubstitutionArticleListModel> {
    return this.http.post<SubstitutionArticleListModel>(`${this.substitutionsUrl()}${API_HUB_PATHS.orderLineIdenticalArticles}`,
      ordLineIdenticalArgs);
  }

  saveAlternatives(saveAlternativesArgs: SaveAlternativesArgsModel): Observable<null> {
    return this.http.post<null>(`${this.substitutionsUrl()}${API_HUB_PATHS.saveAlternatives}`,
      saveAlternativesArgs);
  }

  saveIdenticalBlockedState(saveIdenticalBlockedStateArgs: SaveIdenticalBlockedStateArgsModel): Observable<null> {
    return this.http.post<null>(`${this.substitutionsUrl()}${API_HUB_PATHS.saveIdenticalBlockedState}`,
      saveIdenticalBlockedStateArgs);
  }
}
