"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ExternalDocumentFileType;
(function (ExternalDocumentFileType) {
    // Image types
    ExternalDocumentFileType[ExternalDocumentFileType["BMP"] = 1] = "BMP";
    ExternalDocumentFileType[ExternalDocumentFileType["GIF"] = 2] = "GIF";
    ExternalDocumentFileType[ExternalDocumentFileType["PNG"] = 3] = "PNG";
    ExternalDocumentFileType[ExternalDocumentFileType["JPG"] = 4] = "JPG";
    ExternalDocumentFileType[ExternalDocumentFileType["JPEG"] = 5] = "JPEG";
    ExternalDocumentFileType[ExternalDocumentFileType["ICO"] = 6] = "ICO";
    ExternalDocumentFileType[ExternalDocumentFileType["TIF"] = 7] = "TIF";
    ExternalDocumentFileType[ExternalDocumentFileType["TIFF"] = 8] = "TIFF";
    // Word Types
    ExternalDocumentFileType[ExternalDocumentFileType["DOC"] = 21] = "DOC";
    ExternalDocumentFileType[ExternalDocumentFileType["DOCM"] = 22] = "DOCM";
    ExternalDocumentFileType[ExternalDocumentFileType["DOCX"] = 23] = "DOCX";
    // Text types
    ExternalDocumentFileType[ExternalDocumentFileType["DOT"] = 41] = "DOT";
    ExternalDocumentFileType[ExternalDocumentFileType["ODT"] = 42] = "ODT";
    ExternalDocumentFileType[ExternalDocumentFileType["RTF"] = 43] = "RTF";
    ExternalDocumentFileType[ExternalDocumentFileType["TXT"] = 44] = "TXT";
    // Excel types
    ExternalDocumentFileType[ExternalDocumentFileType["XLA"] = 61] = "XLA";
    ExternalDocumentFileType[ExternalDocumentFileType["XLS"] = 62] = "XLS";
    // Powerpoint types
    ExternalDocumentFileType[ExternalDocumentFileType["PPT"] = 81] = "PPT";
    ExternalDocumentFileType[ExternalDocumentFileType["POTM"] = 82] = "POTM";
    ExternalDocumentFileType[ExternalDocumentFileType["POTX"] = 83] = "POTX";
    ExternalDocumentFileType[ExternalDocumentFileType["PPA"] = 84] = "PPA";
    ExternalDocumentFileType[ExternalDocumentFileType["PPAM"] = 85] = "PPAM";
    ExternalDocumentFileType[ExternalDocumentFileType["PPS"] = 86] = "PPS";
    ExternalDocumentFileType[ExternalDocumentFileType["PPSM"] = 87] = "PPSM";
    ExternalDocumentFileType[ExternalDocumentFileType["PPSX"] = 88] = "PPSX";
    ExternalDocumentFileType[ExternalDocumentFileType["PPTM"] = 89] = "PPTM";
    ExternalDocumentFileType[ExternalDocumentFileType["PPTX"] = 90] = "PPTX";
    // Video types
    ExternalDocumentFileType[ExternalDocumentFileType["MP4"] = 101] = "MP4";
    ExternalDocumentFileType[ExternalDocumentFileType["MOV"] = 102] = "MOV";
    ExternalDocumentFileType[ExternalDocumentFileType["AVI"] = 103] = "AVI";
    ExternalDocumentFileType[ExternalDocumentFileType["MPG"] = 104] = "MPG";
    ExternalDocumentFileType[ExternalDocumentFileType["WMV"] = 105] = "WMV";
    ExternalDocumentFileType[ExternalDocumentFileType["OGG"] = 106] = "OGG";
    ExternalDocumentFileType[ExternalDocumentFileType["WEBM"] = 107] = "WEBM";
    ExternalDocumentFileType[ExternalDocumentFileType["MP2"] = 108] = "MP2";
    ExternalDocumentFileType[ExternalDocumentFileType["MPEG"] = 109] = "MPEG";
    ExternalDocumentFileType[ExternalDocumentFileType["MPE"] = 110] = "MPE";
    ExternalDocumentFileType[ExternalDocumentFileType["MPV"] = 111] = "MPV";
    ExternalDocumentFileType[ExternalDocumentFileType["M4P"] = 112] = "M4P";
    ExternalDocumentFileType[ExternalDocumentFileType["M4V"] = 113] = "M4V";
    ExternalDocumentFileType[ExternalDocumentFileType["QT"] = 114] = "QT";
    ExternalDocumentFileType[ExternalDocumentFileType["FLV"] = 115] = "FLV";
    ExternalDocumentFileType[ExternalDocumentFileType["SWF"] = 116] = "SWF";
    ExternalDocumentFileType[ExternalDocumentFileType["AVCHD"] = 117] = "AVCHD";
    // Archive types
    ExternalDocumentFileType[ExternalDocumentFileType["ZIP"] = 141] = "ZIP";
    ExternalDocumentFileType[ExternalDocumentFileType["ZIPX"] = 142] = "ZIPX";
    // Sound types
    ExternalDocumentFileType[ExternalDocumentFileType["WAV"] = 161] = "WAV";
    ExternalDocumentFileType[ExternalDocumentFileType["MP3"] = 162] = "MP3";
    // Single types
    ExternalDocumentFileType[ExternalDocumentFileType["URL"] = 181] = "URL";
    ExternalDocumentFileType[ExternalDocumentFileType["PDF"] = 182] = "PDF";
    ExternalDocumentFileType[ExternalDocumentFileType["OTHER"] = 183] = "OTHER";
})(ExternalDocumentFileType = exports.ExternalDocumentFileType || (exports.ExternalDocumentFileType = {}));
var ICONTYPE;
(function (ICONTYPE) {
    ICONTYPE["IMG"] = "IMG";
    ICONTYPE["WORD"] = "WORD";
    ICONTYPE["TEXT"] = "TEXT";
    ICONTYPE["EXCEL"] = "EXCEL";
    ICONTYPE["POWERPOINT"] = "POWERPOINT";
    ICONTYPE["VIDEO"] = "VIDEO";
    ICONTYPE["ARCHIVE"] = "ARCHIVE";
    ICONTYPE["URL"] = "URL";
    ICONTYPE["PDF"] = "PDF";
    ICONTYPE["OTHER"] = "OTHER";
    ICONTYPE["AUDIO"] = "AUDIO";
})(ICONTYPE = exports.ICONTYPE || (exports.ICONTYPE = {}));
