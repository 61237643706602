"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var app_constants_1 = require("@app/shared/models/app.constants");
var HttpErrorReportService = /** @class */ (function () {
    function HttpErrorReportService(http) {
        this.http = http;
    }
    HttpErrorReportService.prototype.sendErrorReport = function (errorArgs) {
        var _a;
        return this.http.post(api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.errorCtrl, errorArgs, { headers: new http_1.HttpHeaders((_a = {}, _a[app_constants_1.SKIP_HEADER] = '', _a)) });
    };
    return HttpErrorReportService;
}());
exports.HttpErrorReportService = HttpErrorReportService;
