import { ActionReducer, ActionReducerMap, MetaReducer, Action } from '@ngrx/store';

import { ServerSessionState, serverSessionReducer } from './server-session/store/server-session.reducer';
import { UserSessionState, userSessionReducer } from './user-session/store/user-session.reducer';
import { CustomerState, customerReducer } from './customer/store/customer.reducer';
import { InfoOverviewState, infoOverviewReducer } from '@app/dashboard/data-layer/info-overview/store/info-overview.reducer';
import { FactoryState, factoryReducer } from './factory/store/factory.reducer';
import { OrderState, orderReducer } from './order/store/order.reducer';
import { LOCALSTORAGE_APP_STATE } from '@app/shared/models/app.constants';

// STATE
export interface AppState {
  serverSession: ServerSessionState;
  userSession: UserSessionState;
  order: OrderState;
  customer: CustomerState;
  factory: FactoryState;
  infoOverview: InfoOverviewState;
}

// REDUCERS
export const reducers: ActionReducerMap<AppState> = {
  serverSession: serverSessionReducer,
  userSession: userSessionReducer,
  order: orderReducer,
  customer: customerReducer,
  factory: factoryReducer,
  infoOverview: infoOverviewReducer
};

const localStorageKey = LOCALSTORAGE_APP_STATE;

// Meta-reducers allow to pre-process actions before normal reducers are invoked
export function metaReducerFactory(): MetaReducer<AppState> {
  return (reducer: ActionReducer<AppState, Action>): ActionReducer<AppState, Action> => {
    return (state: AppState | undefined, action: Action) => {
      // initialize state from local storage
      if (state === undefined) {
        const persisted = localStorage.getItem(localStorageKey);
        return persisted ? JSON.parse(persisted) : reducer(state, action);
      }
      // save state in local storage
      else {
        const nextState = reducer(state, action);
        localStorage.setItem(localStorageKey, JSON.stringify(nextState));
        return nextState;
      }
    };
  }
}
