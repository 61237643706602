import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ControlState } from '@shared/models/constrol-state.model';

@Component({
  selector: 'td-toolbar-button',
  templateUrl: './td-toolbar-button.component.html',
  styleUrls: ['./td-toolbar-button.component.less']
})
export class TdToolbarButtonComponent {

  @Input() text: string;
  @Input() icon: string;
  @Input() hint?: string;
  @Input() buttonState?: ControlState = new ControlState();
  @Output() btnClick: EventEmitter<any> = new EventEmitter();
}
