"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var HttpSubstitutionsService = /** @class */ (function () {
    function HttpSubstitutionsService(http) {
        this.http = http;
    }
    HttpSubstitutionsService.prototype.substitutionsUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.substitutionsCtrl;
    };
    HttpSubstitutionsService.prototype.getOrderTemplateSubstitutionArticles = function (orderTemplateKeyId) {
        return this.http.get(this.substitutionsUrl() + "(" + orderTemplateKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.orderTemplateSubstitutionArticles);
    };
    HttpSubstitutionsService.prototype.getOrderSubstitutionArticles = function (orderKeyId) {
        return this.http.get(this.substitutionsUrl() + "(" + orderKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.orderSubstitutionArticles);
    };
    HttpSubstitutionsService.prototype.getOrderLineIdenticalArticles = function (ordLineIdenticalArgs) {
        return this.http.post("" + this.substitutionsUrl() + api_hub_constants_1.API_HUB_PATHS.orderLineIdenticalArticles, ordLineIdenticalArgs);
    };
    HttpSubstitutionsService.prototype.saveAlternatives = function (saveAlternativesArgs) {
        return this.http.post("" + this.substitutionsUrl() + api_hub_constants_1.API_HUB_PATHS.saveAlternatives, saveAlternativesArgs);
    };
    HttpSubstitutionsService.prototype.saveIdenticalBlockedState = function (saveIdenticalBlockedStateArgs) {
        return this.http.post("" + this.substitutionsUrl() + api_hub_constants_1.API_HUB_PATHS.saveIdenticalBlockedState, saveIdenticalBlockedStateArgs);
    };
    return HttpSubstitutionsService;
}());
exports.HttpSubstitutionsService = HttpSubstitutionsService;
