"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#settingsTabPanel[_ngcontent-%COMP%]     .dx-tab.dx-tab-selected:before{border-bottom-width:4px;margin-top:-1.5px}.settings-tab-panel-height[_ngcontent-%COMP%]{height:calc(100% - 46px)}"];
exports.styles = styles;
