<dx-load-panel
  id="load-panel"
  shadingColor="rgba(255,255,255,0.7)"
  [(visible)]="loadPanelService.loadPanelParams.visible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
  [message]="loadPanelService.loadPanelParams.message">
</dx-load-panel>
