import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ErrorReportModel } from '../models/error-report.model';
import { ErrorReportServiceInterface } from './error-report.service.interface';
import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { SKIP_HEADER } from '@app/shared/models/app.constants';

@Injectable()
export class HttpErrorReportService implements ErrorReportServiceInterface {

  constructor(
    private http: HttpClient
  ) {}

  sendErrorReport(errorArgs: ErrorReportModel): Observable<null> {
    return this.http.post<null>(
      API_HUB_PATHS.apiUrl + API_HUB_PATHS.errorCtrl, 
      errorArgs, 
      { headers: new HttpHeaders({[SKIP_HEADER]: ''})}
    );
  }

}
