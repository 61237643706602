"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MEDIA_WRAPPER_WIDTH = {
    Multiple: 117,
    Single: 106
};
exports.SKIP_HEADER = 'SkipHeader';
exports.MEDIA_SERIES_PRIORITY = ['product', 'item'];
exports.UNIT_STATUS = 'unitStatus';
exports.ENDOSCOPE_UNIT_STATUS = 'endoscopeUnitStatus';
exports.OPERATION_STATUS = 'operationStatus';
exports.ORDER_BOOKING_STATUS = 'orderBookingStatus';
exports.ORDER_BOOKING_MODE = 'orderBookingMode';
exports.RESERVATION_STATE = 'reservationState';
var GuardTypes;
(function (GuardTypes) {
    GuardTypes[GuardTypes["AuthenticatedUserAndCustomerSelected"] = 0] = "AuthenticatedUserAndCustomerSelected";
    GuardTypes[GuardTypes["LoginModeAlways"] = 1] = "LoginModeAlways";
    GuardTypes[GuardTypes["AllowAlways"] = 2] = "AllowAlways";
})(GuardTypes = exports.GuardTypes || (exports.GuardTypes = {}));
exports.GUARD_TYPE = 'guardType';
exports.PRODUCT_AS_STRING = 'product';
exports.UNIT_AS_STRING = 'unit';
exports.ITEM_AS_STRING = 'item';
exports.LOCALSTORAGE_APP_STATE = 'td-app-state';
exports.ROUTE_PATHS = {
    productionOverview: 'production-overview',
    operations: 'operations',
    orders: 'orders',
    orderTemplates: 'order-templates',
    landing: ''
};
exports.GRID_TEMPLATES = {
    customTemplate: 'customTemplate'
};
exports.GRID_SIZES = {
    rowHeight: 29,
    gridHeaderHeight: 30,
    labelHeight: 27,
    noArticlesHeight: 100,
    get maxPopupGridHeight() {
        return (10 * this.rowHeight) + this.gridHeaderHeight;
    }
};
