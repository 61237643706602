"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var ServerDesktopOptionsModel = /** @class */ (function () {
    function ServerDesktopOptionsModel() {
        this.loginCookieMethod = td_enumerations_1.LoginCookieMethod.UserNamePassword;
        this.passwordVerificationMethod = td_enumerations_1.PasswordVerificationMethod.None;
        this.loginAllowBlankPasswords = false;
        this.defaultFTCoKeyId = 0;
        this.prodOverviewVisibilityMode = td_enumerations_1.ProdOverviewVisibilityMode.AvailableInWeb;
        this.fastTrackMode = td_enumerations_1.WebFastTrackMode.On;
        this.startStopOperationMode = td_enumerations_1.WebStartStopOperationMode.Allow;
        this.editOprationArticlesMode = td_enumerations_1.WebEditOprationArticlesMode.AllowEdit;
        this.orderMode = td_enumerations_1.WebOrderMode.On;
        this.displayPriceMode = td_enumerations_1.WebDisplayPriceMode.ShowAll;
        this.displayCustomerProductsMode = td_enumerations_1.WebDisplayCustomerProductsMode.All;
        this.displayGeneralProductsMode = td_enumerations_1.WebDisplayGeneralProductsMode.Allowed;
        this.previewOrderBeforeSending = false;
    }
    return ServerDesktopOptionsModel;
}());
exports.ServerDesktopOptionsModel = ServerDesktopOptionsModel;
