"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("@app/core/guards/auth.guard");
var operation_list_component_1 = require("./operation-list/operation-list.component");
var operation_details_component_1 = require("./operation-details/operation-details.component");
var operationsRoutes = [
    {
        path: '',
        component: operation_list_component_1.OperationListComponent,
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: ':id',
        component: operation_details_component_1.OperationDetailsComponent,
        canActivate: [auth_guard_1.AuthGuard]
    }
];
var OperationsRoutingModule = /** @class */ (function () {
    function OperationsRoutingModule() {
    }
    return OperationsRoutingModule;
}());
exports.OperationsRoutingModule = OperationsRoutingModule;
