"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var customer_selectors_1 = require("@core/data-layer/customer/store/customer.selectors");
var custActions = require("@core/data-layer/customer/store/customer.actions");
var factActions = require("@core/data-layer/factory/store/factory.actions");
var info_overview_actions_1 = require("@app/dashboard/data-layer/info-overview/store/info-overview.actions");
var td_popup_service_1 = require("../td-popup/services/td-popup.service");
var td_deep_link_enumerations_1 = require("./models/td-deep-link.enumerations");
var td_load_panel_service_1 = require("../td-load-panel/services/td-load-panel.service");
var deep_link_constants_1 = require("./models/deep-link.constants");
var td_popup_button_interface_1 = require("../td-popup/models/td-popup-button.interface");
var td_popup_model_1 = require("../td-popup/models/td-popup.model");
var DeepLinkComponent = /** @class */ (function () {
    function DeepLinkComponent(authService, popupService, route, router, store, loadPanelService) {
        this.authService = authService;
        this.popupService = popupService;
        this.route = route;
        this.router = router;
        this.store = store;
        this.loadPanelService = loadPanelService;
    }
    DeepLinkComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params[deep_link_constants_1.ERROR_MESSAGE]) {
                _this.handleDeepLinkError(params);
            }
            else {
                _this.handleDeepLinkSuccess(params);
            }
        });
    };
    /**
     * Decodes base64 error message, and displays any error that occured during deep linking and redirects if needed
     *
     * @param params - Current params in URL
     * @returns void
     */
    DeepLinkComponent.prototype.handleDeepLinkError = function (params) {
        var _this = this;
        var errorCode = Number(params[deep_link_constants_1.ERROR_CODE]);
        var errorMessage = atob(params[deep_link_constants_1.ERROR_MESSAGE]);
        var redirectUrl = this.determineErrorRedirectUrl(errorCode);
        if (errorCode === td_deep_link_enumerations_1.JumpErrorCodes.WrongUserLoggedIn) {
            this.showCustomPopup(errorMessage);
        }
        else {
            this.popupService.showError(errorMessage).then(function () {
                if (errorCode === td_deep_link_enumerations_1.JumpErrorCodes.UnableAutoLogin || errorCode === td_deep_link_enumerations_1.JumpErrorCodes.OperationNotFound) {
                    _this.loadPanelService.showLoadPanel();
                    _this.authService.startSignin(redirectUrl);
                }
                else {
                    _this.router.navigateByUrl(redirectUrl);
                }
            });
        }
    };
    /**
     * Returns a URL to navigate to while displaying the error to the user
     *
     * @remarks
     *    Should we introduce more JumpErrorCodes that require specific return URL's, introduce them as cases here,
     *     - ie: case JumpErrorCodes.OrderNotFound: return '/orders';
     * @param errorCode - number that represents a value in the JumpErrorCodes enum
     * @returns URL to redirect to
     */
    DeepLinkComponent.prototype.determineErrorRedirectUrl = function (errorCode) {
        switch (Number(errorCode)) {
            case td_deep_link_enumerations_1.JumpErrorCodes.OperationNotFound: return deep_link_constants_1.OPERATIONS_URL;
            default: return deep_link_constants_1.LANDING_PAGE_URL;
        }
    };
    /**
     * Handles the redirection that occurs when a deep link has successfully been performed
     * @remarks
     *   If a user tries to use a deep link URL, but is not authenticated, we will redirect to login page, and after successful login, we redirect back to desired route
     * @param params - Current params in URL
     * @returns void
     */
    DeepLinkComponent.prototype.handleDeepLinkSuccess = function (params) {
        var customerId = Number(params[deep_link_constants_1.CUSTOMER_ID]);
        var redirectUrl = this.determineSuccessRedirectUrl(params);
        if (this.authService.isAuthenticated()) {
            this.setCustomerAndRedirect(customerId, redirectUrl);
        }
        else {
            this.authService.startSignin();
        }
    };
    /**
     * Returns a URL to redirect to, after receiving a succesful deeplink
     *
     * @remarks
     *   Currently, we only use redirect to operation details. However, we also support redirecting to order details.
     *   To redirect to order details, make sure the deep link URL contains a "orderId" property
     * @param params - Current params in URL
     * @returns URL to redirect to
     */
    DeepLinkComponent.prototype.determineSuccessRedirectUrl = function (params) {
        if (params[deep_link_constants_1.OPERATION_ID]) {
            return "operations/" + params[deep_link_constants_1.OPERATION_ID];
        }
        else if (params[deep_link_constants_1.ORDER_ID]) {
            return "orders/" + params[deep_link_constants_1.ORDER_ID];
        }
    };
    /**
     * Triggers the "setCustomer()" function and redirects based on parameters
     *
     * @param customerId - The keyId of the customer desired
     * @param url - The URL desired to redirect to
     * @returns void
     */
    DeepLinkComponent.prototype.setCustomerAndRedirect = function (customerId, url) {
        var _this = this;
        this.setCustomer(customerId).then(function (customerAssociatedWithUser) {
            if (customerAssociatedWithUser) {
                _this.navigateTo(url);
            }
            else {
                _this.navigateTo(deep_link_constants_1.LANDING_PAGE_URL);
            }
        });
    };
    /**
     * Attempts to trigger a customer selection in the store, if the desired customer is linked to the authenticated user
     * Should the user not be associated with the desired customer, a popup error should be displayed
     * @param customerId - The keyid of the customer desired
     * @returns promise - if it was possible to find a matching customer, resolve(true) else resolve(false)
     */
    DeepLinkComponent.prototype.setCustomer = function (customerId) {
        var _this = this;
        var customers$ = this.store.pipe(store_1.select(customer_selectors_1.selectUserCustomers));
        return new Promise(function (resolve) {
            customers$.subscribe(function (customerList) {
                var wantedCustomer = customerList.find(function (entry) { return entry.keyId === customerId; });
                if (wantedCustomer) {
                    _this.selectCustomer(wantedCustomer);
                    resolve(true);
                }
                else {
                    _this.popupService.showError('appMessages.userNotAssociatedWithCustomer', true);
                    resolve(false);
                }
            });
        });
    };
    /**
     * Selects the customer in the store, based on customer parameter
     *
     * @remark this logic is equal to the logic used in auth-customers component
     * @param customer - The customer desired to be selected
     * @returns void
     */
    DeepLinkComponent.prototype.selectCustomer = function (customer) {
        this.store.dispatch(new factActions.LoadCustomerFactories(customer.keyId));
        this.store.dispatch(new factActions.LoadUserFactories());
        this.store.dispatch(new custActions.SetCurrentCustomer(customer));
        if (customer.cuSiteKeyId) {
            this.store.dispatch(new custActions.LoadSiteCustomers(customer.cuSiteKeyId));
        }
        this.store.dispatch(new info_overview_actions_1.LoadIOConfigurations());
    };
    DeepLinkComponent.prototype.navigateTo = function (url) {
        this.router.navigateByUrl(url);
    };
    DeepLinkComponent.prototype.showCustomPopup = function (errorMessage) {
        var _this = this;
        this.popupService.showCustomWithButtons('appMessages.logout', errorMessage, td_popup_model_1.PopupType.Confirmation, [
            {
                text: 'button.cancel',
                type: td_popup_button_interface_1.TdPopupButtonType.Secondary
            },
            {
                text: 'appMessages.logout',
                type: td_popup_button_interface_1.TdPopupButtonType.Danger,
                callback: function () {
                    _this.authService.startSignout();
                }
            }
        ]).then(function (popupResolved) {
            if (!popupResolved) {
                _this.navigateTo('');
            }
            ;
        });
    };
    return DeepLinkComponent;
}());
exports.DeepLinkComponent = DeepLinkComponent;
