"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_constants_1 = require("@app/core/data-layer/shared/models/td.constants");
var recent_search_interface_1 = require("@app/core/search/models/recent-search.interface");
var param_models_1 = require("./param-models");
var OperationSearchFactory = /** @class */ (function () {
    function OperationSearchFactory() {
    }
    OperationSearchFactory.prototype.createSearchModel = function () {
        return {
            url: '/operations',
            properties: [
                {
                    urlParamName: td_constants_1.TD_QUERY_PARAMS.text,
                    type: param_models_1.SearchParamTypes.String
                },
                {
                    urlParamName: td_constants_1.TD_QUERY_PARAMS.dateFrom,
                    type: param_models_1.SearchParamTypes.Date
                },
                {
                    urlParamName: td_constants_1.TD_QUERY_PARAMS.dateTo,
                    type: param_models_1.SearchParamTypes.Date
                }
            ]
        };
    };
    OperationSearchFactory.prototype.createDefaultSearchModel = function () {
        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        return {
            url: '/operations',
            properties: [
                {
                    urlParamName: td_constants_1.TD_QUERY_PARAMS.dateFrom,
                    type: param_models_1.SearchParamTypes.Date,
                    value: today
                },
                {
                    urlParamName: td_constants_1.TD_QUERY_PARAMS.dateTo,
                    type: param_models_1.SearchParamTypes.Date,
                    value: tomorrow
                }
            ]
        };
    };
    OperationSearchFactory.prototype.getRecentSearchAsParams = function (recentSearch) {
        var params = {};
        var recentSearchFilter = recentSearch.operationFilters;
        if (recentSearch.type === recent_search_interface_1.RecentSearchType.Date) {
            // Storing date parameters as a number in milliseconds
            params.dateFrom = recentSearchFilter.dateInterval.from ? new Date(recentSearchFilter.dateInterval.from).valueOf() : null;
            params.dateTo = recentSearchFilter.dateInterval.to ? new Date(recentSearchFilter.dateInterval.to).valueOf() : null;
        }
        else {
            params.text = recentSearch.operationFilters.text;
        }
        return params;
    };
    OperationSearchFactory.prototype.getEmptyParamsSearchModel = function () {
        return this.createDefaultSearchModel();
    };
    return OperationSearchFactory;
}());
exports.OperationSearchFactory = OperationSearchFactory;
