import { Guid } from 'guid-typescript';

import * as srvSessionActions from './server-session.actions';
import { ClientConfigurationModel } from '@core/client-configuration/models/client-configuration.model';
import { LicenseModel } from '@core/license/models/license.model';
import { ServerDesktopOptionsModel } from '../models/server-desktop-options.model';

export interface ServerSessionState {
  clientId: string;
  sessionEnded: boolean;
  returnUrl: string;
  license: LicenseModel;
  clientConfiguration: ClientConfigurationModel;
  desktopOptions: ServerDesktopOptionsModel;
}

export const initialServerSessionState: ServerSessionState = {
  clientId: null,
  sessionEnded: false,
  returnUrl: null,
  license: null,
  clientConfiguration: null,
  desktopOptions: null
};

export function serverSessionReducer(state = initialServerSessionState, action: srvSessionActions.ServerSessionActions): ServerSessionState {
  switch (action.type) {
    case srvSessionActions.ServerSessionActionTypes.InitializeClientId:
      return handleInitializeClientId(state, action);
    case srvSessionActions.ServerSessionActionTypes.SetSessionEnded:
      return handleSetSessionEnded(state, action);
    case srvSessionActions.ServerSessionActionTypes.SetReturnUrl:
      return handleSetReturnUrl(state, action);
    case srvSessionActions.ServerSessionActionTypes.LoadServerDesktopOptions:
      return handleLoadServerDesktopOptions(state, action);
    case srvSessionActions.ServerSessionActionTypes.SetServerDesktopOptions:
      return handleSetServerDesktopOptions(state, action);
    case srvSessionActions.ServerSessionActionTypes.LoadClientConfiguration:
      return handleLoadClientConfiguration(state, action);
    case srvSessionActions.ServerSessionActionTypes.SetClientConfiguration:
      return handleSetClientConfiguration(state, action);
    case srvSessionActions.ServerSessionActionTypes.LoadLicense:
      return handleLoadLicense(state, action);
    case srvSessionActions.ServerSessionActionTypes.SetLicense:
      return handleSetLicense(state, action);
    default:
      return state;
  }
}

function handleInitializeClientId(state: ServerSessionState, action: srvSessionActions.InitializeClientId): ServerSessionState {
  return Object.assign({}, state, { clientId: Guid.create().toString() });
}

function handleSetSessionEnded(state: ServerSessionState, action: srvSessionActions.SetSessionEnded): ServerSessionState {
  return Object.assign({}, state, { sessionEnded: action.payload });
}

function handleSetReturnUrl(state: ServerSessionState, action: srvSessionActions.SetReturnUrl): ServerSessionState {
  return Object.assign({}, state, { returnUrl: action.payload });
}

function handleLoadServerDesktopOptions(state: ServerSessionState, action: srvSessionActions.LoadServerDesktopOptions): ServerSessionState {
  return state;
}

function handleSetServerDesktopOptions(state: ServerSessionState, action: srvSessionActions.SetServerDesktopOptions): ServerSessionState {
  return Object.assign({}, state, { desktopOptions: action.payload });
}

function handleLoadClientConfiguration(state: ServerSessionState, action: srvSessionActions.LoadClientConfiguration): ServerSessionState {
  return state;
}

function handleSetClientConfiguration(state: ServerSessionState, action: srvSessionActions.SetClientConfiguration): ServerSessionState {
  return Object.assign({}, state, { clientConfiguration: action.payload });
}

function handleLoadLicense(state: ServerSessionState, action: srvSessionActions.LoadLicense): ServerSessionState {
  return state;
}

function handleSetLicense(state: ServerSessionState, action: srvSessionActions.SetLicense): ServerSessionState {
  return Object.assign({}, state, { license: action.payload });
}
