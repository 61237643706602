import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { NgModule } from '@angular/core';

import { OperationListComponent } from './operation-list/operation-list.component';
import { OperationDetailsComponent } from './operation-details/operation-details.component';

const operationsRoutes: Routes = [
  {
    path: '',
    component: OperationListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':id',
    component: OperationDetailsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(operationsRoutes)
  ],
  exports: [RouterModule],
  declarations: [],
  providers: []
})
export class OperationsRoutingModule {}
