"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OrderActionTypes;
(function (OrderActionTypes) {
    OrderActionTypes["LoadCustomerOpenOrders"] = "[Order] Load Customer Open Orders";
    OrderActionTypes["SetCustomerOpenOrders"] = "[Order] Set Customer Open Orders";
    OrderActionTypes["LoadUserLastOpenOrder"] = "[Order] Load User Last Open Order";
    OrderActionTypes["SetUserLastOpenOrder"] = "[Order] Set User Last Open Order";
})(OrderActionTypes = exports.OrderActionTypes || (exports.OrderActionTypes = {}));
var LoadCustomerOpenOrders = /** @class */ (function () {
    // "payload" keeps Customer Key Id
    function LoadCustomerOpenOrders(payload) {
        this.payload = payload;
        this.type = OrderActionTypes.LoadCustomerOpenOrders;
    }
    return LoadCustomerOpenOrders;
}());
exports.LoadCustomerOpenOrders = LoadCustomerOpenOrders;
var SetCustomerOpenOrders = /** @class */ (function () {
    function SetCustomerOpenOrders(payload) {
        this.payload = payload;
        this.type = OrderActionTypes.SetCustomerOpenOrders;
    }
    return SetCustomerOpenOrders;
}());
exports.SetCustomerOpenOrders = SetCustomerOpenOrders;
var LoadUserLastOpenOrder = /** @class */ (function () {
    function LoadUserLastOpenOrder(orderKeyId, customerKeyId) {
        this.orderKeyId = orderKeyId;
        this.customerKeyId = customerKeyId;
        this.type = OrderActionTypes.LoadUserLastOpenOrder;
    }
    return LoadUserLastOpenOrder;
}());
exports.LoadUserLastOpenOrder = LoadUserLastOpenOrder;
var SetUserLastOpenOrder = /** @class */ (function () {
    // "payload" keeps OrderView model
    function SetUserLastOpenOrder(payload) {
        this.payload = payload;
        this.type = OrderActionTypes.SetUserLastOpenOrder;
    }
    return SetUserLastOpenOrder;
}());
exports.SetUserLastOpenOrder = SetUserLastOpenOrder;
