import { OperationFilterInterface } from '@core/data-layer/operation-data/models/operation-filter.interface';
import { OrderFilterDataInterface } from '@core/data-layer/order/models/order-filter-data.interface';
import { OrderTemplateFilterInterface } from '@core/data-layer/order-template/models/order-template-filter.interface';

export interface RecentSearchInterface {
  type?: RecentSearchType;
  orderFilters?: OrderFilterDataInterface
  operationFilters?: OperationFilterInterface;
  orderTemplateFilters?: OrderTemplateFilterInterface;
  tabIndex?: number;
}

export enum RecentSearchType {
  Date = 0,
  Text = 1,
  Mixed = 2
}