<nav id="headerNavBar" class="navbar navbar-expand" *ngIf="!headerHidden">
  <div class="d-flex flex-column w-100">
    <div id="divBottom" class="d-flex flex-row">
      <a class="cursorPointer w-auto h-auto d-flex align-items-center" (click)="onLogoClick()">
        <img id="imgLogo" class="img-fluid" src=".\assets\img\Program_icon_T-DOC.svg" alt="Logo" />
      </a>

      <ul class="navbar-nav d-flex mr-auto" *ngIf="noActionsHeader">
        <li class="nav-item">
          &nbsp;
        </li>
      </ul>

      <ng-container *ngIf="!noActionsHeader">
        <ul class="navbar-nav d-flex mr-auto">        
          <li class="nav-item d-flex align-items-center" *ngIf="sessionHelperService.canViewOrder()">
            <a id="linkOrders" class="nav-link cursorPointer" [ngClass]="{ 'bold-nav-item': activePage == allActivePages.Orders }"  routerLink="orders">
              {{ 'appCommon.orders' | translate }}
            </a>
          </li>
          <li class="nav-item d-flex align-items-center" *ngIf="sessionHelperService.canViewOrder() && sessionHelperService.license.stockExtended">
            <a id="linkTemplates" class="nav-link cursorPointer" [ngClass]="{ 'bold-nav-item': activePage == allActivePages.Templates }" routerLink="order-templates">
              {{ 'appCommon.templates' | translate }}
            </a>
          </li>
          <li class="nav-item d-flex align-items-center" *ngIf="sessionHelperService.canViewOperation()">
            <a id="linkOperations" class="nav-link cursorPointer" [ngClass]="{ 'bold-nav-item': activePage == allActivePages.Operations }" routerLink="operations">
              {{ 'appCommon.scheduledOperations' | translate }}
            </a>
          </li>
          <li class="nav-item d-flex align-items-center" *ngIf="sessionHelperService.canViewProductionOverview()">
            <a id="linkPO" class="nav-link cursorPointer" [ngClass]="{ 'bold-nav-item': activePage == allActivePages.ProductionOverview }" [routerLink]="['production-overview']" [queryParams]="{IOCKEYID: defaultIOConfigId}">
              {{ 'appCommon.prodOverview' | translate }}
            </a>
          </li>        
        </ul>

        <div *ngIf="allowSearch" id="searchDiv" class="d-flex align-items-center justify-content-center flex-grow-1">            
          <dx-text-box id="searchTextBox" #headerSearchBox class="search-box"
                        placeholder="{{ 'appCommon.searchPlaceholder' | translate }}"
                        [(value)]="searchText"
                        width="25%"
                        (keyup.enter)="submitSearch()">
          </dx-text-box>
          <dx-button 
            (onClick)="submitSearch()"
            class="inputfield-button action-icon-button"
            hint="{{ 'appCommon.search' | translate }}"
            icon="search">
          </dx-button>
          <div class="pl-1" *ngIf="sessionHelperService.loggedIn()">
            <td-search-filters-button [darkMode]="true"></td-search-filters-button>
          </div> 
        </div>
      </ng-container>

      <ul class="navbar-nav d-flex ml-auto" *ngIf="!noActionsHeader">
        <li class="nav-item d-flex align-items-center mr-3 header-user-data" *ngIf="sessionHelperService.isAuthenticated">
          <div class="d-flex cursorPointer align-items-center" (click)="triggerDropDownSelect()">
            <div class="d-flex flex-column user-data-menu align-items-end mr-2">
              <div class="user-initials">{{userInitials}}</div>
              <div class="user-customer">{{userCustomer}}</div>
            </div>
            <img *ngIf="!userProfilePicture" class="user-profile-picture" src=".\assets\img\default-user.svg">
            <img *ngIf="userProfilePicture" class="user-profile-picture" [src]="userProfilePicture">
          </div>
          <dx-drop-down-button #userNameElement
                                id="userName"
                                class="drop-down-button"
                                stylingMode="text"
                                [useSelectMode]="false"
                                [items]="profileSettings"
                                itemTemplate="listItem"
                                displayExpr="name"
                                keyExpr="id"
                                height="100%"
                                hoverStateEnabled=false
                                (onItemClick)="dropDownSelect($event.itemData.value)">            
            <div title="" *dxTemplate="let itemData of 'listItem'">
              <div class="align-middle dx-list-item-icon-container">
                <ng-container *ngIf="itemData.value === 0">
                  <i class="dx-icon dx-icon-{{itemData.icon}} dx-list-item-icon"></i>
                </ng-container>
                <ng-container *ngIf="itemData.value === 1">
                  <img src="{{itemData.icon}}" class="dx-icon dx-list-item-icon">
                </ng-container>
              </div>
              {{itemData.name}}
            </div>
          </dx-drop-down-button>
        </li>

        <li class="nav-item d-flex align-items-center mr-3" *ngIf="sessionHelperService.isAuthenticated">
          <dx-button class="header-icon-button tertiary-button-dark-mode" (onClick)="logout()"
             hint="{{ 'appCommon.logout' | translate }}" icon=".\assets\img\Log_out.svg">
          </dx-button>
        </li>
        <li class="nav-item d-flex align-items-center mr-3" *ngIf="!sessionHelperService.isAuthenticated">
          <dx-button class="header-icon-button tertiary-button-dark-mode" (onClick)="login()"
             hint="{{ 'appCommon.login' | translate }}" icon=".\assets\img\Log_in.svg">
          </dx-button>
        </li>
        <li class="nav-item d-flex align-items-center">
          <td-help [routerUrl]="routerUrl"></td-help>
        </li>
      </ul>
    </div>
  </div>
</nav>
<td-settings [(visible)]="settingsVisible" *ngIf="settingsVisible"></td-settings>
