export const IMAGES_TO_PRELOAD = new Set<string>([
  // Substitution icons
  './assets/img/substitute-icons/identicalIcon.png',
  './assets/img/substitute-icons/alternativeIcon.png',
  './assets/img/substitute-icons/blockedIcon.png',
  './assets/img/substitute-icons/identicalIconBlocked.png',
  './assets/img/substitute-icons/inheritedAlternativeIcon.png',
  './assets/img/substitute-icons/inheritedIdenticalIcon.png',
  './assets/img/substitute-icons/inheritedIdenticalIconBlocked.png',

  // Popup icons
  './assets/img/Information.svg',
  './assets/img/Error-White.svg',
  './assets/img/Error.svg',
  './assets/img/Warning Orange.svg',

  // Calendar icons
  './assets/img/LeftButtonWhiteTriangle.svg',    
  './assets/img/RightButtonWhiteTriangle.svg',

  // Production overview icons
  './assets/img/Close.svg',
  './assets/img/Error-White.svg',
  './assets/img/fastTrack.png',
  './assets/img/user.png',
  './assets/img/user_d.png',
  './assets/img/arrowDown.png',
  './assets/img/arrowUp.png',

  // Action icons
  './assets/img/actionDelivered.png',
  './assets/img/actionSend.png',    
  './assets/img/ordersAll.png', 
  './assets/img/16x16/copyOrder.png',  
  './assets/img/actionAddComment.png',  
  './assets/img/actionCancel.png',
  './assets/img/actionClone.png',
  './assets/img/actionPrint.png',
  './assets/img/actionPreview.png',
  './assets/img/actionRefresh.png',
  './assets/img/actionSave.png',
  './assets/img/actionSendOrder.png',
  './assets/img/actionUndo.png',
  './assets/img/redCross.png',
  './assets/img/greenTriangle.png',
  './assets/img/Error.svg',
  './assets/img/Remove.svg',  

  // Fast track icons
  './assets/img/fastTrackActive.png',
  './assets/img/fastTrackCancel.png',

  // Item details specific icons
  './assets/img/Disable.svg',

  // Unit details specific icons
  './assets/img/Disapprove.svg',

  // Shopping car specific icons
  './assets/img/shoppingCart.png',
  './assets/img/delete.png',
  './assets/img/RightBlueTriangle.svg',
  './assets/img/slideLeft.png',
  './assets/img/add.svg',

  // Template specific icons
  './assets/img/actionCopy.png',

  // Search specific icons  
  './assets/img/endoscope.png',
  './assets/img/item.png',
  './assets/img/product.png',
  './assets/img/unit.png',
  './assets/img/addToShoppingCart.png',

  // Media specific icons
  './assets/img/media.svg',
  './assets/img/16x16/enlargeImage.png',  

  // Datagrid specific icons
  './assets/img/datagrid/expandItem.svg',
  './assets/img/datagrid/collapseItem.svg',
  './assets/img/columnChooser.svg',

  // Filetype icons
  './assets/img/file-type-icons/ARCHIVE.svg',
  './assets/img/file-type-icons/AUDIO.svg',
  './assets/img/file-type-icons/EXCEL.svg',
  './assets/img/file-type-icons/IMG.svg',
  './assets/img/file-type-icons/OTHER.svg',
  './assets/img/file-type-icons/PDF.svg',
  './assets/img/file-type-icons/POWERPOINT.svg',
  './assets/img/file-type-icons/TEXT.svg',
  './assets/img/file-type-icons/URL.svg',
  './assets/img/file-type-icons/VIDEO.svg',
  './assets/img/file-type-icons/WORD.svg',

  // Operation list specific
  './assets/img/Approved.svg',
  './assets/img/Warning_Orange.svg',

  // Operation details specific
  './assets/img/stop.svg',

  // Arrow navigation icons
  './assets/img/arrowLeft.png',
  './assets/img/arrowLastLeft.png',
  './assets/img/arrowRight.png',
  './assets/img/arrowLastRight.png',
  
  // Header specific icons
  './assets/img/changePassword.svg',
  './assets/img/help.svg',
  './assets/img/Getinge_Logo.png',
  './assets/img/Program_icon_T-DOC.svg',

  // Order cart specific
  './assets/img/slideLeft.png',

  // Search filter button specific
  './assets/img/SearchFilter.svg',
  './assets/img/SearchFilterOverlay.svg',
  
  // Order booking status specific
  './assets/img/order/booking-status/okAlternative.png',
  './assets/img/order/booking-status/okIdentical.png',
  './assets/img/order/booking-status/okIdenticalForAlternative.png',
  './assets/img/order/booking-status/okOriginal.png',
  './assets/img/order/booking-status/undetermined.png',
  './assets/img/order/booking-status/warningAlternative.png',
  './assets/img/order/booking-status/warningIdentical.png',
  './assets/img/order/booking-status/warningIdenticalForAlternative.png',
  './assets/img/order/booking-status/warningOriginal.png',
  './assets/img/order/booking-status/16x16/okAlternative.png',
  './assets/img/order/booking-status/16x16/okIdentical.png',
  './assets/img/order/booking-status/16x16/okIdenticalForAlternative.png',
  './assets/img/order/booking-status/16x16/okOriginal.png',
  './assets/img/order/booking-status/16x16/undetermined.png',
  './assets/img/order/booking-status/16x16/warningAlternative.png',
  './assets/img/order/booking-status/16x16/warningIdentical.png',
  './assets/img/order/booking-status/16x16/warningIdenticalForAlternative.png',
  './assets/img/order/booking-status/16x16/warningOriginal.png',
]);