"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var guid_typescript_1 = require("guid-typescript");
var srvSessionActions = require("./server-session.actions");
exports.initialServerSessionState = {
    clientId: null,
    sessionEnded: false,
    returnUrl: null,
    license: null,
    clientConfiguration: null,
    desktopOptions: null
};
function serverSessionReducer(state, action) {
    if (state === void 0) { state = exports.initialServerSessionState; }
    switch (action.type) {
        case srvSessionActions.ServerSessionActionTypes.InitializeClientId:
            return handleInitializeClientId(state, action);
        case srvSessionActions.ServerSessionActionTypes.SetSessionEnded:
            return handleSetSessionEnded(state, action);
        case srvSessionActions.ServerSessionActionTypes.SetReturnUrl:
            return handleSetReturnUrl(state, action);
        case srvSessionActions.ServerSessionActionTypes.LoadServerDesktopOptions:
            return handleLoadServerDesktopOptions(state, action);
        case srvSessionActions.ServerSessionActionTypes.SetServerDesktopOptions:
            return handleSetServerDesktopOptions(state, action);
        case srvSessionActions.ServerSessionActionTypes.LoadClientConfiguration:
            return handleLoadClientConfiguration(state, action);
        case srvSessionActions.ServerSessionActionTypes.SetClientConfiguration:
            return handleSetClientConfiguration(state, action);
        case srvSessionActions.ServerSessionActionTypes.LoadLicense:
            return handleLoadLicense(state, action);
        case srvSessionActions.ServerSessionActionTypes.SetLicense:
            return handleSetLicense(state, action);
        default:
            return state;
    }
}
exports.serverSessionReducer = serverSessionReducer;
function handleInitializeClientId(state, action) {
    return Object.assign({}, state, { clientId: guid_typescript_1.Guid.create().toString() });
}
function handleSetSessionEnded(state, action) {
    return Object.assign({}, state, { sessionEnded: action.payload });
}
function handleSetReturnUrl(state, action) {
    return Object.assign({}, state, { returnUrl: action.payload });
}
function handleLoadServerDesktopOptions(state, action) {
    return state;
}
function handleSetServerDesktopOptions(state, action) {
    return Object.assign({}, state, { desktopOptions: action.payload });
}
function handleLoadClientConfiguration(state, action) {
    return state;
}
function handleSetClientConfiguration(state, action) {
    return Object.assign({}, state, { clientConfiguration: action.payload });
}
function handleLoadLicense(state, action) {
    return state;
}
function handleSetLicense(state, action) {
    return Object.assign({}, state, { license: action.payload });
}
