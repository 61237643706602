export class TdLoadPanelModel {

  public visible: boolean;
  public message: string;

  constructor(visible: boolean = false, message: string = '') {
    this.visible = visible;
    this.message = message;
  }

}
