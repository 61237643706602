"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var po_data_model_1 = require("./po-data.model");
var InfoOverviewType;
(function (InfoOverviewType) {
    InfoOverviewType[InfoOverviewType["none"] = 0] = "none";
    InfoOverviewType[InfoOverviewType["units"] = 1] = "units";
    InfoOverviewType[InfoOverviewType["orders"] = 2] = "orders";
    InfoOverviewType[InfoOverviewType["endoDryers"] = 3] = "endoDryers";
    InfoOverviewType[InfoOverviewType["endoListUse"] = 4] = "endoListUse";
    InfoOverviewType[InfoOverviewType["endoListClean"] = 5] = "endoListClean";
    InfoOverviewType[InfoOverviewType["needsList"] = 6] = "needsList";
    InfoOverviewType[InfoOverviewType["incubators"] = 7] = "incubators";
    InfoOverviewType[InfoOverviewType["indicatorQueue"] = 8] = "indicatorQueue";
    InfoOverviewType[InfoOverviewType["prodOverview"] = 9] = "prodOverview";
    InfoOverviewType[InfoOverviewType["endoProdOverview"] = 10] = "endoProdOverview";
    InfoOverviewType[InfoOverviewType["itemExchange"] = 11] = "itemExchange";
    InfoOverviewType[InfoOverviewType["distribution"] = 12] = "distribution";
    InfoOverviewType[InfoOverviewType["scheduledOperation"] = 13] = "scheduledOperation";
    InfoOverviewType[InfoOverviewType["returnRequested"] = 14] = "returnRequested";
})(InfoOverviewType = exports.InfoOverviewType || (exports.InfoOverviewType = {}));
var InfoOverview = /** @class */ (function () {
    function InfoOverview() {
        this.configId = null; // IOCKEYID
        this.configName = null; // IOCNAME
        this.configType = null; // IOCCONFIGTYPE
        this.data = null; // production data (e.g.: list of KPIs calculated by JSrv)
    }
    return InfoOverview;
}());
exports.InfoOverview = InfoOverview;
var ProdOverviewIOConfigBase = /** @class */ (function () {
    function ProdOverviewIOConfigBase() {
        this.users = null;
        this.incomUnits = null;
        this.unitsAtCust = null;
        this.ftUnits = null;
        this.errorNumbers = null;
        this.sbCurrUnit = null;
        this.sbProdStatus = null;
        this.sbUncleanArea = null;
        this.sbCleanArea = null;
        this.sbWashers = null;
        // extra info
        this.temperatureDegree = null;
        this.allowKpiClick = null;
    }
    return ProdOverviewIOConfigBase;
}());
exports.ProdOverviewIOConfigBase = ProdOverviewIOConfigBase;
var ProdOverviewIOConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProdOverviewIOConfig, _super);
    function ProdOverviewIOConfig(ioConfig) {
        var _this = _super.call(this) || this;
        _this.unitsOnStock = null;
        _this.sbSterileArea = null;
        _this.sbSterilizers = null;
        if (ioConfig) {
            Object.assign(_this, ioConfig);
        }
        return _this;
    }
    return ProdOverviewIOConfig;
}(ProdOverviewIOConfigBase));
exports.ProdOverviewIOConfig = ProdOverviewIOConfig;
var EndoProdOverviewIOConfig = /** @class */ (function (_super) {
    tslib_1.__extends(EndoProdOverviewIOConfig, _super);
    function EndoProdOverviewIOConfig(ioConfig) {
        var _this = _super.call(this) || this;
        _this.unitsInTransp = null;
        _this.unitsInSterils = null;
        _this.sbDryingCabs = null;
        _this.endoIdentifier = null;
        if (ioConfig) {
            Object.assign(_this, ioConfig);
        }
        return _this;
    }
    return EndoProdOverviewIOConfig;
}(ProdOverviewIOConfigBase));
exports.EndoProdOverviewIOConfig = EndoProdOverviewIOConfig;
// object that we are receiving from back-end
var InfoOverviewData = /** @class */ (function () {
    function InfoOverviewData(ioData) {
        this.configId = null;
        this.data = null;
        if (ioData) {
            this.configId = ioData.configId;
            this.data = new po_data_model_1.ProdOverviewData();
            Object.assign(this.data, ioData.data);
            this.data.hasConnection = true;
        }
    }
    return InfoOverviewData;
}());
exports.InfoOverviewData = InfoOverviewData;
