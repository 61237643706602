import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ProductModel } from '../models/product.model';
import { ProductViewModel } from '../models/product-view.model';
import { ItemInstrumentType, WebDisplayPriceMode, ProdReservationMode, ArticleStatus } from '@core/data-layer/shared/models/td.enumerations';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { ViewHelperService } from '@core/data-layer/shared/services/view-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ProductHelperService {

  constructor(
    private translateService: TranslateService,
    private sessionHelperService: SessionHelperService,
    private viewHelperService: ViewHelperService
  ) { }

  /**
   * @description converts [ProductModel][Object] to [ProductViewModel][Object]
   * @param product: [ProductModel][Object]
   * @returns [ProductViewModel][Object]
   */
  convertProductToViewModel(product: ProductModel): ProductViewModel {
    const type = this.viewHelperService.getItemType(product.item);
    const isEndo = type === ItemInstrumentType.Endoscope;
    return {
      ...product,
      supplierText: this.getSupplierText(product, isEndo),
      productCaption: isEndo ? this.translateService.instant('appCommon.endoscope') : this.translateService.instant('appCommon.product'),
      productText: product.product + ' - ' + product.item.text,
      customerText: product.custKeyId ? product.cust.customer + ' ' + product.cust.name : '',
      reservationModeText: this.translateService.instant(`prodReservationMode.${ProdReservationMode[product.reservationMode].toLowerCase()}`),
      omitted: (product.status === ArticleStatus.Dead),
      omittedText: this.viewHelperService.getOmittedText(product.status, this.translateService.instant('appCommon.product')),
      omittedImgUrl: this.viewHelperService.getOmittedImgUrl(product.status),
      itemType: type,
      isEndoscope: isEndo,
      displayPrice: (this.sessionHelperService.serverOptions.displayPriceMode !== WebDisplayPriceMode.HideAll)
    };
  }

  getSupplierText(product: ProductModel, isEndo: boolean): string {
    let suppText = '';
    if (isEndo && product.item.suppKeyId) {
      suppText = product.item.supp.supplier;
      if (suppText && product.item.supp.name) {
        suppText = suppText + ' - ' + product.item.supp.name;
      }
    }
    return suppText;
  }
}
