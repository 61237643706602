"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var customer_selectors_1 = require("../data-layer/customer/store/customer.selectors");
var utility_service_1 = require("../utility/utility.service");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var td_enumerations_1 = require("../data-layer/shared/models/td.enumerations");
var td_popup_service_1 = require("@shared/components/td-popup/services/td-popup.service");
var app_constants_1 = require("@shared/models/app.constants");
var info_overview_selectors_1 = require("@app/dashboard/data-layer/info-overview/store/info-overview.selectors");
var production_overview_constants_1 = require("@app/dashboard/models/production-overview.constants");
var td_constants_1 = require("../data-layer/shared/models/td.constants");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("../utility/utility.service");
var i3 = require("../../shared/services/session-helper.service");
var i4 = require("@angular/router");
var i5 = require("../../shared/components/td-popup/services/td-popup.service");
var RouteGuard = /** @class */ (function () {
    function RouteGuard(authService, store, utilityService, sessionHelperService, router, popupService) {
        var _this = this;
        this.authService = authService;
        this.store = store;
        this.utilityService = utilityService;
        this.sessionHelperService = sessionHelperService;
        this.router = router;
        this.popupService = popupService;
        this.customerSelected = false;
        this.store.pipe(store_1.select(customer_selectors_1.selectCurrentCustomer)).subscribe(function (cust) {
            _this.customerSelected = !_this.utilityService.objIsEmpty(cust);
        });
    }
    RouteGuard.prototype.canActivate = function (route) {
        var response = false;
        var routeTarget = route.data.path;
        var childId;
        if (route.children.length > 0) {
            childId = route.children[0].params['id'];
            routeTarget += "/" + childId;
        }
        /*
          How a route is guarded is determined by two factors:
            guardType, which can be 1 of 2:
              LoginModeAlways: If set, requires the user to be logged in and authenticated to access a route
              AuthenticatedUserAndCustomerSelected: Some routes will always require you to be logged in and have a customer selected, independent of loginMode
            loginMode, which can be 1 of 2:
              Always: The route always require the user to be logged in and a customer to be selected
              WhenSpecified: The route can be accessed without being logged in
        */
        /**
         * Route: production-overview
         */
        if (route.data.path && route.data.path === app_constants_1.ROUTE_PATHS.productionOverview) {
            response = this.handleProductionOverviewRequest(route);
            return response;
        }
        /**
         * Any other routes are checked by a combination of guardType and loginMode
         */
        var loginMode = this.sessionHelperService.clientConfiguration.loginMode;
        var guardType = route.data[app_constants_1.GUARD_TYPE];
        if (guardType === app_constants_1.GuardTypes.AuthenticatedUserAndCustomerSelected) {
            response = this.checkIfUserIsAuthenticatedAndCustomerIsSelected(routeTarget);
        }
        else if (guardType === app_constants_1.GuardTypes.LoginModeAlways) {
            if (loginMode === td_enumerations_1.WebLoginMode.Always) {
                response = this.checkIfUserIsAuthenticatedAndCustomerIsSelected(routeTarget);
            }
            else if (loginMode === td_enumerations_1.WebLoginMode.WhenSpecified) {
                response = true;
            }
        }
        else if (guardType === app_constants_1.GuardTypes.AllowAlways) {
            response = true;
        }
        // check there is "operation" module included in license
        if (response && route.data.path && route.data.path === app_constants_1.ROUTE_PATHS.operations && !this.sessionHelperService.canViewOperation()) {
            this.router.navigate(['/error'], { queryParams: { missedModule: td_constants_1.TD_MODULES.operation } });
        }
        return response;
    };
    RouteGuard.prototype.checkIfUserIsAuthenticatedAndCustomerIsSelected = function (url) {
        if (this.authService.isAuthenticated() && this.customerSelected) {
            return true;
        }
        else {
            this.authService.startSignin(url + '?cancel=' + app_constants_1.ROUTE_PATHS.landing);
            return false;
        }
    };
    RouteGuard.prototype.handleProductionOverviewRequest = function (route) {
        var _this = this;
        var response = false;
        if (this.sessionHelperService.canViewProductionOverview()) {
            this.store.pipe(store_1.select(info_overview_selectors_1.selectInfoOverviews)).subscribe(function (infoOverviews) {
                var match = infoOverviews.find(function (entry) { return entry.configId === Number(route.queryParams[production_overview_constants_1.POConst.TDOC_IO_ID]); });
                if (match) {
                    response = true;
                }
                else if (_this.authService.isAuthenticated() && _this.customerSelected) {
                    _this.displayErrorDialog('appMessages.productionOverviewNotAvailableToCustomer');
                }
                else {
                    _this.displayErrorDialog('appMessages.productionOverviewNotAvailableMayRequireLogin');
                }
            });
        }
        else {
            this.displayErrorDialog('appMessages.productionOverviewNotAvailableMayRequireLogin');
        }
        return response;
    };
    RouteGuard.prototype.displayErrorDialog = function (translateKey) {
        this.popupService.showError(translateKey, true);
        this.router.navigateByUrl('');
    };
    RouteGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouteGuard_Factory() { return new RouteGuard(i0.ɵɵinject("AUTH_SERVICE"), i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.UtilityService), i0.ɵɵinject(i3.SessionHelperService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.TdPopupService)); }, token: RouteGuard, providedIn: "root" });
    return RouteGuard;
}());
exports.RouteGuard = RouteGuard;
