"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var store_1 = require("@ngrx/store");
var effects_1 = require("@ngrx/effects");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var info_overview_actions_1 = require("./info-overview.actions");
var info_overview_helper_service_1 = require("@app/dashboard/services/info-overview-helper.service");
var info_overview_model_1 = require("@app/dashboard/models/info-overview.model");
var info_overview_selectors_1 = require("./info-overview.selectors");
var session_helper_service_1 = require("@app/shared/services/session-helper.service");
var InfoOverviewEffects = /** @class */ (function () {
    function InfoOverviewEffects(actions$, store$, ioService, ioHelper, sessionHelperService) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.ioService = ioService;
        this.ioHelper = ioHelper;
        this.sessionHelperService = sessionHelperService;
        this.loadIOConfigurations$ = this.actions$
            .pipe(effects_1.ofType(info_overview_actions_1.InfoOverviewActionTypes.LoadIOConfigurations), operators_1.mergeMap(function () { return _this.ioService.getInfoOverviews([info_overview_model_1.InfoOverviewType.prodOverview, info_overview_model_1.InfoOverviewType.endoProdOverview], _this.sessionHelperService.userPrimaryFactoryKeyId, _this.sessionHelperService.profileData.system_CanViewProductionOverview).pipe(operators_1.map(function (ioConfigurations) { return ({ type: info_overview_actions_1.InfoOverviewActionTypes.LoadIOConfigurationsSuccess, payload: ioConfigurations }); }), operators_1.catchError(function () { return rxjs_1.of({ type: info_overview_actions_1.InfoOverviewActionTypes.LoadIOConfigurationsError }); })); }));
        this.getIOConfigurationById$ = this.actions$
            .pipe(effects_1.ofType(info_overview_actions_1.InfoOverviewActionTypes.GetIOConfigurationById), operators_1.mergeMap(function (act) { return _this.ioService.getInfoOverview(act.payload)
            .pipe(operators_1.map(function (ioConfiguration) { return ({ type: info_overview_actions_1.InfoOverviewActionTypes.GetIOConfigurationByIdSuccess, payload: ioConfiguration }); }), operators_1.catchError(function () { return rxjs_1.EMPTY; })); }));
        this.setIOData$ = this.actions$
            .pipe(effects_1.ofType(info_overview_actions_1.InfoOverviewActionTypes.SetIOData), operators_1.withLatestFrom(this.store$.pipe(store_1.select(info_overview_selectors_1.selectInfoOverviews))), operators_1.mergeMap(function (_a) {
            var act = _a[0], infoOverviews = _a[1];
            var ioDataPayload = act.payload;
            if (ioDataPayload) {
                var filteredIO = infoOverviews.filter(function (io) { return io.configId === ioDataPayload.configId; });
                if ((filteredIO.length > 0) && [info_overview_model_1.InfoOverviewType.prodOverview, info_overview_model_1.InfoOverviewType.endoProdOverview].includes(filteredIO[0].configType)) {
                    Object.assign(ioDataPayload.data, _this.ioHelper.adjustPOData(ioDataPayload.data));
                }
            }
            return rxjs_1.of(ioDataPayload).pipe(operators_1.map(function (ioData) { return ({ type: info_overview_actions_1.InfoOverviewActionTypes.SetIODataSuccess, payload: ioData }); }), operators_1.catchError(function () { return rxjs_1.EMPTY; }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InfoOverviewEffects.prototype, "loadIOConfigurations$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InfoOverviewEffects.prototype, "getIOConfigurationById$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InfoOverviewEffects.prototype, "setIOData$", void 0);
    return InfoOverviewEffects;
}());
exports.InfoOverviewEffects = InfoOverviewEffects;
