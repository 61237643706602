import { Injectable, Inject } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { UserSessionActionTypes } from './user-session.actions';
import { UserSessionServiceInterface } from '../services/user-session.service.interface';

@Injectable()
export class UserSessionEffects {

  constructor(
    private actions$: Actions,
    @Inject('USER_SESSION_SERVICE') private userSessionService: UserSessionServiceInterface) {
  }

  @Effect()
  loadProfileData$ = this.actions$
    .pipe(
      ofType(UserSessionActionTypes.LoadProfileData),
      mergeMap(() => {
        return this.userSessionService.getProfileData()
          .pipe(
            map(profileData => ({ type: UserSessionActionTypes.SetProfileData, payload: profileData })),
            catchError(() => of({ type: UserSessionActionTypes.SetProfileData }))
          );
      })
    );

  @Effect()
  loadUserOptions$ = this.actions$
    .pipe(
      ofType(UserSessionActionTypes.LoadUserOptions),
      mergeMap(() => {
        return this.userSessionService.getUserOptions()
          .pipe(
            map(response => ({ type: UserSessionActionTypes.SetUserOptions, payload: response })), // TODO: handle error that comes from backend (response.error)
            catchError(() => of({ type: UserSessionActionTypes.SetUserOptions }))
          );
      })
    );

  @Effect()
  loadDefaultUserOptions$ = this.actions$
    .pipe(
      ofType(UserSessionActionTypes.LoadDefaultUserOptions),
      mergeMap(() => {
        return this.userSessionService.getDefaultUserOptions()
          .pipe(
            map(response => ({ type: UserSessionActionTypes.SetDefaultUserOptions, payload: response })), // TODO: handle error that comes from backend (response.error)
            catchError(() => of({ type: UserSessionActionTypes.SetDefaultUserOptions }))
          );
      })
    );
}
