import { Action } from '@ngrx/store';

import { ServerDesktopOptionsModel } from '../models/server-desktop-options.model';
import { LicenseModel } from '@core/license/models/license.model';
import { ClientConfigurationModel } from '@core/client-configuration/models/client-configuration.model';

export enum ServerSessionActionTypes {
  InitializeClientId = '[ServerSession] Initialize Client Id',
  SetSessionEnded = '[ServerSession] Set Session Ended',
  SetReturnUrl = '[ServerSession] Set Return Url',

  LoadLicense = '[ServerSession] Load License',
  SetLicense = '[ServerSession] Set License',

  LoadServerDesktopOptions = '[ServerSession] Load Server Desktop Options',
  SetServerDesktopOptions = '[ServerSession] Set Server Desktop Options',

  LoadClientConfiguration = '[ServerSession] Load Client Configuration',
  SetClientConfiguration = '[ServerSession] Set Client Configuration'
}

export class InitializeClientId implements Action {
  readonly type = ServerSessionActionTypes.InitializeClientId;

  constructor() { }
}

export class SetSessionEnded implements Action {
  readonly type = ServerSessionActionTypes.SetSessionEnded;

  constructor(public payload: boolean) { }
}

export class SetReturnUrl implements Action {
  readonly type = ServerSessionActionTypes.SetReturnUrl;

  constructor(public payload: string) { }
}

export class LoadLicense implements Action {
  readonly type = ServerSessionActionTypes.LoadLicense;

  constructor() { }
}

export class SetLicense implements Action {
  readonly type = ServerSessionActionTypes.SetLicense;

  constructor(public payload: LicenseModel) { }
}

export class LoadServerDesktopOptions implements Action {
  readonly type = ServerSessionActionTypes.LoadServerDesktopOptions;

  constructor() { }
}

export class SetServerDesktopOptions implements Action {
  readonly type = ServerSessionActionTypes.SetServerDesktopOptions;

  constructor(public payload: ServerDesktopOptionsModel) { }
}

export class LoadClientConfiguration implements Action {
  readonly type = ServerSessionActionTypes.LoadClientConfiguration;

  constructor() { }
}

export class SetClientConfiguration implements Action {
  readonly type = ServerSessionActionTypes.SetClientConfiguration;

  constructor(public payload: ClientConfigurationModel) { }
}

export type ServerSessionActions = InitializeClientId | SetSessionEnded | SetReturnUrl | LoadLicense | SetLicense | LoadServerDesktopOptions | SetServerDesktopOptions | LoadClientConfiguration | SetClientConfiguration;
