import { Action } from '@ngrx/store';

import { FactoryModel } from '../models/factory.model';

export enum FactoryActionTypes {
  LoadAllFactories = '[Factory] Load All Factories',
  SetAllFactories = '[Factory] Set All Factories',

  LoadUserFactories = '[Factory] Load User Factories',
  SetUserFactories = '[Factory] Set User Factories',
  LoadCustomerFactories = '[Factory] Load Customer Factories',
  SetCustomerFactories = '[Factory] Set Customer Factories',
  SetCustomerFactoriesFromAll = '[Factory] Set Customer Factories From All'
}

export class LoadAllFactories implements Action {
  readonly type = FactoryActionTypes.LoadAllFactories;

  constructor() { }
}

export class SetAllFactories implements Action {
  readonly type = FactoryActionTypes.SetAllFactories;

  constructor(public payload: FactoryModel[]) { }
}

export class LoadUserFactories implements Action {
  readonly type = FactoryActionTypes.LoadUserFactories;

  constructor() { }
}

export class SetUserFactories implements Action {
  readonly type = FactoryActionTypes.SetUserFactories;

  constructor(public payload: FactoryModel[]) { }
}

export class LoadCustomerFactories implements Action {
  readonly type = FactoryActionTypes.LoadCustomerFactories;

  constructor(public payload: number) { }
}

export class SetCustomerFactories implements Action {
  readonly type = FactoryActionTypes.SetCustomerFactories;

  constructor(public payload: FactoryModel[]) { }
}

export class SetCustomerFactoriesFromAll implements Action {
  readonly type = FactoryActionTypes.SetCustomerFactoriesFromAll;

  constructor() { }
}

export type FactoryActions = LoadAllFactories | SetAllFactories | LoadUserFactories | SetUserFactories | LoadCustomerFactories | SetCustomerFactories | SetCustomerFactoriesFromAll;
