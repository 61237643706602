import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { TdPopupService } from '../td-popup/services/td-popup.service';

@Component({
  selector: 'td-change-password-success',
  template: ''
})

export class ChangePasswordSuccessComponent {
   
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private popup: TdPopupService,
    private translateService: TranslateService
  ) {
   }

  ngAfterViewInit() {         
    this.popup.showInfo(this.translateService.instant('settings.passwordChanged'));        
      this.route.queryParams.subscribe(params => {
        this.router.navigate([params['redirectUrl']]);
      });
  }
}