import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, ElementRef} from '@angular/core';
import { Store } from '@ngrx/store';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';

import { TableTypes, MultiMediaType } from '@core/data-layer/shared/models/td.enumerations';
import { GalleryItemInterface } from './models/gallery-item.interface';
import { MediaServiceInterface } from '@core/media/services/media.service.interface';
import { MediaInfoInterface } from '@core/media/models/media-info.interface';
import { MEDIA_SERIES_PRIORITY } from '@app/shared/models/app.constants';
import { SessionHelperService } from '@app/shared/services/session-helper.service';
import { UserSessionServiceInterface } from '@core/data-layer/user-session/services/user-session.service.interface';
import { AppState } from '@core/data-layer/app.state';
import { LoadUserOptions } from '@core/data-layer/user-session/store/user-session.actions';
import { UserDesktopOptionsModel } from '@core/data-layer/user-session/models/user-desktop-options.model';

@Component({
  selector: 'td-media-viewer',
  templateUrl: './td-media-viewer.component.html',
  styleUrls: ['./td-media-viewer.component.less']
})
export class TdMediaViewerComponent implements OnInit {

  mediaButtonsWidth = 36;
  thumbnailButtonsWidth = 36 + 10 + 36;
  thumbnailWidth = 70;
  thumbnailHeight = 70;
  popupHeight = '80vh';
  popupWidth = '60vw';
  firstIndex = 0;
  thumbnailNavigationWidth = 200;

  @Input() mediaVisible: boolean;
  @Output() mediaVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() tableType: TableTypes;
  @Input() refKeyId: number;
  @Input() mediaTitle: string;
  @Input() mediaSeries: number;
  @Input() mediaIndex: number;

  @ViewChild('popUp', { static: false }) popupRef?: DxPopupComponent;
  @ViewChild('mediaVideo', { static: false }) mediaVideoRef?: ElementRef;

  userOptions: UserDesktopOptionsModel;

  mediaItems: GalleryItemInterface[];
  currentMediaItem: GalleryItemInterface;
  multiMediaType = MultiMediaType;
  tableTypes = TableTypes;

  swiperMediaIndex = 0;
  swiperConfig: SwiperConfigInterface;
  lastIndex: number;
  mediaSlideWrapperHeight: number;
  mediaSlideWrapperWidth: number;
  thumbnailWrapperWidth: number;
  resizeCurentSlideIndex: number;
  displayMediaViewer = true;
  imageWidth: string;
  imageHeight: string;
  moreThanOneMedia = true;
  showThumbnails = true;
  swiperWidth: number;
  showMediaViewer = false;

  showMediaViewOptions: boolean;
  autoStartVideo: boolean;

  constructor(
    @Inject('MEDIA_SERVICE') private mediaService: MediaServiceInterface,
    @Inject('USER_SESSION_SERVICE') private userSessionService: UserSessionServiceInterface,
    private sessionHelperService: SessionHelperService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.userOptions = { ...this.sessionHelperService.userOptions };
    this.getData();
    this.initAutoStartVideoFlag();
  }

  getData(): void {
    this.mediaService.loadMediaInfoList(this.tableType, this.mediaSeries, this.refKeyId).subscribe(response => {
      let relevantMediaSeries = [];
      for (let type of MEDIA_SERIES_PRIORITY) {
        relevantMediaSeries = response.filter(entry => entry.linkType === type);
        if (relevantMediaSeries.length > 0) {
          break;
        }
      }
      this.handleMediaInfoResponse(relevantMediaSeries);
    });
  }

  handleMediaInfoResponse(data: MediaInfoInterface[]): void {
    this.mediaItems = [];
    data.forEach(entry => {
      let mediaItem: GalleryItemInterface = {
        keyId: entry.keyId,
        uniqueIndex: entry.position,
        types: entry.types,
        mediaType: null
      };
      this.getMediaThumbnail(mediaItem);
      this.mediaItems.push(mediaItem);
    });
    this.getMedia(this.mediaItems[0]);
    if (this.mediaItems.length < 2) {
      this.moreThanOneMedia = false;
    }        
    this.lastIndex = this.mediaItems.length - 1;
    if (this.mediaItems.length > 0) {
      this.currentMediaItem = this.mediaItems[0];
      this.autoStartStopPlayingVideo();
    }
  }

  resetMediaIndex(): void {
    this.mediaIndex = 0;
  }

  initSwiperConfig() {
    this.swiperConfig = {
      centeredSlides: true,
      spaceBetween: 10,
      slidesPerView: 1,
      watchOverflow: true,
      observer: true,
      navigation: true
    };
  }

  public mediaIndexChange(index: any) {
    const mediaToLoad = this.mediaItems[index];
    if (!mediaToLoad.video || !mediaToLoad.image) {
      this.getMedia(mediaToLoad);
    }
    if (this.moreThanOneMedia && this.showThumbnails) {
      this.swiperMediaIndex = index;    
      const element = document.getElementById('thumbnail-img-' + index);
      element.scrollIntoView();
    }
    this.currentMediaItem = this.mediaItems[index];
    setTimeout(() => {
      this.autoStartStopPlayingVideo();
    });
  }

  // CURRENTLY NOT USED
  startResizeMediaViewer() {
    // const currentImageIndex = this.swiperMediaIndex;    
    // this.resizeCurentSlideIndex = currentImageIndex;
    // this.swapArrayPosition(this.mediaItems, this.firstIndex, currentImageIndex);
    // this.swiperMediaIndex = this.firstIndex;
  }

  // CURRENTLY NOT USED
  endResizeMediaViewer() {
    // this.swapArrayPosition(this.mediaItems, this.firstIndex, this.resizeCurentSlideIndex);    
    // this.mediaIndexChange(this.resizeCurentSlideIndex);
    // /* 
    //   Explaining following if statement
    //   Issue: When we use Swiper API, automatic centering of slides does not perform correctly, if we are at index 0.
    //   Solution: The slide will be centered correctly, after navigating 1 slide forward and 1 back.
    //   Implementation: We change the index 1 forward, and after 10 miliseconds we change it back.
    // */
    
    // if (this.moreThanOneMedia && this.swiperMediaIndex === this.firstIndex) {   
      // this.swiperMediaIndex++;
      // setTimeout(() => {
      //   this.swiperMediaIndex--;
      // }, 10);      
    // }
  }

  swapArrayPosition(array: GalleryItemInterface[], indexOne: number, indexTwo: number): void {
    [array[indexOne], array[indexTwo]] = [array[indexTwo], array[indexOne]];
  }

  thumbnailClick(mediaIndex: number) {
    this.swiperMediaIndex = mediaIndex;
  }

  nextThumb(): void {
    this.swiperMediaIndex++;
  }
  previousThumb(): void {
    this.swiperMediaIndex--;
  }
  lastThumb(): void {
    this.swiperMediaIndex = this.lastIndex;
  }
  firstThumb(): void {
    this.swiperMediaIndex = this.firstIndex;
  }

  nextMedia(): void {
    this.swiperMediaIndex++;
  }

  closeMediaViewer(): void {
    this.mediaVisible = false;
    this.mediaVisibleChange.emit(this.mediaVisible);
  }

  getMedia(mediaItem: GalleryItemInterface): void {
    mediaItem.types.forEach(type => {
      switch (type.type) {
        case 'Image': {
          if (type.identifier === 'thumbnail') {
            break;
          }
          this.getMediaImage(mediaItem);
          break;
        }
        case 'Text': {
          this.getMediaText(mediaItem);
          break;
        }
        case 'Video': {
          mediaItem.videoFileType = type.format.toLowerCase();
          this.getMediaVideo(mediaItem);
          break;
        }
      }        
    });
  }

  getMediaThumbnail(mediaItem: GalleryItemInterface): void {
    this.mediaService.getMediaThumbnail(mediaItem.keyId).subscribe(thumbnail => {
      mediaItem.thNImage = thumbnail;
    });
  }

  getMediaImage(mediaItem: GalleryItemInterface): void {
    this.mediaService.getMediaImage(mediaItem.keyId).subscribe(data => {      
      mediaItem.image = data;
      mediaItem.mediaType = MultiMediaType.Picture;      
      this.assignSwiperWidth();
      this.showMediaViewerIfData();
    });
  }

  getMediaText(mediaItem: GalleryItemInterface): void {    
    this.mediaService.getMediaText(mediaItem.keyId).subscribe(data => {
      mediaItem.text = data;
    });
  }

  getMediaVideo(mediaItem: GalleryItemInterface): void {
    mediaItem.video = this.mediaService.getMediaVideo(mediaItem.keyId);      
    mediaItem.mediaType = MultiMediaType.Video;
    this.assignSwiperWidth();
    this.showMediaViewerIfData();
  }

  isVideoTypeSupported(type: string): boolean {
    return type === '.mov' || type === '.mp4' ? true : false;
  }

  assignSwiperWidth() {    
    if (!this.swiperWidth) {
      this.initSwiperConfig();
      setTimeout(() => {
        this.getPopupSize(); 
      }, 50);   
    }
  }

  showMediaViewerIfData() {
      if (!this.showMediaViewer) {
        this.showMediaViewer = true;
      }    
  }
  getPopupSize(): void {
    this.swiperWidth = this.popupRef.instance.content().clientWidth;
  }

  initAutoStartVideoFlag(): void {
    this.autoStartVideo =
      (this.sessionHelperService.profileData.system_CanChangeVideoAutoplay && this.userOptions.mediaAutoPlayVideos) ||
      (!this.sessionHelperService.profileData.system_CanChangeVideoAutoplay &&
        (this.sessionHelperService.customerFactories.length > 0) &&
        this.sessionHelperService.customerFactories[0].autoPlayVideos);
  }

  setMediaViewOptionsVisibility(): void {
    this.showMediaViewOptions = this.sessionHelperService.profileData.system_CanChangeVideoAutoplay &&
      (this.currentMediaItem.mediaType === MultiMediaType.Video) &&
      this.isVideoTypeSupported(this.currentMediaItem.videoFileType);
  }

  autoStartStopPlayingVideo() {
    this.setMediaViewOptionsVisibility();
    if (this.mediaVideoRef) {
      if (this.showMediaViewOptions ||
        ((this.currentMediaItem.mediaType === MultiMediaType.Video) &&
          this.isVideoTypeSupported(this.currentMediaItem.videoFileType) && this.autoStartVideo)) {
        this.mediaVideoRef.nativeElement.load();
      }
      else {
        this.mediaVideoRef.nativeElement.pause();
      }
    }
  }

  autoPlayVideosChanged(): void {
    this.initAutoStartVideoFlag();
    this.userSessionService.saveUserOptions(this.userOptions).subscribe(() => {
      this.store.dispatch(new LoadUserOptions());
    });
  }
}
