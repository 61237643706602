"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@ngx-translate/core");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var grid_column_helper_service_1 = require("@shared/services/grid-column-helper.service");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("../../../shared/services/session-helper.service");
var i3 = require("../../../shared/services/grid-column-helper.service");
var TableInfoHelperService = /** @class */ (function () {
    function TableInfoHelperService(translateService, sessionHelperService, gridColumnHelperService) {
        this.translateService = translateService;
        this.sessionHelperService = sessionHelperService;
        this.gridColumnHelperService = gridColumnHelperService;
    }
    TableInfoHelperService.prototype.exceptionColumnTranslation = function (displayName, fieldName) {
        // special-field columns (Created, Created by, Modified, Modified by)
        if ((fieldName === td_constants_1.TD_SPECIAL_FIELDS.created) || (fieldName === td_constants_1.TD_SPECIAL_FIELDS.modified) ||
            (fieldName === td_constants_1.TD_SPECIAL_FIELDS.createdUser) || (fieldName === td_constants_1.TD_SPECIAL_FIELDS.modifiedUser) ||
            (fieldName === td_constants_1.TD_SPECIAL_FIELDS.createdKeyId) || (fieldName === td_constants_1.TD_SPECIAL_FIELDS.modifiedKeyId)) {
            return this.translateService.instant('specialColumns.' + fieldName);
        }
        else if (displayName.endsWith(td_constants_1.TD_MODEL_NAMES.unitModel + '.' + td_constants_1.TD_DYNAMIC_FIELDS.keyId)) {
            return this.translateService.instant(td_constants_1.TD_MODEL_NAMES.unitModel + '.' + td_constants_1.TD_DYNAMIC_FIELDS.unit);
        }
        else if (displayName.endsWith(td_constants_1.TD_MODEL_NAMES.processModel + '.' + td_constants_1.TD_DYNAMIC_FIELDS.keyId)) {
            return this.translateService.instant(td_constants_1.TD_MODEL_NAMES.processModel + '.' + td_constants_1.TD_DYNAMIC_FIELDS.batch);
        }
        else if (displayName.endsWith(td_constants_1.TD_MODEL_NAMES.compositeModel + '.' + td_constants_1.TD_DYNAMIC_FIELDS.keyId)) {
            return this.translateService.instant(td_constants_1.TD_MODEL_NAMES.compositeModel + '.' + td_constants_1.TD_DYNAMIC_FIELDS.key);
        }
        else {
            return '';
        }
    };
    TableInfoHelperService.prototype.translateExceptionAndNormalColumnDisplayName = function (displayName, fieldName) {
        // process "exception" columns
        var columnDisplayName = this.exceptionColumnTranslation(displayName, fieldName);
        if (columnDisplayName === '') {
            // process "normal" columns
            columnDisplayName = this.translateService.instant(displayName);
        }
        return columnDisplayName;
    };
    // Example of "raw" display-name to be translated:
    // "orderTemplateModel.suppRefSuppKeyId.supplierModel.facKeyId.factoryModel.createdKeyId.userModel.initials"
    TableInfoHelperService.prototype.prepareColumnDisplayNamePrefix = function (displayName) {
        var fieldParts = displayName.split('.');
        if ((fieldParts.length > 2) && (fieldParts.length % 2 === 0)) {
            var columnPrefix = '';
            // we should skip the last pair "ModelName.PropertyName" - will be prepared separately, later
            for (var i = 0; i < fieldParts.length - 2; i = i + 2) {
                columnPrefix = columnPrefix + this.translateExceptionAndNormalColumnDisplayName(fieldParts[i] + '.' + fieldParts[i + 1], fieldParts[i + 1]) + '.';
            }
            return columnPrefix;
        }
        else {
            return '';
        }
    };
    TableInfoHelperService.prototype.translateColumnDisplayName = function (gridColumn) {
        var columnDisplayName = '';
        // process "calculated" columns
        if (gridColumn.calculated) {
            columnDisplayName = this.translateService.instant('calcColumns.' + gridColumn.displayName);
        }
        // process "user-field" columns
        else if (gridColumn.userField) {
            if (gridColumn.displayName.includes(td_constants_1.TD_VALUES.userFieldDefaultDisplayNamePrefix)) {
                columnDisplayName = gridColumn.displayName.replace(td_constants_1.TD_VALUES.userFieldDefaultDisplayNamePrefix, this.translateService.instant('specialColumns.userField'));
            }
            else {
                columnDisplayName = gridColumn.displayName;
            }
        }
        else {
            // get field-name
            var dataFieldParts = gridColumn.dataField.split('.');
            var fieldName = dataFieldParts[dataFieldParts.length - 1];
            // get field-name with own table-name
            var displayName = gridColumn.displayName;
            var displayNameParts = gridColumn.displayName.split('.');
            if (displayNameParts.length > 2) {
                displayName = displayNameParts.slice(displayNameParts.length - 2).join('.');
            }
            columnDisplayName = this.translateExceptionAndNormalColumnDisplayName(displayName, fieldName);
        }
        return columnDisplayName;
    };
    TableInfoHelperService.prototype.convertGridTablesToViewModel = function (gridTables) {
        var _this = this;
        // initialize grid tables
        var gridTablesView = [];
        if (this.sessionHelperService.serverOptions.displayPriceMode === td_enumerations_1.WebDisplayPriceMode.HideAll) {
            // filter out tables related to pricing
            gridTables = gridTables.filter(function (table) { return table.tableName !== td_constants_1.TD_MODEL_NAMES.priceGroupModel; });
        }
        gridTables.forEach(function (table) {
            if (_this.sessionHelperService.serverOptions.displayPriceMode === td_enumerations_1.WebDisplayPriceMode.HideAll) {
                // filter out columns related to pricing
                table.columns = table.columns.filter(function (column) { return !_this.gridColumnHelperService.hidePriceColumn(column.dataField); });
            }
            // translate display names for all columns in table
            table.columns.forEach(function (column) {
                column.displayName = _this.translateColumnDisplayName(column);
            });
            // translate display name for table
            var tableView = tslib_1.__assign({}, table, { displayName: _this.translateService.instant('tables.' + table.tableName) });
            gridTablesView.push(tableView);
        });
        return gridTablesView;
    };
    TableInfoHelperService.prototype.convertGridColumnsConfigurationsToViewModel = function (columnsConfigurations) {
        var _this = this;
        var columnsConfigurationsView = [];
        columnsConfigurations.forEach(function (columnConfig) {
            // prepare column view
            var columnConfigView = tslib_1.__assign({}, columnConfig, { 
                // view properties
                displayName: _this.prepareColumnDisplayNamePrefix(columnConfig.displayName) + _this.translateColumnDisplayName(columnConfig) });
            columnsConfigurationsView.push(columnConfigView);
        });
        return columnsConfigurationsView;
    };
    TableInfoHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TableInfoHelperService_Factory() { return new TableInfoHelperService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.SessionHelperService), i0.ɵɵinject(i3.GridColumnHelperService)); }, token: TableInfoHelperService, providedIn: "root" });
    return TableInfoHelperService;
}());
exports.TableInfoHelperService = TableInfoHelperService;
