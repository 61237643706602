import { Injectable } from '@angular/core';

import { OperationDataModel } from '../models/operation-data.model';
import { OperationDataViewModel } from '../models/operation-data-view.model';
import { CustomerModel } from '@core/data-layer/customer/models/customer.model';
import { OrderModel } from '@core/data-layer/order/models/order.model';
import {
  OperationStatus,
  PrefListOrderCreateMode,
  OrderBookingStatus,
  OrderBookingMode,
  CustOrderBookingMode,
  OrderOrgStatus
} from '@core/data-layer/shared/models/td.enumerations';
import { OPERATION_STATUS, ORDER_BOOKING_STATUS, ORDER_BOOKING_MODE } from '@shared/models/app.constants';
import { LanguageService } from '@core/language/services/language.service';

@Injectable({
  providedIn: 'root'
})
export class OperationDataHelperService {

  constructor(private languageService: LanguageService) { }

  /**
   * @description converts [OperationDataModel][Object] to [OperationDataViewModel][Object]
   * @param operation: [OperationDataModel][Object]
   * @returns [OperationDataViewModel][Object]
   */
  convertOperationDataToViewModel(operation: OperationDataModel): OperationDataViewModel {
    return {
      ...operation,
      typeText: operation.opt ? `${operation.opt.code} ${operation.opt.name}` : '',
      statusText: this.languageService.translateEnumValue(OPERATION_STATUS, operation.status),
      roomText: operation.room ? `${operation.room.id} - ${operation.room.name || ''}` : '',
      patientText: operation.pat ? `${operation.pat.id} - ${operation.pat.name || ''}` : '',
      doctorText: operation.doct ? `${operation.doct.initials} - ${operation.doct.name}` : '',
      startTime: operation.startTime ? new Date(operation.startTime) : null,
      endTime: operation.endTime ? new Date(operation.endTime) : null,
      actualStartTime: operation.actualStartTime ? new Date(operation.actualStartTime) : null,
      actualEndTime: operation.actualEndTime ? new Date(operation.actualEndTime) : null,
      bookingStatusText: this.translateBookingStatusEnum(operation),
      bookingModeText: this.translateBookingModeEnum(operation)
    };
  }

  private translateBookingStatusEnum(operation: OperationDataModel): string {
    let bookingStatus: OrderBookingStatus;
    if (this.isBookingUndetermined(operation)) {
      bookingStatus = OrderBookingStatus.Undetermined;
    }
    else {
      bookingStatus = +operation.bookingStatus;
    }
    return this.languageService.translateEnumValue(ORDER_BOOKING_STATUS, bookingStatus);
  }

  private translateBookingModeEnum(operation: OperationDataModel): string {
    let bookingMode: OrderBookingMode;
    if (this.isBookingUndetermined(operation)) {
      bookingMode = OrderBookingMode.Undetermined;
    }
    else {
      if ((operation.status === OperationStatus.Accepted) && this.doesCustomerAllowBooking(operation.cust)) {
        if (this.canActivateOperationBooking(operation.refOpDOrderList)) {
          bookingMode = OrderBookingMode.Inactive;
        }
        else {
          bookingMode = OrderBookingMode.Active;
        }
      }
      else {
        bookingMode = OrderBookingMode.Undetermined;
      }
    }
    return this.languageService.translateEnumValue(ORDER_BOOKING_MODE, bookingMode);
  }

  private isBookingUndetermined(operation: OperationDataModel): boolean {
    return (
      [OperationStatus.Started, OperationStatus.Done, OperationStatus.Cancelled].includes(operation.status) ||
      (operation.prefListOrderMode !== PrefListOrderCreateMode.Immediate)
    );
  }

  private doesCustomerAllowBooking(customer: CustomerModel): boolean {
    return (!!customer) && customer.traceability &&
      [CustOrderBookingMode.Default, CustOrderBookingMode.Manual, CustOrderBookingMode.Automation].includes(+customer.orderBookingMode);
  }

  private canActivateOperationBooking(orderList: OrderModel[]): boolean {
    return orderList.some(order =>
      (!!order.reqDelTime) &&
      (order.orgStatus <= OrderOrgStatus.Sent) &&
      (order.bookingMode === OrderBookingMode.Inactive)
    );
  }
}
