import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { LicenseServiceInterface } from './license.service.interface';
import { LicenseModel } from '../models/license.model';
import { LicenseBindingsModel } from '../models/license-bindings.model';

@Injectable()
export class HttpLicenseService implements LicenseServiceInterface {

  constructor(
    private http: HttpClient
  ) { }

  licenseUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.licenseCtrl;
  }

  getLicense(): Observable<LicenseModel> {
    return this.http.get<LicenseModel>(this.licenseUrl());
  }

  getLicenseBindings(): Observable<LicenseBindingsModel> {
    return this.http.get<LicenseBindingsModel>(this.licenseUrl() + API_HUB_PATHS.bindings);
  }
}
