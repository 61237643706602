import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { POConst } from '@app/dashboard/models/production-overview.constants';
import { LoggerService } from '@core/logger/logger.service';
import { SignalRService } from '@core/signal-r/signal-r.service';
import { InfoOverviewService } from './info-overview.service';
import { InfoOverview, InfoOverviewType, InfoOverviewData } from '@app/dashboard/models/info-overview.model';
import { SetIOData, SetConnectedToHub, SetConnectedToJSrv } from '../store/info-overview.actions';
import { AppState } from '@core/data-layer/app.state';
import { ProdOverviewData } from '@app/dashboard/models/po-data.model';

// signal-r server methods
const HUB_SRV_METHOD_SUBSCRIBE_TO_INFO_OVERVIEW = 'AddSubscriptions';
const HUB_SRV_METHOD_UNSUBSCRIBE_FROM_INFO_OVERVIEW = 'RemoveSubscriptions';
const HUB_SRV_METHOD_REFRESH_INFO_OVERVIEW = 'Refresh';
// signal-r client methods
const HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_CONFIG = 'UpdateConfiguration';
const HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_DATA = 'UpdateData';
const HUB_CLIENT_METHOD_JOB_SRV_CONFIRMATION_MESSAGE = 'JobSrvConfirmationMessage';
const HUB_CLIENT_METHOD_PO_JOB_CONNECTION_ESTABLISHED = 'ProdOverviewJobConnectionEstablished';
const HUB_CLIENT_METHOD_PO_JOB_CONNECTION_LOST = 'ProdOverviewJobConnectionLost';
// signal-r client methods (users status)
const HUB_METHOD_SET_USERS_ONLINE = 'SetUsersOnline';
const HUB_METHOD_USERS_JOINED = 'UsersJoined';
const HUB_METHOD_USERS_LEFT = 'UsersLeft';

@Injectable()
export class SignalRInfoOverviewService extends SignalRService implements InfoOverviewService {

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    loggerService: LoggerService
  ) {
    super(loggerService);
    this.reconnectTimeout = POConst.RECONNECT_IO_TIMEOUT;
    this.initializeConnection();
  }

  // override the protected method
  protected getHubServerUrl(): string {
    return API_HUB_PATHS.serverUrl + API_HUB_PATHS.infoOverviewsHub;
  }

  // override the protected method
  public beforeInitialize(): void {
    this.store.dispatch(new SetConnectedToHub(false));
  }

  private infoOverviewsUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.infoOverviewsCtrl;
  }

  public getInfoOverviews(ioTypes: InfoOverviewType[], factoryKeyId: number, canViewProductionOverview: boolean): Observable<InfoOverview[]> {
    return this.http.get<InfoOverview[]>(`${this.infoOverviewsUrl()}?ioTypes=${ioTypes.join()}&factoryKeyId=${factoryKeyId}&canViewProductionOverview=${canViewProductionOverview}`).pipe(
      map(infoOverviews => {
        this.onUpdateIOConfiguration();
        return infoOverviews;
      })
    );
  }

  public getInfoOverview(id: number): Observable<InfoOverview> {
    return this.http.get<InfoOverview>(`${this.infoOverviewsUrl()}/${id}`).pipe(
      map(infoOverview => {
        return infoOverview;
      })
    );
  }

  // override the protected method
  protected onConnectionEstablished() {
    // if we want we can still explicitly call the initial method
    this.onJSrvConnectionLost();
    this.onJSrvConnectionEstablished();
    super.onConnectionEstablished();
    this.store.dispatch(new SetConnectedToHub(true));
  }

  // override the protected method
  protected onConnectionLost(errMsg: string) {
    // if we want we can still explicitly call the initial method
    super.onConnectionLost(errMsg);
    this.store.dispatch(new SetConnectedToHub(false));
  }

  public subscribeToIO(id: number) {
    if (this.connectedToHub()) {
      this.hubConnection.invoke(HUB_SRV_METHOD_SUBSCRIBE_TO_INFO_OVERVIEW, [id])
        .then(() => {
          this.onUpdateIOData();
          this.loggerService.log('Subscribed to info-overview [' + id + ']');
        })
        .catch(err => { this.loggerService.error(HUB_SRV_METHOD_SUBSCRIBE_TO_INFO_OVERVIEW + ' error: ' + err); });
    }
  }

  public unsubscribeFromIO(id: number) {
    if (this.connectedToHub()) {
      this.hubConnection.invoke(HUB_SRV_METHOD_UNSUBSCRIBE_FROM_INFO_OVERVIEW, [id]).
        then(() => {
          this.offUpdateIOData();
          this.loggerService.log('Unsubscribed from info-overview [' + id + ']');
        })
        .catch(err => { this.loggerService.error(HUB_SRV_METHOD_UNSUBSCRIBE_FROM_INFO_OVERVIEW + ' error: ' + err); });
    }
  }

  public refreshIO(id: number) {
    if (this.connectedToHub()) {
      this.hubConnection.invoke(HUB_SRV_METHOD_REFRESH_INFO_OVERVIEW, id).
        then(() => {
          this.loggerService.log('Refresh info-overview [' + id + ']');
        })
        .catch(err => { this.loggerService.error(HUB_SRV_METHOD_REFRESH_INFO_OVERVIEW + ' error: ' + err); });
    }
  }

  // TODO: "update" local copy of changed info-overview or "add" new info overview in list
  public onUpdateIOConfiguration(): void {
    if (this.connectedToHub()) {
      this.hubConnection.on(HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_CONFIG, (ioConfig) => {
        /*var addNewIOInList = true;
        ioConfig = JSON.parse(ioConfig);
        if (this.infoOverviewDataList) {
          for (var i = 0; i < this.infoOverviewDataList.length; i++) {
            if (this.infoOverviewDataList[i]['configID'] === ioConfig.ConfigID) {
              this.infoOverviewDataList[i]['configClientReceiveTime'] = Utils.formatDateTime(new Date());
              this.infoOverviewDataList[i]['configJobServerUpdateTime'] = Utils.formatDateTime(new Date(poConfig.ConfigUpdateTime));
              this.infoOverviewDataList[i]['configFields'] = this.prepareInfoOverviewColumns(poConfig.ConfigFields);
              this.infoOverviewDataList[i]['configData'] = JSON.parse(ioConfig.ConfigData);
              addNewIOInList = false;
              break;
            }
          };
        }
        if (addNewIOInList) {
          this.infoOverviewDataList.push({
            configID: ioConfig.ConfigID,
            configClientReceiveTime: Utils.formatDateTime(new Date()),
            configJobServerUpdateTime: Utils.formatDateTime(new Date(ioConfig.ConfigUpdateTime)),
            configFields: this.prepareInfoOverviewColumns(ioConfig.ConfigFields),
            configData: JSON.parse(ioConfig.ConfigData)
          });
        }*/
      });
    }
  }

  public onUpdateIOData() {
    if (this.connectedToHub()) {
      this.hubConnection.on(HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_DATA, (ioData: InfoOverviewData) => {
        if (ioData) {
          const newIOData = new InfoOverviewData(ioData);
          this.store.dispatch(new SetIOData(newIOData));
        }
      });
    }
  }

  private offUpdateIOData() {
    if (this.connectedToHub()) {
      this.hubConnection.off(HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_DATA);
    }
  }

  // listen "JobConfirmation" hub method (can be response from TDJobServer about subscribe/unsubscribe to particular info-overview)
  public onJSrvConfirmationMessage(): void {
    if (this.connectedToHub()) {
      this.hubConnection.on(HUB_CLIENT_METHOD_JOB_SRV_CONFIRMATION_MESSAGE, (message) => {
        this.loggerService.log(message);
      });
    }
  }

  public onJSrvConnectionEstablished(): void {
    if (this.connectedToHub()) {
      this.hubConnection.on(HUB_CLIENT_METHOD_PO_JOB_CONNECTION_ESTABLISHED, (message) => {
        this.store.dispatch(new SetConnectedToJSrv(ProdOverviewData, true));
        this.loggerService.log(message + ' (:');
      });
    }
  }

  public onJSrvConnectionLost(): void {
    if (this.connectedToHub()) {
      this.hubConnection.on(HUB_CLIENT_METHOD_PO_JOB_CONNECTION_LOST, (message) => {
        this.store.dispatch(new SetConnectedToJSrv(ProdOverviewData, false));
        this.loggerService.log(message + ' (:');
      });
    }
  }

  public onSetUsersOnline(): void {
    if (this.connectedToHub()) {
      this.hubConnection.on(HUB_METHOD_SET_USERS_ONLINE, usersOnline => {
        usersOnline.forEach(user => this.loggerService.log(HUB_METHOD_SET_USERS_ONLINE + ': ' + user.connectionId));
      });
    }
  }

  public onUsersJoined(): void {
    if (this.connectedToHub()) {
      this.hubConnection.on(HUB_METHOD_USERS_JOINED, users => {
        users.forEach(user => {
          this.loggerService.log(HUB_METHOD_USERS_JOINED + ': ' + user.connectionId + ' joined the InfoOverview');
        });
      });
    }
  }

  public onUsersLeft(): void {
    if (this.connectedToHub()) {
      this.hubConnection.on(HUB_METHOD_USERS_LEFT, users => {
        users.forEach(user => {
          this.loggerService.log(HUB_METHOD_USERS_LEFT + ': ' + user.connectionId + ' left the InfoOverview');
        });
      });
    }
  }

}
