"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
exports.selectServerSessionState = function (state) { return state.serverSession; };
var ɵ0 = function (state) { return state.clientId; };
exports.ɵ0 = ɵ0;
exports.selectClientId = store_1.createSelector(exports.selectServerSessionState, ɵ0);
var ɵ1 = function (state) { return state.sessionEnded; };
exports.ɵ1 = ɵ1;
exports.selectSessionEnded = store_1.createSelector(exports.selectServerSessionState, ɵ1);
var ɵ2 = function (state) { return state.returnUrl; };
exports.ɵ2 = ɵ2;
exports.selectReturnUrl = store_1.createSelector(exports.selectServerSessionState, ɵ2);
var ɵ3 = function (state) { return state.desktopOptions; };
exports.ɵ3 = ɵ3;
exports.selectServerDesktopOptions = store_1.createSelector(exports.selectServerSessionState, ɵ3);
var ɵ4 = function (state) { return state.clientConfiguration; };
exports.ɵ4 = ɵ4;
exports.selectClientConfiguration = store_1.createSelector(exports.selectServerSessionState, ɵ4);
var ɵ5 = function (state) { return state.license; };
exports.ɵ5 = ɵ5;
exports.selectLicense = store_1.createSelector(exports.selectServerSessionState, ɵ5);
