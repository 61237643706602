"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var customer_selectors_1 = require("../data-layer/customer/store/customer.selectors");
var utility_service_1 = require("../utility/utility.service");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("@ngrx/store");
var i3 = require("../utility/utility.service");
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, authService, store, utilityService) {
        var _this = this;
        this.router = router;
        this.authService = authService;
        this.store = store;
        this.utilityService = utilityService;
        this.customerSelected = false;
        this.store.pipe(store_1.select(customer_selectors_1.selectCurrentCustomer)).subscribe(function (cust) {
            _this.customerSelected = !_this.utilityService.objIsEmpty(cust);
        });
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        // we allow to navigate to page only when:
        // 1. user was logged in
        // 2. customer was selected
        if (this.authService.isAuthenticated() && ((route.routeConfig.path === 'auth-customers') || this.customerSelected)) {
            // check if route is restricted by role
            /*if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
              // role not authorised so redirect to home page
              this.router.navigate(['/']);
              return false;
            }*/
            return true;
        }
        else {
            return false;
        }
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject("AUTH_SERVICE"), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.UtilityService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
exports.AuthGuard = AuthGuard;
