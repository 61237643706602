"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./error.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i4 = require("devextreme-angular/core");
var i5 = require("devextreme-angular/ui/button");
var i6 = require("@angular/platform-browser");
var i7 = require("@angular/common");
var i8 = require("./error.component");
var i9 = require("@angular/router");
var i10 = require("angular-user-idle");
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
exports.RenderType_ErrorComponent = RenderType_ErrorComponent;
function View_ErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpod(8, { "maximum": 0, "remaining": 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "pt-3 d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 5, "dx-button", [["class", "td-button tertiary-button"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.tryAgain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DxButtonComponent_0, i3.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(18, 7323648, null, 0, i5.DxButtonComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { text: [0, "text"] }, { onClick: "onClick" }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("license.tryAgain")); _ck(_v, 18, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("license.noAvailableLicenses")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform("license.countLicensesInUse", _ck(_v, 8, 0, _co.maximumCount, (_co.maximumCount - _co.remainingCount)))); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("license.tryAgainInFewMin")); _ck(_v, 11, 0, currVal_2); }); }
function View_ErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "pt-3 d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "dx-button", [["class", "td-button tertiary-button"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.newSession() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DxButtonComponent_0, i3.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(14, 7323648, null, 0, i5.DxButtonComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { text: [0, "text"] }, { onClick: "onClick" }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("session.newSession")); _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("session.sessionEnded")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("session.startNewSession")); _ck(_v, 7, 0, currVal_1); }); }
function View_ErrorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpod(2, { "pageName": 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("appMessages.moduleNotIncluded", _ck(_v, 2, 0, _co.missedPageName))); _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row h-100 d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "col-sm-12 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_3)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noAvailableLicense; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.sessionEnded; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.moduleNotIncluded; _ck(_v, 9, 0, currVal_2); }, null); }
exports.View_ErrorComponent_0 = View_ErrorComponent_0;
function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 245760, null, 0, i8.ErrorComponent, ["LICENSE_SERVICE", "LICENSE_BINDING_SERVICE", i9.ActivatedRoute, i10.UserIdleService, i6.Title, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ErrorComponent_Host_0 = View_ErrorComponent_Host_0;
var ErrorComponentNgFactory = i1.ɵccf("ng-component", i8.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
exports.ErrorComponentNgFactory = ErrorComponentNgFactory;
