import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';

import { InfoOverviewService } from '../services/info-overview.service';
import { InfoOverviewActionTypes, GetIOConfigurationById, SetIOData } from './info-overview.actions';
import { InfoOverviewHelperService } from '@app/dashboard/services/info-overview-helper.service';
import { AppState } from '@core/data-layer/app.state';
import { InfoOverviewType } from '@app/dashboard/models/info-overview.model';
import { selectInfoOverviews } from './info-overview.selectors';
import { SessionHelperService } from '@app/shared/services/session-helper.service';

@Injectable()
export class InfoOverviewEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Inject('INFO_OVERVIEW_SERVICE') private ioService: InfoOverviewService,
    private ioHelper: InfoOverviewHelperService,
    private sessionHelperService: SessionHelperService
  ) { }

  @Effect()
  loadIOConfigurations$ = this.actions$
    .pipe(
      ofType(InfoOverviewActionTypes.LoadIOConfigurations),
      mergeMap(() => this.ioService.getInfoOverviews(
        [InfoOverviewType.prodOverview, InfoOverviewType.endoProdOverview],
        this.sessionHelperService.userPrimaryFactoryKeyId,
        this.sessionHelperService.profileData.system_CanViewProductionOverview
      ).pipe(
          map(ioConfigurations => ({ type: InfoOverviewActionTypes.LoadIOConfigurationsSuccess, payload: ioConfigurations })),
          catchError(() => of({ type: InfoOverviewActionTypes.LoadIOConfigurationsError }))
        ))
    );

  @Effect()
  getIOConfigurationById$ = this.actions$
    .pipe(
      ofType(InfoOverviewActionTypes.GetIOConfigurationById),
      mergeMap((act: GetIOConfigurationById) => this.ioService.getInfoOverview(act.payload)
        .pipe(
          map(ioConfiguration => ({ type: InfoOverviewActionTypes.GetIOConfigurationByIdSuccess, payload: ioConfiguration })),
          catchError(() => EMPTY)
        ))
    );

  @Effect()
  setIOData$ = this.actions$
    .pipe(
      ofType(InfoOverviewActionTypes.SetIOData),
      withLatestFrom(this.store$.pipe(select(selectInfoOverviews))),
      mergeMap(([act, infoOverviews]) => {
        const ioDataPayload = (act as SetIOData).payload;
        if (ioDataPayload) {
          const filteredIO = infoOverviews.filter((io) => io.configId === ioDataPayload.configId);
          if ((filteredIO.length > 0) && [InfoOverviewType.prodOverview, InfoOverviewType.endoProdOverview].includes(filteredIO[0].configType)) {
            Object.assign(ioDataPayload.data, this.ioHelper.adjustPOData(ioDataPayload.data));
          }
        }
        return of(ioDataPayload).pipe(
          map(ioData => ({ type: InfoOverviewActionTypes.SetIODataSuccess, payload: ioData })),
          catchError(() => EMPTY)
        );
      })
    );
}
