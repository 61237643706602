import { Inject, Injectable } from '@angular/core';
import * as StackTrace from 'stacktrace-js';

import { ErrorReportServiceInterface } from './error-report.service.interface';
import { ErrorReportModel } from '../models/error-report.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportManagerService {

  constructor(
    @Inject('ERROR_REPORT_SERVICE') private errorReportService: ErrorReportServiceInterface
  ) { }

  handleError(error: Error, message: string, url: string, exceptionClass: string, exceptionMessage: string, traceId = '') {
    // Create special trace with extra info (eg: function names and ts references)
    // OBS: this is necessary because if we just send the string from f.ex. error.stack it will be different
    // than what is shown in the console, as the console automatically translates js references to ts based on source maps.
    // To replicate the same behavior, we need to use stacktrace-js
    StackTrace.fromError(error).then((arr) => {
      const improvedStackTrace = arr.join('\n');

      // Send the report
      const errorReport = new ErrorReportModel(
        message,
        url,
        exceptionClass,
        exceptionMessage,
        improvedStackTrace,
        traceId);
      this.errorReportService.sendErrorReport(errorReport).subscribe();
    });
  }
}
