"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_load_panel_service_1 = require("../td-load-panel/services/td-load-panel.service");
var LoadingComponent = /** @class */ (function () {
    function LoadingComponent(loadPanelService) {
        this.loadPanelService = loadPanelService;
        this.loadPanelService.showLoadPanel();
    }
    LoadingComponent.prototype.ngOnDestroy = function () {
        this.loadPanelService.hideLoadPanel();
    };
    return LoadingComponent;
}());
exports.LoadingComponent = LoadingComponent;
