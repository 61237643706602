import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExternalDocumentsServiceInterface } from './external-documents.service.interface';
import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';

@Injectable()
export class HttpExternalDocumentsService implements ExternalDocumentsServiceInterface {
  
  constructor(
    private http: HttpClient
  ) { }

  externalDocumentsUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.externalDocumentsCtrl;
  }
  
  getExternalDocument(keyId: number): Observable<any> {
    return this.http.get(`${this.externalDocumentsUrl()}(${keyId})${API_HUB_PATHS.file}`, { responseType: 'blob' });   
  }
}
