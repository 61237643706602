import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TdPopupService } from '@shared/components/td-popup/services/td-popup.service';
import { ErrorReportManagerService } from '../error-report/services/error-report-manager.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector,
    private errorReportManagerService: ErrorReportManagerService,
    private popupService: TdPopupService
  ) { }

  handleError(error: Error) {
    if (environment.logInternalErrors && !(error instanceof HttpErrorResponse)) {
      if (environment.production) {
        const router = this.injector.get(Router);
        this.errorReportManagerService.handleError(error, '', router.url, error.name, error.message);
      }
      else {
        console.error(error);
      }
      this.popupService.showError('appMessages.technicalErrorContactSupport', true);
    }
  }

}
