"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var angular_user_idle_1 = require("angular-user-idle");
var store_1 = require("@ngrx/store");
var production_overview_constants_1 = require("../dashboard/models/production-overview.constants");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var info_overview_selectors_1 = require("@app/dashboard/data-layer/info-overview/store/info-overview.selectors");
var app_enumerations_1 = require("@app/shared/models/app.enumerations");
var header_enumerations_1 = require("./models/header.enumerations");
var devextreme_angular_1 = require("devextreme-angular");
var td_popup_service_1 = require("@shared/components/td-popup/services/td-popup.service");
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(authService, licenseBindingService, mediaService, sessionHelperService, router, activatedRoute, userIdle, translateService, popupService, store) {
        var _this = this;
        this.authService = authService;
        this.licenseBindingService = licenseBindingService;
        this.mediaService = mediaService;
        this.sessionHelperService = sessionHelperService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.userIdle = userIdle;
        this.translateService = translateService;
        this.popupService = popupService;
        this.store = store;
        this.headerHidden = true;
        this.allowSearch = false;
        this.searchText = '';
        this.userInitials = '';
        this.userCustomer = '';
        this.activePage = header_enumerations_1.ActivePage.Other;
        this.allActivePages = header_enumerations_1.ActivePage;
        setInterval(function () {
            _this.now = Date.now();
        }, 1000);
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events
            .subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd) {
                _this.activePage = _this.setActivePage(event.url);
                _this.allowSearch = _this.sessionHelperService.allowSearch();
                _this.activatedRoute.queryParamMap.subscribe(function (params) {
                    _this.headerHidden = !!params.get(production_overview_constants_1.POConst.TDOC_APP_TYPE);
                    _this.noActionsHeader =
                        (params.get(td_constants_1.TD_QUERY_PARAMS.type) === td_constants_1.TD_ERROR_TYPES.noAvailableLicense) ||
                            (params.get(td_constants_1.TD_QUERY_PARAMS.type) === td_constants_1.TD_ERROR_TYPES.sessionEnded) ||
                            _this.router.url.startsWith('/auth-customers');
                });
                _this.routerUrl = _this.router.url;
            }
        });
        this.getCollapsed();
        this.setupProfileSettings();
        this.initDefaultInfoOverviewConfigId();
        var license = this.sessionHelperService.license;
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        if (this.selectInfoOverviewsSubscription) {
            this.selectInfoOverviewsSubscription.unsubscribe();
        }
        // this.subscriptions.forEach(subscription => subscription.unsubscribe());
    };
    HeaderComponent.prototype.triggerDropDownSelect = function () {
        this.userDropDown.instance.open();
    };
    HeaderComponent.prototype.initDefaultInfoOverviewConfigId = function () {
        var _this = this;
        this.selectInfoOverviewsSubscription = this.store.pipe(store_1.select(info_overview_selectors_1.selectInfoOverviews)).subscribe(function (infoOverviews) {
            if (infoOverviews && (infoOverviews.length > 0)) {
                // select the default first info-overview from list
                _this.defaultIOConfigId = infoOverviews[0].configId;
            }
        });
    };
    HeaderComponent.prototype.getCollapsed = function () {
        this.collapsed = window.innerWidth < 992;
    };
    HeaderComponent.prototype.onLogoClick = function () {
        if (this.noActionsHeader) {
            this.licenseBindingService.startConnectionAndLoadLicense();
            this.userIdle.resetTimer();
        }
        else {
            this.router.navigate(['']);
        }
    };
    HeaderComponent.prototype.login = function () {
        this.authService.startSignin();
    };
    HeaderComponent.prototype.submitSearch = function (e) {
        if (this.searchText) {
            this.router.navigate(['/search'], {
                queryParams: {
                    text: this.searchText
                }
            });
            this.searchText = '';
        }
        else {
            this.popupService.showInfo('appMessages.searchTextEmptyError', true);
        }
    };
    HeaderComponent.prototype.logout = function () {
        this.authService.startSignout();
    };
    HeaderComponent.prototype.dropDownSelect = function (value) {
        if (value === app_enumerations_1.ProfileMenu.Settings) {
            this.settingsVisible = true;
        }
        if (value === app_enumerations_1.ProfileMenu.ChangePassword) {
            this.authService.navigateToChangePassword();
        }
    };
    HeaderComponent.prototype.setupProfileSettings = function () {
        var _this = this;
        this.profileSettings = [];
        var settings = {
            value: app_enumerations_1.ProfileMenu.Settings,
            name: this.translateService.instant('settings.settings'),
            icon: 'preferences'
        };
        var changePassword = {
            value: app_enumerations_1.ProfileMenu.ChangePassword,
            name: this.translateService.instant('settings.changePassword'),
            icon: './assets/img/changePassword.svg'
        };
        this.sessionHelperService.$isAdmin.subscribe(function (isAdmin) {
            _this.profileSettings = isAdmin ? [settings, changePassword] : [changePassword];
        });
        this.sessionHelperService.$loggedIn.subscribe(function (response) {
            if (response) {
                _this.userInitials = _this.authService.getUserInitials();
                _this.userCustomer = _this.sessionHelperService.currentCustomer.name;
                _this.getUserProfilePicture();
            }
        });
    };
    HeaderComponent.prototype.getUserProfilePicture = function () {
        var _this = this;
        var userId = Number(this.authService.getUserId());
        this.mediaService.getMediaSeries(userId, td_constants_1.TD_DYNAMIC_FIELDS.user).subscribe(function (mediaSeries) {
            if (mediaSeries.length > 0) {
                _this.mediaService.getMediaImage(mediaSeries[0].keyId).subscribe(function (response) {
                    if (response) {
                        _this.userProfilePicture = response;
                    }
                });
            }
        });
    };
    HeaderComponent.prototype.setActivePage = function (url) {
        if (url.startsWith('/orders')) {
            return header_enumerations_1.ActivePage.Orders;
        }
        if (url.startsWith('/operations')) {
            return header_enumerations_1.ActivePage.Operations;
        }
        if (url.startsWith('/production-overview')) {
            return header_enumerations_1.ActivePage.ProductionOverview;
        }
        if (url.startsWith('/order-templates')) {
            return header_enumerations_1.ActivePage.Templates;
        }
        else {
            return header_enumerations_1.ActivePage.Other;
        }
    };
    return HeaderComponent;
}());
exports.HeaderComponent = HeaderComponent;
