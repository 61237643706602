import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import { TranslateService } from '@ngx-translate/core';

import { TD_QUERY_PARAMS, TD_ERROR_TYPES, TD_MODULES } from '@core/data-layer/shared/models/td.constants';
import { LicenseServiceInterface } from '@core/license/services/license.service.interface';
import { LicenseBindingServiceInterface } from '@core/license/services/license-binding.service.interface';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit, OnDestroy {

  maximumCount: number;
  remainingCount: number;
  noAvailableLicense = false;
  sessionEnded = false;
  moduleNotIncluded = false;
  missedPageName: string;
  queryParamsSubscription: Subscription;

  constructor(
    @Inject('LICENSE_SERVICE') private licenseService: LicenseServiceInterface,
    @Inject('LICENSE_BINDING_SERVICE') private licenseBindingService: LicenseBindingServiceInterface,
    private activatedRoute: ActivatedRoute,
    private userIdle: UserIdleService,
    private titleService: Title,
    private translateService: TranslateService
  ) { }

  public ngOnInit() {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(queryParams => {
      this.showErrorBlock();
    });
  }

  ngOnDestroy() {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  showErrorBlock() {
    // check either error should be shown
    const errorType = this.activatedRoute.snapshot.queryParams[TD_QUERY_PARAMS.type];
    if (errorType) {
      this.sessionEnded = false;
      this.noAvailableLicense = false;
      switch (errorType) {
        case TD_ERROR_TYPES.noAvailableLicense:
          this.titleService.setTitle(this.translateService.instant('license.noLicense'));
          this.licenseService.getLicenseBindings().subscribe(bindings => {
            this.maximumCount = bindings.maximum;
            this.remainingCount = bindings.remaining;
          });
          this.noAvailableLicense = true;
          break;
        case TD_ERROR_TYPES.sessionEnded:
          this.titleService.setTitle(this.translateService.instant('session.tDocSessionEnded'));
          this.sessionEnded = true;
          break;
        
      }
    }
    // validation for missed modules in license
    this.moduleNotIncluded = false;
    const missedModule = this.activatedRoute.snapshot.queryParams[TD_QUERY_PARAMS.missedModule];
    if (missedModule) {
      switch (missedModule) {
        case TD_MODULES.operation:
          this.titleService.setTitle(this.translateService.instant('license.noLicense'));
          this.missedPageName = this.translateService.instant('appCommon.scheduledOperations')
          this.moduleNotIncluded = true;
          break;
      }
    }
  }

  tryAgain(): void {
    this.newSession();
  }

  newSession(): void {
    this.licenseBindingService.startConnectionAndLoadLicense();
    this.userIdle.resetTimer();
  }

}
