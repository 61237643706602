import { WebOrderModel, WebOrderDetailsModel, WebOrderLineModel } from './web-order.model';
import { ReservationLineViewModel } from './web-order-booking-details-view.model';

export class WebOrderViewModel extends WebOrderModel {
  details: WebOrderDetailsViewModel;
  lines: WebOrderLineViewModel[];

  constructor() {
    super();
    this.details = new WebOrderDetailsViewModel();
    this.lines = new Array<WebOrderLineViewModel>();
  }
}

export class WebOrderDetailsViewModel extends WebOrderDetailsModel {
  suppStatusText: string;
  orgStatusText: string;
}

export class WebOrderLineViewModel extends WebOrderLineModel {
  oLnRefTypeText: string;
  oLnOrgOrderedText: string;
  oLnSuppDeliveredText: string;
  oLnSuppLaterText: string;
  oLnResvStateText: string;
  hasDetails?: boolean;
  reservationLines?: ReservationLineViewModel[];
}
