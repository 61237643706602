<ng-template #removeUnitPopupTemplate let-item="item">
  <div class="d-flex flex-column justify-content-between remove-unit-container">
    <div>
      <p class="popup-headline"> {{ 'appCommon.product' | translate }}: {{ articleUpForRemoval.product }} - {{ articleUpForRemoval.item.text }}</p>
      <div class="remove-unit-grid grid-border">
        <td-data-grid
          [gridAlias]="TD_DESKTOP_IDENTIFIERS.operationRemoveUnitListGrid"
          [data]="unitsToRemove"
          [mainTable]="TD_MODEL_NAMES.unitModel"
          [gridStructure]="removeUnitGridStructure"
          [templates]="{
            select: removeUnitCheckboxTemplate,
            status: removeUnitStatusTemplate
          }"
        ></td-data-grid>
      </div>
    </div>
    <div id="button-row" class="row">
      <div class="col d-flex justify-content-end">
        <dx-button class="td-button secondary-button mr-3" (onClick)="popupService.cancelPopup()">
          {{ 'button.cancel' | translate }}
        </dx-button>
        <dx-button class="td-button danger-button"
                  [disabled]="!unitSelected"
                  (onClick)="confirmRemoveUnit()">
          {{  'operationDetails.remove' | translate }}
        </dx-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #removeUnitCheckboxTemplate let-item="item">
  <dx-check-box [(value)]="item.data[TD_DYNAMIC_FIELDS.select]"
                (onValueChanged)="checkUnitSelected()">
  </dx-check-box>
</ng-template>

<ng-template #removeUnitStatusTemplate let-item="item">
  {{ item.data.status }}
</ng-template>
