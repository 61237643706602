import { createSelector } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { InfoOverviewState } from './info-overview.reducer';
import { InfoOverview } from '@app/dashboard/models/info-overview.model';

export const selectInfoOverviewState = (state: AppState) => state.infoOverview;
export const selectInfoOverviews = createSelector(
  selectInfoOverviewState,
  (state: InfoOverviewState) => state.infoOverviews
);
export const selectInfoOverviewById = createSelector(
  selectInfoOverviewState,
  (state: InfoOverviewState, props) => {
    return state.infoOverviews ? state.infoOverviews.filter((io) =>
      io.configId === props.id)[0] : new InfoOverview();
  }
);

export const selectConnectedToHub = createSelector(
  selectInfoOverviewState,
  (state: InfoOverviewState, props) => {
    return state.connectedToHub;
  }
);
