import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { OrderModel } from '../models/order.model';
import { ORDER_PROPERTIES_TO_ENUM_MAPPING, ORDER_DETAILS_PROPERTIES_TO_ENUM_MAPPING } from '@core/language/models/order-translate.mapping';
import { OrderOrgStatus, OrderSuppStatus, ReservationState, ItemInstrumentType, TableTypes } from '@core/data-layer/shared/models/td.enumerations';
import { LanguageService } from '@core/language/services/language.service';
import { WebOrderDetailsModel, WebOrderLineModel, WebOrderState } from '../models/web-order.model';
import { WebOrderDetailsViewModel, WebOrderLineViewModel, WebOrderViewModel } from '../models/web-order-view.model';
import { WebOrderBookingDetailsModel, WebOrderReservLineModel, WebOrderReservMsgLineModel } from '../models/web-order-booking-details.model';
import { WebOrderBookingDetailsViewModel, ReservationLineViewModel } from '../models/web-order-booking-details-view.model';
import { StringDictionary } from '@shared/models/custom-types.interface';
import { TD_CALC_FIELDS } from '@core/data-layer/shared/models/td.constants';
import { ReservationStateGridImgPaths } from '@shared/models/img.enumerations';
import { RESERVATION_STATE } from '@shared/models/app.constants';

@Injectable({
  providedIn: 'root'
})
export class OrderHelperService {

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService
  ) { }

  /**
   * @description converts [WebOrderReservLineModel][Array] and adds image paths
   * @param data [WebOrderReservLineModel][Array]
   * @returns [ReservationLineViewModel][Array]
   */
  prepareReservationLineResponse(data: WebOrderReservLineModel[]): ReservationLineViewModel[] {
    const convertedData = this.convertReservationLineToViewModel(data);
    return convertedData;
  }

  /**
   * @description converts [WebOrderReservLineModel][Array] to view model [Array]
   * @param data: [WebOrderReservLineModel][Array]
   * @returns [ReservationLineViewModel][Array]
   */
  convertReservationLineToViewModel(data: WebOrderReservLineModel[]): ReservationLineViewModel[] {
    return data.map(line => {
      return {
        ...line,
        resvStateText: this.languageService.translateEnumValue(RESERVATION_STATE, line.resvState),
        resvStatusText: '',
        resvStateImagePath: ReservationStateGridImgPaths[line.resvState]
      };
    });
  }
  /**
   * @description: converts [WebOrderBookingDetailsModel] to [WebOrderBookingDetailsViewModel]
   * @param data: [WebOrderBookingDetailsModel][Object]
   * @param resvLines: [ReservationLineViewModel][Array] - Reservation lines converted to view model
   * @param resvLogs: [StringDictionary<WebOrderReservMsgLineModel[]>] - Reservation logs converted to view model
   * @param resvStates: [StringDictionary<ReservationState>] - Reservation States
   * @returns [WebOrderBookingDetailsViewModel] - The WebOrderBookingDetails model converted to view model
   */
  convertBookingDetailsToViewModel(
    data: WebOrderBookingDetailsModel, resvLines: ReservationLineViewModel[],
    resvLogs: StringDictionary<WebOrderReservMsgLineModel[]>, resvStates: StringDictionary<ReservationState>
  ): WebOrderBookingDetailsViewModel {
    return {
      ...data,
      reservLines: resvLines,
      reservLogs: resvLogs,
      reservStates: resvStates
    };
  }

  /**
   * @description converts [OrderModel][Array] to [OrderViewModel][Array], and translate any [enum] properties
   * @param data: [OrderModel][Array] - An array of [OrderModel][Objects]
   * @returns [OrderViewModel][Array] - An array of [OrderViewModel][Objects]
   */
  prepareOrderResponse(data: any[]): any[] {
    const convertedData = this.convertOrderToViewModel(data);
    return this.languageService.translateAllEnums(convertedData, ORDER_PROPERTIES_TO_ENUM_MAPPING);
  }

  /**
   * @description converts [OrderModel][Array] to [OrderViewModel][Array] and calculates calculated fields
   * @param data: [OrderModel][Array] - An array of [OrderModel][Objects]
   * @returns [OrderViewModel][Array] - An array of [OrderViewModel][Objects]
   */
  convertOrderToViewModel(data: any[]): any[] {
    return data.map(order => {
      order[TD_CALC_FIELDS.status] = this.determineStatus(order);
      order[TD_CALC_FIELDS.time] = this.determineTime(order);
      order[TD_CALC_FIELDS.orgStatusText] = '';
      order[TD_CALC_FIELDS.suppStatusText] = '';
      return order;
    });
  }

  /**
   * @description calculates [status][property] of an [OrderModel][Object]
   * @param order: [OrderModel] - The order to calculate [status][property] on
   * @returns [string] - The calculated [status][property] as a [string]
   */
  determineStatus(order: OrderModel): string {
    let status: string = OrderOrgStatus[order.orgStatus];
    let translateString = `orderOrgStatus.${status.toLowerCase()}`;

    if (order.suppStatus === OrderSuppStatus.Delivered) {
      translateString = `orderSuppStatus.${OrderSuppStatus[order.suppStatus].toLowerCase()}`;
      status = this.translateService.instant(translateString);
    }
    else if ((order.suppStatus > OrderSuppStatus.Order) && (order.suppStatus < OrderSuppStatus.Cancelled)) {
      status = this.translateService.instant(translateString);
      let suppStatus = `orderSuppStatus.${OrderSuppStatus[order.suppStatus].toLowerCase()}`;
      suppStatus = this.translateService.instant(suppStatus);
      status = `${status} & ${suppStatus}`;
    }
    else {
      status = this.translateService.instant(translateString);
    }
    return status;
  }

  /**
   * @description calculates [time][property] of an [OrderModel][Object]
   * @param order: [OrderModel] - The order to calculate [time][property] on
   * @returns [Date] - The calculated [Date][property] as a [Date]
   */
  determineTime(order: OrderModel): Date {
    return order.suppTime ? order.suppTime : order.orgTime;
  }

  /**
   * @description converts [WebOrderDetailsModel][Object] to [WebOrderDetailsViewModel][Object], and translate any [enum] properties
   * @param data: [WebOrderDetailsModel][Object]
   * @returns [WebOrderDetailsViewModel][Object]
   */
  prepareOrderDetailsResponse(data: WebOrderDetailsModel): WebOrderDetailsViewModel {
    data.opDStartTime = data.opDStartTime ? new Date(data.opDStartTime) : null;
    data.orgTime = data.orgTime ? new Date(data.orgTime) : null;
    data.reqDelTime = data.reqDelTime ? new Date(data.reqDelTime) : null;
    data.suppTime = data.suppTime ? new Date(data.suppTime) : null;
    const convertedData = this.convertOrderDetailsToViewModel(data);
    return this.languageService.translateEnum(convertedData, ORDER_DETAILS_PROPERTIES_TO_ENUM_MAPPING);
  }

  /**
   * @description converts [WebOrderDetailsModel][Object] to [WebOrderDetailsViewModel][Object]
   * @param data: [WebOrderDetailsModel][Object]
   * @returns [WebOrderDetailsModel][Object]
   */
  convertOrderDetailsToViewModel(data: WebOrderDetailsModel): WebOrderDetailsViewModel {
    return {
      ...data,
      suppStatusText: '',
      orgStatusText: ''
    };
  }

  /**
   * @description converts [WebOrderModel][Object] to [WebOrderViewModel][Object]
   * @param state: [WebOrderState][Object]
   * @param details: [WebOrderDetailsViewModel][Object]
   * @param lines: [WebOrderLineViewModel][Array]
   * @returns [WebOrderViewModel][Object]
   */
  convertWebOrderToViewModel(state: WebOrderState, details: WebOrderDetailsViewModel, lines: WebOrderLineViewModel[]): WebOrderViewModel {
    return {
      state,
      details,
      lines
    };
  }

  /**
   * @description converts [WebOrderLineModel][Array] to [WebOrderLineViewModel][Array]m translate any [enum] properties and calculates calculated fields
   * @param data: [WebOrderLineModel][Array]
   * @returns [WebOrderLineViewModel][Array]
   */
  prepareOrderLinesResponse(data: WebOrderLineModel[]): WebOrderLineViewModel[] {
    let convertedData = this.convertOrderLinesToViewModel(data);
    convertedData = this.getCalculatedFields(convertedData);
    return convertedData.map(entry => {
      switch (entry.oLnRefType) {
        case TableTypes.Product:
          if (entry.itemInstrumentType === ItemInstrumentType.Endoscope) {
            entry.oLnRefTypeText = this.translateService.instant('orderLineRefType.endoscope');
          }
          else {
            entry.oLnRefTypeText = this.translateService.instant('orderLineRefType.product');
          }
          break;
        case TableTypes.Item:
          entry.oLnRefTypeText = this.translateService.instant('orderLineRefType.item');
          break;
      }
      return entry;
    });
  }

  /**
   * @description converts [WebOrderLineModel][Array] to [WebOrderLineViewModel][Array]
   * @param data: [WebOrderLineModel][Array]
   * @returns [WebOrderLineViewModel][Array]
   */
  convertOrderLinesToViewModel(data: WebOrderLineModel[]): WebOrderLineViewModel[] {
    return data ? data.map(orderLine => {
      return {
        ...orderLine,
        oLnRefTypeText: '',
        oLnOrgOrderedText: '',
        oLnSuppDeliveredText: '',
        oLnSuppLaterText: '',
        oLnResvStateText: this.languageService.translateEnumValue(RESERVATION_STATE, orderLine.oLnResvState)
      };
    }) : null;
  }

  /**
   * @description calculates all properties needed on a [webOrderLineViewModel][Array]
   * @param lines [WebOrderLineViewModel][Array]
   * @returns [WebOrderLineViewModel][Array]
   */
  getCalculatedFields(lines: WebOrderLineViewModel[]): WebOrderLineViewModel[] {
    if (lines) {
      return lines.map(entry => {
        return {
          ...entry,
          oLnOrgOrderedText: this.calculateOrderedSum(entry),
          oLnSuppDeliveredText: this.calculateDeliveredSum(entry),
          oLnSuppLaterText: this.calculateBackOrderSum(entry)
        };
      });
    }
    else {
      return lines;
    }
  }

  /**
   * @description calculates the [orderedSum][property] on a given [WebOrderLineModel] to a [string]
   * @param input: [WebOrderLineModel]
   * @returns [string]
   */
  calculateOrderedSum(input: WebOrderLineModel): string {
    let orderedSum = input.oLnOrgOrdered.toString();
    if (input.oLnOrgStdCount > 1) {
      orderedSum = `${orderedSum}(${input.oLnOrgStdCount})`;
    }
    return orderedSum;
  }

  /**
   * @description calculates the [deliveredSum][property] on a given [WebOrderLineModel] to a [string]
   * @param input: [WebOrderLineModel]
   * @returns [string]
   */
  calculateDeliveredSum(input: WebOrderLineModel): string {
    let deliveredSum = input.oLnSuppDelivered.toString();
    if (input.oLnSuppStdCount > 1 && input.oLnSuppDelivered > 0) {
      deliveredSum = `${deliveredSum}(${input.oLnSuppStdCount})`;
    }
    return deliveredSum;
  }

  /**
   * @description calculates the [backOrderSum][property] on a given [WebOrderLineModel] to a [string]
   * @param input: [WebOrderLineModel]
   * @returns [string]
   */
  calculateBackOrderSum(input: WebOrderLineModel): string {
    let backOrderSum = input.oLnSuppLater.toString();
    if (input.oLnSuppStdCount > 1 && input.oLnSuppLater > 0) {
      backOrderSum = `${backOrderSum}(${input.oLnSuppStdCount})`;
    }
    return backOrderSum;
  }
}
