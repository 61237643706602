<div class="row page-container" *ngIf="operation">
  <div class="col-sm-12">
    <div class="row page-title">
      {{ 'operationDetails.operationDetails' | translate }}
    </div>

    <div class="row page-menu">
      <div class="col">
        <td-toolbar-button (btnClick)="refreshOperation()"
                           [text]="'button.refresh' | translate"
                           icon="./assets/img/actionRefresh.png">
        </td-toolbar-button>

        <td-toolbar-button *ngIf="allowAddOrder"
                           (btnClick)="createOrder()"
                           [text]="'operationDetails.createOrder' | translate"
                           icon="./assets/img/ordersAll.png">
        </td-toolbar-button>

        <td-toolbar-button (btnClick)="startOperation()"
                           [text]="'operationDetails.startOperation' | translate"
                           icon="./assets/img/greenTriangle.png"
                           [buttonState]="startOpBtnState">
        </td-toolbar-button>

        <td-toolbar-button (btnClick)="stopOperation()"
                           [text]="'operationDetails.stopOperation' | translate"
                           icon="./assets/img/stop.svg"
                           [buttonState]="stopOpBtnState">
        </td-toolbar-button>
      </div>
    </div>

    <div class="row page-details d-flex justify-content-between">
      <div class="col">
        <td-details-property id="operationIdent"
                             [caption]="'appCommon.operation' | translate"
                             [value]="operation.ident">
        </td-details-property>
        <td-details-property id="operationType"
                             [caption]="'operationDetails.type' | translate"
                             [value]="operation.typeText">
        </td-details-property>
        <td-details-property id="operationStatus"
                             [caption]="'appCommon.status' | translate"
                             [value]="operation.statusText">
        </td-details-property>
        <td-details-property id="operationDoctor"
                             [caption]="'operationDetails.doctor' | translate"
                             [value]="operation.doctorText">
        </td-details-property>
        <td-details-property *ngIf="sessionHelperService.license.patientReg"
                             id="operationPatient"
                             [caption]="'operationDetails.patient' | translate"
                             [value]="operation.patientText">
        </td-details-property>
      </div>
      <div class="col">
        <td-details-property id="operationRoom"
                             [caption]="'operationDetails.room' | translate"
                             [value]="operation.roomText">
        </td-details-property>
        <td-details-property id="operationStartTime"
                             [caption]="'operationDetails.startTime' | translate"
                             [value]="operation.startTime | maskableField">
        </td-details-property>
        <td-details-property id="operationEndTime"
                             [caption]="'operationDetails.endTime' | translate"
                             [value]="operation.endTime | maskableField">
        </td-details-property>
        <td-details-property id="operationActualStart"
                             [caption]="'operationDetails.actualStart' | translate"
                             [value]="operation.actualStartTime | maskableField">
        </td-details-property>
        <td-details-property id="operationActualEnd"
                             [caption]="'operationDetails.actualEnd' | translate"
                             [value]="operation.actualEndTime | maskableField">
        </td-details-property>
      </div>
      <div class="col">
        <td-details-property *ngIf="bookingEnabled"
                             id="operationBookingMode"
                             [caption]="'booking.bookingMode' | translate"
                             [value]="operation.bookingModeText">
        </td-details-property>
        <td-details-property *ngIf="bookingEnabled"
                             id="operationBookingStatus"
                             [caption]="'booking.bookingStatus' | translate"
                             [value]="operation.bookingStatusText">
        </td-details-property>
        <div class="row">
          <div class="col form-group">
            <label for="operationOrders">{{ 'operationDetails.orderNo' | translate }}</label>
            <div id="operationOrders">
              <span *ngFor="let order of operation.refOpDOrderList; last as isLast">
                <a class="cursorPointer listLink" [routerLink]="['/orders', order.keyId]" href="">
                  {{ order.orgRefNo }}
                </a>
                <span *ngIf="!isLast">,&nbsp;</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row page-main-block">
      <td-add-unit *ngIf="addUnitVisible"
                   [disabled]="addUnitDisabled"
                   [operationKeyId]="operationKeyId"
                   (unitAdded)="loadOperation($event)">
      </td-add-unit>

      <dx-tab-panel #tabPanel
                    [dataSource]="operationTabs"
                    [focusStateEnabled]="false"
                    width="100%"
                    [deferRendering]="false">

        <td-tab-panel-title [tabs]="operationTabs"
                            [selectedIndex]="tabPanel.selectedIndex">
        </td-tab-panel-title>

        <div class="mt-1" *dxTemplate="let tab of productsTabTemplate">
          <td-data-grid
            [gridAlias]="TD_DESKTOP_IDENTIFIERS.operationProductListGrid"
            [data]="tab.data"
            [mainTable]="TD_MODEL_NAMES.productModel"
            [gridStructure]="productsGridStructure"
            [remoteOperations]="true"
            [wordWrapEnabled]="true"
            [columnChooser]="true"
            (refreshGridData)="refreshGridData(tab)"
            [templates]="{
              media: mediaTemplate,
              product: productDetailsTemplate,
              UNITUNITLIST: unitListTemplate,
              removeUnit: removeUnitTemplate
            }">
          </td-data-grid>
        </div>

        <div class="mt-1" *dxTemplate="let tab of itemsTabTemplate">
          <td-data-grid
            [gridAlias]="TD_DESKTOP_IDENTIFIERS.operationItemListGrid"
            [data]="tab.data"
            [mainTable]="TD_MODEL_NAMES.itemModel"
            [gridStructure]="itemsGridStructure"
            [remoteOperations]="true"
            [columnChooser]="true"
            (refreshGridData)="refreshGridData(tab)"
            [templates]="{
              media: mediaTemplate,
              item: itemDetailsTemplate
            }">
          </td-data-grid>
        </div>

        <div class="mt-1" *dxTemplate="let tab of endoscopesTabTemplate">
          <td-data-grid
            [gridAlias]="TD_DESKTOP_IDENTIFIERS.operationEndoscopeListGrid"
            [data]="tab.data"
            [mainTable]="TD_MODEL_NAMES.productModel"
            [gridStructure]="endoscopesGridStructure"
            [remoteOperations]="true"
            [wordWrapEnabled]="true"
            [columnChooser]="true"
            (refreshGridData)="refreshGridData(tab)"
            [templates]="{
              media: mediaTemplate,
              product: productDetailsTemplate,
              UNITUNITLIST: unitListTemplate,
              removeUnit: removeUnitTemplate
            }">
          </td-data-grid>
        </div>

      </dx-tab-panel>
    </div>
  </div>
</div>

<ng-template #productDetailsTemplate let-item="item">
  <a [routerLink]="['/products', item.data.keyId]" [state]="{ picsKeyId: item.data[TD_DYNAMIC_FIELDS.autoPictureKeyId] }">
    {{item.data.product}}
  </a>
</ng-template>

<ng-template #unitListTemplate let-item="item">
  <span *ngFor="let unitKeyId of item.data[TD_DYNAMIC_FIELDS.unitList]; last as isLast">
    <a class="cursorPointer listLink" [routerLink]="['/units', unitKeyId]" href="">
      {{ unitKeyId }}
    </a>
    <span *ngIf="!isLast">,&nbsp;</span>
  </span>
</ng-template>

<ng-template #itemDetailsTemplate let-item="item">
  <a [routerLink]="['/items', item.data.keyId]" [state]="{ picsKeyId: item.data[TD_DYNAMIC_FIELDS.autoPictureKeyId] }">
    {{item.data.item}}
  </a>
</ng-template>

<ng-template #mediaTemplate let-item="item">
  <td-grid-media-wrapper
    [picsKeyId]="item.data[TD_DYNAMIC_FIELDS.autoPictureKeyId]"
    [mediaIndex]="item.rowIndex"
    [mediaItem]="item.data"
    [keyId]="item.data.keyId"
    [tableType]="item.data[TD_DYNAMIC_FIELDS.unitList] ? TableTypes.Product : TableTypes.Item"
    [mediaSeries]="TD_SYSTEM_MEDIA_SERIES.Normal">
  </td-grid-media-wrapper>
</ng-template>

<ng-template #removeUnitTemplate let-item="item">
  <dx-button
    class="grid-action-button"
    icon=".\assets\img\Remove.svg"
    [disabled]="removeUnitDisabled || !item.data[TD_DYNAMIC_FIELDS.olnOrgReceived]"
    [hint]="'operationDetails.removeUnit' | translate"
    (onClick)="removeUnit(item.data)">
  </dx-button>
</ng-template>

<ng-container *ngIf="showRemoveUnit">
  <td-remove-unit
  [articleUpForRemoval]="articleUpForRemoval"
  [operationKeyId]="operationKeyId"
  (popupResolved)="removeUnitPopupResolved($event)"
></td-remove-unit>
</ng-container>
