import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Modules
import { OperationsRoutingModule } from './operations-routing.module';
import { SharedModule } from '@app/shared/shared.module';

// Services
import { LanguageService } from '@core/language/services/language.service';
import { OperationsSearchService } from './services/operations-search.service';
import { OperationsViewModelService } from './services/operations-viewmodel-helper.service';
import { UrlSearchService } from '@shared/services/url-search.service';

// Components
import { OperationListComponent } from './operation-list/operation-list.component';
import { OperationDetailsComponent } from './operation-details/operation-details.component';
import { AddUnitComponent } from './operation-details/components/add-unit/add-unit.component';
import { RemoveUnitComponent } from './operation-details/components/remove-unit/remove-unit.component';

@NgModule({
  declarations: [
    OperationListComponent,
    OperationDetailsComponent,
    AddUnitComponent,
    RemoveUnitComponent
  ],
  imports: [
    OperationsRoutingModule,
    SharedModule,
    RouterModule
  ],
  exports: [],
  providers: [
    OperationsSearchService,
    OperationsViewModelService,
    UrlSearchService
  ],
})
export class OperationsModule {
  constructor(languageService: LanguageService) {
    languageService.loadLanguageFiles(['operations']);
  }
 }
