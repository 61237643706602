"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var order_helper_service_1 = require("./order-helper.service");
var HttpOrderService = /** @class */ (function () {
    function HttpOrderService(http, orderHelperService) {
        this.http = http;
        this.orderHelperService = orderHelperService;
    }
    HttpOrderService.prototype.ordersUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.ordersCtrl;
    };
    HttpOrderService.prototype.getOrder = function (ordKeyId, customerKeyId) {
        var _this = this;
        return this.http.post(this.ordersUrl() + ("(" + ordKeyId + ")"), customerKeyId).pipe(operators_1.map(function (response) {
            var convertedDetails = _this.orderHelperService.prepareOrderDetailsResponse(response.value.details);
            var convertedOrderLines = _this.orderHelperService.prepareOrderLinesResponse(response.value.lines);
            var convertedWebOrderViewModel = _this.orderHelperService.convertWebOrderToViewModel(response.value.state, convertedDetails, convertedOrderLines);
            return tslib_1.__assign({}, response, { value: convertedWebOrderViewModel });
        }));
    };
    HttpOrderService.prototype.getOrderLine = function (ordLineInfoArgs) {
        var _this = this;
        return this.http.post(this.ordersUrl() + api_hub_constants_1.API_HUB_PATHS.getOrderLine, ordLineInfoArgs).pipe(operators_1.map(function (response) {
            var convertedOrderLines = _this.orderHelperService.prepareOrderLinesResponse([response.value]);
            var convertedOrdLineView = null;
            if (convertedOrderLines.length > 0) {
                convertedOrdLineView = convertedOrderLines[0];
            }
            return tslib_1.__assign({}, response, { value: convertedOrdLineView });
        }));
    };
    HttpOrderService.prototype.saveOrder = function (webOrder) {
        var order = tslib_1.__assign({}, webOrder, { details: tslib_1.__assign({}, webOrder.details, { reqDelTime: webOrder.details.reqDelTime }) });
        return this.http.post(this.ordersUrl() + ("" + api_hub_constants_1.API_HUB_PATHS.saveOrder), order);
    };
    HttpOrderService.prototype.saveOrderLines = function (ordKeyId, webOrderLines) {
        return this.http.post(this.ordersUrl() + ("(" + ordKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.saveOrderLines), webOrderLines);
    };
    HttpOrderService.prototype.sendOrder = function (ordKeyId, factoryKeyId) {
        var params = { factoryKeyId: factoryKeyId };
        return this.http.post(this.ordersUrl() + ("(" + ordKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.sendOrder), params);
    };
    HttpOrderService.prototype.cancelOrder = function (ordKeyId) {
        return this.http.post(this.ordersUrl() + ("(" + ordKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.cancelOrder), null);
    };
    HttpOrderService.prototype.copyOrder = function (ordKeyId) {
        return this.http.post(this.ordersUrl() + ("(" + ordKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.copyOrder), null);
    };
    HttpOrderService.prototype.createOrderFromTemplate = function (templateKeyId, factoryKeyId, customerKeyId) {
        var params = { factoryKeyId: factoryKeyId, customerKeyId: customerKeyId };
        return this.http.post(this.ordersUrl() + (api_hub_constants_1.API_HUB_PATHS.createFromTemplate + "(" + templateKeyId + ")"), params);
    };
    HttpOrderService.prototype.changeBookingMode = function (ordKeyId, newBookingMode) {
        var params = { newBookingMode: newBookingMode };
        return this.http.post(this.ordersUrl() + ("(" + ordKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.changeBookingMode), params);
    };
    HttpOrderService.prototype.convertBookingDetailsToViewModel = function (data) {
        var resvLines = this.orderHelperService.prepareReservationLineResponse(data.reservLines);
        return this.orderHelperService.convertBookingDetailsToViewModel(data, resvLines, data.reservLogs, data.reservStates);
    };
    HttpOrderService.prototype.checkVirtualBooking = function (ordKeyId) {
        var _this = this;
        return this.http.post(this.ordersUrl() + "(" + ordKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.checkVirtualBooking, null).pipe(operators_1.map(function (response) { return _this.convertBookingDetailsToViewModel(response.value); }));
    };
    HttpOrderService.prototype.updateActualBooking = function (ordKeyId) {
        var _this = this;
        return this.http.post(this.ordersUrl() + "(" + ordKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.updateActualBooking, null).pipe(operators_1.map(function (response) { return _this.convertBookingDetailsToViewModel(response.value); }));
    };
    HttpOrderService.prototype.getActualBooking = function (ordKeyId) {
        var _this = this;
        return this.http.get(this.ordersUrl() + "(" + ordKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.getActualBooking).pipe(operators_1.map(function (response) {
            var resvLines = _this.orderHelperService.prepareReservationLineResponse(response.value.reservLines);
            return _this.orderHelperService.convertBookingDetailsToViewModel(response.value, resvLines, null, response.value.reservStates);
        }));
    };
    HttpOrderService.prototype.getOrdersByCustomerAndOrgStatus = function (customerKeyId, originatorStatus) {
        var params = { customerKeyId: customerKeyId, originatorStatus: originatorStatus };
        return this.http.post("" + this.ordersUrl() + api_hub_constants_1.API_HUB_PATHS.getByCustomerAndOrgStatus, params);
    };
    HttpOrderService.prototype.getOrderCustomer = function (orderKeyId) {
        return this.http.get(this.ordersUrl() + "(" + orderKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.customerKeyId);
    };
    return HttpOrderService;
}());
exports.HttpOrderService = HttpOrderService;
