"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var devextreme_angular_1 = require("devextreme-angular");
var search_manager_service_1 = require("@core/search/services/search-manager.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var base_component_1 = require("@shared/components/base/base.component");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var td_data_grid_component_1 = require("@shared/components/td-data-grid/td-data-grid.component");
var utility_service_1 = require("@core/utility/utility.service");
var order_manager_service_1 = require("@shared/services/order-manager.service");
var unit_helper_service_1 = require("@shared/services/unit-helper.service");
var grid_structure_interface_1 = require("@core/table-info/models/grid-structure.interface");
var tab_helper_service_1 = require("@shared/services/tab-helper.service");
var url_search_service_1 = require("@shared/services/url-search.service");
var article_search_factory_1 = require("@shared/models/url-search-models/article-search.factory");
var SearchComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SearchComponent, _super);
    function SearchComponent(orderService, activatedRoute, searchManagerService, orderManagerService, unitHelperService, tabHelperService, urlSearchService) {
        var _this = _super.call(this) || this;
        _this.orderService = orderService;
        _this.activatedRoute = activatedRoute;
        _this.searchManagerService = searchManagerService;
        _this.orderManagerService = orderManagerService;
        _this.unitHelperService = unitHelperService;
        _this.tabHelperService = tabHelperService;
        _this.urlSearchService = urlSearchService;
        _this.tableTypes = td_enumerations_1.TableTypes;
        _this.itemInstrumentType = td_enumerations_1.ItemInstrumentType;
        _this.modelNames = td_constants_1.TD_MODEL_NAMES;
        _this.gridIdentifiers = td_constants_1.TD_DESKTOP_IDENTIFIERS;
        _this.dynamicFields = td_constants_1.TD_DYNAMIC_FIELDS;
        _this.maxQuantityValue = td_constants_1.TD_MAX_INT32_VALUE;
        _this.productTabTemplate = 'products';
        _this.itemTabTemplate = 'items';
        _this.endoscopeTabTemplate = 'endoscopes';
        _this.unitTabTemplate = 'units';
        _this.articleSerialsData = {};
        _this.activeTableType = _this.tableTypes.Product;
        _this.activeTabIndex = 0;
        _this.tabPanelVisible = false;
        // Search Model
        _this.searchModel = new article_search_factory_1.ArticleSearchFactory();
        _this.articleSearchModel = _this.searchModel.createSearchModel();
        _this.firstSearch = true;
        _this.tester = 1;
        return _this;
    }
    SearchComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _super.prototype.ngOnInit.call(this);
                        this.tabTotalCountData = this.initTabTotalCountData();
                        // init search tabs: products, items, endoscopes, units
                        this.initSearchTabs();
                        // init search grids
                        return [4 /*yield*/, this.initSearchGrids()];
                    case 1:
                        // init search grids
                        _a.sent();
                        this.getParamsAndSearch();
                        // Doesn't trigger on the first search
                        this.navigationSubscription = this.router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; })).subscribe(function (event) {
                            _this.getParamsAndSearch();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchComponent.prototype.ngOnDestroy = function () {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    };
    SearchComponent.prototype.getParamsAndSearch = function () {
        var textParam = this.activatedRoute.snapshot.queryParams[td_constants_1.TD_QUERY_PARAMS.text];
        if (this.searchText || textParam && textParam.length > 0) {
            var searchParamsModel = this.urlSearchService.onQueryParamChanges(this.activatedRoute.snapshot.queryParams, this.articleSearchModel, this.searchModel, this.firstSearch);
            if (searchParamsModel) {
                this.searchText = searchParamsModel.properties.find(function (prop) { return prop.urlParamName === td_constants_1.TD_QUERY_PARAMS.text; }).value;
                this.tabPanelVisible = true;
                // remember current (selected) tab index
                this.activeTabIndexPreSearch = this.searchManagerService.getRecentArticleSearchTab();
                this.titleService.setTitle(this.getTitleIdentifier());
                this.setSearchFilters();
                this.search();
                this.firstSearch = false;
            }
        }
    };
    SearchComponent.prototype.getTitleIdentifier = function () {
        var title = this.translateInstant('appCommon.search');
        return this.searchText ? title + " " + this.searchText : title;
    };
    SearchComponent.prototype.initSearchGrids = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: 
                                // get products grid structure
                                return [4 /*yield*/, this.initProductsGridSearch()];
                                case 1:
                                    // get products grid structure
                                    _a.sent();
                                    // get items grid structure
                                    return [4 /*yield*/, this.initItemsGridSearch()];
                                case 2:
                                    // get items grid structure
                                    _a.sent();
                                    if (!this.sessionHelperService.canViewEndoscope()) return [3 /*break*/, 4];
                                    // get endoscopes grid structure
                                    return [4 /*yield*/, this.initEndoscopesGridSearch()];
                                case 3:
                                    // get endoscopes grid structure
                                    _a.sent();
                                    _a.label = 4;
                                case 4: 
                                // get units grid structure
                                return [4 /*yield*/, this.initUnitsGridSearch()];
                                case 5:
                                    // get units grid structure
                                    _a.sent();
                                    // get product serials grid structure
                                    return [4 /*yield*/, this.initProductSerialsGridSearch()];
                                case 6:
                                    // get product serials grid structure
                                    _a.sent();
                                    if (!this.sessionHelperService.canViewEndoscope()) return [3 /*break*/, 8];
                                    // get endoscope serials grid structure
                                    return [4 /*yield*/, this.initEndoscopeSerialsGridSearch()];
                                case 7:
                                    // get endoscope serials grid structure
                                    _a.sent();
                                    _a.label = 8;
                                case 8:
                                    resolve(true);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SearchComponent.prototype.adjustCustomColumns = function (columnsConfigurations) {
        // add custom "media" column
        if (this.sessionHelperService.canViewMedia()) {
            this.gridColumnHelperService.addCustomMediaColumn(columnsConfigurations);
        }
        else {
            // remove custom "media" column
            this.gridColumnHelperService.removeColumn(columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.media);
        }
        if (this.sessionHelperService.canViewOrder()) {
            // add custom "quantity" column
            var quantityColumn = {
                dataField: td_constants_1.TD_ACTION_FIELDS.quantity,
                dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
                visible: true,
                displayName: this.translateInstant('calcColumns.quantity'),
                style: {
                    alignment: 'center'
                }
            };
            this.gridColumnHelperService.addCustomColumn(columnsConfigurations, quantityColumn);
            // add custom "add" column
            var addColumn = {
                dataField: td_constants_1.TD_ACTION_FIELDS.add,
                dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
                visible: true,
                displayName: this.translateInstant('calcColumns.add'),
                style: {
                    alignment: 'center'
                }
            };
            this.gridColumnHelperService.addCustomColumn(columnsConfigurations, addColumn);
        }
    };
    SearchComponent.prototype.adjustUnitColumnHeaders = function () {
        var nameOfLocation = utility_service_1.nameOf();
        this.gridColumnHelperService.setColumnDisplayName(this.unitGridStructure.columnsConfigurations, nameOfLocation('name'), this.translateInstant('columnHeaders.location'));
    };
    SearchComponent.prototype.initProductsGridSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tableInfoService.loadGridStructure(td_constants_1.TD_DESKTOP_IDENTIFIERS.productListGrid, td_constants_1.TD_MODEL_NAMES.productModel).subscribe(function (gridStructure) {
                            if (gridStructure) {
                                // add custom columns
                                _this.adjustCustomColumns(gridStructure.columnsConfigurations);
                                // init products search args
                                _this.productsSearchArgs = _this.searchManagerService.initWebArticlesSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebProdWithSerials, td_constants_1.TD_MODEL_NAMES.productModel, gridStructure);
                            }
                            _this.productGridStructure = gridStructure;
                            if (_this.productsGrid) {
                                _this.productsGrid.grid.instance.repaint();
                            }
                            resolve(true);
                        });
                    })];
            });
        });
    };
    SearchComponent.prototype.initItemsGridSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tableInfoService.loadGridStructure(td_constants_1.TD_DESKTOP_IDENTIFIERS.itemListGrid, td_constants_1.TD_MODEL_NAMES.itemModel).subscribe(function (gridStructure) {
                            if (gridStructure) {
                                // add custom columns
                                _this.adjustCustomColumns(gridStructure.columnsConfigurations);
                                // init items search args
                                _this.itemsSearchArgs = _this.searchManagerService.initWebArticlesSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebBaseProdItem, td_constants_1.TD_MODEL_NAMES.itemModel, gridStructure);
                            }
                            _this.itemGridStructure = gridStructure;
                            if (_this.itemsGrid) {
                                _this.itemsGrid.grid.instance.repaint();
                            }
                            resolve(true);
                        });
                    })];
            });
        });
    };
    SearchComponent.prototype.initEndoscopesGridSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tableInfoService.loadGridStructure(td_constants_1.TD_DESKTOP_IDENTIFIERS.endoscopeListGrid, td_constants_1.TD_MODEL_NAMES.productModel).subscribe(function (gridStructure) {
                            if (gridStructure) {
                                // add custom columns
                                _this.adjustCustomColumns(gridStructure.columnsConfigurations);
                                // init endoscopes search args
                                _this.endoscopesSearchArgs = _this.searchManagerService.initWebArticlesSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebProdWithSerials, td_constants_1.TD_MODEL_NAMES.productModel, gridStructure, td_enumerations_1.ItemInstrumentType.Endoscope);
                            }
                            _this.endoscopeGridStructure = gridStructure;
                            if (_this.endoscopesGrid) {
                                _this.endoscopesGrid.grid.instance.repaint();
                            }
                            resolve(true);
                        });
                    })];
            });
        });
    };
    SearchComponent.prototype.initUnitsGridSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tableInfoService.loadGridStructure(td_constants_1.TD_DESKTOP_IDENTIFIERS.unitListGrid, td_constants_1.TD_MODEL_NAMES.unitModel).subscribe(function (gridStructure) {
                            if (gridStructure) {
                                // add custom "fastTrack" column
                                if (_this.sessionHelperService.license.fastTrackBasic) {
                                    _this.gridColumnHelperService.addCustomFastTrackColumn(gridStructure.columnsConfigurations);
                                }
                                else {
                                    _this.gridColumnHelperService.removeColumn(gridStructure.columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.fastTrack);
                                }
                                // add custom "media" column
                                if (_this.sessionHelperService.canViewMedia()) {
                                    _this.gridColumnHelperService.addCustomMediaColumn(gridStructure.columnsConfigurations);
                                }
                                else {
                                    _this.gridColumnHelperService.removeColumn(gridStructure.columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.media);
                                }
                                // init units search args
                                _this.unitsSearchArgs = _this.searchManagerService.initWebArticlesSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebUnits, td_constants_1.TD_MODEL_NAMES.unitModel, gridStructure);
                                _this.unitsSearchArgs.autoPictureMode = td_enumerations_1.SearchAutoPictureMode.On;
                            }
                            _this.unitGridStructure = gridStructure;
                            var nameOfUnitViewModel = utility_service_1.nameOf();
                            _this.gridColumnHelperService.setColumnProperty(_this.unitGridStructure.columnsConfigurations, nameOfUnitViewModel('status'), { style: { alignment: 'left' } });
                            _this.adjustUnitColumnHeaders();
                            if (_this.unitsGrid) {
                                _this.unitsGrid.grid.instance.repaint();
                            }
                            resolve(true);
                        });
                    })];
            });
        });
    };
    SearchComponent.prototype.initProductSerialsGridSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tableInfoService.loadGridStructure(td_constants_1.TD_DESKTOP_IDENTIFIERS.productSerialsSearchGrid, td_constants_1.TD_MODEL_NAMES.serialModel).subscribe(function (gridStructure) {
                            if (gridStructure) {
                                // init product serials search args
                                _this.productSerialsSearchArgs = _this.searchManagerService.initWebArticlesSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebBaseProdItem, td_constants_1.TD_MODEL_NAMES.serialModel, gridStructure);
                            }
                            resolve(true);
                        });
                    })];
            });
        });
    };
    SearchComponent.prototype.initEndoscopeSerialsGridSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tableInfoService.loadGridStructure(td_constants_1.TD_DESKTOP_IDENTIFIERS.endoscopeSerialsSearchGrid, td_constants_1.TD_MODEL_NAMES.serialModel).subscribe(function (gridStructure) {
                            if (gridStructure) {
                                // init endoscope serials search args
                                _this.endoscopeSerialsSearchArgs = _this.searchManagerService.initWebArticlesSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebBaseProdItem, td_constants_1.TD_MODEL_NAMES.serialModel, gridStructure, td_enumerations_1.ItemInstrumentType.Endoscope);
                            }
                            resolve(true);
                        });
                    })];
            });
        });
    };
    SearchComponent.prototype.initSearchTabs = function () {
        this.searchTabs = [
            {
                title: this.translateInstant('search.products'),
                data: null,
                template: this.productTabTemplate,
                iconPath: '\\assets\\img\\product.png'
            },
            {
                title: this.translateInstant('search.items'),
                data: null,
                template: this.itemTabTemplate,
                iconPath: '\\assets\\img\\item.png'
            }
        ].concat((this.sessionHelperService.canViewEndoscope() ? [{
                title: this.translateInstant('search.endoscopes'),
                data: null,
                template: this.endoscopeTabTemplate,
                iconPath: '\\assets\\img\\endoscope.png'
            }] : []), [
            {
                title: this.translateInstant('search.units'),
                data: null,
                template: this.unitTabTemplate,
                iconPath: '\\assets\\img\\unit.png'
            }
        ]);
    };
    SearchComponent.prototype.updateSearchTabData = function (templateName, searchArgs) {
        var searchTab = this.searchTabs.find(function (tab) { return tab.template === templateName; });
        if (searchTab) {
            this.tabPanel.selectedIndex = this.searchTabs.indexOf(searchTab);
            searchTab.data = this.searchText ?
                this.searchManagerService.requestSearchData(searchArgs, this.searchText, this.afterDataLoaded(templateName), this.getKeyIdField(templateName)) :
                null;
            // searchTab.disabled = false;
        }
    };
    SearchComponent.prototype.getKeyIdField = function (templateName) {
        return [this.productTabTemplate, this.endoscopeTabTemplate].includes(templateName) ? this.dynamicFields.keyId : null;
    };
    SearchComponent.prototype.afterDataLoaded = function (templateName) {
        var _this = this;
        return function (data) {
            _this.tabHelperService.setTabState(_this.searchTabs, templateName, !(data.totalCount > 0), _this.tabPanel);
            if (templateName === _this.productTabTemplate) {
                _this.productsGrid.grid.instance.collapseAll(-1);
            }
            if (templateName === _this.endoscopeTabTemplate) {
                _this.endoscopesGrid.grid.instance.collapseAll(-1);
            }
            if (!_this.tabPanel.items[_this.activeTabIndexPreSearch].disabled) {
                _this.tabPanel.selectedIndex = _this.activeTabIndexPreSearch;
            }
            _this.tabTotalCountData.find(function (entry) { return entry.name === templateName; }).count = data.totalCount;
            _this.setActiveTab();
        };
    };
    SearchComponent.prototype.searchProducts = function () {
        this.updateSearchTabData(this.productTabTemplate, this.productsSearchArgs);
    };
    SearchComponent.prototype.searchItems = function () {
        this.updateSearchTabData(this.itemTabTemplate, this.itemsSearchArgs);
    };
    SearchComponent.prototype.searchEndoscopes = function () {
        if (this.sessionHelperService.canViewEndoscope()) {
            this.updateSearchTabData(this.endoscopeTabTemplate, this.endoscopesSearchArgs);
        }
    };
    SearchComponent.prototype.searchUnits = function () {
        this.updateSearchTabData(this.unitTabTemplate, this.unitsSearchArgs);
    };
    SearchComponent.prototype.setSearchParameters = function () {
        var _this = this;
        if (this.searchText) {
            this.articleSearchModel.properties.forEach(function (prop) {
                prop.value = _this.searchText;
            });
            this.urlSearchService.setSearchParamsAndNavigate(this.articleSearchModel, this.firstSearch);
        }
        else {
            this.popupService.showInfo('appMessages.searchTextEmptyError', true);
        }
    };
    SearchComponent.prototype.search = function () {
        this.searchProducts();
        this.searchItems();
        this.searchEndoscopes();
        this.searchUnits();
    };
    SearchComponent.prototype.searchSerials = function (productKeyId) {
        var _this = this;
        // search product serials
        if (this.searchTabs[this.tabPanel.selectedIndex].template === this.productTabTemplate) {
            this.productSerialsSearchArgs.extraWebArticlesArgs.productKeyId = productKeyId;
            this.productSerialsSearchArgs.paginationArgs.pageRowCount = td_constants_1.TD_MAX_INT32_VALUE - 1;
            this.searchManagerService.requestSearch(this.productSerialsSearchArgs, this.searchText).subscribe(function (response) {
                _this.articleSerialsData[productKeyId] = response.value.dataSet.mappedRows;
                if (_this.productsGrid) {
                    _this.productsGrid.grid.instance.repaint();
                }
            });
        }
        // search endoscope serials
        else if (this.searchTabs[this.tabPanel.selectedIndex].template === this.endoscopeTabTemplate) {
            this.endoscopeSerialsSearchArgs.extraWebArticlesArgs.productKeyId = productKeyId;
            this.endoscopeSerialsSearchArgs.paginationArgs.pageRowCount = td_constants_1.TD_MAX_INT32_VALUE - 1;
            this.searchManagerService.requestSearch(this.endoscopeSerialsSearchArgs, this.searchText).subscribe(function (response) {
                _this.articleSerialsData[productKeyId] = response.value.dataSet.mappedRows;
                if (_this.endoscopesGrid) {
                    _this.endoscopesGrid.grid.instance.repaint();
                }
            });
        }
    };
    SearchComponent.prototype.setSearchFilters = function () {
        this.searchManagerService.setUserSearchFilters(this.productsSearchArgs);
        this.searchManagerService.setUserSearchFilters(this.itemsSearchArgs);
        if (this.sessionHelperService.canViewEndoscope()) {
            this.searchManagerService.setUserSearchFilters(this.endoscopesSearchArgs);
        }
        this.searchManagerService.setUserSearchFilters(this.unitsSearchArgs);
        this.searchManagerService.setUserSearchFilters(this.productSerialsSearchArgs);
        if (this.sessionHelperService.canViewEndoscope()) {
            this.searchManagerService.setUserSearchFilters(this.endoscopeSerialsSearchArgs);
        }
    };
    SearchComponent.prototype.showFastTrackInfo = function (unitKeyId) {
        this.unitHelperService.showFastTrackInfo(unitKeyId);
    };
    SearchComponent.prototype.showSerialNumberInfo = function (serialNumber) {
        var nameOfSerial = utility_service_1.nameOf();
        var nameOfUnit = utility_service_1.nameOf();
        var nameOfLocation = utility_service_1.nameOf();
        this.serialInfo = {
            serialNo: serialNumber[nameOfSerial('serialNo')],
            unit: serialNumber[nameOfSerial('unitUnitUnit')][nameOfUnit('keyId')],
            location: serialNumber[nameOfSerial('loca')][nameOfLocation('name')],
            placement: serialNumber[nameOfSerial('stockplacement')],
            remark: serialNumber[nameOfSerial('remark')]
        };
        this.popupService.showTemplate('appCommon.serialNumber', this.serialInfoTemplate);
    };
    SearchComponent.prototype.saveAddedOrderLine = function () {
        var _this = this;
        var lastOpenOrderKeyId = this.sessionHelperService.userLastOpenOrder.details.keyId;
        this.orderService.saveOrderLines(lastOpenOrderKeyId, this.sessionHelperService.userLastOpenOrder.lines).subscribe(function (response) {
            _this.sessionHelperService.reloadLastOpenOrder(lastOpenOrderKeyId);
        });
    };
    SearchComponent.prototype.addToCart = function (refType, refKeyId, item) {
        if (this.sessionHelperService.userLastOpenOrder) {
            var quantity = item.data.newQuantity ? item.data.newQuantity : item.data.quantity;
            var articleInfo = { refType: refType, refKeyId: refKeyId, quantity: quantity };
            this.orderManagerService.addLineIntoOrder(articleInfo, this.sessionHelperService.userLastOpenOrder, this.sessionHelperService.userLastOpenOrder.details.suppRefFacKeyId, this.saveAddedOrderLine.bind(this));
        }
    };
    SearchComponent.prototype.checkQuantity = function (e, item) {
        if (!this.sessionHelperService.quantityIsValid(e.value)) {
            e.component.option('value', e.previousValue);
        }
        else {
            item.data.newQuantity = e.value;
        }
    };
    SearchComponent.prototype.highlightSearchText = function (value) {
        return this.utilityService.highlightContent(value, this.searchText);
    };
    SearchComponent.prototype.setActiveTab = function () {
        var latestTab = this.tabTotalCountData[this.activeTabIndexPreSearch];
        var targetTab;
        if (latestTab.count > 0) {
            targetTab = latestTab;
        }
        else {
            var tabWithData = this.tabTotalCountData.find(function (entry) { return entry.count > 0; });
            targetTab = tabWithData;
        }
        this.tabPanel.selectedIndex = targetTab ? this.tabTotalCountData.indexOf(targetTab) : this.activeTabIndexPreSearch;
        this.activeTableType = targetTab ? targetTab.tableType : this.tableTypes.Product;
    };
    SearchComponent.prototype.clickTabTitle = function () {
        this.searchManagerService.setRecentArticleSearchTab(this.tabPanel.selectedIndex);
        this.activeTabIndexPreSearch = this.tabPanel.selectedIndex;
        this.setActiveTab();
    };
    SearchComponent.prototype.initTabTotalCountData = function () {
        return [
            {
                name: this.productTabTemplate,
                tableType: td_enumerations_1.TableTypes.Product,
                count: 0
            },
            {
                name: this.itemTabTemplate,
                tableType: td_enumerations_1.TableTypes.Item,
                count: 0
            }
        ].concat((this.sessionHelperService.canViewEndoscope() ? [{
                name: this.endoscopeTabTemplate,
                tableType: td_enumerations_1.TableTypes.Product,
                count: 0
            }] : []), [
            {
                name: this.unitTabTemplate,
                tableType: td_enumerations_1.TableTypes.Unit,
                count: 0
            },
        ]);
    };
    return SearchComponent;
}(base_component_1.BaseComponent));
exports.SearchComponent = SearchComponent;
