import { Action } from '@ngrx/store';

import { ProfileDataModel } from '../models/profile-data.model';
import { UserDesktopOptionsModel } from '../models/user-desktop-options.model';

export enum UserSessionActionTypes {
  LoadProfileData = '[UserSession] Load Profile Data',
  SetProfileData = '[UserSession] Set Profile Data',

  LoadUserOptions = '[UserSession] Load User Options',
  SetUserOptions = '[UserSession] Set User Options',
  LoadDefaultUserOptions = '[UserSession] Load Default User Options',
  SetDefaultUserOptions = '[UserSession] Set Default User Options',
}

export class LoadProfileData implements Action {
  readonly type = UserSessionActionTypes.LoadProfileData;

  constructor() { }
}

export class SetProfileData implements Action {
  readonly type = UserSessionActionTypes.SetProfileData;

  constructor(public payload: ProfileDataModel) { }
}

export class LoadUserOptions implements Action {
  readonly type = UserSessionActionTypes.LoadUserOptions;

  constructor() { }
}

export class SetUserOptions implements Action {
  readonly type = UserSessionActionTypes.SetUserOptions;

  constructor(public payload: UserDesktopOptionsModel) { }
}

export class LoadDefaultUserOptions implements Action {
  readonly type = UserSessionActionTypes.LoadDefaultUserOptions;

  constructor() { }
}

export class SetDefaultUserOptions implements Action {
  readonly type = UserSessionActionTypes.SetDefaultUserOptions;

  constructor(public payload: UserDesktopOptionsModel) { }
}
export type UserSessionActions = LoadProfileData | SetProfileData | LoadUserOptions | SetUserOptions | LoadDefaultUserOptions | SetDefaultUserOptions;
