"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var core_1 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var server_session_selectors_1 = require("@core/data-layer/server-session/store/server-session.selectors");
var user_session_selectors_1 = require("@core/data-layer/user-session/store/user-session.selectors");
var customer_selectors_1 = require("@core/data-layer/customer/store/customer.selectors");
var factory_selectors_1 = require("@core/data-layer/factory/store/factory.selectors");
var order_selectors_1 = require("@core/data-layer/order/store/order.selectors");
var server_desktop_options_model_1 = require("@core/data-layer/server-session/models/server-desktop-options.model");
var profile_data_model_1 = require("@core/data-layer/user-session/models/profile-data.model");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var utility_service_1 = require("@core/utility/utility.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var td_popup_service_1 = require("../components/td-popup/services/td-popup.service");
var ordActions = require("@core/data-layer/order/store/order.actions");
var info_overview_selectors_1 = require("@app/dashboard/data-layer/info-overview/store/info-overview.selectors");
var td_load_panel_service_1 = require("@shared/components/td-load-panel/services/td-load-panel.service");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("../../core/utility/utility.service");
var i3 = require("../components/td-popup/services/td-popup.service");
var i4 = require("../components/td-load-panel/services/td-load-panel.service");
var i5 = require("@ngx-translate/core");
var SessionHelperService = /** @class */ (function () {
    function SessionHelperService(authService, store, utilityService, popupService, loadPanelService, translateService) {
        var _this = this;
        this.authService = authService;
        this.store = store;
        this.utilityService = utilityService;
        this.popupService = popupService;
        this.loadPanelService = loadPanelService;
        this.translateService = translateService;
        this.$loggedIn = new rxjs_1.BehaviorSubject(false);
        this.$isAdmin = new rxjs_1.BehaviorSubject(false);
        // get License
        this.store.pipe(store_1.select(server_session_selectors_1.selectLicense)).subscribe(function (lic) {
            _this.license = lic;
        });
        // get System Data
        this.store.pipe(store_1.select(server_session_selectors_1.selectClientConfiguration)).subscribe(function (configuration) {
            _this.clientConfiguration = configuration;
        });
        // get ServerDesktop options
        this.store.pipe(store_1.select(server_session_selectors_1.selectServerDesktopOptions)).subscribe(function (options) {
            if (options) {
                _this.serverOptions = options;
            }
            else {
                _this.serverOptions = new server_desktop_options_model_1.ServerDesktopOptionsModel();
            }
        });
        // get UserDesktop options
        this.store.pipe(store_1.select(user_session_selectors_1.selectUserOptions)).subscribe(function (options) {
            _this.userOptions = options;
        });
        // get Default UserDesktop options
        this.store.pipe(store_1.select(user_session_selectors_1.selectDefaultUserOptions)).subscribe(function (options) {
            if (options) {
                _this.defaultUserOptions = options;
            }
        });
        // get Profile data
        this.store.pipe(store_1.select(user_session_selectors_1.selectProfileData)).subscribe(function (profData) {
            if (profData) {
                _this.$isAdmin.next(profData.system_SystemAdminRights);
                _this.profileData = profData;
            }
            else {
                _this.profileData = new profile_data_model_1.ProfileDataModel();
            }
        });
        // is authenticated
        this.authService.authStatus$().subscribe(function (status) {
            _this.isAuthenticated = status;
            _this.userName = _this.authService.getUserName();
            if (_this.loggedIn()) {
                _this.reloadCustomerOpenOrders();
            }
            _this.updateLoggedInSubject();
        });
        // get site Customers
        this.store.pipe(store_1.select(customer_selectors_1.selectSiteCustomers)).subscribe(function (custs) {
            _this.siteCustomers = custs;
        });
        // get current Customer
        this.store.pipe(store_1.select(customer_selectors_1.selectCurrentCustomer)).subscribe(function (cust) {
            _this.currentCustomer = cust;
            _this.customerSelected = !_this.utilityService.objIsEmpty(cust);
            if (_this.loggedIn()) {
                _this.reloadCustomerOpenOrders();
            }
            _this.updateLoggedInSubject();
        });
        // get customer's factories
        this.store.pipe(store_1.select(factory_selectors_1.selectCustomerFactories)).subscribe(function (custFactories) {
            _this.customerFactories = custFactories;
        });
        // get customer's open orders
        this.store.pipe(store_1.select(order_selectors_1.selectCustomerOpenOrders)).subscribe(function (orders) {
            _this.customerOpenOrders = orders;
            if (_this.isAuthenticated) {
                _this.initUserLastOpenOrder();
            }
        });
        // get customer's factories
        this.store.pipe(store_1.select(factory_selectors_1.selectUserFactories)).subscribe(function (userFactories) {
            _this.userFactories = userFactories;
        });
        // get user's last open order
        this.store.pipe(store_1.select(order_selectors_1.selectUserLastOpenOrder)).subscribe(function (order) {
            _this.userLastOpenOrder = order;
        });
        // check info overviews
        this.store.pipe(store_1.select(info_overview_selectors_1.selectInfoOverviews)).subscribe(function (infoOverviews) {
            _this.haveInfoOverviews = !!infoOverviews && (infoOverviews.length > 0);
        });
    }
    SessionHelperService.prototype.loggedIn = function () {
        return this.customerSelected && this.isAuthenticated;
    };
    /*
      method should determine if login is required (loginAlways) or not (!loginAlways)
        if login is required, should check if user is logged in and a customer is selected
        if login is not required
    */
    SessionHelperService.prototype.allowSearch = function () {
        var userLoggedIn = this.$loggedIn.value;
        var loginAlways = this.clientConfiguration.loginMode === td_enumerations_1.WebLoginMode.Always;
        return loginAlways ? userLoggedIn && this.loggedIn() : true;
        // if (loginAlways) {
        //   return userLoggedIn && this.loggedIn();
        // }
        // else if (!loginAlways) {
        //   return true;
        // }
        // else {
        //   return false;
        // }
        // return /*todo: uWebGlobal.LoginAlwaysRequired*/false && !this.loggedIn();
    };
    SessionHelperService.prototype.canViewMedia = function () {
        return this.license.picture;
    };
    SessionHelperService.prototype.canViewOrder = function () {
        return this.canEditOrder();
    };
    SessionHelperService.prototype.canEditOrder = function () {
        return (this.serverOptions.orderMode === td_enumerations_1.WebOrderMode.On) && this.license && this.license.stockBasic &&
            this.loggedIn();
    };
    SessionHelperService.prototype.canViewOperation = function () {
        return this.loggedIn() && this.license && this.license.operation;
    };
    SessionHelperService.prototype.canViewProductionOverview = function () {
        return (this.serverOptions.prodOverviewVisibilityMode === td_enumerations_1.ProdOverviewVisibilityMode.AvailableInWeb) &&
            this.haveInfoOverviews && this.productionOverviewLicense();
    };
    SessionHelperService.prototype.productionOverviewLicense = function () {
        return !!this.license && [td_enumerations_1.ProductLine.SDM, td_enumerations_1.ProductLine.TDOC2000, td_enumerations_1.ProductLine.EndoCycle].includes(this.license.productLine);
    };
    SessionHelperService.prototype.canViewStock = function () {
        return this.clientConfiguration.prodStockMode !== td_enumerations_1.ProductStockControlMode.Simple;
    };
    SessionHelperService.prototype.canFastTrack = function () {
        return this.loggedIn() && this.license && !!(this.license.fastTrackBasic && this.serverOptions && (this.serverOptions.defaultFTCoKeyId > 0));
    };
    SessionHelperService.prototype.showFastTrack = function () {
        return this.license && this.license.fastTrackBasic;
    };
    SessionHelperService.prototype.canViewEndoscope = function () {
        return this.license && this.license.endoscope;
    };
    SessionHelperService.prototype.updateLoggedInSubject = function () {
        this.$loggedIn.next(this.customerSelected && this.isAuthenticated);
    };
    Object.defineProperty(SessionHelperService.prototype, "customerFactoryKeyIds", {
        get: function () {
            var customerFactoryIds = [];
            if (this.customerFactories && (this.customerFactories.length > 0)) {
                this.customerFactories.forEach(function (factory) { customerFactoryIds.push(factory.keyId); });
            }
            return customerFactoryIds;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHelperService.prototype, "currentFactoryKeyId", {
        get: function () {
            if (this.customerFactories && (this.customerFactories.length > 0)) {
                return this.customerFactories[0].keyId;
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHelperService.prototype, "userPrimaryFactoryKeyId", {
        get: function () {
            if (this.userFactories && (this.userFactories.length > 0)) {
                return this.userFactories[0].keyId;
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHelperService.prototype, "orderFactoryKeyIds", {
        get: function () {
            var orderFactoryIds = [];
            if (this.userLastOpenOrder) {
                orderFactoryIds.push(this.userLastOpenOrder.details.suppRefFacKeyId);
            }
            if (orderFactoryIds.length > 0) {
                return orderFactoryIds;
            }
            else {
                return this.customerFactoryKeyIds;
            }
        },
        enumerable: true,
        configurable: true
    });
    SessionHelperService.prototype.quantityIsValid = function (value, maxValue) {
        if (maxValue === void 0) { maxValue = td_constants_1.TD_MAX_INT32_VALUE; }
        if (!value) {
            return false;
        }
        else if (value > td_constants_1.TD_MAX_INT32_VALUE) {
            this.popupService.showInfo(this.translateService.instant('appMessages.maxQuantityValidation')
                .replace('%d', Math.floor(maxValue).toString()));
            return false;
        }
        else {
            return true;
        }
    };
    SessionHelperService.prototype.reloadCustomerOpenOrders = function () {
        this.store.dispatch(new ordActions.LoadCustomerOpenOrders(this.currentCustomer.keyId));
    };
    SessionHelperService.prototype.initUserLastOpenOrder = function () {
        var _this = this;
        var loggedInUser = this.authService.getClaims();
        if (loggedInUser && this.customerOpenOrders && (this.customerOpenOrders.length > 0)) {
            // filter user's open orders and get the newest one in case exists
            var userOpenOrders = this.customerOpenOrders.filter(function (order) { return order.orgUserKeyId == loggedInUser.user_id; });
            if (userOpenOrders && (userOpenOrders.length > 0)) {
                if (!this.userLastOpenOrder || !userOpenOrders.find(function (order) { return order.keyId === _this.userLastOpenOrder.details.keyId; })) {
                    this.reloadLastOpenOrder(userOpenOrders[0].keyId);
                }
            }
            // get the newest customer's open order
            else {
                this.reloadLastOpenOrder(this.customerOpenOrders[0].keyId);
            }
        }
        else {
            this.store.dispatch(new ordActions.SetUserLastOpenOrder(null));
        }
    };
    SessionHelperService.prototype.reloadLastOpenOrder = function (orderKeyId) {
        this.loadPanelService.hideLoadPanel();
        this.store.dispatch(new ordActions.LoadUserLastOpenOrder(orderKeyId, this.currentCustomer.keyId));
    };
    SessionHelperService.prototype.getChargeOptions = function () {
        return this.currentCustomer ? this.currentCustomer.chargeOptions : 0;
    };
    SessionHelperService.prototype.canOrderProduct = function (product) {
        if (product.omitted || !this.currentCustomer) {
            return false;
        }
        if (product.custKeyId < 1) {
            return (this.serverOptions.displayGeneralProductsMode === td_enumerations_1.WebDisplayGeneralProductsMode.Allowed);
        }
        switch (this.serverOptions.displayCustomerProductsMode) {
            case td_enumerations_1.WebDisplayCustomerProductsMode.OwnOnly: {
                return (this.currentCustomer.keyId === product.custKeyId);
            }
            case td_enumerations_1.WebDisplayCustomerProductsMode.OwnSiteOnly: {
                return this.siteCustomers && this.siteCustomers.some(function (cust) { return cust.keyId === product.custKeyId; });
            }
            case td_enumerations_1.WebDisplayCustomerProductsMode.OwnSiteOrCust: {
                return (this.currentCustomer.keyId === product.custKeyId) || (this.siteCustomers && this.siteCustomers.some(function (cust) { return cust.keyId === product.custKeyId; }));
            }
            case td_enumerations_1.WebDisplayCustomerProductsMode.All: {
                return true;
            }
            default: {
                return false;
            }
        }
    };
    SessionHelperService.prototype.getBookingEnabled = function () {
        return this.license.allowBooking && !!this.customerFactories && (this.customerFactories.length > 0) &&
            this.customerFactories.some(function (fac) { return ![td_enumerations_1.FacOrderBookingMode.Disabled, null].includes(fac.orderBookingMode); });
    };
    SessionHelperService.prototype.isAdminUser = function () {
        return this.profileData.system_SystemAdminRights;
    };
    SessionHelperService.prototype.getPassVerifMethod = function (serverOptions) {
        var options = serverOptions ? serverOptions : this.serverOptions;
        if (options.passwordVerificationMethod === td_enumerations_1.PasswordVerificationMethod.None) {
            if (this.clientConfiguration.passwordVerify === td_enumerations_1.PasswordVerify.ActiveDirectory) {
                return td_enumerations_1.PasswordVerificationMethod.ActiveDirectory;
            }
            else {
                return td_enumerations_1.PasswordVerificationMethod.TDOC;
            }
        }
        else {
            return options.passwordVerificationMethod;
        }
    };
    SessionHelperService.prototype.getUserOptions = function () {
        return this.userOptions ? this.userOptions : this.defaultUserOptions;
    };
    SessionHelperService.prototype.urlAllowsOrderCart = function (url) {
        return (url === '/') || url.includes('/products') || url.includes('/items') || url.includes('/search');
    };
    SessionHelperService.prototype.getSigningOut = function () {
        return this.authService.getSigningOut();
    };
    SessionHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionHelperService_Factory() { return new SessionHelperService(i0.ɵɵinject("AUTH_SERVICE"), i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.UtilityService), i0.ɵɵinject(i3.TdPopupService), i0.ɵɵinject(i4.TdLoadPanelService), i0.ɵɵinject(i5.TranslateService)); }, token: SessionHelperService, providedIn: "root" });
    return SessionHelperService;
}());
exports.SessionHelperService = SessionHelperService;
