import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggerService } from '@core/logger/logger.service';

@Injectable()
export class TelemetryInterceptor implements HttpInterceptor {

  constructor(private loggerService: LoggerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse) {
          const elapsed = Date.now() - started;
          this.loggerService.log(`Request for ${request.urlWithParams} took ${elapsed} ms.`);
        }
      })
    );
  }
}
