import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import * as factoryActions from '@core/data-layer/factory/store/factory.actions';
import { selectAllFactories } from '@core/data-layer/factory/store/factory.selectors';
import { FactoryModel } from '@core/data-layer/factory/models/factory.model';

@Injectable({
  providedIn: 'root'
})
export class FactoriesInitializer {

  constructor(
    private store: Store<AppState>
  ) { }

  initializeAllFactories(): Promise<FactoryModel[]> {
    return new Promise(resolve => {
      this.store.dispatch(new factoryActions.LoadAllFactories());
      this.store.pipe(select(selectAllFactories)).subscribe(factories => {
        if (factories) {
          resolve(factories);
        }
      });
    });
  }

}
