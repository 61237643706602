"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utility_service_1 = require("@core/utility/utility.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var core_1 = require("@ngx-translate/core");
var session_helper_service_1 = require("./session-helper.service");
var grid_structure_interface_1 = require("@core/table-info/models/grid-structure.interface");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var app_constants_1 = require("../models/app.constants");
var i0 = require("@angular/core");
var i1 = require("../../core/utility/utility.service");
var i2 = require("@ngx-translate/core");
var i3 = require("./session-helper.service");
var GridColumnHelperService = /** @class */ (function () {
    function GridColumnHelperService(utilityService, translateService, sessionHelperService) {
        this.utilityService = utilityService;
        this.translateService = translateService;
        this.sessionHelperService = sessionHelperService;
    }
    // TODO: in future should be able to include "calculated" fields to be send to BackEnd
    GridColumnHelperService.prototype.isSelectedColumn = function (columnConfig) {
        return columnConfig.required || (columnConfig.visible && !columnConfig.calculated && (columnConfig.dataType !== grid_structure_interface_1.PropertyTypes.typeUndefined) && (columnConfig.dataType !== grid_structure_interface_1.PropertyTypes.typeObject));
    };
    GridColumnHelperService.prototype.addCustomColumn = function (columnsConfigurations, newColumnConfig) {
        if (columnsConfigurations) {
            var columnConfigIndex = columnsConfigurations.findIndex(function (column) { return column.dataField === newColumnConfig.dataField; });
            if (columnConfigIndex === -1) {
                if (newColumnConfig.visibleIndex || newColumnConfig.visibleIndex === 0) {
                    // adjust column visible index by [+1] for all the elements after one that are inserting now
                    columnsConfigurations.forEach(function (entry) {
                        if (entry.visibleIndex >= newColumnConfig.visibleIndex) {
                            entry.visibleIndex++;
                        }
                    });
                    // INSERT at the concrete POSITION in array
                    columnsConfigurations.splice(newColumnConfig.visibleIndex, 0, newColumnConfig);
                }
                else {
                    // adjust column visible index to be the last one
                    if (newColumnConfig.visible) {
                        newColumnConfig.visibleIndex = columnsConfigurations.length;
                    }
                    // INSERT at the END of array
                    columnsConfigurations.push(newColumnConfig);
                }
            }
            else {
                // UPDATE EXISTING element in array
                columnsConfigurations[columnConfigIndex] = newColumnConfig;
                // re-sort all elements in array by visible index
                columnsConfigurations.sort(function (a, b) { return a.visibleIndex - b.visibleIndex; });
            }
        }
    };
    GridColumnHelperService.prototype.removeColumn = function (columnsConfigurations, columnName) {
        if (columnsConfigurations) {
            var columnConfigIndex = columnsConfigurations.findIndex(function (column) { return column.dataField === columnName; });
            if (columnConfigIndex > -1) {
                columnsConfigurations.splice(columnConfigIndex, 1);
            }
        }
    };
    GridColumnHelperService.prototype.setColumnProperty = function (columnsConfigurations, columnName, property) {
        var columnConfig = this.getColumnConfigurationByName(columnsConfigurations, columnName);
        if (columnConfig) {
            this.utilityService.copyPropertyValues(property, columnConfig, true);
        }
    };
    GridColumnHelperService.prototype.setColumnDisplayName = function (columnsConfigurations, columnName, displayName) {
        var columnConfig = this.getColumnConfigurationByName(columnsConfigurations, columnName);
        if (columnConfig) {
            columnConfig.displayName = displayName;
        }
    };
    GridColumnHelperService.prototype.addActionColumn = function (columnsConfigurations, dataField, displayName, width, visibleIndex) {
        var actionColumn = this.getColumnConfigurationByName(columnsConfigurations, dataField);
        if (!actionColumn) {
            actionColumn = {
                dataField: dataField,
                dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
                visible: true,
                visibleIndex: visibleIndex,
                displayName: displayName,
                width: width,
                cellTemplate: app_constants_1.GRID_TEMPLATES.customTemplate,
                style: {
                    alignment: 'center',
                }
            };
        }
        else {
            actionColumn.cellTemplate = app_constants_1.GRID_TEMPLATES.customTemplate;
            actionColumn.displayName = displayName;
            actionColumn.dataType = grid_structure_interface_1.PropertyTypes.typeUndefined;
        }
        this.addCustomColumn(columnsConfigurations, actionColumn);
    };
    // add custom "media" column
    GridColumnHelperService.prototype.addCustomMediaColumn = function (columnsConfigurations) {
        if (this.sessionHelperService.license.picture) {
            this.addActionColumn(columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.media, this.translateService.instant('calcColumns.media'), '60', 0);
        }
    };
    // add custom "fast track" column
    GridColumnHelperService.prototype.addCustomFastTrackColumn = function (columnsConfigurations) {
        if (this.sessionHelperService.showFastTrack()) {
            this.addActionColumn(columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.fastTrack, this.translateService.instant('calcColumns.fastTrack'), undefined, 0);
        }
    };
    // add custom "view substitutions" column
    GridColumnHelperService.prototype.addCustomSubstitutionsColumn = function (columnsConfigurations, visibleIndex) {
        // If substituteIcons column already exists, remove it
        if (columnsConfigurations.findIndex(function (entry) { return entry.dataField === td_constants_1.TD_ACTION_FIELDS.substituteIcons; }) !== -1) {
            this.removeColumn(columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.substituteIcons);
        }
        // find the number column
        var numberColumnEntry = columnsConfigurations.find(function (entry) { return entry.displayName === 'Number'; });
        // used for setting visibleIndex of substituteIcons column
        var targetVisibleIndex;
        // set target visibleIndex for sub icons column to numberColumn index + 1
        targetVisibleIndex = numberColumnEntry ? numberColumnEntry.visibleIndex + 1 : visibleIndex;
        // add the column
        this.addActionColumn(columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.substituteIcons, '', '60', targetVisibleIndex);
        // re-sort all elements in array by visible index
        columnsConfigurations.sort(function (a, b) { return a.visibleIndex - b.visibleIndex; });
    };
    // add custom "manage substitutions" column
    GridColumnHelperService.prototype.addCustomManageSubstitutionsColumn = function (columnsConfigurations) {
        this.addActionColumn(columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.manageSubstitutions, '', '30', 0);
    };
    GridColumnHelperService.prototype.getColumnConfigurationByName = function (columnsConfigurations, dataField) {
        return columnsConfigurations ? columnsConfigurations.find(function (columnConfig) { return columnConfig.dataField === dataField; }) : null;
    };
    GridColumnHelperService.prototype.hidePriceColumn = function (dataField) {
        var hidePrice = this.sessionHelperService.serverOptions.displayPriceMode === td_enumerations_1.WebDisplayPriceMode.HideAll;
        if (hidePrice) {
            hidePrice = dataField.toLowerCase().includes(td_constants_1.TD_PRICE_FIELD_NAMES.price) ||
                dataField.toLowerCase().includes(td_constants_1.TD_PRICE_FIELD_NAMES.pricing) ||
                dataField.toLowerCase().includes(td_constants_1.TD_PRICE_FIELD_NAMES.priGrKeyId) ||
                dataField.toLowerCase().includes(td_constants_1.TD_PRICE_FIELD_NAMES.priGr);
        }
        return hidePrice;
    };
    GridColumnHelperService.prototype.getGridOptions = function (maxAllowedHeight, rowCount, excludeElementsIds, excludeAdditionalHeight, setParentComponentHeight) {
        if (excludeElementsIds === void 0) { excludeElementsIds = []; }
        if (excludeAdditionalHeight === void 0) { excludeAdditionalHeight = 0; }
        if (setParentComponentHeight === void 0) { setParentComponentHeight = false; }
        var borderHeight = 1;
        var excludingHeight = this.utilityService.getElementsHeightSum(excludeElementsIds) + excludeAdditionalHeight;
        var maxAllowedGridHeight = maxAllowedHeight ? (maxAllowedHeight - excludingHeight) : app_constants_1.GRID_SIZES.maxPopupGridHeight;
        var maxGridHeight = (rowCount ? ((rowCount * app_constants_1.GRID_SIZES.rowHeight) + (2 * borderHeight)) : app_constants_1.GRID_SIZES.noArticlesHeight) +
            app_constants_1.GRID_SIZES.gridHeaderHeight;
        var gridOptions;
        if (maxGridHeight > maxAllowedGridHeight) {
            gridOptions = {
                height: maxAllowedGridHeight,
                showScrollbar: 'always'
            };
        }
        else {
            gridOptions = {
                height: maxGridHeight,
                showScrollbar: 'never'
            };
        }
        if (setParentComponentHeight) {
            gridOptions.parentComponentHeight = gridOptions.height + excludingHeight;
        }
        return gridOptions;
    };
    GridColumnHelperService.prototype.doesColumnExist = function (columnConfiguration, column) {
        return columnConfiguration.some(function (configuration) {
            return configuration.dataField === column && configuration.visible;
        });
    };
    GridColumnHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GridColumnHelperService_Factory() { return new GridColumnHelperService(i0.ɵɵinject(i1.UtilityService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.SessionHelperService)); }, token: GridColumnHelperService, providedIn: "root" });
    return GridColumnHelperService;
}());
exports.GridColumnHelperService = GridColumnHelperService;
