"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StackTrace = require("stacktrace-js");
var error_report_model_1 = require("../models/error-report.model");
var i0 = require("@angular/core");
var ErrorReportManagerService = /** @class */ (function () {
    function ErrorReportManagerService(errorReportService) {
        this.errorReportService = errorReportService;
    }
    ErrorReportManagerService.prototype.handleError = function (error, message, url, exceptionClass, exceptionMessage, traceId) {
        var _this = this;
        if (traceId === void 0) { traceId = ''; }
        // Create special trace with extra info (eg: function names and ts references)
        // OBS: this is necessary because if we just send the string from f.ex. error.stack it will be different
        // than what is shown in the console, as the console automatically translates js references to ts based on source maps.
        // To replicate the same behavior, we need to use stacktrace-js
        StackTrace.fromError(error).then(function (arr) {
            var improvedStackTrace = arr.join('\n');
            // Send the report
            var errorReport = new error_report_model_1.ErrorReportModel(message, url, exceptionClass, exceptionMessage, improvedStackTrace, traceId);
            _this.errorReportService.sendErrorReport(errorReport).subscribe();
        });
    };
    ErrorReportManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorReportManagerService_Factory() { return new ErrorReportManagerService(i0.ɵɵinject("ERROR_REPORT_SERVICE")); }, token: ErrorReportManagerService, providedIn: "root" });
    return ErrorReportManagerService;
}());
exports.ErrorReportManagerService = ErrorReportManagerService;
