<div class="page-container d-flex justify-content-center mt-5">
  <div id="landing-container" class="d-flex flex-column align-items-center">
    <div class="landing-header">
      {{ 'landing.welcomeToTDOCWeb' | translate }}
    </div>
    <ng-container *ngIf="loginModeAlways">
      <ng-container *ngIf="userLoggedIn">
        <div class="d-flex flex-row landing-page-text">
          {{ 'landing.searchOptions' | translate }}
        </div>
        <div class="d-flex flex-row landing-page-text">
          {{ 'landing.loggedIn' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="!userLoggedIn">
        <div class="d-flex flex-row landing-page-text">
          {{ 'landing.loginModeAlwaysIntro' | translate }}
        </div>
        <div class="d-flex flex-row landing-page-text">
          {{ 'landing.loginModeAlwaysLogIn' | translate }}
        </div>
        <div class="d-flex flex-row">
          <dx-button class="td-button success-button mt-3"
                     icon=".\assets\img\Log_in.svg"
                     text="{{'appCommon.login' | translate }}"
                     (onClick)="login()">
          </dx-button>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!loginModeAlways">
      <div class="d-flex flex-row landing-page-text">
        {{ 'landing.searchOptions' | translate }}
      </div>
      <div *ngIf="userLoggedIn" class="d-flex flex-row landing-page-text">
        {{ 'landing.loggedIn' | translate }}
      </div>
      <ng-container *ngIf="!userLoggedIn">
        <div class="d-flex flex-row landing-page-text">
          {{ 'landing.loginFor' | translate }}
        </div>
        <div class="d-flex flex-row">
          <dx-button
            class="td-button success-button mt-3 landing-login-button"
            icon=".\assets\img\Log_in.svg"
            text="{{'appCommon.login' | translate }}"
            (onClick)="login()">
          </dx-button>
        </div>
      </ng-container>

    </ng-container>


  </div>
</div>
