"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var ProfileDataModel = /** @class */ (function () {
    function ProfileDataModel() {
        this.preferences_AutoEdit = false;
        this.preferences_StartWithSearch = false; // removed
        this.preferences_ModalOnSearch = false;
        this.preferences_WarnOnKeyChange = true;
        this.preferences_Language = td_enumerations_1.Language.Default;
        this.preferences_NotifyPriorityAsEmail = td_enumerations_1.NotifyPriority.None;
        this.preferences_NotifyPriorityAsSMS = td_enumerations_1.NotifyPriority.None;
        this.preferences_NotifyPriorityAsPopup = td_enumerations_1.NotifyPriority.High;
        this.preferences_FastTrackPrompt = true;
        this.preferences_PictureOnPack = td_enumerations_1.UserPictureOnPack.On;
        this.functions_Profficiency = 0;
        this.functions_MaxDifficulty = 0;
        this.functions_AdminLOT = 0;
        this.functions_AllowAdminLogin = true;
        this.functions_DiscardLevel = 0;
        this.system_SystemAdminRights = false;
        this.system_CanControlTDocComputers = false;
        this.system_CanChangeDefaults = false;
        this.system_CanMarkUnits = false;
        this.system_DatabaseCleanUp = false;
        this.system_CanShutdown = false;
        this.system_CanChangeUnitBatch = false;
        this.system_CanRunJobs = false;
        this.system_CanRestartServers = false;
        this.system_CanBackup = false;
        this.system_CanRestore = false;
        this.system_CanArchive = false;
        this.system_CanEditItemListEverywhere = false; //Obsolete; Removed from list using 'stored' directive. Cannot be removed - read error occures if it exists in dfm.
        this.system_CanRedirectPrinters = false;
        this.system_CanWorkWithCommonData = true;
        this.system_CanAssignFactories = false;
        this.system_CanAssignFactoryData = td_enumerations_1.AssignFactoryData.None;
        this.system_CanCreateAdminProcess = false;
        this.system_CanEditReportSecurity = false;
        this.system_CanEditPictures = true;
        this.system_CanEditProcessPictures = true;
        this.system_CanChangeParameterValues = true;
        this.system_CanRegisterWeightWithScanner = false;
        this.system_CanChangeBatchChargeNum = false;
        this.system_CanChangeBatchExternalNum = false;
        this.system_CanSetBatchChargeNum = true;
        this.system_CanSetBatchExternalNum = true;
        this.system_CanCancelFastTrack = false;
        this.system_CanClearProcessError = false;
        this.system_CanChangeProcessProgram = false;
        this.system_CanChangeUnitPatient = false;
        this.system_CanChangeVideoAutoplay = false;
        this.system_CanSetReferenceProcess = false;
        this.system_CanApproveEnableABatch = false;
        this.system_CanMergeBasicData = false;
        this.system_CanEditInfoOverviewConfigs = false;
        this.system_CanEditExtDocRefs = false;
        this.system_CanCreateIndicators = false;
        this.system_CanApproveIndicators = false;
        this.system_CanDisapproveIndicators = false;
        this.system_CanForceDispatchExpired = false;
        this.system_CanForceDispatchWrongCust = true;
        this.system_CanForceDispatchIndicator = false;
        this.system_CanForceIndicatorApproval = false;
        this.system_CanForceDispatchReserved = false;
        this.system_CanForceDifferentProgramGroup = false;
        this.system_CanForceFlexibleDelivery = false;
        this.system_CanForceReservation = false;
        this.system_CanSendOrdWhenBookingFailed = false;
        this.system_CanClearSerialReference = false;
        this.system_CanPostRegisterIndicators = true;
        this.system_CanSetDefaultLocation = false;
        this.system_CanChangePackMaterial = true;
        this.system_CanCancelUnits = true;
        this.system_CanIgnoreEndoscopeErrors = false;
        this.system_CanCreateUnitFromProductList = false;
        this.system_CanChangeDefaultCalendar = false;
        this.system_CanChangeCalendar = false;
        this.system_CanChangeCustBookingOrderMode = false;
        this.system_CanSeeAllProductsInWeb = false;
        this.system_CanForceUseReserved = false;
        this.system_CanCustomizeToolbars = true;
        this.system_CanCustomizeTabs = true;
        this.system_CanClearRecents = false;
        this.system_CanViewProductionOverview = true;
        this.system_CanEditLOTNumbersOfOthers = false;
        this.system_CanEditMachineDisplayGroups = false;
        this.system_CanSuspendMachineLogging = false;
        this.system_CanEditPIPSystemData = false;
        this.system_CanAlterItemSerialInCount = true;
        this.system_CanSkipPatientVerification = false;
        this.system_CanOverrideStopTrigger = false;
        this.system_CanContinueOnRestrictedTrigger = false;
        this.system_CanEditCustomSQLFilters = false;
        this.system_FullReportEditingRights = false;
        this.system_SysOP = false;
        this.pip_CanViewPIPData = false;
        this.pip_CanViewPIPDataActiveOperations = false;
        this.pip_CanViewPIPDataEndedOperations = false;
        this.pip_CanViewPIPAuditLog = false;
    }
    return ProfileDataModel;
}());
exports.ProfileDataModel = ProfileDataModel;
