import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { OperationDataServiceInterface } from '@core/data-layer/operation-data/services/operation-data.service.interface';
import { OrderServiceInterface } from '@core/data-layer/order/services/order.service.interface';
import { ApiDataResponse } from '@core/data-layer/shared/models/api-response.model';
import { TdPopupService } from '@app/shared/components/td-popup/services/td-popup.service';
import { OrderManagerService } from '@app/shared/services/order-manager.service';
import { ProductionErrorService } from '@app/shared/services/production-error.service';
import { SessionHelperService } from '@app/shared/services/session-helper.service';
import { TdLoadPanelService } from '@app/shared/components/td-load-panel/services/td-load-panel.service';

@Injectable({providedIn: 'root'})

export class OperationsSharedService {

  constructor(
    @Inject('ORDER_SERVICE') private orderService: OrderServiceInterface,
    @Inject('OPERATION_DATA_SERVICE') private operationService: OperationDataServiceInterface,
    private popupService: TdPopupService,
    private translateService: TranslateService,
    private orderManagerService: OrderManagerService,
    private sessionHelperService: SessionHelperService,
    private productionErrorService: ProductionErrorService,
    private loadPanelService: TdLoadPanelService,
    private router: Router
  ) { }

  addOrderToOperation(operationKeyId: number, stokKeyId?: number, reqDelTime?: Date) {
    this.operationService.getCreateOperationOrderInfo(operationKeyId, this.sessionHelperService.currentCustomer.keyId).subscribe(info => {
      if (info.roomBlockOrderCreation) {
        this.loadPanelService.hideLoadPanel();
        this.showErrorIfRoomBlocksOrderCreation(info.roomText);
      }
      else if (!info.customerCanOrder) {
        this.loadPanelService.hideLoadPanel();
        this.showErrorIfCustomerCannotCreateOrder(info.customerText);
      }
      else {
      this.saveNewOrderToOperation(operationKeyId, stokKeyId, reqDelTime);
      }
    });
  }

  private showErrorIfRoomBlocksOrderCreation(roomText: string) {
    const message = this.translateService.instant('operationDetails.ordersBlockedForRoom').replace('%s', roomText);
    this.popupService.showError(message);
  }

  private showErrorIfCustomerCannotCreateOrder(customerText: string) {
    const message = this.translateService.instant('operationDetails.customerCannotOrder').replace('%s', customerText);
    this.popupService.showError(message);
  }

  private saveNewOrderToOperation(operationKeyId: number, stokKeyId?: number, reqDelTime?: Date) {
    const newOrder = this.orderManagerService.initializeNewOrder(operationKeyId, stokKeyId);
    newOrder.details.reqDelTime = reqDelTime;
    this.orderService.saveOrder(newOrder).subscribe(response => {
      this.loadPanelService.hideLoadPanel();
      if (this.productionErrorService.handleResponseErrors(response)) {
        this.handleSuccessfulSavedOrder((response as ApiDataResponse<number>).value);
      }
    });
  }

  private handleSuccessfulSavedOrder(orderId: number) {
    this.sessionHelperService.reloadCustomerOpenOrders();
    this.router.navigate([`/orders/${orderId}`]);
  }

}
