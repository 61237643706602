"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var devextreme_angular_1 = require("devextreme-angular");
var base_component_1 = require("@shared/components/base/base.component");
var constrol_state_model_1 = require("@shared/models/constrol-state.model");
var search_manager_service_1 = require("@core/search/services/search-manager.service");
var tab_helper_service_1 = require("@shared/services/tab-helper.service");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var grid_structure_interface_1 = require("@core/table-info/models/grid-structure.interface");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var td_enumerations_2 = require("@core/data-layer/shared/models/td.enumerations");
var td_constants_2 = require("@core/data-layer/shared/models/td.constants");
var operations_shared_service_1 = require("../services/operations-shared.service");
var OperationDetailsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OperationDetailsComponent, _super);
    function OperationDetailsComponent(operationService, activatedRoute, searchManagerService, tabHelperService, operationsSharedService) {
        var _this = _super.call(this) || this;
        _this.operationService = operationService;
        _this.activatedRoute = activatedRoute;
        _this.searchManagerService = searchManagerService;
        _this.tabHelperService = tabHelperService;
        _this.operationsSharedService = operationsSharedService;
        _this.productsTabTemplate = 'products';
        _this.itemsTabTemplate = 'items';
        _this.endoscopesTabTemplate = 'endoscopes';
        _this.startOpBtnState = new constrol_state_model_1.ControlState();
        _this.stopOpBtnState = new constrol_state_model_1.ControlState();
        _this.showRemoveUnit = false;
        _this.allowAddOrder = false;
        _this.TD_MODEL_NAMES = td_constants_1.TD_MODEL_NAMES;
        _this.TD_DESKTOP_IDENTIFIERS = td_constants_1.TD_DESKTOP_IDENTIFIERS;
        _this.TD_DYNAMIC_FIELDS = td_constants_1.TD_DYNAMIC_FIELDS;
        _this.TD_SYSTEM_MEDIA_SERIES = td_constants_1.TD_SYSTEM_MEDIA_SERIES;
        _this.TableTypes = td_enumerations_1.TableTypes;
        return _this;
    }
    OperationDetailsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _super.prototype.ngOnInit.call(this);
                        return [4 /*yield*/, this.initGrid(td_constants_1.TD_DESKTOP_IDENTIFIERS.operationProductListGrid, td_constants_1.TD_MODEL_NAMES.productModel)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.initGrid(td_constants_1.TD_DESKTOP_IDENTIFIERS.operationItemListGrid, td_constants_1.TD_MODEL_NAMES.itemModel)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.initGrid(td_constants_1.TD_DESKTOP_IDENTIFIERS.operationEndoscopeListGrid, td_constants_1.TD_MODEL_NAMES.productModel)];
                    case 3:
                        _a.sent();
                        this.bookingEnabled = this.sessionHelperService.getBookingEnabled();
                        this.getOperation();
                        return [2 /*return*/];
                }
            });
        });
    };
    OperationDetailsComponent.prototype.initGrid = function (identifier, model) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.tableInfoService.loadGridStructure(identifier, model).subscribe(function (gridStructure) {
                if (gridStructure) {
                    // add custom "media" column
                    if (_this.sessionHelperService.canViewMedia()) {
                        _this.gridColumnHelperService.addCustomMediaColumn(gridStructure.columnsConfigurations);
                    }
                    else {
                        // remove custom "media" column
                        _this.gridColumnHelperService.removeColumn(gridStructure.columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.media);
                    }
                    if (identifier === td_constants_1.TD_DESKTOP_IDENTIFIERS.operationItemListGrid) {
                        _this.gridColumnHelperService.setColumnProperty(gridStructure.columnsConfigurations, td_constants_1.TD_DYNAMIC_FIELDS.olnOrgOrdered, { style: { alignment: 'right' } });
                    }
                    else {
                        _this.addRemoveUnitColumn(gridStructure.columnsConfigurations);
                    }
                    _this.setGridStructure(identifier, gridStructure);
                }
                resolve(true);
            });
        });
    };
    OperationDetailsComponent.prototype.addRemoveUnitColumn = function (columnsConfigurations) {
        if (this.allowEditReferenceToUnits()) {
            // add custom "remove unit" column
            var removeUnitColumn = {
                dataField: td_constants_1.TD_ACTION_FIELDS.removeUnit,
                dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
                visible: true,
                width: '30',
                displayName: '',
                style: {
                    alignment: 'center',
                }
            };
            this.gridColumnHelperService.addCustomColumn(columnsConfigurations, removeUnitColumn);
        }
    };
    OperationDetailsComponent.prototype.setGridStructure = function (identifier, gridStructure) {
        switch (identifier) {
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.operationProductListGrid:
                this.productsGridStructure = gridStructure;
                break;
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.operationItemListGrid:
                this.itemsGridStructure = gridStructure;
                break;
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.operationEndoscopeListGrid:
                this.endoscopesGridStructure = gridStructure;
                break;
        }
    };
    OperationDetailsComponent.prototype.getOperation = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            _this.operationKeyId = +params.id;
            _this.loadOperation();
        });
    };
    OperationDetailsComponent.prototype.loadOperation = function (recentItemTypeAdded) {
        var _this = this;
        if (recentItemTypeAdded !== undefined) {
            this.desiredActiveTabIndex = recentItemTypeAdded === td_enumerations_2.ItemInstrumentType.Instrument ? 0 : 2;
        }
        this.loadPanelService.showLoadPanel();
        this.operationService.getOperationData(this.operationKeyId, this.sessionHelperService.currentCustomer.keyId).subscribe(function (operationResponse) {
            _this.loadPanelService.hideLoadPanel();
            if (operationResponse) {
                _this.allowAddOrder = (_this.sessionHelperService.serverOptions.orderMode === td_enumerations_1.WebOrderMode.On) && (operationResponse.status === td_enumerations_2.OperationStatus.Accepted || operationResponse.status === td_enumerations_2.OperationStatus.Planned);
                _this.operation = operationResponse;
                _this.initOperationTabs();
                _this.titleService.setTitle(_this.getTitleIdentifier());
                _this.updateControls();
            }
        });
    };
    OperationDetailsComponent.prototype.getProductsData = function (itemType, template) {
        return this.searchManagerService.requestSearchData(this.getProductsRequestArgs(itemType), '', this.afterDataLoaded(template));
    };
    OperationDetailsComponent.prototype.getItemsData = function () {
        return this.searchManagerService.requestSearchData(this.getItemsRequestArgs(), '', this.afterDataLoaded(this.itemsTabTemplate));
    };
    OperationDetailsComponent.prototype.getProductsRequestArgs = function (itemType) {
        return this.searchManagerService.initWebOperationProductsSearchRequestArgs(this.productsGridStructure, this.operationKeyId, itemType);
    };
    OperationDetailsComponent.prototype.getItemsRequestArgs = function () {
        return this.searchManagerService.initWebOperationItemsSearchRequestArgs(this.itemsGridStructure, this.operationKeyId);
    };
    OperationDetailsComponent.prototype.afterDataLoaded = function (templateName) {
        var _this = this;
        return function (loadResult) {
            _this.tabHelperService.setTabState(_this.operationTabs, templateName, !loadResult.totalCount, _this.tabPanel, _this.desiredActiveTabIndex);
            if ([_this.productsTabTemplate, _this.endoscopesTabTemplate].includes(templateName)) {
                return loadResult.data.map(function (product) { return (product[td_constants_1.TD_DYNAMIC_FIELDS.unitList] = product[td_constants_1.TD_DYNAMIC_FIELDS.unitList].split(',')); });
            }
        };
    };
    OperationDetailsComponent.prototype.initOperationTabs = function () {
        this.operationTabs = [
            {
                title: this.translateInstant('search.products'),
                data: this.getTabData(this.productsTabTemplate),
                template: this.productsTabTemplate
            },
            {
                title: this.translateInstant('search.items'),
                data: this.getTabData(this.itemsTabTemplate),
                template: this.itemsTabTemplate
            },
            {
                title: this.translateInstant('search.endoscopes'),
                data: this.getTabData(this.endoscopesTabTemplate),
                template: this.endoscopesTabTemplate
            }
        ];
    };
    OperationDetailsComponent.prototype.allowEditReferenceToUnits = function (checkOperationStatus) {
        if (checkOperationStatus === void 0) { checkOperationStatus = false; }
        var allowEdit = this.sessionHelperService.serverOptions.editOprationArticlesMode === td_enumerations_2.WebEditOprationArticlesMode.AllowEdit;
        if (checkOperationStatus) {
            allowEdit = allowEdit && ![td_enumerations_2.OperationStatus.Done, td_enumerations_2.OperationStatus.Cancelled].includes(this.operation.status);
        }
        return allowEdit;
    };
    OperationDetailsComponent.prototype.updateControls = function () {
        this.startOpBtnState.disabled = this.operation.status !== td_enumerations_2.OperationStatus.Accepted;
        this.stopOpBtnState.disabled = this.operation.status !== td_enumerations_2.OperationStatus.Started;
        this.removeUnitDisabled = (this.operation.status !== td_enumerations_2.OperationStatus.Started) ||
            (this.sessionHelperService.serverOptions.editOprationArticlesMode === td_enumerations_2.WebEditOprationArticlesMode.NotAllowEdit);
        this.addUnitDisabled = this.operation.status !== td_enumerations_2.OperationStatus.Started;
        this.addUnitVisible = this.allowEditReferenceToUnits(true);
        if (!this.addUnitVisible) {
            this.removeRemoveUnitColumn(this.productsGridStructure);
            this.removeRemoveUnitColumn(this.endoscopesGridStructure);
        }
        this.startOpBtnState.visible =
            (this.sessionHelperService.serverOptions.startStopOperationMode === td_enumerations_2.WebStartStopOperationMode.Allow);
        this.stopOpBtnState.visible = this.startOpBtnState.visible;
    };
    OperationDetailsComponent.prototype.getTitleIdentifier = function () {
        var title = this.translateInstant('appCommon.operation');
        return this.operation ? title + " " + this.operation.ident : title;
    };
    OperationDetailsComponent.prototype.refreshOperation = function () {
        this.showRemoveUnit = false;
        this.loadOperation();
    };
    OperationDetailsComponent.prototype.refreshGridData = function (tab) {
        tab.data = this.getTabData(tab.template);
    };
    OperationDetailsComponent.prototype.getTabData = function (template) {
        switch (template) {
            case this.productsTabTemplate:
                return this.getProductsData(td_enumerations_2.ItemInstrumentType.Instrument, template);
            case this.itemsTabTemplate:
                return this.getItemsData();
            case this.endoscopesTabTemplate:
                return this.getProductsData(td_enumerations_2.ItemInstrumentType.Endoscope, template);
        }
    };
    OperationDetailsComponent.prototype.createOrder = function () {
        this.loadPanelService.showLoadPanel();
        this.operationsSharedService.addOrderToOperation(this.operationKeyId, this.operation.custStokKeyId, this.operation.startTime);
    };
    OperationDetailsComponent.prototype.startOperation = function () {
        var _this = this;
        if (this.checkOperationStatus('start')) {
            var activeOrdersRefNo = this.getActiveOrdersRefNo(this.operation.refOpDOrderList);
            if (activeOrdersRefNo.length > 0) {
                var activeOrdersAsString_1 = '';
                activeOrdersRefNo.forEach(function (entry) {
                    activeOrdersAsString_1 += entry + " <br/>";
                });
                var popupTitle = this.translateInstant('operationDetails.startOperation');
                var popupMessage = this.translateInstant('operationDetails.ordersAssociatedWithOperation').replace(/\|/g, '<br/>').replace('%s', activeOrdersAsString_1);
                this.popupService.confirmDialog(popupTitle, popupMessage).then(function (confirmed) {
                    if (confirmed) {
                        _this.performStartOperation();
                    }
                });
            }
            else {
                this.performStartOperation();
            }
        }
    };
    OperationDetailsComponent.prototype.stopOperation = function () {
        var _this = this;
        if (this.checkOperationStatus('end')) {
            var undeliveredOrders_1 = [];
            this.operation.refOpDOrderList.forEach(function (order) {
                if (order.suppStatus < td_enumerations_2.OrderSuppStatus.Delivered) {
                    undeliveredOrders_1.push(order);
                }
            });
            if (undeliveredOrders_1.length > 0) {
                var popupTitle = this.translateInstant('operationDetails.stopOperation');
                this.popupService.confirmDialog(popupTitle, 'operationDetails.cancelUndeliveredOrders', true).then(function (confirmed) {
                    if (confirmed) {
                        _this.performStopOperation(true);
                    }
                    else {
                        _this.performStopOperation();
                    }
                });
            }
            else {
                this.performStopOperation();
            }
        }
    };
    OperationDetailsComponent.prototype.performStartOperation = function () {
        var _this = this;
        this.operationService.startOperation(this.operationKeyId).subscribe(function (response) {
            _this.handleOperationStatusChangeResponse(response);
        });
    };
    OperationDetailsComponent.prototype.performStopOperation = function (cancelOrders) {
        var _this = this;
        if (cancelOrders === void 0) { cancelOrders = false; }
        this.operationService.stopOperation(this.operationKeyId, cancelOrders).subscribe(function (response) {
            _this.handleOperationStatusChangeResponse(response);
        });
    };
    OperationDetailsComponent.prototype.checkOperationStatus = function (attemptedTask) {
        var status = this.operation.status;
        if (attemptedTask === 'start' && status === td_enumerations_2.OperationStatus.Started) {
            this.popupService.showError('operationDetails.alreadyStarted', true);
            return false;
        }
        else if (status === td_enumerations_2.OperationStatus.Done) {
            this.popupService.showError('operationDetails.alreadyEnded', true);
            return false;
        }
        else {
            return true;
        }
    };
    OperationDetailsComponent.prototype.getActiveOrdersRefNo = function (refOpDOrderList) {
        var result = [];
        refOpDOrderList.forEach(function (order) {
            if (order.ordProductReservationList.length > 0) {
                var reservation = order.ordProductReservationList.find(function (reserv) { return reserv.status === td_enumerations_2.ReservationStatus.Active; });
                if (reservation) {
                    result.push(order.orgRefNo);
                }
            }
        });
        return result;
    };
    OperationDetailsComponent.prototype.handleOperationStatusChangeResponse = function (response) {
        if (response.error !== td_constants_2.NO_ERROR_CODE) {
            this.popupService.showInfo(response.message);
        }
        this.refreshOperation();
    };
    OperationDetailsComponent.prototype.removeUnit = function (data) {
        this.articleUpForRemoval = data;
        this.showRemoveUnit = true;
    };
    OperationDetailsComponent.prototype.removeUnitPopupResolved = function (errorMessage) {
        var _this = this;
        this.showRemoveUnit = false;
        if (errorMessage) {
            if (errorMessage === 'refresh') {
                this.refreshOperation();
            }
            else {
                this.popupService.showInfo(errorMessage).then(function () {
                    _this.refreshOperation();
                });
            }
        }
    };
    OperationDetailsComponent.prototype.removeRemoveUnitColumn = function (gridStructure) {
        if (gridStructure) {
            this.gridColumnHelperService.removeColumn(gridStructure.columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.removeUnit);
        }
    };
    return OperationDetailsComponent;
}(base_component_1.BaseComponent));
exports.OperationDetailsComponent = OperationDetailsComponent;
