"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var order_template_helper_service_1 = require("./order-template-helper.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./order-template-helper.service");
var HttpOrderTemplateService = /** @class */ (function () {
    function HttpOrderTemplateService(http, orderTemplateHelperService) {
        this.http = http;
        this.orderTemplateHelperService = orderTemplateHelperService;
    }
    HttpOrderTemplateService.prototype.templatesUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.orderTemplatesCtrl;
    };
    HttpOrderTemplateService.prototype.getOrderTemplate = function (orderTemplateKeyId) {
        var _this = this;
        return this.http.get(this.templatesUrl() + "(" + orderTemplateKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.details).pipe(operators_1.map(function (response) { return (_this.orderTemplateHelperService.convertOrderTemplateToViewModel(response)); }));
    };
    HttpOrderTemplateService.prototype.getOrderTemplateArticles = function (orderTemplateKeyId) {
        return this.http.get(this.templatesUrl() + "(" + orderTemplateKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.articles);
    };
    HttpOrderTemplateService.prototype.getOrderTemplateCustomer = function (orderTemplateKeyId) {
        return this.http.get(this.templatesUrl() + "(" + orderTemplateKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.customerKeyId);
    };
    HttpOrderTemplateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpOrderTemplateService_Factory() { return new HttpOrderTemplateService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.OrderTemplateHelperService)); }, token: HttpOrderTemplateService, providedIn: "root" });
    return HttpOrderTemplateService;
}());
exports.HttpOrderTemplateService = HttpOrderTemplateService;
