import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { GridTableInterface, GridColumnConfigurationInterface, GridColumnBaseInterface } from '../models/grid-structure.interface';
import { GridTableViewInterface, GridColumnConfigurationViewInterface } from '../models/grid-structure-view.interface';
import { TD_MODEL_NAMES, TD_DYNAMIC_FIELDS, TD_SPECIAL_FIELDS, TD_VALUES } from '@core/data-layer/shared/models/td.constants';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { WebDisplayPriceMode } from '@core/data-layer/shared/models/td.enumerations';
import { GridColumnHelperService } from '@shared/services/grid-column-helper.service';

@Injectable({
  providedIn: 'root'
})
export class TableInfoHelperService {

  constructor(
    private translateService: TranslateService,
    private sessionHelperService: SessionHelperService,
    private gridColumnHelperService: GridColumnHelperService,
  ) { }

  private exceptionColumnTranslation(displayName: string, fieldName: string): string {
    // special-field columns (Created, Created by, Modified, Modified by)
    if ((fieldName === TD_SPECIAL_FIELDS.created) || (fieldName === TD_SPECIAL_FIELDS.modified) ||
      (fieldName === TD_SPECIAL_FIELDS.createdUser) || (fieldName === TD_SPECIAL_FIELDS.modifiedUser) ||
      (fieldName === TD_SPECIAL_FIELDS.createdKeyId) || (fieldName === TD_SPECIAL_FIELDS.modifiedKeyId)) {
      return this.translateService.instant('specialColumns.' + fieldName);
    }
    else if (displayName.endsWith(TD_MODEL_NAMES.unitModel + '.' + TD_DYNAMIC_FIELDS.keyId)) {
      return this.translateService.instant(TD_MODEL_NAMES.unitModel + '.' + TD_DYNAMIC_FIELDS.unit);
    }
    else if (displayName.endsWith(TD_MODEL_NAMES.processModel + '.' + TD_DYNAMIC_FIELDS.keyId)) {
      return this.translateService.instant(TD_MODEL_NAMES.processModel + '.' + TD_DYNAMIC_FIELDS.batch);
    }
    else if (displayName.endsWith(TD_MODEL_NAMES.compositeModel + '.' + TD_DYNAMIC_FIELDS.keyId)) {
      return this.translateService.instant(TD_MODEL_NAMES.compositeModel + '.' + TD_DYNAMIC_FIELDS.key);
    }
    else {
      return '';
    }
  }

  private translateExceptionAndNormalColumnDisplayName(displayName: string, fieldName: string): string {
    // process "exception" columns
    let columnDisplayName = this.exceptionColumnTranslation(displayName, fieldName);
    if (columnDisplayName === '') {
      // process "normal" columns
      columnDisplayName = this.translateService.instant(displayName);
    }
    return columnDisplayName;
  }

  // Example of "raw" display-name to be translated:
  // "orderTemplateModel.suppRefSuppKeyId.supplierModel.facKeyId.factoryModel.createdKeyId.userModel.initials"
  private prepareColumnDisplayNamePrefix(displayName: string): string {
    const fieldParts = displayName.split('.');
    if ((fieldParts.length > 2) && (fieldParts.length % 2 === 0)) {
      let columnPrefix = '';
      // we should skip the last pair "ModelName.PropertyName" - will be prepared separately, later
      for (let i = 0; i < fieldParts.length - 2; i = i + 2) {
        columnPrefix = columnPrefix + this.translateExceptionAndNormalColumnDisplayName(fieldParts[i] + '.' + fieldParts[i + 1], fieldParts[i + 1]) + '.';
      }
      return columnPrefix;
    }
    else {
      return '';
    }
  }

  private translateColumnDisplayName(gridColumn: GridColumnBaseInterface): string {
    let columnDisplayName = '';
    // process "calculated" columns
    if (gridColumn.calculated) {
      columnDisplayName = this.translateService.instant('calcColumns.' + gridColumn.displayName);
    }
    // process "user-field" columns
    else if (gridColumn.userField) {
      if (gridColumn.displayName.includes(TD_VALUES.userFieldDefaultDisplayNamePrefix)) {
        columnDisplayName = gridColumn.displayName.replace(TD_VALUES.userFieldDefaultDisplayNamePrefix, this.translateService.instant('specialColumns.userField'));
      }
      else {
        columnDisplayName = gridColumn.displayName;
      }
    }
    else {
      // get field-name
      const dataFieldParts = gridColumn.dataField.split('.');
      const fieldName = dataFieldParts[dataFieldParts.length - 1];
      // get field-name with own table-name
      let displayName = gridColumn.displayName;
      const displayNameParts = gridColumn.displayName.split('.');
      if (displayNameParts.length > 2) {
        displayName = displayNameParts.slice(displayNameParts.length - 2).join('.');
      }
      columnDisplayName = this.translateExceptionAndNormalColumnDisplayName(displayName, fieldName);
    }
    return columnDisplayName;
  }

  convertGridTablesToViewModel(gridTables: GridTableInterface[]): GridTableViewInterface[] {
    // initialize grid tables
    const gridTablesView: GridTableViewInterface[] = [];
    if (this.sessionHelperService.serverOptions.displayPriceMode === WebDisplayPriceMode.HideAll) {
      // filter out tables related to pricing
      gridTables = gridTables.filter(table => table.tableName !== TD_MODEL_NAMES.priceGroupModel);
    }
    gridTables.forEach(table => {
      if (this.sessionHelperService.serverOptions.displayPriceMode === WebDisplayPriceMode.HideAll) {
        // filter out columns related to pricing
        table.columns = table.columns.filter(column => !this.gridColumnHelperService.hidePriceColumn(column.dataField));
      }
      // translate display names for all columns in table
      table.columns.forEach(column => {
        column.displayName = this.translateColumnDisplayName(column);
      });
      // translate display name for table
      const tableView: GridTableViewInterface = {
        ...table,
        displayName: this.translateService.instant('tables.' + table.tableName)
      }
      gridTablesView.push(tableView);
    });
    return gridTablesView;
  }

  convertGridColumnsConfigurationsToViewModel(columnsConfigurations: GridColumnConfigurationInterface[]): GridColumnConfigurationViewInterface[] {
    const columnsConfigurationsView: GridColumnConfigurationViewInterface[] = [];
    columnsConfigurations.forEach(columnConfig => {
      // prepare column view
      const columnConfigView: GridColumnConfigurationViewInterface = {
        ...columnConfig,
        // view properties
        displayName: this.prepareColumnDisplayNamePrefix(columnConfig.displayName) + this.translateColumnDisplayName(columnConfig)
      };
      columnsConfigurationsView.push(columnConfigView);
    });
    return columnsConfigurationsView;
  }
}
