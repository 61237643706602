import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ArticleStatus, ItemInstrumentType } from '../models/td.enumerations';
import { ItemModel } from '@core/data-layer/item/models/item.model';
import { SessionHelperService } from '@shared/services/session-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ViewHelperService {

  constructor(
    private translateService: TranslateService,
    private sessionHelperService: SessionHelperService
  ) {

  }

  getOmittedText(status: number, articleTypeTranslation: string): string {
    let omittedTranslation: string;
    if (status === ArticleStatus.Dead) {
      omittedTranslation = 'appMessages.isOmitted';
    }
    else if (status === ArticleStatus.Dieing) {
      omittedTranslation = 'appMessages.toBeOmitted';
    }

    if (omittedTranslation) {
      return this.translateService.instant(omittedTranslation).replace('%s', articleTypeTranslation);
    }
    else {
      return '';
    }
  }

  getOmittedImgUrl(status: number): string {
    if (status === ArticleStatus.Dead) {
      return './assets/img/Disable.svg';
    }
    else if (status === ArticleStatus.Dieing) {
      return './assets/img/Warning Orange.svg';
    }
    else {
      return '';
    }
  }

  getItemType(item: ItemModel): ItemInstrumentType {
    if (this.sessionHelperService.license.endoCycle) {
      return ItemInstrumentType.Endoscope;
    }
    else if (this.sessionHelperService.license.endoscope) {
      return item.type as ItemInstrumentType;
    }
    else {
      return ItemInstrumentType.Instrument;
    }
  }
}
