"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".page-details[_ngcontent-%COMP%]     .dx-field-item-content .dx-texteditor{width:200px;height:30px}.page-details[_ngcontent-%COMP%]     .dx-field-item{padding-right:0;padding-left:0}.order-status-img[_ngcontent-%COMP%]{width:16px;height:16px}.order-status-count[_ngcontent-%COMP%]{line-height:0;margin-right:5px}"];
exports.styles = styles;
