"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./landing.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i4 = require("devextreme-angular/core");
var i5 = require("devextreme-angular/ui/button");
var i6 = require("@angular/platform-browser");
var i7 = require("@angular/common");
var i8 = require("./landing.component");
var i9 = require("@angular/router");
var styles_LandingComponent = [i0.styles];
var RenderType_LandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingComponent, data: {} });
exports.RenderType_LandingComponent = RenderType_LandingComponent;
function View_LandingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex flex-row landing-page-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "d-flex flex-row landing-page-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("landing.searchOptions")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("landing.loggedIn")); _ck(_v, 5, 0, currVal_1); }); }
function View_LandingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex flex-row landing-page-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "d-flex flex-row landing-page-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "d-flex flex-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "dx-button", [["class", "td-button success-button mt-3"], ["icon", ".\\assets\\img\\Log_in.svg"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DxButtonComponent_0, i3.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(12, 7323648, null, 0, i5.DxButtonComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { icon: [0, "icon"], text: [1, "text"] }, { onClick: "onClick" }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = ".\\assets\\img\\Log_in.svg"; var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 12, 1, i1.ɵnov(_v, 13).transform("appCommon.login")), ""); _ck(_v, 12, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("landing.loginModeAlwaysIntro")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("landing.loginModeAlwaysLogIn")); _ck(_v, 5, 0, currVal_1); }); }
function View_LandingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_3)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userLoggedIn; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.userLoggedIn; _ck(_v, 4, 0, currVal_1); }, null); }
function View_LandingComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex flex-row landing-page-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("landing.loggedIn")); _ck(_v, 1, 0, currVal_0); }); }
function View_LandingComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex flex-row landing-page-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "d-flex flex-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "dx-button", [["class", "td-button success-button mt-3 landing-login-button"], ["icon", ".\\assets\\img\\Log_in.svg"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DxButtonComponent_0, i3.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(9, 7323648, null, 0, i5.DxButtonComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { icon: [0, "icon"], text: [1, "text"] }, { onClick: "onClick" }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = ".\\assets\\img\\Log_in.svg"; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 10).transform("appCommon.login")), ""); _ck(_v, 9, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("landing.loginFor")); _ck(_v, 2, 0, currVal_0); }); }
function View_LandingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex flex-row landing-page-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_5)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_6)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userLoggedIn; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.userLoggedIn; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("landing.searchOptions")); _ck(_v, 2, 0, currVal_0); }); }
function View_LandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "page-container d-flex justify-content-center mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex flex-column align-items-center"], ["id", "landing-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "landing-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_4)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loginModeAlways; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.loginModeAlways; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("landing.welcomeToTDOCWeb")); _ck(_v, 3, 0, currVal_0); }); }
exports.View_LandingComponent_0 = View_LandingComponent_0;
function View_LandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-landing", [], null, null, null, View_LandingComponent_0, RenderType_LandingComponent)), i1.ɵdid(1, 245760, null, 0, i8.LandingComponent, ["AUTH_SERVICE", i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LandingComponent_Host_0 = View_LandingComponent_Host_0;
var LandingComponentNgFactory = i1.ɵccf("td-landing", i8.LandingComponent, View_LandingComponent_Host_0, {}, {}, []);
exports.LandingComponentNgFactory = LandingComponentNgFactory;
