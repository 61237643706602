import * as custActions from './customer.actions';
import { CustomerModel } from '../models/customer.model';

export interface CustomerState {
  userCustomers: CustomerModel[];
  currentCustomer: CustomerModel;
  siteCustomers: CustomerModel[];
}

export const initialCustState: CustomerState = {
  userCustomers: null,
  currentCustomer: null,
  siteCustomers: null
};

export function customerReducer(state = initialCustState, action: custActions.CustomerActions): CustomerState {
  switch (action.type) {
    case custActions.CustomerActionTypes.LoadUserCustomers:
      return handleLoadUserCustomers(state, action);
    case custActions.CustomerActionTypes.SetUserCustomers:
      return handleSetUserCustomers(state, action);
    case custActions.CustomerActionTypes.SetCurrentCustomer:
      return handleSetCurrentCustomer(state, action);
    case custActions.CustomerActionTypes.LoadSiteCustomers:
      return handleLoadSiteCustomers(state, action);
    case custActions.CustomerActionTypes.SetSiteCustomers:
      return handleSetSiteCustomers(state, action);
    default:
      return state;
  }
}

function handleLoadUserCustomers(state: CustomerState, action: custActions.LoadUserCustomers): CustomerState {
  return state;
}

function handleSetUserCustomers(state: CustomerState, action: custActions.SetUserCustomers): CustomerState {
  return Object.assign({}, state, { userCustomers: action.payload });
}

function handleSetCurrentCustomer(state: CustomerState, action: custActions.SetCurrentCustomer): CustomerState {
  return Object.assign({}, state, { currentCustomer: action.payload });
}

function handleLoadSiteCustomers(state: CustomerState, action: custActions.LoadSiteCustomers): CustomerState {
  return state;
}

function handleSetSiteCustomers(state: CustomerState, action: custActions.SetSiteCustomers): CustomerState {
  return Object.assign({}, state, { siteCustomers: action.payload });
}
