"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ioActions = require("./info-overview.actions");
exports.initialState = {
    connectedToHub: false,
    infoOverviews: null
};
function infoOverviewReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case ioActions.InfoOverviewActionTypes.LoadIOConfigurations:
            return handleLoadIOConfigurations(state, action);
        case ioActions.InfoOverviewActionTypes.LoadIOConfigurationsSuccess:
            return handleLoadIOConfigurationsSuccess(state, action);
        case ioActions.InfoOverviewActionTypes.GetIOConfigurationByIdSuccess:
            return handleGetIOConfigurationByIdSuccess(state, action);
        case ioActions.InfoOverviewActionTypes.SetIOData:
            return handleSetIOData(state, action);
        case ioActions.InfoOverviewActionTypes.SetIODataSuccess:
            return handleSetIODataSuccess(state, action);
        case ioActions.InfoOverviewActionTypes.SetConnectedToHub:
            return handleSetConnectedToHub(state, action);
        case ioActions.InfoOverviewActionTypes.SetConnectedToJSrv:
            return handleSetConnectedToJSrv(state, action);
        default:
            return state;
    }
}
exports.infoOverviewReducer = infoOverviewReducer;
function handleLoadIOConfigurations(state, action) {
    return state;
}
function handleLoadIOConfigurationsSuccess(state, action) {
    return Object.assign({}, state, { infoOverviews: action.payload });
}
function handleGetIOConfigurationByIdSuccess(state, action) {
    var newIOs = !!state.infoOverviews ? state.infoOverviews : Array();
    action.payload.data = null;
    if (newIOs.some(function (io) { return io.configId === action.payload.configId; })) {
        newIOs.map(function (io) { return (io.configId === action.payload.configId ? action.payload : io); });
    }
    else {
        newIOs.push(action.payload);
    }
    return Object.assign({}, state, { infoOverviews: newIOs });
}
function handleSetIOData(state, action) {
    return state;
}
function handleSetIODataSuccess(state, action) {
    if (state.infoOverviews) {
        return Object.assign({}, state, {
            infoOverviews: state.infoOverviews.map(function (io) {
                // we just received update for info-overview data
                if (io.configId === action.payload.configId) {
                    io.data = action.payload.data;
                }
                return io;
            })
        });
    }
    else {
        return state;
    }
}
function handleSetConnectedToHub(state, action) {
    return Object.assign({}, state, { connectedToHub: action.payload });
}
function handleSetConnectedToJSrv(state, action) {
    if (state.infoOverviews) {
        state.infoOverviews.forEach(function (io) {
            if (io.data instanceof action.ioDataType) {
                io.data.hasConnection = action.payload;
            }
        });
        return Object.assign({}, state);
    }
    else {
        return state;
    }
}
