"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./td-deep-link.component");
var i2 = require("../td-popup/services/td-popup.service");
var i3 = require("@angular/router");
var i4 = require("@ngrx/store");
var i5 = require("../td-load-panel/services/td-load-panel.service");
var styles_DeepLinkComponent = [];
var RenderType_DeepLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeepLinkComponent, data: {} });
exports.RenderType_DeepLinkComponent = RenderType_DeepLinkComponent;
function View_DeepLinkComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
exports.View_DeepLinkComponent_0 = View_DeepLinkComponent_0;
function View_DeepLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "td-deep-link", [], null, null, null, View_DeepLinkComponent_0, RenderType_DeepLinkComponent)), i0.ɵdid(1, 4243456, null, 0, i1.DeepLinkComponent, ["AUTH_SERVICE", i2.TdPopupService, i3.ActivatedRoute, i3.Router, i4.Store, i5.TdLoadPanelService], null, null)], null, null); }
exports.View_DeepLinkComponent_Host_0 = View_DeepLinkComponent_Host_0;
var DeepLinkComponentNgFactory = i0.ɵccf("td-deep-link", i1.DeepLinkComponent, View_DeepLinkComponent_Host_0, {}, {}, []);
exports.DeepLinkComponentNgFactory = DeepLinkComponentNgFactory;
