"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var TabHelperService = /** @class */ (function () {
    function TabHelperService() {
    }
    TabHelperService.prototype.setTabState = function (tabs, templateName, disabled, tabPanel, desiredActiveTabIndex) {
        tabs.find(function (tab) { return tab.template === templateName; }).disabled = disabled;
        this.setActiveTab(tabs, tabPanel, desiredActiveTabIndex);
    };
    TabHelperService.prototype.setTabStateFromDesktopIdentifier = function (tabs, identifier, disabled, tabPanel, desiredActiveTabIndex) {
        tabs.find(function (tab) { return tab.desktopIdentifier === identifier; }).disabled = disabled;
        this.setActiveTab(tabs, tabPanel, desiredActiveTabIndex);
    };
    TabHelperService.prototype.setActiveTab = function (tabs, tabPanel, desiredActiveTabIndex) {
        if (desiredActiveTabIndex !== undefined) {
            tabPanel.selectedIndex = desiredActiveTabIndex;
        }
        else {
            if (tabs[tabPanel.selectedIndex].disabled) {
                var enabledTabIndex = tabs.findIndex(function (tab) { return !tab.disabled; });
                if (enabledTabIndex >= 0) {
                    tabPanel.selectedIndex = enabledTabIndex;
                }
            }
        }
    };
    TabHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TabHelperService_Factory() { return new TabHelperService(); }, token: TabHelperService, providedIn: "root" });
    return TabHelperService;
}());
exports.TabHelperService = TabHelperService;
