import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { PropertyToEnumMappingInterface } from '../models/property-to-enum-mapping.interface';
import { NAME_TO_ENUM_MAPPING } from '@core/data-layer/shared/models/td.enumerations';
import { TD_VALUES } from '@core/data-layer/shared/models/td.constants';

export class Language {
  culture: string;
  englishName: string;
  index: number;
  name: string;
  nativeName: string;
}
const baseHref = window.location.origin;
const defaultLanguage = 'en-US';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private currentLanguage: string;
  private languages: Language[] = [];
  private resolveLanguageFiles: (value?: boolean | PromiseLike<boolean>) => void

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {}

  public initializeLanguage(languages: Language[], currentLanguage: string, resolve: (value?: boolean | PromiseLike<boolean>) => void) {
    this.resolveLanguageFiles = resolve;
    this.currentLanguage = currentLanguage;
    this.languages = languages;
    this.addLanguagesForTranslator();
    this.loadLanguageFiles(['tables', 'enumerations', 'app']);
    this.useLanguage();    
  }

  private addLanguagesForTranslator() {
    const isoCodes: string[] = [];
    for (const language of this.languages) {
      isoCodes.push(language.culture);
    }
    this.translateService.addLangs(isoCodes);
  }

  useLanguage(language = '') {
    if (!language) {
      language = this.currentLanguage;
      // this language will be used as a fallback when a translation isn't found in the current language
      this.translateService.setDefaultLang(defaultLanguage);
    }
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use(language).toPromise();
  }

  private getLanguageFilesPath(moduleFolder: string, isoCode: string): string {
    return `${baseHref}/assets/locales/${moduleFolder}/${isoCode}.json`
  }

  loadLanguageFiles(moduleFolders: string[]) {
    const processedModuleFolders = new Array<string>();
    // first of all we should load current language files
    moduleFolders.forEach(folder => {
      this.http.get(this.getLanguageFilesPath(folder, this.currentLanguage)).toPromise().then(transl => {
        this.translateService.setTranslation(this.currentLanguage, transl, true);
        processedModuleFolders.push(folder);
        if (this.resolveLanguageFiles && (processedModuleFolders.length === moduleFolders.length)) {
          this.resolveLanguageFiles(true);
        }
      });      
    });
    // load all others language files except current
    if ((this.currentLanguage !== defaultLanguage) && (this.languages.length > 1)) {
      moduleFolders.forEach(folder => {
        this.http.get(this.getLanguageFilesPath(folder, defaultLanguage)).toPromise().then(transl => {
          this.translateService.setTranslation(defaultLanguage, transl, true);
        });
      });
    }
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }

  instant(key: string): string  {
    return this.translateService.instant(key);
  }

  /**
   * @description Translates [number] enum properties on the objects of the data array [T] into [string]
   * @param data: [T] - An [Array] of [objects] where each object has some [integer] properties
   * @param propertyToEnumMapping: [PropertyToEnumMappingInterface][Array]
   *  Each object in the array has properties that maps the [data] object properties to a corresponding [enum], as well as a string that is used for translating
   * @returns A modified version of the [data] as an [Array], where enum integers has been translated to text strings.
   */
  translateAllEnums<T>(data: T[], propertyToEnumMapping: PropertyToEnumMappingInterface[]): T[] {
    return data ? data.map(entry => {
      return this.translateEnum(entry, propertyToEnumMapping);
    }) : null;
  }
  /**
   * @description Translates [number] enum properties of a single [object] into [string]
   * @param data: [T] - An [object] that has some [integer] properties
   * @param propertyToEnumMapping: [PropertyEnumMappingInterface][Array]
   *  An [Array] of objects, where each object holds information about what properties needs to be translated
   * @returns A modified version of the [data] [object], where each [integer] property that matched [propertyToEnumMapping] has been translated to string
   */
  translateEnum<T>(data: T, propertyToEnumMapping: PropertyToEnumMappingInterface[]): T {
    propertyToEnumMapping.forEach(mapObject => {
      if (data[mapObject.sourceProperty] !== undefined) {
        const stringToBeTranslated = `${mapObject.translate}.${mapObject.enum[data[mapObject.sourceProperty]].toLowerCase()}`;
        const translatedString = this.translateService.instant(stringToBeTranslated);
        data[mapObject.textProperty] = translatedString ? translatedString : TD_VALUES.noTranslation;
      }
    });
    return data;
  }

  translateEnumValue(enumName: string, value?: number): string {
    if (enumName) {
      const enumeration = NAME_TO_ENUM_MAPPING[enumName];
      if (enumeration) {
        if (enumeration[value]) {
          return this.translateService.instant(`${enumName}.${enumeration[value].toLowerCase()}`);
        }
        else {
          return '';
        }
      }
      else {
        return `${TD_VALUES.noTranslation}(${enumName})[${value}]`;
      }
    }
    else {
      return TD_VALUES.noTranslation;
    }
  }

  translateBooleanValue(value: string): string {
    switch (value) {
      case TD_VALUES.sqlTrue: {
        return this.translateService.instant('appCommon.yes');
      }
      case TD_VALUES.sqlFalse: {
        return this.translateService.instant('appCommon.no');
      }
      default: {
        return '';
      }
    }
  }
}
