"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var module_import_guard_1 = require("./module-import-guard");
var auth_configuration_initializer_1 = require("./initializers/auth-configuration/auth-configuration.initializer");
var license_initializer_1 = require("./initializers/license/license.initializer");
var factories_initializer_1 = require("./initializers/factories/factories.initializer");
var client_configuration_initializer_1 = require("./initializers/client-configuration/client-configuration.initializer");
var server_desktop_options_initializer_1 = require("./initializers/server-desktop-options/server-desktop-options.initializer");
var language_service_1 = require("./language/services/language.service");
var language_initializer_1 = require("./initializers/language/language.initializer");
var default_user_options_initializer_1 = require("./initializers/default-user-options/default-user-options.initializer");
function getLanguageFiles(languageInitializer, languageService) {
    return function () { return languageInitializer.initializeLanguage(languageService); };
}
exports.getLanguageFiles = getLanguageFiles;
function getAuthConfiguration(authConfigurationInitializer) {
    return function () { return authConfigurationInitializer.initializeAuthOptions(); };
}
exports.getAuthConfiguration = getAuthConfiguration;
function getLicense(licenseInitializer) {
    return function () { return licenseInitializer.initializeLicense(); };
}
exports.getLicense = getLicense;
function getAllFactories(factInitializer) {
    return function () { return factInitializer.initializeAllFactories(); };
}
exports.getAllFactories = getAllFactories;
function getClientConfiguration(clientConfigInitializer) {
    return function () { return clientConfigInitializer.initializeClientConfiguration(); };
}
exports.getClientConfiguration = getClientConfiguration;
function getServerDesktopOptions(serverDesktopOptionsInitializer) {
    return function () { return serverDesktopOptionsInitializer.initializeServerDesktopOptions(); };
}
exports.getServerDesktopOptions = getServerDesktopOptions;
function getDefaultUserDesktopOptions(userDesktopOptionsInitializer) {
    return function () { return userDesktopOptionsInitializer.initializeDefaultUserDesktopOptions(); };
}
exports.getDefaultUserDesktopOptions = getDefaultUserDesktopOptions;
var CoreModule = /** @class */ (function () {
    function CoreModule(parentModule) {
        module_import_guard_1.throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
    return CoreModule;
}());
exports.CoreModule = CoreModule;
