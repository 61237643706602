"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var store_1 = require("@ngrx/store");
var server_session_selectors_1 = require("@core/data-layer/server-session/store/server-session.selectors");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var LanguageInitializer = /** @class */ (function () {
    function LanguageInitializer(store) {
        this.store = store;
    }
    LanguageInitializer.prototype.initializeLanguage = function (languageService) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.licenseSubscription = _this.store.pipe(store_1.select(server_session_selectors_1.selectLicense)).subscribe(function (license) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    if (license) {
                        if (this.licenseSubscription) {
                            this.licenseSubscription.unsubscribe();
                        }
                        return [2 /*return*/, languageService.initializeLanguage(license.languages, license.defaultLanguage.culture, resolve)];
                    }
                    return [2 /*return*/];
                });
            }); });
        });
    };
    LanguageInitializer.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageInitializer_Factory() { return new LanguageInitializer(i0.ɵɵinject(i1.Store)); }, token: LanguageInitializer, providedIn: "root" });
    return LanguageInitializer;
}());
exports.LanguageInitializer = LanguageInitializer;
