"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var base_component_1 = require("@app/shared/components/base/base.component");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var LandingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LandingComponent, _super);
    function LandingComponent(authService, activatedRoute) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.activatedRoute = activatedRoute;
        _this.userLoggedIn = false;
        _this.subscriptions = [];
        _this.loginModeAlways = false;
        return _this;
    }
    LandingComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.loginModeAlways = this.sessionHelperService.clientConfiguration.loginMode === td_enumerations_1.WebLoginMode.Always;
        this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(function (queryParams) {
            _this.showErrorMessage();
        });
        this.subscriptions.push(this.sessionHelperService.$loggedIn.subscribe(function (change) {
            _this.userLoggedIn = change;
        }));
    };
    LandingComponent.prototype.ngOnDestroy = function () {
        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
        }
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    LandingComponent.prototype.getTitleIdentifier = function () {
        return 'appCommon.titleLanding';
    };
    LandingComponent.prototype.login = function () {
        this.authService.startSignin();
    };
    LandingComponent.prototype.showErrorMessage = function () {
        var _this = this;
        // check either error should be shown
        var error = this.activatedRoute.snapshot.queryParams[td_constants_1.TD_QUERY_PARAMS.error];
        if (error) {
            switch (error) {
                case td_constants_1.TD_AUTH_ERRORS.noCust:
                    this.popupService.showError('appMessages.userNotLinkedToCustomer', true).then(function () {
                        _this.authService.startSignout();
                    });
                    break;
            }
        }
    };
    return LandingComponent;
}(base_component_1.BaseComponent));
exports.LandingComponent = LandingComponent;
