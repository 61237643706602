"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utility_service_1 = require("@core/utility/utility.service");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var order_enumerations_1 = require("@app/orders/models/order.enumerations");
var i0 = require("@angular/core");
var OrderFilterService = /** @class */ (function () {
    function OrderFilterService() {
    }
    OrderFilterService.prototype.getOrderListFilter = function (tab, custKeyId, filterData) {
        var nameOfOrder = utility_service_1.nameOf();
        var filter = [[nameOfOrder('type'), '=', td_enumerations_1.OrderType.Order], 'and', [nameOfOrder('orgRefCustKeyId'), '=', custKeyId]];
        var fldTime = tab === order_enumerations_1.OrderTabType.Delivered ? nameOfOrder('suppTime') : nameOfOrder('orgTime');
        if (filterData.dateInterval) {
            if (filterData.dateInterval.from) {
                filter = filter.concat(['and', [[fldTime, '<>', null], 'and', [fldTime, '>=', filterData.dateInterval.from.toISOString()]]]);
            }
            if (filterData.dateInterval.to) {
                filter = filter.concat(['and', [[fldTime, '=', null], 'or', [fldTime, '<', filterData.dateInterval.to.toISOString()]]]);
            }
        }
        if (filterData.orderNumber) {
            if (filterData.orderNumber.from) {
                filter = filter.concat(['and', [nameOfOrder('orgRefNo'), '>=', filterData.orderNumber.from]]);
            }
            if (filterData.orderNumber.to) {
                filter = filter.concat(['and', [nameOfOrder('orgRefNo'), '<=', filterData.orderNumber.to]]);
            }
        }
        switch (tab) {
            case order_enumerations_1.OrderTabType.All: {
                filter = filter.concat(['and', [nameOfOrder('orgStatus'), '<>', td_enumerations_1.OrderOrgStatus.Cancelled]]);
                filter = filter.concat(['and', [nameOfOrder('suppStatus'), '<>', td_enumerations_1.OrderSuppStatus.Cancelled]]);
                break;
            }
            case order_enumerations_1.OrderTabType.Open: {
                filter = filter.concat(['and', [nameOfOrder('orgStatus'), '=', td_enumerations_1.OrderOrgStatus.Open]]);
                break;
            }
            case order_enumerations_1.OrderTabType.Delivered: {
                filter = filter.concat(['and', [nameOfOrder('suppStatus'), '=', td_enumerations_1.OrderSuppStatus.Delivered]]);
                break;
            }
            case order_enumerations_1.OrderTabType.Sent: {
                filter = filter.concat(['and', [nameOfOrder('orgStatus'), '=', td_enumerations_1.OrderOrgStatus.Sent]]);
                filter = filter.concat(['and', [nameOfOrder('suppStatus'), '<>', td_enumerations_1.OrderSuppStatus.Delivered]]);
                filter = filter.concat(['and', [nameOfOrder('suppStatus'), '<>', td_enumerations_1.OrderSuppStatus.Cancelled]]);
            }
        }
        return filter;
    };
    OrderFilterService.prototype.getOperationOrderListFilter = function (keyId) {
        var nameOfOrder = utility_service_1.nameOf();
        return [nameOfOrder('refOpDKeyId'), '=', keyId];
    };
    OrderFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderFilterService_Factory() { return new OrderFilterService(); }, token: OrderFilterService, providedIn: "root" });
    return OrderFilterService;
}());
exports.OrderFilterService = OrderFilterService;
