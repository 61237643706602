import { Component, ViewChild, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { DxButtonComponent } from 'devextreme-angular';

import { TD_MODEL_NAMES, TD_DESKTOP_IDENTIFIERS, NO_ERROR_CODE } from '@core/data-layer/shared/models/td.constants';
import { SearchManagerService } from '@core/search/services/search-manager.service';
import { TdDropDownGridComponent } from '@shared/components/td-drop-down-grid/td-drop-down-grid.component';
import { GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { SearchArgsModel } from '@core/search/models/search-args.model';
import { OperationDataServiceInterface } from '@core/data-layer/operation-data/services/operation-data.service.interface';
import { TdPopupService } from '@shared/components/td-popup/services/td-popup.service';
import { nameOf } from '@core/utility/utility.service';
import { UnitModel } from '@core/data-layer/unit/models/unit.model';
import { ViewHelperService } from '@core/data-layer/shared/services/view-helper.service';
import { ItemInstrumentType } from '@core/data-layer/shared/models/td.enumerations';

@Component({
  selector: 'td-add-unit',
  templateUrl: './add-unit.component.html',
  styleUrls: ['./add-unit.component.less'],
})
export class AddUnitComponent implements OnInit {

  @Input() operationKeyId: number;
  @Input() disabled: boolean;
  @Output() unitAdded: EventEmitter<ItemInstrumentType> = new EventEmitter<ItemInstrumentType>();

  mainTable = TD_MODEL_NAMES.unitModel;
  gridIdentifier = TD_DESKTOP_IDENTIFIERS.operationUnitSearchListGrid;
  itemInstrumentTypeSelected: ItemInstrumentType;
  @ViewChild('unitsSearchDropDownGrid', { static: false }) private unitsSearchDropDownGrid: TdDropDownGridComponent;
  @ViewChild('addUnitButton', { static: false }) private addUnitButton: DxButtonComponent;

  constructor(
    @Inject('OPERATION_DATA_SERVICE') private operationDataService: OperationDataServiceInterface,
    private searchManagerService: SearchManagerService,
    private popupService: TdPopupService,
    private viewHelperService: ViewHelperService
  ) {
    this.unitDisplayExpr = this.unitDisplayExpr.bind(this);
    this.initUnitSearchRequestArgs = this.initUnitSearchRequestArgs.bind(this);
  }

  public ngOnInit() {
    //
  }

  initUnitSearchRequestArgs(gridStructure: GridStructureViewInterface): SearchArgsModel {
    return this.searchManagerService.initWebOperationUnitsSearchRequestArgs(gridStructure, this.operationKeyId, true);
  }

  onSearchResultCleared() {
    //
  }

  isUnitSelected(): boolean {
    if (this.unitsSearchDropDownGrid) {
      return this.unitsSearchDropDownGrid.searchRowSelected();
    }
    else {
      return false;
    }
  }

  unitDisplayExpr(unitData): string {
    return unitData && unitData.keyId;
  }

  addUnit() {
    const nameOfUnit = nameOf<UnitModel>();
    this.operationDataService.addUnit(this.operationKeyId, this.unitsSearchDropDownGrid.selectedData[nameOfUnit('keyId')]).subscribe(response => {
      if (response.error === NO_ERROR_CODE) {
        // unit added successfully
        this.unitAdded.emit(this.itemInstrumentTypeSelected);
      }
      else {
        // there occurred an error while trying to add unit to operation
        this.popupService.showInfo(response.message);
      }
    });
    this.unitsSearchDropDownGrid.clearSearchResult();
  }

  catchUnitSelected(unitSelected: UnitModel): void {
    this.itemInstrumentTypeSelected = this.viewHelperService.getItemType(unitSelected.prod.item);
    this.addUnitButton.instance.focus();
  }
}
