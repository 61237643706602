"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_constants_1 = require("@app/core/data-layer/shared/models/td.constants");
var param_models_1 = require("./param-models");
var ArticleSearchFactory = /** @class */ (function () {
    function ArticleSearchFactory() {
    }
    ArticleSearchFactory.prototype.createSearchModel = function () {
        return {
            url: '/search',
            properties: [
                {
                    urlParamName: td_constants_1.TD_QUERY_PARAMS.text,
                    type: param_models_1.SearchParamTypes.String
                }
            ]
        };
    };
    /**
     * NB: This is never used - As you cannot access search directly, only by performing a search
     * But to obey the interface, a method is built for it
     * @returns search model with empty string
     */
    ArticleSearchFactory.prototype.createDefaultSearchModel = function () {
        return {
            url: '/search',
            properties: [
                {
                    urlParamName: td_constants_1.TD_QUERY_PARAMS.text,
                    type: param_models_1.SearchParamTypes.String
                }
            ]
        };
    };
    ArticleSearchFactory.prototype.getRecentSearchAsParams = function (recentSearch) {
        throw new Error("Method not implemented.");
    };
    /**
     * NB: This is never used - As you cannot access search directly, only by performing a search
     * But to obey the interface, a method is built for it
     * @returns default search params model
     */
    ArticleSearchFactory.prototype.getEmptyParamsSearchModel = function () {
        return this.createDefaultSearchModel();
    };
    return ArticleSearchFactory;
}());
exports.ArticleSearchFactory = ArticleSearchFactory;
