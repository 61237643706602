"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constrol_state_model_1 = require("@shared/models/constrol-state.model");
var TdToolbarButtonComponent = /** @class */ (function () {
    function TdToolbarButtonComponent() {
        this.buttonState = new constrol_state_model_1.ControlState();
        this.btnClick = new core_1.EventEmitter();
    }
    return TdToolbarButtonComponent;
}());
exports.TdToolbarButtonComponent = TdToolbarButtonComponent;
