import { Component, OnInit, Inject, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DxTabPanelComponent } from 'devextreme-angular';

import { SearchManagerService } from '@core/search/services/search-manager.service';
import { TD_MODEL_NAMES, TD_DESKTOP_IDENTIFIERS, TD_ACTION_FIELDS, TD_DYNAMIC_FIELDS, TD_MAX_INT32_VALUE, TD_QUERY_PARAMS } from '@core/data-layer/shared/models/td.constants';
import { TabsInterface } from '@shared/models/tabs.interface';
import { BaseComponent } from '@shared/components/base/base.component';
import { GridStructureViewInterface, GridColumnConfigurationViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { SpecialSearchType, ItemInstrumentType, TableTypes, SearchAutoPictureMode } from '@core/data-layer/shared/models/td.enumerations';
import { TdDataGridComponent } from '@shared/components/td-data-grid/td-data-grid.component';
import { SearchArgsModel } from '@core/search/models/search-args.model';
import { SerialNumberInfoInterface } from '@shared/models/serial-number-info.interface';
import { nameOf } from '@core/utility/utility.service';
import { SerialModel } from '@core/data-layer/serial/models/serial.model';
import { LocationModel } from '@core/data-layer/location/models/location.model';
import { OrderManagerService } from '@shared/services/order-manager.service';
import { ArticleBaseInfoInterface } from '@shared/models/article-base-info.interface';
import { OrderServiceInterface } from '@core/data-layer/order/services/order.service.interface';
import { UnitHelperService } from '@shared/services/unit-helper.service';
import { PropertyTypes } from '@core/table-info/models/grid-structure.interface';
import { TabHelperService } from '@shared/services/tab-helper.service';
import { UnitViewModel } from '@core/data-layer/unit/models/unit-view.model';
import { UnitModel } from '@core/data-layer/unit/models/unit.model';
import { TabTotalCountInterface } from './models/tab-total-count-data.interface';
import { GridLoadResultInterface } from '@shared/models/grid-data-request.interface';
import { UrlSearchService } from '@shared/services/url-search.service';
import { ArticleSearchFactory } from '@shared/models/url-search-models/article-search.factory';

@Component({
  selector: 'td-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('productsGrid', { static: false }) productsGrid: TdDataGridComponent;
  @ViewChild('itemsGrid', { static: false }) itemsGrid: TdDataGridComponent;
  @ViewChild('endoscopesGrid', { static: false }) endoscopesGrid: TdDataGridComponent;
  @ViewChild('unitsGrid', { static: false }) unitsGrid: TdDataGridComponent;
  @ViewChild('tabPanel', { static: false }) tabPanel: DxTabPanelComponent;
  @ViewChild('serialInfoTemplate', { static: false }) serialInfoTemplate: TemplateRef<any>;

  productGridStructure: GridStructureViewInterface;
  itemGridStructure: GridStructureViewInterface;
  endoscopeGridStructure: GridStructureViewInterface;
  unitGridStructure: GridStructureViewInterface;

  productsSearchArgs: SearchArgsModel;
  itemsSearchArgs: SearchArgsModel;
  endoscopesSearchArgs: SearchArgsModel;
  unitsSearchArgs: SearchArgsModel;
  productSerialsSearchArgs: SearchArgsModel;
  endoscopeSerialsSearchArgs: SearchArgsModel;

  tableTypes = TableTypes;
  itemInstrumentType = ItemInstrumentType;
  modelNames = TD_MODEL_NAMES;
  gridIdentifiers = TD_DESKTOP_IDENTIFIERS;
  dynamicFields = TD_DYNAMIC_FIELDS;
  maxQuantityValue = TD_MAX_INT32_VALUE;
  searchTabs: TabsInterface[];

  productTabTemplate = 'products';
  itemTabTemplate = 'items';
  endoscopeTabTemplate = 'endoscopes';
  unitTabTemplate = 'units';

  searchText: string;
  navigationSubscription: Subscription;

  articleSerialsData: any = {};
  serialInfo: SerialNumberInfoInterface;

  activeTableType: TableTypes = this.tableTypes.Product;
  activeTabIndex = 0;

  activeTabIndexPreSearch: number;
  tabTotalCountData: TabTotalCountInterface[];

  tabPanelVisible = false;

  // Search Model
  searchModel = new ArticleSearchFactory();
  articleSearchModel = this.searchModel.createSearchModel();
  firstSearch = true;

  constructor(
    @Inject('ORDER_SERVICE') private orderService: OrderServiceInterface,
    private activatedRoute: ActivatedRoute,
    private searchManagerService: SearchManagerService,
    private orderManagerService: OrderManagerService,
    private unitHelperService: UnitHelperService,
    private tabHelperService: TabHelperService,
    private urlSearchService: UrlSearchService
  ) {
    super();
  }

  public async ngOnInit() {
    super.ngOnInit();
    this.tabTotalCountData = this.initTabTotalCountData();
    // init search tabs: products, items, endoscopes, units
    this.initSearchTabs();
    // init search grids
    await this.initSearchGrids();
    this.getParamsAndSearch();
    // Doesn't trigger on the first search
    this.navigationSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.getParamsAndSearch();
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getParamsAndSearch() {  
    const textParam = this.activatedRoute.snapshot.queryParams[TD_QUERY_PARAMS.text];    
    if (this.searchText || textParam && textParam.length > 0) {
      const searchParamsModel = this.urlSearchService.onQueryParamChanges(this.activatedRoute.snapshot.queryParams, this.articleSearchModel, this.searchModel, this.firstSearch);
      if (searchParamsModel) {
        this.searchText = searchParamsModel.properties.find(prop => prop.urlParamName === TD_QUERY_PARAMS.text).value as string;
        
          this.tabPanelVisible = true;
          // remember current (selected) tab index
          this.activeTabIndexPreSearch = this.searchManagerService.getRecentArticleSearchTab();
          this.titleService.setTitle(this.getTitleIdentifier());
          this.setSearchFilters();
          this.search();
          this.firstSearch = false;
      }
    }
  }

  getTitleIdentifier(): string {
    const title = this.translateInstant('appCommon.search');
    return this.searchText ? `${title} ${this.searchText}` : title;
  }

  async initSearchGrids(): Promise<boolean> {
    return new Promise(async resolve => {
      // get products grid structure
      await this.initProductsGridSearch();
      // get items grid structure
      await this.initItemsGridSearch();
      if (this.sessionHelperService.canViewEndoscope()) {
        // get endoscopes grid structure
        await this.initEndoscopesGridSearch();
      }
      // get units grid structure
      await this.initUnitsGridSearch();
      // get product serials grid structure
      await this.initProductSerialsGridSearch();
      if (this.sessionHelperService.canViewEndoscope()) {
        // get endoscope serials grid structure
        await this.initEndoscopeSerialsGridSearch();
      }
      resolve(true);
    });
  }

  adjustCustomColumns(columnsConfigurations: GridColumnConfigurationViewInterface[]) {
    // add custom "media" column
    if (this.sessionHelperService.canViewMedia()) {
      this.gridColumnHelperService.addCustomMediaColumn(columnsConfigurations);
    }
    else {
      // remove custom "media" column
      this.gridColumnHelperService.removeColumn(columnsConfigurations, TD_ACTION_FIELDS.media);
    }
    if (this.sessionHelperService.canViewOrder()) {
      // add custom "quantity" column
      const quantityColumn: GridColumnConfigurationViewInterface = {
        dataField: TD_ACTION_FIELDS.quantity,
        dataType: PropertyTypes.typeUndefined,
        visible: true,
        displayName: this.translateInstant('calcColumns.quantity'),
        style: {
          alignment: 'center'
        }
      };
      this.gridColumnHelperService.addCustomColumn(columnsConfigurations, quantityColumn);
      // add custom "add" column
      const addColumn: GridColumnConfigurationViewInterface = {
        dataField: TD_ACTION_FIELDS.add,
        dataType: PropertyTypes.typeUndefined,
        visible: true,
        displayName: this.translateInstant('calcColumns.add'),
        style: {
          alignment: 'center'
        }
      };
      this.gridColumnHelperService.addCustomColumn(columnsConfigurations, addColumn);
    }
  }

  adjustUnitColumnHeaders() {
    const nameOfLocation = nameOf<LocationModel>();
    this.gridColumnHelperService.setColumnDisplayName(this.unitGridStructure.columnsConfigurations, nameOfLocation('name'), this.translateInstant('columnHeaders.location'));
  }

  private async initProductsGridSearch(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(TD_DESKTOP_IDENTIFIERS.productListGrid, TD_MODEL_NAMES.productModel).subscribe(gridStructure => {
        if (gridStructure) {
          // add custom columns
          this.adjustCustomColumns(gridStructure.columnsConfigurations);
          // init products search args
          this.productsSearchArgs = this.searchManagerService.initWebArticlesSearchRequestArgs(
            SpecialSearchType.WebProdWithSerials, TD_MODEL_NAMES.productModel, gridStructure
          );
        }
        this.productGridStructure = gridStructure;
        if (this.productsGrid) {
          this.productsGrid.grid.instance.repaint();
        }
        resolve(true);
      });
    });
  }

  private async initItemsGridSearch(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(TD_DESKTOP_IDENTIFIERS.itemListGrid, TD_MODEL_NAMES.itemModel).subscribe(gridStructure => {
        if (gridStructure) {
          // add custom columns
          this.adjustCustomColumns(gridStructure.columnsConfigurations);
          // init items search args
          this.itemsSearchArgs = this.searchManagerService.initWebArticlesSearchRequestArgs(
            SpecialSearchType.WebBaseProdItem, TD_MODEL_NAMES.itemModel, gridStructure);
        }
        this.itemGridStructure = gridStructure;
        if (this.itemsGrid) {
          this.itemsGrid.grid.instance.repaint();
        }
        resolve(true);
      });
    });
  }

  private async initEndoscopesGridSearch(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(TD_DESKTOP_IDENTIFIERS.endoscopeListGrid, TD_MODEL_NAMES.productModel).subscribe(gridStructure => {
        if (gridStructure) {
          // add custom columns
          this.adjustCustomColumns(gridStructure.columnsConfigurations);
          // init endoscopes search args
          this.endoscopesSearchArgs = this.searchManagerService.initWebArticlesSearchRequestArgs(
            SpecialSearchType.WebProdWithSerials, TD_MODEL_NAMES.productModel, gridStructure, ItemInstrumentType.Endoscope);

        }
        this.endoscopeGridStructure = gridStructure;
        if (this.endoscopesGrid) {
          this.endoscopesGrid.grid.instance.repaint();
        }
        resolve(true);
      });
    });
  }

  private async initUnitsGridSearch(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(TD_DESKTOP_IDENTIFIERS.unitListGrid, TD_MODEL_NAMES.unitModel).subscribe(gridStructure => {
        if (gridStructure) {
          // add custom "fastTrack" column
          if (this.sessionHelperService.license.fastTrackBasic) {
            this.gridColumnHelperService.addCustomFastTrackColumn(gridStructure.columnsConfigurations);
          }
          else {
            this.gridColumnHelperService.removeColumn(gridStructure.columnsConfigurations, TD_ACTION_FIELDS.fastTrack);
          }

          // add custom "media" column
          if (this.sessionHelperService.canViewMedia()) {
            this.gridColumnHelperService.addCustomMediaColumn(gridStructure.columnsConfigurations);
          }
          else {
            this.gridColumnHelperService.removeColumn(gridStructure.columnsConfigurations, TD_ACTION_FIELDS.media);
          }
          // init units search args
          this.unitsSearchArgs = this.searchManagerService.initWebArticlesSearchRequestArgs(
            SpecialSearchType.WebUnits, TD_MODEL_NAMES.unitModel, gridStructure);
          this.unitsSearchArgs.autoPictureMode = SearchAutoPictureMode.On;
        }
        this.unitGridStructure = gridStructure;
        const nameOfUnitViewModel = nameOf<UnitViewModel>();
        this.gridColumnHelperService.setColumnProperty(
          this.unitGridStructure.columnsConfigurations,
            nameOfUnitViewModel('status'),
            { style: { alignment: 'left'} }
          );
        this.adjustUnitColumnHeaders();
        if (this.unitsGrid) {
          this.unitsGrid.grid.instance.repaint();
        }
        resolve(true);
      });
    });
  }

  private async initProductSerialsGridSearch(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(TD_DESKTOP_IDENTIFIERS.productSerialsSearchGrid, TD_MODEL_NAMES.serialModel).subscribe(gridStructure => {
        if (gridStructure) {
          // init product serials search args
          this.productSerialsSearchArgs = this.searchManagerService.initWebArticlesSearchRequestArgs(
            SpecialSearchType.WebBaseProdItem, TD_MODEL_NAMES.serialModel, gridStructure);
        }
        resolve(true);
      });
    });
  }

  private async initEndoscopeSerialsGridSearch(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(TD_DESKTOP_IDENTIFIERS.endoscopeSerialsSearchGrid, TD_MODEL_NAMES.serialModel).subscribe(gridStructure => {
        if (gridStructure) {
          // init endoscope serials search args
          this.endoscopeSerialsSearchArgs = this.searchManagerService.initWebArticlesSearchRequestArgs(
            SpecialSearchType.WebBaseProdItem, TD_MODEL_NAMES.serialModel, gridStructure, ItemInstrumentType.Endoscope);
        }
        resolve(true);
      });
    });
  }

  private initSearchTabs() {
    this.searchTabs = [
      {
        title: this.translateInstant('search.products'),
        data: null,
        template: this.productTabTemplate,
        iconPath: '\\assets\\img\\product.png'
      },
      {
        title: this.translateInstant('search.items'),
        data: null,
        template: this.itemTabTemplate,
        iconPath: '\\assets\\img\\item.png'
      },
      ...(this.sessionHelperService.canViewEndoscope() ? [{
        title: this.translateInstant('search.endoscopes'),
        data: null,
        template: this.endoscopeTabTemplate,
        iconPath: '\\assets\\img\\endoscope.png'
      }] : []),
      {
        title: this.translateInstant('search.units'),
        data: null,
        template: this.unitTabTemplate,
        iconPath: '\\assets\\img\\unit.png'
      }
    ];
  }

  private updateSearchTabData(templateName: string, searchArgs: SearchArgsModel) {
    const searchTab = this.searchTabs.find(tab => tab.template === templateName);
    if (searchTab) {
      this.tabPanel.selectedIndex = this.searchTabs.indexOf(searchTab);
      searchTab.data = this.searchText ?
        this.searchManagerService.requestSearchData(
          searchArgs,
          this.searchText,
          this.afterDataLoaded(templateName),
          this.getKeyIdField(templateName)) :
        null;
        // searchTab.disabled = false;
    }
  }

  getKeyIdField(templateName: string): string {
    return [this.productTabTemplate, this.endoscopeTabTemplate].includes(templateName) ? this.dynamicFields.keyId : null;
  }

  afterDataLoaded(templateName: string): (data: GridLoadResultInterface) => void {
    return (data) => {
      this.tabHelperService.setTabState(this.searchTabs, templateName, !(data.totalCount > 0), this.tabPanel);
      if (templateName === this.productTabTemplate) {
        this.productsGrid.grid.instance.collapseAll(-1);
        }
      if (templateName === this.endoscopeTabTemplate) {
        this.endoscopesGrid.grid.instance.collapseAll(-1);
      }
      if (!this.tabPanel.items[this.activeTabIndexPreSearch].disabled) {
        this.tabPanel.selectedIndex = this.activeTabIndexPreSearch;
      }
      this.tabTotalCountData.find(entry => entry.name === templateName).count = data.totalCount;
      this.setActiveTab();
    };
  }

  searchProducts() {
    this.updateSearchTabData(this.productTabTemplate, this.productsSearchArgs);
  }

  searchItems() {
    this.updateSearchTabData(this.itemTabTemplate, this.itemsSearchArgs);
  }

  searchEndoscopes() {
    if (this.sessionHelperService.canViewEndoscope()) {
      this.updateSearchTabData(this.endoscopeTabTemplate, this.endoscopesSearchArgs);
    }
  }

  searchUnits() {
    this.updateSearchTabData(this.unitTabTemplate, this.unitsSearchArgs);
  }

  setSearchParameters(): void {
    if (this.searchText) {
      this.articleSearchModel.properties.forEach(prop => {
        prop.value = this.searchText;
      });
      this.urlSearchService.setSearchParamsAndNavigate(this.articleSearchModel, this.firstSearch);
    }
    else {
      this.popupService.showInfo('appMessages.searchTextEmptyError', true);
    }     
  }

  search() {
    this.searchProducts();
    this.searchItems();
    this.searchEndoscopes();
    this.searchUnits();
  }

  searchSerials(productKeyId: number) {
    // search product serials
    if (this.searchTabs[this.tabPanel.selectedIndex].template === this.productTabTemplate) {
      this.productSerialsSearchArgs.extraWebArticlesArgs.productKeyId = productKeyId;
      this.productSerialsSearchArgs.paginationArgs.pageRowCount = TD_MAX_INT32_VALUE - 1;
      this.searchManagerService.requestSearch(this.productSerialsSearchArgs, this.searchText).subscribe(response => {
        this.articleSerialsData[productKeyId] = response.value.dataSet.mappedRows;
        if (this.productsGrid) {
          this.productsGrid.grid.instance.repaint();
        }
      });
    }
    // search endoscope serials
    else if (this.searchTabs[this.tabPanel.selectedIndex].template === this.endoscopeTabTemplate) {
      this.endoscopeSerialsSearchArgs.extraWebArticlesArgs.productKeyId = productKeyId;
      this.endoscopeSerialsSearchArgs.paginationArgs.pageRowCount = TD_MAX_INT32_VALUE - 1;
      this.searchManagerService.requestSearch(this.endoscopeSerialsSearchArgs, this.searchText).subscribe(response => {
        this.articleSerialsData[productKeyId] = response.value.dataSet.mappedRows;
        if (this.endoscopesGrid) {
          this.endoscopesGrid.grid.instance.repaint();
        }
      });
    }
  }

  setSearchFilters() {
    this.searchManagerService.setUserSearchFilters(this.productsSearchArgs);
    this.searchManagerService.setUserSearchFilters(this.itemsSearchArgs);
    if (this.sessionHelperService.canViewEndoscope()) {
      this.searchManagerService.setUserSearchFilters(this.endoscopesSearchArgs);
    }
    this.searchManagerService.setUserSearchFilters(this.unitsSearchArgs);
    this.searchManagerService.setUserSearchFilters(this.productSerialsSearchArgs);
    if (this.sessionHelperService.canViewEndoscope()) {
      this.searchManagerService.setUserSearchFilters(this.endoscopeSerialsSearchArgs);
    }
  }

  showFastTrackInfo(unitKeyId: number) {
    this.unitHelperService.showFastTrackInfo(unitKeyId);
  }

  showSerialNumberInfo(serialNumber: any) {
    const nameOfSerial = nameOf<SerialModel>();
    const nameOfUnit = nameOf<UnitModel>();
    const nameOfLocation = nameOf<LocationModel>();
    this.serialInfo = {
      serialNo: serialNumber[nameOfSerial('serialNo')],
      unit: serialNumber[nameOfSerial('unitUnitUnit')][nameOfUnit('keyId')],
      location: serialNumber[nameOfSerial('loca')][nameOfLocation('name')],
      placement: serialNumber[nameOfSerial('stockplacement')],
      remark: serialNumber[nameOfSerial('remark')]
    };
    this.popupService.showTemplate('appCommon.serialNumber', this.serialInfoTemplate);
  }

  saveAddedOrderLine() {
    const lastOpenOrderKeyId = this.sessionHelperService.userLastOpenOrder.details.keyId;
    this.orderService.saveOrderLines(lastOpenOrderKeyId, this.sessionHelperService.userLastOpenOrder.lines).subscribe(response => {
      this.sessionHelperService.reloadLastOpenOrder(lastOpenOrderKeyId);
    });
  }

  addToCart(refType: TableTypes, refKeyId: number, item: any) {
    if (this.sessionHelperService.userLastOpenOrder) {
      const quantity = item.data.newQuantity ? item.data.newQuantity : item.data.quantity;
      const articleInfo: ArticleBaseInfoInterface = { refType, refKeyId, quantity };
      this.orderManagerService.addLineIntoOrder(
        articleInfo,
        this.sessionHelperService.userLastOpenOrder,
        this.sessionHelperService.userLastOpenOrder.details.suppRefFacKeyId,
        this.saveAddedOrderLine.bind(this));
    }
  }
  tester = 1;
  checkQuantity(e: any, item: any) {
    if (!this.sessionHelperService.quantityIsValid(e.value)) {
      e.component.option('value', e.previousValue);
    } else {
      item.data.newQuantity = e.value;
    }
  }

  highlightSearchText(value: string): string {
    return this.utilityService.highlightContent(value, this.searchText);
  }

  setActiveTab(): void {
    const latestTab = this.tabTotalCountData[this.activeTabIndexPreSearch];
    let targetTab: TabTotalCountInterface;
    if (latestTab.count > 0) {
      targetTab = latestTab;
    }
    else {
      const tabWithData = this.tabTotalCountData.find(entry => entry.count > 0);
      targetTab = tabWithData;
    }
    this.tabPanel.selectedIndex = targetTab ? this.tabTotalCountData.indexOf(targetTab) : this.activeTabIndexPreSearch;
    this.activeTableType = targetTab ? targetTab.tableType : this.tableTypes.Product;
  }

  clickTabTitle(): void {
    this.searchManagerService.setRecentArticleSearchTab(this.tabPanel.selectedIndex);
     this.activeTabIndexPreSearch = this.tabPanel.selectedIndex;
    this.setActiveTab();
  }

  initTabTotalCountData(): TabTotalCountInterface[] {
    return [
      {
        name: this.productTabTemplate,
        tableType: TableTypes.Product,
        count: 0
      },
      {
        name: this.itemTabTemplate,
        tableType: TableTypes.Item,
        count: 0
      },
      ...(this.sessionHelperService.canViewEndoscope() ? [{
        name: this.endoscopeTabTemplate,
        tableType: TableTypes.Product,
        count: 0
      }] : []),
      {
        name: this.unitTabTemplate,
        tableType: TableTypes.Unit,
        count: 0
      },
    ]
  }
}
