import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { LoggerService } from '@core/logger/logger.service';
import { SignalRService } from '@core/signal-r/signal-r.service';
import { AppState } from '@core/data-layer/app.state';
import { LicenseBindingServiceInterface } from './license-binding.service.interface';
import { InitializeClientId, LoadLicense, SetLicense } from '@core/data-layer/server-session/store/server-session.actions';
import { selectClientId } from '@core/data-layer/server-session/store/server-session.selectors';
import { TD_ERROR_TYPES } from '@core/data-layer/shared/models/td.constants';

// signal-r server method(s)
const HUB_SRV_METHOD_CREATE_LICENSE_BINDING = 'CreateLicenseBinding';

@Injectable()
export class SignalRLicenseBindingService extends SignalRService implements LicenseBindingServiceInterface {

  private clientId: string;

  constructor(
    loggerService: LoggerService,
    private store: Store<AppState>,
    private router: Router
  ) {
    super(loggerService);
  }

  // override the protected method
  public getHubServerUrl(): string {
    return API_HUB_PATHS.serverUrl + API_HUB_PATHS.licenseBindingHub;
  }

  // override the protected method
  public beforeInitialize(): void {
    super.beforeInitialize();
    this.store.pipe(select(selectClientId)).subscribe(clientId => {
      if (clientId) {
        this.clientId = clientId;
      }
      else {
        this.store.dispatch(new InitializeClientId());
      }
    });
  }

  // override the protected method
  public startConnectionAndLoadLicense(): void {
    super.initializeConnection();
  }

  // override the protected method
  public stopConnection(): void {
    super.stopConnection();
    this.store.dispatch(new SetLicense(null));
  }

  // override the protected method
  protected onConnectionEstablished() {
    super.onConnectionEstablished();
    this.createLicenseBinding();
  }

  private createLicenseBinding() {
    if (this.connectedToHub()) {
      this.hubConnection.invoke(HUB_SRV_METHOD_CREATE_LICENSE_BINDING, this.clientId)
        .then((response: boolean) => {
          if (response) {
            this.store.dispatch(new LoadLicense());
            this.loggerService.log('Create license binding [' + this.clientId + ']');
          }
          else {
            this.stopConnection();
            this.router.navigate(['/error'], { skipLocationChange: true, queryParams: { type: TD_ERROR_TYPES.noAvailableLicense } });
            this.loggerService.log('No available license [' + this.clientId + ']');
          }
        })
        .catch(err => { this.loggerService.error(HUB_SRV_METHOD_CREATE_LICENSE_BINDING + ' error: ' + err); });
    }
  }

}
