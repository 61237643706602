<ng-container *ngIf="showMediaViewer">
  <dx-popup #popUp id="popup" class="media-popup d-flex flex-column justify-content-between" [showTitle]="true" minHeight="70vh" minWidth="38vw" position="center"
    [dragEnabled]="true" [resizeEnabled]="false" [closeOnOutsideClick]="true" 
    [(visible)]="mediaVisible" (onHiding)="closeMediaViewer()" (onResizeEnd)="endResizeMediaViewer()"
    (onResizeStart)="startResizeMediaViewer()" (onDisposing)="closeMediaViewer()" titleTemplate="title">
    <div *dxTemplate="let data of 'title'" #popUpTitle>
      <div class="media-title">{{ mediaTitle }}</div>
    </div>
    <div class="media-viewer-wrapper d-flex flex-column justify-content-between" id="popup-container">

      <div *ngIf="showMediaViewOptions" id="mediaViewOptions">
        <dx-check-box [id]="autoPlayVideos"
                      [text]="'settings.autoPlayVideos' | translate"
                      [(value)]="userOptions.mediaAutoPlayVideos"
                      (onValueChanged)="autoPlayVideosChanged()">
        </dx-check-box>
      </div>

      <div class="media-and-navigation d-flex justify-content-between align-items-center">
        <ng-container *ngIf="moreThanOneMedia">
          <dx-button *ngIf="moreThanOneMedia" class="action-icon-button navigation-button" icon="./assets/img/Previous_white.svg"
            [disabled]="swiperMediaIndex === 0" (click)="previousThumb()">
          </dx-button>
          <div #mediaSwiper 
            *ngIf="moreThanOneMedia" class="swiper-container w-100" [style.width.px]="swiperWidth"
            [swiper]="swiperConfig" [(index)]="swiperMediaIndex" (indexChange)="mediaIndexChange($event)">
            <ng-container *ngTemplateOutlet="mediaTemplate;"></ng-container>
          </div>
          <dx-button *ngIf="moreThanOneMedia" class="action-icon-button navigation-button" icon="./assets/img/Next_white.svg"
            [disabled]="swiperMediaIndex === lastIndex" (click)="nextThumb()">
          </dx-button>
        </ng-container>
        <ng-container *ngIf="!moreThanOneMedia">
          <ng-container *ngTemplateOutlet="mediaTemplate;"></ng-container>
        </ng-container>        
      </div>      

      <div *ngIf="moreThanOneMedia && showThumbnails" class="thumbnail-container w-100 mt-3 d-flex justify-content-center flex-nowrap">
        <div class="thumbnail-navigation mr-4">
          <!-- Previous thumbnail image -->
          <dx-button class="action-icon-button navigation-button" icon="./assets/img/Previous_white.svg"
            [disabled]="swiperMediaIndex === 0" (click)="previousThumb()">
          </dx-button>
        </div>

        <!-- Thumbnails -->
        <!-- Thumbnails wrapper -->
        <div #thumbnailWrapper class="thumbnail-wrapper d-flex justify-content-start align-items-center">
          <ng-container *ngFor="let media of mediaItems; let index = index">
            <div class="mr-3 thumbnail-img-container"
              [ngClass]="swiperMediaIndex === index ? 'thumbnail-active' : 'thumbnail-border'" 
              (click)="thumbnailClick(index)">
              <img *ngIf="media.thNImage" class="simple-border thumbnail-img" id="thumbnail-img-{{index}}"  [src]="media.thNImage"/>
              <ng-container *ngIf="!media.thNImage">
                <p class="d-flex align-items-center justify-content-center no-preview"> {{ 'appCommon.noMediaPreview' | translate }} </p>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <!-- Next thumbnail image -->
        <div class="thumbnail-navigation ml-3">
          <dx-button class="action-icon-button navigation-button" icon="./assets/img/Next_white.svg"
            [disabled]="swiperMediaIndex === lastIndex" (click)="nextThumb()">
          </dx-button>
        </div>
      </div>

      <div class="row thumbnail-comment d-flex justify-content-center mt-3 ml-0 w-100">
        <dx-text-area width="100%" [value]="mediaItems[swiperMediaIndex].text" [readOnly]="true">
        </dx-text-area>
      </div>
    </div>
  </dx-popup>
</ng-container>

<ng-template #notSupported let-media="media">
  <div class="d-flex flex-column justify-content-center align-items-center w-100">
    <div>{{ 'media.notSupported' | translate }}</div>    
    <div><a [href]="media.video" target="_blank">{{ 'media.download' | translate }}</a></div>
  </div>
</ng-template>

<ng-template #mediaTemplate>
  <div class="swiper-wrapper align-items-center">
    <div #slide *ngFor="let media of mediaItems; let index = index"
      class="swiper-slide d-flex justify-content-center w-100" id="slide-{{index}}">
      <ng-container *ngIf="media.mediaType === multiMediaType.Picture && media.image">
        <img #slideImg class="media-slide" id="img-{{index}}" [src]="media.image">
      </ng-container>
      <ng-container *ngIf="media.mediaType === multiMediaType.Video && media.video">
        <ng-container *ngIf="isVideoTypeSupported(media.videoFileType)">
          <video #mediaVideo class="media-slide" controls disablepictureinpicture controlslist="nodownload" [attr.autoplay]="autoStartVideo ? '' : null">
            <source [src]="media.video">
            <ng-container *ngTemplateOutlet="notSupported; context: {media: media}"></ng-container>
          </video>
        </ng-container>
        <ng-container *ngIf="!isVideoTypeSupported(media.videoFileType) || !media.video">          
          <ng-container *ngTemplateOutlet="notSupported; context: {media: media}"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
