"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SignoutCallbackComponent = /** @class */ (function () {
    function SignoutCallbackComponent(authService) {
        this.authService = authService;
        this.authService.completeSignout();
    }
    return SignoutCallbackComponent;
}());
exports.SignoutCallbackComponent = SignoutCallbackComponent;
