"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var td_popup_service_1 = require("../components/td-popup/services/td-popup.service");
var i0 = require("@angular/core");
var i1 = require("../components/td-popup/services/td-popup.service");
var ProductionErrorService = /** @class */ (function () {
    function ProductionErrorService(popupService) {
        this.popupService = popupService;
    }
    /*
      Returns true if: the response object exists, and does not contain any errors
      returns false if: the response object contains an error, or it does not exist
        - if response object exists and contains a message, call poopupService to display it
      - NB: Not every implementation cares about the returned value if it's false
    */
    ProductionErrorService.prototype.handleResponseErrors = function (response) {
        if (response && response.error === td_constants_1.NO_ERROR_CODE) {
            return true;
        }
        else {
            if (response && response.message) {
                this.popupService.showInfo(response.message);
            }
            return false;
        }
    };
    ProductionErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductionErrorService_Factory() { return new ProductionErrorService(i0.ɵɵinject(i1.TdPopupService)); }, token: ProductionErrorService, providedIn: "root" });
    return ProductionErrorService;
}());
exports.ProductionErrorService = ProductionErrorService;
