import { Injectable } from '@angular/core';
import { OrdersLeadStatus, OrderSuppStatus } from '@app/core/data-layer/shared/models/td.enumerations';
import { OperationOrderInterface, OperationOrderViewModelInterface } from '../models/operation-order-view-model.interface';

@Injectable()
export class OperationsViewModelService {
  constructor() { }
  
    /** BEGIN: Search Operation Orders: Private methods **/

  /**Converts OperationOrderInterface to OPerationOrderViewModelInterface
   * @param order - OperationOrderInterface object
   * @param operationLeadStatus - The OrdersLeadStatus of the operation which the orders belong to
   * @returns OperationOrderViewModelInterface - the converted view model
   */
   public convertOrderToViewModel(order: OperationOrderInterface, operationLeadStatus: OrdersLeadStatus): OperationOrderViewModelInterface {
    return {
      ...order,
      operationLeadStatus,
      orderLeadStatus: this.extractOrderLeadStatus(order.suppStatusEnumValue, operationLeadStatus)
    }
  }

  /**Extracts the relevant order lead status based on OrderSuppStatus
   * @param suppStatus - The supp status of the order
   * @param operationLeadStatus - The lead status for the operation containing the order
   * @returns OrdersLeadStatus - the Lead status that should be assigned to the order
   */
  private extractOrderLeadStatus(suppStatus: OrderSuppStatus, operationLeadStatus: OrdersLeadStatus): OrdersLeadStatus {
    switch (suppStatus) {
      case OrderSuppStatus.Cancelled: return OrdersLeadStatus.Neutral;
      case OrderSuppStatus.Delivered: return OrdersLeadStatus.Done;
      default: return operationLeadStatus;
    }
  }
  /** END: Search Operation Orders: Private methods **/
}