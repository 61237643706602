"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var server_session_actions_1 = require("@core/data-layer/server-session/store/server-session.actions");
var server_session_selectors_1 = require("@core/data-layer/server-session/store/server-session.selectors");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var ServerDesktopOptionsInitializer = /** @class */ (function () {
    function ServerDesktopOptionsInitializer(store) {
        this.store = store;
    }
    ServerDesktopOptionsInitializer.prototype.initializeServerDesktopOptions = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.store.dispatch(new server_session_actions_1.LoadServerDesktopOptions());
            _this.store.pipe(store_1.select(server_session_selectors_1.selectServerDesktopOptions)).subscribe(function (serverDesktopOptions) {
                if (serverDesktopOptions) {
                    resolve(serverDesktopOptions);
                }
            });
        });
    };
    ServerDesktopOptionsInitializer.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServerDesktopOptionsInitializer_Factory() { return new ServerDesktopOptionsInitializer(i0.ɵɵinject(i1.Store)); }, token: ServerDesktopOptionsInitializer, providedIn: "root" });
    return ServerDesktopOptionsInitializer;
}());
exports.ServerDesktopOptionsInitializer = ServerDesktopOptionsInitializer;
