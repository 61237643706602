import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleService } from 'angular-user-idle';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { POConst } from '../dashboard/models/production-overview.constants';
import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { TD_QUERY_PARAMS, TD_ERROR_TYPES, TD_DYNAMIC_FIELDS } from '@core/data-layer/shared/models/td.constants';
import { LicenseBindingServiceInterface } from '@core/license/services/license-binding.service.interface';
import { AppState } from '@core/data-layer/app.state';
import { selectInfoOverviews } from '@app/dashboard/data-layer/info-overview/store/info-overview.selectors';
import { ProfileMenu } from '@app/shared/models/app.enumerations';
import { ActivePage } from './models/header.enumerations';
import { DxDropDownButtonComponent } from 'devextreme-angular';
import { MediaServiceInterface } from '@core/media/services/media.service.interface';
import { TdPopupService } from '@shared/components/td-popup/services/td-popup.service';

@Component({
  selector: 'td-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnDestroy {

  collapsed: boolean;
  headerHidden = true;
  noActionsHeader: boolean;
  now: number;
  defaultIOConfigId: number;
  settingsVisible: boolean;
  profileSettings: Array<object>;
  private selectInfoOverviewsSubscription: Subscription;
  allowSearch = false;
  searchText: string = '';
  userInitials: string = '';
  userCustomer: string = '';
  activePage: ActivePage = ActivePage.Other;
  allActivePages = ActivePage;
  userProfilePicture: any;
  routerUrl: string;

  @ViewChild('userNameElement', {static: false}) userDropDown: DxDropDownButtonComponent;

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    @Inject('LICENSE_BINDING_SERVICE') private licenseBindingService: LicenseBindingServiceInterface,
    @Inject('MEDIA_SERVICE') private mediaService: MediaServiceInterface,
    public sessionHelperService: SessionHelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userIdle: UserIdleService,
    private translateService: TranslateService,
    private popupService: TdPopupService,
    private store: Store<AppState>
  ) {
    setInterval(() => {
      this.now = Date.now();
    }, 1000);
  }

  ngOnInit() {
    this.router.events
      .subscribe((event) => {        
        if (event instanceof NavigationEnd) {
          this.activePage = this.setActivePage(event.url);
          this.allowSearch = this.sessionHelperService.allowSearch();
          this.activatedRoute.queryParamMap.subscribe((params) => {
            this.headerHidden = !!params.get(POConst.TDOC_APP_TYPE);
            this.noActionsHeader =
              (params.get(TD_QUERY_PARAMS.type) === TD_ERROR_TYPES.noAvailableLicense) ||
              (params.get(TD_QUERY_PARAMS.type) === TD_ERROR_TYPES.sessionEnded) ||
              this.router.url.startsWith('/auth-customers');
          });
          this.routerUrl = this.router.url;
        }
      });
    this.getCollapsed();
    this.setupProfileSettings();
    this.initDefaultInfoOverviewConfigId();
    const license = this.sessionHelperService.license;
  }

  ngOnDestroy(): void {
    if (this.selectInfoOverviewsSubscription) {
      this.selectInfoOverviewsSubscription.unsubscribe();
    }
    // this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  triggerDropDownSelect():void {
    this.userDropDown.instance.open();
  }

  initDefaultInfoOverviewConfigId() {
    this.selectInfoOverviewsSubscription = this.store.pipe(select(selectInfoOverviews)).subscribe(infoOverviews => {
      if (infoOverviews && (infoOverviews.length > 0)) {
        // select the default first info-overview from list
        this.defaultIOConfigId = infoOverviews[0].configId;
      }
    });
  }

  getCollapsed() {
    this.collapsed = window.innerWidth < 992;
  }

  onLogoClick() {
    if (this.noActionsHeader) {
      this.licenseBindingService.startConnectionAndLoadLicense();
      this.userIdle.resetTimer();
    }
    else {
      this.router.navigate(['']);
    }
  }

  login() {
    this.authService.startSignin();
  }

  submitSearch(e: any) {
    if (this.searchText) {
      this.router.navigate(['/search'], {
        queryParams: {
          text: this.searchText
        }
      });
      this.searchText = '';
    }
    else {
      this.popupService.showInfo('appMessages.searchTextEmptyError', true);
    } 
  }

  logout() {
    this.authService.startSignout();
  }

  dropDownSelect(value: number) {
    if (value === ProfileMenu.Settings) {
      this.settingsVisible = true;
    }
    if (value === ProfileMenu.ChangePassword) {
      this.authService.navigateToChangePassword();
    }
  }

  private setupProfileSettings(): void {
    this.profileSettings = [];
    const settings = {
      value: ProfileMenu.Settings,
      name: this.translateService.instant('settings.settings'),
      icon: 'preferences'
    };
    const changePassword = {
      value: ProfileMenu.ChangePassword,
      name: this.translateService.instant('settings.changePassword'),
      icon: './assets/img/changePassword.svg'
    };
    this.sessionHelperService.$isAdmin.subscribe(isAdmin => {
      this.profileSettings = isAdmin ? [settings, changePassword] : [changePassword];
    });
    this.sessionHelperService.$loggedIn.subscribe(response => {
      if (response) {
        this.userInitials = this.authService.getUserInitials();
        this.userCustomer = this.sessionHelperService.currentCustomer.name;
        this.getUserProfilePicture();
      }
    });    
  }

  private getUserProfilePicture(): void {
    const userId = Number(this.authService.getUserId());
    this.mediaService.getMediaSeries(userId, TD_DYNAMIC_FIELDS.user).subscribe(mediaSeries => {
      if (mediaSeries.length > 0) {
        this.mediaService.getMediaImage(mediaSeries[0].keyId).subscribe(response => {
          if (response) {
            this.userProfilePicture = response;
          }
        })
      }
    })
  }

  private setActivePage(url: string): ActivePage {
    if (url.startsWith('/orders')) {
      return ActivePage.Orders;
    }
    if (url.startsWith('/operations')) {
      return ActivePage.Operations;
    }
    if (url.startsWith('/production-overview')) {
      return ActivePage.ProductionOverview;
    }
    if (url.startsWith('/order-templates')) {
      return ActivePage.Templates;
    }
    else {
      return ActivePage.Other;
    } 
  }
}
