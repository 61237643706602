"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
// Storing date parameter as a number in milliseconds
function addDatePropToParams(property, params) {
    params[property.urlParamName] = property.value.valueOf();
    return params;
}
exports.addDatePropToParams = addDatePropToParams;
// Getting date parameter from a number in milliseconds to Date
function extractDateFromStringParam(paramName, params) {
    return params[td_constants_1.TD_QUERY_PARAMS[paramName]] ? new Date(+params[td_constants_1.TD_QUERY_PARAMS[paramName]]) : undefined;
}
exports.extractDateFromStringParam = extractDateFromStringParam;
