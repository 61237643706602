import { Component, ViewChild, Inject, Output, EventEmitter, Input, SimpleChanges, OnChanges, TemplateRef, ElementRef } from '@angular/core';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';

import { SearchManagerService } from '@core/search/services/search-manager.service';
import { TdDataGridComponent } from '@shared/components/td-data-grid/td-data-grid.component';
import { GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { SearchArgsModel } from '@core/search/models/search-args.model';
import { TableInfoServiceInterface } from '@core/table-info/services/table-info.service.interface';
import { GridLoadResultInterface } from '@shared/models/grid-data-request.interface';
import { TD_DYNAMIC_FIELDS } from '@core/data-layer/shared/models/td.constants';

@Component({
  selector: 'td-drop-down-grid',
  templateUrl: './td-drop-down-grid.component.html',
  styleUrls: ['./td-drop-down-grid.component.less'],
})
export class TdDropDownGridComponent implements OnChanges {

  @Input() mainTable: string;
  @Input() gridIdentifier: string;
  @Input() placeHolder: string;
  @Input() disabled: boolean;
  @Input() gridWidth?: number;
  @Input() displayExpr: (selectedRow: any) => string;
  @Input() searchCriterias: any[] = [];
  @Input() initSearchRequestArgs: (gridStructure: GridStructureViewInterface) => SearchArgsModel;
  @Input() afterSearchDataLoaded?: (dataSet: any[], gridStructure: GridStructureViewInterface) => void;
  @Input() onExecuteSearch?: () => void;
  @Input() templates: TemplateRef<any>[];
  @Output() searchResultCleared: EventEmitter<null> = new EventEmitter();
  @Output() articleSelected: EventEmitter<any> = new EventEmitter();

  gridStructure: GridStructureViewInterface;
  private searchArgs: SearchArgsModel;
  searchResultData: DataSource;
  searchValue: any;
  dropDownWidth = 300;
  private searchTimer: NodeJS.Timeout;
  @ViewChild('searchGrid', { static: false }) searchGrid: TdDataGridComponent;
  @ViewChild('searchInputBox', { static: false }) searchInputBox: DxDropDownBoxComponent;

  // properties for navigating with keyboard
  allSearchResultData: any[] = [];
  preventSearchOnEnter = false;
  focusSearchGrid = false;
  focusRowEnabled = false;
  focusedRowKey: number;

  constructor(
    @Inject('TABLE_INFO_SERVICE') private tableInfoService: TableInfoServiceInterface,
    private searchManagerService: SearchManagerService
  ) {
    if (!this.gridWidth) {
      this.gridWidth = this.dropDownWidth + 100; // a little bit wide
    }
    this.inputBoxDisplayExpr = this.inputBoxDisplayExpr.bind(this);
    this.onInputSearchText = this.onInputSearchText.bind(this);
    this.onOpenedSearchResult = this.onOpenedSearchResult.bind(this);
    this.onClosedSearchResult = this.onClosedSearchResult.bind(this);
    this.onChangedSearchValue = this.onChangedSearchValue.bind(this);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['gridIdentifier'] && changes['gridIdentifier'].currentValue) {
      await this.initGridSearch();
    }
  }

  private async initGridSearch(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(this.gridIdentifier, this.mainTable).subscribe(gridStructure => {
        this.gridStructure = gridStructure;
        // init search args
        this.searchArgs = this.initSearchRequestArgs(this.gridStructure);
        this.searchArgs.criterias = this.searchCriterias;
        this.clearSearchResult();
        resolve(true);
      });
    });
  }

  clearSearchResult() {
    this.searchResultCleared.emit();
    this.searchResultData = null;
    this.searchInputBox.instance.reset();
    if (this.searchGrid) {
      this.searchGrid.grid.instance.repaint();
    }
  }

  get selectedData() {
    return this.searchValue;
  }

  requestSearch() {
    this.searchArgs.criterias = this.searchCriterias;
    // due to search filters can be changed from parent component we are updating them before calling search
    this.searchManagerService.setUserSearchFilters(this.searchArgs);
    this.searchResultData = this.searchManagerService.requestSearchData(this.searchArgs, this.searchValue, this.afterDataLoaded(), TD_DYNAMIC_FIELDS.keyId);
  }

  private executeSearch(enterPressed = false) {
    if ((!enterPressed || this.searchRowSelected()) && (this.searchInputBox.text !== '')) {
      this.searchValue = this.searchInputBox.text;
      this.requestSearch();
    }
    else if (!enterPressed) {
      this.clearSearchResult();
    }
    else if (this.searchValue) {
      this.requestSearch();
    }
    if (this.onExecuteSearch) {
      this.onExecuteSearch();
    }
  }

  /*
    Every time a new page of data is loaded, it is pushed to the "allSearchResultData" array, so it contains data of all pages
    This array is used to find a match, if it's not from the latest searched page.
    Furthermore, we store the keyId of the first row in the dataset, used to determine which row to focus initially
  */
  afterDataLoaded(): (loadResult: GridLoadResultInterface) => void {
    return (loadResult) => {
      this.focusedRowKey = loadResult.totalCount > 0 ? loadResult.data[0][TD_DYNAMIC_FIELDS.keyId] : null;
      loadResult.data.forEach(entry => this.allSearchResultData.push(entry));
      if (this.afterSearchDataLoaded) {
        this.afterSearchDataLoaded(loadResult.data, this.gridStructure);
      }
    };
  }

  searchRowSelected(): boolean {
    return this.searchValue && (typeof this.searchValue !== 'string');
  }

  inputBoxDisplayExpr(selectedRow: any) {
    if (this.searchRowSelected()) {
      return this.displayExpr(selectedRow);
    }
    else {
      return selectedRow;
    }
  }

  onInputSearchText() {
    clearTimeout(this.searchTimer);
    if (this.searchInputBox.text === '') {
      this.clearSearchResult();
    }
    this.searchTimer = setTimeout(() => {
      if (this.searchInputBox.opened) {
        this.executeSearch();
      }
      else {
        this.searchInputBox.instance.open();
      }
    }, 1000);
  }

  onOpenedSearchResult(e) {
    // Since we can't set different width for autocreated overlay dropdown popup using css we set it here
    e.component._popup.option('width', 'auto');
    this.executeSearch();
  }

  onClosedSearchResult() {
    clearTimeout(this.searchTimer);
  }

  onChangedSearchValue() {
    this.allSearchResultData = [];
    clearTimeout(this.searchTimer);
    this.searchInputBox.instance.focus();
  }

  /*
    When a row is selected by either mouse click or enter press, we match the keyId with the entries in the "allSearchResultData" array    
  */
  onChangeSelectedSearchRow(selectedRows: Array<any>): void {
    if (selectedRows && (selectedRows.length > 0)) {
      const match = this.allSearchResultData.find(entry => entry[TD_DYNAMIC_FIELDS.keyId] === selectedRows[0]);      
      this.searchValue = match;      
      this.closeDropdown();
    }
  }

  /*
    The dropdown box will keep track of when enter is pressed, even when it's done within the drop down data grid in it's template.
    Therefore it's important that we don't trigger a new search, when the user is trying to select an article
  */
  enterKeyDown(): void {
    if (!this.preventSearchOnEnter && this.searchValue && this.searchValue !== '') {
      clearTimeout(this.searchTimer);
      this.searchInputBox.instance.focus();
      if (!this.searchInputBox.opened) {
        this.searchInputBox.instance.open();
      }
      else {
        this.executeSearch(true);
      }
    }    
  }

  /*
    Triggered when the user presses enter on a row they want to focus.
    It sets the "preventSearchOnEnter" property, to ensure we don't trigger a new search when enter is being pressed
  */
  enterPressedOnFocusedRow(e: any): void {
    this.preventSearchOnEnter = true;
    this.onChangeSelectedSearchRow([e[TD_DYNAMIC_FIELDS.keyId]]);
  }

  /*
    Closes the search result grid, and allows enter to be used for searching for new articles again, after a 10 ms delay.
    This is to ensure that selecting a row, doesn't also trigger a new search
  */
  closeDropdown(): void {
    setTimeout(() => {
      this.searchInputBox.instance.close();
      this.preventSearchOnEnter = false;
      this.articleSelected.emit(this.searchValue);
    }, 10);
  }

  /*
    When the arrow down key is released, we want to focus the search grid and enable focusRow
  */
  arrowDownKeyUp(): void {
    this.focusSearchGrid = true;
  }

  /*
    When the data grid is focused, and the user presses escape, this method will set focus to the searchInputBox, and cancel grid focus
  */
  focusDropdownInput(): void {
    this.focusSearchGrid = false;    
    this.searchInputBox.instance.focus();
  }

  /*
    When the searchInputBox is clicked with mouse, we will remove focus from data grid
  */
  mouseFocusDropdownInput(): void {
    this.focusSearchGrid = false;    
  }
}
