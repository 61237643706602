import { Component } from '@angular/core';
import { TdLoadPanelService } from '../td-load-panel/services/td-load-panel.service';

@Component({
  selector: 'td-loading',
  template: ''
})

export class LoadingComponent {
  constructor(
    public loadPanelService: TdLoadPanelService
  ) { 
    this.loadPanelService.showLoadPanel();
  }

  ngOnDestroy() {
    this.loadPanelService.hideLoadPanel();
  }
}