"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActivePage;
(function (ActivePage) {
    ActivePage[ActivePage["Other"] = 0] = "Other";
    ActivePage[ActivePage["Orders"] = 1] = "Orders";
    ActivePage[ActivePage["Operations"] = 2] = "Operations";
    ActivePage[ActivePage["Templates"] = 3] = "Templates";
    ActivePage[ActivePage["ProductionOverview"] = 4] = "ProductionOverview";
})(ActivePage = exports.ActivePage || (exports.ActivePage = {}));
