"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var td_enumerations_1 = require("../models/td.enumerations");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("../../../../shared/services/session-helper.service");
var ViewHelperService = /** @class */ (function () {
    function ViewHelperService(translateService, sessionHelperService) {
        this.translateService = translateService;
        this.sessionHelperService = sessionHelperService;
    }
    ViewHelperService.prototype.getOmittedText = function (status, articleTypeTranslation) {
        var omittedTranslation;
        if (status === td_enumerations_1.ArticleStatus.Dead) {
            omittedTranslation = 'appMessages.isOmitted';
        }
        else if (status === td_enumerations_1.ArticleStatus.Dieing) {
            omittedTranslation = 'appMessages.toBeOmitted';
        }
        if (omittedTranslation) {
            return this.translateService.instant(omittedTranslation).replace('%s', articleTypeTranslation);
        }
        else {
            return '';
        }
    };
    ViewHelperService.prototype.getOmittedImgUrl = function (status) {
        if (status === td_enumerations_1.ArticleStatus.Dead) {
            return './assets/img/Disable.svg';
        }
        else if (status === td_enumerations_1.ArticleStatus.Dieing) {
            return './assets/img/Warning Orange.svg';
        }
        else {
            return '';
        }
    };
    ViewHelperService.prototype.getItemType = function (item) {
        if (this.sessionHelperService.license.endoCycle) {
            return td_enumerations_1.ItemInstrumentType.Endoscope;
        }
        else if (this.sessionHelperService.license.endoscope) {
            return item.type;
        }
        else {
            return td_enumerations_1.ItemInstrumentType.Instrument;
        }
    };
    ViewHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ViewHelperService_Factory() { return new ViewHelperService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.SessionHelperService)); }, token: ViewHelperService, providedIn: "root" });
    return ViewHelperService;
}());
exports.ViewHelperService = ViewHelperService;
