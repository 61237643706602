"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var router_1 = require("@angular/router");
var td_popup_service_1 = require("@app/shared/components/td-popup/services/td-popup.service");
var order_manager_service_1 = require("@app/shared/services/order-manager.service");
var production_error_service_1 = require("@app/shared/services/production-error.service");
var session_helper_service_1 = require("@app/shared/services/session-helper.service");
var td_load_panel_service_1 = require("@app/shared/components/td-load-panel/services/td-load-panel.service");
var i0 = require("@angular/core");
var i1 = require("../../shared/components/td-popup/services/td-popup.service");
var i2 = require("@ngx-translate/core");
var i3 = require("../../shared/services/order-manager.service");
var i4 = require("../../shared/services/session-helper.service");
var i5 = require("../../shared/services/production-error.service");
var i6 = require("../../shared/components/td-load-panel/services/td-load-panel.service");
var i7 = require("@angular/router");
var OperationsSharedService = /** @class */ (function () {
    function OperationsSharedService(orderService, operationService, popupService, translateService, orderManagerService, sessionHelperService, productionErrorService, loadPanelService, router) {
        this.orderService = orderService;
        this.operationService = operationService;
        this.popupService = popupService;
        this.translateService = translateService;
        this.orderManagerService = orderManagerService;
        this.sessionHelperService = sessionHelperService;
        this.productionErrorService = productionErrorService;
        this.loadPanelService = loadPanelService;
        this.router = router;
    }
    OperationsSharedService.prototype.addOrderToOperation = function (operationKeyId, stokKeyId, reqDelTime) {
        var _this = this;
        this.operationService.getCreateOperationOrderInfo(operationKeyId, this.sessionHelperService.currentCustomer.keyId).subscribe(function (info) {
            if (info.roomBlockOrderCreation) {
                _this.loadPanelService.hideLoadPanel();
                _this.showErrorIfRoomBlocksOrderCreation(info.roomText);
            }
            else if (!info.customerCanOrder) {
                _this.loadPanelService.hideLoadPanel();
                _this.showErrorIfCustomerCannotCreateOrder(info.customerText);
            }
            else {
                _this.saveNewOrderToOperation(operationKeyId, stokKeyId, reqDelTime);
            }
        });
    };
    OperationsSharedService.prototype.showErrorIfRoomBlocksOrderCreation = function (roomText) {
        var message = this.translateService.instant('operationDetails.ordersBlockedForRoom').replace('%s', roomText);
        this.popupService.showError(message);
    };
    OperationsSharedService.prototype.showErrorIfCustomerCannotCreateOrder = function (customerText) {
        var message = this.translateService.instant('operationDetails.customerCannotOrder').replace('%s', customerText);
        this.popupService.showError(message);
    };
    OperationsSharedService.prototype.saveNewOrderToOperation = function (operationKeyId, stokKeyId, reqDelTime) {
        var _this = this;
        var newOrder = this.orderManagerService.initializeNewOrder(operationKeyId, stokKeyId);
        newOrder.details.reqDelTime = reqDelTime;
        this.orderService.saveOrder(newOrder).subscribe(function (response) {
            _this.loadPanelService.hideLoadPanel();
            if (_this.productionErrorService.handleResponseErrors(response)) {
                _this.handleSuccessfulSavedOrder(response.value);
            }
        });
    };
    OperationsSharedService.prototype.handleSuccessfulSavedOrder = function (orderId) {
        this.sessionHelperService.reloadCustomerOpenOrders();
        this.router.navigate(["/orders/" + orderId]);
    };
    OperationsSharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperationsSharedService_Factory() { return new OperationsSharedService(i0.ɵɵinject("ORDER_SERVICE"), i0.ɵɵinject("OPERATION_DATA_SERVICE"), i0.ɵɵinject(i1.TdPopupService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.OrderManagerService), i0.ɵɵinject(i4.SessionHelperService), i0.ɵɵinject(i5.ProductionErrorService), i0.ɵɵinject(i6.TdLoadPanelService), i0.ɵɵinject(i7.Router)); }, token: OperationsSharedService, providedIn: "root" });
    return OperationsSharedService;
}());
exports.OperationsSharedService = OperationsSharedService;
