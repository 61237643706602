"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var unit_helper_service_1 = require("./unit-helper.service");
var utility_service_1 = require("@core/utility/utility.service");
var HttpUnitService = /** @class */ (function () {
    function HttpUnitService(http, unitHelperService, utilityService) {
        this.http = http;
        this.unitHelperService = unitHelperService;
        this.utilityService = utilityService;
    }
    HttpUnitService.prototype.unitsUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.unitsCtrl;
    };
    HttpUnitService.prototype.getUnit = function (unitKeyId) {
        var _this = this;
        return this.http.get(this.unitsUrl() + ("(" + unitKeyId + ")") + api_hub_constants_1.API_HUB_PATHS.getDetails).pipe(operators_1.map(function (response) { return _this.unitHelperService.convertUnitToViewModel(response); }));
    };
    HttpUnitService.prototype.searchByUnit = function (unitKeyId) {
        return this.http.get("" + this.unitsUrl() + api_hub_constants_1.API_HUB_PATHS.searchByUnit + "(" + unitKeyId + ")");
    };
    HttpUnitService.prototype.getFastTrackInfo = function (unitKeyId) {
        return this.http.get(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.getFastTrackInfo);
    };
    HttpUnitService.prototype.getFastTrackCodes = function () {
        return this.http.get(this.unitsUrl() + api_hub_constants_1.API_HUB_PATHS.fastTrackCodes);
    };
    HttpUnitService.prototype.addFastTrackCode = function (unitKeyId, customerKeyId, fastTrackCodeKeyId) {
        var params = { customerKeyId: customerKeyId, fastTrackCodeKeyId: fastTrackCodeKeyId };
        return this.http.post(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.addFastTrackCode, params);
    };
    HttpUnitService.prototype.cancelFastTrackCode = function (unitKeyId, fastTrackCodeKeyId) {
        return this.http.post(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.cancelFastTrackCode + "(" + fastTrackCodeKeyId + ")", null);
    };
    HttpUnitService.prototype.customerCanFastTrack = function (unitKeyId, customerKeyId, fastTrackCodeKeyId) {
        var params = { customerKeyId: customerKeyId, fastTrackCodeKeyId: fastTrackCodeKeyId };
        return this.http.post(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.customerCanFastTrack, params);
    };
    HttpUnitService.prototype.hasFastTrackCode = function (unitKeyId, fastTrackCodeKeyId) {
        return this.http.get(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.hasFastTrackCode + "(" + fastTrackCodeKeyId + ")");
    };
    HttpUnitService.prototype.getCommentTypes = function (factoryKeyId) {
        return this.http.get("" + this.unitsUrl() + api_hub_constants_1.API_HUB_PATHS.getStandardCommentTypes + "?factoryKeyId=" + factoryKeyId);
    };
    HttpUnitService.prototype.getComments = function (unitKeyId) {
        return this.http.get(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.getStandardComments);
    };
    HttpUnitService.prototype.addComment = function (unitKeyId, commentArgs) {
        return this.http.post(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.addStandardComment, commentArgs);
    };
    HttpUnitService.prototype.getEventList = function (requestArgs, unitKeyId) {
        var _this = this;
        return this.http.post(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.eventList, requestArgs).pipe(operators_1.map(function (response) {
            response.dataSet.forEach(function (event) {
                event.time = _this.utilityService.convertValueToDate(event.time);
            });
            return response;
        }));
    };
    HttpUnitService.prototype.unitHasAwaitingIndicator = function (unitKeyId) {
        return this.http.get(this.unitsUrl() + "(" + unitKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.awaitingIndicator);
    };
    return HttpUnitService;
}());
exports.HttpUnitService = HttpUnitService;
