import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import DataSource from 'devextreme/data/data_source';

// Services
import { SearchManagerService } from '@core/search/services/search-manager.service';
import { OrderFilterService } from '@core/search/filters/order-filter.service';

// Interfaces, Models, const's & Enums
import { OperationFilterInterface } from '@core/data-layer/operation-data/models/operation-filter.interface';
import { TD_DYNAMIC_FIELDS, TD_MODEL_NAMES } from '@core/data-layer/shared/models/td.constants';
import { RecentSearchInterface, RecentSearchType } from '@core/search/models/recent-search.interface';
import { GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { OperationSearchType, SpecialSearchType } from '@core/data-layer/shared/models/td.enumerations';
import { OperationOrderInterface, OperationOrderViewModelInterface } from '../models/operation-order-view-model.interface';
import { OperationsViewModelService } from './operations-viewmodel-helper.service';
import { take } from 'rxjs/operators';

@Injectable()
export class OperationsSearchService {
  
  lastSearchType: OperationSearchType;
  
  constructor(
    private searchManagerService: SearchManagerService,
    private orderFilterService: OrderFilterService,
    private operationsViewModelService: OperationsViewModelService
  ) { }
 
  /** BEGIN: Search Operations: Public methods **/

  /**
   * Performs search for operations
   * @param gridStructure - the grid structure used in the table that holds the results
   * @param searchArgFromDate the - from date used in search
   * @param searchArgToDate - the to date used in search
   * @param searchArgText - the text used in search
   * @param searchType - the search type enum (either date or text)
   * @returns DataSource - the data source object used in the table that display the info
   */
  public createSearchOperationsDataSource(
    gridStructure: GridStructureViewInterface,
    searchArgFromDate: Date, 
    searchArgToDate: Date, 
    searchArgText: string = '', 
    searchType?: OperationSearchType
  ): DataSource {
    const nextSearchType = (searchType != null) ? searchType : this.lastSearchType;
    this.lastSearchType = nextSearchType;
    this.searchManagerService.setRecentOperationSearch(this.generateRecentSearch(searchArgFromDate, searchArgToDate, searchArgText, nextSearchType));
    const operationsSearchArgs = this.searchManagerService.initWebScheduledOperationSearchRequestArgs(
      gridStructure, searchArgFromDate, searchArgToDate, nextSearchType, true);
    return this.searchManagerService.requestSearchData(
      operationsSearchArgs,
      searchArgText,
      null,
      TD_DYNAMIC_FIELDS.keyId
    );
    
  }
  /** END: Search Operations: Public methods **/

  /** BEGIN: Search Operation Orders: Public methods **/

  /**Get's the orders associated with an operation
   * @param keyId - keyId of the operation
   * @param gridStructure - grid structure of the table that will hold the data
   * @param operationsData - The datasource of the table that holds the data
   * @returns Subject of OperationOrderViewModelInterface[]
   */
  public getOperationOrders(
    keyId: number,
    gridStructure: GridStructureViewInterface,
    operationsData: DataSource
    ): BehaviorSubject<OperationOrderViewModelInterface[]> {
    const operationOrdersSearchArgs = this.searchManagerService.initSearchRequestArgs(
      SpecialSearchType.None,
      TD_MODEL_NAMES.orderModel,
      gridStructure,
      this.orderFilterService.getOperationOrderListFilter(keyId),
      true
    );
    const items = operationsData.items();
    const ordersLeadStatus = items.find(entry => entry.keyId === keyId).OrdersLeadStatus;
    const result = new BehaviorSubject<OperationOrderViewModelInterface[]>(null);
    this.searchManagerService.requestSearch(operationOrdersSearchArgs, '').pipe(take(1)).subscribe(response => {
      const dataValues = response.value.dataSet.mappedRows.map(entry => {
        return this.operationsViewModelService.convertOrderToViewModel(entry as OperationOrderInterface, ordersLeadStatus);
      });
      result.next(dataValues);
    });
    return result;
  }

  /** END: Search Operation Orders: Public methods **/



  /** BEGIN: Search Operations: Private methods **/

  /**Generates recent search filter for operations
   * @param searchArgFromDate the - from date used in search
   * @param searchArgToDate - the to date used in search
   * @param searchArgText - the text used in search
   * @param searchType - the search type enum (either date or text)
   * @returns RecentSearchInterface
   */
  private generateRecentSearch(
    searchArgFromDate: Date, 
    searchArgToDate: Date, 
    searchArgText: string = '',
    searchType: OperationSearchType): RecentSearchInterface {
    const type: RecentSearchType = searchType === OperationSearchType.Date ? RecentSearchType.Date : RecentSearchType.Text;
    return {
      type: type,
      operationFilters: this.getOperationFilters(searchArgFromDate, searchArgToDate, searchArgText)
    }
  }

  /**Generates filters used for searching operation
   * @param searchArgFromDate the - from date used in search
   * @param searchArgToDate - the to date used in search
   * @param searchArgText - the text used in search
   * @returns OperationFilterInterface
   */
  private getOperationFilters(
    searchArgFromDate: Date, 
    searchArgToDate: Date, 
    searchArgText: string = ''): OperationFilterInterface {
    let filters: OperationFilterInterface = {
      dateInterval: {
        from: searchArgFromDate,
        to: searchArgToDate
      },
      text: searchArgText
    }    
    return filters;
  }
  /** END: Search Operations: Private methods **/
}
