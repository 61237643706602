"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserSessionActionTypes;
(function (UserSessionActionTypes) {
    UserSessionActionTypes["LoadProfileData"] = "[UserSession] Load Profile Data";
    UserSessionActionTypes["SetProfileData"] = "[UserSession] Set Profile Data";
    UserSessionActionTypes["LoadUserOptions"] = "[UserSession] Load User Options";
    UserSessionActionTypes["SetUserOptions"] = "[UserSession] Set User Options";
    UserSessionActionTypes["LoadDefaultUserOptions"] = "[UserSession] Load Default User Options";
    UserSessionActionTypes["SetDefaultUserOptions"] = "[UserSession] Set Default User Options";
})(UserSessionActionTypes = exports.UserSessionActionTypes || (exports.UserSessionActionTypes = {}));
var LoadProfileData = /** @class */ (function () {
    function LoadProfileData() {
        this.type = UserSessionActionTypes.LoadProfileData;
    }
    return LoadProfileData;
}());
exports.LoadProfileData = LoadProfileData;
var SetProfileData = /** @class */ (function () {
    function SetProfileData(payload) {
        this.payload = payload;
        this.type = UserSessionActionTypes.SetProfileData;
    }
    return SetProfileData;
}());
exports.SetProfileData = SetProfileData;
var LoadUserOptions = /** @class */ (function () {
    function LoadUserOptions() {
        this.type = UserSessionActionTypes.LoadUserOptions;
    }
    return LoadUserOptions;
}());
exports.LoadUserOptions = LoadUserOptions;
var SetUserOptions = /** @class */ (function () {
    function SetUserOptions(payload) {
        this.payload = payload;
        this.type = UserSessionActionTypes.SetUserOptions;
    }
    return SetUserOptions;
}());
exports.SetUserOptions = SetUserOptions;
var LoadDefaultUserOptions = /** @class */ (function () {
    function LoadDefaultUserOptions() {
        this.type = UserSessionActionTypes.LoadDefaultUserOptions;
    }
    return LoadDefaultUserOptions;
}());
exports.LoadDefaultUserOptions = LoadDefaultUserOptions;
var SetDefaultUserOptions = /** @class */ (function () {
    function SetDefaultUserOptions(payload) {
        this.payload = payload;
        this.type = UserSessionActionTypes.SetDefaultUserOptions;
    }
    return SetDefaultUserOptions;
}());
exports.SetDefaultUserOptions = SetDefaultUserOptions;
