<div class="row h-100 d-flex align-items-center">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-center">
        <div *ngIf="noAvailableLicense" class="row">
          <div class="col-sm-12">
            <div>
              <label>{{ 'license.noAvailableLicenses' | translate }}</label>
            </div>
            <div>{{ 'license.countLicensesInUse' | translate: {'maximum': maximumCount, 'remaining': (maximumCount - remainingCount) } }}</div>
            <div>{{ 'license.tryAgainInFewMin' | translate }}</div>
            <div class="pt-3 d-flex justify-content-end">
              <dx-button (onClick)="tryAgain()" class="td-button tertiary-button"
                         [text]="'license.tryAgain' | translate">
              </dx-button>
            </div>
          </div>
        </div>
        <div *ngIf="sessionEnded" class="row">
          <div class="col-sm-12">
            <div>
              <label>{{ 'session.sessionEnded' | translate }}</label>
            </div>
            <div>{{ 'session.startNewSession' | translate }}</div>
            <div class="pt-3 d-flex justify-content-end">
              <dx-button (onClick)="newSession()" class="td-button tertiary-button"
                         [text]="'session.newSession' | translate">
              </dx-button>
            </div>
          </div>
        </div>
        <div *ngIf="moduleNotIncluded" class="row">
          {{ 'appMessages.moduleNotIncluded' | translate: {'pageName': missedPageName } }}
        </div>
      </div>
    </div>
  </div>
</div>
