import {
  Language,
  NotifyPriority,
  UserPackOnScreen,
  UserPictureOnPack,
  LegacyProcessType,
  AssignFactoryData,
  TriggerPostponeRight
} from '@core/data-layer/shared/models/td.enumerations';

export class ProfileDataModel {
  preferences_AutoEdit: boolean = false;
  preferences_StartWithSearch: boolean = false; // removed
  preferences_ModalOnSearch: boolean = false;
  preferences_WarnOnKeyChange: boolean = true;
  preferences_Language: Language = Language.Default;
  preferences_NotifyPriorityAsEmail: NotifyPriority = NotifyPriority.None;
  preferences_NotifyPriorityAsSMS: NotifyPriority = NotifyPriority.None;
  preferences_NotifyPriorityAsPopup: NotifyPriority = NotifyPriority.High;
  preferences_FastTrackPrompt: boolean = true;
  preferences_Colors_AutoNumberColor: string; // Color.Yellow
  preferences_Colors_FastTrackingColor: string; // Color.Red
  preferences_PackOnScreen: UserPackOnScreen;
  preferences_PictureOnPack: UserPictureOnPack = UserPictureOnPack.On;

  functions_ScannerRoles: Set<LegacyProcessType>;
  functions_ScannerRolesStr: string;
  functions_Profficiency: number = 0;
  functions_MaxDifficulty: number = 0;
  functions_AdminLOT: number = 0;
  functions_AllowAdminLogin: boolean = true;
  functions_DiscardLevel: number = 0;

  system_SystemAdminRights: boolean = false;
  system_CanControlTDocComputers: boolean = false;
  system_CanChangeDefaults: boolean = false;
  system_CanMarkUnits: boolean = false;
  system_DatabaseCleanUp: boolean = false;
  system_CanShutdown: boolean = false;
  system_CanChangeUnitBatch: boolean = false;
  system_CanRunJobs: boolean = false;
  system_CanRestartServers: boolean = false;
  system_CanBackup: boolean = false;
  system_CanRestore: boolean = false;
  system_CanArchive: boolean = false;
  system_CanEditItemListEverywhere: boolean = false; //Obsolete; Removed from list using 'stored' directive. Cannot be removed - read error occures if it exists in dfm.
  system_CanRedirectPrinters: boolean = false;
  system_CanWorkWithCommonData: boolean = true;
  system_CanAssignFactories: boolean = false;
  system_CanAssignFactoryData: AssignFactoryData = AssignFactoryData.None;
  system_CanCreateAdminProcess: boolean = false;
  system_CanEditReportSecurity: boolean = false;
  system_CanEditPictures: boolean = true;
  system_CanEditProcessPictures: boolean = true;
  system_CanChangeParameterValues: boolean = true;
  system_CanRegisterWeightWithScanner: boolean = false;
  system_CanChangeBatchChargeNum: boolean = false;
  system_CanChangeBatchExternalNum: boolean = false;
  system_CanSetBatchChargeNum: boolean = true;
  system_CanSetBatchExternalNum: boolean = true;
  system_CanCancelFastTrack: boolean = false;
  system_CanClearProcessError: boolean = false;
  system_CanChangeProcessProgram: boolean = false;
  system_CanChangeUnitPatient: boolean = false;
  system_CanChangeVideoAutoplay: boolean = false;
  system_CanSetReferenceProcess: boolean = false;
  system_CanApproveEnableABatch: boolean = false;
  system_CanMergeBasicData: boolean = false;
  system_CanEditInfoOverviewConfigs: boolean = false;
  system_CanEditExtDocRefs: boolean = false;
  system_CanCreateIndicators: boolean = false;
  system_CanApproveIndicators: boolean = false;
  system_CanDisapproveIndicators: boolean = false;
  system_CanForceDispatchExpired: boolean = false;
  system_CanForceDispatchWrongCust: boolean = true;
  system_CanForceDispatchIndicator: boolean = false;
  system_CanForceIndicatorApproval: boolean = false;
  system_CanForceDispatchReserved: boolean = false;
  system_CanForceDifferentProgramGroup: boolean = false;
  system_CanForceFlexibleDelivery: boolean = false;
  system_CanForceReservation: boolean = false;
  system_CanSendOrdWhenBookingFailed: boolean = false;
  system_CanClearSerialReference: boolean = false;
  system_CanPostRegisterIndicators: boolean = true;
  system_CanSetDefaultLocation: boolean = false;
  system_CanChangePackMaterial: boolean = true;
  system_CanCancelUnits: boolean = true;
  system_CanIgnoreEndoscopeErrors: boolean = false;
  system_CanCreateUnitFromProductList: boolean = false;
  system_CanChangeDefaultCalendar: boolean = false;
  system_CanChangeCalendar: boolean = false;
  system_CanChangeCustBookingOrderMode: boolean = false;
  system_CanSeeAllProductsInWeb: boolean = false;
  system_CanForceUseReserved: boolean = false;
  system_CanCustomizeToolbars: boolean = true;
  system_CanCustomizeTabs: boolean = true;
  system_CanClearRecents: boolean = false;
  system_CanViewProductionOverview: boolean = true;
  system_CanEditLOTNumbersOfOthers: boolean = false;
  system_CanEditMachineDisplayGroups: boolean = false;
  system_CanSuspendMachineLogging: boolean = false;
  system_CanEditPIPSystemData: boolean = false;
  system_CanAlterItemSerialInCount: boolean = true;
  system_CanSkipPatientVerification: boolean = false;
  system_CanOverrideStopTrigger: boolean = false;
  system_CanPostponeTriggerRights: Set<TriggerPostponeRight>;
  system_CanContinueOnRestrictedTrigger: boolean = false;
  system_CanEditCustomSQLFilters: boolean = false;
  system_FullReportEditingRights: boolean = false;
  system_SysOP: boolean = false;

  pip_CanViewPIPData: boolean = false;
  pip_CanViewPIPDataActiveOperations: boolean = false;
  pip_CanViewPIPDataEndedOperations: boolean = false;
  pip_CanViewPIPAuditLog: boolean = false;
}
