import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { ProductServiceInterface } from './product.service.interface';
import { ProductModel } from '../models/product.model';
import { ApiDataResponse } from '@core/data-layer/shared/models/api-response.model';
import { ProductViewModel } from '../models/product-view.model';
import { ProductHelperService } from './product-helper.service';
import { ExtendedSearchResultViewModel } from '@core/search/models/search-result-view.model';
import { SearchResultModel } from '@core/search/models/search-result.model';
import { SearchHelperService } from '@core/search/services/search-helper.service';
import { BaseGridArgsModel } from '@core/data-layer/shared/models/base-grid-args.model';
import { SearchArgsModel } from '@core/search/models/search-args.model';
import { ProductLocatorGridArgsModel } from '../models/product-locator-args.model';
import { TD_MAX_LEVEL_OF_NESTED_TABLES } from '@core/data-layer/shared/models/td.constants';
import { ExternalDocumentInfoListViewModel } from '@core/data-layer/external-documents/models/external-document-list-view.model';
import { ExternalDocumentsHelperService } from '@core/data-layer/external-documents/services/external-documents-helper.service';

@Injectable()
export class HttpProductService implements ProductServiceInterface {

  constructor(
    private http: HttpClient,
    private productHelperService: ProductHelperService,
    private searchHelperService: SearchHelperService,
    private extDocHelperService: ExternalDocumentsHelperService
  ) { }

  productsUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.productsCtrl;
  }

  getProduct(productKeyId: number, chargeOptions: number): Observable<ApiDataResponse<ProductViewModel>> {
    return this.http.get<ApiDataResponse<ProductModel>>(this.productsUrl() + `(${productKeyId})` + API_HUB_PATHS.details, { params: { customerChargeOptions: chargeOptions.toString() } }).pipe(
      map(response => ({ ...response, value: this.productHelperService.convertProductToViewModel(response.value) }))
    );
  }

  locateProduct(requestArgs: ProductLocatorGridArgsModel, productKeyId: number): Observable<ExtendedSearchResultViewModel> {
    this.searchHelperService.applyColumnsConfigurations(requestArgs);
    return this.http.post<SearchResultModel>(`${this.productsUrl()}(${productKeyId})${API_HUB_PATHS.locate}?maxLevelOfNestedTables=${TD_MAX_LEVEL_OF_NESTED_TABLES}`, requestArgs).pipe(
      map(response => this.searchHelperService.convertExtendedSearchResultToViewModel(requestArgs as BaseGridArgsModel as SearchArgsModel, response)));
  }

  getExternalDocumentList(requestArgs: BaseGridArgsModel, productKeyId: number): Observable<ExternalDocumentInfoListViewModel> {
    return this.http.post<any>(`${this.productsUrl()}(${productKeyId})${API_HUB_PATHS.externalDocumentList}`, requestArgs).pipe(
      map(response => {
        this.extDocHelperService.convertExternalDocumentsToViewModel(response);
        return response;
      })
    );
  }
}
