import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { ServerSessionServiceInterface } from './server-session.service.interface';
import { ServerDesktopOptionsModel } from '../models/server-desktop-options.model';

@Injectable()
export class HttpServerSessionService implements ServerSessionServiceInterface {

  constructor(
    private http: HttpClient
  ) { }

  desktopDataUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.desktopDataCtrl;
  }

  getServerDesktopOptions(): Observable<ServerDesktopOptionsModel> {
    return this.http.get<ServerDesktopOptionsModel>(this.desktopDataUrl() + API_HUB_PATHS.getServerOptions);
  }

  saveServerDesktopOptions(options: ServerDesktopOptionsModel): Observable<null>  {
    return this.http.post<null>(this.desktopDataUrl() + API_HUB_PATHS.saveServerOptions, options);
  }

}
