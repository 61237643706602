import { Component, OnInit, Input } from '@angular/core';

import { TabsInterface } from '@app/shared/models/tabs.interface';

@Component({
  selector: 'td-tab-panel-title',
  templateUrl: './td-tab-panel-title.component.html',
  styleUrls: ['./td-tab-panel-title.component.less']
})
export class TdTabPanelTitleComponent implements OnInit {

  @Input() tabs: TabsInterface[];
  @Input() selectedIndex: number;
  @Input() translateTitle = false;

  constructor() { }

  ngOnInit() {
  }

}
