"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var factoryActions = require("./factory.actions");
exports.initialFactoryState = {
    allFactories: null,
    customerFactories: null,
    userFactories: null
};
function factoryReducer(state, action) {
    if (state === void 0) { state = exports.initialFactoryState; }
    switch (action.type) {
        case factoryActions.FactoryActionTypes.LoadAllFactories:
            return handleLoadAllFactories(state, action);
        case factoryActions.FactoryActionTypes.SetAllFactories:
            return handleSetAllFactories(state, action);
        case factoryActions.FactoryActionTypes.LoadUserFactories:
            return handleLoadUserFactories(state, action);
        case factoryActions.FactoryActionTypes.SetUserFactories:
            return handleSetUserFactories(state, action);
        case factoryActions.FactoryActionTypes.LoadCustomerFactories:
            return handleLoadCustomerFactories(state, action);
        case factoryActions.FactoryActionTypes.SetCustomerFactories:
            return handleSetCustomerFactories(state, action);
        case factoryActions.FactoryActionTypes.SetCustomerFactoriesFromAll:
            return handleSetCustomerFactoriesFromAll(state, action);
        default:
            return state;
    }
}
exports.factoryReducer = factoryReducer;
function handleLoadAllFactories(state, action) {
    return state;
}
function handleSetAllFactories(state, action) {
    // due to loading all factories is executed only once (on APP_INITIALIZER), we are assigning "customerFactories" to default "allFactories" array
    return Object.assign({}, state, { allFactories: action.payload, customerFactories: action.payload });
}
function handleLoadUserFactories(state, action) {
    return state;
}
function handleSetUserFactories(state, action) {
    return Object.assign({}, state, { userFactories: action.payload });
}
function handleLoadCustomerFactories(state, action) {
    return state;
}
function handleSetCustomerFactories(state, action) {
    // user has selected linked customer:
    // there are linked factories to selected customers, so as a result, we are assigning just received "customerFactories" array
    return Object.assign({}, state, { customerFactories: action.payload });
}
function handleSetCustomerFactoriesFromAll(state, action) {
    // user has selected linked customer:
    // there are NO linked factories to selected customers, so as a result, we are assigning "customerFactories" to default "allFactories" array
    return Object.assign({}, state, { customerFactories: state.allFactories });
}
