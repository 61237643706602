  <dx-data-grid #dataGrid
              [id]="gridAlias"
              [dataSource]="data"
              [elementAttr]="{ class: disabled ? 'dx-datagrid disabled-overlay' : 'dx-datagrid'}"
              [masterDetail]="{enabled: enableMasterDetail, template: 'rowDetail'}"
              [remoteOperations]="remoteOperations"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [rowAlternationEnabled]="true"
              [showColumnHeaders]="!hideColumnHeaders"
              [keyExpr]="remoteOperations ? '' : keyExpression"
              [hoverStateEnabled]="hoverStateEnabled"
              [width]="width"
              [height]="height"
              [scrolling]="scrolling"
              [selection]="selection"
              [showBorders]="showBorders"
              [showColumnLines]="false"
              [wordWrapEnabled]="wordWrapEnabled"
              [noDataText]="noDataTranslationTerm | translate"
              [focusedRowEnabled]="focusRowEnabled"
              [focusedRowKey]="focusedRowKeyForDataGrid"
              (onSelectionChanged)="gridSelectionChanged($event)"
              (onContentReady)="gridContentReady($event)"
              (onRowPrepared)="gridRowPrepared($event)"
              (keyup.enter)="enterKeyUp()"
              (keyup.escape)="escapeKeyUp()"
              (onFocusedRowChanged)="focusedRowChanged($event)">
  <dxo-state-storing *ngIf="enableStateStoring && sessionHelperService.isAuthenticated" [enabled]="true" type="custom" [customLoad]="loadGridStateFunc" [customSave]="saveGridStateFunc"></dxo-state-storing>
  <dxo-state-storing *ngIf="enableStateStoring && !sessionHelperService.isAuthenticated" [enabled]="true" type="localStorage" [storageKey]="gridAlias"></dxo-state-storing>
  <dxo-paging [enabled]="pagination.enabled" [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="pagination.enabled"
             [allowedPageSizes]="[10, 20, 40, 80]"
             [showInfo]="pagination.enabled"
             [infoText]="formatPagerTranslation('dataGrid.pager' | translate)"
             [visible]="pagination.enabled || columnChooser"></dxo-pager>
  <dxi-column type="detailExpand" cellTemplate="detailCellTemplate" [fixed]="true" fixedPosition="left" [allowReordering]="false"></dxi-column>
  <div *dxTemplate="let data of 'detailCellTemplate'">
    <ng-container *ngIf="data.rowType === 'data' && data.data.hasDetails">
      <div [className]="data.row.isExpanded ? 'dx-datagrid-group-opened' : 'dx-datagrid-group-closed'" (click)="onExpandClick($event, data)">
      </div>
    </ng-container>
  </div>
  <ng-container *ngFor="let column of gridStructure?.columnsConfigurations">
    <ng-container *ngIf="column.visible">
      <dxi-column [dataField]="column.dataField"
                  [caption]="column.displayName"
                  [alignment]="column.style?.alignment"
                  [width]="column.width"
                  [cellTemplate]="column.cellTemplate ? column.cellTemplate : cellTemplateHighlights"
                  [format]="column.format"
                  cssClass="align-vertical-middle"
                  [fixed]="column.fixed"
                  [fixedPosition]="column.fixedPosition"
                  [allowReordering]="!column.fixed"
                  [allowSorting]="!column.calculated && (column.dataType !== propertyTypes.typeUndefined)"></dxi-column>
    </ng-container>
  </ng-container>

  <div *dxTemplate="let item of GRID_TEMPLATES.customTemplate">
    <ng-template [ngTemplateOutlet]="templates[item.column.dataField] || defaultTemplate"
                 [ngTemplateOutletContext]="{item: item}"></ng-template>
  </div>

  <div *dxTemplate="let item of 'dateTemplate'">
    <ng-container *ngIf="valueIsDateType(item.data, item.column.dataField); else simpleValue">
      {{ getDataPropertyValue(item.data, item.column.dataField) | date: 'short' }}
    </ng-container>
    <ng-template #simpleValue>
      {{ getDataPropertyValue(item.data, item.column.dataField) }}
    </ng-template>
  </div>

  <div *dxTemplate="let entry of 'rowDetail'">
    <ng-template [ngTemplateOutlet]="masterDetail"
                 [ngTemplateOutletContext]="{item: entry}"></ng-template>
  </div>
</dx-data-grid>

<div *ngIf="columnChooser" class="table-settings-container mt-2" #columnSelectorContainer>
  <dx-button class="action-icon-button tertiary-button" (onClick)="openSettings()" [hint]="'columnChooser.columnSelection' | translate">
    <div class="button-container">
      <img class="action-icon" src=".\assets\img\columnChooser.svg">
    </div>
  </dx-button>
</div>

<ng-template #defaultTemplate let-item="item">{{item}}</ng-template>

<ng-container *ngIf="displaySettings">
  <td-grid-column-chooser [displayColumnChooser]="displaySettings"
                          [mainTable]="mainTable"
                          [gridStructure]="gridStructure"
                          (refreshGridData)="refreshData()"
                          (dialogClosed)="columnChooserClosed()"></td-grid-column-chooser>
</ng-container>
