"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
/**
 * Prefixes all requests with `environment.serverUrl`.
 */
var ApiPrefixInterceptor = /** @class */ (function () {
    function ApiPrefixInterceptor() {
    }
    ApiPrefixInterceptor.prototype.intercept = function (request, next) {
        if (!/^(http|https):/i.test(request.url)) {
            request = request.clone({ url: api_hub_constants_1.API_HUB_PATHS.serverUrl + request.url });
        }
        return next.handle(request);
    };
    return ApiPrefixInterceptor;
}());
exports.ApiPrefixInterceptor = ApiPrefixInterceptor;
