"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var operation_data_helper_service_1 = require("./operation-data-helper.service");
var HttpOperationDataService = /** @class */ (function () {
    function HttpOperationDataService(http, operationDataHelperService) {
        this.http = http;
        this.operationDataHelperService = operationDataHelperService;
    }
    HttpOperationDataService.prototype.operationsUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.operationsCtrl;
    };
    HttpOperationDataService.prototype.getOperationData = function (operationKeyId, customerKeyId) {
        var _this = this;
        return this.http.post(this.operationsUrl() + "(" + operationKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.details, customerKeyId).pipe(operators_1.map(function (response) { return (_this.operationDataHelperService.convertOperationDataToViewModel(response)); }));
    };
    HttpOperationDataService.prototype.addUnit = function (operationKeyId, unitKeyId) {
        return this.http.post(this.operationsUrl() + "(" + operationKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.addUnit + "(" + unitKeyId + ")", null);
    };
    HttpOperationDataService.prototype.removeUnit = function (operationKeyId, unitKeyId) {
        return this.http.post(this.operationsUrl() + "(" + operationKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.removeUnit + "(" + unitKeyId + ")", null);
    };
    HttpOperationDataService.prototype.startOperation = function (operationKeyId) {
        return this.http.post(this.operationsUrl() + "(" + operationKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.startOperation, null);
    };
    HttpOperationDataService.prototype.stopOperation = function (operationKeyId, cancelOrders) {
        return this.http.post(this.operationsUrl() + "(" + operationKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.stopOperation + "?cancelOrders=" + cancelOrders, null);
    };
    HttpOperationDataService.prototype.getCreateOperationOrderInfo = function (operationKeyId, customerKeyId) {
        return this.http.post(this.operationsUrl() + "(" + operationKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.getCreateOperationOrderInfo, customerKeyId);
    };
    HttpOperationDataService.prototype.getOperationCustomer = function (operationKeyId) {
        return this.http.get(this.operationsUrl() + "(" + operationKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.customerKeyId);
    };
    return HttpOperationDataService;
}());
exports.HttpOperationDataService = HttpOperationDataService;
