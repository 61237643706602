export interface TdPopupButtonInterface {
  text: string;
  type: TdPopupButtonType;
  callback?: () => void;
}

export enum TdPopupButtonType {
  Success = 0,
  Danger = 1,
  Secondary = 2,
  Tertiary = 3
}
