"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var grid_column_helper_service_1 = require("@shared/services/grid-column-helper.service");
var web_order_model_1 = require("@core/data-layer/order/models/web-order.model");
var core_2 = require("@ngx-translate/core");
var order_manager_service_1 = require("@shared/services/order-manager.service");
var grid_structure_interface_1 = require("@core/table-info/models/grid-structure.interface");
var utility_service_1 = require("@core/utility/utility.service");
var td_data_grid_component_1 = require("../td-data-grid/td-data-grid.component");
var TdOrderCartComponent = /** @class */ (function () {
    function TdOrderCartComponent(orderService, router, activatedRoute, sessionHelperService, gridColumnHelperService, translateService, orderManagerService) {
        this.orderService = orderService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.sessionHelperService = sessionHelperService;
        this.gridColumnHelperService = gridColumnHelperService;
        this.translateService = translateService;
        this.orderManagerService = orderManagerService;
        this.orderCartMainTable = td_constants_1.TD_MODEL_NAMES.webOrderLineModel;
        this.gridIdentifiers = td_constants_1.TD_DESKTOP_IDENTIFIERS;
        this.maxQuantityValue = td_constants_1.TD_MAX_INT32_VALUE;
        this.tableTypes = td_enumerations_1.TableTypes;
        this.expanded = true;
        this.collapseCartPixelWidth = 1000;
        this.orderCartGridStructure = {
            tables: [{
                    tableName: this.orderCartMainTable,
                    displayName: this.translateService.instant('tables.' + this.orderCartMainTable),
                    columns: []
                }],
            columnsConfigurations: []
        };
    }
    TdOrderCartComponent.prototype.ngOnInit = function () {
        this.getSelectedArticleInfo();
        this.adjustCustomColumnsInGridTables();
        this.expanded = window.innerWidth > this.collapseCartPixelWidth;
    };
    TdOrderCartComponent.prototype.ngOnDestroy = function () {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    };
    TdOrderCartComponent.prototype.getSelectedArticleInfo = function () {
        var _this = this;
        this.routeSubscription = this.router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; }))
            .pipe(operators_1.map(function () { return _this.activatedRoute.root.firstChild; }), operators_1.switchMap(function (firstChild) {
            if (firstChild && firstChild.firstChild) {
                return firstChild.firstChild.paramMap.pipe(operators_1.map(function (paramMap) { return paramMap.get('id'); }));
            }
            else {
                return rxjs_1.of(null);
            }
        })).subscribe(function (id) {
            // remember item/product key id
            _this.articleKeyId = +id;
            // remember item/product type
            if (_this.router.url.includes('/items')) {
                _this.articleType = td_enumerations_1.TableTypes.Item;
            }
            else if (_this.router.url.includes('/products')) {
                _this.articleType = td_enumerations_1.TableTypes.Product;
            }
            // repaint grid required to show highlight for selected item/product
            if (_this.sessionHelperService.urlAllowsOrderCart(_this.router.url)) {
                _this.sessionHelperService.reloadCustomerOpenOrders();
            }
            if (_this.orderCartGrid) {
                _this.orderCartGrid.grid.instance.repaint();
            }
        });
    };
    TdOrderCartComponent.prototype.setGridOptions = function () {
        if (this.sessionHelperService.userLastOpenOrder && this.sessionHelperService.userLastOpenOrder.lines.length > 0) {
            var borderHeight = 1;
            var paddingBottomTop = 8;
            this.gridOptions = this.gridColumnHelperService.getGridOptions(document.documentElement.clientHeight, this.sessionHelperService.userLastOpenOrder.lines.length, ['headerNavBar', 'cartIconArea', 'orderNumberArea', 'viewOrderBtnArea'], 2 * paddingBottomTop + 2 * borderHeight);
        }
    };
    TdOrderCartComponent.prototype.gridContentReady = function () {
        var _this = this;
        // we are using timeout to fix issue: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        setTimeout(function () { return _this.setGridOptions(); }, 0);
    };
    TdOrderCartComponent.prototype.gridRowPrepared = function (e) {
        var nameOfWebOrderLine = utility_service_1.nameOf();
        if (e.data && (e.data[nameOfWebOrderLine('oLnRefKeyId')] === this.articleKeyId) && (e.data[nameOfWebOrderLine('oLnRefType')] === this.articleType)) {
            e.rowElement.classList.add('highlightDataGridRow');
        }
    };
    TdOrderCartComponent.prototype.lastOpenOrderKeyId = function () {
        return this.sessionHelperService.userLastOpenOrder ? this.sessionHelperService.userLastOpenOrder.details.keyId : 0;
    };
    TdOrderCartComponent.prototype.adjustCustomColumnsInGridTables = function () {
        // add custom "article" column
        var articleColumn = {
            dataField: td_constants_1.TD_ACTION_FIELDS.article,
            dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
            visible: true,
            displayName: this.translateService.instant('calcColumns.article'),
        };
        this.gridColumnHelperService.addCustomColumn(this.orderCartGridStructure.columnsConfigurations, articleColumn);
        // add custom "quantity" column
        var quantityColumn = {
            dataField: td_constants_1.TD_ACTION_FIELDS.quantity,
            dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
            visible: true,
            width: '70',
            displayName: this.translateService.instant('calcColumns.qty'),
            style: {
                alignment: 'center'
            }
        };
        this.gridColumnHelperService.addCustomColumn(this.orderCartGridStructure.columnsConfigurations, quantityColumn);
        // add custom "delete" column
        var deleteColumn = {
            dataField: td_constants_1.TD_ACTION_FIELDS.delete,
            dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
            visible: true,
            width: '30',
            displayName: '',
            style: {
                alignment: 'center',
            }
        };
        this.gridColumnHelperService.addCustomColumn(this.orderCartGridStructure.columnsConfigurations, deleteColumn);
    };
    TdOrderCartComponent.prototype.selectedOrderDisplayValue = function (order) {
        return order ? "" + order.orgRefNo : null;
    };
    TdOrderCartComponent.prototype.selectedOrderChanged = function (e) {
        this.sessionHelperService.reloadLastOpenOrder(e.value);
    };
    TdOrderCartComponent.prototype.createNewOrder = function () {
        var _this = this;
        var newOrder = this.orderManagerService.initializeNewOrder();
        this.orderService.saveOrder(newOrder).subscribe(function (response) {
            _this.sessionHelperService.reloadCustomerOpenOrders();
            _this.sessionHelperService.reloadLastOpenOrder(response.value);
        });
    };
    TdOrderCartComponent.prototype.toggleExpandCart = function () {
        this.expanded = !this.expanded;
    };
    TdOrderCartComponent.prototype.saveOrderLine = function (orderLine) {
        var _this = this;
        this.orderService.saveOrderLines(this.lastOpenOrderKeyId(), [orderLine]).subscribe(function (response) {
            _this.sessionHelperService.reloadLastOpenOrder(_this.lastOpenOrderKeyId());
        });
    };
    TdOrderCartComponent.prototype.updateLine = function (e, orderLine) {
        if (e.previousValue) {
            if (this.orderManagerService.updateLineQuantity(e.value, orderLine)) {
                this.saveOrderLine(orderLine);
            }
            else {
                e.component.option('value', e.previousValue);
            }
        }
    };
    TdOrderCartComponent.prototype.deleteLine = function (orderLine) {
        orderLine.lineState = web_order_model_1.OrderLineState.Deleted;
        this.saveOrderLine(orderLine);
    };
    TdOrderCartComponent.prototype.onResize = function () {
        this.expanded = window.innerWidth > this.collapseCartPixelWidth;
        if (this.expanded) {
            this.setGridOptions();
        }
    };
    return TdOrderCartComponent;
}());
exports.TdOrderCartComponent = TdOrderCartComponent;
