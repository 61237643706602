import { TemplateRef } from '@angular/core';
import { TdPopupButtonInterface } from './td-popup-button.interface';

export enum PopupType {
  Information = 0,
  Confirmation = 1,
  Error = 2,
  Template = 3,
  CustomWithButtons = 4
}

export class TdPopupModel {

  private result: boolean;
  private resolve: (value?: unknown) => void;

  public visible: boolean;
  public title: string;
  public message: string;
  public template: TemplateRef<any>;
  public type: PopupType;
  public response: Promise<boolean>;
  public titleIconPath: string;
  public customButtons: TdPopupButtonInterface[];
  public iconType: PopupType;

  public comfirm() {
    this.result = true;
  }
  public cancel() {
    this.result = false;
  }
  public resolvePopup() {
    this.resolve(this.result);
  }

  constructor(
    visible: boolean = false, 
    title: string = '', 
    message: string = '', 
    template: TemplateRef<any> = null, 
    type: PopupType = PopupType.Information, 
    titleIconPath: string = null,
    buttons: TdPopupButtonInterface[] = null,
    iconType: PopupType = null) {
    this.visible = visible;
    this.title = title;
    this.message = message;
    this.template = template;
    this.type = type;
    this.titleIconPath = titleIconPath;
    this.customButtons = buttons;
    this.iconType = iconType;

    this.response = new Promise<boolean>(resolve => {
      this.resolve = resolve;
    });
  }

}
