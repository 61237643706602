"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./shared/components/td-order-cart/td-order-cart.component.ngfactory");
var i3 = require("./shared/components/td-order-cart/td-order-cart.component");
var i4 = require("@angular/router");
var i5 = require("./shared/services/session-helper.service");
var i6 = require("./shared/services/grid-column-helper.service");
var i7 = require("@ngx-translate/core");
var i8 = require("./shared/services/order-manager.service");
var i9 = require("./shared/components/td-popup/td-popup.component.ngfactory");
var i10 = require("./shared/components/td-popup/td-popup.component");
var i11 = require("./shared/components/td-popup/services/td-popup.service");
var i12 = require("./header/header.component.ngfactory");
var i13 = require("./header/header.component");
var i14 = require("angular-user-idle");
var i15 = require("@ngrx/store");
var i16 = require("@angular/common");
var i17 = require("./shared/components/td-load-panel/td-load-panel.component.ngfactory");
var i18 = require("./shared/components/td-load-panel/td-load-panel.component");
var i19 = require("./shared/components/td-load-panel/services/td-load-panel.service");
var i20 = require("./app.component");
var i21 = require("./core/media/services/preload-media.service");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td-order-cart", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TdOrderCartComponent_0, i2.RenderType_TdOrderCartComponent)), i1.ɵdid(2, 245760, null, 0, i3.TdOrderCartComponent, ["ORDER_SERVICE", i4.Router, i4.ActivatedRoute, i5.SessionHelperService, i6.GridColumnHelperService, i7.TranslateService, i8.OrderManagerService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.allowOrderCart ? "block" : "none"); _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-popup", [], null, null, null, i9.View_TdPopupComponent_0, i9.RenderType_TdPopupComponent)), i1.ɵdid(1, 49152, null, 0, i10.TdPopupComponent, [i11.TdPopupService], null, null)], null, null); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-header", [], null, null, null, i12.View_HeaderComponent_0, i12.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i13.HeaderComponent, ["AUTH_SERVICE", "LICENSE_BINDING_SERVICE", "MEDIA_SERVICE", i5.SessionHelperService, i4.Router, i4.ActivatedRoute, i14.UserIdleService, i7.TranslateService, i11.TdPopupService, i15.Store], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "container-fluid main-page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "row h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(8, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(10, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td-load-panel", [], null, null, null, i17.View_TdLoadPanelComponent_0, i17.RenderType_TdLoadPanelComponent)), i1.ɵdid(12, 49152, null, 0, i18.TdLoadPanelComponent, [i19.TdLoadPanelService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_0 = _co.sessionHelperService.canViewOrder(); _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.displayPopup; _ck(_v, 10, 0, currVal_1); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i20.AppComponent, ["AUTH_SERVICE", "LICENSE_BINDING_SERVICE", i5.SessionHelperService, i4.Router, i15.Store, i14.UserIdleService, i11.TdPopupService, i1.ChangeDetectorRef, i21.PreloadMediaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("td-root", i20.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
