import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { ArticleBaseInfoInterface } from '@shared/models/article-base-info.interface';
import { WebOrderViewModel, WebOrderLineViewModel } from '@core/data-layer/order/models/web-order-view.model';
import { SessionHelperService } from './session-helper.service';
import { TD_MAX_INT32_VALUE, NO_ERROR_CODE } from '@core/data-layer/shared/models/td.constants';
import { OrderLineInfoArgsModel } from '@core/data-layer/order/models/order-line-info-args.model';
import { OrderServiceInterface } from '@core/data-layer/order/services/order.service.interface';
import { OrderLineState, WebOrderState } from '@core/data-layer/order/models/web-order.model';
import { TdPopupService } from '../components/td-popup/services/td-popup.service';
import { TdLoadPanelService } from '../components/td-load-panel/services/td-load-panel.service';

@Injectable({
  providedIn: 'root'
})
export class OrderManagerService {

  constructor(
    @Inject('ORDER_SERVICE') private orderService: OrderServiceInterface,
    private sessionHelperService: SessionHelperService,
    private popupService: TdPopupService,
    private loadPanelService: TdLoadPanelService,
    private router: Router
  ) { }

  updateLineQuantity(quantity: number, orderLine: WebOrderLineViewModel): boolean {
    const ordered = quantity * orderLine.oLnOrgStdCount;
    if (this.sessionHelperService.quantityIsValid(ordered, TD_MAX_INT32_VALUE / orderLine.oLnOrgStdCount)) {
      orderLine.oLnOrgUnitCount = quantity;
      orderLine.oLnOrgOrdered = ordered;
      if (orderLine.lineState !== OrderLineState.New) {
        orderLine.lineState = OrderLineState.Edited;
      }
      return true;
    }
    else {
      return false;
    }
  }

  addLineIntoOrder(
    articleInfo: ArticleBaseInfoInterface,
    order: WebOrderViewModel,
    factoryId: number,
    onLineAdded: (newLine?: WebOrderLineViewModel) => void
  ) {
    if (!this.loadPanelService.showLoadPanel()) {
      const orderLine = order.lines.find(entry => ((entry.oLnRefType === articleInfo.refType) && (entry.oLnRefKeyId === articleInfo.refKeyId)));
      // update existing order line
      if (orderLine) {
        const newLine = orderLine.lineState === OrderLineState.Deleted;
        if (this.updateLineQuantity(orderLine.oLnOrgUnitCount + articleInfo.quantity, orderLine)) {
          onLineAdded(newLine ? orderLine : null);
        }
      }
      // insert new order line
      else {
        const ordLineInfoArgs: OrderLineInfoArgsModel = {
          refType: articleInfo.refType,
          refKeyId: articleInfo.refKeyId,
          quantity: articleInfo.quantity,
          customerKeyId: this.sessionHelperService.currentCustomer.keyId,
          customerChargeOptions: this.sessionHelperService.currentCustomer.chargeOptions,
          supplierFactoryKeyId: factoryId
        };
        this.orderService.getOrderLine(ordLineInfoArgs).subscribe(response => {
          if (response.error === NO_ERROR_CODE) {
            const ordLine = response.value;
            ordLine.lineState = OrderLineState.New;
            // get max order line number
            let maxLineNo = 0;
            order.lines.forEach(entry => {
              if (entry.oLnLine > maxLineNo) {
                maxLineNo = entry.oLnLine;
              }
            });
            // set next order line number
            ordLine.oLnLine = maxLineNo + 1;
            order.lines.push(ordLine);
            onLineAdded(ordLine);
          }
          else {
            // there occurred an error while trying to get order line details
            this.popupService.showInfo(response.message);
          }
        });
      }
    }
  }

  initializeNewOrder(operationKeyId?: number, custStokKeyId?: number): WebOrderViewModel {
    const order = new WebOrderViewModel();
    order.state = WebOrderState.New;
    order.details.orgRefCustKeyId = this.sessionHelperService.currentCustomer.keyId;
    order.details.suppRefFacKeyId = this.sessionHelperService.customerFactories[0].keyId;
    if (operationKeyId) {
      order.details.refOpDKeyId = operationKeyId;
      order.details.orgStokKeyId = custStokKeyId ? custStokKeyId : 0;
    }
    return order;
  }

  createOrderFromTemplate(keyId: number) {
    this.orderService.createOrderFromTemplate(keyId, this.sessionHelperService.currentFactoryKeyId, this.sessionHelperService.currentCustomer.keyId).subscribe(response => {
      if (response.error === NO_ERROR_CODE) {
        // order created successfully
        this.router.navigate(['/orders/' + response.value]);
      }
      else {
        // there occurred an error while trying to create order from template
        this.popupService.showInfo(response.message);
      }
    });
  }
}
