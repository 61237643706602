"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomerActionTypes;
(function (CustomerActionTypes) {
    CustomerActionTypes["LoadUserCustomers"] = "[Customer] Load User Customers";
    CustomerActionTypes["SetUserCustomers"] = "[Customer] Set User Customers";
    CustomerActionTypes["SetCurrentCustomer"] = "[Customer] Set Current Customer";
    CustomerActionTypes["LoadSiteCustomers"] = "[Customer] Load Site Customers";
    CustomerActionTypes["SetSiteCustomers"] = "[Customer] Set Site Customers";
})(CustomerActionTypes = exports.CustomerActionTypes || (exports.CustomerActionTypes = {}));
var LoadUserCustomers = /** @class */ (function () {
    function LoadUserCustomers() {
        this.type = CustomerActionTypes.LoadUserCustomers;
    }
    return LoadUserCustomers;
}());
exports.LoadUserCustomers = LoadUserCustomers;
var SetUserCustomers = /** @class */ (function () {
    function SetUserCustomers(payload) {
        this.payload = payload;
        this.type = CustomerActionTypes.SetUserCustomers;
    }
    return SetUserCustomers;
}());
exports.SetUserCustomers = SetUserCustomers;
var SetCurrentCustomer = /** @class */ (function () {
    function SetCurrentCustomer(payload) {
        this.payload = payload;
        this.type = CustomerActionTypes.SetCurrentCustomer;
    }
    return SetCurrentCustomer;
}());
exports.SetCurrentCustomer = SetCurrentCustomer;
var LoadSiteCustomers = /** @class */ (function () {
    // payload = cuSiteKeyId
    function LoadSiteCustomers(payload) {
        this.payload = payload;
        this.type = CustomerActionTypes.LoadSiteCustomers;
    }
    return LoadSiteCustomers;
}());
exports.LoadSiteCustomers = LoadSiteCustomers;
var SetSiteCustomers = /** @class */ (function () {
    function SetSiteCustomers(payload) {
        this.payload = payload;
        this.type = CustomerActionTypes.SetSiteCustomers;
    }
    return SetSiteCustomers;
}());
exports.SetSiteCustomers = SetSiteCustomers;
