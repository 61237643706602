<div class="row text-danger font-weight-bold">
  {{ 'settings.changesAffectUsers' | translate }}
</div>
<div class="d-flex flex-row setting-title">
  <div class="d-flex pr-2">
    {{ 'settings.loginOptions' | translate }}
  </div>
  <div class="d-flex flex-fill line-middle"></div>
</div>
<div class="row">
  <div class="col">
    <div class="py-1" [ngClass]="{'disabled': verificationDisabled}">
      {{ 'settings.passwordVerification' | translate }}
    </div>
    <dx-radio-group
      id="passwordVerificationSetting"
      valueExpr="key"
      displayExpr="value"
      [items]="passwordVerificationMethods"
      [(value)]="passVerification"
      layout="horizontal"
      [disabled]="verificationDisabled"
      (onValueChanged)="passwordVerificationMethodChanged($event.value)">
    </dx-radio-group>
  </div>
</div>
<div class="row pt-1">
  <dx-check-box
    id="allowBlankPasswordsSetting"
    [text]="'settings.allowBlankPasswords' | translate"
    [(value)]="serverOptions.loginAllowBlankPasswords"
    [disabled]="blankPasswordsDisabled"
    (onValueChanged)="setChanged()">
  </dx-check-box>
</div>
<div class="d-flex flex-row setting-title" *ngIf="sessionHelperService.license?.fastTrackBasic">
  <div class="d-flex pr-2">
    {{ 'settings.fastTracking' | translate }}
  </div>
  <div class="d-flex flex-fill line-middle"></div>
</div>
<div class="row" *ngIf="sessionHelperService.license?.fastTrackBasic">
  <div class="col">
    <div class="py-1">
      {{ 'settings.allowFastTracking' | translate }}
    </div>
    <dx-select-box
      id="allowFastTrackingSetting"
      valueExpr="key"
      displayExpr="value"
      [(value)]="serverOptions.defaultFTCoKeyId"
      (onValueChanged)="setChanged()"
      [dataSource]="factTrackCodes$ | async"
      [width]="300">
    </dx-select-box>
  </div>
</div>
<div class="d-flex flex-row setting-title" *ngIf="sessionHelperService.license?.operation">
  <div class="d-flex pr-2">
    {{ 'settings.operationData' | translate }}
  </div>
  <div class="d-flex flex-fill line-middle"></div>
</div>
<div class="row pt-1" *ngIf="sessionHelperService.license?.operation">
  <dx-check-box
    id="allowStartAndStop"
    [text]="'settings.allowStartAndStop' | translate"
    [value]="serverOptions.startStopOperationMode === WebStartStopOperationMode.Allow"
    (onValueChanged)="allowStartAndStopChanged($event.value)">
  </dx-check-box>
</div>
<div class="row pt-1" *ngIf="sessionHelperService.license?.operation">
  <dx-check-box
    id="allowEditingArticles"
    [text]="'settings.allowEditingArticles' | translate"
    [value]="serverOptions.editOprationArticlesMode === WebEditOprationArticlesMode.AllowEdit"
    (onValueChanged)="allowEditingArticlesChanged($event.value)">
  </dx-check-box>
</div>
<div class="d-flex flex-row setting-title" *ngIf="sessionHelperService.license?.stockBasic">
  <div class="d-flex pr-2">
    {{ 'settings.ordering' | translate }}
  </div>
  <div class="d-flex flex-fill line-middle"></div>
</div>
<div class="row pt-1" *ngIf="sessionHelperService.license?.stockBasic">
  <dx-check-box
    id="allowOrderingSetting"
    [text]="'settings.allowOrdering' | translate"
    [value]="serverOptions.orderMode === WebOrderMode.On"
    (onValueChanged)="allowOrderingChanged($event.value)">
  </dx-check-box>
</div>
<div class="row pt-1" *ngIf="sessionHelperService.license?.stockBasic">
  <dx-check-box
    id="previewOrderBeforeSendSetting"
    [text]="'settings.previewOrderBeforeSend' | translate"
    [(value)]="serverOptions.previewOrderBeforeSending"
    (onValueChanged)="setChanged()">
  </dx-check-box>
</div>
<div class="d-flex flex-row setting-title">
  <div class="d-flex pr-2">
    {{ 'settings.display' | translate }}
  </div>
  <div class="d-flex flex-fill line-middle"></div>
</div>
<div class="row pt-1">
  <dx-check-box
    id="displayPriceModeSetting"
    [text]="'settings.prices' | translate"
    [value]="serverOptions.displayPriceMode === WebDisplayPriceMode.ShowAll"
    (onValueChanged)="displayPricesChanged($event.value)">
  </dx-check-box>
</div>
<div class="row pt-1">
  <dx-check-box
    id="displayGeneralProductsSetting"
    [text]="'settings.generalProducts' | translate"
    [value]="serverOptions.displayGeneralProductsMode === WebDisplayGeneralProductsMode.Allowed"
    (onValueChanged)="displayGeneralProductsChanged($event.value)">
  </dx-check-box>
</div>
<div class="row">
  <div class="col">
    <div class="py-1">
      {{ 'settings.custOrSiteSpecific' | translate }}
    </div>
    <dx-select-box
      id="displayCustomerProductsSetting"
      valueExpr="key"
      displayExpr="value"
      [(value)]="serverOptions.displayCustomerProductsMode"
      (onValueChanged)="setChanged()"
      [dataSource]="displayCustomerProducts"
      [width]="300">
    </dx-select-box>
  </div>
</div>
<div class="d-flex flex-row setting-title" *ngIf="sessionHelperService.productionOverviewLicense()">
  <div class="d-flex pr-2">
    {{ 'settings.poAvailability' | translate }}
  </div>
  <div class="d-flex flex-fill line-middle"></div>
</div>
<div class="row pt-1" *ngIf="sessionHelperService.productionOverviewLicense()">
  <dx-check-box
    id="availableInWebSetting"
    [text]="'settings.availableInWeb' | translate"
    [value]="serverOptions.prodOverviewVisibilityMode === ProdOverviewVisibilityMode.AvailableInWeb"
    (onValueChanged)="availableInWebChanged($event.value)">
  </dx-check-box>
</div>
