"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var devextreme_angular_1 = require("devextreme-angular");
var td_popup_service_1 = require("./services/td-popup.service");
var td_popup_model_1 = require("./models/td-popup.model");
var td_popup_button_interface_1 = require("./models/td-popup-button.interface");
var TdPopupComponent = /** @class */ (function () {
    function TdPopupComponent(popupService) {
        var _this = this;
        this.popupService = popupService;
        this.PopupType = td_popup_model_1.PopupType;
        this.PopupButtonType = td_popup_button_interface_1.TdPopupButtonType;
        this.displayPopup = false;
        this.closeOnOutsideClick = false;
        this.popupIconPath = this.determinePopupIcon(this.popupService.popupParams);
        this.popupService.displayPopup.subscribe(function (change) {
            _this.displayPopup = change;
        });
    }
    TdPopupComponent.prototype.popupContentReady = function () {
        var _this = this;
        setTimeout(function () {
            _this.closeOnOutsideClick = true;
        }, 0);
        var searchGridDropdown = document.getElementById('strictArticlesSearchDropDownGrid');
        if (searchGridDropdown) {
            var inputElement_1 = searchGridDropdown.getElementsByClassName('dx-texteditor-input').item(0);
            setTimeout(function () {
                inputElement_1.focus();
            }, 1000);
        }
    };
    TdPopupComponent.prototype.determinePopupIcon = function (popupParams) {
        if (popupParams.type === td_popup_model_1.PopupType.CustomWithButtons) {
            return this.getImageUrl(popupParams.iconType);
        }
        else {
            return this.getImageUrl(popupParams.type);
        }
    };
    TdPopupComponent.prototype.getImageUrl = function (popupType) {
        switch (popupType) {
            case td_popup_model_1.PopupType.Information:
                return './assets/img/Information.svg';
            case td_popup_model_1.PopupType.Confirmation:
                return './assets/img/Warning Orange.svg';
            case td_popup_model_1.PopupType.Error:
                return './assets/img/Error.svg';
        }
    };
    return TdPopupComponent;
}());
exports.TdPopupComponent = TdPopupComponent;
