"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var HttpClientConfigurationService = /** @class */ (function () {
    function HttpClientConfigurationService(http) {
        this.http = http;
    }
    HttpClientConfigurationService.prototype.clientConfigurationUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.clientConfigurationCtrl;
    };
    HttpClientConfigurationService.prototype.getClientConfiguration = function () {
        return this.http.get(this.clientConfigurationUrl());
    };
    return HttpClientConfigurationService;
}());
exports.HttpClientConfigurationService = HttpClientConfigurationService;
