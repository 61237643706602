"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NO_ERROR_CODE = 0;
exports.TD_MAX_LEVEL_OF_NESTED_TABLES = 4;
exports.TD_QUERY_PARAMS = {
    type: 'type',
    text: 'text',
    returnUrl: 'returnUrl',
    error: 'error',
    state: 'state',
    missedModule: 'missedModule',
    dateFrom: 'dateFrom',
    dateTo: 'dateTo',
    orderNumberFrom: 'orderNumberFrom',
    orderNumberTo: 'orderNumberTo',
    tab: 'tab',
    onlyOwnCustomer: 'onlyOwnCustomer'
};
exports.TD_AUTH_ERRORS = {
    accessDenied: 'access_denied',
    noCust: 'no_cust'
};
exports.TD_ERROR_TYPES = {
    noAvailableLicense: 'noAvailableLicense',
    sessionEnded: 'sessionEnded'
};
exports.TD_MODULES = {
    operation: 'operation'
};
exports.TD_MODEL_NAMES = {
    // db table models
    orderModel: 'orderModel',
    userModel: 'userModel',
    factoryModel: 'factoryModel',
    productModel: 'productModel',
    itemModel: 'itemModel',
    compositeModel: 'compositeModel',
    unitModel: 'unitModel',
    processModel: 'processModel',
    serialModel: 'serialModel',
    locationModel: 'locationModel',
    supplierModel: 'supplierModel',
    tagContentModel: 'tagContentModel',
    stocksModel: 'stocksModel',
    operationDataModel: 'operationDataModel',
    textModel: 'textModel',
    orderTemplateModel: 'orderTemplateModel',
    eventModel: 'eventModel',
    stockModel: 'stockModel',
    externalDocumentInfoModel: 'externalDocumentInfoModel',
    priceGroupModel: 'priceGroupModel',
    // custom models
    webOrderLineModel: 'webOrderLineModel',
    webOrderReservLineModel: 'webOrderReservLineModel',
    orderTemplateArticleLineModel: 'orderTemplateArticleLineModel',
    substitutionArticleModel: 'substitutionArticleModel'
};
exports.TD_DYNAMIC_FIELDS = {
    // begin: "main search" columns
    pictureKeyId: 'PICTPICSKEYID',
    autoPictureKeyId: 'AUTOPICSKEYID',
    unitIsFastTracked: 'FTUNITFIELD',
    productKeepSerials: 'ProdKeepSeri',
    key: 'key',
    keyId: 'keyId',
    unit: 'unit',
    batch: 'batch',
    // end: "main search" columns
    // begin: "product locator" columns
    tagInfo: 'TAGINFO',
    expectedReady: 'EXPECTEDREADY',
    expectedBack: 'RPAEXPECTEDBACK',
    rpaSuppSupplier: 'RPASUPPSUPPLIER',
    rpaSuppName: 'RPASUPPNAME',
    fastTrackReason: 'FASTTRACKREASON',
    unitFastTracked: 'UNITFASTTRACKED',
    what: 'What',
    // end: "product locator" columns
    // begin: "operation details grids" columns
    unitList: 'UNITUNITLIST',
    olnOrgOrdered: 'OLNORGORDERED',
    olnOrgReceived: 'OLNORGRECEIVED',
    // end: "operation details grids" columns
    // begin: "external document" columns
    icon: 'icon',
    // end: "external document" columns
    // begin: operation list columns
    totalOrderCount: 'TotalOrdCount',
    ordersLeadStatus: 'OrdersLeadStatus',
    // end: operation list columns
    // begin: operation details remove unit columns
    select: 'select',
    // end: operation details remove unit columns
    // begin: substitutions
    altItemItem: 'altItemItem',
    // end: substitutions
    user: 'user'
};
// calculated fields that are using for grids
exports.TD_CALC_FIELDS = {
    // begin: "order list" columns
    time: 'time',
    status: 'status',
    orgStatusText: 'orgStatusText',
    suppStatusText: 'suppStatusText'
    // end: "order list" columns
};
exports.TD_SPECIAL_FIELDS = {
    created: 'created',
    createdUser: 'createdUser',
    createdKeyId: 'createdKeyId',
    modified: 'modified',
    modifiedUser: 'modifiedUser',
    modifiedKeyId: 'modifiedKeyId'
};
// custom action fields that are using for grids
exports.TD_ACTION_FIELDS = {
    copy: 'copy',
    delete: 'delete',
    add: 'add',
    create: 'create',
    article: 'article',
    quantity: 'quantity',
    media: 'media',
    fastTrack: 'fastTrack',
    expand: 'expand',
    select: 'select',
    articleState: 'articleState',
    resvState: 'resvState',
    hasDetails: 'hasDetails',
    orderStatus: 'orderStatus',
    removeUnit: 'removeUnit',
    substituteIcons: 'substituteIcons',
    manageSubstitutions: 'manageSubstitutions',
    addOrder: 'addOrder'
};
exports.TD_VALUES = {
    sqlTrue: 'T',
    sqlFalse: 'F',
    systemDefaultComment: 1,
    noTranslation: 'NO_TRANSLATION',
    systemEventAttr: 'SYSTEM=',
    detailsGridRowsPerPage: 10,
    pureGridRowsPerPage: 20,
    userFieldDefaultDisplayNamePrefix: '#user_field#'
};
exports.TD_PRICE_FIELD_NAMES = {
    price: 'price',
    pricing: 'pricing',
    priGrKeyId: 'prigrkeyid',
    priGr: 'prigr' // a link to the price group
};
// desktop identifiers that are using for saving, getting different components states
exports.TD_DESKTOP_IDENTIFIERS = {
    allOrdersListGrid: 'AllOrdersListGrid',
    openOrdersListGrid: 'OpenOrdersListGrid',
    sentOrdersListGrid: 'SentOrdersListGrid',
    deliveredOrdersListGrid: 'DeliveredOrdersListGrid',
    orderDetailsArticlesSearchGrid: 'OrderDetailsArticlesSearchGrid',
    openOrderDetailsArticlesGrid: 'OpenOrderDetailsArticlesGrid',
    sentOrderDetailsArticlesGrid: 'SentOrderDetailsArticlesGrid',
    deliveredOrderDetailsArticlesGrid: 'DeliveredOrderDetailsArticlesGrid',
    orderDetailsReservationsGrid: 'OrderDetailsReservationsGrid',
    productListGrid: 'ProductListGrid',
    productListReducedGrid: 'ProductListReducedGrid',
    endoscopeListGrid: 'EndoscopeListGrid',
    endoscopeListReducedGrid: 'EndoscopeListReducedGrid',
    itemListGrid: 'ItemListGrid',
    itemListReducedGrid: 'ItemListReducedGrid',
    unitListGrid: 'UnitListGrid',
    productSerialsSearchGrid: 'ProductSerialsSearchGrid',
    endoscopeSerialsSearchGrid: 'EndoscopeSerialsSearchGrid',
    orderCartGrid: 'OrderCartGrid',
    orderTemplateListGrid: 'OrderTemplateListGrid',
    orderTemplateArticlesGrid: 'OrderTemplateArticlesGrid',
    articleItemListGrid: 'ArticleItemListGrid',
    articleCompositeItemListGrid: 'ArticleCompositeItemListGrid',
    productSerialsGrid: 'ProductSerialsGrid',
    unitEventsGrid: 'UnitEventsGrid',
    itemProductListGrid: 'ItemProductListGrid',
    externalDocumentListGrid: 'ExternalDocumentListGrid',
    itemLocatorListGrid: 'ItemLocatorListGrid',
    productLocatorReadyListGrid: 'ProductLocatorReadyListGrid',
    productLocatorPackedListGrid: 'ProductLocatorPackedListGrid',
    productLocatorReturnedListGrid: 'ProductLocatorReturnedListGrid',
    productLocatorUsedListGrid: 'ProductLocatorUsedListGrid',
    productLocatorRepairListGrid: 'ProductLocatorRepairListGrid',
    productLocatorOtherListGrid: 'ProductLocatorOtherListGrid',
    scheduledOperationListGrid: 'ScheduledOperationListGrid',
    scheduledOperationsOrderListGrid: 'ScheduledOperationOrderListGrid',
    operationUnitSearchListGrid: 'OperationUnitSearchListGrid',
    operationItemListGrid: 'OperationItemListGrid',
    operationProductListGrid: 'OperationProductListGrid',
    operationEndoscopeListGrid: 'OperationEndoscopeListGrid',
    operationRemoveUnitListGrid: 'OperationRemoveUnitListGrid',
    substitutePopupGrid: 'SubstitutePopupGrid',
    alternativeItemSearchListGrid: 'AlternativeItemSearchListGrid',
    alternativeProductSearchListGrid: 'AlternativeProductSearchListGrid',
    alternativeEndoscopeSearchListGrid: 'AlternativeEndoscopeSearchListGrid',
    substitutionManageIdentical: 'SubstitutionManageIdenticalGrid',
    substitutionManageAlternative: 'SubstitutionManageAlternativeGrid'
};
exports.TD_DEFAULT_USER_IDLE_CONFIG = {
    idle: 600,
    timeout: 1,
    frequencyOfPing: 5 // how many times in "idle-time"
};
// System Media series
exports.TD_SYSTEM_MEDIA_SERIES = {
    None: 0,
    Normal: 1,
    Printing: 2,
    Service: 3
};
// Maximum int32 value
exports.TD_MAX_INT32_VALUE = 2147483647;
// Minimum SQL DateTime value
exports.TD_MIN_DATE_VALUE = new Date(-53690);
// Media constants
exports.TD_MEDIA_THUMBNAIL = 'mediaIdentifier=thumbnail';
exports.PIP_DUMMY_NUMBER = -666;
exports.PIP_DUMMY_DATE = new Date('01/01/1900');
exports.PIP_MASK_ASTERISKS = '******';
// Help articles
exports.TD_HELP_ARTICLES = [
    { name: 'WebIntroduction', articleId: 500, url: '' },
    // { name: 'AccessingTheTDOCWebServer', articleId: 501, url: '' },
    // { name: 'LoggingIn', articleId: 502, url: '' },
    // { name: 'loggingOut', articleId: 503, url: '' },
    { name: 'DataSearchesIntroduction', articleId: 504, url: 'search' },
    { name: 'QueryingAndViewingProducts', articleId: 505, url: 'products' },
    { name: 'QueryingAndViewingProducts', articleId: 505, url: 'products/:id' },
    { name: 'QueryingAndViewingItems', articleId: 506, url: 'items' },
    { name: 'QueryingAndViewingItems', articleId: 506, url: 'items/:id' },
    // { name: 'QueryingAndViewingSerializedItems', articleId: 507, url: '' },
    { name: 'QueryingAndViewingUnits', articleId: 508, url: 'units' },
    { name: 'QueryingAndViewingUnits', articleId: 508, url: 'units/:id' },
    // { name: 'ViewingHistory', articleId: 509, url: '' },
    // { name: 'ModifyingSearchResults', articleId: 510, url: '' },
    { name: 'ProcessingTheCurrentOrder', articleId: 511, url: 'orders/:id' },
    { name: 'ProcessingTheCurrentOrder', articleId: 511, url: 'order-templates' },
    { name: 'ViewingScheduledOperations', articleId: 512, url: 'operations' },
    // { name: 'OrderProcessingPrerequisites', articleId: 513, url: '' },
    // { name: 'OrderingItems', articleId: 514, url: '' },
    // { name: 'OrderingProducts', articleId: 515, url: '' },
    // { name: 'OrderingSerializedItems', articleId: 516, url: '' },
    // { name: 'OrderingUnits', articleId: 517, url: '' },
    // { name: 'SendingAnOrder', articleId: 518, url: '' },
    // { name: 'ProcessingCancellingOrders', articleId: 519, url: '' },
    // { name: 'ProcessingModifyingOrders', articleId: 520, url: '' },
    // { name: 'FastTrackingApplying', articleId: 521, url: '' },
    // { name: 'LntroducingWebAdministration', articleId: 522, url: '' },
    // { name: 'Prerequisites', articleId: 523, url: '' },
    // { name: 'InstallationAndINIFileSettings', articleId: 524, url: '' },
    // { name: 'AccountManagement', articleId: 525, url: '' },
    // { name: 'ServerManagement', articleId: 526, url: '' },
    // { name: 'FastTrackingViewing', articleId: 527, url: '' },
    // { name: 'OrderProcessingPreviewing', articleId: 528, url: '' },
    // { name: 'OrderProcessingUnderstanding', articleId: 529, url: '' },
    { name: 'IntroducingOrders', articleId: 530, url: 'orders' },
    // { name: 'IntroducingCreatingOrders', articleId: 531, url: '' },
    // { name: 'UserManagement', articleId: 532, url: '' },
    // { name: 'QueryingIntroduction', articleId: 533, url: '' },
    // { name: 'FastTrackingPrerequisites', articleId: 534, url: '' },
    { name: 'ProductionOverview', articleId: 535, url: 'production-overview' },
    { name: 'OperationDetails', articleId: 536, url: 'operations/:id' }
];
exports.TD_SYSTEM_USER_INITIALS = 'SYS';
