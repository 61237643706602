<div class="cell-center" *ngIf="picsKeyId" (mouseenter)="mediaHoverEnter()" (mouseleave)="mediaHoverExit()">
  <dx-button #gridButton class="grid-action-button" [attr.id]="buttonId"
             (onClick)="showMediaViewer()"
             icon=".\assets\img\media.svg">
  </dx-button>

  <dx-popover
      #popOver
      [target]="popOverTarget"
      position="right"
      [width]="140"
      [height]="110"
      [(visible)]="popOverVisible"
    >
    <div *dxTemplate="let data of 'content'" class="popover d-flex align-items-center justify-content-center">
      <ng-container *ngIf="popOverImg">
        <img [src]="popOverImg" class="popover-img">
      </ng-container>
      <ng-container *ngIf="noThumbnailExists">
        <p class="no-preview">{{ 'appCommon.noMediaPreview' | translate }}</p>
      </ng-container>
    </div>

    </dx-popover>
</div>

<ng-container *ngIf="displayMediaViewer">
  <td-media-viewer 
    [(mediaVisible)]="displayMediaViewer" 
    [tableType]="tableType" 
    [refKeyId]="keyId"
    [mediaTitle]="mediaTitle" 
    [mediaSeries]="mediaSeries" 
    [mediaIndex]="mediaIndex">
  </td-media-viewer>
</ng-container>
