"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ControlState = /** @class */ (function () {
    function ControlState() {
        this.disabled = false;
        this.visible = true;
    }
    return ControlState;
}());
exports.ControlState = ControlState;
