<td-header></td-header>
<div class="container-fluid main-page-container">
  <div class="row h-100">
    <div class="col">
      <router-outlet></router-outlet>
    </div>

    <!--Fix for Bug 19484 - Todo: more investigation to find root cause which could be related to DevExpress-->
    <div *ngIf="this.sessionHelperService.canViewOrder()" [style.display]="allowOrderCart ? 'block' : 'none'">
      <td-order-cart></td-order-cart>
    </div>
  </div>
</div>
<td-popup *ngIf="displayPopup"></td-popup>
<td-load-panel></td-load-panel>

