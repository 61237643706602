"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var info_overview_model_1 = require("@app/dashboard/models/info-overview.model");
exports.selectInfoOverviewState = function (state) { return state.infoOverview; };
var ɵ0 = function (state) { return state.infoOverviews; };
exports.ɵ0 = ɵ0;
exports.selectInfoOverviews = store_1.createSelector(exports.selectInfoOverviewState, ɵ0);
var ɵ1 = function (state, props) {
    return state.infoOverviews ? state.infoOverviews.filter(function (io) {
        return io.configId === props.id;
    })[0] : new info_overview_model_1.InfoOverview();
};
exports.ɵ1 = ɵ1;
exports.selectInfoOverviewById = store_1.createSelector(exports.selectInfoOverviewState, ɵ1);
var ɵ2 = function (state, props) {
    return state.connectedToHub;
};
exports.ɵ2 = ɵ2;
exports.selectConnectedToHub = store_1.createSelector(exports.selectInfoOverviewState, ɵ2);
