"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PropertyTypes;
(function (PropertyTypes) {
    // Type "boolean".
    PropertyTypes["typeBoolean"] = "boolean";
    // Type "number".
    PropertyTypes["typeNumber"] = "number";
    // Type "string".
    PropertyTypes["typeString"] = "string";
    // Type "Date".
    PropertyTypes["typeDate"] = "Date";
    // Type "Object". (Reference to other DBModel)
    PropertyTypes["typeObject"] = "Object";
    // Type "undefined". (For example, can be "Media", "Quantity" columns)
    PropertyTypes["typeUndefined"] = "undefined";
    // Type "enum". We are using this to convert enum-value into translated text-value.
    PropertyTypes["typeEnum"] = "enum";
})(PropertyTypes = exports.PropertyTypes || (exports.PropertyTypes = {}));
