"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
function addStringPropToParams(property, params) {
    params[property.urlParamName] = property.value;
    return params;
}
exports.addStringPropToParams = addStringPropToParams;
function extractStringFromParams(paramName, params) {
    return params[td_constants_1.TD_QUERY_PARAMS[paramName]] ? params[td_constants_1.TD_QUERY_PARAMS[paramName]].toString() : undefined;
}
exports.extractStringFromParams = extractStringFromParams;
