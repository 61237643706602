import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { OrderBookingMode, OrderOrgStatus } from '@core/data-layer/shared/models/td.enumerations';
import { OrderServiceInterface } from './order.service.interface';
import { OrderSendArgsInterface, OrderBookingModeArgsInterface, OrdersByCustomerAndOrgStatusArgsInterface, OrderCreateFromTemplateArgsInterface } from '../models/order-args.interface';
import { OrderHelperService } from './order-helper.service';
import { WebOrderViewModel, WebOrderLineViewModel } from '../models/web-order-view.model';
import { OrderModel } from '../models/order.model';
import { WebOrderBookingDetailsModel } from '../models/web-order-booking-details.model';
import { WebOrderBookingDetailsViewModel } from '../models/web-order-booking-details-view.model';
import { WebOrderModel, WebOrderLineModel } from '../models/web-order.model';
import { ApiResponse, ApiDataResponse } from '../../shared/models/api-response.model';
import { OrderLineInfoArgsModel } from '../models/order-line-info-args.model';

@Injectable()
export class HttpOrderService implements OrderServiceInterface {

  constructor(
    private http: HttpClient,
    private orderHelperService: OrderHelperService
  ) { }

  ordersUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.ordersCtrl;
  }

  getOrder(ordKeyId: number, customerKeyId: number): Observable<ApiDataResponse<WebOrderViewModel>> {
    return this.http.post<ApiDataResponse<WebOrderModel>>(this.ordersUrl() + `(${ordKeyId})`, customerKeyId).pipe(
      map(response => {
        const convertedDetails = this.orderHelperService.prepareOrderDetailsResponse(response.value.details);
        const convertedOrderLines = this.orderHelperService.prepareOrderLinesResponse(response.value.lines);
        const convertedWebOrderViewModel = this.orderHelperService.convertWebOrderToViewModel(response.value.state, convertedDetails, convertedOrderLines);
        return { ...response, value: convertedWebOrderViewModel};
      })
    );
  }

  getOrderLine(ordLineInfoArgs: OrderLineInfoArgsModel): Observable<ApiDataResponse<WebOrderLineViewModel>> {
    return this.http.post<ApiDataResponse<WebOrderLineModel>>(this.ordersUrl() + API_HUB_PATHS.getOrderLine, ordLineInfoArgs).pipe(
      map(response => {
        const convertedOrderLines = this.orderHelperService.prepareOrderLinesResponse([response.value]);
        let convertedOrdLineView: WebOrderLineViewModel = null;
        if (convertedOrderLines.length > 0) {
          convertedOrdLineView = convertedOrderLines[0];
        }
        return { ...response, value: convertedOrdLineView };
      })
    );
  }

  saveOrder(webOrder: WebOrderViewModel): Observable<ApiDataResponse<number>> {
    const order = { ...webOrder, details: { ...webOrder.details, reqDelTime: webOrder.details.reqDelTime } };
    return this.http.post<ApiDataResponse<number>>(this.ordersUrl() + `${API_HUB_PATHS.saveOrder}`, order);
  }

  saveOrderLines(ordKeyId: number, webOrderLines: WebOrderLineModel[]): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.ordersUrl() + `(${ordKeyId})${API_HUB_PATHS.saveOrderLines}`, webOrderLines);
  }

  sendOrder(ordKeyId: number, factoryKeyId: number): Observable<ApiResponse> {
    const params: OrderSendArgsInterface = { factoryKeyId };
    return this.http.post<ApiResponse>(this.ordersUrl() + `(${ordKeyId})${API_HUB_PATHS.sendOrder}`, params);
  }

  cancelOrder(ordKeyId: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.ordersUrl() + `(${ordKeyId})${API_HUB_PATHS.cancelOrder}`, null);
  }

  copyOrder(ordKeyId: number): Observable<ApiDataResponse<number>> {
    return this.http.post<ApiDataResponse<number>>(this.ordersUrl() + `(${ordKeyId})${API_HUB_PATHS.copyOrder}`, null);
  }

  createOrderFromTemplate(templateKeyId: number, factoryKeyId: number, customerKeyId: number): Observable<ApiDataResponse<number>> {
    const params: OrderCreateFromTemplateArgsInterface = { factoryKeyId, customerKeyId };
    return this.http.post<ApiDataResponse<number>>(this.ordersUrl() + `${API_HUB_PATHS.createFromTemplate}(${templateKeyId})`, params);
  }

  changeBookingMode(ordKeyId: number, newBookingMode: OrderBookingMode): Observable<ApiResponse> {
    const params: OrderBookingModeArgsInterface = { newBookingMode };
    return this.http.post<ApiResponse>(this.ordersUrl() + `(${ordKeyId})${API_HUB_PATHS.changeBookingMode}`, params);
  }

  convertBookingDetailsToViewModel(data: WebOrderBookingDetailsModel): WebOrderBookingDetailsViewModel {
    const resvLines = this.orderHelperService.prepareReservationLineResponse(data.reservLines);
    return this.orderHelperService.convertBookingDetailsToViewModel(data, resvLines, data.reservLogs, data.reservStates);
  }

  checkVirtualBooking(ordKeyId: number): Observable<WebOrderBookingDetailsViewModel> {
    return this.http.post<ApiDataResponse<WebOrderBookingDetailsModel>>(`${this.ordersUrl()}(${ordKeyId})${API_HUB_PATHS.checkVirtualBooking}`, null).pipe(
      map(response => this.convertBookingDetailsToViewModel(response.value))
    );
  }

  updateActualBooking(ordKeyId: number): Observable<WebOrderBookingDetailsViewModel> {
    return this.http.post<ApiDataResponse<WebOrderBookingDetailsModel>>(`${this.ordersUrl()}(${ordKeyId})${API_HUB_PATHS.updateActualBooking}`, null).pipe(
      map(response => this.convertBookingDetailsToViewModel(response.value))
    );
  }

  getActualBooking(ordKeyId: number): Observable<WebOrderBookingDetailsViewModel> {
    return this.http.get<ApiDataResponse<WebOrderBookingDetailsModel>>(`${this.ordersUrl()}(${ordKeyId})${API_HUB_PATHS.getActualBooking}`).pipe(
      map(response => {
        const resvLines = this.orderHelperService.prepareReservationLineResponse(response.value.reservLines);
        return this.orderHelperService.convertBookingDetailsToViewModel(response.value, resvLines, null, response.value.reservStates);
      })
    );
  }

  getOrdersByCustomerAndOrgStatus(customerKeyId: number, originatorStatus: OrderOrgStatus): Observable<OrderModel[]> {
    const params: OrdersByCustomerAndOrgStatusArgsInterface = { customerKeyId, originatorStatus };
    return this.http.post<OrderModel[]>(`${this.ordersUrl()}${API_HUB_PATHS.getByCustomerAndOrgStatus}`, params);
  }

  getOrderCustomer(orderKeyId: number): Observable<number> {
     return this.http.get<number>(`${this.ordersUrl()}(${orderKeyId})${API_HUB_PATHS.customerKeyId}`);
  }
}


