"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var HttpCustomerService = /** @class */ (function () {
    function HttpCustomerService(http) {
        this.http = http;
    }
    HttpCustomerService.prototype.customersUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.customersCtrl;
    };
    HttpCustomerService.prototype.getUserCustomers = function () {
        return this.http.get("" + this.customersUrl());
    };
    HttpCustomerService.prototype.getSiteCustomers = function (cuSiteKeyId) {
        return this.http.get("" + this.customersUrl() + api_hub_constants_1.API_HUB_PATHS.bySite + "(" + cuSiteKeyId + ")");
    };
    return HttpCustomerService;
}());
exports.HttpCustomerService = HttpCustomerService;
