"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POConst = {
    CSSDType: {
        UNCLEAN: 0,
        CLEAN: 1,
        STERILE: 2
    },
    MachineType: {
        STERILIZER: 0,
        WASHER: 1,
        ENDO: 3,
        DRYER: 5 // mtEndoDryer
    },
    StatusBarType: {
        CURRENT_COUNT: 0,
        PRODUCTION: 1,
        UNCLEAN_AREA: 2,
        WASHERS: 3,
        CLEAN_AREA: 4,
        STERILIZERS: 5,
        STERILE_AREA: 6
    },
    EndoIdentifier: {
        SERIAL_NUMBER: 0,
        BARCODE: 1 // eoeiBarcode
    },
    DoorStatus: {
        UNKNOWN: 0,
        CLOSED: 1,
        OPEN: 2 // dsOpen
    },
    FlowStatus: {
        UNKNOWN: 0,
        OK: 1,
        ERROR: 2 // fsError
    },
    TemperatureDegree: {
        CELSIUS: 'C',
        FAHRENHEIT: 'F' // ctFahrenheit
    },
    FuncClickType: {
        KPI: 'kpi_',
        MACHINE: 'machine_',
        USER: 'user_'
    },
    HEARTBEAT: 'heart_beat',
    TXTPrefix: 'txt_',
    MIN_BODY_HEIGHT: 820,
    MIN_BODY_WIDTH: 1280,
    MACHINE_SIZE: 80,
    IE_TXT_EXTRA_SPACE: 3,
    POPUP_TXT_EXTRA_SPACE: 2 * 2 + 2 * 5,
    MIN_POPUP_WIDTH: 220,
    MAX_POPUP_WIDTH: 450,
    BAR_GROUP_TOP_AREA_HEIGHT: 202,
    BAR_GROUP_TOP_AREA_WIDTH: 372,
    KPI_BTN_HEIGHT: 60,
    KPI_BTN_EXTRA_SPACE: 4 * 2 + 5 * 2,
    DEFAULT_KPI_BTN_WIDTH: 164,
    // DEFAULT_KPI_TXT_WIDTH: this.DEFAULT_KPI_BTN_WIDTH - this.KPI_BTN_EXTRA_SPACE,
    DEFAULT_KPI_TXT_WIDTH: 164 - (4 * 2 + 5 * 2),
    KPI_BTN_FONT: 'bold 16px "Segoe UI"',
    POPUP_TXT_FONT: 'normal 12px "Segoe UI"',
    KPIInternalCustomer: 163,
    KPIOnStock: 164,
    TIMEOUT_REACT_ON_RESIZE: 0,
    DEFAULT_KPI_BTN_BG_COLOR: 'bgColorWhite',
    DEFAULT_KPI_BTN_TXT_COLOR: 'fontColorDefault',
    IMG_USER_NOT_ACTIVE: './assets/img/user_d.png',
    IMG_USER_ACTIVE: './assets/img/user.png',
    IMG_WARNING: './assets/img/Warning Orange.svg',
    POPUP_FADE_IN_SPEED: 600,
    DEFAULT_MACHINE_AREA_WIDTH: 162,
    DRYER_MACINE_AREA_WIDTH: 142,
    TDOC_IO_WINDOW_EXTRA_HEIGHT: 108,
    TDOC_MS_WINDOWS_TASKBAR_HEIGHT: 40,
    TDOC_APP_TYPE: 'APPTYPE',
    TDOC_IO_ID: 'IOCKEYID',
    RECONNECT_IO_TIMEOUT: 50000 // milliseconds
};
exports.PO_KPI = {
    KPIReturned: 101,
    KPIReadyForWash: 102,
    KPIWashErrorsToday: 105,
    KPIReturnedToday: 106,
    KPIAverageOfReturnedToday: 107,
    KPIMaxOfReturnedToday: 108,
    KPIWaitingForWasherApproval: 110,
    KPIAverageWaitingForWasherApproval: 111,
    KPIReadyForAutoclave: 112,
    KPIPacked: 113,
    KPIAveragePacked: 114,
    KPIPackErrorsToday: 115,
    KPISterileErrorsToday: 116,
    KPIReadyForDispatch: 120,
    KPIWaitingForSterilizerApproval: 121,
    KPIAverageReadyForDispatch: 122,
    KPIAverageWaitingForSterilizerApproval: 123,
    // Unclean area
    KPIWashArea: 130,
    KPIAverageAtWashArea: 131,
    KPIMaxAtWashArea: 132,
    KPIWashedToday: 133,
    KPIAverageOfWashedToday: 134,
    KPIMaxOfWashedToday: 135,
    KPIAverageReturned: 136,
    KPIAverageReadyForWash: 137,
    // Pack area
    KPIPackArea: 140,
    KPIAverageAtPackArea: 141,
    KPIMaxAtPackArea: 142,
    KPISterilizedToday: 143,
    KPIAverageOfSterilizedToday: 144,
    KPIMaxOfSterilizedToday: 145,
    KPIPackedToday: 146,
    KPIAverageOfPackedToday: 147,
    KPIMaxOfPackedToday: 148,
    KPIAverageReadyForAutoclave: 149,
    // Sterile area
    KPISterileArea: 150,
    KPIAverageAtSterileArea: 151,
    KPIMaxAtSterileArea: 152,
    KPIDispatchedToday: 153,
    KPIAverageOfDispatchedToday: 154,
    KPIMaxOfDispatchedToday: 155,
    KPIExpectedIncoming: 160,
    KPIIncoming: 161,
    KPIExternalCustomer: 162,
    KPIInternalCustomer: 163,
    KPIOnStock: 164,
    KPIInQuarantine: 165,
    KPIExpired: 166,
    KPIAverageIncoming: 167,
    KPIAverageInternalCustomer: 168,
    KPIAverageOnStock: 169,
    // Current status
    KPITotalInHospital: 170,
    KPIAverageOfTotalInHospital: 171,
    KPITotalInCSSD: 172,
    KPIAverageOfTotalInCSSD: 173,
    KPITotalInStock: 174,
    KPIAverageOfTotalInStock: 175,
    KPITotalWithCustomers: 176,
    KPIAverageOfTotalWithCustomers: 177,
    // Production (accumulate values)
    KPIHourUntilNow: 179,
    KPITodayUntilNow: 180,
    KPITodayAverageUntilNow: 181,
    KPITodayAverageWholePeriod: 182,
    KPITodayPercent: 183,
    KPIThisWeekUntilNow: 184,
    KPIThisWeekAverageUntilNow: 185,
    KPIThisWeekAverageWholePeriod: 186,
    KPIThisWeekPercent: 187,
    KPIThisMonthUntilNow: 188,
    KPIThisMonthAverageUntilNow: 189,
    KPIThisMonthAverageWholePeriod: 190,
    KPIThisMonthPercent: 191,
    KPIThisYearUntilNow: 192,
    KPIThisYearAverageUntilNow: 193,
    KPIThisYearAverageWholePeriod: 194,
    KPIThisYearPercent: 195,
    KPIMaxOfTotalInHospital: 210,
    KPIMaxOfTotalInCSSD: 211,
    KPIMaxOfTotalInStock: 212,
    KPIMaxOfTotalWithCustomers: 213,
    KPITodayMaxUntilNow: 216,
    KPIThisWeekMaxUntilNow: 217,
    KPIThisMonthMaxUntilNow: 218,
    KPIThisYearMaxUntilNow: 219,
    KPITodayMaxWholePeriod: 220,
    KPIThisWeekMaxWholePeriod: 221,
    KPIThisMonthMaxWholePeriod: 222,
    KPIThisYearMaxWholePeriod: 223,
    // Machines
    KPIUnitsInWashers: 501,
    KPIAverageUnitsInWashers: 502,
    KPIMaxUnitsInWashers: 503,
    KPIUnitsInSterilizers: 511,
    KPIAverageUnitsInSterilizers: 512,
    KPIMaxUnitsInSterilizers: 513,
    // Endo KPI
    KPITestedAndCleaned: 1001,
    KPIAverageTestedAndCleaned: 1002,
    KPIReprocessed: 1003,
    KPIAverageReprocessed: 1004,
    KPIReprocessedToday: 1005,
    KPIAverageReprocessedToday: 1006,
    KPIMaxReprocessedToday: 1007,
    KPIEndoUncleanErrorsToday: 1008,
    KPIEndoCleanErrorsToday: 1009,
    KPIEndoInTransport: 1010,
    KPIEndoAverageInTransport: 1011,
    KPIEndoInSterilizers: 1012,
    KPIEndoAverageInSterilizers: 1013,
    KPIEndoScopesInDryers: 1014,
    KPIEndoMaxScopesInDryers: 1015,
    KPIEndoAverageScopesInDryers: 1016
};
