"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@ngx-translate/core");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var view_helper_service_1 = require("@core/data-layer/shared/services/view-helper.service");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("../../../../shared/services/session-helper.service");
var i3 = require("../../shared/services/view-helper.service");
var ProductHelperService = /** @class */ (function () {
    function ProductHelperService(translateService, sessionHelperService, viewHelperService) {
        this.translateService = translateService;
        this.sessionHelperService = sessionHelperService;
        this.viewHelperService = viewHelperService;
    }
    /**
     * @description converts [ProductModel][Object] to [ProductViewModel][Object]
     * @param product: [ProductModel][Object]
     * @returns [ProductViewModel][Object]
     */
    ProductHelperService.prototype.convertProductToViewModel = function (product) {
        var type = this.viewHelperService.getItemType(product.item);
        var isEndo = type === td_enumerations_1.ItemInstrumentType.Endoscope;
        return tslib_1.__assign({}, product, { supplierText: this.getSupplierText(product, isEndo), productCaption: isEndo ? this.translateService.instant('appCommon.endoscope') : this.translateService.instant('appCommon.product'), productText: product.product + ' - ' + product.item.text, customerText: product.custKeyId ? product.cust.customer + ' ' + product.cust.name : '', reservationModeText: this.translateService.instant("prodReservationMode." + td_enumerations_1.ProdReservationMode[product.reservationMode].toLowerCase()), omitted: (product.status === td_enumerations_1.ArticleStatus.Dead), omittedText: this.viewHelperService.getOmittedText(product.status, this.translateService.instant('appCommon.product')), omittedImgUrl: this.viewHelperService.getOmittedImgUrl(product.status), itemType: type, isEndoscope: isEndo, displayPrice: (this.sessionHelperService.serverOptions.displayPriceMode !== td_enumerations_1.WebDisplayPriceMode.HideAll) });
    };
    ProductHelperService.prototype.getSupplierText = function (product, isEndo) {
        var suppText = '';
        if (isEndo && product.item.suppKeyId) {
            suppText = product.item.supp.supplier;
            if (suppText && product.item.supp.name) {
                suppText = suppText + ' - ' + product.item.supp.name;
            }
        }
        return suppText;
    };
    ProductHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductHelperService_Factory() { return new ProductHelperService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.SessionHelperService), i0.ɵɵinject(i3.ViewHelperService)); }, token: ProductHelperService, providedIn: "root" });
    return ProductHelperService;
}());
exports.ProductHelperService = ProductHelperService;
