import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { TableTypes } from '@core/data-layer/shared/models/td.enumerations';
import { MediaInfoInterface } from '../models/media-info.interface';
import { MediaServiceInterface } from './media.service.interface';
import { TD_MEDIA_THUMBNAIL } from '@core/data-layer/shared/models/td.constants';
import { PRODUCT_AS_STRING, UNIT_AS_STRING, ITEM_AS_STRING } from '@app/shared/models/app.constants';

@Injectable()
export class HttpMediaService implements MediaServiceInterface {

  constructor(
    private http: HttpClient
  ) { }

  mediaUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.mediaCtrl;
  }

  loadMediaInfoList(tableType: TableTypes, mediaSeries: number, refKeyId: number): Observable<MediaInfoInterface[]> {
    const stringType = TableTypes[tableType].toLowerCase();
    let url = `${this.mediaUrl()}${API_HUB_PATHS.getMediaInfoList}?keyId=${refKeyId}&linkType=${stringType}`;
    url = mediaSeries ? url + `&seriesType=${mediaSeries}` : url;
    return this.http.get<MediaInfoInterface[]>(url);      
  }

  // Get Media Content
  getMediaText(keyId: number): Observable<string> {
    const url = this.getMediaTextUrl(keyId);
    return this.http.get(url, { responseType: 'text'});
  }

  getMediaImage(keyId: number): Observable<Blob> {
    const url = this.getMediaImageUrl(keyId);
    return this.createImageObservable(url);
  }

  getMediaVideo(keyId: number): string {
    return this.getMediaVideoUrl(keyId);
  }

  getMediaThumbnail(keyId: number): Observable<any> {
    const url = this.getMediaThumbnailUrl(keyId);
    return this.createImageObservable(url);
  }

  getMediaSeries(keyId: number, linkType: string): Observable<MediaInfoInterface[]> {
    const url = this.getMediaSeriesUrl(keyId, linkType);    
    return this.http.get<MediaInfoInterface[]>(url);
  }


  // Get Media URL's
  getMediaImageUrl(refKeyId: number): string {
    return `${this.mediaUrl()}${API_HUB_PATHS.getImage}?keyId=${refKeyId}`;
  }

  getMediaThumbnailUrl(refKeyId: number): string {
    return `${this.mediaUrl()}${API_HUB_PATHS.getImage}?keyId=${refKeyId}&${TD_MEDIA_THUMBNAIL}`;
  }

  getMediaTextUrl(refKeyId: number): string {
    return `${this.mediaUrl()}${API_HUB_PATHS.getText}?keyId=${refKeyId}`;
  }

  getMediaVideoUrl(refKeyId: number): string {
    return `${this.mediaUrl()}${API_HUB_PATHS.getVideo}?keyId=${refKeyId}`;
  }

  getMediaSeriesUrl(refKeyId: number, linkType: string): string {
    return `${this.mediaUrl()}${API_HUB_PATHS.getSeries}?keyId=${refKeyId}&linktype=${linkType}`;
  }

  createImageFromBlob(image: Blob): Observable<any> {
    const subject = new Subject<any>();
    let reader = new FileReader();
    reader.onload = () => {
      const content = reader.result;
      subject.next(content);
      subject.complete;
    }
    if (image) {
      reader.readAsDataURL(image);
      return subject.asObservable();
    }
  }

  createImageObservable(url: string): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(url, {responseType: 'blob'}).subscribe(
      response => {
        if (response.size < 1) {
          subject.next();
          subject.complete();
        }
        else {
          this.createImageFromBlob(response).subscribe(img => {
            subject.next(img);
            subject.complete;
          });
        } 
      },
      error => {
        subject.next(null);
        subject.complete;        
      }
    );
    return subject.asObservable();
  }

  getLinkType(tableType: TableTypes): string {
    switch (tableType) {
      case TableTypes.Product: {
        return PRODUCT_AS_STRING;
      }
      case TableTypes.Unit: {
        return UNIT_AS_STRING;
      }
      case TableTypes.Item: {
        return ITEM_AS_STRING;
      }
    }
  }

}
