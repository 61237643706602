import { Params } from '@angular/router';
import { TD_QUERY_PARAMS } from '@core/data-layer/shared/models/td.constants';
import { SearchParam, SearchParamBase, SearchParamTypes } from './search-param.models';

export interface SearchParamNumber extends SearchParamBase {  
  type: SearchParamTypes;  
  value?: number;
}

export function extractNumberFromParams(paramName: string, params: Params): number {
  return params[TD_QUERY_PARAMS[paramName]] ? Number(params[TD_QUERY_PARAMS[paramName]]) : undefined;
}

export function addNumberPropToParams(property: SearchParam, params: Params): Params {
  params[property.urlParamName] = (property.value as number).toString();
  return params;
}
