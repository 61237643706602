import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import { LoggerService } from '../logger/logger.service';

@Injectable()
export class SignalRService {

  public hubConnection: HubConnection;
  protected reconnectTimeout = 0;

  constructor(
    public loggerService: LoggerService
  ) { }

  protected beforeInitialize() {
    // Do nothing. Can override in inherited classes
  }

  protected initializeConnection(): void {
    this.beforeInitialize();
    this.createConnection();
    this.startConnection();
  }

  protected onConnectionEstablished() {
    this.loggerService.log('Hub connection started');
  }

  protected onConnectionLost(errMsg: string) {
    this.loggerService.error(errMsg);
  }

  private reconnectToHub() {
    if (this.reconnectTimeout > 0) {
      setTimeout(this.startConnection.bind(this), this.reconnectTimeout);
    }
  }

  protected getHubServerUrl(): string {
    return '';
  }

  private createConnection() {
    if (this.hubConnection) {
      this.hubConnection = null;
    }
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.getHubServerUrl())
      /*.configureLogging({
        log: function (logLevel, message) {
          this.loggerService.error(new Date().toISOString() + ": " + message);
        }
      })*/
      .build();
  }

  private startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        this.onConnectionEstablished();
      })
      .catch(() => {
        this.onConnectionLost('Error while establishing connection, retrying...');
        this.reconnectToHub();
      });
  }

  protected stopConnection() {
    this.hubConnection.stop();
  }

  private onCloseConnection(): void {
    this.hubConnection.onclose(err => {
      if (err) {
        this.onConnectionLost('HubConnection closed with error: ' + err);
        this.reconnectToHub();
      }
      else {
        this.loggerService.log('HubConnection disconnected');
      }
    });
  }

  public connectedToHub(): boolean {
    return (this.hubConnection && (this.hubConnection.state === HubConnectionState.Connected));
  }
}
