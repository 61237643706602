import {
  LoginCookieMethod,
  PasswordVerificationMethod,
  ProdOverviewVisibilityMode,
  WebFastTrackMode,
  WebStartStopOperationMode,
  WebEditOprationArticlesMode,
  WebOrderMode,
  WebDisplayPriceMode,
  WebDisplayCustomerProductsMode,
  WebDisplayGeneralProductsMode
} from '@core/data-layer/shared/models/td.enumerations';

export class ServerDesktopOptionsModel {
  loginCookieMethod: LoginCookieMethod = LoginCookieMethod.UserNamePassword;
  passwordVerificationMethod: PasswordVerificationMethod = PasswordVerificationMethod.None;
  loginAllowBlankPasswords = false;
  defaultFTCoKeyId = 0;
  prodOverviewVisibilityMode: ProdOverviewVisibilityMode = ProdOverviewVisibilityMode.AvailableInWeb;
  fastTrackMode: WebFastTrackMode = WebFastTrackMode.On;
  startStopOperationMode: WebStartStopOperationMode = WebStartStopOperationMode.Allow;
  editOprationArticlesMode: WebEditOprationArticlesMode = WebEditOprationArticlesMode.AllowEdit;
  orderMode: WebOrderMode = WebOrderMode.On;
  displayPriceMode: WebDisplayPriceMode = WebDisplayPriceMode.ShowAll;
  displayCustomerProductsMode: WebDisplayCustomerProductsMode = WebDisplayCustomerProductsMode.All;
  displayGeneralProductsMode: WebDisplayGeneralProductsMode = WebDisplayGeneralProductsMode.Allowed;
  previewOrderBeforeSending = false;
}
