import * as factoryActions from './factory.actions';
import { FactoryModel } from '../models/factory.model';

export interface FactoryState {
  allFactories: FactoryModel[];
  // A list of factories the customer can order from. Primary is index 0.
  // In case customer is not factory bound then all factories are valid
  customerFactories: FactoryModel[];
  // A list of factories the user is asigned to. Primary is index 0.
  userFactories: FactoryModel[];
}

export const initialFactoryState: FactoryState = {
  allFactories: null,
  customerFactories: null,
  userFactories: null
};

export function factoryReducer(
  state = initialFactoryState,
  action: factoryActions.FactoryActions
): FactoryState {
  switch (action.type) {
    case factoryActions.FactoryActionTypes.LoadAllFactories:
      return handleLoadAllFactories(state, action);
    case factoryActions.FactoryActionTypes.SetAllFactories:
      return handleSetAllFactories(state, action);
    case factoryActions.FactoryActionTypes.LoadUserFactories:
      return handleLoadUserFactories(state, action);
    case factoryActions.FactoryActionTypes.SetUserFactories:
      return handleSetUserFactories(state, action);
    case factoryActions.FactoryActionTypes.LoadCustomerFactories:
      return handleLoadCustomerFactories(state, action);
    case factoryActions.FactoryActionTypes.SetCustomerFactories:
      return handleSetCustomerFactories(state, action);
    case factoryActions.FactoryActionTypes.SetCustomerFactoriesFromAll:
      return handleSetCustomerFactoriesFromAll(state, action);
    default:
      return state;
  }
}

function handleLoadAllFactories(state: FactoryState, action: factoryActions.LoadAllFactories): FactoryState {
  return state;
}

function handleSetAllFactories(state: FactoryState, action: factoryActions.SetAllFactories): FactoryState {
  // due to loading all factories is executed only once (on APP_INITIALIZER), we are assigning "customerFactories" to default "allFactories" array
  return Object.assign({}, state, { allFactories: action.payload, customerFactories: action.payload });
}

function handleLoadUserFactories(state: FactoryState, action: factoryActions.LoadUserFactories): FactoryState {
  return state;
}

function handleSetUserFactories(state: FactoryState, action: factoryActions.SetUserFactories): FactoryState {
  return Object.assign({}, state, { userFactories: action.payload });
}

function handleLoadCustomerFactories(state: FactoryState, action: factoryActions.LoadCustomerFactories): FactoryState {
  return state;
}

function handleSetCustomerFactories(state: FactoryState, action: factoryActions.SetCustomerFactories): FactoryState {
  // user has selected linked customer:
  // there are linked factories to selected customers, so as a result, we are assigning just received "customerFactories" array
  return Object.assign({}, state, { customerFactories: action.payload });
}

function handleSetCustomerFactoriesFromAll(state: FactoryState, action: factoryActions.SetCustomerFactoriesFromAll): FactoryState {
  // user has selected linked customer:
  // there are NO linked factories to selected customers, so as a result, we are assigning "customerFactories" to default "allFactories" array
  return Object.assign({}, state, { customerFactories: state.allFactories });
}
