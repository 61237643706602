import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { FactoryServiceInterface } from './factory.service.interface';
import { FactoryModel } from '../models/factory.model';

@Injectable()
export class HttpFactoryService implements FactoryServiceInterface {

  constructor(
    private http: HttpClient
  ) { }

  factoriesUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.factoriesCtrl;
  }

  getFactory(factKeyId: number): Observable<FactoryModel> {
    return this.http.get<FactoryModel>(`${this.factoriesUrl()}(${factKeyId})`);
  }

  getFactories(): Observable<FactoryModel[]> {
    return this.http.get<FactoryModel[]>(this.factoriesUrl());
  }

  getFactoriesByCustomer(custKeyId: number): Observable<FactoryModel[]> {
    return this.http.get<FactoryModel[]>(`${this.factoriesUrl()}${API_HUB_PATHS.getByCustomer}(${custKeyId})`);
  }

  getUserFactories(): Observable<FactoryModel[]> {
    return this.http.get<FactoryModel[]>(this.factoriesUrl() + API_HUB_PATHS.getByLoggedInUser);
  }
}
