"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
exports.selectOrderState = function (state) { return state.order; };
var ɵ0 = function (state) { return state.customerOpenOrders; };
exports.ɵ0 = ɵ0;
exports.selectCustomerOpenOrders = store_1.createSelector(exports.selectOrderState, ɵ0);
var ɵ1 = function (state) { return state.userLastOpenOrder; };
exports.ɵ1 = ɵ1;
exports.selectUserLastOpenOrder = store_1.createSelector(exports.selectOrderState, ɵ1);
