<dx-popup #popup
    class="column-chooser-popup td-popup global-popup"
    [title]="'columnChooser.columnSelection' | translate"
    [showCloseButton]="false"
    [dragEnabled]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="displayColumnChooser"
    (onHiding)="onClose()">
  <div *dxTemplate="let data of 'content'">
    <div class="table-headers-displayed-container">
      <dx-tree-list #columnPickerTreeList
                    id="columnPickerTreeList"
                    [dataSource]="chooserColumnData"
                    keyExpr="id"
                    parentIdExpr="parentId"
                    [showRowLines]="true"
                    [columnAutoWidth]="true"
                    [height]="calcHeightTreeList()"
                    [(selectedRowKeys)]="selectedRowKeys"
                    (onEditorPreparing)="onEditorPreparing($event)"
                    (onEditorPrepared)="onEditorPrepared($event)">
        <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
        <dxo-search-panel [visible]="true" placeholder="{{ 'appCommon.search' | translate }}"></dxo-search-panel>
        <dxo-sorting mode="single"></dxo-sorting>
        <dxi-column dataField="displayName"  caption="{{ 'columnChooser.columns' | translate }}" sortOrder="asc"></dxi-column>
        <dxo-scrolling [useNative]="false"
                       [scrollByContent]="true"
                       [scrollByThumb]="true"
                       showScrollbar="onHover">
        </dxo-scrolling>
      </dx-tree-list>
      <div class="d-flex justify-content-end mt-3">
        <dx-button
          class="td-button success-button float-right mr-3"
          (onClick)="confirmSelection()">
          {{ 'button.ok' | translate}}
        </dx-button>
        <dx-button
          class="td-button secondary-button float-right"
          (onClick)="onClose()">
          {{ 'button.cancel' | translate }}
        </dx-button>

      </div>
    </div>
  </div>
</dx-popup>
