<div class="row" *ngIf="sessionHelperService.loggedIn()">
  <div class="col-sm-12">
    <dx-button 
               class="action-icon-button"
               [ngClass]="darkMode ? 'tertiary-button-dark-mode' : 'tertiary-button'"
               (onClick)="defineSearchFilters()"
               hint="{{ 'search.searchFilters' | translate }}">
            <div class="filter-button-container">
              <img class="filter-button-img" src=".\assets\img\{{filterIcon}}">
              <ng-container *ngIf="activeFilterIcon">
                  <img class="filter-button-overlay" src=".\assets\img\SearchFilterOverlay.svg">
              </ng-container>
            </div>
    </dx-button>
  </div>
</div>

<ng-template #searchFiltersTemplate>
  <td-search-filters #searchFilters [defaultFilters]="false"></td-search-filters>
  <td-confirm-button-panel
    [clearButtonVisible]="true"
    [resetButtonVisible]="true"
    [disableSaveButton]="!searchFilters.filtersChanged"
    (confirmEvent)="apply()"
    (resetEvent)="searchFilters.resetToDefault()"
    (cancelEvent)="cancel()"
    (clearEvent)="searchFilters.clear()">
  </td-confirm-button-panel>
</ng-template>
