"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./td-load-panel.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../node_modules/devextreme-angular/ui/load-panel/devextreme-angular-ui-load-panel.ngfactory");
var i3 = require("devextreme-angular/core");
var i4 = require("devextreme-angular/ui/load-panel");
var i5 = require("@angular/platform-browser");
var i6 = require("./td-load-panel.component");
var i7 = require("./services/td-load-panel.service");
var styles_TdLoadPanelComponent = [i0.styles];
var RenderType_TdLoadPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TdLoadPanelComponent, data: {} });
exports.RenderType_TdLoadPanelComponent = RenderType_TdLoadPanelComponent;
function View_TdLoadPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "dx-load-panel", [["id", "load-panel"], ["shadingColor", "rgba(255,255,255,0.7)"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.loadPanelService.loadPanelParams.visible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DxLoadPanelComponent_0, i2.RenderType_DxLoadPanelComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(4, 7323648, null, 0, i4.DxLoadPanelComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { closeOnOutsideClick: [0, "closeOnOutsideClick"], message: [1, "message"], shading: [2, "shading"], shadingColor: [3, "shadingColor"], showIndicator: [4, "showIndicator"], showPane: [5, "showPane"], visible: [6, "visible"] }, { visibleChange: "visibleChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.loadPanelService.loadPanelParams.message; var currVal_2 = true; var currVal_3 = "rgba(255,255,255,0.7)"; var currVal_4 = true; var currVal_5 = true; var currVal_6 = _co.loadPanelService.loadPanelParams.visible; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
exports.View_TdLoadPanelComponent_0 = View_TdLoadPanelComponent_0;
function View_TdLoadPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-load-panel", [], null, null, null, View_TdLoadPanelComponent_0, RenderType_TdLoadPanelComponent)), i1.ɵdid(1, 49152, null, 0, i6.TdLoadPanelComponent, [i7.TdLoadPanelService], null, null)], null, null); }
exports.View_TdLoadPanelComponent_Host_0 = View_TdLoadPanelComponent_Host_0;
var TdLoadPanelComponentNgFactory = i1.ɵccf("td-load-panel", i6.TdLoadPanelComponent, View_TdLoadPanelComponent_Host_0, {}, {}, []);
exports.TdLoadPanelComponentNgFactory = TdLoadPanelComponentNgFactory;
