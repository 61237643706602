"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var base_component_1 = require("@app/shared/components/base/base.component");
var img_enumerations_1 = require("@app/shared/models/img.enumerations");
var grid_structure_interface_1 = require("@core/table-info/models/grid-structure.interface");
var utility_service_1 = require("@core/utility/utility.service");
var operations_shared_service_1 = require("../services/operations-shared.service");
var operations_search_service_1 = require("../services/operations-search.service");
var operation_search_factory_1 = require("@shared/models/url-search-models/operation-search.factory");
var url_search_service_1 = require("@shared/services/url-search.service");
var OperationListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OperationListComponent, _super);
    function OperationListComponent(operationsSearchService, operationsSharedService, urlSearchService, activatedRoute) {
        var _this = _super.call(this) || this;
        _this.operationsSearchService = operationsSearchService;
        _this.operationsSharedService = operationsSharedService;
        _this.urlSearchService = urlSearchService;
        _this.activatedRoute = activatedRoute;
        _this.mainTable = td_constants_1.TD_MODEL_NAMES.operationDataModel;
        _this.gridIdentifier = td_constants_1.TD_DESKTOP_IDENTIFIERS.scheduledOperationListGrid;
        _this.operationOrderStateImgPathEnum = img_enumerations_1.OperationOrderStateImgPaths;
        _this.dynamicFields = td_constants_1.TD_DYNAMIC_FIELDS;
        _this.ordersLeadStatus = td_enumerations_1.OrdersLeadStatus;
        // Sub grid
        _this.subGridIdentifier = td_constants_1.TD_DESKTOP_IDENTIFIERS.scheduledOperationsOrderListGrid;
        _this.subGridTable = td_constants_1.TD_MODEL_NAMES.orderModel;
        _this.operationOrdersData = {};
        _this.suppStatus = td_enumerations_1.OrderSuppStatus;
        // Search
        _this.textFormData = {
            text: ''
        };
        _this.datesFormData = {
            dateFrom: null,
            dateTo: null
        };
        _this.operationSearchType = td_enumerations_1.OperationSearchType;
        _this.lastSearchType = null;
        _this.operationStatus = td_enumerations_1.OperationStatus;
        _this.subscriptions = [];
        _this.firstSearch = true;
        // Search Model
        _this.searchModel = new operation_search_factory_1.OperationSearchFactory();
        _this.operationSearchModel = _this.searchModel.createSearchModel();
        _this.TD_MIN_DATE_VALUE = td_constants_1.TD_MIN_DATE_VALUE;
        return _this;
    }
    OperationListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queryParamSubscription;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _super.prototype.ngOnInit.call(this);
                        return [4 /*yield*/, this.getGridColumnsStructure()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getSubGridColumnsStructure()];
                    case 2:
                        _a.sent();
                        queryParamSubscription = this.activatedRoute.queryParams.subscribe(function (queryParams) {
                            var searchParamsModel = _this.urlSearchService.onQueryParamChanges(queryParams, _this.operationSearchModel, _this.searchModel, _this.firstSearch);
                            if (searchParamsModel) {
                                var searchType = _this.determineSearchType(searchParamsModel);
                                _this.setFormData(searchParamsModel, searchType);
                                _this.searchOperations(searchType);
                                _this.firstSearch = false;
                            }
                        });
                        this.subscriptions.push(queryParamSubscription);
                        return [2 /*return*/];
                }
            });
        });
    };
    OperationListComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    OperationListComponent.prototype.getTitleIdentifier = function () {
        var title = this.translateInstant('appCommon.scheduledOperations');
        return title;
    };
    /**
     * @param keyId keyId of the opreation that we want to fetch orders by
     * Extra info: We're using pipe(take(2)), because we want the value that happens when the returned value is initialized, as well as the value that is returned when we get a response from fuzzysearch
     */
    OperationListComponent.prototype.getOrders = function (keyId) {
        var _this = this;
        this.operationsSearchService.getOperationOrders(keyId, this.subGridStructure, this.operationsData).pipe(operators_1.take(2)).subscribe(function (response) {
            _this.operationOrdersData[keyId] = response;
        });
    };
    OperationListComponent.prototype.highlightSearchText = function (value) {
        return this.utilityService.highlightContent(value, this.textFormData.text);
    };
    OperationListComponent.prototype.addOrder = function (operationKeyId, stokKeyId, reqDelTime) {
        this.operationsSharedService.addOrderToOperation(operationKeyId, stokKeyId, reqDelTime);
    };
    OperationListComponent.prototype.setSearchParameters = function (searchType) {
        var allowSearch = true;
        if (searchType === td_enumerations_1.OperationSearchType.Date) {
            this.operationSearchModel.properties.find(function (entry) { return entry.urlParamName === td_constants_1.TD_QUERY_PARAMS.dateFrom; }).value = this.datesFormData.dateFrom;
            this.operationSearchModel.properties.find(function (entry) { return entry.urlParamName === td_constants_1.TD_QUERY_PARAMS.dateTo; }).value = this.datesFormData.dateTo;
            this.operationSearchModel.properties.find(function (entry) { return entry.urlParamName === td_constants_1.TD_QUERY_PARAMS.text; }).value = null;
        }
        else if (searchType === td_enumerations_1.OperationSearchType.OperationID) {
            if (this.textFormData.text) {
                this.operationSearchModel.properties.find(function (entry) { return entry.urlParamName === td_constants_1.TD_QUERY_PARAMS.text; }).value = this.textFormData.text;
            }
            else {
                allowSearch = false;
                this.popupService.showInfo('appMessages.searchTextEmptyError', true);
            }
        }
        if (allowSearch) {
            this.resetFormsData(searchType);
            this.urlSearchService.setSearchParamsAndNavigate(this.operationSearchModel, this.firstSearch);
        }
    };
    OperationListComponent.prototype.translateTodayButton = function (event) {
        event.component._popup.option('toolbarItems[0]', {
            options: {
                text: this.translateInstant('button.today')
            }
        });
    };
    OperationListComponent.prototype.getGridColumnsStructure = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.tableInfoService.loadGridStructure(_this.gridIdentifier, _this.mainTable).subscribe(function (gridStructure) {
                _this.gridStructure = gridStructure;
                _this.adjustCustomColumns();
                resolve(true);
            });
        });
    };
    OperationListComponent.prototype.adjustCustomColumns = function () {
        // align "OrderLeadStatus" column on center
        this.gridColumnHelperService.setColumnProperty(this.gridStructure.columnsConfigurations, this.dynamicFields.ordersLeadStatus, {
            style: { alignment: 'center' },
            fixed: true,
            fixedPosition: 'left'
        });
        var nameOfOperationData = utility_service_1.nameOf();
        // hide "BookingStatus" column
        if (!this.sessionHelperService.license.planAssure) {
            this.gridColumnHelperService.setColumnProperty(this.gridStructure.columnsConfigurations, nameOfOperationData('bookingStatus'), { visible: false });
        }
        if (this.sessionHelperService.serverOptions.orderMode === td_enumerations_1.WebOrderMode.On) {
            var addOrderColumn = {
                dataField: td_constants_1.TD_ACTION_FIELDS.addOrder,
                dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
                visible: true,
                width: '100',
                displayName: this.translateInstant('calcColumns.createOrder'),
                style: {
                    alignment: 'center'
                },
                fixed: true,
                fixedPosition: 'right'
            };
            this.gridColumnHelperService.addCustomColumn(this.gridStructure.columnsConfigurations, addOrderColumn);
        }
        else {
            this.gridColumnHelperService.removeColumn(this.gridStructure.columnsConfigurations, td_constants_1.TD_ACTION_FIELDS.addOrder);
        }
    };
    OperationListComponent.prototype.getSubGridColumnsStructure = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.tableInfoService.loadGridStructure(_this.subGridIdentifier, _this.subGridTable).subscribe(function (gridStructure) {
                _this.subGridStructure = gridStructure;
                var orderStatusColumn = {
                    dataField: td_constants_1.TD_ACTION_FIELDS.orderStatus,
                    dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
                    visible: true,
                    visibleIndex: 1,
                    width: '30',
                    displayName: '',
                    style: { alignment: 'center' },
                    fixed: true,
                    fixedPosition: 'left'
                };
                _this.gridColumnHelperService.addCustomColumn(_this.subGridStructure.columnsConfigurations, orderStatusColumn);
                resolve(true);
            });
        });
    };
    OperationListComponent.prototype.searchOperations = function (searchType) {
        var searchArgText = searchType == td_enumerations_1.OperationSearchType.Date ? '' : this.textFormData.text;
        if (!this.utilityService.dateRangeAllowed(this.datesFormData.dateFrom)) {
            this.datesFormData.dateFrom = null;
        }
        if (!this.utilityService.dateRangeAllowed(this.datesFormData.dateTo)) {
            this.datesFormData.dateTo = null;
        }
        this.operationsData = this.operationsSearchService.createSearchOperationsDataSource(this.gridStructure, this.datesFormData.dateFrom, this.datesFormData.dateTo, searchArgText, searchType);
    };
    OperationListComponent.prototype.resetFormsData = function (searchType) {
        if (searchType === td_enumerations_1.OperationSearchType.Date && this.textFormData) {
            this.textFormData.text = null;
        }
        else if (searchType === td_enumerations_1.OperationSearchType.OperationID && this.datesFormData) {
            this.datesFormData.dateFrom = null;
            this.datesFormData.dateTo = null;
        }
    };
    OperationListComponent.prototype.setFormData = function (searchModel, searchType) {
        if (searchType === td_enumerations_1.OperationSearchType.Date) {
            this.datesFormData[td_constants_1.TD_QUERY_PARAMS.dateFrom] = searchModel.properties.find(function (prop) { return prop.urlParamName === td_constants_1.TD_QUERY_PARAMS.dateFrom; }).value;
            this.datesFormData[td_constants_1.TD_QUERY_PARAMS.dateTo] = searchModel.properties.find(function (prop) { return prop.urlParamName === td_constants_1.TD_QUERY_PARAMS.dateTo; }).value;
            this.textFormData[td_constants_1.TD_QUERY_PARAMS.text] = null;
        }
        else if (searchType === td_enumerations_1.OperationSearchType.OperationID) {
            this.textFormData[td_constants_1.TD_QUERY_PARAMS.text] = searchModel.properties.find(function (prop) { return prop.urlParamName === td_constants_1.TD_QUERY_PARAMS.text; }).value;
            this.datesFormData[td_constants_1.TD_QUERY_PARAMS.dateFrom] = null;
            this.datesFormData[td_constants_1.TD_QUERY_PARAMS.dateTo] = null;
        }
    };
    OperationListComponent.prototype.determineSearchType = function (model) {
        var propertyWithValue = model.properties.find(function (prop) { return prop.value; });
        return propertyWithValue && propertyWithValue.urlParamName === td_constants_1.TD_QUERY_PARAMS.text ? td_enumerations_1.OperationSearchType.OperationID : td_enumerations_1.OperationSearchType.Date;
    };
    return OperationListComponent;
}(base_component_1.BaseComponent));
exports.OperationListComponent = OperationListComponent;
