import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { skip } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { AppState } from '@core/data-layer/app.state';
import { WebDisplayCustomerProductsMode, WebDisplayGeneralProductsMode } from '@core/data-layer/shared/models/td.enumerations';
import { UserDesktopOptionsModel } from '@core/data-layer/user-session/models/user-desktop-options.model';
import { UserSessionServiceInterface } from '@core/data-layer/user-session/services/user-session.service.interface';
import { LoadDefaultUserOptions, LoadUserOptions } from '@core/data-layer/user-session/store/user-session.actions';
import { selectDefaultUserOptions, selectUserOptions } from '@core/data-layer/user-session/store/user-session.selectors';
import { SearchFiltersHelperService } from '@shared/services/search-filters-helper.service';
import { SessionHelperService } from '@shared/services/session-helper.service';

@Component({
  selector: 'td-search-filters',
  templateUrl: './td-search-filters.component.html',
  styleUrls: ['./td-search-filters.component.less']
})

export class TdSearchFiltersComponent implements OnInit, OnDestroy {

  // Defines which filters should be checked default or user
  @Input() defaultFilters: boolean;

  @Input() filtersChanged: boolean;
  @Output() filtersChangedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // User or default user options
  userOptions: UserDesktopOptionsModel;

  noSpecifiedCustomerVisible: boolean;
  ownCustomerVisible: boolean;
  ownCustomerSiteVisible: boolean;
  systemDefaultFilters = new UserDesktopOptionsModel();
  selectOptionsSubscription: Subscription;

  constructor(
    @Inject('USER_SESSION_SERVICE') private userSessionService: UserSessionServiceInterface,
    private sessionHelperService: SessionHelperService,
    private store: Store<AppState>,
    private searchFiltersHelpeService: SearchFiltersHelperService
  ) { }

  // Get user options depending on defaultFilters property and set visible controls state
  ngOnInit(): void {
    this.userOptions = { ...(this.defaultFilters ? this.sessionHelperService.defaultUserOptions : this.sessionHelperService.userOptions) };

    const userCanSeeAllProducts = this.sessionHelperService.profileData.system_CanSeeAllProductsInWeb;
    const generalProductsMode = this.sessionHelperService.serverOptions.displayGeneralProductsMode;
    const customerProductsMode = this.sessionHelperService.serverOptions.displayCustomerProductsMode;
    this.noSpecifiedCustomerVisible = userCanSeeAllProducts || generalProductsMode === WebDisplayGeneralProductsMode.Allowed;
    this.ownCustomerVisible = userCanSeeAllProducts || !(generalProductsMode === WebDisplayGeneralProductsMode.Blocked &&
      customerProductsMode === WebDisplayCustomerProductsMode.OwnOnly);
    this.ownCustomerSiteVisible = userCanSeeAllProducts || customerProductsMode !== WebDisplayCustomerProductsMode.OwnOnly;
  }

  setDefaultOrderableFromCurrentFactory(setDefault: boolean) {
    this.userOptions.search_OrderableFromCurrentFactory = setDefault ?
      this.systemDefaultFilters.search_OrderableFromCurrentFactory : !this.systemDefaultFilters.search_OrderableFromCurrentFactory;
  }

  setDefaultProdFiltMethodGeneral(setDefault: boolean) {
    this.userOptions.display_ProdFiltMethodGeneral = setDefault ?
      this.systemDefaultFilters.display_ProdFiltMethodGeneral : !this.systemDefaultFilters.display_ProdFiltMethodGeneral;
  }

  setDefaultProdFiltMethodCustomer(setDefault: boolean) {
    this.userOptions.display_ProdFiltMethodCustomer = setDefault ?
      this.systemDefaultFilters.display_ProdFiltMethodCustomer : !this.systemDefaultFilters.display_ProdFiltMethodCustomer;
  }

  setDefaultProdFiltMethodCustSite(setDefault: boolean) {
    this.userOptions.display_ProdFiltMethodCustSite = setDefault ?
      this.systemDefaultFilters.display_ProdFiltMethodCustSite : !this.systemDefaultFilters.display_ProdFiltMethodCustSite;
  }

  setChanged() {
    this.filtersChanged = true;
    this.filtersChangedChange.emit(true);
  }

  resetToDefault() {
    const newOptions = this.defaultFilters ? this.systemDefaultFilters : this.sessionHelperService.defaultUserOptions;
    this.userOptions = {
      ...this.userOptions, ...this.searchFiltersHelpeService.getSearchFiltersOptions(newOptions)
    };
    this.setChanged();
  }

  clear() {
    this.userOptions = {
      ...this.userOptions,
      search_OrderableFromCurrentFactory: false,
      display_ProdFiltMethodGeneral: false,
      display_ProdFiltMethodCustomer: false,
      display_ProdFiltMethodCustSite: false,
    };
    this.setChanged();
  }

  // Save filters for default or user options depending on defaultFilters property
  // Wait until options are saved and loaded to the store before resolving the Promise
  // skip(1) in order to omit the initial value from store and wait for the new one
  saveSearchFilters(): Promise<null> {
    const saveOptions = this.defaultFilters ? this.userSessionService.saveDefaultUserOptions.bind(this.userSessionService) :
      this.userSessionService.saveUserOptions.bind(this.userSessionService);
    return new Promise(resolve => {
      saveOptions(this.userOptions).subscribe(() => {
        this.store.dispatch(new (this.defaultFilters ? LoadDefaultUserOptions : LoadUserOptions)());
        this.selectOptionsSubscription = this.store.pipe(
          select(this.defaultFilters ? selectDefaultUserOptions : selectUserOptions), skip(1))
          .subscribe(options => {
            if (options) {
              resolve();
            }
          });
      });
    });
  }

  ngOnDestroy() {
    if (this.selectOptionsSubscription) {
      this.selectOptionsSubscription.unsubscribe();
    }
  }

}
