import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { ClientConfigurationModel } from '../models/client-configuration.model';
import { ClientConfigurationServiceInterface } from './client-configuration.service.interface';

@Injectable()
export class HttpClientConfigurationService implements ClientConfigurationServiceInterface {

  constructor(
    private http: HttpClient
  ) { }

  clientConfigurationUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.clientConfigurationCtrl;
  }

  getClientConfiguration(): Observable<ClientConfigurationModel> {
    return this.http.get<ClientConfigurationModel>(this.clientConfigurationUrl());
  }

}
