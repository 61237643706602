"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomerModel = /** @class */ (function () {
    function CustomerModel() {
        this.custOperationDataList = new Array();
        this.custProductList = new Array();
        this.custUnitList = new Array();
        this.invoiceCustCustomerList = new Array();
        this.orgInvRefCustOrderList = new Array();
        this.orgRefCustOrderList = new Array();
        this.orgRefCustOrderTemplateList = new Array();
        this.refCustStocksList = new Array();
        this.suppRefCustOrderList = new Array();
    }
    return CustomerModel;
}());
exports.CustomerModel = CustomerModel;
