"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@ngx-translate/core");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var view_helper_service_1 = require("@core/data-layer/shared/services/view-helper.service");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("../../../../shared/services/session-helper.service");
var i3 = require("../../shared/services/view-helper.service");
var ItemHelperService = /** @class */ (function () {
    function ItemHelperService(translateService, sessionHelperService, viewHelperService) {
        this.translateService = translateService;
        this.sessionHelperService = sessionHelperService;
        this.viewHelperService = viewHelperService;
    }
    /**
     * @description converts [ItemModel][Object] to [ItemViewModel][Object]
     * @param item: [ItemModel][Object]
     * @returns [ItemViewModel][Object]
     */
    ItemHelperService.prototype.convertItemToViewModel = function (item) {
        return tslib_1.__assign({}, item, { itemText: item.item + ' - ' + item.text, supplierText: item.supp ? item.supp.supplier + ' - ' + item.supp.name : '', itemGroupText: item.itGrp ? item.itGrp.itemGroup + ' - ' + item.itGrp.name : '', itemSpecialtyText: item.itspc ? item.itspc.specialty + ' - ' + item.itspc.name : '', traceTypeText: this.translateService.instant("itemTraceType." + td_enumerations_1.ItemTraceType[item.traceType].toLowerCase()), omitted: (item.status === td_enumerations_1.ArticleStatus.Dead), omittedText: this.viewHelperService.getOmittedText(item.status, this.translateService.instant('appCommon.item')), omittedImgUrl: this.viewHelperService.getOmittedImgUrl(item.status), displayPrice: (this.sessionHelperService.serverOptions.displayPriceMode === td_enumerations_1.WebDisplayPriceMode.ShowAll) });
    };
    ItemHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ItemHelperService_Factory() { return new ItemHelperService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.SessionHelperService), i0.ɵɵinject(i3.ViewHelperService)); }, token: ItemHelperService, providedIn: "root" });
    return ItemHelperService;
}());
exports.ItemHelperService = ItemHelperService;
