"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var core_1 = require("@ngx-translate/core");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("@ngx-translate/core");
var Language = /** @class */ (function () {
    function Language() {
    }
    return Language;
}());
exports.Language = Language;
var baseHref = window.location.origin;
var defaultLanguage = 'en-US';
var LanguageService = /** @class */ (function () {
    function LanguageService(http, translateService) {
        this.http = http;
        this.translateService = translateService;
        this.languages = [];
    }
    LanguageService.prototype.initializeLanguage = function (languages, currentLanguage, resolve) {
        this.resolveLanguageFiles = resolve;
        this.currentLanguage = currentLanguage;
        this.languages = languages;
        this.addLanguagesForTranslator();
        this.loadLanguageFiles(['tables', 'enumerations', 'app']);
        this.useLanguage();
    };
    LanguageService.prototype.addLanguagesForTranslator = function () {
        var isoCodes = [];
        for (var _i = 0, _a = this.languages; _i < _a.length; _i++) {
            var language = _a[_i];
            isoCodes.push(language.culture);
        }
        this.translateService.addLangs(isoCodes);
    };
    LanguageService.prototype.useLanguage = function (language) {
        if (language === void 0) { language = ''; }
        if (!language) {
            language = this.currentLanguage;
            // this language will be used as a fallback when a translation isn't found in the current language
            this.translateService.setDefaultLang(defaultLanguage);
        }
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translateService.use(language).toPromise();
    };
    LanguageService.prototype.getLanguageFilesPath = function (moduleFolder, isoCode) {
        return baseHref + "/assets/locales/" + moduleFolder + "/" + isoCode + ".json";
    };
    LanguageService.prototype.loadLanguageFiles = function (moduleFolders) {
        var _this = this;
        var processedModuleFolders = new Array();
        // first of all we should load current language files
        moduleFolders.forEach(function (folder) {
            _this.http.get(_this.getLanguageFilesPath(folder, _this.currentLanguage)).toPromise().then(function (transl) {
                _this.translateService.setTranslation(_this.currentLanguage, transl, true);
                processedModuleFolders.push(folder);
                if (_this.resolveLanguageFiles && (processedModuleFolders.length === moduleFolders.length)) {
                    _this.resolveLanguageFiles(true);
                }
            });
        });
        // load all others language files except current
        if ((this.currentLanguage !== defaultLanguage) && (this.languages.length > 1)) {
            moduleFolders.forEach(function (folder) {
                _this.http.get(_this.getLanguageFilesPath(folder, defaultLanguage)).toPromise().then(function (transl) {
                    _this.translateService.setTranslation(defaultLanguage, transl, true);
                });
            });
        }
    };
    LanguageService.prototype.getLanguages = function () {
        return this.languages;
    };
    LanguageService.prototype.getCurrentLanguage = function () {
        return this.currentLanguage;
    };
    LanguageService.prototype.instant = function (key) {
        return this.translateService.instant(key);
    };
    /**
     * @description Translates [number] enum properties on the objects of the data array [T] into [string]
     * @param data: [T] - An [Array] of [objects] where each object has some [integer] properties
     * @param propertyToEnumMapping: [PropertyToEnumMappingInterface][Array]
     *  Each object in the array has properties that maps the [data] object properties to a corresponding [enum], as well as a string that is used for translating
     * @returns A modified version of the [data] as an [Array], where enum integers has been translated to text strings.
     */
    LanguageService.prototype.translateAllEnums = function (data, propertyToEnumMapping) {
        var _this = this;
        return data ? data.map(function (entry) {
            return _this.translateEnum(entry, propertyToEnumMapping);
        }) : null;
    };
    /**
     * @description Translates [number] enum properties of a single [object] into [string]
     * @param data: [T] - An [object] that has some [integer] properties
     * @param propertyToEnumMapping: [PropertyEnumMappingInterface][Array]
     *  An [Array] of objects, where each object holds information about what properties needs to be translated
     * @returns A modified version of the [data] [object], where each [integer] property that matched [propertyToEnumMapping] has been translated to string
     */
    LanguageService.prototype.translateEnum = function (data, propertyToEnumMapping) {
        var _this = this;
        propertyToEnumMapping.forEach(function (mapObject) {
            if (data[mapObject.sourceProperty] !== undefined) {
                var stringToBeTranslated = mapObject.translate + "." + mapObject.enum[data[mapObject.sourceProperty]].toLowerCase();
                var translatedString = _this.translateService.instant(stringToBeTranslated);
                data[mapObject.textProperty] = translatedString ? translatedString : td_constants_1.TD_VALUES.noTranslation;
            }
        });
        return data;
    };
    LanguageService.prototype.translateEnumValue = function (enumName, value) {
        if (enumName) {
            var enumeration = td_enumerations_1.NAME_TO_ENUM_MAPPING[enumName];
            if (enumeration) {
                if (enumeration[value]) {
                    return this.translateService.instant(enumName + "." + enumeration[value].toLowerCase());
                }
                else {
                    return '';
                }
            }
            else {
                return td_constants_1.TD_VALUES.noTranslation + "(" + enumName + ")[" + value + "]";
            }
        }
        else {
            return td_constants_1.TD_VALUES.noTranslation;
        }
    };
    LanguageService.prototype.translateBooleanValue = function (value) {
        switch (value) {
            case td_constants_1.TD_VALUES.sqlTrue: {
                return this.translateService.instant('appCommon.yes');
            }
            case td_constants_1.TD_VALUES.sqlFalse: {
                return this.translateService.instant('appCommon.no');
            }
            default: {
                return '';
            }
        }
    };
    LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService)); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
exports.LanguageService = LanguageService;
