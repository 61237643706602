import { ProdOverviewData } from './po-data.model';

export enum InfoOverviewType {
  none = 0,
  units = 1,
  orders = 2,
  endoDryers = 3,
  endoListUse = 4,
  endoListClean = 5, // EndoListClean not implemented
  needsList = 6,
  incubators = 7,
  indicatorQueue = 8,
  prodOverview = 9,
  endoProdOverview = 10,
  itemExchange = 11,
  distribution = 12,
  scheduledOperation = 13,
  returnRequested = 14
}

export class InfoOverview {
  configId: number = null; // IOCKEYID
  configName: string = null; // IOCNAME
  configType: InfoOverviewType = null; // IOCCONFIGTYPE
  configuration: ProdOverviewIOConfig | EndoProdOverviewIOConfig; // IOCCONFIGDATA
  data: ProdOverviewData = null; // production data (e.g.: list of KPIs calculated by JSrv)
}

export class ProdOverviewIOConfigBase {
  users: boolean = null;
  incomUnits: boolean = null;
  unitsAtCust: boolean = null;
  ftUnits: boolean = null;
  errorNumbers: boolean = null;
  sbCurrUnit: boolean = null;
  sbProdStatus: boolean = null;
  sbUncleanArea: boolean = null;
  sbCleanArea: boolean = null;
  sbWashers: boolean = null;
  // extra info
  temperatureDegree: string = null;
  allowKpiClick: boolean = null;
}

export class ProdOverviewIOConfig extends ProdOverviewIOConfigBase {
  unitsOnStock: boolean = null;
  sbSterileArea: boolean = null;
  sbSterilizers: boolean = null;

  public constructor(
    ioConfig?: {
      users?: boolean;
      incomUnits?: boolean;
      unitsAtCust?: boolean;
      ftUnits?: boolean;
      errorNumbers?: boolean;
      sbCurrUnit?: boolean;
      sbProdStatus?: boolean;
      sbUncleanArea?: boolean;
      sbCleanArea?: boolean;
      sbWashers?: boolean;
      temperatureDegree?: string;
      allowKpiClick?: boolean;
      unitsOnStock?: boolean;
      sbSterileArea?: boolean;
      sbSterilizers?: boolean;
    }) {
    super();
    if (ioConfig) {
      Object.assign(this, ioConfig);
    }
  }
}

export class EndoProdOverviewIOConfig extends ProdOverviewIOConfigBase {
  unitsInTransp: boolean = null;
  unitsInSterils: boolean = null;
  sbDryingCabs: boolean = null;
  endoIdentifier: number = null;

  public constructor(
    ioConfig?: {
      users?: boolean;
      incomUnits?: boolean;
      unitsAtCust?: boolean;
      ftUnits?: boolean;
      errorNumbers?: boolean;
      sbCurrUnit?: boolean;
      sbProdStatus?: boolean;
      sbUncleanArea?: boolean;
      sbCleanArea?: boolean;
      sbWashers?: boolean;
      temperatureDegree?: string;
      allowKpiClick?: boolean;
      unitsInTransp?: boolean;
      unitsInSterils?: boolean;
      sbDryingCabs?: boolean;
      endoIdentifier?: number;
    }) {
    super();
    if (ioConfig) {
      Object.assign(this, ioConfig);
    }
  }
}

// object that we are receiving from back-end
export class InfoOverviewData {
  configId: number = null;
  data: ProdOverviewData = null;

  public constructor(
    ioData?: {
      configId?: number;
      data?: ProdOverviewData;
    }) {
    if (ioData) {
      this.configId = ioData.configId;
      this.data = new ProdOverviewData();
      Object.assign(this.data, ioData.data);
      this.data.hasConnection = true;
    }
  }
}

