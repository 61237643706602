import { Store, select } from '@ngrx/store';
import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { AppState } from '@core/data-layer/app.state';
import { selectServerDesktopOptions, selectLicense, selectClientConfiguration } from '@core/data-layer/server-session/store/server-session.selectors';
import { selectProfileData, selectUserOptions, selectDefaultUserOptions } from '@core/data-layer/user-session/store/user-session.selectors';
import { selectCurrentCustomer, selectSiteCustomers } from '@core/data-layer/customer/store/customer.selectors';
import { selectCustomerFactories, selectUserFactories } from '@core/data-layer/factory/store/factory.selectors';
import { selectCustomerOpenOrders, selectUserLastOpenOrder } from '@core/data-layer/order/store/order.selectors';
import { LicenseModel } from '@core/license/models/license.model';
import { ClientConfigurationModel } from '@core/client-configuration/models/client-configuration.model';
import { ServerDesktopOptionsModel } from '@core/data-layer/server-session/models/server-desktop-options.model';
import { UserDesktopOptionsModel } from '@core/data-layer/user-session/models/user-desktop-options.model';
import { ProfileDataModel } from '@core/data-layer/user-session/models/profile-data.model';
import {
  WebOrderMode,
  ProdOverviewVisibilityMode,
  ProductLine,
  WebDisplayGeneralProductsMode,
  WebDisplayCustomerProductsMode,
  ProductStockControlMode,
  WebLoginMode,
  FacOrderBookingMode,
  PasswordVerificationMethod,
  PasswordVerify
} from '@core/data-layer/shared/models/td.enumerations';
import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';
import { CustomerModel } from '@app/core/data-layer';
import { FactoryModel } from '@core/data-layer/factory/models/factory.model';
import { OrderModel } from '@core/data-layer/order/models/order.model';
import { WebOrderViewModel } from '@core/data-layer/order/models/web-order-view.model';
import { UtilityService } from '@core/utility/utility.service';
import { TD_MAX_INT32_VALUE } from '@core/data-layer/shared/models/td.constants';
import { TdPopupService } from '../components/td-popup/services/td-popup.service';
import * as ordActions from '@core/data-layer/order/store/order.actions';
import { selectInfoOverviews } from '@app/dashboard/data-layer/info-overview/store/info-overview.selectors';
import { ProductViewModel } from '@core/data-layer/product/models/product-view.model';
import { TdLoadPanelService } from '@shared/components/td-load-panel/services/td-load-panel.service';

@Injectable({
  providedIn: 'root'
})
export class SessionHelperService {

  public license: LicenseModel;
  public clientConfiguration: ClientConfigurationModel;
  public serverOptions: ServerDesktopOptionsModel;
  public userOptions: UserDesktopOptionsModel;
  public defaultUserOptions: UserDesktopOptionsModel;
  public profileData: ProfileDataModel;

  public isAuthenticated: boolean;
  userName: string;
  customerSelected: boolean;
  public currentCustomer: CustomerModel;
  public siteCustomers: CustomerModel[];
  public customerFactories: FactoryModel[];
  public userFactories: FactoryModel[];
  public customerOpenOrders: OrderModel[];
  public userLastOpenOrder: WebOrderViewModel;
  haveInfoOverviews: boolean;
  public $loggedIn = new BehaviorSubject<boolean>(false);
  public $isAdmin = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    private store: Store<AppState>,
    private utilityService: UtilityService,
    private popupService: TdPopupService,
    private loadPanelService: TdLoadPanelService,
    private translateService: TranslateService
  ) {
    // get License
    this.store.pipe(select(selectLicense)).subscribe(lic => {      
      this.license = lic;
    });
    // get System Data
    this.store.pipe(select(selectClientConfiguration)).subscribe(configuration => {
      this.clientConfiguration = configuration;
    });
    // get ServerDesktop options
    this.store.pipe(select(selectServerDesktopOptions)).subscribe(options => {
      if (options) {
        this.serverOptions = options;
      }
      else {
        this.serverOptions = new ServerDesktopOptionsModel();
      }
    });
    // get UserDesktop options
    this.store.pipe(select(selectUserOptions)).subscribe(options => {
        this.userOptions = options;
    });
    // get Default UserDesktop options
    this.store.pipe(select(selectDefaultUserOptions)).subscribe(options => {
      if (options) {
        this.defaultUserOptions = options;
      }
    });
    // get Profile data
    this.store.pipe(select(selectProfileData)).subscribe(profData => {
      if (profData) {
        this.$isAdmin.next(profData.system_SystemAdminRights);
        this.profileData = profData;
      }
      else {
        this.profileData = new ProfileDataModel();
      }
    });
    // is authenticated
    this.authService.authStatus$().subscribe(status => {
      this.isAuthenticated = status;
      this.userName = this.authService.getUserName();
      if (this.loggedIn()) {
        this.reloadCustomerOpenOrders();
      }
      this.updateLoggedInSubject();
    });
    // get site Customers
    this.store.pipe(select(selectSiteCustomers)).subscribe(custs => {
      this.siteCustomers = custs;
    });
    // get current Customer
    this.store.pipe(select(selectCurrentCustomer)).subscribe(cust => {
      this.currentCustomer = cust;
      this.customerSelected = !this.utilityService.objIsEmpty(cust);
      if (this.loggedIn()) {
        this.reloadCustomerOpenOrders();
      }
      this.updateLoggedInSubject();
    });
    // get customer's factories
    this.store.pipe(select(selectCustomerFactories)).subscribe(custFactories => {
      this.customerFactories = custFactories;
    });
    // get customer's open orders
    this.store.pipe(select(selectCustomerOpenOrders)).subscribe(orders => {
      this.customerOpenOrders = orders;
      if (this.isAuthenticated) {
        this.initUserLastOpenOrder();
      }
    });
    // get customer's factories
    this.store.pipe(select(selectUserFactories)).subscribe(userFactories => {
      this.userFactories = userFactories;
    });
    // get user's last open order
    this.store.pipe(select(selectUserLastOpenOrder)).subscribe(order => {
      this.userLastOpenOrder = order;
    });
    // check info overviews
    this.store.pipe(select(selectInfoOverviews)).subscribe(infoOverviews => {
      this.haveInfoOverviews = !!infoOverviews && (infoOverviews.length > 0);
    });
  }

  loggedIn() {
    return this.customerSelected && this.isAuthenticated;
  }

  /*
    method should determine if login is required (loginAlways) or not (!loginAlways)
      if login is required, should check if user is logged in and a customer is selected
      if login is not required
  */
  allowSearch(): boolean {
    const userLoggedIn = this.$loggedIn.value;
    const loginAlways = this.clientConfiguration.loginMode === WebLoginMode.Always;

    return loginAlways ? userLoggedIn && this.loggedIn() : true;
    // if (loginAlways) {
    //   return userLoggedIn && this.loggedIn();
    // }
    // else if (!loginAlways) {
    //   return true;
    // }
    // else {
    //   return false;
    // }

    // return /*todo: uWebGlobal.LoginAlwaysRequired*/false && !this.loggedIn();
  }
  canViewMedia(): boolean {
    return this.license.picture;
  }
  canViewOrder(): boolean {
    return this.canEditOrder();
  }

  canEditOrder(): boolean {
    return (this.serverOptions.orderMode === WebOrderMode.On) && this.license && this.license.stockBasic &&
      this.loggedIn();
  }

  canViewOperation(): boolean {
    return this.loggedIn() && this.license && this.license.operation;
  }

  canViewProductionOverview(): boolean {
    return (this.serverOptions.prodOverviewVisibilityMode === ProdOverviewVisibilityMode.AvailableInWeb) &&
      this.haveInfoOverviews && this.productionOverviewLicense();
  }

  productionOverviewLicense(): boolean {
    return !!this.license && [ProductLine.SDM, ProductLine.TDOC2000, ProductLine.EndoCycle].includes(this.license.productLine);
  }

  canViewStock(): boolean {
    return this.clientConfiguration.prodStockMode !== ProductStockControlMode.Simple;
  }

  canFastTrack(): boolean {
    return this.loggedIn() && this.license && !!(this.license.fastTrackBasic && this.serverOptions && (this.serverOptions.defaultFTCoKeyId > 0));
  }

  showFastTrack(): boolean {
    return this.license && this.license.fastTrackBasic;
  }

  canViewEndoscope(): boolean {
    return this.license && this.license.endoscope;
  }

  updateLoggedInSubject() {
    this.$loggedIn.next(this.customerSelected && this.isAuthenticated);
  }

  get customerFactoryKeyIds(): number[] {
    const customerFactoryIds: number[] = [];
    if (this.customerFactories && (this.customerFactories.length > 0)) {
      this.customerFactories.forEach(factory => { customerFactoryIds.push(factory.keyId); });
    }
    return customerFactoryIds;
  }

  get currentFactoryKeyId(): number {
    if (this.customerFactories && (this.customerFactories.length > 0)) {
      return this.customerFactories[0].keyId;
    }
    else {
      return 0;
    }
  }

  get userPrimaryFactoryKeyId(): number {
    if (this.userFactories && (this.userFactories.length > 0)) {
      return this.userFactories[0].keyId;
    }
    else {
      return 0;
    }
  }

  get orderFactoryKeyIds(): number[] {
    const orderFactoryIds: number[] = [];
    if (this.userLastOpenOrder) {
      orderFactoryIds.push(this.userLastOpenOrder.details.suppRefFacKeyId);
    }
    if (orderFactoryIds.length > 0) {
      return orderFactoryIds;
    }
    else {
      return this.customerFactoryKeyIds;
    }
  }

  quantityIsValid(value: number, maxValue: number = TD_MAX_INT32_VALUE): boolean {
    if (!value) {
      return false;
    }
    else if (value > TD_MAX_INT32_VALUE) {
      this.popupService.showInfo(this.translateService.instant('appMessages.maxQuantityValidation')
        .replace('%d', Math.floor(maxValue).toString()));
      return false;
    }
    else {
      return true;
    }
  }

  reloadCustomerOpenOrders() {
    this.store.dispatch(new ordActions.LoadCustomerOpenOrders(this.currentCustomer.keyId));
  }

  initUserLastOpenOrder() {
    const loggedInUser = this.authService.getClaims();
    if (loggedInUser && this.customerOpenOrders && (this.customerOpenOrders.length > 0)) {
      // filter user's open orders and get the newest one in case exists
      const userOpenOrders = this.customerOpenOrders.filter(order => order.orgUserKeyId == loggedInUser.user_id);
      if (userOpenOrders && (userOpenOrders.length > 0)) {
        if (!this.userLastOpenOrder || !userOpenOrders.find(order => order.keyId === this.userLastOpenOrder.details.keyId)) {
          this.reloadLastOpenOrder(userOpenOrders[0].keyId);
        }
      }
      // get the newest customer's open order
      else {
        this.reloadLastOpenOrder(this.customerOpenOrders[0].keyId);
      }
    }
    else {
      this.store.dispatch(new ordActions.SetUserLastOpenOrder(null));
    }
  }

  reloadLastOpenOrder(orderKeyId: number) {
    this.loadPanelService.hideLoadPanel();
    this.store.dispatch(new ordActions.LoadUserLastOpenOrder(orderKeyId, this.currentCustomer.keyId));
  }

  getChargeOptions() {
    return this.currentCustomer ? this.currentCustomer.chargeOptions : 0;
  }

  canOrderProduct(product: ProductViewModel): boolean {
    if (product.omitted || !this.currentCustomer) {
      return false;
    }
    if (product.custKeyId < 1) {
      return (this.serverOptions.displayGeneralProductsMode === WebDisplayGeneralProductsMode.Allowed);
    }

    switch (this.serverOptions.displayCustomerProductsMode) {
      case WebDisplayCustomerProductsMode.OwnOnly: {
        return (this.currentCustomer.keyId === product.custKeyId);
      }
      case WebDisplayCustomerProductsMode.OwnSiteOnly: {
        return this.siteCustomers && this.siteCustomers.some(cust => cust.keyId === product.custKeyId);
      }
      case WebDisplayCustomerProductsMode.OwnSiteOrCust: {
        return (this.currentCustomer.keyId === product.custKeyId) || (this.siteCustomers && this.siteCustomers.some(cust => cust.keyId === product.custKeyId));
      }
      case WebDisplayCustomerProductsMode.All: {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  getBookingEnabled(): boolean {
    return this.license.allowBooking && !!this.customerFactories && (this.customerFactories.length > 0) &&
      this.customerFactories.some(fac => ![FacOrderBookingMode.Disabled, null].includes(fac.orderBookingMode));
  }

  isAdminUser(): boolean {
    return this.profileData.system_SystemAdminRights;
  }

  getPassVerifMethod(serverOptions?: ServerDesktopOptionsModel): PasswordVerificationMethod {
    const options = serverOptions ? serverOptions : this.serverOptions;
    if (options.passwordVerificationMethod === PasswordVerificationMethod.None) {
      if (this.clientConfiguration.passwordVerify === PasswordVerify.ActiveDirectory) {
        return PasswordVerificationMethod.ActiveDirectory;
      }
      else {
        return PasswordVerificationMethod.TDOC;
      }
    }
    else {
      return options.passwordVerificationMethod;
    }
  }

  getUserOptions(): UserDesktopOptionsModel {
    return this.userOptions ? this.userOptions : this.defaultUserOptions;
  }

  urlAllowsOrderCart(url: string): boolean {
    return (url === '/') || url.includes('/products') || url.includes('/items') || url.includes('/search');
  }

  getSigningOut(): boolean {
    return this.authService.getSigningOut();
  }
}
