import { Component } from '@angular/core';

import { TdLoadPanelService } from './services/td-load-panel.service';

@Component({
  selector: 'td-load-panel',
  templateUrl: './td-load-panel.component.html',
  styleUrls: ['./td-load-panel.component.less']
})
export class TdLoadPanelComponent {

  constructor(public loadPanelService: TdLoadPanelService) { }

}
