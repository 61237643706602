"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_configuration_service_1 = require("@core/authentication/services/auth-configuration.service");
var i0 = require("@angular/core");
var i1 = require("../../authentication/services/auth-configuration.service");
var AuthConfigurationInitializer = /** @class */ (function () {
    function AuthConfigurationInitializer(authConfigurationService) {
        this.authConfigurationService = authConfigurationService;
    }
    AuthConfigurationInitializer.prototype.initializeAuthOptions = function () {
        return this.authConfigurationService.getAuthOptions();
    };
    AuthConfigurationInitializer.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthConfigurationInitializer_Factory() { return new AuthConfigurationInitializer(i0.ɵɵinject(i1.AuthConfigurationService)); }, token: AuthConfigurationInitializer, providedIn: "root" });
    return AuthConfigurationInitializer;
}());
exports.AuthConfigurationInitializer = AuthConfigurationInitializer;
