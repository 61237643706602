"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var server_session_reducer_1 = require("./server-session/store/server-session.reducer");
var user_session_reducer_1 = require("./user-session/store/user-session.reducer");
var customer_reducer_1 = require("./customer/store/customer.reducer");
var info_overview_reducer_1 = require("@app/dashboard/data-layer/info-overview/store/info-overview.reducer");
var factory_reducer_1 = require("./factory/store/factory.reducer");
var order_reducer_1 = require("./order/store/order.reducer");
var app_constants_1 = require("@app/shared/models/app.constants");
// REDUCERS
exports.reducers = {
    serverSession: server_session_reducer_1.serverSessionReducer,
    userSession: user_session_reducer_1.userSessionReducer,
    order: order_reducer_1.orderReducer,
    customer: customer_reducer_1.customerReducer,
    factory: factory_reducer_1.factoryReducer,
    infoOverview: info_overview_reducer_1.infoOverviewReducer
};
var localStorageKey = app_constants_1.LOCALSTORAGE_APP_STATE;
// Meta-reducers allow to pre-process actions before normal reducers are invoked
function metaReducerFactory() {
    return function (reducer) {
        return function (state, action) {
            // initialize state from local storage
            if (state === undefined) {
                var persisted = localStorage.getItem(localStorageKey);
                return persisted ? JSON.parse(persisted) : reducer(state, action);
            }
            // save state in local storage
            else {
                var nextState = reducer(state, action);
                localStorage.setItem(localStorageKey, JSON.stringify(nextState));
                return nextState;
            }
        };
    };
}
exports.metaReducerFactory = metaReducerFactory;
