<div class="row page-container" *ngIf="textFormData && datesFormData">
  <div class="col-sm-12">

    <div class="row page-title">
      <div class="col-md-8">
        {{ 'operationList.scheduledOperations' | translate }}
      </div>
    </div>

    <div class="row page-details">
      <div class="text-search-form d-flex">
        <dx-form
          [formData]="textFormData"
          labelLocation="top"
          colCount="1"
          (keyup.enter)="setSearchParameters(operationSearchType.OperationID)"
        >
        <dxi-item [label]="{text: 'operationList.searchFor' | translate, showColon: false}">
          <div *dxTemplate>
            <dx-text-box id="searchText" [(value)]="textFormData.text"></dx-text-box>
          </div>
        </dxi-item>
        </dx-form>
        <div class="d-flex justify-content-between align-items-end ml-1">
          <dx-button class="action-icon-button tertiary-button" icon="search" (onClick)="setSearchParameters(operationSearchType.OperationID)"></dx-button>
        </div>
      </div>
      <div class="dates-search-form ml-5 d-flex">
        <dx-form
          [formData]="datesFormData"
          labelLocation="top"
          colCount="2"
          (keyup.enter)="setSearchParameters(operationSearchType.Date)">
          <dxi-item [label]="{ text: 'operationList.fromDate' | translate, showColon: false}">
            <div *dxTemplate>
              <dx-date-box
                [displayFormat]="transformDateAsShort"
                useMaskBehavior="true"
                [min]="TD_MIN_DATE_VALUE"
                [max]="datesFormData.dateTo"
                [dateOutOfRangeMessage]="'appMessages.valueOutOfRange' | translate"
                [cancelButtonText]="'button.cancel' | translate"
                [applyButtonText]="'button.ok' | translate"
                [(value)]="datesFormData.dateFrom"
                [showClearButton]="true"
                (onOpened)="translateTodayButton($event)"
                type="datetime">
              </dx-date-box>
            </div>
          </dxi-item>
          <dxi-item cssClass="ml-1" [label]="{ text: 'operationList.toDate' | translate, showColon: false}">
            <div *dxTemplate>
              <dx-date-box
                [displayFormat]="transformDateAsShort"
                useMaskBehavior="true"
                [min]="datesFormData.dateFrom || TD_MIN_DATE_VALUE"
                [dateOutOfRangeMessage]="'appMessages.valueOutOfRange' | translate"
                [cancelButtonText]="'button.cancel' | translate"
                [applyButtonText]="'button.ok' | translate"
                [(value)]="datesFormData.dateTo"
                [showClearButton]="true"
                (onOpened)="translateTodayButton($event)"
                type="datetime">
              </dx-date-box>
            </div>
          </dxi-item>
        </dx-form>
        <div class="d-flex justify-content-between align-items-end ml-1">
          <dx-button
            class="action-icon-button tertiary-button"
            icon="search"
            (onClick)="setSearchParameters(operationSearchType.Date)"></dx-button>
        </div>
      </div>
    </div>

    <div class="row grid-border">
        <td-data-grid
        [gridAlias]="gridIdentifier"
        [data]="operationsData"
        [mainTable]="mainTable"
        [gridStructure]="gridStructure"
        [highlightText]="textFormData && textFormData.text ? textFormData.text : ''"
        [masterDetail]="masterDetailTemplate"
        [enableMasterDetail]="true"
        [remoteOperations]="true"
        [columnChooser]="true"
        [showBorders]="false"
        (refreshGridData)="setSearchParameters(lastSearchType)"
        (rowExpand)="getOrders($event)"
        keyExpression="keyId"
        [templates]="{
          OrdersLeadStatus: operationOrdersTemplate,
          ident: operationLinkTemplate,
          addOrder: addOrderTemplate
        }"
      ></td-data-grid>
    </div>
  </div>
</div>

<ng-template #operationLinkTemplate let-item="item">
  <a [routerLink]="['/operations', item.data.keyId]"><span [innerHTML]="highlightSearchText(item.data.ident)"></span></a>
</ng-template>

<ng-template #operationOrdersTemplate let-item="item">
  <div class="cell-center">
    <div class="order-status-count">{{ item.data[dynamicFields.totalOrderCount]}}</div>

    <ng-container *ngIf="item.data[dynamicFields.ordersLeadStatus] !== ordersLeadStatus.Neutral">
      <img class="order-status-img" [src]="operationOrderStateImgPathEnum[item.data[dynamicFields.ordersLeadStatus]]">
    </ng-container>
  </div>
</ng-template>


<ng-template #masterDetailTemplate let-item="item">
  <ng-container>
    <td-data-grid
      [gridAlias]="subGridIdentifier"
      [gridStructure]="subGridStructure"
      [mainTable]="subGridTable"
      [data]="operationOrdersData[item.data.keyId]"
      [pagination]="false"
      [templates]="{
        orgRefNo: orderRefNoTemplate,
        orderStatus: orderStatusTemplate
      }">
    </td-data-grid>
  </ng-container>
</ng-template>

<ng-template #orderRefNoTemplate let-item="item">
  <a [routerLink]="['/orders', item.data.keyId]">{{ item.data.orgRefNo }}</a>
</ng-template>

<ng-template #orderStatusTemplate let-item="item">
  <div class="cell-center">

    <ng-container *ngIf="item.data.orderLeadStatus === ordersLeadStatus.Done">
      <ng-container *ngTemplateOutlet="orderStatusDoneTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="item.data.orderLeadStatus === ordersLeadStatus.Alert">
      <ng-container *ngTemplateOutlet="alertNotDelivered; context: {item: item}"></ng-container>
    </ng-container>

    <ng-container *ngIf="item.data.orderLeadStatus === ordersLeadStatus.Warning">
      <ng-container *ngTemplateOutlet="warningNotDelivered; context: {item: item}"></ng-container>
    </ng-container>

  </div>
</ng-template>

<ng-template #orderStatusDoneTemplate>
  <img class="order-status-img" [src]="operationOrderStateImgPathEnum[ordersLeadStatus.Done]">
</ng-template>

<!-- If suppStatus is cancelled, use no icon - else, use alert icon -->
<ng-template let-item="item" #alertNotDelivered>
  <ng-container *ngIf="item.data.suppStatus === suppStatus.Cancelled; else alert"></ng-container>
</ng-template>
<ng-template #alert>
  <img class="order-status-img" [src]="operationOrderStateImgPathEnum[ordersLeadStatus.Alert]">
</ng-template>

<!-- If suppStatus is cancelled, use no icon - else, use warning icon -->
<ng-template let-item="item" #warningNotDelivered>
  <ng-container *ngIf="item.data.suppStatus === suppStatus.Cancelled; else warning"></ng-container>
</ng-template>
<ng-template #warning>
  <img class="order-status-img" [src]="operationOrderStateImgPathEnum[ordersLeadStatus.Warning]">
</ng-template>


<ng-template let-item="item" #addOrderTemplate>
  <ng-container *ngIf="item.data.statusEnumValue === operationStatus.Accepted || item.data.statusEnumValue === operationStatus.Planned">
    <div class="cell-center">
      <dx-button class="grid-action-button"
        (onClick)="addOrder(item.data.keyId, item.data.custStokKeyId, item.data.startTime)"
        hint="{{ 'operationDetails.createOrder' | translate }}"
        icon=".\assets\img\ordersAll.png">
      </dx-button>
    </div>
  </ng-container>
</ng-template>
