import { Component, TemplateRef, ViewChild, Input } from '@angular/core';

import { SessionHelperService } from '@shared/services/session-helper.service';
import { TdPopupService } from '@shared/components/td-popup/services/td-popup.service';
import { TdSearchFiltersComponent } from '../td-search-filters/td-search-filters.component';
import { SearchFiltersHelperService } from '@shared/services/search-filters-helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'td-search-filters-button',
  templateUrl: './td-search-filters-button.component.html',
  styleUrls: ['./td-search-filters-button.component.less']
})
export class TdSearchFiltersButtonComponent {

  @Input() darkMode = false;

  filterIcon: string = 'SearchFilter.svg';
  activeFilterIcon: boolean;
  searchFiltersSubscription: Subscription;
  @ViewChild('searchFiltersTemplate', { static: false}) searchFiltersTemplate: TemplateRef<any>;
  @ViewChild('searchFilters', { static: false}) searchFilters: TdSearchFiltersComponent;

  constructor(
    public sessionHelperService: SessionHelperService,
    protected popupService: TdPopupService,
    private searchFiltersHelperService: SearchFiltersHelperService
  ) {
    this.setFilterButtonIcon();
    this.listenForFilterChanges();
  }

  ngOnDestroy(): void {
    this.searchFiltersSubscription.unsubscribe();
  }

  setFilterButtonIcon() {
    // Change icon if any filter option is activated
    if (Object.values(this.searchFiltersHelperService.getSearchFiltersOptions(this.sessionHelperService.getUserOptions()))
      .some((option: boolean) => option))
    {
      this.activeFilterIcon = true;
    }
    else {
      this.activeFilterIcon = false;
    }
  }

  listenForFilterChanges(): void {
    this.searchFiltersSubscription = this.searchFiltersHelperService.searchFilterOptions$.subscribe(value => {
      if (Object.values(value).some((option: boolean) => option)) {
        this.activeFilterIcon = true;
      }
      else {
        this.activeFilterIcon = false;
      }
    });
  }

  defineSearchFilters() {
    this.popupService.showTemplate('search.searchFilters', this.searchFiltersTemplate).then((result) => {
      if (result) {
        this.setFilterButtonIcon();
      }
    });
  }

  cancel() {
    this.popupService.cancelPopup();
  }

  apply() {
    this.searchFilters.saveSearchFilters().then( () => {
      this.popupService.confirmPopup();
    });
  }

}
