<dx-drop-down-box #searchInputBox
                  valueExpr="keyId"
                  [(value)]="searchValue"
                  [placeholder]="placeHolder"
                  [deferRendering]="false"
                  [displayExpr]="inputBoxDisplayExpr"
                  [showClearButton]="true"
                  [acceptCustomValue]="true"
                  [openOnFieldClick]="false"
                  valueChangeEvent=""
                  [dataSource]="searchResultData"
                  (onInput)="onInputSearchText()"
                  (onOpened)="onOpenedSearchResult($event)"
                  (onClosed)="onClosedSearchResult()"
                  (onValueChanged)="onChangedSearchValue()"
                  (keydown.enter)="enterKeyDown()"
                  (keyup.arrowdown)="arrowDownKeyUp()"
                  (onFocusIn)="mouseFocusDropdownInput()"
                  [width]="dropDownWidth"
                  [disabled]="disabled">

  <div *dxTemplate="let data of 'content'">
    <div class="grid-border">
      <td-data-grid #searchGrid                  
                    [gridAlias]="gridIdentifier"
                    [data]="searchResultData"
                    [mainTable]="mainTable"
                    [gridStructure]="gridStructure"
                    [remoteOperations]="true"
                    [enableStateStoring]="false"
                    [highlightText]="searchInputBox.text"
                    [scrolling]="{ mode: 'infinite' }"
                    [selection]="{ mode: 'single' }"
                    [hoverStateEnabled]="true"
                    [pagination]="{ enabled: false, pageIndex: 0, pageSize: 10 }"
                    [width]="gridWidth"
                    [height]="300"
                    [templates]="templates"
                    [focusRowEnabled]="focusSearchGrid"
                    [focusedRowKey]="focusedRowKey"
                    (selectedRowChange)="onChangeSelectedSearchRow($event)"
                    (enterPressedOnFocusedRow)="enterPressedOnFocusedRow($event)"
                    (escapeKeyUpWhileGridFocused)="focusDropdownInput()">
      </td-data-grid>
    </div>
  </div>
</dx-drop-down-box>
