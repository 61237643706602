"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PaginationArgsModel = /** @class */ (function () {
    function PaginationArgsModel() {
    }
    return PaginationArgsModel;
}());
exports.PaginationArgsModel = PaginationArgsModel;
var OrderByArgsModel = /** @class */ (function () {
    function OrderByArgsModel(fieldName, sortOrder) {
        this.fieldName = fieldName;
        this.sortOrder = sortOrder;
    }
    return OrderByArgsModel;
}());
exports.OrderByArgsModel = OrderByArgsModel;
var BaseGridArgsModel = /** @class */ (function () {
    function BaseGridArgsModel(mainTable, gridStructure) {
        this.mainTable = mainTable;
        this.selectedFields = new Array();
        this.searchTextFields = new Array();
        this.orderByFields = new Array();
        this.paginationArgs = new PaginationArgsModel();
        this.gridStructure = gridStructure;
    }
    return BaseGridArgsModel;
}());
exports.BaseGridArgsModel = BaseGridArgsModel;
