"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var orderActions = require("./order.actions");
exports.initialOrderState = {
    customerOpenOrders: null,
    userLastOpenOrder: null
};
function orderReducer(state, action) {
    if (state === void 0) { state = exports.initialOrderState; }
    switch (action.type) {
        case orderActions.OrderActionTypes.LoadCustomerOpenOrders:
            return handleLoadCustomerOpenOrders(state, action);
        case orderActions.OrderActionTypes.SetCustomerOpenOrders:
            return handleSetCustomerOpenOrders(state, action);
        case orderActions.OrderActionTypes.LoadUserLastOpenOrder:
            return handleLoadUserLastOpenOrder(state, action);
        case orderActions.OrderActionTypes.SetUserLastOpenOrder:
            return handleSetUserLastOpenOrder(state, action);
        default:
            return state;
    }
}
exports.orderReducer = orderReducer;
function handleLoadCustomerOpenOrders(state, action) {
    return state;
}
function handleSetCustomerOpenOrders(state, action) {
    return Object.assign({}, state, { customerOpenOrders: action.payload });
}
function handleLoadUserLastOpenOrder(state, action) {
    return state;
}
function handleSetUserLastOpenOrder(state, action) {
    return Object.assign({}, state, { userLastOpenOrder: action.payload });
}
