import { HttpAuthenticationService } from '@core/authentication/services/http-authentication.service';
import { SignalRInfoOverviewService } from '@app/dashboard/data-layer/info-overview/services/signal-r-info-overview.service';
import { HttpCustomerService } from '@core/data-layer/customer/services/http-customer.service';
import { HttpOrderService } from '@core/data-layer/order/services/http-order.service';
import { HttpFactoryService } from '@core/data-layer/factory/services/http-factory.service';
import { HttpUnitService } from '@core/data-layer/unit/services/http-unit.service';
import { HttpUserSessionService } from '@core/data-layer/user-session/services/http-user-session.service';
import { HttpServerSessionService } from '@core/data-layer/server-session/services/http-server-session.service';
import { HttpTableInfoService } from '@core/table-info/services/http-table-info.service';
import { HttpMediaService } from '@core/media/services/http-media.service';
import { HttpSearchService } from '@core/search/services/http-search.service';
import { HttpErrorReportService } from '@core/error-report/services/http-error-report.service';
import { HttpProductService } from '@core/data-layer/product/services/http-product.service';
import { HttpItemService } from '@core/data-layer/item/services/http-item.service';
import { HttpOrderTemplateService } from '@core/data-layer/order-template/services/http-order-template.service';
import { HttpLicenseService } from '@core/license/services/http-license.service';
import { SignalRLicenseBindingService } from '@core/license/services/signal-r-license-binding.service';
import { HttpClientConfigurationService } from '@core/client-configuration/services/http-client-configuration.service';
import { HttpExternalDocumentsService } from '@core/data-layer/external-documents/services/http-external-documents.service';
import { HttpOperationDataService } from '@core/data-layer/operation-data/services/http-operation-data.service';
import { HttpSubstitutionsService } from '@core/substitutions/services/http-substitutions.service';

export const environment = {
  production: true,
  telemetry: true,
  logHttpErrors: true,
  logInternalErrors: true,
  authenticationServiceType: HttpAuthenticationService,
  infoOverviewServiceType: SignalRInfoOverviewService,
  customerServiceType: HttpCustomerService,
  orderServiceType: HttpOrderService,
  productServiceType: HttpProductService,
  factoryServiceType: HttpFactoryService,
  unitServiceType: HttpUnitService,
  userSessionServiceType: HttpUserSessionService,
  serverSessionServiceType: HttpServerSessionService,
  tableInfoServiceType: HttpTableInfoService,
  mediaServiceType: HttpMediaService,
  searchServiceType: HttpSearchService,
  errorReportServiceType: HttpErrorReportService,
  itemServiceType: HttpItemService,
  orderTemplateServiceType: HttpOrderTemplateService,
  licenseServiceType: HttpLicenseService,
  licenseBindingServiceType: SignalRLicenseBindingService,
  clientConfigurationServiceType: HttpClientConfigurationService,
  externalDocumentsServiceType: HttpExternalDocumentsService,
  operationDataServiceType: HttpOperationDataService,
  substitutionsServiceType: HttpSubstitutionsService
};
