export const API_HUB_PATHS = {
  apiUrl: 'api/',
  serverUrl: './',
  // HUB(s)
  infoOverviewsHub: 'InfoOverview',
  licenseBindingHub: 'LicenseBinding',
  // CONTROLLER(s)
  infoOverviewsCtrl: 'InfoOverviews',
  customersCtrl: 'Customers',
  ordersCtrl: 'Orders',
  productsCtrl: 'Products',
  itemsCtrl: 'Items',
  factoriesCtrl: 'Factories',
  unitsCtrl: 'Units',
  desktopDataCtrl: 'DesktopData',
  scrollingDataUrl: 'ScrollingData',
  clientConfigurationCtrl: 'ClientConfiguration',
  licenseCtrl: 'License',
  userProfileDataCtrl: 'UserProfileData',
  translationsCtrl: 'Translations',
  mediaCtrl: 'Media',
  searchCtrl: 'Search',
  errorCtrl: 'Errors',
  orderTemplatesCtrl: 'OrderTemplates',
  externalDocumentsCtrl: 'ExternalDocuments',
  operationsCtrl: 'Operations',
  substitutionsCtrl: 'Substitutions',
  // COMMON METHOD(s)
  details: '/Details',
  locate: '/Locate',
  // METHOD(s) for License
  bindings: '/Bindings',
  // METHOD(s) for Customers
  bySite: '/BySite',
  // METHOD(s) for Orders
  cancelOrder: '/Cancel',
  sendOrder: '/Send',
  copyOrder: '/Copy',
  saveOrder: '/Save',
  saveOrderLines: '/SaveLines',
  createFromTemplate: '/CreateFromTemplate',
  changeBookingMode: '/ChangeBookingMode',
  checkVirtualBooking: '/CheckVirtualBooking',
  updateActualBooking: '/UpdateActualBooking',
  getActualBooking: '/GetActualBooking',
  getByCustomerAndOrgStatus: '/GetByCustomerAndOrgStatus',
  getOrderLine: '/GetLine',
  // METHOD(s) for Unit
  getFastTrackInfo: '/GetFastTrackInfo',
  fastTrackCodes: '/FastTrackCodes',
  addFastTrackCode: '/AddFastTrackCode',
  cancelFastTrackCode: '/CancelFastTrackCode',
  customerCanFastTrack: '/CustomerCanFastTrack',
  hasFastTrackCode: '/HasFastTrackCode',
  getStandardCommentTypes: '/CommentTypes',
  getStandardComments: '/Comments',
  addStandardComment: '/Comment',
  eventList: '/EventList',
  searchByUnit: '/SearchByUnit',
  awaitingIndicator: '/AwaitingIndicator',
  // METHOD(s) for DesktopData
  getServerOptions: '/GetServerOptions',
  saveServerOptions: '/SaveServerOptions',
  getUserOptions: '/GetUserOptions',
  saveUserOptions: '/SaveUserOptions',
  getDefaultUserOptions: '/GetDefaultUserOptions',
  saveDefaultUserOptions: '/SaveDefaultUserOptions',
  getComponentState: '/GetComponentState',
  saveComponentState: '/SaveComponentState',
  getGridStructure: '/GetGridStructure',
  // METHOD(s) for ScrollingData
  findPreviousNextKeyId: '/FindPreviousNextKeyId',
  findFirstLastKeyId: '/FindFirstLastKeyId',
  // METHOD(s) for Factories
  getByCustomer: '/GetByCustomer',
  getByLoggedInUser: '/GetByLoggedInUser',
  // METHOD(s) for Media
  getMediaInfoList: '/Series', // TODO: Should be renamed, as it only collects info, not series. Backend needs to implement Series request
  getImage: '/Image',
  getText: '/Text',
  getVideo: '/Video',
  getSeries: '/Series',
  // METHOD(s) for Search
  search: '/Search',
  // METHOD(s) for Products
  getDetails: '/GetDetails',
  // METHOD(s) for Order templates
  articles: '/Articles',
  // METHOD(s) for External Documents
  externalDocumentList: '/ExternalDocumentList',
  file: '/File',
  // METHOD(s) for Operations
  addUnit: '/AddUnit',
  removeUnit: '/RemoveUnit',
  startOperation: '/Start',
  stopOperation: '/Stop',
  getCreateOperationOrderInfo: '/GetCreateOperationOrderInfo',
  // METHOD(s) for Substitutions
  orderTemplateSubstitutionArticles: '/OrderTemplateSubstitutionArticles',
  orderSubstitutionArticles: '/OrderSubstitutionArticles',
  orderLineIdenticalArticles: '/OrderLineIdenticalArticles',
  saveAlternatives: '/SaveAlternatives',
  saveIdenticalBlockedState: '/SaveIdenticalBlockedState',
  // METHOD(s) for Client Configuration
  locale: '/Locale',
  // METHOD(s) for Password Expire
  passwordExpire: './PasswordExpires',
  customerKeyId: '/CustomerKeyId'
};
