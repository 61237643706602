import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { LoadDefaultUserOptions } from '@core/data-layer/user-session/store/user-session.actions';
import { UserDesktopOptionsModel } from '@app/core/data-layer/user-session/models/user-desktop-options.model';
import { selectDefaultUserOptions } from '@app/core/data-layer/user-session/store/user-session.selectors';


@Injectable({
  providedIn: 'root'
})
export class DefaultUserDesktopOptionsInitializer {

  constructor(
    private store: Store<AppState>
  ) { }

  initializeDefaultUserDesktopOptions(): Promise<UserDesktopOptionsModel> {
    return new Promise(resolve => {
      this.store.dispatch(new LoadDefaultUserOptions());
      this.store.pipe(select(selectDefaultUserOptions)).subscribe(userDesktopOptions => {
        if (userDesktopOptions) {
          resolve(userDesktopOptions);
        }
      });
    });
  }

}
