import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { CustomerServiceInterface } from './customer.service.interface';
import { CustomerModel } from '../models/customer.model';

@Injectable()
export class HttpCustomerService implements CustomerServiceInterface {

  constructor(
    private http: HttpClient
  ) { }

  customersUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.customersCtrl;
  }

  getUserCustomers(): Observable<CustomerModel[]> {
    return this.http.get<CustomerModel[]>(`${this.customersUrl()}`);
  }

  getSiteCustomers(cuSiteKeyId: number): Observable<CustomerModel[]> {
    return this.http.get<CustomerModel[]>(`${this.customersUrl()}${API_HUB_PATHS.bySite}(${cuSiteKeyId})`);
  }

}
