"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var search_args_model_1 = require("../models/search-args.model");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var grid_data_helper_service_1 = require("@shared/services/grid-data-helper.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var base_grid_args_model_1 = require("@core/data-layer/shared/models/base-grid-args.model");
var operation_filter_service_1 = require("../filters/operation-filter.service");
var utility_service_1 = require("@core/utility/utility.service");
var i0 = require("@angular/core");
var i1 = require("../../../shared/services/session-helper.service");
var i2 = require("../../../shared/services/grid-data-helper.service");
var i3 = require("../filters/operation-filter.service");
var SearchManagerService = /** @class */ (function () {
    function SearchManagerService(searchService, sessionHelperService, gridDataHelperService, operationFilterService) {
        this.searchService = searchService;
        this.sessionHelperService = sessionHelperService;
        this.gridDataHelperService = gridDataHelperService;
        this.operationFilterService = operationFilterService;
        this.defaultShowAllResult = false;
        this.defaultFilterIsSet = false;
        this.defaultPageRowCount = 25;
        this.defaultStartingRow = 0;
        this.defaultViewCommonData = true;
        this.defaultAutoPictureMode = td_enumerations_1.SearchAutoPictureMode.Off;
        this.defaultIdentifier = 0;
        this.defaultUseFuzzySearchInFilters = false;
        this.recentOperationSearch = {};
        this.recentOrderSearch = {};
        this.recentEntitySearch = {};
        this.recentOrderTemplatesSearch = {};
    }
    SearchManagerService.prototype.initSearchRequestArgs = function (searchType, mainTableModel, gridStructure, criterias, showAllResult, autoPictureMode, filterIsSet) {
        if (criterias === void 0) { criterias = []; }
        if (showAllResult === void 0) { showAllResult = this.defaultShowAllResult; }
        if (autoPictureMode === void 0) { autoPictureMode = this.defaultAutoPictureMode; }
        if (filterIsSet === void 0) { filterIsSet = this.defaultFilterIsSet; }
        var searchArgs = new search_args_model_1.SearchArgsModel(mainTableModel, gridStructure);
        searchArgs.requestId = 0;
        searchArgs.searchControllerId = 1;
        searchArgs.searchType = searchType;
        searchArgs.currentFactoryKeyId = this.sessionHelperService.currentFactoryKeyId;
        searchArgs.searchText = '';
        searchArgs.showAllResult = showAllResult;
        searchArgs.orderByFields = Object.assign([], []);
        searchArgs.paginationArgs.pageRowCount = this.defaultPageRowCount;
        searchArgs.paginationArgs.startingRow = this.defaultStartingRow;
        searchArgs.autoPictureMode = autoPictureMode;
        searchArgs.viewCommonData = this.defaultViewCommonData;
        searchArgs.criterias = criterias;
        searchArgs.filterIsSet = filterIsSet;
        searchArgs.preDefinedDropDownFilterText = '';
        this.setGridFilterArgsList(searchArgs, '', '');
        searchArgs.useFuzzySearchInFilters = this.defaultUseFuzzySearchInFilters;
        this.setFuzzySearchArgs(searchArgs, '', '', []);
        this.setExtraItemSerialArgs(searchArgs, td_enumerations_1.IncludeItemSerialUsage.Off, td_enumerations_1.UseOfUsedSerials.Off, td_enumerations_1.UseOfUnusedSerials.Off, 0, 0);
        searchArgs.identifier = this.defaultIdentifier;
        return searchArgs;
    };
    SearchManagerService.prototype.initCustomerUnitsSearchRequestArgs = function (mainTableModel, gridStructure, customerKeyId) {
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.CustomerUnits, mainTableModel, gridStructure);
        searchArgs.extraCustomerUnitsArgs.customerKeyId = customerKeyId;
        return searchArgs;
    };
    SearchManagerService.prototype.initProductsBasedOnItemSearchRequestArgs = function (mainTableModel, gridStructure, itemKeyId, composite, directly) {
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.ProductsBasedOnItem, mainTableModel, gridStructure);
        searchArgs.extraProductsBasedOnItemArgs.itemKeyId = itemKeyId;
        searchArgs.extraProductsBasedOnItemArgs.composite = composite;
        searchArgs.extraProductsBasedOnItemArgs.directly = directly;
        return searchArgs;
    };
    SearchManagerService.prototype.initWebProductsBasedOnItemSearchRequestArgs = function (mainTableModel, gridStructure, itemKeyId, composite, directly, factoryKeyId) {
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebProductsBasedOnItem, mainTableModel, gridStructure, [], true, td_enumerations_1.SearchAutoPictureMode.On);
        searchArgs.extraWebProductsBasedOnItemArgs.itemKeyId = itemKeyId;
        searchArgs.extraWebProductsBasedOnItemArgs.composite = composite;
        searchArgs.extraWebProductsBasedOnItemArgs.directly = directly;
        searchArgs.extraWebProductsBasedOnItemArgs.factoryKeyId = factoryKeyId;
        return searchArgs;
    };
    SearchManagerService.prototype.initWebArticlesSearchRequestArgs = function (searchType, mainTableModel, gridStructure, itemInstrumentType, reducedSearch, productKeyId) {
        if (itemInstrumentType === void 0) { itemInstrumentType = td_enumerations_1.ItemInstrumentType.Instrument; }
        if (reducedSearch === void 0) { reducedSearch = false; }
        if (productKeyId === void 0) { productKeyId = 0; }
        var searchArgs = this.initSearchRequestArgs(searchType, mainTableModel, gridStructure);
        searchArgs.extraWebArticlesArgs.orderFactoryList = Object.assign([], this.sessionHelperService.orderFactoryKeyIds);
        searchArgs.extraWebArticlesArgs.customerFactoryList = Object.assign([], this.sessionHelperService.customerFactoryKeyIds);
        searchArgs.extraWebArticlesArgs.generalProductsMode = this.sessionHelperService.serverOptions.displayGeneralProductsMode;
        searchArgs.extraWebArticlesArgs.customerProductsMode = this.sessionHelperService.serverOptions.displayCustomerProductsMode;
        this.setUserSearchFilters(searchArgs);
        searchArgs.extraWebArticlesArgs.userCanSeeAllProducts = this.sessionHelperService.profileData.system_CanSeeAllProductsInWeb;
        if (this.sessionHelperService.currentCustomer) {
            searchArgs.extraWebArticlesArgs.userCustomerKeyId = this.sessionHelperService.currentCustomer.keyId;
            searchArgs.extraWebArticlesArgs.userCustomerCustomerSiteKeyId =
                this.sessionHelperService.currentCustomer.cuSiteKeyId ? this.sessionHelperService.currentCustomer.cuSiteKeyId : 0;
        }
        else {
            searchArgs.extraWebArticlesArgs.userCustomerKeyId = 0;
            searchArgs.extraWebArticlesArgs.userCustomerCustomerSiteKeyId = 0;
        }
        searchArgs.extraWebArticlesArgs.itemInstrumentType = itemInstrumentType;
        searchArgs.extraWebArticlesArgs.productKeyId = productKeyId;
        searchArgs.extraWebArticlesArgs.reducedSearch = reducedSearch;
        return searchArgs;
    };
    SearchManagerService.prototype.setUserSearchFilters = function (searchArgs) {
        searchArgs.extraWebArticlesArgs.orderableFromCurrentFactory = this.sessionHelperService.getUserOptions().search_OrderableFromCurrentFactory;
        searchArgs.extraWebArticlesArgs.productsFilterMethodIsGeneral = this.sessionHelperService.getUserOptions().display_ProdFiltMethodGeneral;
        searchArgs.extraWebArticlesArgs.productsFilterMethodIsCustomer = this.sessionHelperService.getUserOptions().display_ProdFiltMethodCustomer;
        searchArgs.extraWebArticlesArgs.productsFilterMethodIsCustomerSite = this.sessionHelperService.getUserOptions().display_ProdFiltMethodCustSite;
    };
    SearchManagerService.prototype.initIncubatorLogSearchRequestArgs = function (mainTableModel, gridStructure, toDate, fromDate, machineKeyId) {
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.IncubatorLog, mainTableModel, gridStructure);
        searchArgs.extraIncubatorLogArgs.toDate = toDate;
        searchArgs.extraIncubatorLogArgs.fromDate = fromDate;
        searchArgs.extraIncubatorLogArgs.machineKeyId = machineKeyId;
        return searchArgs;
    };
    SearchManagerService.prototype.initPrefListsSearchRequestArgs = function (mainTableModel, gridStructure, specialtyIds, preferenceListIds) {
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.PrefLists, mainTableModel, gridStructure);
        searchArgs.extraPrefListsArgs.specialtyIds = Object.assign([], specialtyIds);
        searchArgs.extraPrefListsArgs.preferenceListIds = Object.assign([], preferenceListIds);
        return searchArgs;
    };
    SearchManagerService.prototype.initWebScheduledOperationSearchRequestArgs = function (gridStructure, fromDate, toDate, searchType, showAllResult) {
        if (showAllResult === void 0) { showAllResult = this.defaultShowAllResult; }
        var nameOfOperation = utility_service_1.nameOf();
        var operationFilter = this.operationFilterService.getScheduledOperationListFilter(fromDate, toDate, searchType, this.sessionHelperService.currentCustomer.keyId);
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebScheduledOperation, td_constants_1.TD_MODEL_NAMES.operationDataModel, gridStructure, operationFilter, showAllResult);
        searchArgs.extraWebScheduledOperationArgs.userCanChangeDefaults = this.sessionHelperService.profileData.system_CanChangeDefaults;
        // set default order by
        searchArgs.orderByFields = [new base_grid_args_model_1.OrderByArgsModel(nameOfOperation('startTime'), td_enumerations_1.FuzzySearchSortOrder.Desc)];
        return searchArgs;
    };
    SearchManagerService.prototype.initWebOperationUnitsSearchRequestArgs = function (gridStructure, operationKeyId, showAllResult) {
        if (showAllResult === void 0) { showAllResult = this.defaultShowAllResult; }
        var nameOfUnit = utility_service_1.nameOf();
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebOperationUnits, td_constants_1.TD_MODEL_NAMES.unitModel, gridStructure, [], showAllResult);
        searchArgs.extraWebOperationUnitsArgs.operationKeyId = operationKeyId;
        searchArgs.extraWebOperationUnitsArgs.factoryKeyIds = this.sessionHelperService.customerFactoryKeyIds;
        // set default order by
        searchArgs.orderByFields = [new base_grid_args_model_1.OrderByArgsModel(nameOfUnit('keyId'), td_enumerations_1.FuzzySearchSortOrder.Desc)];
        return searchArgs;
    };
    SearchManagerService.prototype.initWebOperationProductsSearchRequestArgs = function (gridStructure, operationKeyId, itemType) {
        if (itemType === void 0) { itemType = td_enumerations_1.ItemInstrumentType.Instrument; }
        var nameOfProduct = utility_service_1.nameOf();
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebOperationProducts, td_constants_1.TD_MODEL_NAMES.productModel, gridStructure, [], true, td_enumerations_1.SearchAutoPictureMode.On);
        searchArgs.extraWebOperationProductsArgs.operationKeyId = operationKeyId;
        searchArgs.extraWebOperationProductsArgs.itemType = itemType;
        // set default order by
        searchArgs.orderByFields = [new base_grid_args_model_1.OrderByArgsModel(nameOfProduct('keyId'), td_enumerations_1.FuzzySearchSortOrder.Desc)];
        return searchArgs;
    };
    SearchManagerService.prototype.initWebOperationItemsSearchRequestArgs = function (gridStructure, operationKeyId) {
        var nameOfItem = utility_service_1.nameOf();
        var searchArgs = this.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.WebOperationItems, td_constants_1.TD_MODEL_NAMES.itemModel, gridStructure, [], true, td_enumerations_1.SearchAutoPictureMode.On);
        searchArgs.extraWebOperationItemsArgs.operationKeyId = operationKeyId;
        // set default order by
        searchArgs.orderByFields = [new base_grid_args_model_1.OrderByArgsModel(nameOfItem('keyId'), td_enumerations_1.FuzzySearchSortOrder.Desc)];
        return searchArgs;
    };
    SearchManagerService.prototype.setGridFilterArgsList = function (searchArgs, fieldName, fieldValue, clear, gridFilterTextMatchType) {
        if (clear === void 0) { clear = false; }
        if (gridFilterTextMatchType === void 0) { gridFilterTextMatchType = td_enumerations_1.FuzzySearchTextMatchType.Contains; }
        if (clear) {
            searchArgs.gridFilterArgsList.splice(0);
        }
        if (fieldName) {
            searchArgs.gridFilterArgsList.push(new search_args_model_1.GridFilterSearchArgsModel(fieldName, fieldValue));
        }
        searchArgs.gridFilterTextMatchType = gridFilterTextMatchType;
    };
    SearchManagerService.prototype.setFuzzySearchArgs = function (searchArgs, xmlCondition, currentFilterText, resultFields) {
        searchArgs.fuzzySearchArgs.xmlCondition = xmlCondition;
        searchArgs.fuzzySearchArgs.currentFilterText = currentFilterText;
        searchArgs.fuzzySearchArgs.resultFields = Object.assign([], resultFields);
    };
    SearchManagerService.prototype.setExtraItemSerialArgs = function (searchArgs, includeItemSerialUsage, allowUsedSerials, allowUnusedSerials, countedUnit, countedProductSerialKeyId) {
        searchArgs.extraItemSerialArgs.includeItemSerialUsage = includeItemSerialUsage;
        searchArgs.extraItemSerialArgs.allowUsedSerials = allowUsedSerials;
        searchArgs.extraItemSerialArgs.allowUnusedSerials = allowUnusedSerials;
        searchArgs.extraItemSerialArgs.countedUnit = countedUnit;
        searchArgs.extraItemSerialArgs.countedProductSerialKeyId = countedProductSerialKeyId;
    };
    SearchManagerService.prototype.requestSearchData = function (searchArgs, searchText, onDataLoaded, keyField) {
        searchArgs.searchText = searchText;
        return this.gridDataHelperService.requestGridData(searchArgs, this.searchService.search.bind(this.searchService), null, onDataLoaded, keyField);
    };
    SearchManagerService.prototype.requestSearch = function (searchArgs, searchText) {
        searchArgs.searchText = searchText;
        return this.searchService.search(searchArgs);
    };
    SearchManagerService.prototype.getAllRows = function () {
        return {
            pageRowCount: td_constants_1.TD_MAX_INT32_VALUE - 1,
            startingRow: 0,
            totalCountOfRows: 0
        };
    };
    SearchManagerService.prototype.setRecentOrderTemplatesSearch = function (recentSearch) {
        this.recentOrderTemplatesSearch = recentSearch;
    };
    SearchManagerService.prototype.setRecentOperationSearch = function (recentSearch) {
        this.recentOperationSearch = recentSearch;
    };
    SearchManagerService.prototype.setRecentOrderSearch = function (recentSearch) {
        this.recentOrderSearch = recentSearch;
    };
    SearchManagerService.prototype.setRecentOrderSearchTab = function (index) {
        this.recentOrderSearch.tabIndex = index;
    };
    SearchManagerService.prototype.setRecentArticleSearchTab = function (index) {
        this.recentEntitySearch.tabIndex = index;
    };
    SearchManagerService.prototype.getRecentArticleSearchTab = function () {
        return this.recentEntitySearch.tabIndex ? this.recentEntitySearch.tabIndex : 0;
    };
    SearchManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchManagerService_Factory() { return new SearchManagerService(i0.ɵɵinject("SEARCH_SERVICE"), i0.ɵɵinject(i1.SessionHelperService), i0.ɵɵinject(i2.GridDataHelperService), i0.ɵɵinject(i3.OperationFilterService)); }, token: SearchManagerService, providedIn: "root" });
    return SearchManagerService;
}());
exports.SearchManagerService = SearchManagerService;
