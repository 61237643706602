"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./signout-callback.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./signout-callback.component");
var styles_SignoutCallbackComponent = [i0.styles];
var RenderType_SignoutCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignoutCallbackComponent, data: {} });
exports.RenderType_SignoutCallbackComponent = RenderType_SignoutCallbackComponent;
function View_SignoutCallbackComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
exports.View_SignoutCallbackComponent_0 = View_SignoutCallbackComponent_0;
function View_SignoutCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-signout-callback", [], null, null, null, View_SignoutCallbackComponent_0, RenderType_SignoutCallbackComponent)), i1.ɵdid(1, 49152, null, 0, i2.SignoutCallbackComponent, ["AUTH_SERVICE"], null, null)], null, null); }
exports.View_SignoutCallbackComponent_Host_0 = View_SignoutCallbackComponent_Host_0;
var SignoutCallbackComponentNgFactory = i1.ɵccf("td-signout-callback", i2.SignoutCallbackComponent, View_SignoutCallbackComponent_Host_0, {}, {}, []);
exports.SignoutCallbackComponentNgFactory = SignoutCallbackComponentNgFactory;
