"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var search_manager_service_1 = require("@core/search/services/search-manager.service");
var utility_service_1 = require("@core/utility/utility.service");
var param_models_1 = require("../models/url-search-models/param-models");
var boolean_param_models_1 = require("../models/url-search-models/param-models/boolean-param.models");
var SearchType;
(function (SearchType) {
    SearchType[SearchType["ArticleSearch"] = 0] = "ArticleSearch";
    SearchType[SearchType["OperationSearch"] = 1] = "OperationSearch";
    SearchType[SearchType["OrderSearch"] = 2] = "OrderSearch";
})(SearchType = exports.SearchType || (exports.SearchType = {}));
var UrlSearchService = /** @class */ (function () {
    function UrlSearchService(searchManagerService, utilityService, router) {
        this.searchManagerService = searchManagerService;
        this.utilityService = utilityService;
        this.router = router;
    }
    UrlSearchService.prototype.onQueryParamChanges = function (params, model, searchObject, firstSearch) {
        if (this.utilityService.objIsEmpty(params)) {
            this.triggerRecentOrDefaultSearch(searchObject, firstSearch);
            return null;
        }
        else {
            return this.extractPropertiesFromUrlParams(model, params);
        }
    };
    UrlSearchService.prototype.setSearchParamsAndNavigate = function (model, firstSearch) {
        var params = this.convertPropertiesToUrlParams(model);
        this.routeNavigate(params, model, firstSearch);
    };
    UrlSearchService.prototype.triggerRecentOrDefaultSearch = function (searchObject, firstSearch) {
        var emptyParamsSearchModel = searchObject.getEmptyParamsSearchModel();
        var recentSearch = this.getRecentSearch(emptyParamsSearchModel.url);
        if (!firstSearch || this.utilityService.objIsEmpty(recentSearch)) {
            this.addParamsForDefaultSearch(emptyParamsSearchModel);
        }
        else {
            this.addParamsForRecentSearch(emptyParamsSearchModel, searchObject);
        }
    };
    UrlSearchService.prototype.extractPropertiesFromUrlParams = function (model, queryParams) {
        model.properties.forEach(function (prop) {
            switch (prop.type) {
                case param_models_1.SearchParamTypes.Date: {
                    prop.value = param_models_1.extractDateFromStringParam(prop.urlParamName, queryParams);
                    break;
                }
                case param_models_1.SearchParamTypes.Number: {
                    prop.value = param_models_1.extractNumberFromParams(prop.urlParamName, queryParams);
                    break;
                }
                case param_models_1.SearchParamTypes.String: {
                    prop.value = param_models_1.extractStringFromParams(prop.urlParamName, queryParams);
                    break;
                }
                case param_models_1.SearchParamTypes.Boolean: {
                    prop.value = boolean_param_models_1.extractBooleanFromParams(prop.urlParamName, queryParams);
                    break;
                }
            }
        });
        return model;
    };
    UrlSearchService.prototype.convertPropertiesToUrlParams = function (model) {
        var params = {};
        model.properties.forEach(function (prop) {
            if (prop.value !== null && prop.value !== undefined) {
                switch (prop.type) {
                    case param_models_1.SearchParamTypes.Date: {
                        params = param_models_1.addDatePropToParams(prop, params);
                        break;
                    }
                    case param_models_1.SearchParamTypes.Number: {
                        params = param_models_1.addNumberPropToParams(prop, params);
                        break;
                    }
                    case param_models_1.SearchParamTypes.String: {
                        params = param_models_1.addStringPropToParams(prop, params);
                        break;
                    }
                    case param_models_1.SearchParamTypes.Boolean: {
                        params = boolean_param_models_1.addBooleanPropToParams(prop, params);
                        break;
                    }
                }
            }
        });
        return params;
    };
    UrlSearchService.prototype.routeNavigate = function (params, searchModel, firstSearch) {
        this.router.navigate([searchModel.url], this.setupNavigationExtras(params, firstSearch));
    };
    UrlSearchService.prototype.addParamsForDefaultSearch = function (searchModel) {
        var params = this.convertPropertiesToUrlParams(searchModel);
        this.routeNavigate(params, searchModel, true);
    };
    UrlSearchService.prototype.addParamsForRecentSearch = function (searchModel, searchClass) {
        var recentSearch = this.getRecentSearch(searchModel.url);
        var params = searchClass.getRecentSearchAsParams(recentSearch);
        this.routeNavigate(params, searchModel, true);
    };
    UrlSearchService.prototype.getRecentSearch = function (url) {
        switch (url) {
            case '/orders': return this.searchManagerService.recentOrderSearch;
            case '/operations': return this.searchManagerService.recentOperationSearch;
            case '/order-templates': return this.searchManagerService.recentOrderTemplatesSearch;
        }
    };
    UrlSearchService.prototype.setupNavigationExtras = function (params, firstSearch) {
        var navigationExtras = {
            queryParams: params,
            replaceUrl: firstSearch
        };
        return navigationExtras;
    };
    return UrlSearchService;
}());
exports.UrlSearchService = UrlSearchService;
