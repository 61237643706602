"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./help.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i3 = require("devextreme-angular/core");
var i4 = require("devextreme-angular/ui/button");
var i5 = require("@angular/platform-browser");
var i6 = require("@ngx-translate/core");
var i7 = require("./help.component");
var i8 = require("../core/language/services/language.service");
var styles_HelpComponent = [i0.styles];
var RenderType_HelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpComponent, data: {} });
exports.RenderType_HelpComponent = RenderType_HelpComponent;
function View_HelpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "dx-button", [["class", "header-icon-button tertiary-button-dark-mode"], ["icon", ".\\assets\\img\\Help-icon.svg"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.help() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DxButtonComponent_0, i2.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(4, 7323648, null, 0, i4.DxButtonComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { hint: [0, "hint"], icon: [1, "icon"] }, { onClick: "onClick" }), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("appCommon.help")), ""); var currVal_1 = ".\\assets\\img\\Help-icon.svg"; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
exports.View_HelpComponent_0 = View_HelpComponent_0;
function View_HelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-help", [], null, null, null, View_HelpComponent_0, RenderType_HelpComponent)), i1.ɵdid(1, 114688, null, 0, i7.HelpComponent, [i8.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HelpComponent_Host_0 = View_HelpComponent_Host_0;
var HelpComponentNgFactory = i1.ɵccf("td-help", i7.HelpComponent, View_HelpComponent_Host_0, { routerUrl: "routerUrl" }, {}, []);
exports.HelpComponentNgFactory = HelpComponentNgFactory;
