"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var td_popup_service_1 = require("@shared/components/td-popup/services/td-popup.service");
var td_search_filters_component_1 = require("../td-search-filters/td-search-filters.component");
var search_filters_helper_service_1 = require("@shared/services/search-filters-helper.service");
var TdSearchFiltersButtonComponent = /** @class */ (function () {
    function TdSearchFiltersButtonComponent(sessionHelperService, popupService, searchFiltersHelperService) {
        this.sessionHelperService = sessionHelperService;
        this.popupService = popupService;
        this.searchFiltersHelperService = searchFiltersHelperService;
        this.darkMode = false;
        this.filterIcon = 'SearchFilter.svg';
        this.setFilterButtonIcon();
        this.listenForFilterChanges();
    }
    TdSearchFiltersButtonComponent.prototype.ngOnDestroy = function () {
        this.searchFiltersSubscription.unsubscribe();
    };
    TdSearchFiltersButtonComponent.prototype.setFilterButtonIcon = function () {
        // Change icon if any filter option is activated
        if (Object.values(this.searchFiltersHelperService.getSearchFiltersOptions(this.sessionHelperService.getUserOptions()))
            .some(function (option) { return option; })) {
            this.activeFilterIcon = true;
        }
        else {
            this.activeFilterIcon = false;
        }
    };
    TdSearchFiltersButtonComponent.prototype.listenForFilterChanges = function () {
        var _this = this;
        this.searchFiltersSubscription = this.searchFiltersHelperService.searchFilterOptions$.subscribe(function (value) {
            if (Object.values(value).some(function (option) { return option; })) {
                _this.activeFilterIcon = true;
            }
            else {
                _this.activeFilterIcon = false;
            }
        });
    };
    TdSearchFiltersButtonComponent.prototype.defineSearchFilters = function () {
        var _this = this;
        this.popupService.showTemplate('search.searchFilters', this.searchFiltersTemplate).then(function (result) {
            if (result) {
                _this.setFilterButtonIcon();
            }
        });
    };
    TdSearchFiltersButtonComponent.prototype.cancel = function () {
        this.popupService.cancelPopup();
    };
    TdSearchFiltersButtonComponent.prototype.apply = function () {
        var _this = this;
        this.searchFilters.saveSearchFilters().then(function () {
            _this.popupService.confirmPopup();
        });
    };
    return TdSearchFiltersButtonComponent;
}());
exports.TdSearchFiltersButtonComponent = TdSearchFiltersButtonComponent;
