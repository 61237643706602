"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".line-middle[_ngcontent-%COMP%]{height:1px;border-bottom:1px solid var(--color-black);margin-top:auto;margin-bottom:auto}.width-300[_ngcontent-%COMP%]{width:300px}"];
exports.styles = styles;
