import { createSelector } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { OrderState } from './order.reducer';

export const selectOrderState = (state: AppState) => state.order;

export const selectCustomerOpenOrders = createSelector(
  selectOrderState,
  (state: OrderState) => state.customerOpenOrders
);

export const selectUserLastOpenOrder = createSelector(
  selectOrderState,
  (state: OrderState) => state.userLastOpenOrder
);
