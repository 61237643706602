"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var production_overview_constants_1 = require("@app/dashboard/models/production-overview.constants");
var logger_service_1 = require("@core/logger/logger.service");
var signal_r_service_1 = require("@core/signal-r/signal-r.service");
var info_overview_model_1 = require("@app/dashboard/models/info-overview.model");
var info_overview_actions_1 = require("../store/info-overview.actions");
var po_data_model_1 = require("@app/dashboard/models/po-data.model");
// signal-r server methods
var HUB_SRV_METHOD_SUBSCRIBE_TO_INFO_OVERVIEW = 'AddSubscriptions';
var HUB_SRV_METHOD_UNSUBSCRIBE_FROM_INFO_OVERVIEW = 'RemoveSubscriptions';
var HUB_SRV_METHOD_REFRESH_INFO_OVERVIEW = 'Refresh';
// signal-r client methods
var HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_CONFIG = 'UpdateConfiguration';
var HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_DATA = 'UpdateData';
var HUB_CLIENT_METHOD_JOB_SRV_CONFIRMATION_MESSAGE = 'JobSrvConfirmationMessage';
var HUB_CLIENT_METHOD_PO_JOB_CONNECTION_ESTABLISHED = 'ProdOverviewJobConnectionEstablished';
var HUB_CLIENT_METHOD_PO_JOB_CONNECTION_LOST = 'ProdOverviewJobConnectionLost';
// signal-r client methods (users status)
var HUB_METHOD_SET_USERS_ONLINE = 'SetUsersOnline';
var HUB_METHOD_USERS_JOINED = 'UsersJoined';
var HUB_METHOD_USERS_LEFT = 'UsersLeft';
var SignalRInfoOverviewService = /** @class */ (function (_super) {
    tslib_1.__extends(SignalRInfoOverviewService, _super);
    function SignalRInfoOverviewService(http, store, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.http = http;
        _this.store = store;
        _this.reconnectTimeout = production_overview_constants_1.POConst.RECONNECT_IO_TIMEOUT;
        _this.initializeConnection();
        return _this;
    }
    // override the protected method
    SignalRInfoOverviewService.prototype.getHubServerUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.serverUrl + api_hub_constants_1.API_HUB_PATHS.infoOverviewsHub;
    };
    // override the protected method
    SignalRInfoOverviewService.prototype.beforeInitialize = function () {
        this.store.dispatch(new info_overview_actions_1.SetConnectedToHub(false));
    };
    SignalRInfoOverviewService.prototype.infoOverviewsUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.infoOverviewsCtrl;
    };
    SignalRInfoOverviewService.prototype.getInfoOverviews = function (ioTypes, factoryKeyId, canViewProductionOverview) {
        var _this = this;
        return this.http.get(this.infoOverviewsUrl() + "?ioTypes=" + ioTypes.join() + "&factoryKeyId=" + factoryKeyId + "&canViewProductionOverview=" + canViewProductionOverview).pipe(operators_1.map(function (infoOverviews) {
            _this.onUpdateIOConfiguration();
            return infoOverviews;
        }));
    };
    SignalRInfoOverviewService.prototype.getInfoOverview = function (id) {
        return this.http.get(this.infoOverviewsUrl() + "/" + id).pipe(operators_1.map(function (infoOverview) {
            return infoOverview;
        }));
    };
    // override the protected method
    SignalRInfoOverviewService.prototype.onConnectionEstablished = function () {
        // if we want we can still explicitly call the initial method
        this.onJSrvConnectionLost();
        this.onJSrvConnectionEstablished();
        _super.prototype.onConnectionEstablished.call(this);
        this.store.dispatch(new info_overview_actions_1.SetConnectedToHub(true));
    };
    // override the protected method
    SignalRInfoOverviewService.prototype.onConnectionLost = function (errMsg) {
        // if we want we can still explicitly call the initial method
        _super.prototype.onConnectionLost.call(this, errMsg);
        this.store.dispatch(new info_overview_actions_1.SetConnectedToHub(false));
    };
    SignalRInfoOverviewService.prototype.subscribeToIO = function (id) {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.invoke(HUB_SRV_METHOD_SUBSCRIBE_TO_INFO_OVERVIEW, [id])
                .then(function () {
                _this.onUpdateIOData();
                _this.loggerService.log('Subscribed to info-overview [' + id + ']');
            })
                .catch(function (err) { _this.loggerService.error(HUB_SRV_METHOD_SUBSCRIBE_TO_INFO_OVERVIEW + ' error: ' + err); });
        }
    };
    SignalRInfoOverviewService.prototype.unsubscribeFromIO = function (id) {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.invoke(HUB_SRV_METHOD_UNSUBSCRIBE_FROM_INFO_OVERVIEW, [id]).
                then(function () {
                _this.offUpdateIOData();
                _this.loggerService.log('Unsubscribed from info-overview [' + id + ']');
            })
                .catch(function (err) { _this.loggerService.error(HUB_SRV_METHOD_UNSUBSCRIBE_FROM_INFO_OVERVIEW + ' error: ' + err); });
        }
    };
    SignalRInfoOverviewService.prototype.refreshIO = function (id) {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.invoke(HUB_SRV_METHOD_REFRESH_INFO_OVERVIEW, id).
                then(function () {
                _this.loggerService.log('Refresh info-overview [' + id + ']');
            })
                .catch(function (err) { _this.loggerService.error(HUB_SRV_METHOD_REFRESH_INFO_OVERVIEW + ' error: ' + err); });
        }
    };
    // TODO: "update" local copy of changed info-overview or "add" new info overview in list
    SignalRInfoOverviewService.prototype.onUpdateIOConfiguration = function () {
        if (this.connectedToHub()) {
            this.hubConnection.on(HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_CONFIG, function (ioConfig) {
                /*var addNewIOInList = true;
                ioConfig = JSON.parse(ioConfig);
                if (this.infoOverviewDataList) {
                  for (var i = 0; i < this.infoOverviewDataList.length; i++) {
                    if (this.infoOverviewDataList[i]['configID'] === ioConfig.ConfigID) {
                      this.infoOverviewDataList[i]['configClientReceiveTime'] = Utils.formatDateTime(new Date());
                      this.infoOverviewDataList[i]['configJobServerUpdateTime'] = Utils.formatDateTime(new Date(poConfig.ConfigUpdateTime));
                      this.infoOverviewDataList[i]['configFields'] = this.prepareInfoOverviewColumns(poConfig.ConfigFields);
                      this.infoOverviewDataList[i]['configData'] = JSON.parse(ioConfig.ConfigData);
                      addNewIOInList = false;
                      break;
                    }
                  };
                }
                if (addNewIOInList) {
                  this.infoOverviewDataList.push({
                    configID: ioConfig.ConfigID,
                    configClientReceiveTime: Utils.formatDateTime(new Date()),
                    configJobServerUpdateTime: Utils.formatDateTime(new Date(ioConfig.ConfigUpdateTime)),
                    configFields: this.prepareInfoOverviewColumns(ioConfig.ConfigFields),
                    configData: JSON.parse(ioConfig.ConfigData)
                  });
                }*/
            });
        }
    };
    SignalRInfoOverviewService.prototype.onUpdateIOData = function () {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.on(HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_DATA, function (ioData) {
                if (ioData) {
                    var newIOData = new info_overview_model_1.InfoOverviewData(ioData);
                    _this.store.dispatch(new info_overview_actions_1.SetIOData(newIOData));
                }
            });
        }
    };
    SignalRInfoOverviewService.prototype.offUpdateIOData = function () {
        if (this.connectedToHub()) {
            this.hubConnection.off(HUB_CLIENT_METHOD_UPDATE_INFO_OVERVIEW_DATA);
        }
    };
    // listen "JobConfirmation" hub method (can be response from TDJobServer about subscribe/unsubscribe to particular info-overview)
    SignalRInfoOverviewService.prototype.onJSrvConfirmationMessage = function () {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.on(HUB_CLIENT_METHOD_JOB_SRV_CONFIRMATION_MESSAGE, function (message) {
                _this.loggerService.log(message);
            });
        }
    };
    SignalRInfoOverviewService.prototype.onJSrvConnectionEstablished = function () {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.on(HUB_CLIENT_METHOD_PO_JOB_CONNECTION_ESTABLISHED, function (message) {
                _this.store.dispatch(new info_overview_actions_1.SetConnectedToJSrv(po_data_model_1.ProdOverviewData, true));
                _this.loggerService.log(message + ' (:');
            });
        }
    };
    SignalRInfoOverviewService.prototype.onJSrvConnectionLost = function () {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.on(HUB_CLIENT_METHOD_PO_JOB_CONNECTION_LOST, function (message) {
                _this.store.dispatch(new info_overview_actions_1.SetConnectedToJSrv(po_data_model_1.ProdOverviewData, false));
                _this.loggerService.log(message + ' (:');
            });
        }
    };
    SignalRInfoOverviewService.prototype.onSetUsersOnline = function () {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.on(HUB_METHOD_SET_USERS_ONLINE, function (usersOnline) {
                usersOnline.forEach(function (user) { return _this.loggerService.log(HUB_METHOD_SET_USERS_ONLINE + ': ' + user.connectionId); });
            });
        }
    };
    SignalRInfoOverviewService.prototype.onUsersJoined = function () {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.on(HUB_METHOD_USERS_JOINED, function (users) {
                users.forEach(function (user) {
                    _this.loggerService.log(HUB_METHOD_USERS_JOINED + ': ' + user.connectionId + ' joined the InfoOverview');
                });
            });
        }
    };
    SignalRInfoOverviewService.prototype.onUsersLeft = function () {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.on(HUB_METHOD_USERS_LEFT, function (users) {
                users.forEach(function (user) {
                    _this.loggerService.log(HUB_METHOD_USERS_LEFT + ': ' + user.connectionId + ' left the InfoOverview');
                });
            });
        }
    };
    return SignalRInfoOverviewService;
}(signal_r_service_1.SignalRService));
exports.SignalRInfoOverviewService = SignalRInfoOverviewService;
