<div class="form-group" [ngClass]="{'disabled':disabled}">
  <div class="pb-1">
    <label>
      {{ 'operationDetails.addUnit' | translate}}
    </label>
  </div>
  <div id="unit-search-container">
    <td-drop-down-grid #unitsSearchDropDownGrid
                       id="unitsSearchDropDownGrid"
                       [mainTable]="mainTable"
                       [gridIdentifier]="gridIdentifier"
                       placeHolder="{{ 'search.enterSearchText' | translate }}"
                       [displayExpr]="unitDisplayExpr"
                       [initSearchRequestArgs]="initUnitSearchRequestArgs"
                       [disabled]="disabled"
                       [gridWidth]="730"
                       (searchResultCleared)="onSearchResultCleared()"
                       (articleSelected)="catchUnitSelected($event)"></td-drop-down-grid>
    <dx-button #addUnitButton (onClick)="addUnit()" class="td-button tertiary-button"
               [text]="'operationDetails.addUnit' | translate" [disabled]="disabled || !isUnitSelected()">
    </dx-button>
  </div>
</div>
