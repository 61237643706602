import { NgModule, Optional, SkipSelf, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageServiceModule } from 'angular-webstorage-service';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { JwtInterceptor } from './interceptors/jwt/jwt.interceptor';
import { ApiPrefixInterceptor } from './interceptors/api-prefix/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler/error-handler.interceptor';
import { TelemetryInterceptor } from './interceptors/telemetry/telemetry.interceptor';
import { AuthConfigurationInitializer } from './initializers/auth-configuration/auth-configuration.initializer';
import { LicenseInitializer } from './initializers/license/license.initializer';
import { FactoriesInitializer } from './initializers/factories/factories.initializer';
import { ClientConfigurationInitializer } from './initializers/client-configuration/client-configuration.initializer';
import { ServerDesktopOptionsInitializer } from './initializers/server-desktop-options/server-desktop-options.initializer';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler';
import { LanguageService } from './language/services/language.service';
import { LanguageInitializer } from './initializers/language/language.initializer';
import { DefaultUserDesktopOptionsInitializer } from './initializers/default-user-options/default-user-options.initializer';

export function getLanguageFiles(languageInitializer: LanguageInitializer, languageService: LanguageService) {
  return () => languageInitializer.initializeLanguage(languageService);
}

export function getAuthConfiguration(authConfigurationInitializer: AuthConfigurationInitializer) {
  return () => authConfigurationInitializer.initializeAuthOptions();
}

export function getLicense(licenseInitializer: LicenseInitializer) {
  return () => licenseInitializer.initializeLicense();
}

export function getAllFactories(factInitializer: FactoriesInitializer) {
  return () => factInitializer.initializeAllFactories();
}

export function getClientConfiguration(clientConfigInitializer: ClientConfigurationInitializer) {
  return () => clientConfigInitializer.initializeClientConfiguration();
}

export function getServerDesktopOptions(serverDesktopOptionsInitializer: ServerDesktopOptionsInitializer) {
  return () => serverDesktopOptionsInitializer.initializeServerDesktopOptions();
}

export function getDefaultUserDesktopOptions(userDesktopOptionsInitializer: DefaultUserDesktopOptionsInitializer) {
  return () => userDesktopOptionsInitializer.initializeDefaultUserDesktopOptions();
}

@NgModule({
  imports: [
    StorageServiceModule,
    TranslateModule.forRoot()
  ],
  providers: [
    { provide: 'AUTH_SERVICE', useClass: environment.authenticationServiceType },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    ...(environment.telemetry ? [{ provide: HTTP_INTERCEPTORS, useClass: TelemetryInterceptor, multi: true }] : []),
    { provide: APP_INITIALIZER, useFactory: getAuthConfiguration, deps: [AuthConfigurationInitializer], multi: true },
    { provide: APP_INITIALIZER, useFactory: getLicense, deps: [LicenseInitializer], multi: true },
    { provide: APP_INITIALIZER, useFactory: getLanguageFiles, deps: [LanguageInitializer, LanguageService], multi: true },
    { provide: APP_INITIALIZER, useFactory: getAllFactories, deps: [FactoriesInitializer], multi: true },
    { provide: APP_INITIALIZER, useFactory: getClientConfiguration, deps: [ClientConfigurationInitializer], multi: true },
    { provide: APP_INITIALIZER, useFactory: getServerDesktopOptions, deps: [ServerDesktopOptionsInitializer], multi: true },
    { provide: APP_INITIALIZER, useFactory: getDefaultUserDesktopOptions, deps: [DefaultUserDesktopOptionsInitializer], multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // data-layer
    { provide: 'CUSTOMER_SERVICE', useClass: environment.customerServiceType },
    { provide: 'USER_SESSION_SERVICE', useClass: environment.userSessionServiceType },
    { provide: 'SERVER_SESSION_SERVICE', useClass: environment.serverSessionServiceType },
    { provide: 'FACTORY_SERVICE', useClass: environment.factoryServiceType },
    { provide: 'ORDER_SERVICE', useClass: environment.orderServiceType },
    { provide: 'PRODUCT_SERVICE', useClass: environment.productServiceType },
    { provide: 'UNIT_SERVICE', useClass: environment.unitServiceType },
    { provide: 'ITEM_SERVICE', useClass: environment.itemServiceType },
    { provide: 'ORDER_TEMPLATE_SERVICE', useClass: environment.orderTemplateServiceType },
    { provide: 'EXTERNAL_DOCUMENTS_SERVICE', useClass: environment.externalDocumentsServiceType },
    { provide: 'CLIENT_CONFIGURATION', useClass: environment.clientConfigurationServiceType },
    { provide: 'LICENSE_SERVICE', useClass: environment.licenseServiceType },
    { provide: 'LICENSE_BINDING_SERVICE', useClass: environment.licenseBindingServiceType },
    { provide: 'TABLE_INFO_SERVICE', useClass: environment.tableInfoServiceType },
    { provide: 'MEDIA_SERVICE', useClass: environment.mediaServiceType },
    { provide: 'SEARCH_SERVICE', useClass: environment.searchServiceType },
    { provide: 'ERROR_REPORT_SERVICE', useClass: environment.errorReportServiceType },
    { provide: 'INFO_OVERVIEW_SERVICE', useClass: environment.infoOverviewServiceType },
    { provide: 'OPERATION_DATA_SERVICE', useClass: environment.operationDataServiceType },
    { provide: 'SUBSTITUTIONS_SERVICE', useClass: environment.substitutionsServiceType }
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
