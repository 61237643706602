"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var product_helper_service_1 = require("./product-helper.service");
var search_helper_service_1 = require("@core/search/services/search-helper.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var external_documents_helper_service_1 = require("@core/data-layer/external-documents/services/external-documents-helper.service");
var HttpProductService = /** @class */ (function () {
    function HttpProductService(http, productHelperService, searchHelperService, extDocHelperService) {
        this.http = http;
        this.productHelperService = productHelperService;
        this.searchHelperService = searchHelperService;
        this.extDocHelperService = extDocHelperService;
    }
    HttpProductService.prototype.productsUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.productsCtrl;
    };
    HttpProductService.prototype.getProduct = function (productKeyId, chargeOptions) {
        var _this = this;
        return this.http.get(this.productsUrl() + ("(" + productKeyId + ")") + api_hub_constants_1.API_HUB_PATHS.details, { params: { customerChargeOptions: chargeOptions.toString() } }).pipe(operators_1.map(function (response) { return (tslib_1.__assign({}, response, { value: _this.productHelperService.convertProductToViewModel(response.value) })); }));
    };
    HttpProductService.prototype.locateProduct = function (requestArgs, productKeyId) {
        var _this = this;
        this.searchHelperService.applyColumnsConfigurations(requestArgs);
        return this.http.post(this.productsUrl() + "(" + productKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.locate + "?maxLevelOfNestedTables=" + td_constants_1.TD_MAX_LEVEL_OF_NESTED_TABLES, requestArgs).pipe(operators_1.map(function (response) { return _this.searchHelperService.convertExtendedSearchResultToViewModel(requestArgs, response); }));
    };
    HttpProductService.prototype.getExternalDocumentList = function (requestArgs, productKeyId) {
        var _this = this;
        return this.http.post(this.productsUrl() + "(" + productKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.externalDocumentList, requestArgs).pipe(operators_1.map(function (response) {
            _this.extDocHelperService.convertExternalDocumentsToViewModel(response);
            return response;
        }));
    };
    return HttpProductService;
}());
exports.HttpProductService = HttpProductService;
