import { createSelector } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { FactoryState } from './factory.reducer';

export const selectFactoryState = (state: AppState) => state.factory;

export const selectAllFactories = createSelector(
  selectFactoryState,
  (state: FactoryState) => state.allFactories
);

export const selectCustomerFactories = createSelector(
  selectFactoryState,
  (state: FactoryState) => state.customerFactories
);

export const selectUserFactories = createSelector(
  selectFactoryState,
  (state: FactoryState) => state.userFactories
);
