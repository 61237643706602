import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionHelperService } from '@shared/services/session-helper.service';
import { ServerDesktopOptionsModel } from '@core/data-layer/server-session/models/server-desktop-options.model';
import {
  PasswordVerificationMethod,
  PasswordVerify,
  WebDisplayCustomerProductsMode,
  LoginCookieMethod,
  WebOrderMode,
  WebDisplayPriceMode,
  WebDisplayGeneralProductsMode,
  ProdOverviewVisibilityMode,
  WebEditOprationArticlesMode,
  WebStartStopOperationMode
} from '@core/data-layer/shared/models/td.enumerations';
import { ServerSessionServiceInterface } from '@core/data-layer/server-session/services/server-session.service.interface';
import { UnitServiceInterface } from '@core/data-layer/unit/services/unit.service.interface';

@Component({
  selector: 'td-server-settings',
  templateUrl: './server-settings.component.html',
  styleUrls: ['./server-settings.component.less']
})
export class ServerSettingsComponent implements OnInit {

  @Input() settingsChanged: boolean;
  @Output() settingsChangedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  serverOptions: ServerDesktopOptionsModel;
  displayCustomerProducts: Array<{ key: number, value: string }>;
  passwordVerificationMethods: Array<{ key: number, value: string }>;
  passVerification: PasswordVerificationMethod;
  verificationDisabled: boolean;
  blankPasswordsDisabled: boolean;
  WebOrderMode = WebOrderMode;
  WebDisplayPriceMode = WebDisplayPriceMode;
  WebDisplayGeneralProductsMode = WebDisplayGeneralProductsMode;
  ProdOverviewVisibilityMode = ProdOverviewVisibilityMode;
  WebEditOprationArticlesMode = WebEditOprationArticlesMode;
  WebStartStopOperationMode = WebStartStopOperationMode;
  factTrackCodes$: Observable<{ key: number; value: string; }[]>;

  constructor(
    @Inject('SERVER_SESSION_SERVICE') private serverSessionService: ServerSessionServiceInterface,
    @Inject('UNIT_SERVICE') private unitService: UnitServiceInterface,
    public sessionHelperService: SessionHelperService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.serverOptions = Object.assign({}, this.sessionHelperService.serverOptions);
    this.initView();
  }

  initView() {
    this.loadDisplayCustomerProducts();
    this.loadPasswordVerificationMethods();
    this.loadVerificationControls();
    this.verificationDisabled = this.sessionHelperService.clientConfiguration.passwordVerify !== PasswordVerify.ActiveDirectory;
    this.factTrackCodes$ = this.unitService.getFastTrackCodes().pipe(
      map(codes => {
        const factTrackValues = codes.map(code => ({ key: code.keyId, value: `${code.code} - ${code.name}`}));
        factTrackValues.unshift({ key: 0, value: this.translateService.instant('settings.none') });
        return factTrackValues;
      })
    );
  }

  loadVerificationControls() {
    this.passVerification = this.sessionHelperService.getPassVerifMethod(this.serverOptions);
    this.blankPasswordsDisabled = this.passVerification === PasswordVerificationMethod.ActiveDirectory;
  }

  loadDisplayCustomerProducts() {
    this.displayCustomerProducts = [
      { key: WebDisplayCustomerProductsMode.All, value: this.translateService.instant('settings.all') },
      { key: WebDisplayCustomerProductsMode.OwnSiteOrCust, value: this.translateService.instant('settings.ownSiteOrCust') },
      { key: WebDisplayCustomerProductsMode.OwnSiteOnly, value: this.translateService.instant('settings.ownSiteOnly') },
      { key: WebDisplayCustomerProductsMode.OwnOnly, value: this.translateService.instant('settings.ownOnly') }
    ];
  }

  loadPasswordVerificationMethods() {
    this.passwordVerificationMethods = [
      { key: PasswordVerificationMethod.TDOC, value: this.translateService.instant('settings.tdoc') },
      { key: PasswordVerificationMethod.ActiveDirectory, value: this.translateService.instant('settings.activeDirectory') }
    ];
  }

  passwordVerificationMethodChanged(value: PasswordVerificationMethod) {
    this.serverOptions.passwordVerificationMethod = value;
    if ([LoginCookieMethod.UserNamePassword, LoginCookieMethod.Autologin].includes(this.serverOptions.loginCookieMethod)) {
      this.serverOptions.loginCookieMethod = LoginCookieMethod.UserName;
    }
    this.loadVerificationControls();
    this.setChanged();
  }

  allowStartAndStopChanged(allowStartAndStop: boolean) {
    this.serverOptions.startStopOperationMode =
      allowStartAndStop ? WebStartStopOperationMode.Allow : WebStartStopOperationMode.NotAllow;
    this.setChanged();
  }

  allowEditingArticlesChanged(allowEditingArticles: boolean) {
    this.serverOptions.editOprationArticlesMode =
      allowEditingArticles ? WebEditOprationArticlesMode.AllowEdit : WebEditOprationArticlesMode.NotAllowEdit;
    this.setChanged();
  }

  allowOrderingChanged(allowOrdering: boolean) {
    this.serverOptions.orderMode = allowOrdering ? WebOrderMode.On : WebOrderMode.Off;
    this.setChanged();
  }

  displayPricesChanged(displayPrices: boolean) {
    this.serverOptions.displayPriceMode = displayPrices ? WebDisplayPriceMode.ShowAll : WebDisplayPriceMode.HideAll;
    this.setChanged();
  }

  displayGeneralProductsChanged(displayGeneralProducts: boolean) {
    this.serverOptions.displayGeneralProductsMode =
      displayGeneralProducts ? WebDisplayGeneralProductsMode.Allowed : WebDisplayGeneralProductsMode.Blocked;
    this.setChanged();
  }

  availableInWebChanged(availableInWeb: boolean) {
    this.serverOptions.prodOverviewVisibilityMode =
      availableInWeb ? ProdOverviewVisibilityMode.AvailableInWeb : ProdOverviewVisibilityMode.NotAvailableInWeb;
    this.setChanged();
  }

  resetToDefault() {
    this.serverOptions = new ServerDesktopOptionsModel();
    this.setChanged();
    this.loadVerificationControls();
  }

  setChanged() {
    this.settingsChangedChange.emit(true);
  }

  saveServerOptions(): Promise<null> {
    return new Promise(resolve => {
      this.serverSessionService.saveServerDesktopOptions(this.serverOptions).subscribe(() => {
        resolve();
      });
    });
  }
}
