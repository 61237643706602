import { ProdOverviewDataMachine } from './po-machine.model';
import { ProdOverviewDataUser } from './po-user.model';
import { ProdOverviewDataKpi } from './po-kpi.model';
import { NumberDictionary } from '@shared/models/custom-types.interface';

export class ProdOverviewData {
  machineList: Array<ProdOverviewDataMachine> = null;
  userList: Array<ProdOverviewDataUser> = null;
  kpiAmountDict: NumberDictionary<ProdOverviewDataKpi> = null;
  sterilizersList: Array<ProdOverviewDataMachine> = null;
  washersList: Array<ProdOverviewDataMachine> = null;
  endoscopesList: Array<ProdOverviewDataMachine> = null;
  dryersList: Array<ProdOverviewDataMachine> = null;
  lastUpdate: Date = null;
  hasConnection: boolean = null;

  public constructor() {
    this.sterilizersList = new Array<ProdOverviewDataMachine>();
    this.washersList = new Array<ProdOverviewDataMachine>();
    this.endoscopesList = new Array<ProdOverviewDataMachine>();
    this.dryersList = new Array<ProdOverviewDataMachine>();
  }
}
