
export const MEDIA_WRAPPER_WIDTH = {
  Multiple: 117,
  Single: 106
}

export const SKIP_HEADER = 'SkipHeader';

export const MEDIA_SERIES_PRIORITY = ['product', 'item'];

export const UNIT_STATUS = 'unitStatus';
export const ENDOSCOPE_UNIT_STATUS = 'endoscopeUnitStatus';
export const OPERATION_STATUS = 'operationStatus';
export const ORDER_BOOKING_STATUS = 'orderBookingStatus';
export const ORDER_BOOKING_MODE = 'orderBookingMode';
export const RESERVATION_STATE = 'reservationState';

export enum GuardTypes {
  AuthenticatedUserAndCustomerSelected = 0,
  LoginModeAlways = 1,
  AllowAlways = 2
}

export const GUARD_TYPE = 'guardType';

export const PRODUCT_AS_STRING = 'product';
export const UNIT_AS_STRING = 'unit';
export const ITEM_AS_STRING = 'item';

export const LOCALSTORAGE_APP_STATE = 'td-app-state';

export const ROUTE_PATHS = {
  productionOverview: 'production-overview',
  operations: 'operations',
  orders: 'orders',
  orderTemplates: 'order-templates',
  landing: ''
}

export const GRID_TEMPLATES = {
  customTemplate: 'customTemplate'
}

export const GRID_SIZES = {
  rowHeight: 29,
  gridHeaderHeight: 30,
  labelHeight: 27,
  noArticlesHeight: 100,
  get maxPopupGridHeight() {
    return (10 * this.rowHeight) + this.gridHeaderHeight;
  }
}
