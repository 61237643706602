import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

import { PIP_MASK_ASTERISKS, PIP_DUMMY_NUMBER, PIP_DUMMY_DATE } from '@core/data-layer/shared/models/td.constants';

/*
 * We add supporting for masking fields only with "Date" and "number" types.
 * The main reason is C# couldn't mask "Date" and "number" on backend side.
 * Fields with "string" type we are getting already masked from C# side.
 */
@Pipe({ name: 'maskableField' })
export class MaskableFieldPipe implements PipeTransform {

  isValidDate(date): boolean {
    return date && (Object.prototype.toString.call(date) === '[object Date]') && !isNaN(date);
  }

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  transform(value: number | Date) {
    if (this.isValidDate(value)) {
      if (formatDate(value, 'shortDate', this.locale) === formatDate(PIP_DUMMY_DATE, 'shortDate', this.locale)) {
        return PIP_MASK_ASTERISKS;
      }
      else {
        return formatDate(value, 'short', this.locale);
      }
    }
    else if (value === PIP_DUMMY_NUMBER) {
      return PIP_MASK_ASTERISKS;
    }
    else {
      return value;
    }
  }

}
