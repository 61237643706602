"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utility_service_1 = require("@core/utility/utility.service");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var i0 = require("@angular/core");
var OperationFilterService = /** @class */ (function () {
    function OperationFilterService() {
    }
    OperationFilterService.prototype.getScheduledOperationListFilter = function (fromDate, toDate, searchType, customerKeyId) {
        var nameOfOperation = utility_service_1.nameOf();
        var filter = [];
        if (searchType === td_enumerations_1.OperationSearchType.Date) {
            if (fromDate) {
                filter = [[nameOfOperation('endTime'), '>=', fromDate.toISOString()]];
            }
            if (toDate) {
                if (filter.length > 0) {
                    filter = filter.concat(['and']);
                }
                filter = filter.concat([[nameOfOperation('startTime'), '<=', toDate.toISOString()]]);
            }
        }
        else {
            // if (BarType === cOperation) {
            //  operationFilter = [nameOfOperation('keyId'), '=', searchText];
            //}
        }
        if (customerKeyId > 0) {
            if (filter.length > 0) {
                filter = filter.concat(['and']);
            }
            filter = filter.concat([[nameOfOperation('custKeyId'), '=', customerKeyId]]);
        }
        return filter;
    };
    OperationFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperationFilterService_Factory() { return new OperationFilterService(); }, token: OperationFilterService, providedIn: "root" });
    return OperationFilterService;
}());
exports.OperationFilterService = OperationFilterService;
