"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
/*
 * We add supporting for masking fields only with "Date" and "number" types.
 * The main reason is C# couldn't mask "Date" and "number" on backend side.
 * Fields with "string" type we are getting already masked from C# side.
 */
var MaskableFieldPipe = /** @class */ (function () {
    function MaskableFieldPipe(locale) {
        this.locale = locale;
    }
    MaskableFieldPipe.prototype.isValidDate = function (date) {
        return date && (Object.prototype.toString.call(date) === '[object Date]') && !isNaN(date);
    };
    MaskableFieldPipe.prototype.transform = function (value) {
        if (this.isValidDate(value)) {
            if (common_1.formatDate(value, 'shortDate', this.locale) === common_1.formatDate(td_constants_1.PIP_DUMMY_DATE, 'shortDate', this.locale)) {
                return td_constants_1.PIP_MASK_ASTERISKS;
            }
            else {
                return common_1.formatDate(value, 'short', this.locale);
            }
        }
        else if (value === td_constants_1.PIP_DUMMY_NUMBER) {
            return td_constants_1.PIP_MASK_ASTERISKS;
        }
        else {
            return value;
        }
    };
    return MaskableFieldPipe;
}());
exports.MaskableFieldPipe = MaskableFieldPipe;
