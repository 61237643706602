import { Component, Inject, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { TD_DESKTOP_IDENTIFIERS, TD_MODEL_NAMES, TD_MAX_INT32_VALUE, TD_ACTION_FIELDS } from '@core/data-layer/shared/models/td.constants';
import { TableTypes } from '@core/data-layer/shared/models/td.enumerations';
import { OrderModel } from '@core/data-layer/order/models/order.model';
import { WebOrderLineViewModel } from '@core/data-layer/order/models/web-order-view.model';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { GridColumnHelperService } from '@shared/services/grid-column-helper.service';
import { OrderLineState } from '@core/data-layer/order/models/web-order.model';
import { OrderServiceInterface } from '@core/data-layer/order/services/order.service.interface';
import { GridStructureViewInterface, GridColumnConfigurationViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { TranslateService } from '@ngx-translate/core';
import { OrderManagerService } from '@shared/services/order-manager.service';
import { PropertyTypes } from '@core/table-info/models/grid-structure.interface';
import { nameOf } from '@core/utility/utility.service';
import { TdDataGridComponent } from '../td-data-grid/td-data-grid.component';
import { GridOptionsInterface } from '@shared/models/grid-data-request.interface';

@Component({
  selector: 'td-order-cart',
  templateUrl: './td-order-cart.component.html',
  styleUrls: ['./td-order-cart.component.less']
})
export class TdOrderCartComponent implements OnInit, OnDestroy {

  orderCartMainTable = TD_MODEL_NAMES.webOrderLineModel;
  gridIdentifiers = TD_DESKTOP_IDENTIFIERS;
  maxQuantityValue = TD_MAX_INT32_VALUE;
  tableTypes = TableTypes;
  expanded = true;
  gridOptions: GridOptionsInterface;
  articleKeyId: number;
  articleType: TableTypes;

  orderCartGridStructure: GridStructureViewInterface;
  gridData: WebOrderLineViewModel[];
  collapseCartPixelWidth = 1000;
  routeSubscription: Subscription;

  @ViewChild('orderCartGrid', { static: false }) orderCartGrid: TdDataGridComponent;

  constructor(
    @Inject('ORDER_SERVICE') private orderService: OrderServiceInterface,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public sessionHelperService: SessionHelperService,
    private gridColumnHelperService: GridColumnHelperService,
    private translateService: TranslateService,
    private orderManagerService: OrderManagerService
  ) {
    this.orderCartGridStructure = {
      tables: [{
        tableName: this.orderCartMainTable,
        displayName: this.translateService.instant('tables.' + this.orderCartMainTable),
        columns: []
      }],
      columnsConfigurations: []
    }
  }

  public ngOnInit() {
    this.getSelectedArticleInfo();
    this.adjustCustomColumnsInGridTables();
    this.expanded = window.innerWidth > this.collapseCartPixelWidth;
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getSelectedArticleInfo() {
    this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .pipe(
        map(() => this.activatedRoute.root.firstChild),
        switchMap(firstChild => {
          if (firstChild && firstChild.firstChild) {
            return firstChild.firstChild.paramMap.pipe(map(paramMap => paramMap.get('id')));
          }
          else {
            return of(null);
          }
        })
      ).subscribe(id => {
        // remember item/product key id
        this.articleKeyId = +id;
        // remember item/product type
        if (this.router.url.includes('/items')) {
          this.articleType = TableTypes.Item;
        }
        else if (this.router.url.includes('/products')) {
          this.articleType = TableTypes.Product;
        }
        // repaint grid required to show highlight for selected item/product
        if (this.sessionHelperService.urlAllowsOrderCart(this.router.url)) {
          this.sessionHelperService.reloadCustomerOpenOrders();
        }
        if (this.orderCartGrid) {
          this.orderCartGrid.grid.instance.repaint();
        }
      });
  }

  setGridOptions() {
    if (this.sessionHelperService.userLastOpenOrder && this.sessionHelperService.userLastOpenOrder.lines.length > 0) {
      const borderHeight = 1;
      const paddingBottomTop = 8;
      this.gridOptions = this.gridColumnHelperService.getGridOptions(
        document.documentElement.clientHeight,
        this.sessionHelperService.userLastOpenOrder.lines.length,
        ['headerNavBar', 'cartIconArea', 'orderNumberArea', 'viewOrderBtnArea'],
        2 * paddingBottomTop + 2 * borderHeight
      );
    }
  }

  gridContentReady() {
    // we are using timeout to fix issue: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    setTimeout(() => this.setGridOptions(), 0);
  }

  gridRowPrepared(e) {
    const nameOfWebOrderLine = nameOf<WebOrderLineViewModel>();
    if (e.data && (e.data[nameOfWebOrderLine('oLnRefKeyId')] === this.articleKeyId) && (e.data[nameOfWebOrderLine('oLnRefType')] === this.articleType)) {
      e.rowElement.classList.add('highlightDataGridRow');
    }
  }

  lastOpenOrderKeyId(): number {
    return this.sessionHelperService.userLastOpenOrder ? this.sessionHelperService.userLastOpenOrder.details.keyId : 0;
  }

  adjustCustomColumnsInGridTables() {
    // add custom "article" column
    const articleColumn: GridColumnConfigurationViewInterface = {
      dataField: TD_ACTION_FIELDS.article,
      dataType: PropertyTypes.typeUndefined,
      visible: true,
      displayName: this.translateService.instant('calcColumns.article'),
    };
    this.gridColumnHelperService.addCustomColumn(this.orderCartGridStructure.columnsConfigurations, articleColumn);
    // add custom "quantity" column
    const quantityColumn: GridColumnConfigurationViewInterface = {
      dataField: TD_ACTION_FIELDS.quantity,
      dataType: PropertyTypes.typeUndefined,
      visible: true,
      width: '70',
      displayName: this.translateService.instant('calcColumns.qty'),
      style: {
        alignment: 'center'
      }
    };
    this.gridColumnHelperService.addCustomColumn(this.orderCartGridStructure.columnsConfigurations, quantityColumn);
    // add custom "delete" column
    const deleteColumn: GridColumnConfigurationViewInterface = {
      dataField: TD_ACTION_FIELDS.delete,
      dataType: PropertyTypes.typeUndefined,
      visible: true,
      width: '30',
      displayName: '',
      style: {
        alignment: 'center',
      }
    };
    this.gridColumnHelperService.addCustomColumn(this.orderCartGridStructure.columnsConfigurations, deleteColumn);
  }

  selectedOrderDisplayValue(order: OrderModel): string {
    return order ? `${order.orgRefNo}` : null;
  }

  selectedOrderChanged(e: any) {
    this.sessionHelperService.reloadLastOpenOrder(e.value);
  }

  createNewOrder() {
    const newOrder = this.orderManagerService.initializeNewOrder();
    this.orderService.saveOrder(newOrder).subscribe(response => {
      this.sessionHelperService.reloadCustomerOpenOrders();
      this.sessionHelperService.reloadLastOpenOrder(response.value);
    });
  }

  toggleExpandCart(): void {
    this.expanded = !this.expanded;
  }

  saveOrderLine(orderLine: WebOrderLineViewModel) {
    this.orderService.saveOrderLines(this.lastOpenOrderKeyId(), [orderLine]).subscribe(response => {
      this.sessionHelperService.reloadLastOpenOrder(this.lastOpenOrderKeyId());
    });
  }

  updateLine(e: any, orderLine: WebOrderLineViewModel) {
    if (e.previousValue) {
      if (this.orderManagerService.updateLineQuantity(e.value as number, orderLine)) {
        this.saveOrderLine(orderLine);
      }
      else {
        e.component.option('value', e.previousValue);
      }
    }
  }

  deleteLine(orderLine: WebOrderLineViewModel) {
    orderLine.lineState = OrderLineState.Deleted;
    this.saveOrderLine(orderLine);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.expanded = window.innerWidth > this.collapseCartPixelWidth;
    if (this.expanded) {
      this.setGridOptions();
    }
  }
}
