import { Profile } from 'oidc-client';

import * as userSessionActions from './user-session.actions';
import { ProfileDataModel } from '../models/profile-data.model';
import { UserDesktopOptionsModel } from '../models/user-desktop-options.model';

export interface UserSessionState {
  authUser: Profile;
  profileData: ProfileDataModel;
  userOptions: UserDesktopOptionsModel;
  defaultUserOptions: UserDesktopOptionsModel;
}

export const initialUserSessionState: UserSessionState = {
  authUser: null,
  profileData: null,
  userOptions: null,
  defaultUserOptions: null
};

export function userSessionReducer(state = initialUserSessionState, action: userSessionActions.UserSessionActions): UserSessionState {
  switch (action.type) {
    case userSessionActions.UserSessionActionTypes.LoadProfileData:
      return handleLoadProfileData(state, action);
    case userSessionActions.UserSessionActionTypes.SetProfileData:
      return handleSetProfileData(state, action);
    case userSessionActions.UserSessionActionTypes.LoadUserOptions:
      return handleLoadUserOptions(state, action);
    case userSessionActions.UserSessionActionTypes.SetUserOptions:
      return handleSetUserOptions(state, action);
    case userSessionActions.UserSessionActionTypes.LoadDefaultUserOptions:
      return handleLoadDefaultUserOptions(state, action);
    case userSessionActions.UserSessionActionTypes.SetDefaultUserOptions:
      return handleSetDefaultUserOptions(state, action);
    default:
      return state;
  }
}

function handleLoadProfileData(state: UserSessionState, action: userSessionActions.LoadProfileData): UserSessionState {
  return state;
}

function handleSetProfileData(state: UserSessionState, action: userSessionActions.SetProfileData): UserSessionState {
  return Object.assign({}, state, { profileData: action.payload });
}

function handleLoadUserOptions(state: UserSessionState, action: userSessionActions.LoadUserOptions): UserSessionState {
  return state;
}

function handleSetUserOptions(state: UserSessionState, action: userSessionActions.SetUserOptions): UserSessionState {
  return Object.assign({}, state, { userOptions: action.payload });
}

function handleLoadDefaultUserOptions(state: UserSessionState, action: userSessionActions.LoadDefaultUserOptions): UserSessionState {
  return state;
}

function handleSetDefaultUserOptions(state: UserSessionState, action: userSessionActions.SetDefaultUserOptions): UserSessionState {
  return Object.assign({}, state, { defaultUserOptions: action.payload });
}
