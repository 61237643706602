import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UnitModel } from '../models/unit.model';
import { UnitViewModel } from '../models/unit-view.model';
import { UnitStatus, ItemInstrumentType } from '@core/data-layer/shared/models/td.enumerations';
import { ViewHelperService } from '@core/data-layer/shared/services/view-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UnitHelperService {

  constructor(
    private translateService: TranslateService,
    private viewHelperService: ViewHelperService
  ) { }

  /**
   * @description converts [UnitModel][Object] to [UnitViewModel][Object]
   * @param unit: [UnitModel][Object]
   * @returns [UnitViewModel][Object]
   */
  convertUnitToViewModel(unit: UnitModel): UnitViewModel {
    const isEndoscope: boolean = this.viewHelperService.getItemType(unit.prod.item) === ItemInstrumentType.Endoscope;
    return {
      ...unit,
      productText: unit.prod.product + ' - ' + unit.prod.item.text,
      customerText: unit.cust ? unit.cust.customer + ' ' + unit.cust.name : '',
      errText: unit.errorText ? unit.errorText.text : this.translateService.instant('unitDetails.none'),
      statusText:  isEndoscope ? 
        this.translateService.instant(`endoscopeUnitStatus.${UnitStatus[unit.status as UnitStatus].toLowerCase()}`) 
        : this.translateService.instant(`unitStatus.${UnitStatus[unit.status as UnitStatus].toLowerCase()}`),
      expire: unit.expire ? new Date(unit.expire) : null,
      isEndoscope: isEndoscope
    };
  }
}
