"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var JumpErrorCodes;
(function (JumpErrorCodes) {
    JumpErrorCodes[JumpErrorCodes["NoError"] = 0] = "NoError";
    JumpErrorCodes[JumpErrorCodes["TechnicalError"] = 1] = "TechnicalError";
    JumpErrorCodes[JumpErrorCodes["LinkExpired"] = 2] = "LinkExpired";
    JumpErrorCodes[JumpErrorCodes["UnableAutoLogin"] = 3] = "UnableAutoLogin";
    JumpErrorCodes[JumpErrorCodes["WrongUserLoggedIn"] = 4] = "WrongUserLoggedIn";
    JumpErrorCodes[JumpErrorCodes["OperationNotFound"] = 5] = "OperationNotFound";
})(JumpErrorCodes = exports.JumpErrorCodes || (exports.JumpErrorCodes = {}));
