import { Action } from '@ngrx/store';

import { CustomerModel } from '../models/customer.model';

export enum CustomerActionTypes {
  LoadUserCustomers = '[Customer] Load User Customers',
  SetUserCustomers = '[Customer] Set User Customers',
  SetCurrentCustomer = '[Customer] Set Current Customer',
  LoadSiteCustomers = '[Customer] Load Site Customers',
  SetSiteCustomers = '[Customer] Set Site Customers',
}

export class LoadUserCustomers implements Action {
  readonly type = CustomerActionTypes.LoadUserCustomers;

  constructor() { }
}

export class SetUserCustomers implements Action {
  readonly type = CustomerActionTypes.SetUserCustomers;

  constructor(public payload: CustomerModel[]) { }
}

export class SetCurrentCustomer implements Action {
  readonly type = CustomerActionTypes.SetCurrentCustomer;

  constructor(public payload: CustomerModel) { }
}

export class LoadSiteCustomers implements Action {
  readonly type = CustomerActionTypes.LoadSiteCustomers;
  // payload = cuSiteKeyId
  constructor(public payload: number) { }
}

export class SetSiteCustomers implements Action {
  readonly type = CustomerActionTypes.SetSiteCustomers;

  constructor(public payload: CustomerModel[]) { }
}

export type CustomerActions = LoadUserCustomers | SetUserCustomers | SetCurrentCustomer | LoadSiteCustomers | SetSiteCustomers;
