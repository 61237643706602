"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var authentication_enumerations_1 = require("@core/authentication/models/authentication.enumerations");
var auth_guard_1 = require("@core/guards/auth.guard");
var search_component_1 = require("./search/search.component");
var signin_callback_component_1 = require("./authentication/signin-callback/signin-callback.component");
var signout_callback_component_1 = require("./authentication/signout-callback/signout-callback.component");
var auth_customers_component_1 = require("./authentication/auth-customers/auth-customers.component");
var error_component_1 = require("./error/error.component");
var landing_component_1 = require("./landing/landing.component");
var route_guard_1 = require("@core/guards/route.guard");
var app_constants_1 = require("@shared/models/app.constants");
var changePassword_guard_1 = require("./core/guards/changePassword.guard");
var td_loading_component_1 = require("./shared/components/td-loading/td-loading.component");
var td_change_password_success_component_1 = require("./shared/components/td-change-password-success/td-change-password-success.component");
var td_deep_link_component_1 = require("./shared/components/td-deep-link/td-deep-link.component");
var ɵ0 = { guardType: app_constants_1.GuardTypes.AllowAlways }, ɵ1 = { guardType: app_constants_1.GuardTypes.LoginModeAlways }, ɵ2 = { guardType: app_constants_1.GuardTypes.LoginModeAlways }, ɵ3 = { roles: [authentication_enumerations_1.Role.Admin], guardType: app_constants_1.GuardTypes.LoginModeAlways }, ɵ4 = { guardType: app_constants_1.GuardTypes.LoginModeAlways }, ɵ5 = { path: app_constants_1.ROUTE_PATHS.productionOverview }, ɵ6 = {
    guardType: app_constants_1.GuardTypes.AuthenticatedUserAndCustomerSelected,
    path: app_constants_1.ROUTE_PATHS.orders
}, ɵ7 = {
    guardType: app_constants_1.GuardTypes.AuthenticatedUserAndCustomerSelected,
    path: app_constants_1.ROUTE_PATHS.orderTemplates
}, ɵ8 = function () { return Promise.resolve().then(function () { return require("./operations/operations.module.ngfactory"); }).then(function (module) { return module.OperationsModuleNgFactory; }); }, ɵ9 = {
    guardType: app_constants_1.GuardTypes.AuthenticatedUserAndCustomerSelected,
    path: app_constants_1.ROUTE_PATHS.operations
};
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ɵ5 = ɵ5;
exports.ɵ6 = ɵ6;
exports.ɵ7 = ɵ7;
exports.ɵ8 = ɵ8;
exports.ɵ9 = ɵ9;
var appRoutes = [
    {
        path: '',
        component: landing_component_1.LandingComponent,
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ0
    },
    {
        path: 'products',
        loadChildren: './products/products.module#ProductsModule',
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ1
    },
    {
        path: 'units',
        loadChildren: './units/units.module#UnitsModule',
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ2
    },
    {
        path: 'items',
        loadChildren: './items/items.module#ItemsModule',
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ3
    },
    {
        path: 'signin-callback',
        component: signin_callback_component_1.SigninCallbackComponent
    },
    {
        path: 'signout-callback',
        component: signout_callback_component_1.SignoutCallbackComponent
    },
    {
        path: 'auth-customers',
        component: auth_customers_component_1.AuthCustomersComponent,
        canActivate: [auth_guard_1.AuthGuard],
    },
    {
        path: 'search',
        component: search_component_1.SearchComponent,
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ4
    },
    {
        path: 'production-overview',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ5
    },
    {
        path: 'orders',
        loadChildren: './orders/orders.module#OrdersModule',
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ6
    },
    {
        path: 'order-templates',
        loadChildren: './order-templates/order-templates.module#OrderTemplatesModule',
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ7
    },
    {
        path: 'operations',
        loadChildren: ɵ8,
        canActivate: [route_guard_1.RouteGuard],
        data: ɵ9,
    },
    {
        path: 'error',
        component: error_component_1.ErrorComponent
    },
    {
        path: '$/start',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
    },
    {
        path: 'changePassword',
        canActivate: [changePassword_guard_1.RedirectGuard],
        // We need a component here because we cannot define the route otherwise
        component: td_loading_component_1.LoadingComponent
    },
    {
        path: 'passwordChanged',
        component: td_change_password_success_component_1.ChangePasswordSuccessComponent
    },
    {
        path: 'jump',
        component: td_deep_link_component_1.DeepLinkComponent
    },
    // otherwise redirect to search
    {
        path: '**',
        redirectTo: ''
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
