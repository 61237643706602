import { Injectable, Inject } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { OrderActionTypes, LoadCustomerOpenOrders, LoadUserLastOpenOrder } from './order.actions';
import { OrderServiceInterface } from '../services/order.service.interface';
import { OrderOrgStatus } from '@core/data-layer/shared/models/td.enumerations';

@Injectable()
export class OrderEffects {

  constructor(
    private actions$: Actions,
    @Inject('ORDER_SERVICE') private orderService: OrderServiceInterface) {
  }

  @Effect()
  loadCustomerOpenOrders$ = this.actions$
    .pipe(
      ofType(OrderActionTypes.LoadCustomerOpenOrders),
      mergeMap((act: LoadCustomerOpenOrders) => {
        return this.orderService.getOrdersByCustomerAndOrgStatus(act.payload, OrderOrgStatus.Open)
          .pipe(
            map(response => ({ type: OrderActionTypes.SetCustomerOpenOrders, payload: response })),
            catchError(() => of({ type: OrderActionTypes.SetCustomerOpenOrders, payload: [] }))
          );
      })
  );

  @Effect()
  loadUserLastOpenOrder$ = this.actions$
    .pipe(
      ofType(OrderActionTypes.LoadUserLastOpenOrder),
      mergeMap((act: LoadUserLastOpenOrder) => {
        return this.orderService.getOrder(act.orderKeyId, act.customerKeyId)
          .pipe(
            map(response => ({ type: OrderActionTypes.SetUserLastOpenOrder, payload: response.value })),
            catchError(() => of({ type: OrderActionTypes.SetUserLastOpenOrder, payload: null }))
          );
      })
    );
}
