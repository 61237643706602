"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var core_1 = require("@angular/core");
var td_popup_service_1 = require("../td-popup/services/td-popup.service");
var TdSerialNumberInfoComponent = /** @class */ (function () {
    function TdSerialNumberInfoComponent(router, popupService) {
        this.router = router;
        this.popupService = popupService;
    }
    TdSerialNumberInfoComponent.prototype.ngOnInit = function () { };
    TdSerialNumberInfoComponent.prototype.cancel = function () {
        this.popupService.cancelPopup();
    };
    TdSerialNumberInfoComponent.prototype.navigateToUnit = function () {
        this.cancel();
        this.router.navigate(['/units', this.serialInfo.unit]);
    };
    return TdSerialNumberInfoComponent;
}());
exports.TdSerialNumberInfoComponent = TdSerialNumberInfoComponent;
