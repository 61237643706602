"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@ngx-translate/core");
var order_translate_mapping_1 = require("@core/language/models/order-translate.mapping");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var language_service_1 = require("@core/language/services/language.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var img_enumerations_1 = require("@shared/models/img.enumerations");
var app_constants_1 = require("@shared/models/app.constants");
var i0 = require("@angular/core");
var i1 = require("../../../language/services/language.service");
var i2 = require("@ngx-translate/core");
var OrderHelperService = /** @class */ (function () {
    function OrderHelperService(languageService, translateService) {
        this.languageService = languageService;
        this.translateService = translateService;
    }
    /**
     * @description converts [WebOrderReservLineModel][Array] and adds image paths
     * @param data [WebOrderReservLineModel][Array]
     * @returns [ReservationLineViewModel][Array]
     */
    OrderHelperService.prototype.prepareReservationLineResponse = function (data) {
        var convertedData = this.convertReservationLineToViewModel(data);
        return convertedData;
    };
    /**
     * @description converts [WebOrderReservLineModel][Array] to view model [Array]
     * @param data: [WebOrderReservLineModel][Array]
     * @returns [ReservationLineViewModel][Array]
     */
    OrderHelperService.prototype.convertReservationLineToViewModel = function (data) {
        var _this = this;
        return data.map(function (line) {
            return tslib_1.__assign({}, line, { resvStateText: _this.languageService.translateEnumValue(app_constants_1.RESERVATION_STATE, line.resvState), resvStatusText: '', resvStateImagePath: img_enumerations_1.ReservationStateGridImgPaths[line.resvState] });
        });
    };
    /**
     * @description: converts [WebOrderBookingDetailsModel] to [WebOrderBookingDetailsViewModel]
     * @param data: [WebOrderBookingDetailsModel][Object]
     * @param resvLines: [ReservationLineViewModel][Array] - Reservation lines converted to view model
     * @param resvLogs: [StringDictionary<WebOrderReservMsgLineModel[]>] - Reservation logs converted to view model
     * @param resvStates: [StringDictionary<ReservationState>] - Reservation States
     * @returns [WebOrderBookingDetailsViewModel] - The WebOrderBookingDetails model converted to view model
     */
    OrderHelperService.prototype.convertBookingDetailsToViewModel = function (data, resvLines, resvLogs, resvStates) {
        return tslib_1.__assign({}, data, { reservLines: resvLines, reservLogs: resvLogs, reservStates: resvStates });
    };
    /**
     * @description converts [OrderModel][Array] to [OrderViewModel][Array], and translate any [enum] properties
     * @param data: [OrderModel][Array] - An array of [OrderModel][Objects]
     * @returns [OrderViewModel][Array] - An array of [OrderViewModel][Objects]
     */
    OrderHelperService.prototype.prepareOrderResponse = function (data) {
        var convertedData = this.convertOrderToViewModel(data);
        return this.languageService.translateAllEnums(convertedData, order_translate_mapping_1.ORDER_PROPERTIES_TO_ENUM_MAPPING);
    };
    /**
     * @description converts [OrderModel][Array] to [OrderViewModel][Array] and calculates calculated fields
     * @param data: [OrderModel][Array] - An array of [OrderModel][Objects]
     * @returns [OrderViewModel][Array] - An array of [OrderViewModel][Objects]
     */
    OrderHelperService.prototype.convertOrderToViewModel = function (data) {
        var _this = this;
        return data.map(function (order) {
            order[td_constants_1.TD_CALC_FIELDS.status] = _this.determineStatus(order);
            order[td_constants_1.TD_CALC_FIELDS.time] = _this.determineTime(order);
            order[td_constants_1.TD_CALC_FIELDS.orgStatusText] = '';
            order[td_constants_1.TD_CALC_FIELDS.suppStatusText] = '';
            return order;
        });
    };
    /**
     * @description calculates [status][property] of an [OrderModel][Object]
     * @param order: [OrderModel] - The order to calculate [status][property] on
     * @returns [string] - The calculated [status][property] as a [string]
     */
    OrderHelperService.prototype.determineStatus = function (order) {
        var status = td_enumerations_1.OrderOrgStatus[order.orgStatus];
        var translateString = "orderOrgStatus." + status.toLowerCase();
        if (order.suppStatus === td_enumerations_1.OrderSuppStatus.Delivered) {
            translateString = "orderSuppStatus." + td_enumerations_1.OrderSuppStatus[order.suppStatus].toLowerCase();
            status = this.translateService.instant(translateString);
        }
        else if ((order.suppStatus > td_enumerations_1.OrderSuppStatus.Order) && (order.suppStatus < td_enumerations_1.OrderSuppStatus.Cancelled)) {
            status = this.translateService.instant(translateString);
            var suppStatus = "orderSuppStatus." + td_enumerations_1.OrderSuppStatus[order.suppStatus].toLowerCase();
            suppStatus = this.translateService.instant(suppStatus);
            status = status + " & " + suppStatus;
        }
        else {
            status = this.translateService.instant(translateString);
        }
        return status;
    };
    /**
     * @description calculates [time][property] of an [OrderModel][Object]
     * @param order: [OrderModel] - The order to calculate [time][property] on
     * @returns [Date] - The calculated [Date][property] as a [Date]
     */
    OrderHelperService.prototype.determineTime = function (order) {
        return order.suppTime ? order.suppTime : order.orgTime;
    };
    /**
     * @description converts [WebOrderDetailsModel][Object] to [WebOrderDetailsViewModel][Object], and translate any [enum] properties
     * @param data: [WebOrderDetailsModel][Object]
     * @returns [WebOrderDetailsViewModel][Object]
     */
    OrderHelperService.prototype.prepareOrderDetailsResponse = function (data) {
        data.opDStartTime = data.opDStartTime ? new Date(data.opDStartTime) : null;
        data.orgTime = data.orgTime ? new Date(data.orgTime) : null;
        data.reqDelTime = data.reqDelTime ? new Date(data.reqDelTime) : null;
        data.suppTime = data.suppTime ? new Date(data.suppTime) : null;
        var convertedData = this.convertOrderDetailsToViewModel(data);
        return this.languageService.translateEnum(convertedData, order_translate_mapping_1.ORDER_DETAILS_PROPERTIES_TO_ENUM_MAPPING);
    };
    /**
     * @description converts [WebOrderDetailsModel][Object] to [WebOrderDetailsViewModel][Object]
     * @param data: [WebOrderDetailsModel][Object]
     * @returns [WebOrderDetailsModel][Object]
     */
    OrderHelperService.prototype.convertOrderDetailsToViewModel = function (data) {
        return tslib_1.__assign({}, data, { suppStatusText: '', orgStatusText: '' });
    };
    /**
     * @description converts [WebOrderModel][Object] to [WebOrderViewModel][Object]
     * @param state: [WebOrderState][Object]
     * @param details: [WebOrderDetailsViewModel][Object]
     * @param lines: [WebOrderLineViewModel][Array]
     * @returns [WebOrderViewModel][Object]
     */
    OrderHelperService.prototype.convertWebOrderToViewModel = function (state, details, lines) {
        return {
            state: state,
            details: details,
            lines: lines
        };
    };
    /**
     * @description converts [WebOrderLineModel][Array] to [WebOrderLineViewModel][Array]m translate any [enum] properties and calculates calculated fields
     * @param data: [WebOrderLineModel][Array]
     * @returns [WebOrderLineViewModel][Array]
     */
    OrderHelperService.prototype.prepareOrderLinesResponse = function (data) {
        var _this = this;
        var convertedData = this.convertOrderLinesToViewModel(data);
        convertedData = this.getCalculatedFields(convertedData);
        return convertedData.map(function (entry) {
            switch (entry.oLnRefType) {
                case td_enumerations_1.TableTypes.Product:
                    if (entry.itemInstrumentType === td_enumerations_1.ItemInstrumentType.Endoscope) {
                        entry.oLnRefTypeText = _this.translateService.instant('orderLineRefType.endoscope');
                    }
                    else {
                        entry.oLnRefTypeText = _this.translateService.instant('orderLineRefType.product');
                    }
                    break;
                case td_enumerations_1.TableTypes.Item:
                    entry.oLnRefTypeText = _this.translateService.instant('orderLineRefType.item');
                    break;
            }
            return entry;
        });
    };
    /**
     * @description converts [WebOrderLineModel][Array] to [WebOrderLineViewModel][Array]
     * @param data: [WebOrderLineModel][Array]
     * @returns [WebOrderLineViewModel][Array]
     */
    OrderHelperService.prototype.convertOrderLinesToViewModel = function (data) {
        var _this = this;
        return data ? data.map(function (orderLine) {
            return tslib_1.__assign({}, orderLine, { oLnRefTypeText: '', oLnOrgOrderedText: '', oLnSuppDeliveredText: '', oLnSuppLaterText: '', oLnResvStateText: _this.languageService.translateEnumValue(app_constants_1.RESERVATION_STATE, orderLine.oLnResvState) });
        }) : null;
    };
    /**
     * @description calculates all properties needed on a [webOrderLineViewModel][Array]
     * @param lines [WebOrderLineViewModel][Array]
     * @returns [WebOrderLineViewModel][Array]
     */
    OrderHelperService.prototype.getCalculatedFields = function (lines) {
        var _this = this;
        if (lines) {
            return lines.map(function (entry) {
                return tslib_1.__assign({}, entry, { oLnOrgOrderedText: _this.calculateOrderedSum(entry), oLnSuppDeliveredText: _this.calculateDeliveredSum(entry), oLnSuppLaterText: _this.calculateBackOrderSum(entry) });
            });
        }
        else {
            return lines;
        }
    };
    /**
     * @description calculates the [orderedSum][property] on a given [WebOrderLineModel] to a [string]
     * @param input: [WebOrderLineModel]
     * @returns [string]
     */
    OrderHelperService.prototype.calculateOrderedSum = function (input) {
        var orderedSum = input.oLnOrgOrdered.toString();
        if (input.oLnOrgStdCount > 1) {
            orderedSum = orderedSum + "(" + input.oLnOrgStdCount + ")";
        }
        return orderedSum;
    };
    /**
     * @description calculates the [deliveredSum][property] on a given [WebOrderLineModel] to a [string]
     * @param input: [WebOrderLineModel]
     * @returns [string]
     */
    OrderHelperService.prototype.calculateDeliveredSum = function (input) {
        var deliveredSum = input.oLnSuppDelivered.toString();
        if (input.oLnSuppStdCount > 1 && input.oLnSuppDelivered > 0) {
            deliveredSum = deliveredSum + "(" + input.oLnSuppStdCount + ")";
        }
        return deliveredSum;
    };
    /**
     * @description calculates the [backOrderSum][property] on a given [WebOrderLineModel] to a [string]
     * @param input: [WebOrderLineModel]
     * @returns [string]
     */
    OrderHelperService.prototype.calculateBackOrderSum = function (input) {
        var backOrderSum = input.oLnSuppLater.toString();
        if (input.oLnSuppStdCount > 1 && input.oLnSuppLater > 0) {
            backOrderSum = backOrderSum + "(" + input.oLnSuppStdCount + ")";
        }
        return backOrderSum;
    };
    OrderHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderHelperService_Factory() { return new OrderHelperService(i0.ɵɵinject(i1.LanguageService), i0.ɵɵinject(i2.TranslateService)); }, token: OrderHelperService, providedIn: "root" });
    return OrderHelperService;
}());
exports.OrderHelperService = OrderHelperService;
