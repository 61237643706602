"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReservationStateImgPaths;
(function (ReservationStateImgPaths) {
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/undetermined.png"] = 0] = "./assets/img/order/booking-status/undetermined.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/okOriginal.png"] = 1] = "./assets/img/order/booking-status/okOriginal.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/okIdentical.png"] = 2] = "./assets/img/order/booking-status/okIdentical.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/okAlternative.png"] = 3] = "./assets/img/order/booking-status/okAlternative.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/okIdenticalForAlternative.png"] = 4] = "./assets/img/order/booking-status/okIdenticalForAlternative.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/warningOriginal.png"] = 5] = "./assets/img/order/booking-status/warningOriginal.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/warningIdentical.png"] = 6] = "./assets/img/order/booking-status/warningIdentical.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/warningAlternative.png"] = 7] = "./assets/img/order/booking-status/warningAlternative.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/warningIdenticalForAlternative.png"] = 8] = "./assets/img/order/booking-status/warningIdenticalForAlternative.png";
    ReservationStateImgPaths[ReservationStateImgPaths["./assets/img/order/booking-status/Error.svg"] = 9] = "./assets/img/order/booking-status/Error.svg";
})(ReservationStateImgPaths = exports.ReservationStateImgPaths || (exports.ReservationStateImgPaths = {}));
var ReservationStateGridImgPaths;
(function (ReservationStateGridImgPaths) {
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/undetermined.png"] = 0] = "./assets/img/order/booking-status/undetermined.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/16x16/okOriginal.png"] = 1] = "./assets/img/order/booking-status/16x16/okOriginal.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/16x16/okIdentical.png"] = 2] = "./assets/img/order/booking-status/16x16/okIdentical.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/16x16/okAlternative.png"] = 3] = "./assets/img/order/booking-status/16x16/okAlternative.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/16x16/okIdenticalForAlternative.png"] = 4] = "./assets/img/order/booking-status/16x16/okIdenticalForAlternative.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/16x16/warningOriginal.png"] = 5] = "./assets/img/order/booking-status/16x16/warningOriginal.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/16x16/warningIdentical.png"] = 6] = "./assets/img/order/booking-status/16x16/warningIdentical.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/16x16/warningAlternative.png"] = 7] = "./assets/img/order/booking-status/16x16/warningAlternative.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/16x16/warningIdenticalForAlternative.png"] = 8] = "./assets/img/order/booking-status/16x16/warningIdenticalForAlternative.png";
    ReservationStateGridImgPaths[ReservationStateGridImgPaths["./assets/img/order/booking-status/Error.svg"] = 9] = "./assets/img/order/booking-status/Error.svg";
})(ReservationStateGridImgPaths = exports.ReservationStateGridImgPaths || (exports.ReservationStateGridImgPaths = {}));
var OperationOrderStateImgPaths;
(function (OperationOrderStateImgPaths) {
    OperationOrderStateImgPaths[OperationOrderStateImgPaths["./assets/img/Warning_Orange.svg"] = 0] = "./assets/img/Warning_Orange.svg";
    OperationOrderStateImgPaths[OperationOrderStateImgPaths["./assets/img/Error.svg"] = 1] = "./assets/img/Error.svg";
    OperationOrderStateImgPaths[OperationOrderStateImgPaths[""] = 2] = "";
    OperationOrderStateImgPaths[OperationOrderStateImgPaths["./assets/img/Approved.svg"] = 3] = "./assets/img/Approved.svg";
})(OperationOrderStateImgPaths = exports.OperationOrderStateImgPaths || (exports.OperationOrderStateImgPaths = {}));
