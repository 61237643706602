"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ArticleType;
(function (ArticleType) {
    ArticleType[ArticleType["Product"] = 0] = "Product";
    ArticleType[ArticleType["Item"] = 1] = "Item";
    ArticleType[ArticleType["Endoscope"] = 2] = "Endoscope";
})(ArticleType = exports.ArticleType || (exports.ArticleType = {}));
var ProfileMenu;
(function (ProfileMenu) {
    ProfileMenu[ProfileMenu["Settings"] = 0] = "Settings";
    ProfileMenu[ProfileMenu["ChangePassword"] = 1] = "ChangePassword";
})(ProfileMenu = exports.ProfileMenu || (exports.ProfileMenu = {}));
