"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var environment_1 = require("src/environments/environment");
var router_1 = require("@angular/router");
var td_popup_service_1 = require("@shared/components/td-popup/services/td-popup.service");
var error_report_manager_service_1 = require("../error-report/services/error-report-manager.service");
var GlobalErrorHandler = /** @class */ (function () {
    function GlobalErrorHandler(injector, errorReportManagerService, popupService) {
        this.injector = injector;
        this.errorReportManagerService = errorReportManagerService;
        this.popupService = popupService;
    }
    GlobalErrorHandler.prototype.handleError = function (error) {
        if (environment_1.environment.logInternalErrors && !(error instanceof http_1.HttpErrorResponse)) {
            if (environment_1.environment.production) {
                var router = this.injector.get(router_1.Router);
                this.errorReportManagerService.handleError(error, '', router.url, error.name, error.message);
            }
            else {
                console.error(error);
            }
            this.popupService.showError('appMessages.technicalErrorContactSupport', true);
        }
    };
    return GlobalErrorHandler;
}());
exports.GlobalErrorHandler = GlobalErrorHandler;
