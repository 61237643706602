import { Injectable } from '@angular/core';

import { UserDesktopOptionsModel } from '@core/data-layer/user-session/models/user-desktop-options.model';
import { BehaviorSubject } from 'rxjs';
import { SearchFiltersInterface } from '../models/search-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchFiltersHelperService {

  searchFilterOptions$ = new BehaviorSubject<SearchFiltersInterface>(null);

  constructor() { }

  getSearchFiltersOptions({
    search_OrderableFromCurrentFactory,
    display_ProdFiltMethodGeneral,
    display_ProdFiltMethodCustomer,
    display_ProdFiltMethodCustSite
  }: UserDesktopOptionsModel): SearchFiltersInterface {
    const searchFilterOptions = {
      search_OrderableFromCurrentFactory,
      display_ProdFiltMethodGeneral,
      display_ProdFiltMethodCustomer,
      display_ProdFiltMethodCustSite
    };
    this.searchFilterOptions$.next(searchFilterOptions);
    return searchFilterOptions;
  }
}
