"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_authentication_service_1 = require("@core/authentication/services/http-authentication.service");
var signal_r_info_overview_service_1 = require("@app/dashboard/data-layer/info-overview/services/signal-r-info-overview.service");
var http_customer_service_1 = require("@core/data-layer/customer/services/http-customer.service");
var http_order_service_1 = require("@core/data-layer/order/services/http-order.service");
var http_factory_service_1 = require("@core/data-layer/factory/services/http-factory.service");
var http_unit_service_1 = require("@core/data-layer/unit/services/http-unit.service");
var http_user_session_service_1 = require("@core/data-layer/user-session/services/http-user-session.service");
var http_server_session_service_1 = require("@core/data-layer/server-session/services/http-server-session.service");
var http_table_info_service_1 = require("@core/table-info/services/http-table-info.service");
var http_media_service_1 = require("@core/media/services/http-media.service");
var http_search_service_1 = require("@core/search/services/http-search.service");
var http_error_report_service_1 = require("@core/error-report/services/http-error-report.service");
var http_product_service_1 = require("@core/data-layer/product/services/http-product.service");
var http_item_service_1 = require("@core/data-layer/item/services/http-item.service");
var http_order_template_service_1 = require("@core/data-layer/order-template/services/http-order-template.service");
var http_license_service_1 = require("@core/license/services/http-license.service");
var signal_r_license_binding_service_1 = require("@core/license/services/signal-r-license-binding.service");
var http_client_configuration_service_1 = require("@core/client-configuration/services/http-client-configuration.service");
var http_external_documents_service_1 = require("@core/data-layer/external-documents/services/http-external-documents.service");
var http_operation_data_service_1 = require("@core/data-layer/operation-data/services/http-operation-data.service");
var http_substitutions_service_1 = require("@core/substitutions/services/http-substitutions.service");
exports.environment = {
    production: true,
    telemetry: true,
    logHttpErrors: true,
    logInternalErrors: true,
    authenticationServiceType: http_authentication_service_1.HttpAuthenticationService,
    infoOverviewServiceType: signal_r_info_overview_service_1.SignalRInfoOverviewService,
    customerServiceType: http_customer_service_1.HttpCustomerService,
    orderServiceType: http_order_service_1.HttpOrderService,
    productServiceType: http_product_service_1.HttpProductService,
    factoryServiceType: http_factory_service_1.HttpFactoryService,
    unitServiceType: http_unit_service_1.HttpUnitService,
    userSessionServiceType: http_user_session_service_1.HttpUserSessionService,
    serverSessionServiceType: http_server_session_service_1.HttpServerSessionService,
    tableInfoServiceType: http_table_info_service_1.HttpTableInfoService,
    mediaServiceType: http_media_service_1.HttpMediaService,
    searchServiceType: http_search_service_1.HttpSearchService,
    errorReportServiceType: http_error_report_service_1.HttpErrorReportService,
    itemServiceType: http_item_service_1.HttpItemService,
    orderTemplateServiceType: http_order_template_service_1.HttpOrderTemplateService,
    licenseServiceType: http_license_service_1.HttpLicenseService,
    licenseBindingServiceType: signal_r_license_binding_service_1.SignalRLicenseBindingService,
    clientConfigurationServiceType: http_client_configuration_service_1.HttpClientConfigurationService,
    externalDocumentsServiceType: http_external_documents_service_1.HttpExternalDocumentsService,
    operationDataServiceType: http_operation_data_service_1.HttpOperationDataService,
    substitutionsServiceType: http_substitutions_service_1.HttpSubstitutionsService
};
