import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { AppState } from '../data-layer/app.state';
import { selectCurrentCustomer } from '../data-layer/customer/store/customer.selectors';
import { UtilityService } from '../utility/utility.service';
import { AuthenticationServiceInterface } from '../authentication/services/authentication.service.interface';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { WebLoginMode } from '../data-layer/shared/models/td.enumerations';
import { TdPopupService } from '@shared/components/td-popup/services/td-popup.service';
import { GuardTypes, GUARD_TYPE, ROUTE_PATHS } from '@shared/models/app.constants';
import { selectInfoOverviews } from '@app/dashboard/data-layer/info-overview/store/info-overview.selectors';
import { POConst } from '@app/dashboard/models/production-overview.constants';
import { TD_MODULES } from '../data-layer/shared/models/td.constants';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {

  customerSelected = false;
  loginMode: WebLoginMode;

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    private store: Store<AppState>,
    private utilityService: UtilityService,
    private sessionHelperService: SessionHelperService,
    private router: Router,
    private popupService: TdPopupService
  ) {
    this.store.pipe(select(selectCurrentCustomer)).subscribe(cust => {      
      this.customerSelected = !this.utilityService.objIsEmpty(cust);          
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    let response = false;
    let routeTarget = route.data.path;
    let childId;
    if (route.children.length > 0) {      
      childId = route.children[0].params['id'];
      routeTarget += `/${childId}`;
    }
    
    /*
      How a route is guarded is determined by two factors:
        guardType, which can be 1 of 2:
          LoginModeAlways: If set, requires the user to be logged in and authenticated to access a route
          AuthenticatedUserAndCustomerSelected: Some routes will always require you to be logged in and have a customer selected, independent of loginMode
        loginMode, which can be 1 of 2:
          Always: The route always require the user to be logged in and a customer to be selected
          WhenSpecified: The route can be accessed without being logged in
    */
   /**
    * Route: production-overview
    */
    if (route.data.path && route.data.path === ROUTE_PATHS.productionOverview) {
      response = this.handleProductionOverviewRequest(route);
      return response;      
    }
    /**
     * Any other routes are checked by a combination of guardType and loginMode
     */
    const loginMode = this.sessionHelperService.clientConfiguration.loginMode;
    const guardType = route.data[GUARD_TYPE];
    if (guardType === GuardTypes.AuthenticatedUserAndCustomerSelected) {
      response = this.checkIfUserIsAuthenticatedAndCustomerIsSelected(routeTarget);
    }
    else if (guardType === GuardTypes.LoginModeAlways) {
      if (loginMode === WebLoginMode.Always) {
        response = this.checkIfUserIsAuthenticatedAndCustomerIsSelected(routeTarget);
      }
      else if (loginMode === WebLoginMode.WhenSpecified) {
        response = true;
      }
    }
    else if (guardType === GuardTypes.AllowAlways) {
      response = true;
    }
    // check there is "operation" module included in license
    if (response && route.data.path && route.data.path === ROUTE_PATHS.operations && !this.sessionHelperService.canViewOperation()) {
      this.router.navigate(['/error'], { queryParams: { missedModule: TD_MODULES.operation } });
    }
    return response;
  }

  checkIfUserIsAuthenticatedAndCustomerIsSelected(url: string): boolean {
    if (this.authService.isAuthenticated() && this.customerSelected) {
      return true;
    }
    else {
      this.authService.startSignin(url + '?cancel=' + ROUTE_PATHS.landing);
      return false;
    }
  }  

  handleProductionOverviewRequest(route: ActivatedRouteSnapshot): boolean {
    let response = false;
    if (this.sessionHelperService.canViewProductionOverview()) {      
      this.store.pipe(select(selectInfoOverviews)).subscribe(infoOverviews => {
        const match = infoOverviews.find(entry => entry.configId === Number(route.queryParams[POConst.TDOC_IO_ID]));
        if (match) {
          response = true;
        }
        else if (this.authService.isAuthenticated() && this.customerSelected) {
          this.displayErrorDialog('appMessages.productionOverviewNotAvailableToCustomer');
        }
        else {
          this.displayErrorDialog('appMessages.productionOverviewNotAvailableMayRequireLogin');
        }
      });
    }
    else {this.displayErrorDialog('appMessages.productionOverviewNotAvailableMayRequireLogin');
    }
    return response;
  }

  displayErrorDialog(translateKey: string): void {
    this.popupService.showError(translateKey, true);
    this.router.navigateByUrl('');
  }
}
