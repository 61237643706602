import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TdPopupModel, PopupType } from '../models/td-popup.model';
import { TranslateService } from '@ngx-translate/core';
import { TdLoadPanelService } from '../../td-load-panel/services/td-load-panel.service';
import { TdPopupButtonInterface } from '../models/td-popup-button.interface';

@Injectable({
  providedIn: 'root'
})
export class TdPopupService {

  public popupParams: TdPopupModel = new TdPopupModel();
  public displayPopup: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private translateService: TranslateService,
    private loadPanelService: TdLoadPanelService) { }

  closePopup() {
    this.popupParams.resolvePopup();
    this.displayPopup.next(false);    
  }

  confirmPopup() {
    this.popupParams.comfirm();
    this.closePopup();
  }

  cancelPopup() {
    this.popupParams.cancel();
    this.closePopup();
  }

  showInfo(message: string, translateMessage = false) {
    return this.showPopup('popup.info', message, null, PopupType.Information, translateMessage);
  }

  showCustomInfo(title: string, message: string, translateMessage = false) {
    return this.showPopup(title, message, null, PopupType.Information, translateMessage);
  }

  confirmDialog(title: string, message: string, translateMessage = false) {
    return this.showPopup(title, message, null, PopupType.Confirmation, translateMessage);
  }

  showError(message: string, translateMessage = false, referenceId = '') {
    return this.showPopup('appCommon.error', message, null, PopupType.Error, translateMessage, referenceId);
  }

  showTemplate(title: string, content: TemplateRef<any>, titleIconPath?: string) {
    return this.showPopup(title, '', content, PopupType.Template, null, null, titleIconPath);
  }

  /**
   * Function for generating a customized popup, with customizable title icon, icon type and buttons
   * @param title: string - The title of the popup. Is automatically translated
   * @param message: string - The message of the popup. Will be translated if "translateMessage" is set to true (default is false)
   * @param iconType: PopupType - Determines the icon that will be displayed above the popup message
   * @param buttons: TdPopupButtonInterface[] - An array of buttons that will be added to the popup
   * @param translateMessage: boolean - decides whether the message property should be translated (default is false)
   * @param titleIconPath: string - a path to an image used as an icon in the title bar (default is null) 
   * 
   * @returns popup as a Promise<boolean>
   */
  showCustomWithButtons(title: string, message: string, iconType: PopupType = PopupType.Information, buttons: TdPopupButtonInterface[], translateMessage = false, titleIconPath: string = null) {    
    return this.showPopup(title, message, null, PopupType.CustomWithButtons, translateMessage, null, titleIconPath, buttons, iconType);
  }

  private showPopup(
    title: string,
    message: string,
    template: TemplateRef<any>,
    type: PopupType = PopupType.Information,
    translateMessage = false,
    referenceId = '',    
    titleIconPath: string = null,
    buttons: TdPopupButtonInterface[] = null,
    iconType: PopupType = null
  ): Promise<boolean> {    
      this.loadPanelService.hideLoadPanel();
      title = this.translateService.instant(title);
      message = (translateMessage ? this.translateService.instant(message) : message).replace('|', '<br/>');
      if (referenceId) {
        message = message + '<br/>' + this.translateService.instant('appCommon.referenceId') + ': ' + referenceId;
      }
      this.popupParams = new TdPopupModel(true, title, message, template, type, titleIconPath, buttons, iconType);
      this.displayPopup.next(true);
      return this.popupParams.response;
  }
}
