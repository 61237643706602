import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Role } from '@core/authentication/models/authentication.enumerations';
import { AuthGuard } from '@core/guards/auth.guard';
import { SearchComponent } from './search/search.component';
import { SigninCallbackComponent } from './authentication/signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './authentication/signout-callback/signout-callback.component';
import { AuthCustomersComponent } from './authentication/auth-customers/auth-customers.component';
import { ErrorComponent } from './error/error.component';
import { LandingComponent } from './landing/landing.component';
import { RouteGuard } from '@core/guards/route.guard';
import { GuardTypes, ROUTE_PATHS } from '@shared/models/app.constants';
import { RedirectGuard } from './core/guards/changePassword.guard';
import { LoadingComponent } from './shared/components/td-loading/td-loading.component';
import { ChangePasswordSuccessComponent } from './shared/components/td-change-password-success/td-change-password-success.component';
import { DeepLinkComponent } from './shared/components/td-deep-link/td-deep-link.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivate: [RouteGuard],
    data: { guardType: GuardTypes.AllowAlways }
  },
  {
    path: 'products',
    loadChildren: './products/products.module#ProductsModule',
    canActivate: [RouteGuard],
    data: { guardType: GuardTypes.LoginModeAlways }
  },
  {
    path: 'units',
    loadChildren: './units/units.module#UnitsModule',
    canActivate: [RouteGuard],
    data: { guardType: GuardTypes.LoginModeAlways }
  },
  {
    path: 'items',
    loadChildren: './items/items.module#ItemsModule',
    canActivate: [RouteGuard],
    data: { roles: [Role.Admin], guardType: GuardTypes.LoginModeAlways }
  },
  {
    path: 'signin-callback',
    component: SigninCallbackComponent
  },
  {
    path: 'signout-callback',
    component: SignoutCallbackComponent
  },
  {
    path: 'auth-customers',
    component: AuthCustomersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [RouteGuard],
    data: { guardType: GuardTypes.LoginModeAlways }
  },
  {
    path: 'production-overview',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [RouteGuard],
    data: { path: ROUTE_PATHS.productionOverview }
  },
  {
    path: 'orders',
    loadChildren: './orders/orders.module#OrdersModule',
    canActivate: [RouteGuard],
    data: { 
      guardType: GuardTypes.AuthenticatedUserAndCustomerSelected,
      path: ROUTE_PATHS.orders
     }
  },
  {
    path: 'order-templates',
    loadChildren: './order-templates/order-templates.module#OrderTemplatesModule',
    canActivate: [RouteGuard],
    data: { 
      guardType: GuardTypes.AuthenticatedUserAndCustomerSelected,
      path: ROUTE_PATHS.orderTemplates
    }
  },
  {
    path: 'operations',
    loadChildren: () => import('./operations/operations.module').then(module => module.OperationsModule),
    canActivate: [RouteGuard],
    data: {
      guardType: GuardTypes.AuthenticatedUserAndCustomerSelected,
      path: ROUTE_PATHS.operations
    },
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '$/start',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'changePassword',
    canActivate: [RedirectGuard],
    // We need a component here because we cannot define the route otherwise
    component: LoadingComponent
  },
  {
    path: 'passwordChanged',
    component: ChangePasswordSuccessComponent
  },
  {
    path: 'jump',
    component: DeepLinkComponent
  }, 
  // otherwise redirect to search
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    appRoutes,
    {
      // this is required to allow to refetch data on same URL navigation.
      // 'reload' - it is worth noting reload does not actually do the work of reloading the route, it only re-triggers events on the router that we then need to hook into.
      onSameUrlNavigation: 'reload'
    }
    /*, { preloadingStrategy: PreloadAllModules }*/
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
