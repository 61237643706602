import { createSelector } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { UserSessionState } from './user-session.reducer';

export const selectUserSessionState = (state: AppState) => state.userSession;

export const selectProfileData = createSelector(
  selectUserSessionState,
  (state: UserSessionState) => state.profileData
);

export const selectUserOptions = createSelector(
  selectUserSessionState,
  (state: UserSessionState) => state.userOptions
);

export const selectDefaultUserOptions = createSelector(
  selectUserSessionState,
  (state: UserSessionState) => state.defaultUserOptions
);
