import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { BaseComponent } from '@app/shared/components/base/base.component';
import { TD_QUERY_PARAMS, TD_AUTH_ERRORS } from '@core/data-layer/shared/models/td.constants';
import { AuthenticationServiceInterface } from '../core/authentication/services/authentication.service.interface';
import { WebLoginMode } from '@core/data-layer/shared/models/td.enumerations';

@Component({
  selector: 'td-landing',
  templateUrl: 'landing.component.html',
  styleUrls: ['./landing.component.less']
})

export class LandingComponent extends BaseComponent implements OnInit, OnDestroy {

  queryParamsSubscription: Subscription;
  userLoggedIn = false;
  subscriptions: Subscription[] = [];
  loginModeAlways = false;

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public ngOnInit() {
    super.ngOnInit();
    this.loginModeAlways = this.sessionHelperService.clientConfiguration.loginMode === WebLoginMode.Always;
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(queryParams => {
      this.showErrorMessage();
    });
    this.subscriptions.push(this.sessionHelperService.$loggedIn.subscribe(change => {
      this.userLoggedIn = change;
    }));
  }

  ngOnDestroy() {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  getTitleIdentifier(): string {
    return 'appCommon.titleLanding';
  }

  login() {
    this.authService.startSignin();
  }

  showErrorMessage() {
    // check either error should be shown
    const error = this.activatedRoute.snapshot.queryParams[TD_QUERY_PARAMS.error];
    if (error) {
      switch (error) {
        case TD_AUTH_ERRORS.noCust:
          this.popupService.showError('appMessages.userNotLinkedToCustomer', true).then(() => {
            this.authService.startSignout();
          });
          break;
      }
    }
  }
}
