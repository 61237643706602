<dx-popup
  id="settingsPopup"
  class="popup td-popup"
  maxHeight="80%"
  maxWidth="35%"
  minHeight="200px"
  minWidth="608px"
  [title]="'settings.settings' | translate"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  (onHidden)="visibleChange.emit(false)"
  [(visible)]="visible">

  <div class="d-flex settings-tab-panel-height">
    <dx-tab-panel
      #tabPanel
      id="settingsTabPanel"
      width="100%"
      height="100%"
      [dataSource]="settingsTabs"
      [focusStateEnabled]="false">

      <td-tab-panel-title [tabs]="settingsTabs" [selectedIndex]="tabPanel.selectedIndex" [translateTitle]="true"></td-tab-panel-title>

      <div class="p-3" *dxTemplate="let tab of searchFiltersTemplate">
        <dx-scroll-view
          [scrollByContent]="true"
          [scrollByThumb]="true"
          showScrollbar="always">
          <td-search-filters #searchFilters [(filtersChanged)]="filtersChanged" [defaultFilters]="true"></td-search-filters>
        </dx-scroll-view>
      </div>

      <div class="p-3" *dxTemplate="let tab of serverSettingsTemplate">
        <dx-scroll-view
          [scrollByContent]="true"
          [scrollByThumb]="true"
          showScrollbar="always">
          <td-server-settings #serverSettings [(settingsChanged)]="settingsChanged"></td-server-settings>
        </dx-scroll-view>
      </div>


    </dx-tab-panel>
  </div>

  <div class="d-flex justify-content-end">
    <td-confirm-button-panel
      [resetButtonVisible]="true"
      [disableSaveButton]="!settingsChanged && !filtersChanged"
      [confirmButtonTranslateString]="'button.apply'"
      (confirmEvent)="apply()"
      (resetEvent)="resetToDefault(tabPanel.selectedItem.template)"
      (cancelEvent)="cancel()">
    </td-confirm-button-panel>
  </div>
</dx-popup>
