import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';

import { OperationsTextSearchFormDataInterface, OperationsDateSearchFormDataInterface } from '../models/operations-search-form-data.interface';
import { SearchArgsModel } from '@core/search/models/search-args.model';
import { OperationSearchType, OrderSuppStatus, OrdersLeadStatus, OperationStatus, WebOrderMode } from '@core/data-layer/shared/models/td.enumerations';
import { TD_MODEL_NAMES, TD_DESKTOP_IDENTIFIERS, TD_DYNAMIC_FIELDS, TD_ACTION_FIELDS, TD_QUERY_PARAMS, TD_MIN_DATE_VALUE } from '@core/data-layer/shared/models/td.constants';
import { GridStructureViewInterface, GridColumnConfigurationViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { GridOptionsInterface } from '@app/shared/components/td-data-grid/models/grid-options.interface';
import { OperationOrderStateImgPaths } from '@app/shared/models/img.enumerations';
import { PropertyTypes } from '@core/table-info/models/grid-structure.interface';
import { nameOf } from '@core/utility/utility.service';
import { OperationDataModel } from '@core/data-layer/operation-data/models/operation-data.model';
import { OperationsSharedService } from '../services/operations-shared.service';
import { OperationsSearchService } from '../services/operations-search.service';
import { OperationsOrdersInterface } from '../models/operations-orders.interface';
import { OperationSearchFactory } from '@shared/models/url-search-models/operation-search.factory';
import { UrlSearchService } from '@shared/services/url-search.service';
import { SearchParamsModel } from '@shared/models/url-search-models/param-models';

@Component({
  selector: 'td-operation-list',
  templateUrl: 'operation-list.component.html',
  styleUrls: ['operation-list.component.less']
})

export class OperationListComponent extends BaseComponent implements OnInit {

  // Grid
  operationsData: DataSource;
  gridStructure: GridStructureViewInterface;
  mainTable: string = TD_MODEL_NAMES.operationDataModel;
  gridIdentifier: string = TD_DESKTOP_IDENTIFIERS.scheduledOperationListGrid;
  operationsGridOptions: GridOptionsInterface;
  operationOrderStateImgPathEnum = OperationOrderStateImgPaths;
  dynamicFields = TD_DYNAMIC_FIELDS;
  ordersLeadStatus = OrdersLeadStatus;

  // Sub grid
  subGridIdentifier: string = TD_DESKTOP_IDENTIFIERS.scheduledOperationsOrderListGrid;
  subGridTable: string = TD_MODEL_NAMES.orderModel;
  subGridStructure: GridStructureViewInterface;
  operationOrdersData: OperationsOrdersInterface = {};
  operationOrdersSearchArgs: SearchArgsModel;
  suppStatus = OrderSuppStatus;

  // Search
  textFormData: OperationsTextSearchFormDataInterface = {
    text: ''
  }
  datesFormData: OperationsDateSearchFormDataInterface = {
    dateFrom: null,
    dateTo: null
  }
  operationsSearchArgs: SearchArgsModel;
  operationSearchType = OperationSearchType;
  lastSearchType: OperationSearchType = null;
  operationStatus = OperationStatus;
  subscriptions: Subscription[] = [];
  firstSearch = true;

  // Search Model
  searchModel = new OperationSearchFactory();
  operationSearchModel = this.searchModel.createSearchModel();

  TD_MIN_DATE_VALUE = TD_MIN_DATE_VALUE;

  constructor(
    private operationsSearchService: OperationsSearchService,
    private operationsSharedService: OperationsSharedService,
    private urlSearchService: UrlSearchService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
   }

  async ngOnInit() {
    super.ngOnInit();
    await this.getGridColumnsStructure();
    await this.getSubGridColumnsStructure();
    const queryParamSubscription = this.activatedRoute.queryParams.subscribe(queryParams => {
      const searchParamsModel = this.urlSearchService.onQueryParamChanges(queryParams, this.operationSearchModel, this.searchModel, this.firstSearch);
      if (searchParamsModel) {
        const searchType = this.determineSearchType(searchParamsModel);
        this.setFormData(searchParamsModel, searchType);
        this.searchOperations(searchType);
        this.firstSearch = false;
      }

    });
    this.subscriptions.push(queryParamSubscription);
   }

   ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getTitleIdentifier(): string {
    const title = this.translateInstant('appCommon.scheduledOperations');
    return title;
  }

  /**
   * @param keyId keyId of the opreation that we want to fetch orders by
   * Extra info: We're using pipe(take(2)), because we want the value that happens when the returned value is initialized, as well as the value that is returned when we get a response from fuzzysearch
   */
  getOrders(keyId: number) {
    this.operationsSearchService.getOperationOrders(keyId, this.subGridStructure, this.operationsData).pipe(take(2)).subscribe(response => {
      this.operationOrdersData[keyId] = response;
    });
  }
  highlightSearchText(value: string): string {
    return this.utilityService.highlightContent(value, this.textFormData.text);
  }

  addOrder(operationKeyId: number, stokKeyId?: number, reqDelTime?: Date) {
    this.operationsSharedService.addOrderToOperation(operationKeyId, stokKeyId, reqDelTime);
  }

  setSearchParameters(searchType: OperationSearchType): void {
    let allowSearch = true;
    if (searchType === OperationSearchType.Date) {
      this.operationSearchModel.properties.find(entry => entry.urlParamName === TD_QUERY_PARAMS.dateFrom).value = this.datesFormData.dateFrom;
      this.operationSearchModel.properties.find(entry => entry.urlParamName === TD_QUERY_PARAMS.dateTo).value = this.datesFormData.dateTo;
      this.operationSearchModel.properties.find(entry => entry.urlParamName === TD_QUERY_PARAMS.text).value = null;
    }
    else if (searchType === OperationSearchType.OperationID) {
      if (this.textFormData.text) {
        this.operationSearchModel.properties.find(entry => entry.urlParamName === TD_QUERY_PARAMS.text).value = this.textFormData.text;
      }
      else {
        allowSearch = false;
        this.popupService.showInfo('appMessages.searchTextEmptyError', true);
      }
    }
    if (allowSearch) {
      this.resetFormsData(searchType);
      this.urlSearchService.setSearchParamsAndNavigate(this.operationSearchModel, this.firstSearch);
    }
  }

  translateTodayButton(event: any) {
    event.component._popup.option('toolbarItems[0]', {
      options: {
        text: this.translateInstant('button.today')
      }
    });
  }

  private getGridColumnsStructure(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(this.gridIdentifier, this.mainTable).subscribe(gridStructure => {
        this.gridStructure = gridStructure;
        this.adjustCustomColumns();
        resolve(true);
      });
    });
  }

  private adjustCustomColumns() {
    // align "OrderLeadStatus" column on center
    this.gridColumnHelperService.setColumnProperty(
      this.gridStructure.columnsConfigurations,
      this.dynamicFields.ordersLeadStatus,
      {
        style: { alignment: 'center' },
        fixed: true,
        fixedPosition: 'left'
      }
    );
    const nameOfOperationData = nameOf<OperationDataModel>();
    // hide "BookingStatus" column
    if (!this.sessionHelperService.license.planAssure) {
      this.gridColumnHelperService.setColumnProperty(
        this.gridStructure.columnsConfigurations,
        nameOfOperationData('bookingStatus'),
        { visible: false }
      );
    }
    if (this.sessionHelperService.serverOptions.orderMode === WebOrderMode.On) {
      const addOrderColumn: GridColumnConfigurationViewInterface = {
        dataField: TD_ACTION_FIELDS.addOrder,
        dataType: PropertyTypes.typeUndefined,
        visible: true,
        width: '100',
        displayName: this.translateInstant('calcColumns.createOrder'),
        style: {
          alignment: 'center'
        },
        fixed: true,
        fixedPosition: 'right'
      }
      this.gridColumnHelperService.addCustomColumn(this.gridStructure.columnsConfigurations, addOrderColumn);
    }
    else {
      this.gridColumnHelperService.removeColumn(this.gridStructure.columnsConfigurations, TD_ACTION_FIELDS.addOrder);
    }
  }

  private getSubGridColumnsStructure(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(this.subGridIdentifier, this.subGridTable).subscribe(gridStructure => {
        this.subGridStructure = gridStructure;
        const orderStatusColumn: GridColumnConfigurationViewInterface = {
          dataField: TD_ACTION_FIELDS.orderStatus,
          dataType: PropertyTypes.typeUndefined,
          visible: true,
          visibleIndex: 1,
          width: '30',
          displayName: '',
          style: { alignment: 'center' },
          fixed: true,
          fixedPosition: 'left'
        };
        this.gridColumnHelperService.addCustomColumn(this.subGridStructure.columnsConfigurations, orderStatusColumn);
        resolve(true);
      });
    })
  }

  private searchOperations(searchType?: OperationSearchType) {
    const searchArgText = searchType == OperationSearchType.Date ? '' : this.textFormData.text;
    if (!this.utilityService.dateRangeAllowed(this.datesFormData.dateFrom)) {
      this.datesFormData.dateFrom = null;
    }
    if (!this.utilityService.dateRangeAllowed(this.datesFormData.dateTo)) {
      this.datesFormData.dateTo = null;
    }
    this.operationsData = this.operationsSearchService.createSearchOperationsDataSource(
      this.gridStructure, this.datesFormData.dateFrom, this.datesFormData.dateTo, searchArgText, searchType
    );
  }

  private resetFormsData(searchType: OperationSearchType): void {
    if (searchType === OperationSearchType.Date && this.textFormData) {
      this.textFormData.text = null;
    }
    else if (searchType === OperationSearchType.OperationID && this.datesFormData) {
      this.datesFormData.dateFrom = null;
      this.datesFormData.dateTo = null;
    }
  }

  private setFormData(searchModel: SearchParamsModel, searchType: OperationSearchType): void {
    if (searchType === OperationSearchType.Date) {
      this.datesFormData[TD_QUERY_PARAMS.dateFrom] = searchModel.properties.find(prop => prop.urlParamName === TD_QUERY_PARAMS.dateFrom).value;
      this.datesFormData[TD_QUERY_PARAMS.dateTo]   = searchModel.properties.find(prop => prop.urlParamName === TD_QUERY_PARAMS.dateTo).value;
      this.textFormData[TD_QUERY_PARAMS.text]      = null;
    }
    else if (searchType === OperationSearchType.OperationID) {
      this.textFormData[TD_QUERY_PARAMS.text]      = searchModel.properties.find(prop => prop.urlParamName === TD_QUERY_PARAMS.text).value;
      this.datesFormData[TD_QUERY_PARAMS.dateFrom] = null;
      this.datesFormData[TD_QUERY_PARAMS.dateTo]   = null;
    }
  }

  private determineSearchType(model: SearchParamsModel): OperationSearchType {
    const propertyWithValue = model.properties.find(prop => prop.value);
    return propertyWithValue && propertyWithValue.urlParamName === TD_QUERY_PARAMS.text ? OperationSearchType.OperationID : OperationSearchType.Date;
  }
}
