"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@ngx-translate/core");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var OrderTemplateHelperService = /** @class */ (function () {
    function OrderTemplateHelperService(translateService) {
        this.translateService = translateService;
    }
    /**
     * @description converts [OrderTemplateModel][Object] to [OrderTemplateViewModel][Object]
     * @param orderTemplate: [OrderTemplateModel][Object]
     * @returns [OrderTemplateViewModel][Object]
     */
    OrderTemplateHelperService.prototype.convertOrderTemplateToViewModel = function (orderTemplate) {
        return tslib_1.__assign({}, orderTemplate, { fromCaption: this.getFromCaption(orderTemplate), fromText: this.getFromText(orderTemplate), toCaption: this.getToCaption(orderTemplate), toText: this.getToText(orderTemplate) });
    };
    OrderTemplateHelperService.prototype.getFromCaption = function (orderTemplate) {
        var caption = this.translateService.instant('templateDetails.from');
        if (orderTemplate.orgRefCustKeyId) {
            return caption + " " + this.translateService.instant('appCommon.customer').toLowerCase() + ":";
        }
        else if (orderTemplate.orgRefFacKeyId) {
            return caption + " " + this.translateService.instant('appCommon.factory').toLowerCase() + ":";
        }
        else if (orderTemplate.orgRefSuppKeyId) {
            return caption + " " + this.translateService.instant('appCommon.supplier').toLowerCase() + ":";
        }
        else {
            return caption + ":";
        }
    };
    OrderTemplateHelperService.prototype.getFromText = function (orderTemplate) {
        if (orderTemplate.orgRefCustKeyId) {
            return orderTemplate.orgRefCust.customer + " - " + orderTemplate.orgRefCust.name;
        }
        else if (orderTemplate.orgRefFacKeyId) {
            return orderTemplate.orgRefFac.factory + " - " + orderTemplate.orgRefFac.name;
        }
        else if (orderTemplate.orgRefSuppKeyId) {
            return orderTemplate.orgRefSupp.supplier + " - " + orderTemplate.orgRefSupp.name;
        }
        else {
            return '';
        }
    };
    OrderTemplateHelperService.prototype.getToCaption = function (orderTemplate) {
        var caption = this.translateService.instant('templateDetails.to');
        if (orderTemplate.suppRefSuppKeyId) {
            return caption + " " + this.translateService.instant('appCommon.supplier').toLowerCase() + ":";
        }
        else if (orderTemplate.suppRefFacKeyId) {
            return caption + " " + this.translateService.instant('appCommon.factory').toLowerCase() + ":";
        }
        else {
            return caption + ":";
        }
    };
    OrderTemplateHelperService.prototype.getToText = function (orderTemplate) {
        if (orderTemplate.orgRefSuppKeyId) {
            return orderTemplate.suppRefSupp.supplier + " - " + orderTemplate.suppRefSupp.name;
        }
        else if (orderTemplate.suppRefFacKeyId) {
            return orderTemplate.suppRefFac.factory + " - " + orderTemplate.suppRefFac.name;
        }
        else {
            return '';
        }
    };
    OrderTemplateHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderTemplateHelperService_Factory() { return new OrderTemplateHelperService(i0.ɵɵinject(i1.TranslateService)); }, token: OrderTemplateHelperService, providedIn: "root" });
    return OrderTemplateHelperService;
}());
exports.OrderTemplateHelperService = OrderTemplateHelperService;
