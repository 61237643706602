import { Component, OnInit, Input, Output, ViewChild, EventEmitter, HostListener } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { DxTreeListComponent } from 'devextreme-angular/ui/tree-list';

import { ChooserColumnDataInterface } from './models/chooser-column-data.interface';
import { GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { TD_MAX_LEVEL_OF_NESTED_TABLES } from '@core/data-layer/shared/models/td.constants';
import { GridColumnBaseInterface } from '@core/table-info/models/grid-structure.interface';
import { GridColumnHelperService } from '@shared/services/grid-column-helper.service';

@Component({
  selector: 'td-grid-column-chooser',
  templateUrl: './td-grid-column-chooser.component.html',
  styleUrls: ['./td-grid-column-chooser.component.less']
})

export class TdGridColumnChooserComponent implements OnInit {

  @Input() displayColumnChooser: boolean;
  @Input() mainTable: string;
  @Input() gridStructure: GridStructureViewInterface;
  @Output() refreshGridData: EventEmitter<null> = new EventEmitter();
  @Output() dialogClosed: EventEmitter<null> = new EventEmitter();

  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('columnPickerTreeList', { static: false }) columnPickerTreeList: DxTreeListComponent;
  selectedRowKeys: number[];
  chooserColumnData: ChooserColumnDataInterface[];
  popupVisible: boolean;

  private id: number;

  constructor(
    private gridColumnHelperService: GridColumnHelperService
  ) { }

  ngOnInit() {
    this.extractChooserTableData();
  }

  calcHeightTreeList() {
    const popupHeaderAndFooterHeight = 166;
    return .8 * window.innerHeight - popupHeaderAndFooterHeight;
  }

  onClose() {
    this.dialogClosed.emit();
  }

  public onEditorPrepared(event: any): void {
    if (event.parentType === 'headerRow') {
      event.cancel = true;
      event.editorElement.style.display = 'none';
    }
  }

  // Override default value changed functionality for reference nodes with children
  // To prevent default recursive select of all child nested nodes
  public onEditorPreparing(event: any): void {
    if (event.row && event.row.node.hasChildren) {
      event.editorOptions.onValueChanged = (e: any) => {
        // If value changed to true - select only childless rows of current node
        if (e.value && (e.previousValue === false) && e.event) {
          e.event.stopImmediatePropagation();
          event.component.selectRows(event.row.node.children.filter(child => !child.hasChildren).map(child => child.key), true);
        }
        // If value changed from undefined - deselect all child nodes recursively
        else if ((e.previousValue === undefined) && e.event) {
          e.event.stopImmediatePropagation();
          event.component.deselectRows(event.row.node.children.map(child => child.key), true);
          e.component.option('value', false)
        }
      };
    }
  }

  public confirmSelection(): void {
    // clear columns configurations "visible" flags
    this.gridStructure.columnsConfigurations.forEach(columnConfig => {
      if (this.gridColumnHelperService.isSelectedColumn(columnConfig) && !columnConfig.calculated) {
        columnConfig.visible = false;
      }
    });
    // set columns configurations "visible" flag based on column chooser
    const uniqueRows = this.columnPickerTreeList.instance.getSelectedRowKeys('leavesOnly');
    uniqueRows.forEach(entry => {
      const node = this.columnPickerTreeList.instance.getNodeByKey(entry);
      const chooserColumn: GridColumnBaseInterface = node.data as GridColumnBaseInterface;
      // check do we have already this selected column in ColumnsConfigurations list
      let columnConfig = this.gridColumnHelperService.getColumnConfigurationByName(this.gridStructure.columnsConfigurations, chooserColumn.dataField);
      // in case we have already this selected column in ColumnsConfigurations list - then just set "visible" flag
      if (columnConfig) {
        columnConfig.visible = true;
      }
      else {
        // prepare display name
        let displayName = chooserColumn.displayName;
        let parent = node.parent;
        while (parent && parent.data) {
          const parentChooserColumn: GridColumnBaseInterface = parent.data as GridColumnBaseInterface;
          displayName = parentChooserColumn.displayName + '.' + displayName;
          parent = parent.parent;
        }
        // create new ColumnConfig and add in ColumnsConfigurations list
        columnConfig = {
          dataField: chooserColumn.dataField,
          dataType: chooserColumn.dataType,
          displayName: displayName,
          calculated: chooserColumn.calculated,
          userField: chooserColumn.userField,
          enumName: chooserColumn.enumName,
          visible: true
        };
        this.gridColumnHelperService.addCustomColumn(this.gridStructure.columnsConfigurations, columnConfig);
      }
    });
    // call refresh grid event
    this.refreshGridData.emit();
    // hide column chooser
    this.popup.instance.hide();
  }

  private prepareColumnsTreeListData(tableName: string, dataFieldPrefix: string, chooserData: ChooserColumnDataInterface[], selectedKeys: number[], parentId: number, levelOfTable: number) {
    if (levelOfTable <= TD_MAX_LEVEL_OF_NESTED_TABLES) {
      const table = this.gridStructure.tables.find(gridTable => gridTable.tableName === tableName);
      if (table) {
        table.columns.forEach(column => {
          // skip user-field columns that haven't been defined by display-name and reference colums on the last nested table
          if ((!column.userField || column.displayName) && !(column.referenceTo && (levelOfTable === TD_MAX_LEVEL_OF_NESTED_TABLES))) {
            this.id++;
            const chooserColumn: ChooserColumnDataInterface = {
              // properties of base interface
              dataField: dataFieldPrefix + column.dataField,
              dataType: column.dataType,
              displayName: column.displayName,
              calculated: column.calculated,
              userField: column.userField,
              enumName: column.enumName,
              // own properties
              id: this.id,
              parentId
            };
            chooserData.push(chooserColumn);
            // remember selected row key
            const columnConfig = this.gridColumnHelperService.getColumnConfigurationByName(this.gridStructure.columnsConfigurations, dataFieldPrefix + column.dataField);
            if (columnConfig && columnConfig.visible) {
              selectedKeys.push(this.id);
            }
            // check whether existing sub table
            if (column.referenceTo) {
              this.prepareColumnsTreeListData(column.referenceTo, dataFieldPrefix + column.dataField + '.', chooserData, selectedKeys, chooserColumn.id, levelOfTable + 1);
            }
          }
        });
      }
    }
  }

  private extractChooserTableData() {
    const levelOfTable = 1;
    this.id = 0;
    const selectedKeys: number[] = [];
    const chooserData: ChooserColumnDataInterface[] = [];
    this.prepareColumnsTreeListData(this.mainTable, '', chooserData, selectedKeys, this.id, levelOfTable);
    this.chooserColumnData = chooserData;
    this.selectedRowKeys = selectedKeys;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.columnPickerTreeList) {
      this.columnPickerTreeList.height = this.calcHeightTreeList();
    }
  }
}
