import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(@Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (this.authService.isAuthenticated()) {
      request = request.clone({
        setHeaders: {
          Authorization: this.authService.getAuthorizationHeaderValue()
        }
      });
    }
    return next.handle(request);
  }

}
