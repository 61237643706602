import { Component, Inject } from '@angular/core';

import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';

@Component({
  selector: 'td-signout-callback',
  templateUrl: './signout-callback.component.html',
  styleUrls: ['./signout-callback.component.less']
})
export class SignoutCallbackComponent {

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface
  ) {
    this.authService.completeSignout();
  }
}
