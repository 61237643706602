"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./signin-callback.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./signin-callback.component");
var styles_SigninCallbackComponent = [i0.styles];
var RenderType_SigninCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SigninCallbackComponent, data: {} });
exports.RenderType_SigninCallbackComponent = RenderType_SigninCallbackComponent;
function View_SigninCallbackComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
exports.View_SigninCallbackComponent_0 = View_SigninCallbackComponent_0;
function View_SigninCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-signin-callback", [], null, null, null, View_SigninCallbackComponent_0, RenderType_SigninCallbackComponent)), i1.ɵdid(1, 49152, null, 0, i2.SigninCallbackComponent, ["AUTH_SERVICE"], null, null)], null, null); }
exports.View_SigninCallbackComponent_Host_0 = View_SigninCallbackComponent_Host_0;
var SigninCallbackComponentNgFactory = i1.ɵccf("td-signin-callback", i2.SigninCallbackComponent, View_SigninCallbackComponent_Host_0, {}, {}, []);
exports.SigninCallbackComponentNgFactory = SigninCallbackComponentNgFactory;
