"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".remove-unit-grid[_ngcontent-%COMP%]{min-width:512px}.remove-unit-container[_ngcontent-%COMP%]{min-height:270px}.popup-headline[_ngcontent-%COMP%]{font-size:var(--font-medium)}"];
exports.styles = styles;
