"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var oidc_client_1 = require("oidc-client");
var auth_configuration_service_1 = require("./auth-configuration.service");
var custActions = require("@core/data-layer/customer/store/customer.actions");
var factActions = require("@core/data-layer/factory/store/factory.actions");
var ordActions = require("@core/data-layer/order/store/order.actions");
var userSessionActions = require("@core/data-layer/user-session/store/user-session.actions");
var customer_selectors_1 = require("@core/data-layer/customer/store/customer.selectors");
var server_session_selectors_1 = require("@core/data-layer/server-session/store/server-session.selectors");
var info_overview_actions_1 = require("@app/dashboard/data-layer/info-overview/store/info-overview.actions");
var server_session_actions_1 = require("@core/data-layer/server-session/store/server-session.actions");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var error_report_manager_service_1 = require("@core/error-report/services/error-report-manager.service");
var td_popup_service_1 = require("@shared/components/td-popup/services/td-popup.service");
var td_popup_button_interface_1 = require("@shared/components/td-popup/models/td-popup-button.interface");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var td_popup_model_1 = require("@shared/components/td-popup/models/td-popup.model");
var HttpAuthenticationService = /** @class */ (function () {
    function HttpAuthenticationService(router, activatedRoute, authConfig, store, errorReportManagerService, popupService, injector, http, translate) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.authConfig = authConfig;
        this.store = store;
        this.errorReportManagerService = errorReportManagerService;
        this.popupService = popupService;
        this.injector = injector;
        this.http = http;
        this.translate = translate;
        this.authStatusSource = new rxjs_1.BehaviorSubject(false);
        this.authPopup = true;
    }
    HttpAuthenticationService.prototype.getUser = function () {
        var _this = this;
        this.manager.getUser().then(function (user) {
            _this.user = user;
            if (_this.user) {
                _this.store.pipe(store_1.select(customer_selectors_1.selectCurrentCustomer)).subscribe(function (cust) {
                    if (cust) {
                        _this.authStatusSource.next(_this.isAuthenticated());
                    }
                    else {
                        _this.navigateToAuthCustomers();
                    }
                });
            }
            else {
                _this.clearSigninInfo();
            }
        });
    };
    HttpAuthenticationService.prototype.initUserManager = function (authPopup) {
        var _this = this;
        if (authPopup === void 0) { authPopup = true; }
        this.authPopup = authPopup;
        this.manager = new oidc_client_1.UserManager(this.authConfig.getUserManagerSettings());
        // we are subscribing for silent refresh of token and as a result - updating current user info
        this.manager.events.addUserLoaded(function (args) {
            _this.getUser();
        });
        // in case user press F5 in browser, we should reload user info
        this.getUser();
    };
    HttpAuthenticationService.prototype.startSignin = function (redirectUrl) {
        var _this = this;
        redirectUrl = redirectUrl ? redirectUrl : this.router.url;
        this.store.dispatch(new server_session_actions_1.SetReturnUrl(redirectUrl));
        if (this.authPopup) {
            this.manager.signinPopup()
                .then(function (user) {
                _this.user = user;
                _this.navigateToAuthCustomers();
            })
                .catch(function (reason) {
                _this.handleSignInError(reason);
                _this.navigateToReturnUrl();
            });
        }
        else {
            this.manager.signinRedirect()
                .catch(function (reason) {
                _this.handleSignInError(reason);
                _this.navigateToReturnUrl();
            });
        }
    };
    HttpAuthenticationService.prototype.handleSignInError = function (error) {
        // We can't intercept third party library http request, so we only can react on the error message returned from the UserManager
        var corsError = 'Network Error';
        var internalServerError = '500';
        if (error && (error.message.includes(corsError) || error.message.includes(internalServerError))) {
            this.errorReportManagerService.handleError(error, '', this.router.url, error.name, error.message);
            this.popupService.showError('appMessages.technicalErrorContactSupport', true);
        }
    };
    HttpAuthenticationService.prototype.completeSignin = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        error = this.activatedRoute.snapshot.queryParams[td_constants_1.TD_QUERY_PARAMS.error];
                        if (!error) return [3 /*break*/, 1];
                        if ((error === td_constants_1.TD_AUTH_ERRORS.accessDenied) && (this.activatedRoute.snapshot.queryParams[td_constants_1.TD_QUERY_PARAMS.state])) {
                            if (this.authPopup) {
                                window.close();
                            }
                            else {
                                this.store.pipe(store_1.select(server_session_selectors_1.selectReturnUrl)).subscribe(function (url) {
                                    if (url.includes('?cancel=')) {
                                        var cancelledLoginUrl = url.split('?cancel=')[1];
                                        _this.store.dispatch(new server_session_actions_1.SetReturnUrl(cancelledLoginUrl));
                                    }
                                    _this.navigateToReturnUrl();
                                });
                            }
                        }
                        return [3 /*break*/, 5];
                    case 1:
                        if (!this.authPopup) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.manager.signinPopupCallback()];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        _a = this;
                        return [4 /*yield*/, this.manager.signinRedirectCallback()];
                    case 4:
                        _a.user = _b.sent();
                        this.handlePasswordExpires();
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    HttpAuthenticationService.prototype.startSignout = function (redirectToLogin) {
        var _this = this;
        if (redirectToLogin === void 0) { redirectToLogin = false; }
        this.signingOut = true;
        if (this.authPopup) {
            this.manager.signoutPopup()
                .then(function () {
                _this.finalizeSignOut(false);
            })
                .catch(function () {
                _this.finalizeSignOut();
            });
        }
        else {
            if (redirectToLogin) {
                this.manager.createSigninRequest()
                    .then(function (signinRequest) {
                    var umSettings = _this.authConfig.getUserManagerSettings();
                    _this.manager.signoutRedirect({
                        extraQueryParams: {
                            returnUrl: signinRequest.url.replace(umSettings.authority, '')
                        },
                    });
                })
                    .catch(function () {
                    _this.finalizeSignOut();
                });
            }
            else {
                this.manager.signoutRedirect()
                    .catch(function () {
                    _this.finalizeSignOut();
                });
            }
        }
    };
    HttpAuthenticationService.prototype.completeSignout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.authPopup) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.manager.signoutPopupCallback()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.manager.signoutRedirectCallback()];
                    case 3:
                        _a.sent();
                        this.finalizeSignOut();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HttpAuthenticationService.prototype.finalizeSignOut = function (cleanUpOidc) {
        if (cleanUpOidc === void 0) { cleanUpOidc = true; }
        if (cleanUpOidc) {
            this.cleanUpOidcTokens();
        }
        this.clearSigninInfo();
        this.authStatusSource.next(this.isAuthenticated());
        this.signingOut = false;
        this.store.dispatch(new server_session_actions_1.SetSessionEnded(true));
        this.store.dispatch(new server_session_actions_1.SetLicense(null));
        this.router.navigate(['/error'], { queryParams: { type: td_constants_1.TD_ERROR_TYPES.sessionEnded } });
    };
    HttpAuthenticationService.prototype.clearSigninInfo = function () {
        this.user = null;
        this.store.dispatch(new custActions.SetCurrentCustomer(null));
        this.store.dispatch(new custActions.SetUserCustomers(null));
        this.store.dispatch(new factActions.SetCustomerFactoriesFromAll());
        this.store.dispatch(new userSessionActions.SetProfileData(null));
        this.store.dispatch(new userSessionActions.SetUserOptions(null));
        this.store.dispatch(new ordActions.SetCustomerOpenOrders(null));
        this.store.dispatch(new ordActions.SetUserLastOpenOrder(null));
        this.store.dispatch(new info_overview_actions_1.LoadIOConfigurations());
    };
    HttpAuthenticationService.prototype.cleanUpOidcTokens = function () {
        var _this = this;
        // clear OIDC LocalStorage rubbish
        this.manager.settings.stateStore.getAllKeys().then(function (keys) {
            keys.forEach(function (key) {
                _this.manager.settings.stateStore.remove(key);
            });
        });
        // clear OIDC SessionStorage rubbish
        this.manager.settings.userStore.getAllKeys().then(function (keys) {
            keys.forEach(function (key) {
                _this.manager.settings.userStore.remove(key);
            });
        });
        //this.manager.clearStaleState();
    };
    HttpAuthenticationService.prototype.navigateToReturnUrl = function () {
        var _this = this;
        this.cleanUpOidcTokens();
        this.store.pipe(store_1.select(server_session_selectors_1.selectReturnUrl)).subscribe(function (url) {
            _this.navigateTo(url);
        });
    };
    HttpAuthenticationService.prototype.navigateToAuthCustomers = function () {
        var _this = this;
        this.store.pipe(store_1.select(server_session_selectors_1.selectReturnUrl)).subscribe(function (url) {
            _this.navigateTo('/auth-customers', url);
        });
    };
    HttpAuthenticationService.prototype.navigateTo = function (url, returnUrl) {
        if (returnUrl === void 0) { returnUrl = ''; }
        this.authStatusSource.next(this.isAuthenticated());
        if (returnUrl) {
            this.router.navigate([url], { queryParams: { returnUrl: returnUrl } });
        }
        else {
            this.router.navigateByUrl(url);
        }
    };
    HttpAuthenticationService.prototype.isAuthenticated = function () {
        return this.user != null && !this.user.expired;
    };
    HttpAuthenticationService.prototype.authStatus$ = function () {
        return this.authStatusSource.asObservable();
    };
    HttpAuthenticationService.prototype.getSigningOut = function () {
        return this.signingOut;
    };
    HttpAuthenticationService.prototype.getClaims = function () {
        return !!this.user ? this.user.profile : null;
    };
    HttpAuthenticationService.prototype.getAuthorizationHeaderValue = function () {
        return this.user.token_type + " " + this.user.access_token;
    };
    HttpAuthenticationService.prototype.getUserName = function () {
        return !!this.user && !!this.user.profile ? this.user.profile.name : '';
    };
    HttpAuthenticationService.prototype.getUserInitials = function () {
        return !!this.user && !!this.user.profile ? this.user.profile.initials : '';
    };
    HttpAuthenticationService.prototype.getUserId = function () {
        return !!this.user && !!this.user.profile ? this.user.profile.user_id : '';
    };
    HttpAuthenticationService.prototype.navigateToChangePassword = function () {
        this.router.navigate(['/changePassword'], { queryParams: { returnUrl: this.router.url } });
    };
    HttpAuthenticationService.prototype.handlePasswordExpires = function () {
        var _this = this;
        var sessionHelperService = this.injector.get(session_helper_service_1.SessionHelperService);
        if ((sessionHelperService.getPassVerifMethod() === td_enumerations_1.PasswordVerificationMethod.TDOC) &&
            (this.getUserInitials() !== td_constants_1.TD_SYSTEM_USER_INITIALS)) {
            this.http.get("" + api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.passwordExpire).subscribe(function (response) {
                if (response !== null) {
                    _this.popupService.showCustomWithButtons(_this.translate.instant('appMessages.passwordExpireWarning'), _this.translate.instant('appMessages.passwordExpireInDays').replace('%d', response), td_popup_model_1.PopupType.Information, [
                        {
                            text: 'button.askMeLater',
                            type: td_popup_button_interface_1.TdPopupButtonType.Secondary
                        },
                        {
                            text: 'settings.changePassword',
                            type: td_popup_button_interface_1.TdPopupButtonType.Success,
                            callback: function () {
                                _this.router.navigate(['/changePassword'], { queryParams: { returnUrl: _this.router.url } });
                            }
                        }
                    ]).then(function () {
                        _this.navigateToAuthCustomers();
                    });
                }
                else {
                    _this.navigateToAuthCustomers();
                }
            });
        }
    };
    return HttpAuthenticationService;
}());
exports.HttpAuthenticationService = HttpAuthenticationService;
