import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { LicenseModel } from '@core/license/models/license.model';
import { LoadLicense } from '@core/data-layer/server-session/store/server-session.actions';
import { AppState } from '@core/data-layer/app.state';
import { selectLicense } from '@core/data-layer/server-session/store/server-session.selectors';

@Injectable({
  providedIn: 'root'
})
export class LicenseInitializer {

  licenseSubscription: Subscription;

  constructor(
    private store: Store<AppState>
  ) { }

  initializeLicense(): Promise<LicenseModel> {
    return new Promise<LicenseModel>(resolve => {
      this.store.dispatch(new LoadLicense());
      this.licenseSubscription = this.store.pipe(select(selectLicense)).subscribe(license => {
        if (license) {
          if (this.licenseSubscription) {
            this.licenseSubscription.unsubscribe();
          }
          resolve(license);
        }
      });
    });
  }

}
