"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#column-chooser .dx-selection.dx-row:not(.dx-row-focused)>td{background-color:transparent;border-bottom:1px solid var(--color-primary-2)}  .dx-treelist-cell-expandable{display:flex}  .column-chooser-popup .dx-treelist-content .dx-row-lines:nth-child(even){background-color:var(--color-gray-5)}  .dx-treelist-rowsview{border:var(--border-2)}#columnPickerTreeList[_ngcontent-%COMP%]     .dx-widget{line-height:.35715}  .dx-treelist-headers{background-color:var(--color-primary-2);color:var(--color-white)}"];
exports.styles = styles;
