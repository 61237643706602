"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var base_component_1 = require("@app/shared/components/base/base.component");
var grid_structure_interface_1 = require("@core/table-info/models/grid-structure.interface");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var search_manager_service_1 = require("@core/search/services/search-manager.service");
var unit_filter_service_1 = require("@core/search/filters/unit-filter.service");
var RemoveUnitComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RemoveUnitComponent, _super);
    function RemoveUnitComponent(operationService, searchManagerService, unitFilterService) {
        var _this = _super.call(this) || this;
        _this.operationService = operationService;
        _this.searchManagerService = searchManagerService;
        _this.unitFilterService = unitFilterService;
        _this.popupResolved = new core_1.EventEmitter();
        _this.TD_DESKTOP_IDENTIFIERS = td_constants_1.TD_DESKTOP_IDENTIFIERS;
        _this.TD_MODEL_NAMES = td_constants_1.TD_MODEL_NAMES;
        _this.TD_DYNAMIC_FIELDS = td_constants_1.TD_DYNAMIC_FIELDS;
        return _this;
    }
    RemoveUnitComponent.prototype.ngOnInit = function () {
        var _this = this;
        var unitListAsNumbers = this.articleUpForRemoval[td_constants_1.TD_DYNAMIC_FIELDS.unitList].map(function (value) { return parseInt(value, 10); });
        this.getOperationRemoveUnitGridStructure().then(function (gridStructureLoaded) {
            if (gridStructureLoaded) {
                var searchArgs = _this.getOperationRemoveUnitSearchArgs(_this.removeUnitGridStructure, unitListAsNumbers);
                _this.searchManagerService.requestSearch(searchArgs, '').subscribe(function (response) {
                    _this.unitsToRemove = response.value.dataSet.mappedRows;
                    _this.unitsToRemove.forEach(function (unit) { return unit[td_constants_1.TD_DYNAMIC_FIELDS.select] = false; });
                    _this.popupService.showTemplate('operationDetails.removeUnit', _this.removeUnitPopupTemplate).then(function () {
                        _this.popupResolved.emit(null);
                    });
                });
            }
        });
    };
    RemoveUnitComponent.prototype.getOperationRemoveUnitGridStructure = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.tableInfoService.loadGridStructure(td_constants_1.TD_DESKTOP_IDENTIFIERS.operationRemoveUnitListGrid, td_constants_1.TD_MODEL_NAMES.unitModel).subscribe(function (gridStructure) {
                var selectUnitColumn = {
                    dataField: td_constants_1.TD_ACTION_FIELDS.select,
                    dataType: grid_structure_interface_1.PropertyTypes.typeUndefined,
                    visible: true,
                    visibleIndex: 0,
                    width: '30',
                    displayName: '',
                    style: {
                        alignment: 'center'
                    }
                };
                _this.gridColumnHelperService.addCustomColumn(gridStructure.columnsConfigurations, selectUnitColumn);
                _this.removeUnitGridStructure = gridStructure;
                resolve(true);
            });
        });
    };
    RemoveUnitComponent.prototype.getOperationRemoveUnitSearchArgs = function (gridStructure, unitList) {
        return this.searchManagerService.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.None, td_constants_1.TD_MODEL_NAMES.unitModel, gridStructure, this.unitFilterService.getOperationUnitListFilter(unitList), true);
    };
    RemoveUnitComponent.prototype.confirmRemoveUnit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var removeUnitResult, unitIdsToRemove, _loop_1, this_1, _i, unitIdsToRemove_1, entry;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        removeUnitResult = true;
                        unitIdsToRemove = this.unitsToRemove.map(function (entry) {
                            return entry.select ? entry.keyId : undefined;
                        }).filter(Number);
                        _loop_1 = function (entry) {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!removeUnitResult) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_1.performRemoveUnit(entry).then(function (promiseResponse) {
                                                removeUnitResult = promiseResponse;
                                                if (promiseResponse && unitIdsToRemove.indexOf(entry) === unitIdsToRemove.length - 1) {
                                                    _this.popupResolved.emit('refresh');
                                                    _this.popupService.cancelPopup();
                                                }
                                            })];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, unitIdsToRemove_1 = unitIdsToRemove;
                        _a.label = 1;
                    case 1:
                        if (!(_i < unitIdsToRemove_1.length)) return [3 /*break*/, 4];
                        entry = unitIdsToRemove_1[_i];
                        return [5 /*yield**/, _loop_1(entry)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RemoveUnitComponent.prototype.performRemoveUnit = function (keyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.operationService.removeUnit(_this.operationKeyId, keyId).subscribe(function (response) {
                            if (response.error === td_constants_1.NO_ERROR_CODE) {
                                resolve(true);
                            }
                            else {
                                _this.popupService.cancelPopup();
                                _this.popupResolved.emit(response.message);
                                resolve(false);
                            }
                        });
                    })];
            });
        });
    };
    RemoveUnitComponent.prototype.checkUnitSelected = function () {
        this.unitSelected = this.unitsToRemove.some(function (unit) { return unit[td_constants_1.TD_DYNAMIC_FIELDS.select]; });
    };
    return RemoveUnitComponent;
}(base_component_1.BaseComponent));
exports.RemoveUnitComponent = RemoveUnitComponent;
