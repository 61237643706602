import {
  OrderBookingMode,
  OrderBookingPriority,
  OrderBookingStatus,
  OrderOperationSyncMode,
  OrderSuppStatus,
  OrderOrgStatus,
  TableTypes,
  ReservationState,
  ItemInstrumentType,
  IdentArticleLineUsage
} from '@core/data-layer/shared/models/td.enumerations';

export enum WebOrderState {
  New = 0,
  ExistingEdit = 1,
  Existing = 2
}

export enum OrderLineState {
  Current = 0,
  Edited = 1,
  Deleted = 2,
  New = 3
}

export class WebOrderModel {
  state: WebOrderState;
  details: WebOrderDetailsModel;
  lines: WebOrderLineModel[];

  constructor() {
    this.details = new WebOrderDetailsModel();
    this.lines = new Array<WebOrderLineModel>();
  }
}

export class WebOrderDetailsModel {
  keyId: number;
  suppRefNo: number;
  suppStatus: OrderSuppStatus;
  suppTime: Date;
  suppRefFacKeyId: number;
  suppRefFacFactory: string;
  suppRefFacName: string;
  suppRefFacAddress1: string;
  suppRefFacAddress2: string;
  suppRefFacCity: string;
  suppRefFacTelephone: string;
  suppRefText: string;
  suppRefText2: string;
  orgRefNo: number;
  orgStatus: OrderOrgStatus;
  orgTime: Date;
  orgStatusAsLangSt: string;
  orgUserKeyId: number;
  orgUserInitials: string;
  orgRefCustKeyId: number;
  orgRefCustCustomer: string;
  orgRefCustName: string;
  orgRefCustAddress1: string;
  orgRefCustAddress2: string;
  orgRefCustCity: string;
  orgRefCustTelephone: string;
  orgRefFacKeyId: number;
  orgRefText: string;
  orgRefText2: string;
  orgRemark: string;
  orgStokKeyId: number;
  orgDisplayString: string;
  opDStartTime: Date;
  refOpDKeyId: number; // order is linked to operation when refOpDKeyId > 0
  refOpDStatus: number;
  refOpDIdent: string;
  syncToOperation: OrderOperationSyncMode;
  reqDelTime: Date;
  bookingMode: OrderBookingMode;
  bookingStatus: OrderBookingStatus;
  bookingPriority: OrderBookingPriority;
}

export class WebOrderLineModel {
  oLnLine: number;
  oLnKey: number;
  oLnRefType: TableTypes;
  oLnRefKeyId: number;
  oLnArticle: string;
  oLnArticleText: string;
  itemInstrumentType: ItemInstrumentType;
  itemText: string;
  oLnOrgPrice: number;
  oLnOrgUnitCount: number;
  oLnOrgStdCount: number;
  oLnOrgOrdered: number;
  oLnSuppPrice: number;
  oLnSuppUnitCount: number;
  oLnSuppStdCount: number;
  oLnSuppDelivered: number;
  oLnSuppUnitLater: number;
  oLnSuppLater: number;
  lineState: OrderLineState;
  oLnResvState: ReservationState;
  picsKeyId: number;
  picsLastChange: Date;
  inArtKeyId: number;
  oLnIdentLineUsage: IdentArticleLineUsage;
  oLnHasAlternative: boolean;
}
