import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { enableProdMode, Injector, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';
import { locale } from 'devextreme/localization';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Bootstrap AppModule only after locale is fetched from the backend, registered and provided
// ToDo: In the Angular 8 LOCALE_ID is provided before any APP_INITIALIZER completed and it is not possibe to load LOCALE_ID asynchronosly
// The issue is fixed in Angular 9, so after upgrading to 9 version LOCALE_ID preloading and providing should be moved to the core module
const injector: Injector = Injector.create({
  providers: [
    { provide: HttpClient, deps: [HttpHandler] },
    { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest() }) },
  ]
});

const http: HttpClient = injector.get(HttpClient);

(async () => {
  const localeName = await new Promise<string>(resolve => {
    http.get(API_HUB_PATHS.apiUrl + API_HUB_PATHS.clientConfigurationCtrl + API_HUB_PATHS.locale, { responseType: 'text' })
      .subscribe((response) => {
        if (response) {
          resolve(response);
        }
        else {
          // Unlikely to happen, but to be sure we set default
          console.error('Web server locale was not fetched from the web server.');
          resolve('en-US');
        }
      });
  });

  // Angular
  let localeFileName = localeName;
  const res = await import(`@angular/common/locales/${localeFileName}.js`).catch(
    async () => {
      localeFileName = localeName.substring(0, 2);
      return await import(`@angular/common/locales/${localeFileName}.js`);
    }
  );
  registerLocaleData(res.default, localeFileName);

  // DevExpress
  locale(localeFileName);

  platformBrowserDynamic(
    [{ provide: LOCALE_ID, useValue: localeName }]
  ).bootstrapModule(AppModule)
    .then((moduleRef) => { })
    .catch(err => console.error(err));
})();
