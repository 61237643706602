"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var AlternativeArticleStatus;
(function (AlternativeArticleStatus) {
    AlternativeArticleStatus[AlternativeArticleStatus["Old"] = 0] = "Old";
    AlternativeArticleStatus[AlternativeArticleStatus["New"] = 1] = "New";
    AlternativeArticleStatus[AlternativeArticleStatus["Deleted"] = 2] = "Deleted";
    AlternativeArticleStatus[AlternativeArticleStatus["Edited"] = 3] = "Edited";
})(AlternativeArticleStatus = exports.AlternativeArticleStatus || (exports.AlternativeArticleStatus = {}));
var AssignFactoryData;
(function (AssignFactoryData) {
    AssignFactoryData[AssignFactoryData["None"] = 0] = "None";
    AssignFactoryData[AssignFactoryData["OwnFactories"] = 1] = "OwnFactories";
    AssignFactoryData[AssignFactoryData["AllFactories"] = 2] = "AllFactories";
})(AssignFactoryData = exports.AssignFactoryData || (exports.AssignFactoryData = {}));
var CustNameDisplay;
(function (CustNameDisplay) {
    CustNameDisplay[CustNameDisplay["Name"] = 0] = "Name";
    CustNameDisplay[CustNameDisplay["ShortName"] = 1] = "ShortName";
    CustNameDisplay[CustNameDisplay["Number"] = 2] = "Number";
})(CustNameDisplay = exports.CustNameDisplay || (exports.CustNameDisplay = {}));
var CustOrderBookingMode;
(function (CustOrderBookingMode) {
    CustOrderBookingMode[CustOrderBookingMode["Default"] = 0] = "Default";
    CustOrderBookingMode[CustOrderBookingMode["Disabled"] = 1] = "Disabled";
    CustOrderBookingMode[CustOrderBookingMode["Manual"] = 2] = "Manual";
    CustOrderBookingMode[CustOrderBookingMode["Automation"] = 3] = "Automation";
})(CustOrderBookingMode = exports.CustOrderBookingMode || (exports.CustOrderBookingMode = {}));
var CustPreDispatchMode;
(function (CustPreDispatchMode) {
    CustPreDispatchMode[CustPreDispatchMode["UseFacSetting"] = 0] = "UseFacSetting";
    CustPreDispatchMode[CustPreDispatchMode["Always"] = 1] = "Always";
    CustPreDispatchMode[CustPreDispatchMode["Never"] = 2] = "Never";
})(CustPreDispatchMode = exports.CustPreDispatchMode || (exports.CustPreDispatchMode = {}));
var DecNonDispItemStockOnPack;
(function (DecNonDispItemStockOnPack) {
    DecNonDispItemStockOnPack[DecNonDispItemStockOnPack["Off"] = 0] = "Off";
    DecNonDispItemStockOnPack[DecNonDispItemStockOnPack["Prompt"] = 1] = "Prompt";
    DecNonDispItemStockOnPack[DecNonDispItemStockOnPack["On"] = 2] = "On";
})(DecNonDispItemStockOnPack = exports.DecNonDispItemStockOnPack || (exports.DecNonDispItemStockOnPack = {}));
var DeltaScanMode;
(function (DeltaScanMode) {
    DeltaScanMode[DeltaScanMode["Delta"] = 0] = "Delta";
    DeltaScanMode[DeltaScanMode["Total"] = 1] = "Total";
})(DeltaScanMode = exports.DeltaScanMode || (exports.DeltaScanMode = {}));
var ExpireType;
(function (ExpireType) {
    ExpireType[ExpireType["Normal"] = 0] = "Normal";
    ExpireType[ExpireType["None"] = 1] = "None";
})(ExpireType = exports.ExpireType || (exports.ExpireType = {}));
var FacDeductItemsFromStockInOpType;
(function (FacDeductItemsFromStockInOpType) {
    FacDeductItemsFromStockInOpType[FacDeductItemsFromStockInOpType["On"] = 0] = "On";
    FacDeductItemsFromStockInOpType[FacDeductItemsFromStockInOpType["Off"] = 1] = "Off";
})(FacDeductItemsFromStockInOpType = exports.FacDeductItemsFromStockInOpType || (exports.FacDeductItemsFromStockInOpType = {}));
var FacOrderBookingMode;
(function (FacOrderBookingMode) {
    FacOrderBookingMode[FacOrderBookingMode["Disabled"] = 0] = "Disabled";
    FacOrderBookingMode[FacOrderBookingMode["Manual"] = 1] = "Manual";
    FacOrderBookingMode[FacOrderBookingMode["Automation"] = 2] = "Automation";
})(FacOrderBookingMode = exports.FacOrderBookingMode || (exports.FacOrderBookingMode = {}));
var FactoryDefProdTraceType;
(function (FactoryDefProdTraceType) {
    FactoryDefProdTraceType[FactoryDefProdTraceType["ProductSerial"] = 0] = "ProductSerial";
    FactoryDefProdTraceType[FactoryDefProdTraceType["Product"] = 1] = "Product";
})(FactoryDefProdTraceType = exports.FactoryDefProdTraceType || (exports.FactoryDefProdTraceType = {}));
var FastTrackCodeType;
(function (FastTrackCodeType) {
    FastTrackCodeType[FastTrackCodeType["General"] = 0] = "General";
    FastTrackCodeType[FastTrackCodeType["AdHoc"] = 1] = "AdHoc";
    FastTrackCodeType[FastTrackCodeType["TemplateOnly"] = 2] = "TemplateOnly";
})(FastTrackCodeType = exports.FastTrackCodeType || (exports.FastTrackCodeType = {}));
var FastTrackCreatorType;
(function (FastTrackCreatorType) {
    FastTrackCreatorType[FastTrackCreatorType["System"] = 0] = "System";
    FastTrackCreatorType[FastTrackCreatorType["JobServer"] = 1] = "JobServer";
    FastTrackCreatorType[FastTrackCreatorType["FacUser"] = 2] = "FacUser";
    FastTrackCreatorType[FastTrackCreatorType["CustUser"] = 3] = "CustUser";
})(FastTrackCreatorType = exports.FastTrackCreatorType || (exports.FastTrackCreatorType = {}));
var FastTrackDisplayMode;
(function (FastTrackDisplayMode) {
    FastTrackDisplayMode[FastTrackDisplayMode["UserSetting"] = 0] = "UserSetting";
    FastTrackDisplayMode[FastTrackDisplayMode["ForcePrompt"] = 1] = "ForcePrompt";
    FastTrackDisplayMode[FastTrackDisplayMode["ForceNoPrompt"] = 2] = "ForceNoPrompt";
    FastTrackDisplayMode[FastTrackDisplayMode["ForceNoPromptOrInfo"] = 3] = "ForceNoPromptOrInfo";
})(FastTrackDisplayMode = exports.FastTrackDisplayMode || (exports.FastTrackDisplayMode = {}));
var FastTrackEndType;
(function (FastTrackEndType) {
    FastTrackEndType[FastTrackEndType["Normal"] = 0] = "Normal";
    FastTrackEndType[FastTrackEndType["NormalNewUnitHas"] = 1] = "NormalNewUnitHas";
    FastTrackEndType[FastTrackEndType["ToRepair"] = 2] = "ToRepair";
    FastTrackEndType[FastTrackEndType["Expired"] = 3] = "Expired";
    FastTrackEndType[FastTrackEndType["Cancelled"] = 4] = "Cancelled";
})(FastTrackEndType = exports.FastTrackEndType || (exports.FastTrackEndType = {}));
var FastTrackInvoiceType;
(function (FastTrackInvoiceType) {
    FastTrackInvoiceType[FastTrackInvoiceType["None"] = 0] = "None";
    FastTrackInvoiceType[FastTrackInvoiceType["Factory"] = 1] = "Factory";
    FastTrackInvoiceType[FastTrackInvoiceType["Customer"] = 2] = "Customer";
})(FastTrackInvoiceType = exports.FastTrackInvoiceType || (exports.FastTrackInvoiceType = {}));
var FastTrackStatus;
(function (FastTrackStatus) {
    FastTrackStatus[FastTrackStatus["Assigned"] = 0] = "Assigned";
    FastTrackStatus[FastTrackStatus["Started"] = 1] = "Started";
    FastTrackStatus[FastTrackStatus["Ended"] = 2] = "Ended";
})(FastTrackStatus = exports.FastTrackStatus || (exports.FastTrackStatus = {}));
var FastTrackStockBasedMode;
(function (FastTrackStockBasedMode) {
    FastTrackStockBasedMode[FastTrackStockBasedMode["Disabled"] = 0] = "Disabled";
    FastTrackStockBasedMode[FastTrackStockBasedMode["Enabled"] = 1] = "Enabled";
})(FastTrackStockBasedMode = exports.FastTrackStockBasedMode || (exports.FastTrackStockBasedMode = {}));
var FastTrackStockBasedOrdersFilter;
(function (FastTrackStockBasedOrdersFilter) {
    FastTrackStockBasedOrdersFilter[FastTrackStockBasedOrdersFilter["AllWithDate"] = 0] = "AllWithDate";
    FastTrackStockBasedOrdersFilter[FastTrackStockBasedOrdersFilter["Operation"] = 1] = "Operation";
})(FastTrackStockBasedOrdersFilter = exports.FastTrackStockBasedOrdersFilter || (exports.FastTrackStockBasedOrdersFilter = {}));
var FuzzySearchSortOrder;
(function (FuzzySearchSortOrder) {
    FuzzySearchSortOrder[FuzzySearchSortOrder["None"] = 0] = "None";
    FuzzySearchSortOrder[FuzzySearchSortOrder["Asc"] = 1] = "Asc";
    FuzzySearchSortOrder[FuzzySearchSortOrder["Desc"] = 2] = "Desc";
})(FuzzySearchSortOrder = exports.FuzzySearchSortOrder || (exports.FuzzySearchSortOrder = {}));
var FuzzySearchTextMatchType;
(function (FuzzySearchTextMatchType) {
    FuzzySearchTextMatchType[FuzzySearchTextMatchType["Equals"] = 0] = "Equals";
    FuzzySearchTextMatchType[FuzzySearchTextMatchType["BeginsWith"] = 1] = "BeginsWith";
    FuzzySearchTextMatchType[FuzzySearchTextMatchType["EndsWith"] = 2] = "EndsWith";
    FuzzySearchTextMatchType[FuzzySearchTextMatchType["Contains"] = 3] = "Contains";
})(FuzzySearchTextMatchType = exports.FuzzySearchTextMatchType || (exports.FuzzySearchTextMatchType = {}));
var IdentArticleLineUsage;
(function (IdentArticleLineUsage) {
    IdentArticleLineUsage[IdentArticleLineUsage["Enabled"] = 0] = "Enabled";
    IdentArticleLineUsage[IdentArticleLineUsage["Disabled"] = 1] = "Disabled";
})(IdentArticleLineUsage = exports.IdentArticleLineUsage || (exports.IdentArticleLineUsage = {}));
var IdentStockUsage;
(function (IdentStockUsage) {
    IdentStockUsage[IdentStockUsage["Default"] = 0] = "Default";
    IdentStockUsage[IdentStockUsage["Enabled"] = 1] = "Enabled";
    IdentStockUsage[IdentStockUsage["Disabled"] = 2] = "Disabled";
})(IdentStockUsage = exports.IdentStockUsage || (exports.IdentStockUsage = {}));
var IncludeItemSerialUsage;
(function (IncludeItemSerialUsage) {
    IncludeItemSerialUsage[IncludeItemSerialUsage["Off"] = 0] = "Off";
    IncludeItemSerialUsage[IncludeItemSerialUsage["On"] = 1] = "On";
    IncludeItemSerialUsage[IncludeItemSerialUsage["OnHidden"] = 2] = "OnHidden";
})(IncludeItemSerialUsage = exports.IncludeItemSerialUsage || (exports.IncludeItemSerialUsage = {}));
var IncNonDispItemStockOnPack;
(function (IncNonDispItemStockOnPack) {
    IncNonDispItemStockOnPack[IncNonDispItemStockOnPack["Off"] = 0] = "Off";
    IncNonDispItemStockOnPack[IncNonDispItemStockOnPack["Prompt"] = 1] = "Prompt";
    IncNonDispItemStockOnPack[IncNonDispItemStockOnPack["On"] = 2] = "On";
})(IncNonDispItemStockOnPack = exports.IncNonDispItemStockOnPack || (exports.IncNonDispItemStockOnPack = {}));
var ItemAltPricingMode;
(function (ItemAltPricingMode) {
    ItemAltPricingMode[ItemAltPricingMode["None"] = 0] = "None";
    ItemAltPricingMode[ItemAltPricingMode["SalesPrice"] = 1] = "SalesPrice";
    ItemAltPricingMode[ItemAltPricingMode["UsagePrice"] = 2] = "UsagePrice";
    ItemAltPricingMode[ItemAltPricingMode["CostPrice"] = 3] = "CostPrice";
})(ItemAltPricingMode = exports.ItemAltPricingMode || (exports.ItemAltPricingMode = {}));
var ItemDifficultyMode;
(function (ItemDifficultyMode) {
    ItemDifficultyMode[ItemDifficultyMode["Default"] = 0] = "Default";
    ItemDifficultyMode[ItemDifficultyMode["Fixed"] = 1] = "Fixed";
    ItemDifficultyMode[ItemDifficultyMode["Sum"] = 2] = "Sum";
    ItemDifficultyMode[ItemDifficultyMode["Max"] = 3] = "Max";
    ItemDifficultyMode[ItemDifficultyMode["Count"] = 4] = "Count";
})(ItemDifficultyMode = exports.ItemDifficultyMode || (exports.ItemDifficultyMode = {}));
var ItemInstrumentType;
(function (ItemInstrumentType) {
    ItemInstrumentType[ItemInstrumentType["Instrument"] = 0] = "Instrument";
    ItemInstrumentType[ItemInstrumentType["Endoscope"] = 1] = "Endoscope";
    ItemInstrumentType[ItemInstrumentType["ButtonValve"] = 2] = "ButtonValve";
})(ItemInstrumentType = exports.ItemInstrumentType || (exports.ItemInstrumentType = {}));
var ItemPricingMode;
(function (ItemPricingMode) {
    ItemPricingMode[ItemPricingMode["SalesPrice"] = 0] = "SalesPrice";
    ItemPricingMode[ItemPricingMode["UsagePrice"] = 1] = "UsagePrice";
    ItemPricingMode[ItemPricingMode["CostPrice"] = 2] = "CostPrice";
})(ItemPricingMode = exports.ItemPricingMode || (exports.ItemPricingMode = {}));
var ItemPricingType;
(function (ItemPricingType) {
    ItemPricingType[ItemPricingType["Normal"] = 0] = "Normal";
    ItemPricingType[ItemPricingType["TimeBased"] = 1] = "TimeBased";
})(ItemPricingType = exports.ItemPricingType || (exports.ItemPricingType = {}));
var ItemReservationMode;
(function (ItemReservationMode) {
    ItemReservationMode[ItemReservationMode["NotRequired"] = 0] = "NotRequired";
    ItemReservationMode[ItemReservationMode["Enabled"] = 1] = "Enabled";
})(ItemReservationMode = exports.ItemReservationMode || (exports.ItemReservationMode = {}));
var ItemSalesRestriction;
(function (ItemSalesRestriction) {
    ItemSalesRestriction[ItemSalesRestriction["None"] = 0] = "None";
    ItemSalesRestriction[ItemSalesRestriction["Blocked"] = 1] = "Blocked";
})(ItemSalesRestriction = exports.ItemSalesRestriction || (exports.ItemSalesRestriction = {}));
var ItemTraceType;
(function (ItemTraceType) {
    ItemTraceType[ItemTraceType["Item"] = 0] = "Item";
    ItemTraceType[ItemTraceType["SerialItem"] = 1] = "SerialItem";
    ItemTraceType[ItemTraceType["LOT"] = 2] = "LOT";
})(ItemTraceType = exports.ItemTraceType || (exports.ItemTraceType = {}));
var Language;
(function (Language) {
    Language[Language["Default"] = 0] = "Default";
    Language[Language["EnglishUS"] = 1] = "EnglishUS";
    Language[Language["Danish"] = 2] = "Danish";
    Language[Language["Swedish"] = 3] = "Swedish";
    Language[Language["Norwegian"] = 4] = "Norwegian";
    Language[Language["Finnish"] = 5] = "Finnish";
    Language[Language["German"] = 6] = "German";
    Language[Language["French"] = 7] = "French";
    Language[Language["Spanish"] = 8] = "Spanish";
    Language[Language["Italian"] = 9] = "Italian";
    Language[Language["Dutch"] = 10] = "Dutch";
    Language[Language["Polish"] = 11] = "Polish";
    Language[Language["Japanese"] = 12] = "Japanese";
    Language[Language["ChineseSimple"] = 13] = "ChineseSimple";
    Language[Language["Russian"] = 14] = "Russian";
    Language[Language["Bulgarian"] = 15] = "Bulgarian";
    Language[Language["Hungarian"] = 16] = "Hungarian";
    Language[Language["BrazilPortuguese"] = 17] = "BrazilPortuguese";
    Language[Language["Slovenian"] = 18] = "Slovenian";
    Language[Language["Romanian"] = 19] = "Romanian";
    Language[Language["SpanishLatin"] = 20] = "SpanishLatin";
    Language[Language["Thai"] = 21] = "Thai";
    Language[Language["Turkish"] = 22] = "Turkish";
    Language[Language["Greek"] = 23] = "Greek";
    Language[Language["Malay"] = 24] = "Malay";
    Language[Language["Lithuanian"] = 25] = "Lithuanian";
    Language[Language["Czech"] = 26] = "Czech";
    Language[Language["Estonian"] = 27] = "Estonian";
    Language[Language["Latvian"] = 28] = "Latvian";
    Language[Language["Slovakian"] = 29] = "Slovakian";
    Language[Language["Korean"] = 30] = "Korean";
    Language[Language["Portuguese"] = 31] = "Portuguese";
    Language[Language["ChineseTrad"] = 32] = "ChineseTrad";
    Language[Language["Icelandic"] = 33] = "Icelandic";
})(Language = exports.Language || (exports.Language = {}));
var LegacyProcessType;
(function (LegacyProcessType) {
    LegacyProcessType[LegacyProcessType["None"] = 0] = "None";
    LegacyProcessType[LegacyProcessType["Pack"] = 1] = "Pack";
    LegacyProcessType[LegacyProcessType["SteriPostBatch"] = 2] = "SteriPostBatch";
    LegacyProcessType[LegacyProcessType["Out"] = 3] = "Out";
    LegacyProcessType[LegacyProcessType["Return"] = 4] = "Return";
    LegacyProcessType[LegacyProcessType["Prepare"] = 5] = "Prepare";
    LegacyProcessType[LegacyProcessType["Wrap"] = 6] = "Wrap";
    LegacyProcessType[LegacyProcessType["LitePro"] = 7] = "LitePro";
    LegacyProcessType[LegacyProcessType["SteriPreBatch"] = 8] = "SteriPreBatch";
    LegacyProcessType[LegacyProcessType["Flash"] = 9] = "Flash";
    LegacyProcessType[LegacyProcessType["Location"] = 10] = "Location";
    LegacyProcessType[LegacyProcessType["WashPreBatch"] = 11] = "WashPreBatch";
    LegacyProcessType[LegacyProcessType["WashPostBatch"] = 12] = "WashPostBatch";
    LegacyProcessType[LegacyProcessType["OpenUsed"] = 13] = "OpenUsed";
    LegacyProcessType[LegacyProcessType["Operation"] = 14] = "Operation";
    LegacyProcessType[LegacyProcessType["Order"] = 15] = "Order";
    LegacyProcessType[LegacyProcessType["Inventory"] = 16] = "Inventory";
    LegacyProcessType[LegacyProcessType["OrderPick"] = 17] = "OrderPick";
    LegacyProcessType[LegacyProcessType["Industry"] = 18] = "Industry";
    LegacyProcessType[LegacyProcessType["PreDisPreBatch"] = 19] = "PreDisPreBatch";
    LegacyProcessType[LegacyProcessType["LoadQueue"] = 20] = "LoadQueue";
    LegacyProcessType[LegacyProcessType["PurchaseOrder"] = 21] = "PurchaseOrder";
    LegacyProcessType[LegacyProcessType["EndoscopePreBatch"] = 22] = "EndoscopePreBatch";
    LegacyProcessType[LegacyProcessType["WashCheck"] = 23] = "WashCheck";
    LegacyProcessType[LegacyProcessType["ExtBatchInfo"] = 24] = "ExtBatchInfo";
    LegacyProcessType[LegacyProcessType["EndoCleanAndTest"] = 25] = "EndoCleanAndTest";
    LegacyProcessType[LegacyProcessType["Transport"] = 26] = "Transport";
    LegacyProcessType[LegacyProcessType["Workflow"] = 27] = "Workflow";
    LegacyProcessType[LegacyProcessType["Incubator"] = 28] = "Incubator";
    LegacyProcessType[LegacyProcessType["Last"] = 29] = "Last";
})(LegacyProcessType = exports.LegacyProcessType || (exports.LegacyProcessType = {}));
var LocaSubProcessType;
(function (LocaSubProcessType) {
    LocaSubProcessType[LocaSubProcessType["None"] = 0] = "None";
    LocaSubProcessType[LocaSubProcessType["Admin"] = 1] = "Admin";
    LocaSubProcessType[LocaSubProcessType["Machine"] = 2] = "Machine";
})(LocaSubProcessType = exports.LocaSubProcessType || (exports.LocaSubProcessType = {}));
var LoginCookieMethod;
(function (LoginCookieMethod) {
    LoginCookieMethod[LoginCookieMethod["NotAllowed"] = 0] = "NotAllowed";
    LoginCookieMethod[LoginCookieMethod["UserName"] = 1] = "UserName";
    LoginCookieMethod[LoginCookieMethod["UserNamePassword"] = 2] = "UserNamePassword";
    LoginCookieMethod[LoginCookieMethod["Autologin"] = 3] = "Autologin";
})(LoginCookieMethod = exports.LoginCookieMethod || (exports.LoginCookieMethod = {}));
var LogKeyType;
(function (LogKeyType) {
    LogKeyType[LogKeyType["ResvProduct"] = 0] = "ResvProduct";
    LogKeyType[LogKeyType["ResvItem"] = 1] = "ResvItem";
    LogKeyType[LogKeyType["OrdDelivered"] = 2] = "OrdDelivered";
})(LogKeyType = exports.LogKeyType || (exports.LogKeyType = {}));
var ManualEndoDryingMode;
(function (ManualEndoDryingMode) {
    ManualEndoDryingMode[ManualEndoDryingMode["NotAllowed"] = 0] = "NotAllowed";
    ManualEndoDryingMode[ManualEndoDryingMode["Allowed"] = 1] = "Allowed";
    ManualEndoDryingMode[ManualEndoDryingMode["Assumed"] = 2] = "Assumed";
})(ManualEndoDryingMode = exports.ManualEndoDryingMode || (exports.ManualEndoDryingMode = {}));
var MinOrderLeadTime;
(function (MinOrderLeadTime) {
    MinOrderLeadTime[MinOrderLeadTime["Actual"] = 0] = "Actual";
    MinOrderLeadTime[MinOrderLeadTime["Work"] = 1] = "Work";
})(MinOrderLeadTime = exports.MinOrderLeadTime || (exports.MinOrderLeadTime = {}));
var MultiMediaSubType;
(function (MultiMediaSubType) {
    MultiMediaSubType[MultiMediaSubType["Picture"] = 0] = "Picture";
    MultiMediaSubType[MultiMediaSubType["ThumbNail"] = 1] = "ThumbNail";
    MultiMediaSubType[MultiMediaSubType["Text"] = 2] = "Text";
    MultiMediaSubType[MultiMediaSubType["Sound"] = 3] = "Sound";
    MultiMediaSubType[MultiMediaSubType["Video"] = 4] = "Video";
})(MultiMediaSubType = exports.MultiMediaSubType || (exports.MultiMediaSubType = {}));
var MultiMediaType;
(function (MultiMediaType) {
    MultiMediaType[MultiMediaType["Picture"] = 0] = "Picture";
    MultiMediaType[MultiMediaType["Video"] = 1] = "Video";
})(MultiMediaType = exports.MultiMediaType || (exports.MultiMediaType = {}));
var NotifyPriority;
(function (NotifyPriority) {
    NotifyPriority[NotifyPriority["None"] = 0] = "None";
    NotifyPriority[NotifyPriority["Low"] = 1] = "Low";
    NotifyPriority[NotifyPriority["Normal"] = 2] = "Normal";
    NotifyPriority[NotifyPriority["High"] = 3] = "High";
})(NotifyPriority = exports.NotifyPriority || (exports.NotifyPriority = {}));
var OperationSearchType;
(function (OperationSearchType) {
    OperationSearchType[OperationSearchType["Date"] = 0] = "Date";
    OperationSearchType[OperationSearchType["OperationID"] = 1] = "OperationID";
})(OperationSearchType = exports.OperationSearchType || (exports.OperationSearchType = {}));
var OperationTypeType;
(function (OperationTypeType) {
    OperationTypeType[OperationTypeType["Elective"] = 0] = "Elective";
    OperationTypeType[OperationTypeType["Emergency"] = 1] = "Emergency";
})(OperationTypeType = exports.OperationTypeType || (exports.OperationTypeType = {}));
var OrderBookingMode;
(function (OrderBookingMode) {
    OrderBookingMode[OrderBookingMode["Undetermined"] = 0] = "Undetermined";
    OrderBookingMode[OrderBookingMode["Disabled"] = 1] = "Disabled";
    OrderBookingMode[OrderBookingMode["Inactive"] = 2] = "Inactive";
    OrderBookingMode[OrderBookingMode["Active"] = 3] = "Active";
})(OrderBookingMode = exports.OrderBookingMode || (exports.OrderBookingMode = {}));
var OrderBookingPriority;
(function (OrderBookingPriority) {
    OrderBookingPriority[OrderBookingPriority["Normal"] = 0] = "Normal";
    OrderBookingPriority[OrderBookingPriority["High"] = 1] = "High";
    OrderBookingPriority[OrderBookingPriority["Force"] = 2] = "Force";
})(OrderBookingPriority = exports.OrderBookingPriority || (exports.OrderBookingPriority = {}));
var OrderBookingStatus;
(function (OrderBookingStatus) {
    OrderBookingStatus[OrderBookingStatus["Undetermined"] = 0] = "Undetermined";
    OrderBookingStatus[OrderBookingStatus["Ok"] = 1] = "Ok";
    OrderBookingStatus[OrderBookingStatus["Warning"] = 2] = "Warning";
    OrderBookingStatus[OrderBookingStatus["Error"] = 3] = "Error";
})(OrderBookingStatus = exports.OrderBookingStatus || (exports.OrderBookingStatus = {}));
var OrderLockMode;
(function (OrderLockMode) {
    OrderLockMode[OrderLockMode["Unlocked"] = 0] = "Unlocked";
    OrderLockMode[OrderLockMode["Locked"] = 1] = "Locked";
})(OrderLockMode = exports.OrderLockMode || (exports.OrderLockMode = {}));
var OrderOperationSyncMode;
(function (OrderOperationSyncMode) {
    OrderOperationSyncMode[OrderOperationSyncMode["NA"] = 0] = "NA";
    OrderOperationSyncMode[OrderOperationSyncMode["Synced"] = 1] = "Synced";
    OrderOperationSyncMode[OrderOperationSyncMode["Stopped"] = 2] = "Stopped";
})(OrderOperationSyncMode = exports.OrderOperationSyncMode || (exports.OrderOperationSyncMode = {}));
var OrderOrgStatus;
(function (OrderOrgStatus) {
    OrderOrgStatus[OrderOrgStatus["None"] = 0] = "None";
    OrderOrgStatus[OrderOrgStatus["Template"] = 1] = "Template";
    OrderOrgStatus[OrderOrgStatus["Open"] = 2] = "Open";
    OrderOrgStatus[OrderOrgStatus["Sent"] = 3] = "Sent";
    OrderOrgStatus[OrderOrgStatus["Received"] = 4] = "Received";
    OrderOrgStatus[OrderOrgStatus["Done"] = 5] = "Done";
    OrderOrgStatus[OrderOrgStatus["Cancelled"] = 6] = "Cancelled";
})(OrderOrgStatus = exports.OrderOrgStatus || (exports.OrderOrgStatus = {}));
var OrderSuppPickMode;
(function (OrderSuppPickMode) {
    OrderSuppPickMode[OrderSuppPickMode["Normal"] = 0] = "Normal";
    OrderSuppPickMode[OrderSuppPickMode["FullAuto"] = 1] = "FullAuto";
    OrderSuppPickMode[OrderSuppPickMode["EjectOnly"] = 2] = "EjectOnly";
})(OrderSuppPickMode = exports.OrderSuppPickMode || (exports.OrderSuppPickMode = {}));
var OrderSuppPickPriority;
(function (OrderSuppPickPriority) {
    OrderSuppPickPriority[OrderSuppPickPriority["Normal"] = 0] = "Normal";
    OrderSuppPickPriority[OrderSuppPickPriority["PickNow"] = 1] = "PickNow";
    OrderSuppPickPriority[OrderSuppPickPriority["PickNowForced"] = 2] = "PickNowForced";
})(OrderSuppPickPriority = exports.OrderSuppPickPriority || (exports.OrderSuppPickPriority = {}));
var OrderSuppPriority;
(function (OrderSuppPriority) {
    OrderSuppPriority[OrderSuppPriority["Normal"] = 0] = "Normal";
    OrderSuppPriority[OrderSuppPriority["FastTrack"] = 1] = "FastTrack";
    OrderSuppPriority[OrderSuppPriority["Emergency"] = 2] = "Emergency";
})(OrderSuppPriority = exports.OrderSuppPriority || (exports.OrderSuppPriority = {}));
var OrderSuppStatus;
(function (OrderSuppStatus) {
    OrderSuppStatus[OrderSuppStatus["None"] = 0] = "None";
    OrderSuppStatus[OrderSuppStatus["Template"] = 1] = "Template";
    OrderSuppStatus[OrderSuppStatus["Order"] = 2] = "Order";
    OrderSuppStatus[OrderSuppStatus["Accepted"] = 3] = "Accepted";
    OrderSuppStatus[OrderSuppStatus["Picked"] = 4] = "Picked";
    OrderSuppStatus[OrderSuppStatus["Delivered"] = 5] = "Delivered";
    OrderSuppStatus[OrderSuppStatus["Cancelled"] = 6] = "Cancelled";
})(OrderSuppStatus = exports.OrderSuppStatus || (exports.OrderSuppStatus = {}));
var OrderType;
(function (OrderType) {
    OrderType[OrderType["None"] = 0] = "None";
    OrderType[OrderType["Order"] = 1] = "Order";
    OrderType[OrderType["ReturnOrder"] = 2] = "ReturnOrder";
    OrderType[OrderType["OrderCorr"] = 3] = "OrderCorr";
})(OrderType = exports.OrderType || (exports.OrderType = {}));
var PasswordVerificationMethod;
(function (PasswordVerificationMethod) {
    PasswordVerificationMethod[PasswordVerificationMethod["None"] = 0] = "None";
    PasswordVerificationMethod[PasswordVerificationMethod["TDOC"] = 1] = "TDOC";
    PasswordVerificationMethod[PasswordVerificationMethod["ActiveDirectory"] = 2] = "ActiveDirectory";
})(PasswordVerificationMethod = exports.PasswordVerificationMethod || (exports.PasswordVerificationMethod = {}));
var PasswordVerify;
(function (PasswordVerify) {
    PasswordVerify[PasswordVerify["TDOC"] = 0] = "TDOC";
    PasswordVerify[PasswordVerify["NTDomain"] = 1] = "NTDomain";
    PasswordVerify[PasswordVerify["ActiveDirectory"] = 2] = "ActiveDirectory";
})(PasswordVerify = exports.PasswordVerify || (exports.PasswordVerify = {}));
var PatientAgeUsage;
(function (PatientAgeUsage) {
    PatientAgeUsage[PatientAgeUsage["NotSet"] = 0] = "NotSet";
    PatientAgeUsage[PatientAgeUsage["Needed"] = 1] = "Needed";
    PatientAgeUsage[PatientAgeUsage["Required"] = 2] = "Required";
})(PatientAgeUsage = exports.PatientAgeUsage || (exports.PatientAgeUsage = {}));
var PatientGenderUsage;
(function (PatientGenderUsage) {
    PatientGenderUsage[PatientGenderUsage["NotSet"] = 0] = "NotSet";
    PatientGenderUsage[PatientGenderUsage["Needed"] = 1] = "Needed";
    PatientGenderUsage[PatientGenderUsage["Required"] = 2] = "Required";
})(PatientGenderUsage = exports.PatientGenderUsage || (exports.PatientGenderUsage = {}));
var PrefListOrderCreateMode;
(function (PrefListOrderCreateMode) {
    PrefListOrderCreateMode[PrefListOrderCreateMode["Immediate"] = 0] = "Immediate";
    PrefListOrderCreateMode[PrefListOrderCreateMode["TimeBased"] = 1] = "TimeBased";
    PrefListOrderCreateMode[PrefListOrderCreateMode["Manual"] = 2] = "Manual";
})(PrefListOrderCreateMode = exports.PrefListOrderCreateMode || (exports.PrefListOrderCreateMode = {}));
var ProcessStatus;
(function (ProcessStatus) {
    ProcessStatus[ProcessStatus["Done"] = 0] = "Done";
    ProcessStatus[ProcessStatus["Running"] = 1] = "Running";
    ProcessStatus[ProcessStatus["Initiated"] = 2] = "Initiated";
    ProcessStatus[ProcessStatus["Ending"] = 3] = "Ending";
})(ProcessStatus = exports.ProcessStatus || (exports.ProcessStatus = {}));
var ProcessType;
(function (ProcessType) {
    ProcessType[ProcessType["None"] = 0] = "None";
    ProcessType[ProcessType["Pack"] = 1] = "Pack";
    ProcessType[ProcessType["SteriPostBatch"] = 2] = "SteriPostBatch";
    ProcessType[ProcessType["Out"] = 3] = "Out";
    ProcessType[ProcessType["Return"] = 4] = "Return";
    ProcessType[ProcessType["Prepare"] = 5] = "Prepare";
    ProcessType[ProcessType["Wrap"] = 6] = "Wrap";
    ProcessType[ProcessType["LitePro"] = 7] = "LitePro";
    ProcessType[ProcessType["SteriPreBatch"] = 8] = "SteriPreBatch";
    ProcessType[ProcessType["Flash"] = 9] = "Flash";
    ProcessType[ProcessType["Location"] = 10] = "Location";
    ProcessType[ProcessType["WashPreBatch"] = 11] = "WashPreBatch";
    ProcessType[ProcessType["WashPostBatch"] = 12] = "WashPostBatch";
    ProcessType[ProcessType["OpenUsed"] = 13] = "OpenUsed";
    ProcessType[ProcessType["Operation"] = 14] = "Operation";
    ProcessType[ProcessType["Order"] = 15] = "Order";
    ProcessType[ProcessType["Inventory"] = 16] = "Inventory";
    ProcessType[ProcessType["OrderPick"] = 17] = "OrderPick";
    ProcessType[ProcessType["Industry"] = 18] = "Industry";
    ProcessType[ProcessType["PreDisPreBatch"] = 19] = "PreDisPreBatch";
    ProcessType[ProcessType["LoadQueue"] = 20] = "LoadQueue";
    ProcessType[ProcessType["PurchaseOrder"] = 21] = "PurchaseOrder";
    ProcessType[ProcessType["EndoscopePreBatch"] = 22] = "EndoscopePreBatch";
    ProcessType[ProcessType["WashCheck"] = 23] = "WashCheck";
    ProcessType[ProcessType["ExtBatchInfo"] = 24] = "ExtBatchInfo";
    ProcessType[ProcessType["EndoCleanAndTest"] = 25] = "EndoCleanAndTest";
    ProcessType[ProcessType["Transport"] = 26] = "Transport";
    ProcessType[ProcessType["Workflow"] = 27] = "Workflow";
    ProcessType[ProcessType["Incubator"] = 28] = "Incubator";
    ProcessType[ProcessType["ReturnWF"] = 29] = "ReturnWF";
    ProcessType[ProcessType["WashPreBatchWF"] = 30] = "WashPreBatchWF";
    ProcessType[ProcessType["SteriPreBatchWF"] = 31] = "SteriPreBatchWF";
    ProcessType[ProcessType["PackWF"] = 32] = "PackWF";
    ProcessType[ProcessType["OutWF"] = 33] = "OutWF";
    ProcessType[ProcessType["Last"] = 34] = "Last";
})(ProcessType = exports.ProcessType || (exports.ProcessType = {}));
var ProdDispatchRestriction;
(function (ProdDispatchRestriction) {
    ProdDispatchRestriction[ProdDispatchRestriction["Default"] = 0] = "Default";
    ProdDispatchRestriction[ProdDispatchRestriction["None"] = 1] = "None";
    ProdDispatchRestriction[ProdDispatchRestriction["CustomerOrSite"] = 2] = "CustomerOrSite";
    ProdDispatchRestriction[ProdDispatchRestriction["CustomerElseSite"] = 3] = "CustomerElseSite";
    ProdDispatchRestriction[ProdDispatchRestriction["CustomerOnly"] = 4] = "CustomerOnly";
})(ProdDispatchRestriction = exports.ProdDispatchRestriction || (exports.ProdDispatchRestriction = {}));
var ProdOverviewAvailability;
(function (ProdOverviewAvailability) {
    ProdOverviewAvailability[ProdOverviewAvailability["AsDefinedForUsers"] = 0] = "AsDefinedForUsers";
    ProdOverviewAvailability[ProdOverviewAvailability["Always"] = 1] = "Always";
    ProdOverviewAvailability[ProdOverviewAvailability["Never"] = 2] = "Never";
})(ProdOverviewAvailability = exports.ProdOverviewAvailability || (exports.ProdOverviewAvailability = {}));
var ProdOverviewVisibilityMode;
(function (ProdOverviewVisibilityMode) {
    ProdOverviewVisibilityMode[ProdOverviewVisibilityMode["None"] = 0] = "None";
    ProdOverviewVisibilityMode[ProdOverviewVisibilityMode["AvailableInWeb"] = 1] = "AvailableInWeb";
    ProdOverviewVisibilityMode[ProdOverviewVisibilityMode["NotAvailableInWeb"] = 2] = "NotAvailableInWeb";
})(ProdOverviewVisibilityMode = exports.ProdOverviewVisibilityMode || (exports.ProdOverviewVisibilityMode = {}));
var ProdReservationMode;
(function (ProdReservationMode) {
    ProdReservationMode[ProdReservationMode["NotRequired"] = 0] = "NotRequired";
    ProdReservationMode[ProdReservationMode["Enabled"] = 1] = "Enabled";
})(ProdReservationMode = exports.ProdReservationMode || (exports.ProdReservationMode = {}));
var ProductDifficultyMode;
(function (ProductDifficultyMode) {
    ProductDifficultyMode[ProductDifficultyMode["UseItemValue"] = 0] = "UseItemValue";
    ProductDifficultyMode[ProductDifficultyMode["Fixed"] = 1] = "Fixed";
})(ProductDifficultyMode = exports.ProductDifficultyMode || (exports.ProductDifficultyMode = {}));
var ProductLine;
(function (ProductLine) {
    ProductLine[ProductLine["Cycle"] = 0] = "Cycle";
    ProductLine[ProductLine["CSM"] = 1] = "CSM";
    ProductLine[ProductLine["CSMLabel"] = 2] = "CSMLabel";
    ProductLine[ProductLine["SDM"] = 3] = "SDM";
    ProductLine[ProductLine["TDOC2000"] = 4] = "TDOC2000";
    ProductLine[ProductLine["EndoCycle"] = 5] = "EndoCycle";
    ProductLine[ProductLine["EndoTrace"] = 6] = "EndoTrace";
    ProductLine[ProductLine["Standalone"] = 7] = "Standalone";
    ProductLine[ProductLine["Select"] = 8] = "Select";
})(ProductLine = exports.ProductLine || (exports.ProductLine = {}));
var ProductLocateSection;
(function (ProductLocateSection) {
    ProductLocateSection[ProductLocateSection["Ready"] = 0] = "Ready";
    ProductLocateSection[ProductLocateSection["Packed"] = 1] = "Packed";
    ProductLocateSection[ProductLocateSection["Returned"] = 2] = "Returned";
    ProductLocateSection[ProductLocateSection["Used"] = 3] = "Used";
    ProductLocateSection[ProductLocateSection["Repair"] = 4] = "Repair";
    ProductLocateSection[ProductLocateSection["Other"] = 5] = "Other";
    ProductLocateSection[ProductLocateSection["All"] = 6] = "All";
})(ProductLocateSection = exports.ProductLocateSection || (exports.ProductLocateSection = {}));
var ProductReservationType;
(function (ProductReservationType) {
    ProductReservationType[ProductReservationType["Normal"] = 0] = "Normal";
    ProductReservationType[ProductReservationType["NotOrdered"] = 1] = "NotOrdered";
    ProductReservationType[ProductReservationType["PickedFromLineSpec"] = 2] = "PickedFromLineSpec";
})(ProductReservationType = exports.ProductReservationType || (exports.ProductReservationType = {}));
var ProductReturnState;
(function (ProductReturnState) {
    ProductReturnState[ProductReturnState["Normal"] = 0] = "Normal";
    ProductReturnState[ProductReturnState["PreProcessed"] = 1] = "PreProcessed";
    ProductReturnState[ProductReturnState["PrePacked"] = 2] = "PrePacked";
})(ProductReturnState = exports.ProductReturnState || (exports.ProductReturnState = {}));
var ProductStockControlMode;
(function (ProductStockControlMode) {
    ProductStockControlMode[ProductStockControlMode["Simple"] = 0] = "Simple";
    ProductStockControlMode[ProductStockControlMode["Basic"] = 1] = "Basic";
    ProductStockControlMode[ProductStockControlMode["Advanced"] = 2] = "Advanced";
})(ProductStockControlMode = exports.ProductStockControlMode || (exports.ProductStockControlMode = {}));
var ProductStorageType;
(function (ProductStorageType) {
    ProductStorageType[ProductStorageType["Factory"] = 0] = "Factory";
    ProductStorageType[ProductStorageType["Transit"] = 1] = "Transit";
})(ProductStorageType = exports.ProductStorageType || (exports.ProductStorageType = {}));
var ProductTraceType;
(function (ProductTraceType) {
    ProductTraceType[ProductTraceType["Product"] = 0] = "Product";
    ProductTraceType[ProductTraceType["ProductSerial"] = 1] = "ProductSerial";
    ProductTraceType[ProductTraceType["Both"] = 2] = "Both";
})(ProductTraceType = exports.ProductTraceType || (exports.ProductTraceType = {}));
var ProductType;
(function (ProductType) {
    ProductType[ProductType["Trays"] = 0] = "Trays";
    ProductType[ProductType["SinglePacks"] = 1] = "SinglePacks";
    ProductType[ProductType["Endoscopes"] = 2] = "Endoscopes";
})(ProductType = exports.ProductType || (exports.ProductType = {}));
var RepairMode;
(function (RepairMode) {
    RepairMode[RepairMode["AskUser"] = 0] = "AskUser";
    RepairMode[RepairMode["UsingCust"] = 1] = "UsingCust";
    RepairMode[RepairMode["Owner"] = 2] = "Owner";
    RepairMode[RepairMode["Factory"] = 3] = "Factory";
})(RepairMode = exports.RepairMode || (exports.RepairMode = {}));
var ReservationState;
(function (ReservationState) {
    ReservationState[ReservationState["Undetermined"] = 0] = "Undetermined";
    ReservationState[ReservationState["OKOriginal"] = 1] = "OKOriginal";
    ReservationState[ReservationState["OKIdentical"] = 2] = "OKIdentical";
    ReservationState[ReservationState["OKAlternative"] = 3] = "OKAlternative";
    ReservationState[ReservationState["OKIdenticalForAlternative"] = 4] = "OKIdenticalForAlternative";
    ReservationState[ReservationState["WarningOriginal"] = 5] = "WarningOriginal";
    ReservationState[ReservationState["WarningIdentical"] = 6] = "WarningIdentical";
    ReservationState[ReservationState["WarningAlternative"] = 7] = "WarningAlternative";
    ReservationState[ReservationState["WarningIdenticalForAlternative"] = 8] = "WarningIdenticalForAlternative";
    ReservationState[ReservationState["Error"] = 9] = "Error";
})(ReservationState = exports.ReservationState || (exports.ReservationState = {}));
var ReservationStatus;
(function (ReservationStatus) {
    ReservationStatus[ReservationStatus["Active"] = 0] = "Active";
    ReservationStatus[ReservationStatus["Completed"] = 1] = "Completed";
    ReservationStatus[ReservationStatus["Failed"] = 2] = "Failed";
    ReservationStatus[ReservationStatus["Cancelled"] = 3] = "Cancelled";
})(ReservationStatus = exports.ReservationStatus || (exports.ReservationStatus = {}));
var ReservFlag;
(function (ReservFlag) {
    ReservFlag[ReservFlag["ResvNormal"] = 0] = "ResvNormal";
    ReservFlag[ReservFlag["ResvForce"] = 1] = "ResvForce";
})(ReservFlag = exports.ReservFlag || (exports.ReservFlag = {}));
var ReservLogType;
(function (ReservLogType) {
    ReservLogType[ReservLogType["Normal"] = 0] = "Normal";
    ReservLogType[ReservLogType["Warning"] = 1] = "Warning";
    ReservLogType[ReservLogType["Error"] = 2] = "Error";
})(ReservLogType = exports.ReservLogType || (exports.ReservLogType = {}));
var ScanUIStartMode;
(function (ScanUIStartMode) {
    ScanUIStartMode[ScanUIStartMode["Default"] = 0] = "Default";
    ScanUIStartMode[ScanUIStartMode["Layers"] = 1] = "Layers";
    ScanUIStartMode[ScanUIStartMode["Grid"] = 2] = "Grid";
})(ScanUIStartMode = exports.ScanUIStartMode || (exports.ScanUIStartMode = {}));
var SearchAutoPictureMode;
(function (SearchAutoPictureMode) {
    SearchAutoPictureMode[SearchAutoPictureMode["Off"] = 0] = "Off";
    SearchAutoPictureMode[SearchAutoPictureMode["On"] = 1] = "On";
})(SearchAutoPictureMode = exports.SearchAutoPictureMode || (exports.SearchAutoPictureMode = {}));
var SearchResultOrigin;
(function (SearchResultOrigin) {
    SearchResultOrigin[SearchResultOrigin["Local"] = 0] = "Local";
    SearchResultOrigin[SearchResultOrigin["JobSQL"] = 1] = "JobSQL";
    SearchResultOrigin[SearchResultOrigin["JobFuzzy"] = 2] = "JobFuzzy";
})(SearchResultOrigin = exports.SearchResultOrigin || (exports.SearchResultOrigin = {}));
var SexType;
(function (SexType) {
    SexType[SexType["None"] = 0] = "None";
    SexType[SexType["Male"] = 1] = "Male";
    SexType[SexType["Female"] = 2] = "Female";
    SexType[SexType["Other"] = 3] = "Other";
})(SexType = exports.SexType || (exports.SexType = {}));
var SpecialSearchType;
(function (SpecialSearchType) {
    SpecialSearchType[SpecialSearchType["None"] = 0] = "None";
    SpecialSearchType[SpecialSearchType["ItemSingle"] = 1] = "ItemSingle";
    SpecialSearchType[SpecialSearchType["ItemComposite"] = 2] = "ItemComposite";
    SpecialSearchType[SpecialSearchType["CustomerUnits"] = 3] = "CustomerUnits";
    SpecialSearchType[SpecialSearchType["ProductsBasedOnItem"] = 4] = "ProductsBasedOnItem";
    SpecialSearchType[SpecialSearchType["ButtonValve"] = 5] = "ButtonValve";
    SpecialSearchType[SpecialSearchType["WebBaseProdItem"] = 6] = "WebBaseProdItem";
    SpecialSearchType[SpecialSearchType["WebProdWithSerials"] = 7] = "WebProdWithSerials";
    SpecialSearchType[SpecialSearchType["PrefLists"] = 8] = "PrefLists";
    SpecialSearchType[SpecialSearchType["ItemSerial"] = 9] = "ItemSerial";
    SpecialSearchType[SpecialSearchType["ProductSerial"] = 10] = "ProductSerial";
    SpecialSearchType[SpecialSearchType["Workstation"] = 11] = "Workstation";
    SpecialSearchType[SpecialSearchType["IncubatorLog"] = 12] = "IncubatorLog";
    SpecialSearchType[SpecialSearchType["TriggerMerge"] = 13] = "TriggerMerge";
    SpecialSearchType[SpecialSearchType["WebUnits"] = 14] = "WebUnits";
    SpecialSearchType[SpecialSearchType["WebCompositeItemList"] = 15] = "WebCompositeItemList";
    SpecialSearchType[SpecialSearchType["WebProductsBasedOnItem"] = 16] = "WebProductsBasedOnItem";
    SpecialSearchType[SpecialSearchType["WebScheduledOperation"] = 17] = "WebScheduledOperation";
    SpecialSearchType[SpecialSearchType["WebOperationItems"] = 18] = "WebOperationItems";
    SpecialSearchType[SpecialSearchType["WebOperationProducts"] = 19] = "WebOperationProducts";
    SpecialSearchType[SpecialSearchType["WebOperationUnits"] = 20] = "WebOperationUnits";
})(SpecialSearchType = exports.SpecialSearchType || (exports.SpecialSearchType = {}));
var StdCommentFreeTextOption;
(function (StdCommentFreeTextOption) {
    StdCommentFreeTextOption[StdCommentFreeTextOption["NotAllowed"] = 0] = "NotAllowed";
    StdCommentFreeTextOption[StdCommentFreeTextOption["Allowed"] = 1] = "Allowed";
    StdCommentFreeTextOption[StdCommentFreeTextOption["Required"] = 2] = "Required";
})(StdCommentFreeTextOption = exports.StdCommentFreeTextOption || (exports.StdCommentFreeTextOption = {}));
var StdCommentShowInProdOption;
(function (StdCommentShowInProdOption) {
    StdCommentShowInProdOption[StdCommentShowInProdOption["No"] = 0] = "No";
    StdCommentShowInProdOption[StdCommentShowInProdOption["Yes"] = 1] = "Yes";
})(StdCommentShowInProdOption = exports.StdCommentShowInProdOption || (exports.StdCommentShowInProdOption = {}));
var StockBackOrderMode;
(function (StockBackOrderMode) {
    StockBackOrderMode[StockBackOrderMode["Never"] = 0] = "Never";
    StockBackOrderMode[StockBackOrderMode["Always"] = 1] = "Always";
    StockBackOrderMode[StockBackOrderMode["Ask"] = 2] = "Ask";
    StockBackOrderMode[StockBackOrderMode["WhenSet"] = 3] = "WhenSet";
    StockBackOrderMode[StockBackOrderMode["WhenSetAuto"] = 4] = "WhenSetAuto";
})(StockBackOrderMode = exports.StockBackOrderMode || (exports.StockBackOrderMode = {}));
var StockOrderInMode;
(function (StockOrderInMode) {
    StockOrderInMode[StockOrderInMode["Auto"] = 0] = "Auto";
    StockOrderInMode[StockOrderInMode["Receive"] = 1] = "Receive";
    StockOrderInMode[StockOrderInMode["Manual"] = 2] = "Manual";
})(StockOrderInMode = exports.StockOrderInMode || (exports.StockOrderInMode = {}));
var StockPrnMode;
(function (StockPrnMode) {
    StockPrnMode[StockPrnMode["Never"] = 0] = "Never";
    StockPrnMode[StockPrnMode["Always"] = 1] = "Always";
    StockPrnMode[StockPrnMode["Ask"] = 2] = "Ask";
})(StockPrnMode = exports.StockPrnMode || (exports.StockPrnMode = {}));
var StockType;
(function (StockType) {
    StockType[StockType["Factory"] = 0] = "Factory";
    StockType[StockType["Cust"] = 1] = "Cust";
    StockType[StockType["Supplier"] = 2] = "Supplier";
    StockType[StockType["Mobile"] = 3] = "Mobile";
})(StockType = exports.StockType || (exports.StockType = {}));
var SubstitutionArticleType;
(function (SubstitutionArticleType) {
    SubstitutionArticleType[SubstitutionArticleType["Item"] = 0] = "Item";
    SubstitutionArticleType[SubstitutionArticleType["Product"] = 1] = "Product";
})(SubstitutionArticleType = exports.SubstitutionArticleType || (exports.SubstitutionArticleType = {}));
var SubstitutionReason;
(function (SubstitutionReason) {
    SubstitutionReason[SubstitutionReason["None"] = 0] = "None";
    SubstitutionReason[SubstitutionReason["Identical"] = 1] = "Identical";
    SubstitutionReason[SubstitutionReason["Alternative"] = 2] = "Alternative";
    SubstitutionReason[SubstitutionReason["IdenticalForAlternative"] = 3] = "IdenticalForAlternative";
})(SubstitutionReason = exports.SubstitutionReason || (exports.SubstitutionReason = {}));
var SubstMode;
(function (SubstMode) {
    SubstMode[SubstMode["Identical"] = 0] = "Identical";
    SubstMode[SubstMode["Alternative"] = 1] = "Alternative";
})(SubstMode = exports.SubstMode || (exports.SubstMode = {}));
var TableTypes;
(function (TableTypes) {
    TableTypes[TableTypes["None"] = 0] = "None";
    TableTypes[TableTypes["Product"] = 1] = "Product";
    TableTypes[TableTypes["Serial"] = 2] = "Serial";
    TableTypes[TableTypes["Item"] = 3] = "Item";
    TableTypes[TableTypes["Machine"] = 4] = "Machine";
    TableTypes[TableTypes["Supplier"] = 5] = "Supplier";
    TableTypes[TableTypes["Customer"] = 6] = "Customer";
    TableTypes[TableTypes["System"] = 7] = "System";
    TableTypes[TableTypes["Unit"] = 8] = "Unit";
    TableTypes[TableTypes["Text"] = 9] = "Text";
    TableTypes[TableTypes["Factory"] = 10] = "Factory";
    TableTypes[TableTypes["Lot_OBSOLETE"] = 11] = "Lot_OBSOLETE";
    TableTypes[TableTypes["Stocks"] = 12] = "Stocks";
    TableTypes[TableTypes["User"] = 13] = "User";
    TableTypes[TableTypes["Process"] = 14] = "Process";
    TableTypes[TableTypes["LotTransAct_OBSOLETE"] = 15] = "LotTransAct_OBSOLETE";
    TableTypes[TableTypes["Location"] = 16] = "Location";
    TableTypes[TableTypes["RushPlan"] = 17] = "RushPlan";
    TableTypes[TableTypes["OperationData"] = 18] = "OperationData";
    TableTypes[TableTypes["OperationType"] = 19] = "OperationType";
    TableTypes[TableTypes["ProcedureType"] = 20] = "ProcedureType";
    TableTypes[TableTypes["Program"] = 21] = "Program";
    TableTypes[TableTypes["ProgramRecipe"] = 22] = "ProgramRecipe";
    TableTypes[TableTypes["Catalog"] = 23] = "Catalog";
    TableTypes[TableTypes["Patient"] = 24] = "Patient";
    TableTypes[TableTypes["Order"] = 25] = "Order";
    TableTypes[TableTypes["MachineType"] = 26] = "MachineType";
    TableTypes[TableTypes["ProgramGroup"] = 27] = "ProgramGroup";
    TableTypes[TableTypes["RepairType"] = 28] = "RepairType";
    TableTypes[TableTypes["Repair"] = 29] = "Repair";
    TableTypes[TableTypes["Doctor"] = 30] = "Doctor";
    TableTypes[TableTypes["IndicatorType"] = 31] = "IndicatorType";
    TableTypes[TableTypes["Room"] = 32] = "Room";
    TableTypes[TableTypes["Pack"] = 33] = "Pack";
    TableTypes[TableTypes["FastTrackCode"] = 34] = "FastTrackCode";
    TableTypes[TableTypes["FastTrackPlan"] = 35] = "FastTrackPlan";
    TableTypes[TableTypes["FastTrackGroup"] = 36] = "FastTrackGroup";
    TableTypes[TableTypes["WashCheckType"] = 37] = "WashCheckType";
    TableTypes[TableTypes["InfoOverviewConfig"] = 38] = "InfoOverviewConfig";
    TableTypes[TableTypes["Indicator"] = 39] = "Indicator";
    TableTypes[TableTypes["TransportMethod"] = 40] = "TransportMethod";
    TableTypes[TableTypes["HandlingStep"] = 41] = "HandlingStep";
    TableTypes[TableTypes["LocationGroup"] = 42] = "LocationGroup";
    TableTypes[TableTypes["CustSite"] = 43] = "CustSite";
    TableTypes[TableTypes["Tags"] = 44] = "Tags";
    TableTypes[TableTypes["DryerProcess"] = 45] = "DryerProcess";
    TableTypes[TableTypes["StandardComment"] = 46] = "StandardComment";
    TableTypes[TableTypes["AGS"] = 47] = "AGS";
    TableTypes[TableTypes["TagType"] = 48] = "TagType";
    TableTypes[TableTypes["Trigger"] = 49] = "Trigger";
    TableTypes[TableTypes["ScannerMacro"] = 50] = "ScannerMacro";
})(TableTypes = exports.TableTypes || (exports.TableTypes = {}));
var TDOCAppType;
(function (TDOCAppType) {
    TDOCAppType[TDOCAppType["Unknown"] = 0] = "Unknown";
    TDOCAppType[TDOCAppType["AppServer"] = 1] = "AppServer";
    TDOCAppType[TDOCAppType["ScannerServer"] = 2] = "ScannerServer";
    TDOCAppType[TDOCAppType["ConnectServer"] = 3] = "ConnectServer";
    TDOCAppType[TDOCAppType["TDOCCtrl"] = 4] = "TDOCCtrl";
    TDOCAppType[TDOCAppType["Admin"] = 5] = "Admin";
    TDOCAppType[TDOCAppType["Scanner"] = 6] = "Scanner";
    TDOCAppType[TDOCAppType["Connect"] = 7] = "Connect";
    TDOCAppType[TDOCAppType["Spool_Obsolete"] = 8] = "Spool_Obsolete";
    TDOCAppType[TDOCAppType["Launcher"] = 9] = "Launcher";
    TDOCAppType[TDOCAppType["Exchange"] = 10] = "Exchange";
    TDOCAppType[TDOCAppType["MacView"] = 11] = "MacView";
    TDOCAppType[TDOCAppType["Service"] = 12] = "Service";
    TDOCAppType[TDOCAppType["PrintServer"] = 13] = "PrintServer";
    TDOCAppType[TDOCAppType["RemoteControl_Obsolete"] = 14] = "RemoteControl_Obsolete";
    TDOCAppType[TDOCAppType["JobServer_Obsolete"] = 15] = "JobServer_Obsolete";
    TDOCAppType[TDOCAppType["ProfEdit"] = 16] = "ProfEdit";
    TDOCAppType[TDOCAppType["DeviceTest"] = 17] = "DeviceTest";
    TDOCAppType[TDOCAppType["MacSimu"] = 18] = "MacSimu";
    TDOCAppType[TDOCAppType["IniEdit"] = 19] = "IniEdit";
    TDOCAppType[TDOCAppType["NetTest"] = 20] = "NetTest";
    TDOCAppType[TDOCAppType["UtilityServer"] = 21] = "UtilityServer";
    TDOCAppType[TDOCAppType["SpoolServer"] = 22] = "SpoolServer";
    TDOCAppType[TDOCAppType["SpoolCtrl"] = 23] = "SpoolCtrl";
    TDOCAppType[TDOCAppType["NetCOMCfg"] = 24] = "NetCOMCfg";
    TDOCAppType[TDOCAppType["JobCfg"] = 25] = "JobCfg";
    TDOCAppType[TDOCAppType["Panel"] = 26] = "Panel";
    TDOCAppType[TDOCAppType["JobServer"] = 27] = "JobServer";
    TDOCAppType[TDOCAppType["WebServer"] = 28] = "WebServer";
    TDOCAppType[TDOCAppType["Engine"] = 29] = "Engine";
    TDOCAppType[TDOCAppType["Upgrade"] = 30] = "Upgrade";
    TDOCAppType[TDOCAppType["UnknownL"] = 31] = "UnknownL";
})(TDOCAppType = exports.TDOCAppType || (exports.TDOCAppType = {}));
var TDOCTable;
(function (TDOCTable) {
    TDOCTable[TDOCTable["None"] = 0] = "None";
    TDOCTable[TDOCTable["Text"] = 1] = "Text";
    TDOCTable[TDOCTable["Pack"] = 2] = "Pack";
    TDOCTable[TDOCTable["PictureRef"] = 3] = "PictureRef";
    TDOCTable[TDOCTable["Program"] = 4] = "Program";
    TDOCTable[TDOCTable["User"] = 5] = "User";
    TDOCTable[TDOCTable["MachineType"] = 6] = "MachineType";
    TDOCTable[TDOCTable["Machine"] = 7] = "Machine";
    TDOCTable[TDOCTable["Cust"] = 8] = "Cust";
    TDOCTable[TDOCTable["Item"] = 9] = "Item";
    TDOCTable[TDOCTable["Product"] = 10] = "Product";
    TDOCTable[TDOCTable["System"] = 11] = "System";
    TDOCTable[TDOCTable["Composite"] = 12] = "Composite";
    TDOCTable[TDOCTable["Stock"] = 13] = "Stock";
    TDOCTable[TDOCTable["Process"] = 14] = "Process";
    TDOCTable[TDOCTable["Unit"] = 15] = "Unit";
    TDOCTable[TDOCTable["Event"] = 16] = "Event";
    TDOCTable[TDOCTable["Serial"] = 17] = "Serial";
    TDOCTable[TDOCTable["Trigger"] = 18] = "Trigger";
    TDOCTable[TDOCTable["Supplier"] = 19] = "Supplier";
    TDOCTable[TDOCTable["CustTransAct"] = 20] = "CustTransAct";
    TDOCTable[TDOCTable["Pics"] = 21] = "Pics";
    TDOCTable[TDOCTable["BcTp"] = 22] = "BcTp";
    TDOCTable[TDOCTable["Contact"] = 23] = "Contact";
    TDOCTable[TDOCTable["Profile"] = 24] = "Profile";
    TDOCTable[TDOCTable["ItemGrp"] = 25] = "ItemGrp";
    TDOCTable[TDOCTable["Delta"] = 26] = "Delta";
    TDOCTable[TDOCTable["Position"] = 27] = "Position";
    TDOCTable[TDOCTable["Patient"] = 28] = "Patient";
    TDOCTable[TDOCTable["PatientCons"] = 29] = "PatientCons";
    TDOCTable[TDOCTable["UnitLocation"] = 30] = "UnitLocation";
    TDOCTable[TDOCTable["UnitList"] = 31] = "UnitList";
    TDOCTable[TDOCTable["Repair"] = 32] = "Repair";
    TDOCTable[TDOCTable["RepairType"] = 33] = "RepairType";
    TDOCTable[TDOCTable["Stocks"] = 34] = "Stocks";
    TDOCTable[TDOCTable["Factory"] = 35] = "Factory";
    TDOCTable[TDOCTable["Location"] = 36] = "Location";
    TDOCTable[TDOCTable["ProgGroups"] = 37] = "ProgGroups";
    TDOCTable[TDOCTable["ProgGroup"] = 38] = "ProgGroup";
    TDOCTable[TDOCTable["Report"] = 39] = "Report";
    TDOCTable[TDOCTable["ReportDef"] = 40] = "ReportDef";
    TDOCTable[TDOCTable["RepSelection"] = 41] = "RepSelection";
    TDOCTable[TDOCTable["ItemSerialUsage"] = 42] = "ItemSerialUsage";
    TDOCTable[TDOCTable["Order"] = 43] = "Order";
    TDOCTable[TDOCTable["OrderLine"] = 44] = "OrderLine";
    TDOCTable[TDOCTable["OrderLineSpec"] = 45] = "OrderLineSpec";
    TDOCTable[TDOCTable["PosLocation"] = 46] = "PosLocation";
    TDOCTable[TDOCTable["PrnDrv"] = 47] = "PrnDrv";
    TDOCTable[TDOCTable["Audit"] = 48] = "Audit";
    TDOCTable[TDOCTable["Defaults"] = 49] = "Defaults";
    TDOCTable[TDOCTable["History"] = 50] = "History";
    TDOCTable[TDOCTable["TriggerLog"] = 51] = "TriggerLog";
    TDOCTable[TDOCTable["OperationType"] = 52] = "OperationType";
    TDOCTable[TDOCTable["ProcedureType"] = 53] = "ProcedureType";
    TDOCTable[TDOCTable["ProcedureTypes"] = 54] = "ProcedureTypes";
    TDOCTable[TDOCTable["ProcedureData"] = 55] = "ProcedureData";
    TDOCTable[TDOCTable["OperationData"] = 56] = "OperationData";
    TDOCTable[TDOCTable["Doctor"] = 57] = "Doctor";
    TDOCTable[TDOCTable["OrderSuggestion"] = 58] = "OrderSuggestion";
    TDOCTable[TDOCTable["OrderLineSugg"] = 59] = "OrderLineSugg";
    TDOCTable[TDOCTable["OrderTemplate"] = 60] = "OrderTemplate";
    TDOCTable[TDOCTable["OrderLineTemp"] = 61] = "OrderLineTemp";
    TDOCTable[TDOCTable["DefaultsBlob"] = 62] = "DefaultsBlob";
    TDOCTable[TDOCTable["Batch"] = 63] = "Batch";
    TDOCTable[TDOCTable["ProgramParam"] = 64] = "ProgramParam";
    TDOCTable[TDOCTable["ProgramRecipe"] = 65] = "ProgramRecipe";
    TDOCTable[TDOCTable["ProgramRecipeParam"] = 66] = "ProgramRecipeParam";
    TDOCTable[TDOCTable["Catalog"] = 67] = "Catalog";
    TDOCTable[TDOCTable["CatRef"] = 68] = "CatRef";
    TDOCTable[TDOCTable["AGS"] = 69] = "AGS";
    TDOCTable[TDOCTable["AGSQueue"] = 70] = "AGSQueue";
    TDOCTable[TDOCTable["Room"] = 71] = "Room";
    TDOCTable[TDOCTable["Barcodes"] = 72] = "Barcodes";
    TDOCTable[TDOCTable["MacLEq"] = 73] = "MacLEq";
    TDOCTable[TDOCTable["LoadEquip"] = 74] = "LoadEquip";
    TDOCTable[TDOCTable["Notify"] = 75] = "Notify";
    TDOCTable[TDOCTable["RoomCustomer"] = 76] = "RoomCustomer";
    TDOCTable[TDOCTable["ItemSpc"] = 77] = "ItemSpc";
    TDOCTable[TDOCTable["ItemUOM"] = 78] = "ItemUOM";
    TDOCTable[TDOCTable["MachineLog"] = 79] = "MachineLog";
    TDOCTable[TDOCTable["ProcessLog"] = 80] = "ProcessLog";
    TDOCTable[TDOCTable["SplitTag"] = 81] = "SplitTag";
    TDOCTable[TDOCTable["SealerProgram"] = 82] = "SealerProgram";
    TDOCTable[TDOCTable["SealerProcess"] = 83] = "SealerProcess";
    TDOCTable[TDOCTable["PrinterLocation"] = 84] = "PrinterLocation";
    TDOCTable[TDOCTable["UserGroups"] = 85] = "UserGroups";
    TDOCTable[TDOCTable["UserGroupMembers"] = 86] = "UserGroupMembers";
    TDOCTable[TDOCTable["ESignatureDefs"] = 87] = "ESignatureDefs";
    TDOCTable[TDOCTable["ESignatures"] = 88] = "ESignatures";
    TDOCTable[TDOCTable["InputDefs_NOT_USED"] = 89] = "InputDefs_NOT_USED";
    TDOCTable[TDOCTable["InputReply"] = 90] = "InputReply";
    TDOCTable[TDOCTable["PrnRedirect"] = 91] = "PrnRedirect";
    TDOCTable[TDOCTable["StockGroup"] = 92] = "StockGroup";
    TDOCTable[TDOCTable["StockGroupRef"] = 93] = "StockGroupRef";
    TDOCTable[TDOCTable["FactoryItems"] = 94] = "FactoryItems";
    TDOCTable[TDOCTable["FactoryPackings"] = 95] = "FactoryPackings";
    TDOCTable[TDOCTable["FactoryProducts"] = 96] = "FactoryProducts";
    TDOCTable[TDOCTable["FactoryUsers"] = 97] = "FactoryUsers";
    TDOCTable[TDOCTable["FactoryCustomers"] = 98] = "FactoryCustomers";
    TDOCTable[TDOCTable["FactoryItemGrps"] = 99] = "FactoryItemGrps";
    TDOCTable[TDOCTable["FactoryItemSpcs"] = 100] = "FactoryItemSpcs";
    TDOCTable[TDOCTable["FactorySuppliers"] = 101] = "FactorySuppliers";
    TDOCTable[TDOCTable["RepSecurityGrp"] = 102] = "RepSecurityGrp";
    TDOCTable[TDOCTable["SPOSList"] = 103] = "SPOSList";
    TDOCTable[TDOCTable["ADGroups"] = 104] = "ADGroups";
    TDOCTable[TDOCTable["RepSecGrpMembers"] = 105] = "RepSecGrpMembers";
    TDOCTable[TDOCTable["IESystems"] = 106] = "IESystems";
    TDOCTable[TDOCTable["Job"] = 107] = "Job";
    TDOCTable[TDOCTable["ChamberPlacements"] = 108] = "ChamberPlacements";
    TDOCTable[TDOCTable["OrderInfo"] = 109] = "OrderInfo";
    TDOCTable[TDOCTable["LotHc"] = 110] = "LotHc";
    TDOCTable[TDOCTable["EndoDryingMethods"] = 111] = "EndoDryingMethods";
    TDOCTable[TDOCTable["FixedStockPlacements"] = 112] = "FixedStockPlacements";
    TDOCTable[TDOCTable["UnitWeightReg"] = 113] = "UnitWeightReg";
    TDOCTable[TDOCTable["UnitWeightInfo"] = 114] = "UnitWeightInfo";
    TDOCTable[TDOCTable["WeightPlan"] = 115] = "WeightPlan";
    TDOCTable[TDOCTable["WeightPlanEntries"] = 116] = "WeightPlanEntries";
    TDOCTable[TDOCTable["LocationGroup"] = 117] = "LocationGroup";
    TDOCTable[TDOCTable["LocationGroupMembers"] = 118] = "LocationGroupMembers";
    TDOCTable[TDOCTable["FastTrackRules"] = 119] = "FastTrackRules";
    TDOCTable[TDOCTable["FastTrackRulesScope"] = 120] = "FastTrackRulesScope";
    TDOCTable[TDOCTable["FastTrackRulesSelection"] = 121] = "FastTrackRulesSelection";
    TDOCTable[TDOCTable["FastTrackCode"] = 122] = "FastTrackCode";
    TDOCTable[TDOCTable["FastTrackPlan"] = 123] = "FastTrackPlan";
    TDOCTable[TDOCTable["FastTrackPlanSteps"] = 124] = "FastTrackPlanSteps";
    TDOCTable[TDOCTable["FastTrackGroup"] = 125] = "FastTrackGroup";
    TDOCTable[TDOCTable["FastTrackGroupCodes"] = 126] = "FastTrackGroupCodes";
    TDOCTable[TDOCTable["UnitFastTrack"] = 127] = "UnitFastTrack";
    TDOCTable[TDOCTable["UnitFastTrackInvoice"] = 128] = "UnitFastTrackInvoice";
    TDOCTable[TDOCTable["UnitFastTrackPlan"] = 129] = "UnitFastTrackPlan";
    TDOCTable[TDOCTable["WashCheck"] = 130] = "WashCheck";
    TDOCTable[TDOCTable["WashCheckType"] = 131] = "WashCheckType";
    TDOCTable[TDOCTable["Desktop"] = 132] = "Desktop";
    TDOCTable[TDOCTable["WeightValidationOverride"] = 133] = "WeightValidationOverride";
    TDOCTable[TDOCTable["PriceGroup"] = 134] = "PriceGroup";
    TDOCTable[TDOCTable["CustomerUsers"] = 135] = "CustomerUsers";
    TDOCTable[TDOCTable["OrderTemplateType"] = 136] = "OrderTemplateType";
    TDOCTable[TDOCTable["MachConsumables"] = 137] = "MachConsumables";
    TDOCTable[TDOCTable["InfoOverviewConfig"] = 138] = "InfoOverviewConfig";
    TDOCTable[TDOCTable["ProcessConsumables"] = 139] = "ProcessConsumables";
    TDOCTable[TDOCTable["PreferenceListTemplates"] = 140] = "PreferenceListTemplates";
    TDOCTable[TDOCTable["Indicator"] = 141] = "Indicator";
    TDOCTable[TDOCTable["IndicatorType"] = 142] = "IndicatorType";
    TDOCTable[TDOCTable["IndicatorTypeSteps"] = 143] = "IndicatorTypeSteps";
    TDOCTable[TDOCTable["IndicatorAction"] = 144] = "IndicatorAction";
    TDOCTable[TDOCTable["IndicatorMachineType"] = 145] = "IndicatorMachineType";
    TDOCTable[TDOCTable["IndicatorProduct"] = 146] = "IndicatorProduct";
    TDOCTable[TDOCTable["IndicatorProgram"] = 147] = "IndicatorProgram";
    TDOCTable[TDOCTable["IndicatorUnit"] = 148] = "IndicatorUnit";
    TDOCTable[TDOCTable["ExtDocStore"] = 149] = "ExtDocStore";
    TDOCTable[TDOCTable["ExtDocRefLink"] = 150] = "ExtDocRefLink";
    TDOCTable[TDOCTable["ExtDocInfo"] = 151] = "ExtDocInfo";
    TDOCTable[TDOCTable["MachConsType"] = 152] = "MachConsType";
    TDOCTable[TDOCTable["MachConsParam"] = 153] = "MachConsParam";
    TDOCTable[TDOCTable["ProcParam"] = 154] = "ProcParam";
    TDOCTable[TDOCTable["MachRuleResult"] = 155] = "MachRuleResult";
    TDOCTable[TDOCTable["ProcBarcode"] = 156] = "ProcBarcode";
    TDOCTable[TDOCTable["McTypRule"] = 157] = "McTypRule";
    TDOCTable[TDOCTable["UnitTaT"] = 158] = "UnitTaT";
    TDOCTable[TDOCTable["UnitTaTDef"] = 159] = "UnitTaTDef";
    TDOCTable[TDOCTable["HandlingTypes"] = 160] = "HandlingTypes";
    TDOCTable[TDOCTable["HandlingStep"] = 161] = "HandlingStep";
    TDOCTable[TDOCTable["TransportMethod"] = 162] = "TransportMethod";
    TDOCTable[TDOCTable["UILayerDef"] = 163] = "UILayerDef";
    TDOCTable[TDOCTable["UIGuideDef"] = 164] = "UIGuideDef";
    TDOCTable[TDOCTable["UISearchDef"] = 165] = "UISearchDef";
    TDOCTable[TDOCTable["TextUser"] = 166] = "TextUser";
    TDOCTable[TDOCTable["Transport"] = 167] = "Transport";
    TDOCTable[TDOCTable["HandlingResult"] = 168] = "HandlingResult";
    TDOCTable[TDOCTable["Favorites"] = 169] = "Favorites";
    TDOCTable[TDOCTable["ProcLoad"] = 170] = "ProcLoad";
    TDOCTable[TDOCTable["PreferenceList"] = 171] = "PreferenceList";
    TDOCTable[TDOCTable["PreferenceListLine"] = 172] = "PreferenceListLine";
    TDOCTable[TDOCTable["PreferenceListLink"] = 173] = "PreferenceListLink";
    TDOCTable[TDOCTable["OperationStaffLink"] = 174] = "OperationStaffLink";
    TDOCTable[TDOCTable["CustSite"] = 175] = "CustSite";
    TDOCTable[TDOCTable["PatientCat"] = 176] = "PatientCat";
    TDOCTable[TDOCTable["PrefListReplaceLine"] = 177] = "PrefListReplaceLine";
    TDOCTable[TDOCTable["OrderAction"] = 178] = "OrderAction";
    TDOCTable[TDOCTable["OPDataAction"] = 179] = "OPDataAction";
    TDOCTable[TDOCTable["DryerProcess"] = 180] = "DryerProcess";
    TDOCTable[TDOCTable["WorkPeriods"] = 181] = "WorkPeriods";
    TDOCTable[TDOCTable["WorkHours"] = 182] = "WorkHours";
    TDOCTable[TDOCTable["Calendar"] = 183] = "Calendar";
    TDOCTable[TDOCTable["Message"] = 184] = "Message";
    TDOCTable[TDOCTable["MessageUserGroup"] = 185] = "MessageUserGroup";
    TDOCTable[TDOCTable["MessageUserGroupLink"] = 186] = "MessageUserGroupLink";
    TDOCTable[TDOCTable["MessageRecipientLink"] = 187] = "MessageRecipientLink";
    TDOCTable[TDOCTable["ProductTimes"] = 188] = "ProductTimes";
    TDOCTable[TDOCTable["ActionLog"] = 189] = "ActionLog";
    TDOCTable[TDOCTable["ActionSubscriber"] = 190] = "ActionSubscriber";
    TDOCTable[TDOCTable["Truck"] = 191] = "Truck";
    TDOCTable[TDOCTable["TruckStop"] = 192] = "TruckStop";
    TDOCTable[TDOCTable["Route"] = 193] = "Route";
    TDOCTable[TDOCTable["RouteStop"] = 194] = "RouteStop";
    TDOCTable[TDOCTable["RouteTruck"] = 195] = "RouteTruck";
    TDOCTable[TDOCTable["ProductReservation"] = 196] = "ProductReservation";
    TDOCTable[TDOCTable["ItemReservation"] = 197] = "ItemReservation";
    TDOCTable[TDOCTable["ALProcessApprove"] = 198] = "ALProcessApprove";
    TDOCTable[TDOCTable["Question"] = 199] = "Question";
    TDOCTable[TDOCTable["QuestionSet"] = 200] = "QuestionSet";
    TDOCTable[TDOCTable["QuestionAnswer"] = 201] = "QuestionAnswer";
    TDOCTable[TDOCTable["QuestionSetLink"] = 202] = "QuestionSetLink";
    TDOCTable[TDOCTable["Action"] = 203] = "Action";
    TDOCTable[TDOCTable["StockOrder"] = 204] = "StockOrder";
    TDOCTable[TDOCTable["ActionDelayLog"] = 205] = "ActionDelayLog";
    TDOCTable[TDOCTable["MessageTemplates"] = 206] = "MessageTemplates";
    TDOCTable[TDOCTable["MessageTempPart"] = 207] = "MessageTempPart";
    TDOCTable[TDOCTable["MessageTempPartText"] = 208] = "MessageTempPartText";
    TDOCTable[TDOCTable["MessageTempPartsLink"] = 209] = "MessageTempPartsLink";
    TDOCTable[TDOCTable["MessageText"] = 210] = "MessageText";
    TDOCTable[TDOCTable["ActionTemplates"] = 211] = "ActionTemplates";
    TDOCTable[TDOCTable["ImportHolidayLocation"] = 212] = "ImportHolidayLocation";
    TDOCTable[TDOCTable["LotInfo"] = 213] = "LotInfo";
    TDOCTable[TDOCTable["OrderLotInfo"] = 214] = "OrderLotInfo";
    TDOCTable[TDOCTable["UnitLotInfo"] = 215] = "UnitLotInfo";
    TDOCTable[TDOCTable["PatientLotInfo"] = 216] = "PatientLotInfo";
    TDOCTable[TDOCTable["IndicatorLotInfo"] = 217] = "IndicatorLotInfo";
    TDOCTable[TDOCTable["Tags"] = 218] = "Tags";
    TDOCTable[TDOCTable["TagContentHistory"] = 219] = "TagContentHistory";
    TDOCTable[TDOCTable["TagContent"] = 220] = "TagContent";
    TDOCTable[TDOCTable["TagLocation"] = 221] = "TagLocation";
    TDOCTable[TDOCTable["MessageGroupProfileLink"] = 222] = "MessageGroupProfileLink";
    TDOCTable[TDOCTable["MessageLabels"] = 223] = "MessageLabels";
    TDOCTable[TDOCTable["MessageLabelLinks"] = 224] = "MessageLabelLinks";
    TDOCTable[TDOCTable["ExpTimePoints"] = 225] = "ExpTimePoints";
    TDOCTable[TDOCTable["TableInfo"] = 226] = "TableInfo";
    TDOCTable[TDOCTable["Recent"] = 227] = "Recent";
    TDOCTable[TDOCTable["KPIValues"] = 228] = "KPIValues";
    TDOCTable[TDOCTable["CountingPoint"] = 229] = "CountingPoint";
    TDOCTable[TDOCTable["CountingPointSet"] = 230] = "CountingPointSet";
    TDOCTable[TDOCTable["CountCountingSet"] = 231] = "CountCountingSet";
    TDOCTable[TDOCTable["OrderCountLst"] = 232] = "OrderCountLst";
    TDOCTable[TDOCTable["IntercomGroup"] = 233] = "IntercomGroup";
    TDOCTable[TDOCTable["IntercomGroupMembers"] = 234] = "IntercomGroupMembers";
    TDOCTable[TDOCTable["MachDisplayGroupLink"] = 235] = "MachDisplayGroupLink";
    TDOCTable[TDOCTable["MachDisplayGroup"] = 236] = "MachDisplayGroup";
    TDOCTable[TDOCTable["CountChangeReason"] = 237] = "CountChangeReason";
    TDOCTable[TDOCTable["CountChanges"] = 238] = "CountChanges";
    TDOCTable[TDOCTable["FacCountChangeReason"] = 239] = "FacCountChangeReason";
    TDOCTable[TDOCTable["FacRepTyp"] = 240] = "FacRepTyp";
    TDOCTable[TDOCTable["CountDataUsers"] = 241] = "CountDataUsers";
    TDOCTable[TDOCTable["CountingPointData"] = 242] = "CountingPointData";
    TDOCTable[TDOCTable["LinkStation"] = 243] = "LinkStation";
    TDOCTable[TDOCTable["ProcessLogQueue"] = 244] = "ProcessLogQueue";
    TDOCTable[TDOCTable["BioIndicatorType"] = 245] = "BioIndicatorType";
    TDOCTable[TDOCTable["TimeRule"] = 246] = "TimeRule";
    TDOCTable[TDOCTable["TimeRuleGroup"] = 247] = "TimeRuleGroup";
    TDOCTable[TDOCTable["TimeRuleLink"] = 248] = "TimeRuleLink";
    TDOCTable[TDOCTable["IncubatorLog"] = 249] = "IncubatorLog";
    TDOCTable[TDOCTable["IncubatorWellLog"] = 250] = "IncubatorWellLog";
    TDOCTable[TDOCTable["BITypePlacementLink"] = 251] = "BITypePlacementLink";
    TDOCTable[TDOCTable["FactoryTimeRuleGroup"] = 252] = "FactoryTimeRuleGroup";
    TDOCTable[TDOCTable["StandardComment"] = 253] = "StandardComment";
    TDOCTable[TDOCTable["FactoryStandardComment"] = 254] = "FactoryStandardComment";
    TDOCTable[TDOCTable["StandardCommentLink"] = 255] = "StandardCommentLink";
    TDOCTable[TDOCTable["HandlingStepBinding"] = 256] = "HandlingStepBinding";
    TDOCTable[TDOCTable["IncubatorBatch"] = 257] = "IncubatorBatch";
    TDOCTable[TDOCTable["CPUnitCheck"] = 258] = "CPUnitCheck";
    TDOCTable[TDOCTable["FactoryAutoFTStocks"] = 259] = "FactoryAutoFTStocks";
    TDOCTable[TDOCTable["DryerLog"] = 260] = "DryerLog";
    TDOCTable[TDOCTable["DryerPlacementLog"] = 261] = "DryerPlacementLog";
    TDOCTable[TDOCTable["PIPFields"] = 262] = "PIPFields";
    TDOCTable[TDOCTable["PIPAuditLog"] = 263] = "PIPAuditLog";
    TDOCTable[TDOCTable["TagProgramGroupLink"] = 264] = "TagProgramGroupLink";
    TDOCTable[TDOCTable["SpecLink"] = 265] = "SpecLink";
    TDOCTable[TDOCTable["EmgCaseCartType"] = 266] = "EmgCaseCartType";
    TDOCTable[TDOCTable["EmgCaseCartTypeLinks"] = 267] = "EmgCaseCartTypeLinks";
    TDOCTable[TDOCTable["UserFieldDef"] = 268] = "UserFieldDef";
    TDOCTable[TDOCTable["CompAlternative"] = 269] = "CompAlternative";
    TDOCTable[TDOCTable["IdenticalArticleGroup"] = 270] = "IdenticalArticleGroup";
    TDOCTable[TDOCTable["OrderLineAlternative"] = 271] = "OrderLineAlternative";
    TDOCTable[TDOCTable["OrderTempLineAlternative"] = 272] = "OrderTempLineAlternative";
    TDOCTable[TDOCTable["PrefListAlternative"] = 273] = "PrefListAlternative";
    TDOCTable[TDOCTable["ClientTeam"] = 274] = "ClientTeam";
    TDOCTable[TDOCTable["ServerPool"] = 275] = "ServerPool";
    TDOCTable[TDOCTable["ServerPoolClientTeams"] = 276] = "ServerPoolClientTeams";
    TDOCTable[TDOCTable["ServerPoolServers"] = 277] = "ServerPoolServers";
    TDOCTable[TDOCTable["TagType"] = 278] = "TagType";
    TDOCTable[TDOCTable["MesOutTask"] = 279] = "MesOutTask";
    TDOCTable[TDOCTable["MesTaskDef"] = 280] = "MesTaskDef";
    TDOCTable[TDOCTable["MesTaskLocations"] = 281] = "MesTaskLocations";
    TDOCTable[TDOCTable["MesOutTaskTagList"] = 282] = "MesOutTaskTagList";
    TDOCTable[TDOCTable["MesOutTaskEvent"] = 283] = "MesOutTaskEvent";
    TDOCTable[TDOCTable["TegrisConfig"] = 284] = "TegrisConfig";
    TDOCTable[TDOCTable["PickJob"] = 285] = "PickJob";
    TDOCTable[TDOCTable["PickJobContent"] = 286] = "PickJobContent";
    TDOCTable[TDOCTable["PickJobAction"] = 287] = "PickJobAction";
    TDOCTable[TDOCTable["PickJobOrderLink"] = 288] = "PickJobOrderLink";
    TDOCTable[TDOCTable["TriggerLink"] = 289] = "TriggerLink";
    TDOCTable[TDOCTable["TriggerExclude"] = 290] = "TriggerExclude";
    TDOCTable[TDOCTable["SpotTest"] = 291] = "SpotTest";
    TDOCTable[TDOCTable["ItemExchange"] = 292] = "ItemExchange";
    TDOCTable[TDOCTable["ScannerMacro"] = 293] = "ScannerMacro";
    TDOCTable[TDOCTable["GLNInfo"] = 294] = "GLNInfo";
    TDOCTable[TDOCTable["MachLocation"] = 295] = "MachLocation";
    TDOCTable[TDOCTable["IOPosLink"] = 296] = "IOPosLink";
    TDOCTable[TDOCTable["NoMore"] = 297] = "NoMore";
})(TDOCTable = exports.TDOCTable || (exports.TDOCTable = {}));
var TriggerPostponeRight;
(function (TriggerPostponeRight) {
    TriggerPostponeRight[TriggerPostponeRight["Prompt"] = 0] = "Prompt";
    TriggerPostponeRight[TriggerPostponeRight["Stop"] = 1] = "Stop";
})(TriggerPostponeRight = exports.TriggerPostponeRight || (exports.TriggerPostponeRight = {}));
var UILocaAvailability;
(function (UILocaAvailability) {
    UILocaAvailability[UILocaAvailability["Default"] = 0] = "Default";
    UILocaAvailability[UILocaAvailability["Visible"] = 1] = "Visible";
    UILocaAvailability[UILocaAvailability["Advanced"] = 2] = "Advanced";
    UILocaAvailability[UILocaAvailability["Hidden"] = 3] = "Hidden";
})(UILocaAvailability = exports.UILocaAvailability || (exports.UILocaAvailability = {}));
var UseOfUnusedSerials;
(function (UseOfUnusedSerials) {
    UseOfUnusedSerials[UseOfUnusedSerials["Off"] = 0] = "Off";
    UseOfUnusedSerials[UseOfUnusedSerials["Prompt"] = 1] = "Prompt";
    UseOfUnusedSerials[UseOfUnusedSerials["PromptExceptFirstCounting"] = 2] = "PromptExceptFirstCounting";
    UseOfUnusedSerials[UseOfUnusedSerials["On"] = 3] = "On";
})(UseOfUnusedSerials = exports.UseOfUnusedSerials || (exports.UseOfUnusedSerials = {}));
var UseOfUsedSerials;
(function (UseOfUsedSerials) {
    UseOfUsedSerials[UseOfUsedSerials["Off"] = 0] = "Off";
    UseOfUsedSerials[UseOfUsedSerials["Prompt"] = 1] = "Prompt";
    UseOfUsedSerials[UseOfUsedSerials["On"] = 2] = "On";
})(UseOfUsedSerials = exports.UseOfUsedSerials || (exports.UseOfUsedSerials = {}));
var UserPackOnScreen;
(function (UserPackOnScreen) {
    UserPackOnScreen[UserPackOnScreen["On"] = 0] = "On";
    UserPackOnScreen[UserPackOnScreen["Off"] = 1] = "Off";
})(UserPackOnScreen = exports.UserPackOnScreen || (exports.UserPackOnScreen = {}));
var UserPictureOnPack;
(function (UserPictureOnPack) {
    UserPictureOnPack[UserPictureOnPack["On"] = 0] = "On";
    UserPictureOnPack[UserPictureOnPack["Auto"] = 1] = "Auto";
    UserPictureOnPack[UserPictureOnPack["Off"] = 2] = "Off";
})(UserPictureOnPack = exports.UserPictureOnPack || (exports.UserPictureOnPack = {}));
var WebDisplayCustomerProductsMode;
(function (WebDisplayCustomerProductsMode) {
    WebDisplayCustomerProductsMode[WebDisplayCustomerProductsMode["OwnOnly"] = 0] = "OwnOnly";
    WebDisplayCustomerProductsMode[WebDisplayCustomerProductsMode["OwnSiteOnly"] = 1] = "OwnSiteOnly";
    WebDisplayCustomerProductsMode[WebDisplayCustomerProductsMode["All"] = 2] = "All";
    WebDisplayCustomerProductsMode[WebDisplayCustomerProductsMode["OwnSiteOrCust"] = 3] = "OwnSiteOrCust";
})(WebDisplayCustomerProductsMode = exports.WebDisplayCustomerProductsMode || (exports.WebDisplayCustomerProductsMode = {}));
var WebDisplayGeneralProductsMode;
(function (WebDisplayGeneralProductsMode) {
    WebDisplayGeneralProductsMode[WebDisplayGeneralProductsMode["Allowed"] = 0] = "Allowed";
    WebDisplayGeneralProductsMode[WebDisplayGeneralProductsMode["Blocked"] = 1] = "Blocked";
})(WebDisplayGeneralProductsMode = exports.WebDisplayGeneralProductsMode || (exports.WebDisplayGeneralProductsMode = {}));
var WebDisplayPriceMode;
(function (WebDisplayPriceMode) {
    WebDisplayPriceMode[WebDisplayPriceMode["ShowAll"] = 0] = "ShowAll";
    WebDisplayPriceMode[WebDisplayPriceMode["HideAll"] = 1] = "HideAll";
})(WebDisplayPriceMode = exports.WebDisplayPriceMode || (exports.WebDisplayPriceMode = {}));
var WebEditOprationArticlesMode;
(function (WebEditOprationArticlesMode) {
    WebEditOprationArticlesMode[WebEditOprationArticlesMode["AllowEdit"] = 0] = "AllowEdit";
    WebEditOprationArticlesMode[WebEditOprationArticlesMode["NotAllowEdit"] = 1] = "NotAllowEdit";
})(WebEditOprationArticlesMode = exports.WebEditOprationArticlesMode || (exports.WebEditOprationArticlesMode = {}));
var WebFastTrackMode;
(function (WebFastTrackMode) {
    WebFastTrackMode[WebFastTrackMode["On"] = 0] = "On";
    WebFastTrackMode[WebFastTrackMode["Off"] = 1] = "Off";
})(WebFastTrackMode = exports.WebFastTrackMode || (exports.WebFastTrackMode = {}));
var WebLoginMode;
(function (WebLoginMode) {
    WebLoginMode[WebLoginMode["WhenSpecified"] = 0] = "WhenSpecified";
    WebLoginMode[WebLoginMode["Always"] = 1] = "Always";
})(WebLoginMode = exports.WebLoginMode || (exports.WebLoginMode = {}));
var WebOrderMode;
(function (WebOrderMode) {
    WebOrderMode[WebOrderMode["On"] = 0] = "On";
    WebOrderMode[WebOrderMode["Off"] = 1] = "Off";
})(WebOrderMode = exports.WebOrderMode || (exports.WebOrderMode = {}));
var WebStartStopOperationMode;
(function (WebStartStopOperationMode) {
    WebStartStopOperationMode[WebStartStopOperationMode["Allow"] = 0] = "Allow";
    WebStartStopOperationMode[WebStartStopOperationMode["NotAllow"] = 1] = "NotAllow";
})(WebStartStopOperationMode = exports.WebStartStopOperationMode || (exports.WebStartStopOperationMode = {}));
// begin: Enumerations that are constants in Delphi
var UnitStatus;
(function (UnitStatus) {
    UnitStatus[UnitStatus["None"] = 0] = "None";
    UnitStatus[UnitStatus["Init"] = 1] = "Init";
    UnitStatus[UnitStatus["Started"] = 3] = "Started";
    UnitStatus[UnitStatus["Rinsed"] = 7] = "Rinsed";
    UnitStatus[UnitStatus["Washed"] = 8] = "Washed";
    UnitStatus[UnitStatus["Prep"] = 10] = "Prep";
    UnitStatus[UnitStatus["Packed"] = 20] = "Packed";
    UnitStatus[UnitStatus["Stock"] = 40] = "Stock";
    UnitStatus[UnitStatus["Dispatched"] = 70] = "Dispatched";
    UnitStatus[UnitStatus["Opened"] = 72] = "Opened";
    UnitStatus[UnitStatus["Used"] = 74] = "Used";
    UnitStatus[UnitStatus["Precleaned"] = 76] = "Precleaned";
    UnitStatus[UnitStatus["Returned"] = 80] = "Returned";
    UnitStatus[UnitStatus["ErrorReg"] = 95] = "ErrorReg";
    UnitStatus[UnitStatus["Last"] = 100] = "Last";
})(UnitStatus = exports.UnitStatus || (exports.UnitStatus = {}));
var OperationStatus;
(function (OperationStatus) {
    OperationStatus[OperationStatus["Planned"] = 10] = "Planned";
    OperationStatus[OperationStatus["Accepted"] = 20] = "Accepted";
    OperationStatus[OperationStatus["Started"] = 30] = "Started";
    OperationStatus[OperationStatus["Done"] = 40] = "Done";
    OperationStatus[OperationStatus["Cancelled"] = 50] = "Cancelled";
})(OperationStatus = exports.OperationStatus || (exports.OperationStatus = {}));
var OrdersLeadStatus;
(function (OrdersLeadStatus) {
    OrdersLeadStatus[OrdersLeadStatus["Warning"] = 0] = "Warning";
    OrdersLeadStatus[OrdersLeadStatus["Alert"] = 1] = "Alert";
    OrdersLeadStatus[OrdersLeadStatus["Neutral"] = 2] = "Neutral";
    OrdersLeadStatus[OrdersLeadStatus["Done"] = 3] = "Done";
})(OrdersLeadStatus = exports.OrdersLeadStatus || (exports.OrdersLeadStatus = {}));
var EventType;
(function (EventType) {
    EventType[EventType["AddedCommentToUnit"] = 19] = "AddedCommentToUnit";
    EventType[EventType["LabelCommentToUnit"] = 20] = "LabelCommentToUnit";
    EventType[EventType["AddedOPCommentToUnit"] = 21] = "AddedOPCommentToUnit";
})(EventType = exports.EventType || (exports.EventType = {}));
var ArticleStatus;
(function (ArticleStatus) {
    ArticleStatus[ArticleStatus["Normal"] = 10] = "Normal";
    ArticleStatus[ArticleStatus["Dieing"] = 50] = "Dieing";
    ArticleStatus[ArticleStatus["Dead"] = 90] = "Dead";
})(ArticleStatus = exports.ArticleStatus || (exports.ArticleStatus = {}));
var MachineType;
(function (MachineType) {
    MachineType[MachineType["FirstMachineType"] = 0] = "FirstMachineType";
    MachineType[MachineType["Sterilizer"] = 0] = "Sterilizer";
    MachineType[MachineType["Washer"] = 1] = "Washer";
    MachineType[MachineType["PreDis"] = 2] = "PreDis";
    MachineType[MachineType["EndoWasher"] = 3] = "EndoWasher";
    MachineType[MachineType["Incubator"] = 4] = "Incubator";
    MachineType[MachineType["EndoDryer"] = 5] = "EndoDryer";
    MachineType[MachineType["LastMachineType"] = 5] = "LastMachineType";
})(MachineType = exports.MachineType || (exports.MachineType = {}));
// end: Enumerations that are constants in Delphi
exports.NAME_TO_ENUM_MAPPING = (_a = {},
    _a['alternativeArticleStatus'] = AlternativeArticleStatus,
    _a['assignFactoryData'] = AssignFactoryData,
    _a['custNameDisplay'] = CustNameDisplay,
    _a['custOrderBookingMode'] = CustOrderBookingMode,
    _a['custPreDispatchMode'] = CustPreDispatchMode,
    _a['decNonDispItemStockOnPack'] = DecNonDispItemStockOnPack,
    _a['deltaScanMode'] = DeltaScanMode,
    _a['expireType'] = ExpireType,
    _a['facDeductItemsFromStockInOpType'] = FacDeductItemsFromStockInOpType,
    _a['facOrderBookingMode'] = FacOrderBookingMode,
    _a['factoryDefProdTraceType'] = FactoryDefProdTraceType,
    _a['fastTrackCodeType'] = FastTrackCodeType,
    _a['fastTrackCreatorType'] = FastTrackCreatorType,
    _a['fastTrackDisplayMode'] = FastTrackDisplayMode,
    _a['fastTrackEndType'] = FastTrackEndType,
    _a['fastTrackInvoiceType'] = FastTrackInvoiceType,
    _a['fastTrackStatus'] = FastTrackStatus,
    _a['fastTrackStockBasedMode'] = FastTrackStockBasedMode,
    _a['fastTrackStockBasedOrdersFilter'] = FastTrackStockBasedOrdersFilter,
    _a['fuzzySearchSortOrder'] = FuzzySearchSortOrder,
    _a['fuzzySearchTextMatchType'] = FuzzySearchTextMatchType,
    _a['identArticleLineUsage'] = IdentArticleLineUsage,
    _a['identStockUsage'] = IdentStockUsage,
    _a['includeItemSerialUsage'] = IncludeItemSerialUsage,
    _a['incNonDispItemStockOnPack'] = IncNonDispItemStockOnPack,
    _a['itemAltPricingMode'] = ItemAltPricingMode,
    _a['itemDifficultyMode'] = ItemDifficultyMode,
    _a['itemInstrumentType'] = ItemInstrumentType,
    _a['itemPricingMode'] = ItemPricingMode,
    _a['itemPricingType'] = ItemPricingType,
    _a['itemReservationMode'] = ItemReservationMode,
    _a['itemSalesRestriction'] = ItemSalesRestriction,
    _a['itemTraceType'] = ItemTraceType,
    _a['language'] = Language,
    _a['legacyProcessType'] = LegacyProcessType,
    _a['locaSubProcessType'] = LocaSubProcessType,
    _a['loginCookieMethod'] = LoginCookieMethod,
    _a['logKeyType'] = LogKeyType,
    _a['manualEndoDryingMode'] = ManualEndoDryingMode,
    _a['minOrderLeadTime'] = MinOrderLeadTime,
    _a['multiMediaSubType'] = MultiMediaSubType,
    _a['multiMediaType'] = MultiMediaType,
    _a['notifyPriority'] = NotifyPriority,
    _a['operationSearchType'] = OperationSearchType,
    _a['operationTypeType'] = OperationTypeType,
    _a['orderBookingMode'] = OrderBookingMode,
    _a['orderBookingPriority'] = OrderBookingPriority,
    _a['orderBookingStatus'] = OrderBookingStatus,
    _a['orderLockMode'] = OrderLockMode,
    _a['orderOperationSyncMode'] = OrderOperationSyncMode,
    _a['orderOrgStatus'] = OrderOrgStatus,
    _a['orderSuppPickMode'] = OrderSuppPickMode,
    _a['orderSuppPickPriority'] = OrderSuppPickPriority,
    _a['orderSuppPriority'] = OrderSuppPriority,
    _a['orderSuppStatus'] = OrderSuppStatus,
    _a['orderType'] = OrderType,
    _a['passwordVerificationMethod'] = PasswordVerificationMethod,
    _a['passwordVerify'] = PasswordVerify,
    _a['patientAgeUsage'] = PatientAgeUsage,
    _a['patientGenderUsage'] = PatientGenderUsage,
    _a['prefListOrderCreateMode'] = PrefListOrderCreateMode,
    _a['processStatus'] = ProcessStatus,
    _a['processType'] = ProcessType,
    _a['prodDispatchRestriction'] = ProdDispatchRestriction,
    _a['prodOverviewAvailability'] = ProdOverviewAvailability,
    _a['prodOverviewVisibilityMode'] = ProdOverviewVisibilityMode,
    _a['prodReservationMode'] = ProdReservationMode,
    _a['productDifficultyMode'] = ProductDifficultyMode,
    _a['productLine'] = ProductLine,
    _a['productLocateSection'] = ProductLocateSection,
    _a['productReservationType'] = ProductReservationType,
    _a['productReturnState'] = ProductReturnState,
    _a['productStockControlMode'] = ProductStockControlMode,
    _a['productStorageType'] = ProductStorageType,
    _a['productTraceType'] = ProductTraceType,
    _a['productType'] = ProductType,
    _a['repairMode'] = RepairMode,
    _a['reservationState'] = ReservationState,
    _a['reservationStatus'] = ReservationStatus,
    _a['reservFlag'] = ReservFlag,
    _a['reservLogType'] = ReservLogType,
    _a['scanUIStartMode'] = ScanUIStartMode,
    _a['searchAutoPictureMode'] = SearchAutoPictureMode,
    _a['searchResultOrigin'] = SearchResultOrigin,
    _a['sexType'] = SexType,
    _a['specialSearchType'] = SpecialSearchType,
    _a['stdCommentFreeTextOption'] = StdCommentFreeTextOption,
    _a['stdCommentShowInProdOption'] = StdCommentShowInProdOption,
    _a['stockBackOrderMode'] = StockBackOrderMode,
    _a['stockOrderInMode'] = StockOrderInMode,
    _a['stockPrnMode'] = StockPrnMode,
    _a['stockType'] = StockType,
    _a['substitutionArticleType'] = SubstitutionArticleType,
    _a['substitutionReason'] = SubstitutionReason,
    _a['substMode'] = SubstMode,
    _a['tableTypes'] = TableTypes,
    _a['TDOCAppType'] = TDOCAppType,
    _a['TDOCTable'] = TDOCTable,
    _a['triggerPostponeRight'] = TriggerPostponeRight,
    _a['uILocaAvailability'] = UILocaAvailability,
    _a['useOfUnusedSerials'] = UseOfUnusedSerials,
    _a['useOfUsedSerials'] = UseOfUsedSerials,
    _a['userPackOnScreen'] = UserPackOnScreen,
    _a['userPictureOnPack'] = UserPictureOnPack,
    _a['webDisplayCustomerProductsMode'] = WebDisplayCustomerProductsMode,
    _a['webDisplayGeneralProductsMode'] = WebDisplayGeneralProductsMode,
    _a['webDisplayPriceMode'] = WebDisplayPriceMode,
    _a['webEditOprationArticlesMode'] = WebEditOprationArticlesMode,
    _a['webFastTrackMode'] = WebFastTrackMode,
    _a['webLoginMode'] = WebLoginMode,
    _a['webOrderMode'] = WebOrderMode,
    _a['webStartStopOperationMode'] = WebStartStopOperationMode,
    // begin: Enumerations that are constants in Delphi
    _a['unitStatus'] = UnitStatus,
    _a['endoscopeUnitStatus'] = UnitStatus,
    _a['operationStatus'] = OperationStatus,
    _a['eventType'] = EventType,
    _a['articleStatus'] = ArticleStatus,
    _a['ordersLeadStatus'] = OrdersLeadStatus,
    _a['machineType'] = MachineType
// end: Enumerations that are constants in Delphi
,
    _a);
