"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var table_info_helper_service_1 = require("@core/table-info/services/table-info-helper.service");
var grid_structure_interface_1 = require("@core/table-info/models/grid-structure.interface");
var language_service_1 = require("@core/language/services/language.service");
var app_constants_1 = require("@shared/models/app.constants");
var grid_column_helper_service_1 = require("@shared/services/grid-column-helper.service");
var i0 = require("@angular/core");
var i1 = require("../../../shared/services/grid-column-helper.service");
var i2 = require("../../table-info/services/table-info-helper.service");
var i3 = require("../../language/services/language.service");
var SearchHelperService = /** @class */ (function () {
    function SearchHelperService(gridColumnHelperService, tableInfoHelperService, languageService) {
        this.gridColumnHelperService = gridColumnHelperService;
        this.tableInfoHelperService = tableInfoHelperService;
        this.languageService = languageService;
    }
    SearchHelperService.prototype.insertCustomFieldsInDataSet = function (searchArgs, rowFields) {
        // insert "quantity" field for "main search" page ("Products", "Items", "Endoscopes")
        if (((searchArgs.mainTable === td_constants_1.TD_MODEL_NAMES.itemModel) || (searchArgs.mainTable === td_constants_1.TD_MODEL_NAMES.productModel)) &&
            ((searchArgs.searchType === td_enumerations_1.SpecialSearchType.WebBaseProdItem) || (searchArgs.searchType === td_enumerations_1.SpecialSearchType.WebProdWithSerials)) &&
            !searchArgs.extraWebArticlesArgs.reducedSearch) {
            rowFields[td_constants_1.TD_ACTION_FIELDS.quantity] = 1;
        }
        if ((searchArgs.mainTable === td_constants_1.TD_MODEL_NAMES.productModel) && (searchArgs.searchType === td_enumerations_1.SpecialSearchType.WebProdWithSerials)) {
            rowFields[td_constants_1.TD_ACTION_FIELDS.hasDetails] = rowFields[td_constants_1.TD_DYNAMIC_FIELDS.productKeepSerials];
        }
        else if ((searchArgs.mainTable === td_constants_1.TD_MODEL_NAMES.operationDataModel) && (searchArgs.searchType === td_enumerations_1.SpecialSearchType.WebScheduledOperation)) {
            // if order counter > 0
            rowFields[td_constants_1.TD_ACTION_FIELDS.hasDetails] = rowFields[td_constants_1.TD_DYNAMIC_FIELDS.totalOrderCount] > 0;
        }
    };
    SearchHelperService.prototype.getEnumName = function (enumName, isEndoscope) {
        return ((enumName === app_constants_1.UNIT_STATUS) && isEndoscope) ? app_constants_1.ENDOSCOPE_UNIT_STATUS : enumName;
    };
    SearchHelperService.prototype.createNestedFields = function (parentObj, fieldParts, columnConfig, value, isEndoscope) {
        if (fieldParts.length === 1) {
            if (this.gridColumnHelperService.hidePriceColumn(fieldParts[0])) {
                parentObj[fieldParts[0]] = td_constants_1.PIP_MASK_ASTERISKS;
            }
            // translate "enum-value" into translated "text-value"
            else if (columnConfig && (columnConfig.dataType === grid_structure_interface_1.PropertyTypes.typeEnum)) {
                parentObj[fieldParts[0] + "EnumValue"] = value;
                parentObj[fieldParts[0]] = this.languageService.translateEnumValue(this.getEnumName(columnConfig.enumName, isEndoscope), value);
            }
            // translate "boolean-value" into translated "text-value"
            else if (columnConfig && (columnConfig.dataType === grid_structure_interface_1.PropertyTypes.typeBoolean)) {
                parentObj[fieldParts[0]] = this.languageService.translateBooleanValue(value);
            }
            // convert "date-value" text in Date
            else if (columnConfig && (columnConfig.dataType === grid_structure_interface_1.PropertyTypes.typeDate)) {
                if (value) {
                    if (value === td_constants_1.PIP_MASK_ASTERISKS) {
                        parentObj[fieldParts[0]] = value;
                    }
                    else {
                        parentObj[fieldParts[0]] = new Date(value);
                    }
                }
                else {
                    parentObj[fieldParts[0]] = null;
                }
            }
            // General "number" type fields and some dynamic fields, like "PICTPICSKEYID", can keep "null" value.
            // But from backend this value returning like "string" type. So we should convert it to real "null".
            else if ((value === 'null') &&
                ((columnConfig && (columnConfig.dataType === grid_structure_interface_1.PropertyTypes.typeNumber)) ||
                    (fieldParts[0] === td_constants_1.TD_DYNAMIC_FIELDS.pictureKeyId) ||
                    (fieldParts[0] === td_constants_1.TD_DYNAMIC_FIELDS.autoPictureKeyId) ||
                    (fieldParts[0] === td_constants_1.TD_DYNAMIC_FIELDS.unitIsFastTracked) ||
                    (fieldParts[0] === td_constants_1.TD_DYNAMIC_FIELDS.keyId))) {
                parentObj[fieldParts[0]] = null;
            }
            else {
                // assign:
                // - main-table fields, for example: { ...keyId: 1001, status: 10 }
                // - referenced-table fields, for example: { ... item.keyId: 1001, item.status: 10 }
                parentObj[fieldParts[0]] = value;
            }
        }
        else {
            // prepare referenced-table fields like new Object
            var childObj = Object.assign({}, parentObj[fieldParts[0]]);
            this.createNestedFields(childObj, fieldParts.slice(1), columnConfig, value, isEndoscope);
            parentObj[fieldParts[0]] = childObj;
        }
    };
    SearchHelperService.prototype.convertDataSetResultToViewModel = function (searchArgs, dataSet) {
        var _this = this;
        var searchRows = new Array();
        dataSet.rows.forEach(function (values) {
            var rowFields = {};
            var isEndoscope = false;
            if (searchArgs.isEndoscope === undefined) {
                var productTypeField_1 = searchArgs.gridStructure.columnsConfigurations.find(function (columnConfig) { return columnConfig.enumName === 'productType'; });
                if (productTypeField_1 && productTypeField_1.dataField) {
                    var productTypeIndex = dataSet.fields.findIndex(function (field) { return field === productTypeField_1.dataField; });
                    if (productTypeIndex >= 0) {
                        isEndoscope = values[productTypeIndex] === td_enumerations_1.ProductType.Endoscopes;
                    }
                }
            }
            else {
                isEndoscope = searchArgs.isEndoscope;
            }
            for (var i = 0; i < values.length; i++) {
                var fieldParts = dataSet.fields[i].split('.');
                var columnConfig = _this.gridColumnHelperService.getColumnConfigurationByName(searchArgs.gridStructure.columnsConfigurations, dataSet.fields[i]);
                _this.createNestedFields(rowFields, fieldParts, columnConfig, values[i], isEndoscope);
            }
            _this.insertCustomFieldsInDataSet(searchArgs, rowFields);
            searchRows.push(rowFields);
        });
        var dataSetView = tslib_1.__assign({}, dataSet, { mappedRows: searchRows });
        return dataSetView;
    };
    SearchHelperService.prototype.convertSearchResultToViewModel = function (searchArgs, searchResult) {
        var searchResultView = tslib_1.__assign({}, searchResult, { dataSet: this.convertDataSetResultToViewModel(searchArgs, searchResult.dataSet) });
        return searchResultView;
    };
    SearchHelperService.prototype.convertExtendedSearchResultToViewModel = function (searchArgs, searchResult) {
        var extendedSearchResultView = this.convertSearchResultToViewModel(searchArgs, searchResult);
        if (searchResult.columnsConfigurations) {
            extendedSearchResultView.columnsConfigurations = this.tableInfoHelperService.convertGridColumnsConfigurationsToViewModel(searchResult.columnsConfigurations);
        }
        return extendedSearchResultView;
    };
    SearchHelperService.prototype.applyColumnsConfigurations = function (requestArgs) {
        var _this = this;
        requestArgs.selectedFields.splice(0);
        requestArgs.searchTextFields.splice(0);
        requestArgs.gridStructure.columnsConfigurations.forEach(function (columnConfig) {
            if (_this.gridColumnHelperService.isSelectedColumn(columnConfig)) {
                requestArgs.selectedFields.push(columnConfig.dataField);
                if (columnConfig.visible) {
                    requestArgs.searchTextFields.push(columnConfig.dataField);
                }
            }
        });
    };
    SearchHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchHelperService_Factory() { return new SearchHelperService(i0.ɵɵinject(i1.GridColumnHelperService), i0.ɵɵinject(i2.TableInfoHelperService), i0.ɵɵinject(i3.LanguageService)); }, token: SearchHelperService, providedIn: "root" });
    return SearchHelperService;
}());
exports.SearchHelperService = SearchHelperService;
