import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule, META_REDUCERS } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserIdleModule } from 'angular-user-idle';
import { TD_DEFAULT_USER_IDLE_CONFIG } from './core/data-layer/shared/models/td.constants';

import { environment } from '../environments/environment';
import { AppInjector } from './app-injector.service';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { reducers, metaReducerFactory } from './core/data-layer/app.state';
import { CustomerEffects } from './core/data-layer/customer/store/customer.effects';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SearchComponent } from './search/search.component';
import { SigninCallbackComponent } from './authentication/signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './authentication/signout-callback/signout-callback.component';
import { AuthCustomersComponent } from './authentication/auth-customers/auth-customers.component';
import { SharedModule } from './shared/shared.module';
import { UserSessionEffects } from './core/data-layer/user-session/store/user-session.effects';
import { ServerSessionEffects } from './core/data-layer/server-session/store/server-session.effects';
import { FactoryEffects } from './core/data-layer/factory/store/factory.effects';
import { OrderEffects } from './core/data-layer/order/store/order.effects';
import { ErrorComponent } from './error/error.component';
import { LandingComponent } from './landing/landing.component';
import { SettingsComponent } from './settings/settings.component';
import { ServerSettingsComponent } from './settings/components/server-settings/server-settings.component';
import { InfoOverviewEffects } from './dashboard/data-layer/info-overview/store/info-overview.effects';
import { HelpComponent } from './help/help.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SearchComponent,
    SigninCallbackComponent,
    SignoutCallbackComponent,
    AuthCustomersComponent,
    ErrorComponent,
    LandingComponent,
    SettingsComponent,
    ServerSettingsComponent,
    HelpComponent
  ],
  providers: [
    { provide: META_REDUCERS, deps: [], useFactory: metaReducerFactory, multi: true }
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    // Default values: `idle` is 600 seconds, `timeout` is 300 seconds, `ping` is 120 seconds.
    // In case timeout set to 0 it will take default 300 seconds.
    UserIdleModule.forRoot({
      idle: TD_DEFAULT_USER_IDLE_CONFIG.idle,
      timeout: TD_DEFAULT_USER_IDLE_CONFIG.timeout,
      ping: TD_DEFAULT_USER_IDLE_CONFIG.idle / TD_DEFAULT_USER_IDLE_CONFIG.frequencyOfPing
    }),
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      CustomerEffects,
      UserSessionEffects,
      ServerSessionEffects,
      FactoryEffects,
      OrderEffects,
      InfoOverviewEffects
    ])
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}


