"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var table_info_helper_service_1 = require("./table-info-helper.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var HttpTableInfoService = /** @class */ (function () {
    function HttpTableInfoService(http, tableInfoHelperService) {
        this.http = http;
        this.tableInfoHelperService = tableInfoHelperService;
    }
    HttpTableInfoService.prototype.desktopDataUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.desktopDataCtrl;
    };
    HttpTableInfoService.prototype.scrollingDataUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.scrollingDataUrl;
    };
    // save component desktop state
    HttpTableInfoService.prototype.saveComponentState = function (identifier, state) {
        var params = { identifier: identifier, desktopState: state };
        return this.http.post(this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.saveComponentState, params);
    };
    // load component desktop states
    HttpTableInfoService.prototype.getComponentState = function (identifier) {
        return this.http.get(this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.getComponentState + ("(" + identifier + ")"));
    };
    // load grid structure: all relative tables and columns
    // all possible identifiers are in TD_DESKTOP_IDENTIFIERS from @core\data-layer\shared\td.constants.ts
    HttpTableInfoService.prototype.loadGridStructure = function (identifier, mainTable) {
        var _this = this;
        var gridConfigurationsData = localStorage.getItem(identifier);
        var gridConfigurations = gridConfigurationsData ? JSON.parse(gridConfigurationsData) : null;
        var params = [];
        if (gridConfigurations && gridConfigurations.columns) {
            gridConfigurations.columns.forEach(function (column) {
                if (column.dataField) {
                    params.push(column);
                }
            });
        }
        return this.http.post(this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.getGridStructure + ("(" + mainTable + ")?identifier=" + identifier + "&maxLevelOfNestedTables=" + td_constants_1.TD_MAX_LEVEL_OF_NESTED_TABLES), params)
            .pipe(operators_1.map(function (gridStructure) {
            var gridStructureView = {
                tables: _this.tableInfoHelperService.convertGridTablesToViewModel(gridStructure.tables),
                columnsConfigurations: _this.tableInfoHelperService.convertGridColumnsConfigurationsToViewModel(gridStructure.columnsConfigurations)
            };
            return gridStructureView;
        }));
    };
    HttpTableInfoService.prototype.findPreviousNextKeyId = function (modelName, keyId, isPrevious) {
        var params = new http_1.HttpParams().set("isPrevious", isPrevious.toString());
        return this.http.get(this.scrollingDataUrl() + "(" + modelName + ")" + api_hub_constants_1.API_HUB_PATHS.findPreviousNextKeyId + "(" + keyId + ")", { params: params });
    };
    HttpTableInfoService.prototype.findFirstLastKeyId = function (modelName, isLast) {
        var params = new http_1.HttpParams().set("isLast", isLast.toString());
        return this.http.get(this.scrollingDataUrl() + "(" + modelName + ")" + api_hub_constants_1.API_HUB_PATHS.findFirstLastKeyId, { params: params });
    };
    return HttpTableInfoService;
}());
exports.HttpTableInfoService = HttpTableInfoService;
