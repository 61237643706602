import { PropertyToEnumMappingInterface } from './property-to-enum-mapping.interface';
import { OrderSuppStatus, OrderOrgStatus, TableTypes } from '@core/data-layer/shared/models/td.enumerations';

export const ORDER_PROPERTIES_TO_ENUM_MAPPING: PropertyToEnumMappingInterface[] = [
  {
    textProperty: 'suppStatusText',
    sourceProperty: 'suppStatus',
    enum: OrderSuppStatus,
    translate: 'orderSuppStatus'
  },
  {
    textProperty: 'orgStatusText',
    sourceProperty: 'orgStatus',
    enum: OrderOrgStatus,
    translate: 'orderOrgStatus'
  }
];

export const ORDER_DETAILS_PROPERTIES_TO_ENUM_MAPPING: PropertyToEnumMappingInterface[] = [
  {
    textProperty: 'suppStatusText',
    sourceProperty: 'suppStatus',
    enum: OrderSuppStatus,
    translate: 'orderSuppStatus'
  },
  {
    textProperty: 'orgStatusText',
    sourceProperty: 'orgStatus',
    enum: OrderOrgStatus,
    translate: 'orderOrgStatus'
  }
];

export const ORDER_LINE_PROPERTIES_TO_ENUM_MAPPING: PropertyToEnumMappingInterface[] = [
  {
    textProperty: 'oLnRefTypeText',
    sourceProperty: 'oLnRefType',
    enum: TableTypes,
    translate: 'orderLineRefType'
  }
];

