export enum ArticleType {
  Product = 0,
  Item = 1,
  Endoscope = 2
}

export enum ProfileMenu {
  Settings = 0,
  ChangePassword = 1
}
