import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { OperationDataModel } from '../models/operation-data.model';
import { OperationDataServiceInterface } from './operation-data.service.interface';
import { ApiResponse } from '@core/data-layer/shared/models/api-response.model';
import { OperationDataHelperService } from './operation-data-helper.service';
import { OperationDataViewModel } from '../models/operation-data-view.model';
import { CreateOperationOrderInfoModel } from '../models/create-operation-order-info.model';

@Injectable()
export class HttpOperationDataService implements OperationDataServiceInterface {

  constructor(
    private http: HttpClient,
    private operationDataHelperService: OperationDataHelperService
  ) { }

  operationsUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.operationsCtrl;
  }

  getOperationData(operationKeyId: number, customerKeyId: number): Observable<OperationDataViewModel> {
    return this.http.post<OperationDataModel>(`${this.operationsUrl()}(${operationKeyId})${API_HUB_PATHS.details}`, customerKeyId).pipe(
      map(response => (this.operationDataHelperService.convertOperationDataToViewModel(response))));
  }

  addUnit(operationKeyId: number, unitKeyId: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.operationsUrl()}(${operationKeyId})${API_HUB_PATHS.addUnit}(${unitKeyId})`, null);
  }

  removeUnit(operationKeyId: number, unitKeyId: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.operationsUrl()}(${operationKeyId})${API_HUB_PATHS.removeUnit}(${unitKeyId})`, null);
  }

  startOperation(operationKeyId: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.operationsUrl()}(${operationKeyId})${API_HUB_PATHS.startOperation}`, null);
  }

  stopOperation(operationKeyId: number, cancelOrders?: boolean): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.operationsUrl()}(${operationKeyId})${API_HUB_PATHS.stopOperation}?cancelOrders=${cancelOrders}`, null);
  }

  getCreateOperationOrderInfo(operationKeyId: number, customerKeyId: number): Observable<CreateOperationOrderInfoModel> {
    return this.http.post<CreateOperationOrderInfoModel>(`${this.operationsUrl()}(${operationKeyId})${API_HUB_PATHS.getCreateOperationOrderInfo}`, customerKeyId);
  }

  getOperationCustomer(operationKeyId: number): Observable<number> {
    return this.http.get<number>(`${this.operationsUrl()}(${operationKeyId})${API_HUB_PATHS.customerKeyId}`);
  } 

}
