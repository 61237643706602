export enum ExternalDocumentFileType {
  // Image types
  BMP = 1,
  GIF = 2,
  PNG = 3,
  JPG = 4,
  JPEG = 5,
  ICO = 6,
  TIF = 7,
  TIFF = 8,
  
  // Word Types
  DOC = 21,
  DOCM = 22,
  DOCX = 23,
  // Text types
  DOT = 41,
  ODT = 42,
  RTF = 43,
  TXT = 44,
  // Excel types
  XLA = 61,
  XLS = 62,
  // Powerpoint types
  PPT = 81,
  POTM = 82,
  POTX = 83,
  PPA = 84,
  PPAM = 85,
  PPS = 86,
  PPSM = 87,
  PPSX = 88,
  PPTM = 89,
  PPTX = 90,
  // Video types
  MP4 = 101,
  MOV = 102,
  AVI = 103,
  MPG = 104,
  WMV = 105,
  OGG = 106,
  WEBM = 107,
  MP2 = 108,
  MPEG = 109,
  MPE = 110,
  MPV = 111,
  M4P = 112,
  M4V = 113,
  QT = 114,
  FLV = 115,
  SWF = 116,
  AVCHD = 117,
  // Archive types
  ZIP = 141,
  ZIPX = 142,
  // Sound types
  WAV = 161,
  MP3 = 162,

  // Single types
  URL = 181,
  PDF = 182,
  OTHER = 183
}

export enum ICONTYPE {
  IMG = 'IMG',
  WORD = 'WORD',
  TEXT = 'TEXT',
  EXCEL = 'EXCEL',
  POWERPOINT = 'POWERPOINT',
  VIDEO = 'VIDEO',
  ARCHIVE = 'ARCHIVE',
  URL = 'URL',
  PDF = 'PDF',
  OTHER = 'OTHER',
  AUDIO = 'AUDIO'
}