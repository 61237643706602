import { Injectable } from '@angular/core';

import { SearchResultModel, DataSetSearchResultModel } from '../models/search-result.model';
import { SearchResultViewModel, DataSetSearchResultViewModel, ExtendedSearchResultViewModel } from '../models/search-result-view.model';
import { SpecialSearchType, ProductType } from '@core/data-layer/shared/models/td.enumerations';
import { TD_ACTION_FIELDS, TD_MODEL_NAMES, TD_DYNAMIC_FIELDS, PIP_MASK_ASTERISKS } from '@core/data-layer/shared/models/td.constants';
import { SearchArgsModel } from '../models/search-args.model';
import { TableInfoHelperService } from '@core/table-info/services/table-info-helper.service';
import { PropertyTypes } from '@core/table-info/models/grid-structure.interface';
import { LanguageService } from '@core/language/services/language.service';
import { UNIT_STATUS, ENDOSCOPE_UNIT_STATUS } from '@shared/models/app.constants';
import { GridColumnConfigurationViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { GridColumnHelperService } from '@shared/services/grid-column-helper.service';
import { BaseGridArgsModel } from '@core/data-layer/shared/models/base-grid-args.model';
import { ProductLocatorGridArgsModel } from '@core/data-layer/product/models/product-locator-args.model';

@Injectable({
  providedIn: 'root'
})
export class SearchHelperService {

  constructor(
    private gridColumnHelperService: GridColumnHelperService,
    private tableInfoHelperService: TableInfoHelperService,
    private languageService: LanguageService
  ) { }

  private insertCustomFieldsInDataSet(searchArgs: SearchArgsModel, rowFields: object) {
    // insert "quantity" field for "main search" page ("Products", "Items", "Endoscopes")
    if (((searchArgs.mainTable === TD_MODEL_NAMES.itemModel) || (searchArgs.mainTable === TD_MODEL_NAMES.productModel)) &&
      ((searchArgs.searchType === SpecialSearchType.WebBaseProdItem) || (searchArgs.searchType === SpecialSearchType.WebProdWithSerials)) &&
      !searchArgs.extraWebArticlesArgs.reducedSearch) {
      rowFields[TD_ACTION_FIELDS.quantity] = 1;
    }
    if ((searchArgs.mainTable === TD_MODEL_NAMES.productModel) && (searchArgs.searchType === SpecialSearchType.WebProdWithSerials)) {
      rowFields[TD_ACTION_FIELDS.hasDetails] = rowFields[TD_DYNAMIC_FIELDS.productKeepSerials];
    }
    else if ((searchArgs.mainTable === TD_MODEL_NAMES.operationDataModel) && (searchArgs.searchType === SpecialSearchType.WebScheduledOperation)) {
      // if order counter > 0
      rowFields[TD_ACTION_FIELDS.hasDetails] = rowFields[TD_DYNAMIC_FIELDS.totalOrderCount] > 0;
    }
  }

  private getEnumName(enumName: string, isEndoscope: boolean): string {
    return ((enumName === UNIT_STATUS) && isEndoscope) ? ENDOSCOPE_UNIT_STATUS : enumName;
  }

  private createNestedFields(parentObj: object, fieldParts: string[], columnConfig: GridColumnConfigurationViewInterface, value: any, isEndoscope: boolean) {
    if (fieldParts.length === 1) {
      if (this.gridColumnHelperService.hidePriceColumn(fieldParts[0])) {
        parentObj[fieldParts[0]] = PIP_MASK_ASTERISKS;
      }
      // translate "enum-value" into translated "text-value"
      else if (columnConfig && (columnConfig.dataType === PropertyTypes.typeEnum)) {
        parentObj[`${fieldParts[0]}EnumValue`] = value;
        parentObj[fieldParts[0]] = this.languageService.translateEnumValue(this.getEnumName(columnConfig.enumName, isEndoscope), value);
      }
      // translate "boolean-value" into translated "text-value"
      else if (columnConfig && (columnConfig.dataType === PropertyTypes.typeBoolean)) {
        parentObj[fieldParts[0]] = this.languageService.translateBooleanValue(value);
      }
      // convert "date-value" text in Date
      else if (columnConfig && (columnConfig.dataType === PropertyTypes.typeDate)) {
        if (value) {
          if (value === PIP_MASK_ASTERISKS) {
            parentObj[fieldParts[0]] = value;
          }
          else {
            parentObj[fieldParts[0]] = new Date(value);
          }
        }
        else {
          parentObj[fieldParts[0]] = null;
        }
      }
      // General "number" type fields and some dynamic fields, like "PICTPICSKEYID", can keep "null" value.
      // But from backend this value returning like "string" type. So we should convert it to real "null".
      else if ((value === 'null') &&
        ((columnConfig && (columnConfig.dataType === PropertyTypes.typeNumber)) ||
         (fieldParts[0] === TD_DYNAMIC_FIELDS.pictureKeyId) ||
         (fieldParts[0] === TD_DYNAMIC_FIELDS.autoPictureKeyId) ||
         (fieldParts[0] === TD_DYNAMIC_FIELDS.unitIsFastTracked) ||
         (fieldParts[0] === TD_DYNAMIC_FIELDS.keyId))
      ) {
        parentObj[fieldParts[0]] = null;
      }
      else {
        // assign:
        // - main-table fields, for example: { ...keyId: 1001, status: 10 }
        // - referenced-table fields, for example: { ... item.keyId: 1001, item.status: 10 }
        parentObj[fieldParts[0]] = value;
      }
    }
    else {
      // prepare referenced-table fields like new Object
      const childObj = Object.assign({}, parentObj[fieldParts[0]]);
      this.createNestedFields(childObj, fieldParts.slice(1), columnConfig, value, isEndoscope);
      parentObj[fieldParts[0]] = childObj;
    }
  }

  convertDataSetResultToViewModel(searchArgs: SearchArgsModel, dataSet: DataSetSearchResultModel): DataSetSearchResultViewModel {
    const searchRows = new Array<object>();
    dataSet.rows.forEach(values => {
      const rowFields = {};
      let isEndoscope = false;
      if ((searchArgs as BaseGridArgsModel as ProductLocatorGridArgsModel).isEndoscope === undefined) {
        const productTypeField = searchArgs.gridStructure.columnsConfigurations.find(columnConfig => columnConfig.enumName === 'productType');
        if (productTypeField && productTypeField.dataField) {
          const productTypeIndex = dataSet.fields.findIndex(field => field === productTypeField.dataField);
          if (productTypeIndex >= 0) {
            isEndoscope = values[productTypeIndex] as ProductType === ProductType.Endoscopes;
          }
        }
      }
      else {
        isEndoscope = (searchArgs as BaseGridArgsModel as ProductLocatorGridArgsModel).isEndoscope;
      }
      for (let i = 0; i < values.length; i++) {
        const fieldParts: string[] = dataSet.fields[i].split('.');
        const columnConfig = this.gridColumnHelperService.getColumnConfigurationByName(searchArgs.gridStructure.columnsConfigurations, dataSet.fields[i]);
        this.createNestedFields(rowFields, fieldParts, columnConfig, values[i], isEndoscope);
      }
      this.insertCustomFieldsInDataSet(searchArgs, rowFields);
      searchRows.push(rowFields);
    });
    const dataSetView: DataSetSearchResultViewModel = {
      ...dataSet,
      mappedRows: searchRows
    };
    return dataSetView;
  }

  convertSearchResultToViewModel(searchArgs: SearchArgsModel, searchResult: SearchResultModel): SearchResultViewModel {
    const searchResultView: SearchResultViewModel = {
      ...searchResult,
      dataSet: this.convertDataSetResultToViewModel(searchArgs, searchResult.dataSet)
    };
    return searchResultView;
  }

  convertExtendedSearchResultToViewModel(searchArgs: SearchArgsModel, searchResult: SearchResultModel): ExtendedSearchResultViewModel {
    const extendedSearchResultView: ExtendedSearchResultViewModel = this.convertSearchResultToViewModel(searchArgs, searchResult);
    if (searchResult.columnsConfigurations) {
      extendedSearchResultView.columnsConfigurations = this.tableInfoHelperService.convertGridColumnsConfigurationsToViewModel(searchResult.columnsConfigurations);
    }
    return extendedSearchResultView;
  }

  applyColumnsConfigurations(requestArgs: BaseGridArgsModel) {
    requestArgs.selectedFields.splice(0);
    requestArgs.searchTextFields.splice(0);
    requestArgs.gridStructure.columnsConfigurations.forEach(columnConfig => {
      if (this.gridColumnHelperService.isSelectedColumn(columnConfig)) {
        requestArgs.selectedFields.push(columnConfig.dataField);
        if (columnConfig.visible) {
          requestArgs.searchTextFields.push(columnConfig.dataField);
        }
      }
    });
  }

}
