"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_load_panel_model_1 = require("../models/td-load-panel.model");
var core_1 = require("@ngx-translate/core");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var TdLoadPanelService = /** @class */ (function () {
    function TdLoadPanelService(translateService) {
        this.translateService = translateService;
        this.loadPanelParams = new td_load_panel_model_1.TdLoadPanelModel();
    }
    TdLoadPanelService.prototype.showLoadPanel = function (message) {
        var loadingPanelVisible = this.loadPanelParams.visible;
        if (!loadingPanelVisible) {
            if (!message) {
                message = 'appMessages.loading';
            }
            message = this.translateService.instant(message).replace('|', '<br/>');
            this.loadPanelParams = new td_load_panel_model_1.TdLoadPanelModel(true, message);
        }
        return loadingPanelVisible;
    };
    TdLoadPanelService.prototype.hideLoadPanel = function () {
        if (this.loadPanelParams.visible) {
            this.loadPanelParams = new td_load_panel_model_1.TdLoadPanelModel();
        }
    };
    TdLoadPanelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TdLoadPanelService_Factory() { return new TdLoadPanelService(i0.ɵɵinject(i1.TranslateService)); }, token: TdLoadPanelService, providedIn: "root" });
    return TdLoadPanelService;
}());
exports.TdLoadPanelService = TdLoadPanelService;
