"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utility_service_1 = require("@core/utility/utility.service");
var i0 = require("@angular/core");
var UnitFilterService = /** @class */ (function () {
    function UnitFilterService() {
    }
    UnitFilterService.prototype.getOperationUnitListFilter = function (keyIds) {
        var nameOfOrder = utility_service_1.nameOf();
        return [nameOfOrder('keyId'), 'in', keyIds];
    };
    UnitFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnitFilterService_Factory() { return new UnitFilterService(); }, token: UnitFilterService, providedIn: "root" });
    return UnitFilterService;
}());
exports.UnitFilterService = UnitFilterService;
