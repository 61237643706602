"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".filter-button-img[_ngcontent-%COMP%]{position:relative;top:0;left:0}.filter-button-overlay[_ngcontent-%COMP%]{position:absolute;right:2px;top:4px;width:80%}"];
exports.styles = styles;
