import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { ItemViewModel } from '../models/item-view.model';
import { ItemServiceInterface } from './item.service.interface';
import { ItemModel } from '../models/item.model';
import { ItemHelperService } from './item-helper.service';
import { BaseGridArgsModel } from '@core/data-layer/shared/models/base-grid-args.model';
import { TD_MAX_LEVEL_OF_NESTED_TABLES } from '@core/data-layer/shared/models/td.constants';
import { SearchResultModel } from '@core/search/models/search-result.model';
import { SearchHelperService } from '@core/search/services/search-helper.service';
import { ExtendedSearchResultViewModel } from '@core/search/models/search-result-view.model';
import { SearchArgsModel } from '@core/search/models/search-args.model';
import { ItemLocatorGridArgsModel } from '../models/item-locator-args.model';
import { ExternalDocumentInfoListViewModel } from '@core/data-layer/external-documents/models/external-document-list-view.model';
import { ExternalDocumentsHelperService } from '@core/data-layer/external-documents/services/external-documents-helper.service';

@Injectable()
export class HttpItemService implements ItemServiceInterface {

  constructor(
    private http: HttpClient,
    private itemHelperService: ItemHelperService,
    private searchHelperService: SearchHelperService,
    private extDocHelperService: ExternalDocumentsHelperService
  ) { }

  itemsUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.itemsCtrl;
  }

  getItem(itemKeyId: number): Observable<ItemViewModel> {
    return this.http.get<ItemModel>(`${this.itemsUrl()}(${itemKeyId})${API_HUB_PATHS.details}`).pipe(
      map(response => (this.itemHelperService.convertItemToViewModel(response))));
  }

  locateItem(requestArgs: ItemLocatorGridArgsModel, itemKeyId: number): Observable<ExtendedSearchResultViewModel> {
    this.searchHelperService.applyColumnsConfigurations(requestArgs);
    return this.http.post<SearchResultModel>(`${this.itemsUrl()}(${itemKeyId})${API_HUB_PATHS.locate}?maxLevelOfNestedTables=${TD_MAX_LEVEL_OF_NESTED_TABLES}`, requestArgs).pipe(
      map(response => (this.searchHelperService.convertExtendedSearchResultToViewModel(requestArgs as BaseGridArgsModel as SearchArgsModel, response)))
    );
  }

  getExternalDocumentList(requestArgs: BaseGridArgsModel, itemKeyId: number): Observable<ExternalDocumentInfoListViewModel> {
    return this.http.post<any>(`${this.itemsUrl()}(${itemKeyId})${API_HUB_PATHS.externalDocumentList}`, requestArgs).pipe(
      map(response => {
        this.extDocHelperService.convertExternalDocumentsToViewModel(response);
        return response;
      })
    );
  }
}
