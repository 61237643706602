<div *ngIf="defaultFilters" class="row text-danger font-weight-bold">
  {{ 'settings.setDefaultSearchFilters' | translate }}
</div>
<h5 *ngIf="!defaultFilters" class="row font-weight-bold">
  {{ 'settings.searchFilters' | translate }}
</h5>
<div *ngIf="defaultFilters"  class="row setting-title">
  <div class="col">
    {{ 'settings.defaultSearchFilters' | translate }}
  </div>
  <div class="col-4 d-flex justify-content-center">
    {{ 'settings.useSystemDefault' | translate }}
  </div>
</div>
<div class="row pt-3 mb-4">
  <div class="col">
    <dx-check-box
      id="mustBeOrderableFromFactory"
      [text]="'settings.mustBeOrderableFromFactory' | translate"
      [(value)]="userOptions.search_OrderableFromCurrentFactory"
      (onValueChanged)="setChanged()">
    </dx-check-box>
  </div>
  <div *ngIf="defaultFilters" class="col-4 d-flex justify-content-center">
    <dx-check-box
      id="mustBeOrderableFromFactoryDefault"
      [value]="userOptions.search_OrderableFromCurrentFactory === systemDefaultFilters.search_OrderableFromCurrentFactory"
      (onValueChanged)="setDefaultOrderableFromCurrentFactory($event.value); setChanged()">
    </dx-check-box>
  </div>
</div>
<div *ngIf="noSpecifiedCustomerVisible || ownCustomerVisible || ownCustomerSiteVisible" class="row setting-title pt-0">
  {{ (defaultFilters ? 'settings.onlyDisplayProductsFor' : 'settings.searchOnlyInProductsFor') | translate }}
</div>
<div *ngIf="noSpecifiedCustomerVisible" class="row pt-3">
  <div class="col">
    <dx-check-box
      id="noSpecifiedCustomer"
      [text]="'settings.noSpecifiedCustomer' | translate"
      [(value)]="userOptions.display_ProdFiltMethodGeneral"
      (onValueChanged)="setChanged()">
    </dx-check-box>
  </div>
  <div *ngIf="defaultFilters" class="col-4 d-flex justify-content-center">
    <dx-check-box
      id="noSpecifiedCustomerDefault"
      [value]="userOptions.display_ProdFiltMethodGeneral === systemDefaultFilters.display_ProdFiltMethodGeneral"
      (onValueChanged)="setDefaultProdFiltMethodGeneral($event.value); setChanged()">
    </dx-check-box>
  </div>
</div>

<div *ngIf="ownCustomerVisible" class="row pt-3">
  <div class="col">
    <dx-check-box
      id="ownCustomer"
      [text]="'settings.ownCustomer' | translate"
      [(value)]="userOptions.display_ProdFiltMethodCustomer"
      (onValueChanged)="setChanged()">
    </dx-check-box>
  </div>
  <div *ngIf="defaultFilters" class="col-4 d-flex justify-content-center">
    <dx-check-box
      id="ownCustomerDefault"
      [value]="userOptions.display_ProdFiltMethodCustomer === systemDefaultFilters.display_ProdFiltMethodCustomer"
      (onValueChanged)="setDefaultProdFiltMethodCustomer($event.value); setChanged()">
    </dx-check-box>
  </div>
</div>

<div *ngIf="ownCustomerSiteVisible" class="row pt-3  pb-4">
  <div class="col">
    <dx-check-box
      id="ownCustomerSite"
      [text]="'settings.ownCustomerSite' | translate"
      [(value)]="userOptions.display_ProdFiltMethodCustSite"
      (onValueChanged)="setChanged()">
    </dx-check-box>
  </div>
  <div *ngIf="defaultFilters" class="col-4 d-flex justify-content-center">
    <dx-check-box
      id="ownCustomerSiteDefault"
      [value]="userOptions.display_ProdFiltMethodCustSite === systemDefaultFilters.display_ProdFiltMethodCustSite"
      (onValueChanged)="setDefaultProdFiltMethodCustSite($event.value); setChanged()">
    </dx-check-box>
  </div>
</div>
