"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var HttpServerSessionService = /** @class */ (function () {
    function HttpServerSessionService(http) {
        this.http = http;
    }
    HttpServerSessionService.prototype.desktopDataUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.desktopDataCtrl;
    };
    HttpServerSessionService.prototype.getServerDesktopOptions = function () {
        return this.http.get(this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.getServerOptions);
    };
    HttpServerSessionService.prototype.saveServerDesktopOptions = function (options) {
        return this.http.post(this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.saveServerOptions, options);
    };
    return HttpServerSessionService;
}());
exports.HttpServerSessionService = HttpServerSessionService;
