"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var signalr_1 = require("@microsoft/signalr");
var logger_service_1 = require("../logger/logger.service");
var SignalRService = /** @class */ (function () {
    function SignalRService(loggerService) {
        this.loggerService = loggerService;
        this.reconnectTimeout = 0;
    }
    SignalRService.prototype.beforeInitialize = function () {
        // Do nothing. Can override in inherited classes
    };
    SignalRService.prototype.initializeConnection = function () {
        this.beforeInitialize();
        this.createConnection();
        this.startConnection();
    };
    SignalRService.prototype.onConnectionEstablished = function () {
        this.loggerService.log('Hub connection started');
    };
    SignalRService.prototype.onConnectionLost = function (errMsg) {
        this.loggerService.error(errMsg);
    };
    SignalRService.prototype.reconnectToHub = function () {
        if (this.reconnectTimeout > 0) {
            setTimeout(this.startConnection.bind(this), this.reconnectTimeout);
        }
    };
    SignalRService.prototype.getHubServerUrl = function () {
        return '';
    };
    SignalRService.prototype.createConnection = function () {
        if (this.hubConnection) {
            this.hubConnection = null;
        }
        this.hubConnection = new signalr_1.HubConnectionBuilder()
            .withUrl(this.getHubServerUrl())
            /*.configureLogging({
              log: function (logLevel, message) {
                this.loggerService.error(new Date().toISOString() + ": " + message);
              }
            })*/
            .build();
    };
    SignalRService.prototype.startConnection = function () {
        var _this = this;
        this.hubConnection
            .start()
            .then(function () {
            _this.onConnectionEstablished();
        })
            .catch(function () {
            _this.onConnectionLost('Error while establishing connection, retrying...');
            _this.reconnectToHub();
        });
    };
    SignalRService.prototype.stopConnection = function () {
        this.hubConnection.stop();
    };
    SignalRService.prototype.onCloseConnection = function () {
        var _this = this;
        this.hubConnection.onclose(function (err) {
            if (err) {
                _this.onConnectionLost('HubConnection closed with error: ' + err);
                _this.reconnectToHub();
            }
            else {
                _this.loggerService.log('HubConnection disconnected');
            }
        });
    };
    SignalRService.prototype.connectedToHub = function () {
        return (this.hubConnection && (this.hubConnection.state === signalr_1.HubConnectionState.Connected));
    };
    return SignalRService;
}());
exports.SignalRService = SignalRService;
