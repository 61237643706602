import { Params } from '@angular/router';
import { TD_QUERY_PARAMS } from '@core/data-layer/shared/models/td.constants';
import { SearchParam, SearchParamBase, SearchParamTypes } from './search-param.models';

export interface SearchParamString extends SearchParamBase {  
  type: SearchParamTypes;  
  value?: string;
}

export function addStringPropToParams(property: SearchParam, params: Params): Params {
  params[property.urlParamName] = property.value;
  return params;
}

export function extractStringFromParams(paramName: string, params: Params): string {
  return params[TD_QUERY_PARAMS[paramName]] ? params[TD_QUERY_PARAMS[paramName]].toString() : undefined;
}