"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./td-change-password-success.component");
var i2 = require("@angular/router");
var i3 = require("../td-popup/services/td-popup.service");
var i4 = require("@ngx-translate/core");
var styles_ChangePasswordSuccessComponent = [];
var RenderType_ChangePasswordSuccessComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangePasswordSuccessComponent, data: {} });
exports.RenderType_ChangePasswordSuccessComponent = RenderType_ChangePasswordSuccessComponent;
function View_ChangePasswordSuccessComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
exports.View_ChangePasswordSuccessComponent_0 = View_ChangePasswordSuccessComponent_0;
function View_ChangePasswordSuccessComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "td-change-password-success", [], null, null, null, View_ChangePasswordSuccessComponent_0, RenderType_ChangePasswordSuccessComponent)), i0.ɵdid(1, 4243456, null, 0, i1.ChangePasswordSuccessComponent, [i2.ActivatedRoute, i2.Router, i3.TdPopupService, i4.TranslateService], null, null)], null, null); }
exports.View_ChangePasswordSuccessComponent_Host_0 = View_ChangePasswordSuccessComponent_Host_0;
var ChangePasswordSuccessComponentNgFactory = i0.ɵccf("td-change-password-success", i1.ChangePasswordSuccessComponent, View_ChangePasswordSuccessComponent_Host_0, {}, {}, []);
exports.ChangePasswordSuccessComponentNgFactory = ChangePasswordSuccessComponentNgFactory;
