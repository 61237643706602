"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var popup_1 = require("devextreme-angular/ui/popup");
var tree_list_1 = require("devextreme-angular/ui/tree-list");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var grid_column_helper_service_1 = require("@shared/services/grid-column-helper.service");
var TdGridColumnChooserComponent = /** @class */ (function () {
    function TdGridColumnChooserComponent(gridColumnHelperService) {
        this.gridColumnHelperService = gridColumnHelperService;
        this.refreshGridData = new core_1.EventEmitter();
        this.dialogClosed = new core_1.EventEmitter();
    }
    TdGridColumnChooserComponent.prototype.ngOnInit = function () {
        this.extractChooserTableData();
    };
    TdGridColumnChooserComponent.prototype.calcHeightTreeList = function () {
        var popupHeaderAndFooterHeight = 166;
        return .8 * window.innerHeight - popupHeaderAndFooterHeight;
    };
    TdGridColumnChooserComponent.prototype.onClose = function () {
        this.dialogClosed.emit();
    };
    TdGridColumnChooserComponent.prototype.onEditorPrepared = function (event) {
        if (event.parentType === 'headerRow') {
            event.cancel = true;
            event.editorElement.style.display = 'none';
        }
    };
    // Override default value changed functionality for reference nodes with children
    // To prevent default recursive select of all child nested nodes
    TdGridColumnChooserComponent.prototype.onEditorPreparing = function (event) {
        if (event.row && event.row.node.hasChildren) {
            event.editorOptions.onValueChanged = function (e) {
                // If value changed to true - select only childless rows of current node
                if (e.value && (e.previousValue === false) && e.event) {
                    e.event.stopImmediatePropagation();
                    event.component.selectRows(event.row.node.children.filter(function (child) { return !child.hasChildren; }).map(function (child) { return child.key; }), true);
                }
                // If value changed from undefined - deselect all child nodes recursively
                else if ((e.previousValue === undefined) && e.event) {
                    e.event.stopImmediatePropagation();
                    event.component.deselectRows(event.row.node.children.map(function (child) { return child.key; }), true);
                    e.component.option('value', false);
                }
            };
        }
    };
    TdGridColumnChooserComponent.prototype.confirmSelection = function () {
        var _this = this;
        // clear columns configurations "visible" flags
        this.gridStructure.columnsConfigurations.forEach(function (columnConfig) {
            if (_this.gridColumnHelperService.isSelectedColumn(columnConfig) && !columnConfig.calculated) {
                columnConfig.visible = false;
            }
        });
        // set columns configurations "visible" flag based on column chooser
        var uniqueRows = this.columnPickerTreeList.instance.getSelectedRowKeys('leavesOnly');
        uniqueRows.forEach(function (entry) {
            var node = _this.columnPickerTreeList.instance.getNodeByKey(entry);
            var chooserColumn = node.data;
            // check do we have already this selected column in ColumnsConfigurations list
            var columnConfig = _this.gridColumnHelperService.getColumnConfigurationByName(_this.gridStructure.columnsConfigurations, chooserColumn.dataField);
            // in case we have already this selected column in ColumnsConfigurations list - then just set "visible" flag
            if (columnConfig) {
                columnConfig.visible = true;
            }
            else {
                // prepare display name
                var displayName = chooserColumn.displayName;
                var parent_1 = node.parent;
                while (parent_1 && parent_1.data) {
                    var parentChooserColumn = parent_1.data;
                    displayName = parentChooserColumn.displayName + '.' + displayName;
                    parent_1 = parent_1.parent;
                }
                // create new ColumnConfig and add in ColumnsConfigurations list
                columnConfig = {
                    dataField: chooserColumn.dataField,
                    dataType: chooserColumn.dataType,
                    displayName: displayName,
                    calculated: chooserColumn.calculated,
                    userField: chooserColumn.userField,
                    enumName: chooserColumn.enumName,
                    visible: true
                };
                _this.gridColumnHelperService.addCustomColumn(_this.gridStructure.columnsConfigurations, columnConfig);
            }
        });
        // call refresh grid event
        this.refreshGridData.emit();
        // hide column chooser
        this.popup.instance.hide();
    };
    TdGridColumnChooserComponent.prototype.prepareColumnsTreeListData = function (tableName, dataFieldPrefix, chooserData, selectedKeys, parentId, levelOfTable) {
        var _this = this;
        if (levelOfTable <= td_constants_1.TD_MAX_LEVEL_OF_NESTED_TABLES) {
            var table = this.gridStructure.tables.find(function (gridTable) { return gridTable.tableName === tableName; });
            if (table) {
                table.columns.forEach(function (column) {
                    // skip user-field columns that haven't been defined by display-name and reference colums on the last nested table
                    if ((!column.userField || column.displayName) && !(column.referenceTo && (levelOfTable === td_constants_1.TD_MAX_LEVEL_OF_NESTED_TABLES))) {
                        _this.id++;
                        var chooserColumn = {
                            // properties of base interface
                            dataField: dataFieldPrefix + column.dataField,
                            dataType: column.dataType,
                            displayName: column.displayName,
                            calculated: column.calculated,
                            userField: column.userField,
                            enumName: column.enumName,
                            // own properties
                            id: _this.id,
                            parentId: parentId
                        };
                        chooserData.push(chooserColumn);
                        // remember selected row key
                        var columnConfig = _this.gridColumnHelperService.getColumnConfigurationByName(_this.gridStructure.columnsConfigurations, dataFieldPrefix + column.dataField);
                        if (columnConfig && columnConfig.visible) {
                            selectedKeys.push(_this.id);
                        }
                        // check whether existing sub table
                        if (column.referenceTo) {
                            _this.prepareColumnsTreeListData(column.referenceTo, dataFieldPrefix + column.dataField + '.', chooserData, selectedKeys, chooserColumn.id, levelOfTable + 1);
                        }
                    }
                });
            }
        }
    };
    TdGridColumnChooserComponent.prototype.extractChooserTableData = function () {
        var levelOfTable = 1;
        this.id = 0;
        var selectedKeys = [];
        var chooserData = [];
        this.prepareColumnsTreeListData(this.mainTable, '', chooserData, selectedKeys, this.id, levelOfTable);
        this.chooserColumnData = chooserData;
        this.selectedRowKeys = selectedKeys;
    };
    TdGridColumnChooserComponent.prototype.onResize = function () {
        if (this.columnPickerTreeList) {
            this.columnPickerTreeList.height = this.calcHeightTreeList();
        }
    };
    return TdGridColumnChooserComponent;
}());
exports.TdGridColumnChooserComponent = TdGridColumnChooserComponent;
