import { Injectable } from '@angular/core';
import { NO_ERROR_CODE } from '@core/data-layer/shared/models/td.constants';
import { ApiResponse, ApiDataResponse } from '@core/data-layer/shared/models/api-response.model';
import { TdPopupService } from '../components/td-popup/services/td-popup.service';

@Injectable({providedIn: 'root'})
export class ProductionErrorService {
  
  constructor(
    private popupService: TdPopupService
  ) { }

  /*
    Returns true if: the response object exists, and does not contain any errors
    returns false if: the response object contains an error, or it does not exist    
      - if response object exists and contains a message, call poopupService to display it
    - NB: Not every implementation cares about the returned value if it's false
  */
  handleResponseErrors(response: ApiResponse | ApiDataResponse<number>): boolean {
    if (response && response.error === NO_ERROR_CODE) {
      return true;
    }
    else {
      if (response && response.message) {
        this.popupService.showInfo(response.message);
      }
      return false;
    }    
  }  
}