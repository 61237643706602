"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var popup_1 = require("devextreme-angular/ui/popup");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var app_constants_1 = require("@app/shared/models/app.constants");
var session_helper_service_1 = require("@app/shared/services/session-helper.service");
var user_session_actions_1 = require("@core/data-layer/user-session/store/user-session.actions");
var TdMediaViewerComponent = /** @class */ (function () {
    function TdMediaViewerComponent(mediaService, userSessionService, sessionHelperService, store) {
        this.mediaService = mediaService;
        this.userSessionService = userSessionService;
        this.sessionHelperService = sessionHelperService;
        this.store = store;
        this.mediaButtonsWidth = 36;
        this.thumbnailButtonsWidth = 36 + 10 + 36;
        this.thumbnailWidth = 70;
        this.thumbnailHeight = 70;
        this.popupHeight = '80vh';
        this.popupWidth = '60vw';
        this.firstIndex = 0;
        this.thumbnailNavigationWidth = 200;
        this.mediaVisibleChange = new core_1.EventEmitter();
        this.multiMediaType = td_enumerations_1.MultiMediaType;
        this.tableTypes = td_enumerations_1.TableTypes;
        this.swiperMediaIndex = 0;
        this.displayMediaViewer = true;
        this.moreThanOneMedia = true;
        this.showThumbnails = true;
        this.showMediaViewer = false;
    }
    TdMediaViewerComponent.prototype.ngOnInit = function () {
        this.userOptions = tslib_1.__assign({}, this.sessionHelperService.userOptions);
        this.getData();
        this.initAutoStartVideoFlag();
    };
    TdMediaViewerComponent.prototype.getData = function () {
        var _this = this;
        this.mediaService.loadMediaInfoList(this.tableType, this.mediaSeries, this.refKeyId).subscribe(function (response) {
            var relevantMediaSeries = [];
            var _loop_1 = function (type) {
                relevantMediaSeries = response.filter(function (entry) { return entry.linkType === type; });
                if (relevantMediaSeries.length > 0) {
                    return "break";
                }
            };
            for (var _i = 0, MEDIA_SERIES_PRIORITY_1 = app_constants_1.MEDIA_SERIES_PRIORITY; _i < MEDIA_SERIES_PRIORITY_1.length; _i++) {
                var type = MEDIA_SERIES_PRIORITY_1[_i];
                var state_1 = _loop_1(type);
                if (state_1 === "break")
                    break;
            }
            _this.handleMediaInfoResponse(relevantMediaSeries);
        });
    };
    TdMediaViewerComponent.prototype.handleMediaInfoResponse = function (data) {
        var _this = this;
        this.mediaItems = [];
        data.forEach(function (entry) {
            var mediaItem = {
                keyId: entry.keyId,
                uniqueIndex: entry.position,
                types: entry.types,
                mediaType: null
            };
            _this.getMediaThumbnail(mediaItem);
            _this.mediaItems.push(mediaItem);
        });
        this.getMedia(this.mediaItems[0]);
        if (this.mediaItems.length < 2) {
            this.moreThanOneMedia = false;
        }
        this.lastIndex = this.mediaItems.length - 1;
        if (this.mediaItems.length > 0) {
            this.currentMediaItem = this.mediaItems[0];
            this.autoStartStopPlayingVideo();
        }
    };
    TdMediaViewerComponent.prototype.resetMediaIndex = function () {
        this.mediaIndex = 0;
    };
    TdMediaViewerComponent.prototype.initSwiperConfig = function () {
        this.swiperConfig = {
            centeredSlides: true,
            spaceBetween: 10,
            slidesPerView: 1,
            watchOverflow: true,
            observer: true,
            navigation: true
        };
    };
    TdMediaViewerComponent.prototype.mediaIndexChange = function (index) {
        var _this = this;
        var mediaToLoad = this.mediaItems[index];
        if (!mediaToLoad.video || !mediaToLoad.image) {
            this.getMedia(mediaToLoad);
        }
        if (this.moreThanOneMedia && this.showThumbnails) {
            this.swiperMediaIndex = index;
            var element = document.getElementById('thumbnail-img-' + index);
            element.scrollIntoView();
        }
        this.currentMediaItem = this.mediaItems[index];
        setTimeout(function () {
            _this.autoStartStopPlayingVideo();
        });
    };
    // CURRENTLY NOT USED
    TdMediaViewerComponent.prototype.startResizeMediaViewer = function () {
        // const currentImageIndex = this.swiperMediaIndex;    
        // this.resizeCurentSlideIndex = currentImageIndex;
        // this.swapArrayPosition(this.mediaItems, this.firstIndex, currentImageIndex);
        // this.swiperMediaIndex = this.firstIndex;
    };
    // CURRENTLY NOT USED
    TdMediaViewerComponent.prototype.endResizeMediaViewer = function () {
        // this.swapArrayPosition(this.mediaItems, this.firstIndex, this.resizeCurentSlideIndex);    
        // this.mediaIndexChange(this.resizeCurentSlideIndex);
        // /* 
        //   Explaining following if statement
        //   Issue: When we use Swiper API, automatic centering of slides does not perform correctly, if we are at index 0.
        //   Solution: The slide will be centered correctly, after navigating 1 slide forward and 1 back.
        //   Implementation: We change the index 1 forward, and after 10 miliseconds we change it back.
        // */
        // if (this.moreThanOneMedia && this.swiperMediaIndex === this.firstIndex) {   
        // this.swiperMediaIndex++;
        // setTimeout(() => {
        //   this.swiperMediaIndex--;
        // }, 10);      
        // }
    };
    TdMediaViewerComponent.prototype.swapArrayPosition = function (array, indexOne, indexTwo) {
        var _a;
        _a = [array[indexTwo], array[indexOne]], array[indexOne] = _a[0], array[indexTwo] = _a[1];
    };
    TdMediaViewerComponent.prototype.thumbnailClick = function (mediaIndex) {
        this.swiperMediaIndex = mediaIndex;
    };
    TdMediaViewerComponent.prototype.nextThumb = function () {
        this.swiperMediaIndex++;
    };
    TdMediaViewerComponent.prototype.previousThumb = function () {
        this.swiperMediaIndex--;
    };
    TdMediaViewerComponent.prototype.lastThumb = function () {
        this.swiperMediaIndex = this.lastIndex;
    };
    TdMediaViewerComponent.prototype.firstThumb = function () {
        this.swiperMediaIndex = this.firstIndex;
    };
    TdMediaViewerComponent.prototype.nextMedia = function () {
        this.swiperMediaIndex++;
    };
    TdMediaViewerComponent.prototype.closeMediaViewer = function () {
        this.mediaVisible = false;
        this.mediaVisibleChange.emit(this.mediaVisible);
    };
    TdMediaViewerComponent.prototype.getMedia = function (mediaItem) {
        var _this = this;
        mediaItem.types.forEach(function (type) {
            switch (type.type) {
                case 'Image': {
                    if (type.identifier === 'thumbnail') {
                        break;
                    }
                    _this.getMediaImage(mediaItem);
                    break;
                }
                case 'Text': {
                    _this.getMediaText(mediaItem);
                    break;
                }
                case 'Video': {
                    mediaItem.videoFileType = type.format.toLowerCase();
                    _this.getMediaVideo(mediaItem);
                    break;
                }
            }
        });
    };
    TdMediaViewerComponent.prototype.getMediaThumbnail = function (mediaItem) {
        this.mediaService.getMediaThumbnail(mediaItem.keyId).subscribe(function (thumbnail) {
            mediaItem.thNImage = thumbnail;
        });
    };
    TdMediaViewerComponent.prototype.getMediaImage = function (mediaItem) {
        var _this = this;
        this.mediaService.getMediaImage(mediaItem.keyId).subscribe(function (data) {
            mediaItem.image = data;
            mediaItem.mediaType = td_enumerations_1.MultiMediaType.Picture;
            _this.assignSwiperWidth();
            _this.showMediaViewerIfData();
        });
    };
    TdMediaViewerComponent.prototype.getMediaText = function (mediaItem) {
        this.mediaService.getMediaText(mediaItem.keyId).subscribe(function (data) {
            mediaItem.text = data;
        });
    };
    TdMediaViewerComponent.prototype.getMediaVideo = function (mediaItem) {
        mediaItem.video = this.mediaService.getMediaVideo(mediaItem.keyId);
        mediaItem.mediaType = td_enumerations_1.MultiMediaType.Video;
        this.assignSwiperWidth();
        this.showMediaViewerIfData();
    };
    TdMediaViewerComponent.prototype.isVideoTypeSupported = function (type) {
        return type === '.mov' || type === '.mp4' ? true : false;
    };
    TdMediaViewerComponent.prototype.assignSwiperWidth = function () {
        var _this = this;
        if (!this.swiperWidth) {
            this.initSwiperConfig();
            setTimeout(function () {
                _this.getPopupSize();
            }, 50);
        }
    };
    TdMediaViewerComponent.prototype.showMediaViewerIfData = function () {
        if (!this.showMediaViewer) {
            this.showMediaViewer = true;
        }
    };
    TdMediaViewerComponent.prototype.getPopupSize = function () {
        this.swiperWidth = this.popupRef.instance.content().clientWidth;
    };
    TdMediaViewerComponent.prototype.initAutoStartVideoFlag = function () {
        this.autoStartVideo =
            (this.sessionHelperService.profileData.system_CanChangeVideoAutoplay && this.userOptions.mediaAutoPlayVideos) ||
                (!this.sessionHelperService.profileData.system_CanChangeVideoAutoplay &&
                    (this.sessionHelperService.customerFactories.length > 0) &&
                    this.sessionHelperService.customerFactories[0].autoPlayVideos);
    };
    TdMediaViewerComponent.prototype.setMediaViewOptionsVisibility = function () {
        this.showMediaViewOptions = this.sessionHelperService.profileData.system_CanChangeVideoAutoplay &&
            (this.currentMediaItem.mediaType === td_enumerations_1.MultiMediaType.Video) &&
            this.isVideoTypeSupported(this.currentMediaItem.videoFileType);
    };
    TdMediaViewerComponent.prototype.autoStartStopPlayingVideo = function () {
        this.setMediaViewOptionsVisibility();
        if (this.mediaVideoRef) {
            if (this.showMediaViewOptions ||
                ((this.currentMediaItem.mediaType === td_enumerations_1.MultiMediaType.Video) &&
                    this.isVideoTypeSupported(this.currentMediaItem.videoFileType) && this.autoStartVideo)) {
                this.mediaVideoRef.nativeElement.load();
            }
            else {
                this.mediaVideoRef.nativeElement.pause();
            }
        }
    };
    TdMediaViewerComponent.prototype.autoPlayVideosChanged = function () {
        var _this = this;
        this.initAutoStartVideoFlag();
        this.userSessionService.saveUserOptions(this.userOptions).subscribe(function () {
            _this.store.dispatch(new user_session_actions_1.LoadUserOptions());
        });
    };
    return TdMediaViewerComponent;
}());
exports.TdMediaViewerComponent = TdMediaViewerComponent;
