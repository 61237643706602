"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var devextreme_angular_1 = require("devextreme-angular");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var search_manager_service_1 = require("@core/search/services/search-manager.service");
var td_drop_down_grid_component_1 = require("@shared/components/td-drop-down-grid/td-drop-down-grid.component");
var td_popup_service_1 = require("@shared/components/td-popup/services/td-popup.service");
var utility_service_1 = require("@core/utility/utility.service");
var view_helper_service_1 = require("@core/data-layer/shared/services/view-helper.service");
var AddUnitComponent = /** @class */ (function () {
    function AddUnitComponent(operationDataService, searchManagerService, popupService, viewHelperService) {
        this.operationDataService = operationDataService;
        this.searchManagerService = searchManagerService;
        this.popupService = popupService;
        this.viewHelperService = viewHelperService;
        this.unitAdded = new core_1.EventEmitter();
        this.mainTable = td_constants_1.TD_MODEL_NAMES.unitModel;
        this.gridIdentifier = td_constants_1.TD_DESKTOP_IDENTIFIERS.operationUnitSearchListGrid;
        this.unitDisplayExpr = this.unitDisplayExpr.bind(this);
        this.initUnitSearchRequestArgs = this.initUnitSearchRequestArgs.bind(this);
    }
    AddUnitComponent.prototype.ngOnInit = function () {
        //
    };
    AddUnitComponent.prototype.initUnitSearchRequestArgs = function (gridStructure) {
        return this.searchManagerService.initWebOperationUnitsSearchRequestArgs(gridStructure, this.operationKeyId, true);
    };
    AddUnitComponent.prototype.onSearchResultCleared = function () {
        //
    };
    AddUnitComponent.prototype.isUnitSelected = function () {
        if (this.unitsSearchDropDownGrid) {
            return this.unitsSearchDropDownGrid.searchRowSelected();
        }
        else {
            return false;
        }
    };
    AddUnitComponent.prototype.unitDisplayExpr = function (unitData) {
        return unitData && unitData.keyId;
    };
    AddUnitComponent.prototype.addUnit = function () {
        var _this = this;
        var nameOfUnit = utility_service_1.nameOf();
        this.operationDataService.addUnit(this.operationKeyId, this.unitsSearchDropDownGrid.selectedData[nameOfUnit('keyId')]).subscribe(function (response) {
            if (response.error === td_constants_1.NO_ERROR_CODE) {
                // unit added successfully
                _this.unitAdded.emit(_this.itemInstrumentTypeSelected);
            }
            else {
                // there occurred an error while trying to add unit to operation
                _this.popupService.showInfo(response.message);
            }
        });
        this.unitsSearchDropDownGrid.clearSearchResult();
    };
    AddUnitComponent.prototype.catchUnitSelected = function (unitSelected) {
        this.itemInstrumentTypeSelected = this.viewHelperService.getItemType(unitSelected.prod.item);
        this.addUnitButton.instance.focus();
    };
    return AddUnitComponent;
}());
exports.AddUnitComponent = AddUnitComponent;
