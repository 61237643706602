"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var server_session_actions_1 = require("./server-session.actions");
var ServerSessionEffects = /** @class */ (function () {
    function ServerSessionEffects(actions$, serverSessionService, clientConfigurationService, licenseService) {
        var _this = this;
        this.actions$ = actions$;
        this.serverSessionService = serverSessionService;
        this.clientConfigurationService = clientConfigurationService;
        this.licenseService = licenseService;
        this.loadServerDesktopOptions$ = this.actions$
            .pipe(effects_1.ofType(server_session_actions_1.ServerSessionActionTypes.LoadServerDesktopOptions), operators_1.mergeMap(function () {
            return _this.serverSessionService.getServerDesktopOptions()
                .pipe(operators_1.map(function (response) { return ({ type: server_session_actions_1.ServerSessionActionTypes.SetServerDesktopOptions, payload: response }); }), operators_1.catchError(function () { return rxjs_1.of({ type: server_session_actions_1.ServerSessionActionTypes.SetServerDesktopOptions }); }));
        }));
        this.loadClientConfiguration$ = this.actions$
            .pipe(effects_1.ofType(server_session_actions_1.ServerSessionActionTypes.LoadClientConfiguration), operators_1.mergeMap(function () {
            return _this.clientConfigurationService.getClientConfiguration()
                .pipe(operators_1.map(function (response) { return ({ type: server_session_actions_1.ServerSessionActionTypes.SetClientConfiguration, payload: response }); }), operators_1.catchError(function () { return rxjs_1.of({ type: server_session_actions_1.ServerSessionActionTypes.SetClientConfiguration }); }));
        }));
        this.loadLicense$ = this.actions$
            .pipe(effects_1.ofType(server_session_actions_1.ServerSessionActionTypes.LoadLicense), operators_1.mergeMap(function () {
            return _this.licenseService.getLicense()
                .pipe(operators_1.map(function (response) { return ({ type: server_session_actions_1.ServerSessionActionTypes.SetLicense, payload: response }); }), operators_1.catchError(function () { return rxjs_1.of({ type: server_session_actions_1.ServerSessionActionTypes.SetLicense }); }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServerSessionEffects.prototype, "loadServerDesktopOptions$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServerSessionEffects.prototype, "loadClientConfiguration$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ServerSessionEffects.prototype, "loadLicense$", void 0);
    return ServerSessionEffects;
}());
exports.ServerSessionEffects = ServerSessionEffects;
