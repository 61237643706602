"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var web_order_model_1 = require("./web-order.model");
var WebOrderViewModel = /** @class */ (function (_super) {
    tslib_1.__extends(WebOrderViewModel, _super);
    function WebOrderViewModel() {
        var _this = _super.call(this) || this;
        _this.details = new WebOrderDetailsViewModel();
        _this.lines = new Array();
        return _this;
    }
    return WebOrderViewModel;
}(web_order_model_1.WebOrderModel));
exports.WebOrderViewModel = WebOrderViewModel;
var WebOrderDetailsViewModel = /** @class */ (function (_super) {
    tslib_1.__extends(WebOrderDetailsViewModel, _super);
    function WebOrderDetailsViewModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WebOrderDetailsViewModel;
}(web_order_model_1.WebOrderDetailsModel));
exports.WebOrderDetailsViewModel = WebOrderDetailsViewModel;
var WebOrderLineViewModel = /** @class */ (function (_super) {
    tslib_1.__extends(WebOrderLineViewModel, _super);
    function WebOrderLineViewModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WebOrderLineViewModel;
}(web_order_model_1.WebOrderLineModel));
exports.WebOrderLineViewModel = WebOrderLineViewModel;
