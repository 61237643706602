"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var core_1 = require("@ngx-translate/core");
var td_popup_service_1 = require("../td-popup/services/td-popup.service");
var ChangePasswordSuccessComponent = /** @class */ (function () {
    function ChangePasswordSuccessComponent(route, router, popup, translateService) {
        this.route = route;
        this.router = router;
        this.popup = popup;
        this.translateService = translateService;
    }
    ChangePasswordSuccessComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.popup.showInfo(this.translateService.instant('settings.passwordChanged'));
        this.route.queryParams.subscribe(function (params) {
            _this.router.navigate([params['redirectUrl']]);
        });
    };
    return ChangePasswordSuccessComponent;
}());
exports.ChangePasswordSuccessComponent = ChangePasswordSuccessComponent;
