"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var server_session_actions_1 = require("@core/data-layer/server-session/store/server-session.actions");
var server_session_selectors_1 = require("@core/data-layer/server-session/store/server-session.selectors");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var ClientConfigurationInitializer = /** @class */ (function () {
    function ClientConfigurationInitializer(store) {
        this.store = store;
    }
    ClientConfigurationInitializer.prototype.initializeClientConfiguration = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.store.dispatch(new server_session_actions_1.LoadClientConfiguration());
            _this.store.pipe(store_1.select(server_session_selectors_1.selectClientConfiguration)).subscribe(function (configuration) {
                if (configuration) {
                    resolve(configuration);
                }
            });
        });
    };
    ClientConfigurationInitializer.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientConfigurationInitializer_Factory() { return new ClientConfigurationInitializer(i0.ɵɵinject(i1.Store)); }, token: ClientConfigurationInitializer, providedIn: "root" });
    return ClientConfigurationInitializer;
}());
exports.ClientConfigurationInitializer = ClientConfigurationInitializer;
