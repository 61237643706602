import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { OrderTemplateServiceInterface } from './order-template.service.interface';
import { OrderTemplateModel } from '../models/order-template.model';
import { OrderTemplateHelperService } from './order-template-helper.service';
import { OrderTemplateViewModel } from '../models/order-template-view.model';
import { OrderTemplateArticleModel } from '../models/order-template-article.model';

@Injectable({
  providedIn: 'root'
})
export class HttpOrderTemplateService implements OrderTemplateServiceInterface {

  constructor(
    private http: HttpClient,
    private orderTemplateHelperService: OrderTemplateHelperService
  ) { }

  templatesUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.orderTemplatesCtrl;
  }

  getOrderTemplate(orderTemplateKeyId: number): Observable<OrderTemplateViewModel> {
    return this.http.get<OrderTemplateModel>(`${this.templatesUrl()}(${orderTemplateKeyId})${API_HUB_PATHS.details}`).pipe(
      map(response => (this.orderTemplateHelperService.convertOrderTemplateToViewModel(response)))
    );
  }

  getOrderTemplateArticles(orderTemplateKeyId: number): Observable<OrderTemplateArticleModel[]> {
    return this.http.get<OrderTemplateArticleModel[]>(`${this.templatesUrl()}(${orderTemplateKeyId})${API_HUB_PATHS.articles}`);
  }

  getOrderTemplateCustomer(orderTemplateKeyId: number): Observable<number> {
    return this.http.get<number>(`${this.templatesUrl()}(${orderTemplateKeyId})${API_HUB_PATHS.customerKeyId}`);
  }
}
