"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./td-grid-media-wrapper.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/common");
var i4 = require("../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i5 = require("devextreme-angular/core");
var i6 = require("devextreme-angular/ui/button");
var i7 = require("@angular/platform-browser");
var i8 = require("../../../../../node_modules/devextreme-angular/ui/popover/devextreme-angular-ui-popover.ngfactory");
var i9 = require("devextreme-angular/ui/popover");
var i10 = require("../td-media-viewer/td-media-viewer.component.ngfactory");
var i11 = require("../td-media-viewer/td-media-viewer.component");
var i12 = require("../../services/session-helper.service");
var i13 = require("@ngrx/store");
var i14 = require("./td-grid-media-wrapper.component");
var styles_TdGridMediaWrapperComponent = [i0.styles];
var RenderType_TdGridMediaWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TdGridMediaWrapperComponent, data: {} });
exports.RenderType_TdGridMediaWrapperComponent = RenderType_TdGridMediaWrapperComponent;
function View_TdGridMediaWrapperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "popover-img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popOverImg; _ck(_v, 1, 0, currVal_0); }); }
function View_TdGridMediaWrapperComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "no-preview"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("appCommon.noMediaPreview")); _ck(_v, 2, 0, currVal_0); }); }
function View_TdGridMediaWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "popover d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdGridMediaWrapperComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdGridMediaWrapperComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popOverImg; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.noThumbnailExists; _ck(_v, 4, 0, currVal_1); }, null); }
function View_TdGridMediaWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "cell-center"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.mediaHoverEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.mediaHoverExit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "dx-button", [["class", "grid-action-button"], ["icon", ".\\assets\\img\\media.svg"]], [[1, "id", 0]], [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.showMediaViewer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DxButtonComponent_0, i4.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i5.DxTemplateHost, i5.DxTemplateHost, []), i1.ɵprd(512, null, i5.WatcherHelper, i5.WatcherHelper, []), i1.ɵprd(512, null, i5.NestedOptionHost, i5.NestedOptionHost, []), i1.ɵdid(5, 7323648, [["gridButton", 4]], 0, i6.DxButtonComponent, [i1.ElementRef, i1.NgZone, i5.DxTemplateHost, i5.WatcherHelper, i5.NestedOptionHost, i7.TransferState, i1.PLATFORM_ID], { icon: [0, "icon"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(6, 0, null, null, 8, "dx-popover", [["position", "right"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.popOverVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DxPopoverComponent_0, i8.RenderType_DxPopoverComponent)), i1.ɵprd(512, null, i5.DxTemplateHost, i5.DxTemplateHost, []), i1.ɵprd(512, null, i5.WatcherHelper, i5.WatcherHelper, []), i1.ɵprd(512, null, i5.IterableDifferHelper, i5.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i5.NestedOptionHost, i5.NestedOptionHost, []), i1.ɵdid(11, 7323648, [["popOver", 4]], 1, i9.DxPopoverComponent, [i1.ElementRef, i1.NgZone, i5.DxTemplateHost, i5.WatcherHelper, i5.IterableDifferHelper, i5.NestedOptionHost, i7.TransferState, i1.PLATFORM_ID], { height: [0, "height"], position: [1, "position"], target: [2, "target"], visible: [3, "visible"], width: [4, "width"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { toolbarItemsChildren: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TdGridMediaWrapperComponent_2)), i1.ɵdid(14, 16384, null, 0, i5.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i5.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ".\\assets\\img\\media.svg"; _ck(_v, 5, 0, currVal_1); var currVal_2 = 110; var currVal_3 = "right"; var currVal_4 = _co.popOverTarget; var currVal_5 = _co.popOverVisible; var currVal_6 = 140; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = "content"; _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonId; _ck(_v, 1, 0, currVal_0); }); }
function View_TdGridMediaWrapperComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td-media-viewer", [], null, [[null, "mediaVisibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mediaVisibleChange" === en)) {
        var pd_0 = ((_co.displayMediaViewer = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_TdMediaViewerComponent_0, i10.RenderType_TdMediaViewerComponent)), i1.ɵdid(2, 114688, null, 0, i11.TdMediaViewerComponent, ["MEDIA_SERVICE", "USER_SESSION_SERVICE", i12.SessionHelperService, i13.Store], { mediaVisible: [0, "mediaVisible"], tableType: [1, "tableType"], refKeyId: [2, "refKeyId"], mediaTitle: [3, "mediaTitle"], mediaSeries: [4, "mediaSeries"], mediaIndex: [5, "mediaIndex"] }, { mediaVisibleChange: "mediaVisibleChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayMediaViewer; var currVal_1 = _co.tableType; var currVal_2 = _co.keyId; var currVal_3 = _co.mediaTitle; var currVal_4 = _co.mediaSeries; var currVal_5 = _co.mediaIndex; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_TdGridMediaWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdGridMediaWrapperComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdGridMediaWrapperComponent_5)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.picsKeyId; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.displayMediaViewer; _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_TdGridMediaWrapperComponent_0 = View_TdGridMediaWrapperComponent_0;
function View_TdGridMediaWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-grid-media-wrapper", [], null, null, null, View_TdGridMediaWrapperComponent_0, RenderType_TdGridMediaWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i14.TdGridMediaWrapperComponent, ["MEDIA_SERVICE"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TdGridMediaWrapperComponent_Host_0 = View_TdGridMediaWrapperComponent_Host_0;
var TdGridMediaWrapperComponentNgFactory = i1.ɵccf("td-grid-media-wrapper", i14.TdGridMediaWrapperComponent, View_TdGridMediaWrapperComponent_Host_0, { picsKeyId: "picsKeyId", mediaIndex: "mediaIndex", mediaItem: "mediaItem", mediaTitle: "mediaTitle", mediaSeries: "mediaSeries", tableType: "tableType", keyId: "keyId", tabIndex: "tabIndex" }, {}, []);
exports.TdGridMediaWrapperComponentNgFactory = TdGridMediaWrapperComponentNgFactory;
