import { Injectable, Inject } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { ServerSessionActionTypes } from './server-session.actions';
import { ServerSessionServiceInterface } from '../services/server-session.service.interface';
import { LicenseServiceInterface } from '@core/license/services/license.service.interface';
import { ClientConfigurationServiceInterface } from '@core/client-configuration/services/client-configuration.service.interface';

@Injectable()
export class ServerSessionEffects {

  constructor(
    private actions$: Actions,
    @Inject('SERVER_SESSION_SERVICE') private serverSessionService: ServerSessionServiceInterface,
    @Inject('CLIENT_CONFIGURATION') private clientConfigurationService: ClientConfigurationServiceInterface,
    @Inject('LICENSE_SERVICE') private licenseService: LicenseServiceInterface) {
  }

  @Effect()
  loadServerDesktopOptions$ = this.actions$
    .pipe(
      ofType(ServerSessionActionTypes.LoadServerDesktopOptions),
      mergeMap(() => {
        return this.serverSessionService.getServerDesktopOptions()
          .pipe(
            map(response => ({ type: ServerSessionActionTypes.SetServerDesktopOptions, payload: response })),
            catchError(() => of({ type: ServerSessionActionTypes.SetServerDesktopOptions }))
          );
      })
    );

  @Effect()
  loadClientConfiguration$ = this.actions$
    .pipe(
      ofType(ServerSessionActionTypes.LoadClientConfiguration),
      mergeMap(() => {
        return this.clientConfigurationService.getClientConfiguration()
          .pipe(
            map(response => ({ type: ServerSessionActionTypes.SetClientConfiguration, payload: response })), 
            catchError(() => of({ type: ServerSessionActionTypes.SetClientConfiguration }))
          );
      })
    );

  @Effect()
  loadLicense$ = this.actions$
    .pipe(
      ofType(ServerSessionActionTypes.LoadLicense),
      mergeMap(() => {
        return this.licenseService.getLicense()
          .pipe(
            map(response => ({ type: ServerSessionActionTypes.SetLicense, payload: response })),
            catchError(() => of({ type: ServerSessionActionTypes.SetLicense }))
          );
      })
    );
}
