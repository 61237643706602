"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var operators_1 = require("rxjs/operators");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var server_desktop_options_model_1 = require("@core/data-layer/server-session/models/server-desktop-options.model");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var ServerSettingsComponent = /** @class */ (function () {
    function ServerSettingsComponent(serverSessionService, unitService, sessionHelperService, translateService) {
        this.serverSessionService = serverSessionService;
        this.unitService = unitService;
        this.sessionHelperService = sessionHelperService;
        this.translateService = translateService;
        this.settingsChangedChange = new core_1.EventEmitter();
        this.WebOrderMode = td_enumerations_1.WebOrderMode;
        this.WebDisplayPriceMode = td_enumerations_1.WebDisplayPriceMode;
        this.WebDisplayGeneralProductsMode = td_enumerations_1.WebDisplayGeneralProductsMode;
        this.ProdOverviewVisibilityMode = td_enumerations_1.ProdOverviewVisibilityMode;
        this.WebEditOprationArticlesMode = td_enumerations_1.WebEditOprationArticlesMode;
        this.WebStartStopOperationMode = td_enumerations_1.WebStartStopOperationMode;
    }
    ServerSettingsComponent.prototype.ngOnInit = function () {
        this.serverOptions = Object.assign({}, this.sessionHelperService.serverOptions);
        this.initView();
    };
    ServerSettingsComponent.prototype.initView = function () {
        var _this = this;
        this.loadDisplayCustomerProducts();
        this.loadPasswordVerificationMethods();
        this.loadVerificationControls();
        this.verificationDisabled = this.sessionHelperService.clientConfiguration.passwordVerify !== td_enumerations_1.PasswordVerify.ActiveDirectory;
        this.factTrackCodes$ = this.unitService.getFastTrackCodes().pipe(operators_1.map(function (codes) {
            var factTrackValues = codes.map(function (code) { return ({ key: code.keyId, value: code.code + " - " + code.name }); });
            factTrackValues.unshift({ key: 0, value: _this.translateService.instant('settings.none') });
            return factTrackValues;
        }));
    };
    ServerSettingsComponent.prototype.loadVerificationControls = function () {
        this.passVerification = this.sessionHelperService.getPassVerifMethod(this.serverOptions);
        this.blankPasswordsDisabled = this.passVerification === td_enumerations_1.PasswordVerificationMethod.ActiveDirectory;
    };
    ServerSettingsComponent.prototype.loadDisplayCustomerProducts = function () {
        this.displayCustomerProducts = [
            { key: td_enumerations_1.WebDisplayCustomerProductsMode.All, value: this.translateService.instant('settings.all') },
            { key: td_enumerations_1.WebDisplayCustomerProductsMode.OwnSiteOrCust, value: this.translateService.instant('settings.ownSiteOrCust') },
            { key: td_enumerations_1.WebDisplayCustomerProductsMode.OwnSiteOnly, value: this.translateService.instant('settings.ownSiteOnly') },
            { key: td_enumerations_1.WebDisplayCustomerProductsMode.OwnOnly, value: this.translateService.instant('settings.ownOnly') }
        ];
    };
    ServerSettingsComponent.prototype.loadPasswordVerificationMethods = function () {
        this.passwordVerificationMethods = [
            { key: td_enumerations_1.PasswordVerificationMethod.TDOC, value: this.translateService.instant('settings.tdoc') },
            { key: td_enumerations_1.PasswordVerificationMethod.ActiveDirectory, value: this.translateService.instant('settings.activeDirectory') }
        ];
    };
    ServerSettingsComponent.prototype.passwordVerificationMethodChanged = function (value) {
        this.serverOptions.passwordVerificationMethod = value;
        if ([td_enumerations_1.LoginCookieMethod.UserNamePassword, td_enumerations_1.LoginCookieMethod.Autologin].includes(this.serverOptions.loginCookieMethod)) {
            this.serverOptions.loginCookieMethod = td_enumerations_1.LoginCookieMethod.UserName;
        }
        this.loadVerificationControls();
        this.setChanged();
    };
    ServerSettingsComponent.prototype.allowStartAndStopChanged = function (allowStartAndStop) {
        this.serverOptions.startStopOperationMode =
            allowStartAndStop ? td_enumerations_1.WebStartStopOperationMode.Allow : td_enumerations_1.WebStartStopOperationMode.NotAllow;
        this.setChanged();
    };
    ServerSettingsComponent.prototype.allowEditingArticlesChanged = function (allowEditingArticles) {
        this.serverOptions.editOprationArticlesMode =
            allowEditingArticles ? td_enumerations_1.WebEditOprationArticlesMode.AllowEdit : td_enumerations_1.WebEditOprationArticlesMode.NotAllowEdit;
        this.setChanged();
    };
    ServerSettingsComponent.prototype.allowOrderingChanged = function (allowOrdering) {
        this.serverOptions.orderMode = allowOrdering ? td_enumerations_1.WebOrderMode.On : td_enumerations_1.WebOrderMode.Off;
        this.setChanged();
    };
    ServerSettingsComponent.prototype.displayPricesChanged = function (displayPrices) {
        this.serverOptions.displayPriceMode = displayPrices ? td_enumerations_1.WebDisplayPriceMode.ShowAll : td_enumerations_1.WebDisplayPriceMode.HideAll;
        this.setChanged();
    };
    ServerSettingsComponent.prototype.displayGeneralProductsChanged = function (displayGeneralProducts) {
        this.serverOptions.displayGeneralProductsMode =
            displayGeneralProducts ? td_enumerations_1.WebDisplayGeneralProductsMode.Allowed : td_enumerations_1.WebDisplayGeneralProductsMode.Blocked;
        this.setChanged();
    };
    ServerSettingsComponent.prototype.availableInWebChanged = function (availableInWeb) {
        this.serverOptions.prodOverviewVisibilityMode =
            availableInWeb ? td_enumerations_1.ProdOverviewVisibilityMode.AvailableInWeb : td_enumerations_1.ProdOverviewVisibilityMode.NotAvailableInWeb;
        this.setChanged();
    };
    ServerSettingsComponent.prototype.resetToDefault = function () {
        this.serverOptions = new server_desktop_options_model_1.ServerDesktopOptionsModel();
        this.setChanged();
        this.loadVerificationControls();
    };
    ServerSettingsComponent.prototype.setChanged = function () {
        this.settingsChangedChange.emit(true);
    };
    ServerSettingsComponent.prototype.saveServerOptions = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.serverSessionService.saveServerDesktopOptions(_this.serverOptions).subscribe(function () {
                resolve();
            });
        });
    };
    return ServerSettingsComponent;
}());
exports.ServerSettingsComponent = ServerSettingsComponent;
