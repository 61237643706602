import { Params } from "@angular/router";
import { TD_QUERY_PARAMS } from "@app/core/data-layer/shared/models/td.constants";
import { RecentSearchInterface, RecentSearchType } from "@app/core/search/models/recent-search.interface";
import { SearchParamsModel, SearchParamTypes } from "./param-models";
import { ParamSearchFactoryInterface } from "./param-search-factory.interface";

export class OperationSearchFactory implements ParamSearchFactoryInterface {

  public createSearchModel(): SearchParamsModel {
    return {
      url: '/operations',
      properties: [
        {
          urlParamName: TD_QUERY_PARAMS.text,
          type: SearchParamTypes.String
        },
        {
          urlParamName: TD_QUERY_PARAMS.dateFrom,
          type: SearchParamTypes.Date
        },
        {
          urlParamName: TD_QUERY_PARAMS.dateTo,
          type: SearchParamTypes.Date
        }
      ]
    }
  }

  createDefaultSearchModel(): SearchParamsModel {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    return {
      url: '/operations',
      properties: [
        {
          urlParamName: TD_QUERY_PARAMS.dateFrom,
          type: SearchParamTypes.Date,
          value: today
        },
        {
          urlParamName: TD_QUERY_PARAMS.dateTo,
          type: SearchParamTypes.Date,
          value: tomorrow
        }
      ]
    }
  }

  getRecentSearchAsParams(recentSearch: RecentSearchInterface): Params {
    let params: Params = {};
    const recentSearchFilter = recentSearch.operationFilters;
    if (recentSearch.type === RecentSearchType.Date) {
      // Storing date parameters as a number in milliseconds
      params.dateFrom = recentSearchFilter.dateInterval.from ?  new Date(recentSearchFilter.dateInterval.from).valueOf() : null;
      params.dateTo = recentSearchFilter.dateInterval.to ?  new Date(recentSearchFilter.dateInterval.to).valueOf() : null;
    }
    else {
      params.text = recentSearch.operationFilters.text;
    }
    return params;
  }

  getEmptyParamsSearchModel(): SearchParamsModel {
    return this.createDefaultSearchModel();
  }
}
