"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var SearchFiltersHelperService = /** @class */ (function () {
    function SearchFiltersHelperService() {
        this.searchFilterOptions$ = new rxjs_1.BehaviorSubject(null);
    }
    SearchFiltersHelperService.prototype.getSearchFiltersOptions = function (_a) {
        var search_OrderableFromCurrentFactory = _a.search_OrderableFromCurrentFactory, display_ProdFiltMethodGeneral = _a.display_ProdFiltMethodGeneral, display_ProdFiltMethodCustomer = _a.display_ProdFiltMethodCustomer, display_ProdFiltMethodCustSite = _a.display_ProdFiltMethodCustSite;
        var searchFilterOptions = {
            search_OrderableFromCurrentFactory: search_OrderableFromCurrentFactory,
            display_ProdFiltMethodGeneral: display_ProdFiltMethodGeneral,
            display_ProdFiltMethodCustomer: display_ProdFiltMethodCustomer,
            display_ProdFiltMethodCustSite: display_ProdFiltMethodCustSite
        };
        this.searchFilterOptions$.next(searchFilterOptions);
        return searchFilterOptions;
    };
    SearchFiltersHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchFiltersHelperService_Factory() { return new SearchFiltersHelperService(); }, token: SearchFiltersHelperService, providedIn: "root" });
    return SearchFiltersHelperService;
}());
exports.SearchFiltersHelperService = SearchFiltersHelperService;
