<dx-popup class="global-popup td-popup"
          width="auto"
          height="auto"
          maxWidth="800px"
          minHeight="350"
          titleTemplate="titleTemplate"
          [showCloseButton]="false"
          [dragEnabled]="true"
          [closeOnOutsideClick]="closeOnOutsideClick"
          [(visible)]="displayPopup"
          (onHidden)="popupService.cancelPopup()"
          (onContentReady)="popupContentReady()">
  
          <!-- Title template -->  
  <div *dxTemplate="let data of 'titleTemplate'" class="popup-title">
    <img *ngIf="popupService.popupParams.titleIconPath" [src]="popupService.popupParams.titleIconPath" class="mr-1"> {{ popupService.popupParams.title }}
  </div>  
  
  <ng-container *ngIf="popupService.popupParams.template; else noTemplate">
    <ng-template [ngTemplateOutlet]="popupService.popupParams.template"></ng-template>
  </ng-container>

  <ng-template #noTemplate>
    <div class="d-flex flex-column justify-content-between">
      <div id="icon-row" class="row">
        <div class="col">
          <img id="popup-icon" [src]="popupIconPath">
        </div>
      </div>
      <div id="message-row" class="row">
        <div class="col">
          <span id="popupMessage" [innerHTML]="popupService.popupParams.message">
          </span>
        </div>
      </div>
    </div>
    <ng-container *ngIf="popupService.popupParams.type === PopupType.CustomWithButtons; else baseType">
      <div id="button-row" class="row align-items-end flex-grow-1">
        <div class="col">
          <ng-container *ngFor="let button of popupService.popupParams.customButtons">
            <ng-container *ngIf="button.callback; else nonCallbackTemplate;">
              <dx-button
                class="td-button float-right ml-3"
                [ngClass]="{
                  'success-button': button.type === PopupButtonType.Success,
                  'danger-button': button.type === PopupButtonType.Danger,
                  'secondary-button': button.type === PopupButtonType.Secondary,
                  'tertiary-button': button.type === PopupButtonType.Tertiary
                }"
                (onClick)="button.callback()"
                [text]="button.text | translate">
              </dx-button>  
            </ng-container>
            <ng-template #nonCallbackTemplate>
              <dx-button
                class="td-button float-right ml-3"
                [ngClass]="{
                  'success-button': button.type === PopupButtonType.Success,
                  'danger-button': button.type === PopupButtonType.Danger,
                  'secondary-button': button.type === PopupButtonType.Secondary,
                  'tertiary-button': button.type === PopupButtonType.Tertiary
                }"
                (onClick)="button.type === PopupButtonType.Success ? popupService.confirmPopup() : popupService.cancelPopup()"
                [text]="button.text | translate">
            </dx-button>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #baseType>
      <div id="button-row" class="row align-items-end flex-grow-1">
        <div class="col">
          <dx-button *ngIf="popupService.popupParams.type === PopupType.Confirmation"
                     class="td-button secondary-button float-right ml-3"
                     (onClick)="popupService.cancelPopup()"
                     [text]="'button.cancel' | translate">
            
          </dx-button>
          <dx-button class="td-button float-right ml-3"
                      [ngClass]="{
                        'success-button': popupService.popupParams.type !== PopupType.Confirmation,
                        'danger-button': popupService.popupParams.type === PopupType.Confirmation
                      }"
                     (onClick)="popupService.confirmPopup()"
                     [text]="'button.ok' | translate">
          </dx-button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</dx-popup>
