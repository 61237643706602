"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var JwtInterceptor = /** @class */ (function () {
    function JwtInterceptor(authService) {
        this.authService = authService;
    }
    JwtInterceptor.prototype.intercept = function (request, next) {
        // add authorization header with jwt token if available
        if (this.authService.isAuthenticated()) {
            request = request.clone({
                setHeaders: {
                    Authorization: this.authService.getAuthorizationHeaderValue()
                }
            });
        }
        return next.handle(request);
    };
    return JwtInterceptor;
}());
exports.JwtInterceptor = JwtInterceptor;
