import { Component, Inject } from '@angular/core';

import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';

@Component({
  selector: 'td-signin-callback',
  templateUrl: './signin-callback.component.html',
  styleUrls: ['./signin-callback.component.less']
})
export class SigninCallbackComponent {

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
  ) {
    // for the case when user press 'browser-back' button on 'auth-customers' page
    if (this.authService.isAuthenticated()) {
      this.authService.startSignout();
    }
    else {
      this.authService.completeSignin();
    }
  }
}
