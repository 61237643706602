"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var HttpUserSessionService = /** @class */ (function () {
    function HttpUserSessionService(http) {
        this.http = http;
    }
    HttpUserSessionService.prototype.profileDataUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.userProfileDataCtrl;
    };
    HttpUserSessionService.prototype.desktopDataUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.desktopDataCtrl;
    };
    HttpUserSessionService.prototype.getAll = function () {
        // todo: call real backend API
        return this.http.get("/users");
    };
    HttpUserSessionService.prototype.getById = function (id) {
        // todo: call real backend API
        return this.http.get("/users/" + id);
    };
    HttpUserSessionService.prototype.getProfileData = function () {
        return this.http.get("" + this.profileDataUrl());
    };
    HttpUserSessionService.prototype.getUserOptions = function () {
        return this.http.get("" + this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.getUserOptions);
    };
    HttpUserSessionService.prototype.saveUserOptions = function (userDesktopOptions) {
        return this.http.post("" + this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.saveUserOptions, userDesktopOptions);
    };
    HttpUserSessionService.prototype.getDefaultUserOptions = function () {
        return this.http.get("" + this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.getDefaultUserOptions);
    };
    HttpUserSessionService.prototype.saveDefaultUserOptions = function (userDesktopOptions) {
        return this.http.post("" + this.desktopDataUrl() + api_hub_constants_1.API_HUB_PATHS.saveDefaultUserOptions, userDesktopOptions);
    };
    HttpUserSessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpUserSessionService_Factory() { return new HttpUserSessionService(i0.ɵɵinject(i1.HttpClient)); }, token: HttpUserSessionService, providedIn: "root" });
    return HttpUserSessionService;
}());
exports.HttpUserSessionService = HttpUserSessionService;
