"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#serialContainer[_ngcontent-%COMP%]     textarea{height:100%}#serialContainer[_ngcontent-%COMP%]{height:270px}"];
exports.styles = styles;
