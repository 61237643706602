"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
exports.ORDER_PROPERTIES_TO_ENUM_MAPPING = [
    {
        textProperty: 'suppStatusText',
        sourceProperty: 'suppStatus',
        enum: td_enumerations_1.OrderSuppStatus,
        translate: 'orderSuppStatus'
    },
    {
        textProperty: 'orgStatusText',
        sourceProperty: 'orgStatus',
        enum: td_enumerations_1.OrderOrgStatus,
        translate: 'orderOrgStatus'
    }
];
exports.ORDER_DETAILS_PROPERTIES_TO_ENUM_MAPPING = [
    {
        textProperty: 'suppStatusText',
        sourceProperty: 'suppStatus',
        enum: td_enumerations_1.OrderSuppStatus,
        translate: 'orderSuppStatus'
    },
    {
        textProperty: 'orgStatusText',
        sourceProperty: 'orgStatus',
        enum: td_enumerations_1.OrderOrgStatus,
        translate: 'orderOrgStatus'
    }
];
exports.ORDER_LINE_PROPERTIES_TO_ENUM_MAPPING = [
    {
        textProperty: 'oLnRefTypeText',
        sourceProperty: 'oLnRefType',
        enum: td_enumerations_1.TableTypes,
        translate: 'orderLineRefType'
    }
];
