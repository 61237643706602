"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var logger_service_1 = require("@core/logger/logger.service");
var TelemetryInterceptor = /** @class */ (function () {
    function TelemetryInterceptor(loggerService) {
        this.loggerService = loggerService;
    }
    TelemetryInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var started = Date.now();
        return next.handle(request).pipe(operators_1.tap(function (response) {
            if (response instanceof http_1.HttpResponse) {
                var elapsed = Date.now() - started;
                _this.loggerService.log("Request for " + request.urlWithParams + " took " + elapsed + " ms.");
            }
        }));
    };
    return TelemetryInterceptor;
}());
exports.TelemetryInterceptor = TelemetryInterceptor;
