import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'td-confirm-button-panel',
  templateUrl: 'td-confirm-button-panel.component.html',
  styleUrls: ['td-confirm-button-panel.component.less']
})

export class TdConfirmButtonPanelComponent implements OnInit {

  @Input() disableSaveButton = false;
  @Input() clearButtonVisible = false;
  @Input() resetButtonVisible = false;
  @Input() confirmButtonTranslateString = 'button.ok';
  @Input() cancelButtonTranslateString = 'button.cancel';
  @Input() resetButtonTranslateString = 'button.resetToDefault';
  @Input() clearButtonTranslateString = 'button.clear';

  @Output() confirmEvent: EventEmitter<null> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<null> = new EventEmitter();
  @Output() resetEvent: EventEmitter<null> = new EventEmitter();
  @Output() clearEvent: EventEmitter<null> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  confirmChanges(): void {
    this.confirmEvent.emit();
  }

  cancelChanges(): void {
    this.cancelEvent.emit();
  }

  clear(): void {
    this.clearEvent.emit();
  }

  reset(): void {
    this.resetEvent.emit();
  }
}
