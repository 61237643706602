"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var user_session_actions_1 = require("@core/data-layer/user-session/store/user-session.actions");
var user_session_selectors_1 = require("@app/core/data-layer/user-session/store/user-session.selectors");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var DefaultUserDesktopOptionsInitializer = /** @class */ (function () {
    function DefaultUserDesktopOptionsInitializer(store) {
        this.store = store;
    }
    DefaultUserDesktopOptionsInitializer.prototype.initializeDefaultUserDesktopOptions = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.store.dispatch(new user_session_actions_1.LoadDefaultUserOptions());
            _this.store.pipe(store_1.select(user_session_selectors_1.selectDefaultUserOptions)).subscribe(function (userDesktopOptions) {
                if (userDesktopOptions) {
                    resolve(userDesktopOptions);
                }
            });
        });
    };
    DefaultUserDesktopOptionsInitializer.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DefaultUserDesktopOptionsInitializer_Factory() { return new DefaultUserDesktopOptionsInitializer(i0.ɵɵinject(i1.Store)); }, token: DefaultUserDesktopOptionsInitializer, providedIn: "root" });
    return DefaultUserDesktopOptionsInitializer;
}());
exports.DefaultUserDesktopOptionsInitializer = DefaultUserDesktopOptionsInitializer;
