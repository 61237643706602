import { Injectable, Inject } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { CustomerServiceInterface } from '../services/customer.service.interface';
import { CustomerActionTypes, LoadUserCustomers, LoadSiteCustomers } from './customer.actions';

@Injectable()
export class CustomerEffects {

  constructor(
    private actions$: Actions,
    @Inject('CUSTOMER_SERVICE') private customerService: CustomerServiceInterface) {
  }

  @Effect()
  LoadUserCustomers$ = this.actions$
    .pipe(
      ofType(CustomerActionTypes.LoadUserCustomers),
      mergeMap((act: LoadUserCustomers) => this.customerService.getUserCustomers()
        .pipe(
          map(customers => ({ type: CustomerActionTypes.SetUserCustomers, payload: customers })),
          catchError(() => of({ type: CustomerActionTypes.SetUserCustomers }))
        ))
  );

  @Effect()
  LoadSiteCustomers$ = this.actions$
    .pipe(
      ofType(CustomerActionTypes.LoadSiteCustomers),
      mergeMap((act: LoadSiteCustomers) => this.customerService.getSiteCustomers(act.payload)
        .pipe(
          map(customers => ({ type: CustomerActionTypes.SetSiteCustomers, payload: customers })),
          catchError(() => of({ type: CustomerActionTypes.SetSiteCustomers }))
        ))
    );
}
