"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var customer_model_1 = require("../models/customer.model");
exports.selectCustomerState = function (state) { return state.customer; };
var ɵ0 = function (state) { return state.userCustomers; };
exports.ɵ0 = ɵ0;
exports.selectUserCustomers = store_1.createSelector(exports.selectCustomerState, ɵ0);
var ɵ1 = function (state) { return state.currentCustomer; };
exports.ɵ1 = ɵ1;
exports.selectCurrentCustomer = store_1.createSelector(exports.selectCustomerState, ɵ1);
var ɵ2 = function (state, props) { return state.userCustomers ? state.userCustomers.filter(function (cust) { return cust.keyId === props.id; })[0] : new customer_model_1.CustomerModel(); };
exports.ɵ2 = ɵ2;
exports.selectCustomerById = store_1.createSelector(exports.selectCustomerState, ɵ2);
var ɵ3 = function (state) { return state.siteCustomers; };
exports.ɵ3 = ɵ3;
exports.selectSiteCustomers = store_1.createSelector(exports.selectCustomerState, ɵ3);
