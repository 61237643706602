"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var RedirectGuard = /** @class */ (function () {
    function RedirectGuard(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    RedirectGuard.prototype.canActivate = function (route, state) {
        // we allow to navigate to page only when:
        // 1. user was logged in
        if (this.authService.isAuthenticated()) {
            if (route.routeConfig.path === 'changePassword') {
                var url = "/Account/ChangePassword?returnUrl=" + route.queryParams.returnUrl;
                window.location.href = url;
                return true;
            }
        }
        else {
            return false;
        }
    };
    RedirectGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RedirectGuard_Factory() { return new RedirectGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject("AUTH_SERVICE")); }, token: RedirectGuard, providedIn: "root" });
    return RedirectGuard;
}());
exports.RedirectGuard = RedirectGuard;
