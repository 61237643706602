"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
exports.selectUserSessionState = function (state) { return state.userSession; };
var ɵ0 = function (state) { return state.profileData; };
exports.ɵ0 = ɵ0;
exports.selectProfileData = store_1.createSelector(exports.selectUserSessionState, ɵ0);
var ɵ1 = function (state) { return state.userOptions; };
exports.ɵ1 = ɵ1;
exports.selectUserOptions = store_1.createSelector(exports.selectUserSessionState, ɵ1);
var ɵ2 = function (state) { return state.defaultUserOptions; };
exports.ɵ2 = ɵ2;
exports.selectDefaultUserOptions = store_1.createSelector(exports.selectUserSessionState, ɵ2);
