import { StringDictionary } from '@shared/models/custom-types.interface';

export enum AlternativeArticleStatus {
  Old = 0,
  New = 1,
  Deleted = 2,
  Edited = 3
}

export enum AssignFactoryData {
  None = 0,
  OwnFactories = 1,
  AllFactories = 2
}

export enum CustNameDisplay {
  Name = 0,
  ShortName = 1,
  Number = 2
}

export enum CustOrderBookingMode {
  Default = 0,
  Disabled = 1,
  Manual = 2,
  Automation = 3
}

export enum CustPreDispatchMode {
  UseFacSetting = 0,
  Always = 1,
  Never = 2
}

export enum DecNonDispItemStockOnPack {
  Off = 0,
  Prompt = 1,
  On = 2
}

export enum DeltaScanMode {
  Delta = 0,
  Total = 1
}

export enum ExpireType {
  Normal = 0,
  None = 1
}

export enum FacDeductItemsFromStockInOpType {
  On = 0,
  Off = 1
}

export enum FacOrderBookingMode {
  Disabled = 0,
  Manual = 1,
  Automation = 2
}

export enum FactoryDefProdTraceType {
  ProductSerial = 0,
  Product = 1
}

export enum FastTrackCodeType {
  General = 0,
  AdHoc = 1,
  TemplateOnly = 2
}

export enum FastTrackCreatorType {
  System = 0,
  JobServer = 1,
  FacUser = 2,
  CustUser = 3
}

export enum FastTrackDisplayMode {
  UserSetting = 0,
  ForcePrompt = 1,
  ForceNoPrompt = 2,
  ForceNoPromptOrInfo = 3
}

export enum FastTrackEndType {
  Normal = 0,
  NormalNewUnitHas = 1,
  ToRepair = 2,
  Expired = 3,
  Cancelled = 4
}

export enum FastTrackInvoiceType {
  None = 0,
  Factory = 1,
  Customer = 2
}

export enum FastTrackStatus {
  Assigned = 0,
  Started = 1,
  Ended = 2
}

export enum FastTrackStockBasedMode {
  Disabled = 0,
  Enabled = 1
}

export enum FastTrackStockBasedOrdersFilter {
  AllWithDate = 0,
  Operation = 1
}

export enum FuzzySearchSortOrder {
  None = 0,
  Asc = 1,
  Desc = 2
}

export enum FuzzySearchTextMatchType {
  Equals = 0,
  BeginsWith = 1,
  EndsWith = 2,
  Contains = 3
}

export enum IdentArticleLineUsage {
  Enabled = 0,
  Disabled = 1
}

export enum IdentStockUsage {
  Default = 0,
  Enabled = 1,
  Disabled = 2
}

export enum IncludeItemSerialUsage {
  Off = 0,
  On = 1,
  OnHidden = 2
}

export enum IncNonDispItemStockOnPack {
  Off = 0,
  Prompt = 1,
  On = 2
}

export enum ItemAltPricingMode {
  None = 0,
  SalesPrice = 1,
  UsagePrice = 2,
  CostPrice = 3
}

export enum ItemDifficultyMode {
  Default = 0,
  Fixed = 1,
  Sum = 2,
  Max = 3,
  Count = 4
}

export enum ItemInstrumentType {
  Instrument = 0,
  Endoscope = 1,
  ButtonValve = 2
}

export enum ItemPricingMode {
  SalesPrice = 0,
  UsagePrice = 1,
  CostPrice = 2
}

export enum ItemPricingType {
  Normal = 0,
  TimeBased = 1
}

export enum ItemReservationMode {
  NotRequired = 0,
  Enabled = 1
}

export enum ItemSalesRestriction {
  None = 0,
  Blocked = 1
}

export enum ItemTraceType {
  Item = 0,
  SerialItem = 1,
  LOT = 2
}

export enum Language {
  Default = 0,
  EnglishUS = 1,
  Danish = 2,
  Swedish = 3,
  Norwegian = 4,
  Finnish = 5,
  German = 6,
  French = 7,
  Spanish = 8,
  Italian = 9,
  Dutch = 10,
  Polish = 11,
  Japanese = 12,
  ChineseSimple = 13,
  Russian = 14,
  Bulgarian = 15,
  Hungarian = 16,
  BrazilPortuguese = 17,
  Slovenian = 18,
  Romanian = 19,
  SpanishLatin = 20,
  Thai = 21,
  Turkish = 22,
  Greek = 23,
  Malay = 24,
  Lithuanian = 25,
  Czech = 26,
  Estonian = 27,
  Latvian = 28,
  Slovakian = 29,
  Korean = 30,
  Portuguese = 31,
  ChineseTrad = 32,
  Icelandic = 33
}

export enum LegacyProcessType {
  None = 0,
  Pack = 1,
  SteriPostBatch = 2,
  Out = 3,
  Return = 4,
  Prepare = 5,
  Wrap = 6,
  LitePro = 7,
  SteriPreBatch = 8,
  Flash = 9,
  Location = 10,
  WashPreBatch = 11,
  WashPostBatch = 12,
  OpenUsed = 13,
  Operation = 14,
  Order = 15,
  Inventory = 16,
  OrderPick = 17,
  Industry = 18,
  PreDisPreBatch = 19,
  LoadQueue = 20,
  PurchaseOrder = 21,
  EndoscopePreBatch = 22,
  WashCheck = 23,
  ExtBatchInfo = 24,
  EndoCleanAndTest = 25,
  Transport = 26,
  Workflow = 27,
  Incubator = 28,
  Last = 29
}

export enum LocaSubProcessType {
  None = 0,
  Admin = 1,
  Machine = 2
}

export enum LoginCookieMethod {
  NotAllowed = 0,
  UserName = 1,
  UserNamePassword = 2,
  Autologin = 3
}

export enum LogKeyType {
  ResvProduct = 0,
  ResvItem = 1,
  OrdDelivered = 2
}

export enum ManualEndoDryingMode {
  NotAllowed = 0,
  Allowed = 1,
  Assumed = 2
}

export enum MinOrderLeadTime {
  Actual = 0,
  Work = 1
}

export enum MultiMediaSubType {
  Picture = 0,
  ThumbNail = 1,
  Text = 2,
  Sound = 3,
  Video = 4
}

export enum MultiMediaType {
  Picture = 0,
  Video = 1
}

export enum NotifyPriority {
  None = 0,
  Low = 1,
  Normal = 2,
  High = 3
}

export enum OperationSearchType {
  Date = 0,
  OperationID = 1
}

export enum OperationTypeType {
  Elective = 0,
  Emergency = 1
}

export enum OrderBookingMode {
  Undetermined = 0,
  Disabled = 1,
  Inactive = 2,
  Active = 3
}

export enum OrderBookingPriority {
  Normal = 0,
  High = 1,
  Force = 2
}

export enum OrderBookingStatus {
  Undetermined = 0,
  Ok = 1,
  Warning = 2,
  Error = 3
}

export enum OrderLockMode {
  Unlocked = 0,
  Locked = 1
}

export enum OrderOperationSyncMode {
  NA = 0,
  Synced = 1,
  Stopped = 2
}

export enum OrderOrgStatus {
  None = 0,
  Template = 1,
  Open = 2,
  Sent = 3,
  Received = 4,
  Done = 5,
  Cancelled = 6
}

export enum OrderSuppPickMode {
  Normal = 0,
  FullAuto = 1,
  EjectOnly = 2
}

export enum OrderSuppPickPriority {
  Normal = 0,
  PickNow = 1,
  PickNowForced = 2
}

export enum OrderSuppPriority {
  Normal = 0,
  FastTrack = 1,
  Emergency = 2
}

export enum OrderSuppStatus {
  None = 0,
  Template = 1,
  Order = 2,
  Accepted = 3,
  Picked = 4,
  Delivered = 5,
  Cancelled = 6
}

export enum OrderType {
  None = 0,
  Order = 1,
  ReturnOrder = 2,
  OrderCorr = 3
}

export enum PasswordVerificationMethod {
  None = 0,
  TDOC = 1,
  ActiveDirectory = 2
}

export enum PasswordVerify {
  TDOC = 0,
  NTDomain = 1,
  ActiveDirectory = 2
}

export enum PatientAgeUsage {
  NotSet = 0,
  Needed = 1,
  Required = 2
}

export enum PatientGenderUsage {
  NotSet = 0,
  Needed = 1,
  Required = 2
}

export enum PrefListOrderCreateMode {
  Immediate = 0,
  TimeBased = 1,
  Manual = 2
}

export enum ProcessStatus {
  Done = 0,
  Running = 1,
  Initiated = 2,
  Ending = 3
}

export enum ProcessType {
  None = 0,
  Pack = 1,
  SteriPostBatch = 2,
  Out = 3,
  Return = 4,
  Prepare = 5,
  Wrap = 6,
  LitePro = 7,
  SteriPreBatch = 8,
  Flash = 9,
  Location = 10,
  WashPreBatch = 11,
  WashPostBatch = 12,
  OpenUsed = 13,
  Operation = 14,
  Order = 15,
  Inventory = 16,
  OrderPick = 17,
  Industry = 18,
  PreDisPreBatch = 19,
  LoadQueue = 20,
  PurchaseOrder = 21,
  EndoscopePreBatch = 22,
  WashCheck = 23,
  ExtBatchInfo = 24,
  EndoCleanAndTest = 25,
  Transport = 26,
  Workflow = 27,
  Incubator = 28,
  ReturnWF = 29,
  WashPreBatchWF = 30,
  SteriPreBatchWF = 31,
  PackWF = 32,
  OutWF = 33,
  Last = 34
}

export enum ProdDispatchRestriction {
  Default = 0,
  None = 1,
  CustomerOrSite = 2,
  CustomerElseSite = 3,
  CustomerOnly = 4
}

export enum ProdOverviewAvailability {
  AsDefinedForUsers = 0,
  Always = 1,
  Never = 2
}

export enum ProdOverviewVisibilityMode {
  None = 0,
  AvailableInWeb = 1,
  NotAvailableInWeb = 2
}

export enum ProdReservationMode {
  NotRequired = 0,
  Enabled = 1
}

export enum ProductDifficultyMode {
  UseItemValue = 0,
  Fixed = 1
}

export enum ProductLine {
  Cycle = 0,
  CSM = 1,
  CSMLabel = 2,
  SDM = 3,
  TDOC2000 = 4,
  EndoCycle = 5,
  EndoTrace = 6,
  Standalone = 7,
  Select = 8
}

export enum ProductLocateSection {
  Ready = 0,
  Packed = 1,
  Returned = 2,
  Used = 3,
  Repair = 4,
  Other = 5,
  All = 6
}

export enum ProductReservationType {
  Normal = 0,
  NotOrdered = 1,
  PickedFromLineSpec = 2
}

export enum ProductReturnState {
  Normal = 0,
  PreProcessed = 1,
  PrePacked = 2
}

export enum ProductStockControlMode {
  Simple = 0,
  Basic = 1,
  Advanced = 2
}

export enum ProductStorageType {
  Factory = 0,
  Transit = 1
}

export enum ProductTraceType {
  Product = 0,
  ProductSerial = 1,
  Both = 2
}

export enum ProductType {
  Trays = 0,
  SinglePacks = 1,
  Endoscopes = 2
}

export enum RepairMode {
  AskUser = 0,
  UsingCust = 1,
  Owner = 2,
  Factory = 3
}

export enum ReservationState {
  Undetermined = 0,
  OKOriginal = 1,
  OKIdentical = 2,
  OKAlternative = 3,
  OKIdenticalForAlternative = 4,
  WarningOriginal = 5,
  WarningIdentical = 6,
  WarningAlternative = 7,
  WarningIdenticalForAlternative = 8,
  Error = 9
}

export enum ReservationStatus {
  Active = 0,
  Completed = 1,
  Failed = 2,
  Cancelled = 3
}

export enum ReservFlag {
  ResvNormal = 0,
  ResvForce = 1
}

export enum ReservLogType {
  Normal = 0,
  Warning = 1,
  Error = 2
}

export enum ScanUIStartMode {
  Default = 0,
  Layers = 1,
  Grid = 2
}

export enum SearchAutoPictureMode {
  Off = 0,
  On = 1
}

export enum SearchResultOrigin {
  Local = 0,
  JobSQL = 1,
  JobFuzzy = 2
}

export enum SexType {
  None = 0,
  Male = 1,
  Female = 2,
  Other = 3
}

export enum SpecialSearchType {
  None = 0,
  ItemSingle = 1,
  ItemComposite = 2,
  CustomerUnits = 3,
  ProductsBasedOnItem = 4,
  ButtonValve = 5,
  WebBaseProdItem = 6,
  WebProdWithSerials = 7,
  PrefLists = 8,
  ItemSerial = 9,
  ProductSerial = 10,
  Workstation = 11,
  IncubatorLog = 12,
  TriggerMerge = 13,
  WebUnits = 14,
  WebCompositeItemList = 15,
  WebProductsBasedOnItem = 16,
  WebScheduledOperation = 17,
  WebOperationItems = 18,
  WebOperationProducts = 19,
  WebOperationUnits = 20
}

export enum StdCommentFreeTextOption {
  NotAllowed = 0,
  Allowed = 1,
  Required = 2
}

export enum StdCommentShowInProdOption {
  No = 0,
  Yes = 1
}

export enum StockBackOrderMode {
  Never = 0,
  Always = 1,
  Ask = 2,
  WhenSet = 3,
  WhenSetAuto = 4
}

export enum StockOrderInMode {
  Auto = 0,
  Receive = 1,
  Manual = 2
}

export enum StockPrnMode {
  Never = 0,
  Always = 1,
  Ask = 2
}

export enum StockType {
  Factory = 0,
  Cust = 1,
  Supplier = 2,
  Mobile = 3
}

export enum SubstitutionArticleType {
  Item = 0,
  Product = 1
}

export enum SubstitutionReason {
  None = 0,
  Identical = 1,
  Alternative = 2,
  IdenticalForAlternative = 3
}

export enum SubstMode {
  Identical = 0,
  Alternative = 1
}

export enum TableTypes {
  None = 0,
  Product = 1,
  Serial = 2,
  Item = 3,
  Machine = 4,
  Supplier = 5,
  Customer = 6,
  System = 7,
  Unit = 8,
  Text = 9,
  Factory = 10,
  Lot_OBSOLETE = 11,
  Stocks = 12,
  User = 13,
  Process = 14,
  LotTransAct_OBSOLETE = 15,
  Location = 16,
  RushPlan = 17,
  OperationData = 18,
  OperationType = 19,
  ProcedureType = 20,
  Program = 21,
  ProgramRecipe = 22,
  Catalog = 23,
  Patient = 24,
  Order = 25,
  MachineType = 26,
  ProgramGroup = 27,
  RepairType = 28,
  Repair = 29,
  Doctor = 30,
  IndicatorType = 31,
  Room = 32,
  Pack = 33,
  FastTrackCode = 34,
  FastTrackPlan = 35,
  FastTrackGroup = 36,
  WashCheckType = 37,
  InfoOverviewConfig = 38,
  Indicator = 39,
  TransportMethod = 40,
  HandlingStep = 41,
  LocationGroup = 42,
  CustSite = 43,
  Tags = 44,
  DryerProcess = 45,
  StandardComment = 46,
  AGS = 47,
  TagType = 48,
  Trigger = 49,
  ScannerMacro = 50
}

export enum TDOCAppType {
  Unknown = 0,
  AppServer = 1,
  ScannerServer = 2,
  ConnectServer = 3,
  TDOCCtrl = 4,
  Admin = 5,
  Scanner = 6,
  Connect = 7,
  Spool_Obsolete = 8,
  Launcher = 9,
  Exchange = 10,
  MacView = 11,
  Service = 12,
  PrintServer = 13,
  RemoteControl_Obsolete = 14,
  JobServer_Obsolete = 15,
  ProfEdit = 16,
  DeviceTest = 17,
  MacSimu = 18,
  IniEdit = 19,
  NetTest = 20,
  UtilityServer = 21,
  SpoolServer = 22,
  SpoolCtrl = 23,
  NetCOMCfg = 24,
  JobCfg = 25,
  Panel = 26,
  JobServer = 27,
  WebServer = 28,
  Engine = 29,
  Upgrade = 30,
  UnknownL = 31
}

export enum TDOCTable {
  None = 0,
  Text = 1,
  Pack = 2,
  PictureRef = 3,
  Program = 4,
  User = 5,
  MachineType = 6,
  Machine = 7,
  Cust = 8,
  Item = 9,
  Product = 10,
  System = 11,
  Composite = 12,
  Stock = 13,
  Process = 14,
  Unit = 15,
  Event = 16,
  Serial = 17,
  Trigger = 18,
  Supplier = 19,
  CustTransAct = 20,
  Pics = 21,
  BcTp = 22,
  Contact = 23,
  Profile = 24,
  ItemGrp = 25,
  Delta = 26,
  Position = 27,
  Patient = 28,
  PatientCons = 29,
  UnitLocation = 30,
  UnitList = 31,
  Repair = 32,
  RepairType = 33,
  Stocks = 34,
  Factory = 35,
  Location = 36,
  ProgGroups = 37,
  ProgGroup = 38,
  Report = 39,
  ReportDef = 40,
  RepSelection = 41,
  ItemSerialUsage = 42,
  Order = 43,
  OrderLine = 44,
  OrderLineSpec = 45,
  PosLocation = 46,
  PrnDrv = 47,
  Audit = 48,
  Defaults = 49,
  History = 50,
  TriggerLog = 51,
  OperationType = 52,
  ProcedureType = 53,
  ProcedureTypes = 54,
  ProcedureData = 55,
  OperationData = 56,
  Doctor = 57,
  OrderSuggestion = 58,
  OrderLineSugg = 59,
  OrderTemplate = 60,
  OrderLineTemp = 61,
  DefaultsBlob = 62,
  Batch = 63,
  ProgramParam = 64,
  ProgramRecipe = 65,
  ProgramRecipeParam = 66,
  Catalog = 67,
  CatRef = 68,
  AGS = 69,
  AGSQueue = 70,
  Room = 71,
  Barcodes = 72,
  MacLEq = 73,
  LoadEquip = 74,
  Notify = 75,
  RoomCustomer = 76,
  ItemSpc = 77,
  ItemUOM = 78,
  MachineLog = 79,
  ProcessLog = 80,
  SplitTag = 81,
  SealerProgram = 82,
  SealerProcess = 83,
  PrinterLocation = 84,
  UserGroups = 85,
  UserGroupMembers = 86,
  ESignatureDefs = 87,
  ESignatures = 88,
  InputDefs_NOT_USED = 89,
  InputReply = 90,
  PrnRedirect = 91,
  StockGroup = 92,
  StockGroupRef = 93,
  FactoryItems = 94,
  FactoryPackings = 95,
  FactoryProducts = 96,
  FactoryUsers = 97,
  FactoryCustomers = 98,
  FactoryItemGrps = 99,
  FactoryItemSpcs = 100,
  FactorySuppliers = 101,
  RepSecurityGrp = 102,
  SPOSList = 103,
  ADGroups = 104,
  RepSecGrpMembers = 105,
  IESystems = 106,
  Job = 107,
  ChamberPlacements = 108,
  OrderInfo = 109,
  LotHc = 110,
  EndoDryingMethods = 111,
  FixedStockPlacements = 112,
  UnitWeightReg = 113,
  UnitWeightInfo = 114,
  WeightPlan = 115,
  WeightPlanEntries = 116,
  LocationGroup = 117,
  LocationGroupMembers = 118,
  FastTrackRules = 119,
  FastTrackRulesScope = 120,
  FastTrackRulesSelection = 121,
  FastTrackCode = 122,
  FastTrackPlan = 123,
  FastTrackPlanSteps = 124,
  FastTrackGroup = 125,
  FastTrackGroupCodes = 126,
  UnitFastTrack = 127,
  UnitFastTrackInvoice = 128,
  UnitFastTrackPlan = 129,
  WashCheck = 130,
  WashCheckType = 131,
  Desktop = 132,
  WeightValidationOverride = 133,
  PriceGroup = 134,
  CustomerUsers = 135,
  OrderTemplateType = 136,
  MachConsumables = 137,
  InfoOverviewConfig = 138,
  ProcessConsumables = 139,
  PreferenceListTemplates = 140,
  Indicator = 141,
  IndicatorType = 142,
  IndicatorTypeSteps = 143,
  IndicatorAction = 144,
  IndicatorMachineType = 145,
  IndicatorProduct = 146,
  IndicatorProgram = 147,
  IndicatorUnit = 148,
  ExtDocStore = 149,
  ExtDocRefLink = 150,
  ExtDocInfo = 151,
  MachConsType = 152,
  MachConsParam = 153,
  ProcParam = 154,
  MachRuleResult = 155,
  ProcBarcode = 156,
  McTypRule = 157,
  UnitTaT = 158,
  UnitTaTDef = 159,
  HandlingTypes = 160,
  HandlingStep = 161,
  TransportMethod = 162,
  UILayerDef = 163,
  UIGuideDef = 164,
  UISearchDef = 165,
  TextUser = 166,
  Transport = 167,
  HandlingResult = 168,
  Favorites = 169,
  ProcLoad = 170,
  PreferenceList = 171,
  PreferenceListLine = 172,
  PreferenceListLink = 173,
  OperationStaffLink = 174,
  CustSite = 175,
  PatientCat = 176,
  PrefListReplaceLine = 177,
  OrderAction = 178,
  OPDataAction = 179,
  DryerProcess = 180,
  WorkPeriods = 181,
  WorkHours = 182,
  Calendar = 183,
  Message = 184,
  MessageUserGroup = 185,
  MessageUserGroupLink = 186,
  MessageRecipientLink = 187,
  ProductTimes = 188,
  ActionLog = 189,
  ActionSubscriber = 190,
  Truck = 191,
  TruckStop = 192,
  Route = 193,
  RouteStop = 194,
  RouteTruck = 195,
  ProductReservation = 196,
  ItemReservation = 197,
  ALProcessApprove = 198,
  Question = 199,
  QuestionSet = 200,
  QuestionAnswer = 201,
  QuestionSetLink = 202,
  Action = 203,
  StockOrder = 204,
  ActionDelayLog = 205,
  MessageTemplates = 206,
  MessageTempPart = 207,
  MessageTempPartText = 208,
  MessageTempPartsLink = 209,
  MessageText = 210,
  ActionTemplates = 211,
  ImportHolidayLocation = 212,
  LotInfo = 213,
  OrderLotInfo = 214,
  UnitLotInfo = 215,
  PatientLotInfo = 216,
  IndicatorLotInfo = 217,
  Tags = 218,
  TagContentHistory = 219,
  TagContent = 220,
  TagLocation = 221,
  MessageGroupProfileLink = 222,
  MessageLabels = 223,
  MessageLabelLinks = 224,
  ExpTimePoints = 225,
  TableInfo = 226,
  Recent = 227,
  KPIValues = 228,
  CountingPoint = 229,
  CountingPointSet = 230,
  CountCountingSet = 231,
  OrderCountLst = 232,
  IntercomGroup = 233,
  IntercomGroupMembers = 234,
  MachDisplayGroupLink = 235,
  MachDisplayGroup = 236,
  CountChangeReason = 237,
  CountChanges = 238,
  FacCountChangeReason = 239,
  FacRepTyp = 240,
  CountDataUsers = 241,
  CountingPointData = 242,
  LinkStation = 243,
  ProcessLogQueue = 244,
  BioIndicatorType = 245,
  TimeRule = 246,
  TimeRuleGroup = 247,
  TimeRuleLink = 248,
  IncubatorLog = 249,
  IncubatorWellLog = 250,
  BITypePlacementLink = 251,
  FactoryTimeRuleGroup = 252,
  StandardComment = 253,
  FactoryStandardComment = 254,
  StandardCommentLink = 255,
  HandlingStepBinding = 256,
  IncubatorBatch = 257,
  CPUnitCheck = 258,
  FactoryAutoFTStocks = 259,
  DryerLog = 260,
  DryerPlacementLog = 261,
  PIPFields = 262,
  PIPAuditLog = 263,
  TagProgramGroupLink = 264,
  SpecLink = 265,
  EmgCaseCartType = 266,
  EmgCaseCartTypeLinks = 267,
  UserFieldDef = 268,
  CompAlternative = 269,
  IdenticalArticleGroup = 270,
  OrderLineAlternative = 271,
  OrderTempLineAlternative = 272,
  PrefListAlternative = 273,
  ClientTeam = 274,
  ServerPool = 275,
  ServerPoolClientTeams = 276,
  ServerPoolServers = 277,
  TagType = 278,
  MesOutTask = 279,
  MesTaskDef = 280,
  MesTaskLocations = 281,
  MesOutTaskTagList = 282,
  MesOutTaskEvent = 283,
  TegrisConfig = 284,
  PickJob = 285,
  PickJobContent = 286,
  PickJobAction = 287,
  PickJobOrderLink = 288,
  TriggerLink = 289,
  TriggerExclude = 290,
  SpotTest = 291,
  ItemExchange = 292,
  ScannerMacro = 293,
  GLNInfo = 294,
  MachLocation = 295,
  IOPosLink = 296,
  NoMore = 297
}

export enum TriggerPostponeRight {
  Prompt = 0,
  Stop = 1
}

export enum UILocaAvailability {
  Default = 0,
  Visible = 1,
  Advanced = 2,
  Hidden = 3
}

export enum UseOfUnusedSerials {
  Off = 0,
  Prompt = 1,
  PromptExceptFirstCounting = 2,
  On = 3
}

export enum UseOfUsedSerials {
  Off = 0,
  Prompt = 1,
  On = 2
}

export enum UserPackOnScreen {
  On = 0,
  Off = 1
}

export enum UserPictureOnPack {
  On = 0,
  Auto = 1,
  Off = 2
}

export enum WebDisplayCustomerProductsMode {
  OwnOnly = 0,
  OwnSiteOnly = 1,
  All = 2,
  OwnSiteOrCust = 3
}

export enum WebDisplayGeneralProductsMode {
  Allowed = 0,
  Blocked = 1
}

export enum WebDisplayPriceMode {
  ShowAll = 0,
  HideAll = 1
}

export enum WebEditOprationArticlesMode {
  AllowEdit = 0,
  NotAllowEdit = 1
}

export enum WebFastTrackMode {
  On = 0,
  Off = 1
}

export enum WebLoginMode {
  WhenSpecified = 0,
  Always = 1
}

export enum WebOrderMode {
  On = 0,
  Off = 1
}

export enum WebStartStopOperationMode {
  Allow = 0,
  NotAllow = 1
}

// begin: Enumerations that are constants in Delphi
export enum UnitStatus {
  None = 0,
  Init = 1,
  Started = 3,
  Rinsed = 7,
  Washed = 8,
  Prep = 10,
  Packed = 20,
  Stock = 40,
  Dispatched = 70,
  Opened = 72,
  Used = 74,
  Precleaned = 76,
  Returned = 80,
  ErrorReg = 95,
  Last = 100,
}

export enum OperationStatus {
  Planned = 10,
  Accepted = 20,
  Started = 30,
  Done = 40,
  Cancelled = 50
}

export enum OrdersLeadStatus {
  Warning = 0,
  Alert = 1,
  Neutral = 2,
  Done = 3
}

export enum EventType {
  AddedCommentToUnit = 19,
  LabelCommentToUnit = 20,
  AddedOPCommentToUnit = 21
}

export enum ArticleStatus {
  Normal = 10,
  Dieing = 50,
  Dead = 90
}

export enum MachineType {
  FirstMachineType = 0,
  Sterilizer = 0,
  Washer = 1,
  PreDis = 2,
  EndoWasher = 3,
  Incubator = 4,
  EndoDryer = 5,
  LastMachineType = 5
}
// end: Enumerations that are constants in Delphi

export const NAME_TO_ENUM_MAPPING: StringDictionary<any> = {
  ['alternativeArticleStatus']: AlternativeArticleStatus,
  ['assignFactoryData']: AssignFactoryData,
  ['custNameDisplay']: CustNameDisplay,
  ['custOrderBookingMode']: CustOrderBookingMode,
  ['custPreDispatchMode']: CustPreDispatchMode,
  ['decNonDispItemStockOnPack']: DecNonDispItemStockOnPack,
  ['deltaScanMode']: DeltaScanMode,
  ['expireType']: ExpireType,
  ['facDeductItemsFromStockInOpType']: FacDeductItemsFromStockInOpType,
  ['facOrderBookingMode']: FacOrderBookingMode,
  ['factoryDefProdTraceType']: FactoryDefProdTraceType,
  ['fastTrackCodeType']: FastTrackCodeType,
  ['fastTrackCreatorType']: FastTrackCreatorType,
  ['fastTrackDisplayMode']: FastTrackDisplayMode,
  ['fastTrackEndType']: FastTrackEndType,
  ['fastTrackInvoiceType']: FastTrackInvoiceType,
  ['fastTrackStatus']: FastTrackStatus,
  ['fastTrackStockBasedMode']: FastTrackStockBasedMode,
  ['fastTrackStockBasedOrdersFilter']: FastTrackStockBasedOrdersFilter,
  ['fuzzySearchSortOrder']: FuzzySearchSortOrder,
  ['fuzzySearchTextMatchType']: FuzzySearchTextMatchType,
  ['identArticleLineUsage']: IdentArticleLineUsage,
  ['identStockUsage']: IdentStockUsage,
  ['includeItemSerialUsage']: IncludeItemSerialUsage,
  ['incNonDispItemStockOnPack']: IncNonDispItemStockOnPack,
  ['itemAltPricingMode']: ItemAltPricingMode,
  ['itemDifficultyMode']: ItemDifficultyMode,
  ['itemInstrumentType']: ItemInstrumentType,
  ['itemPricingMode']: ItemPricingMode,
  ['itemPricingType']: ItemPricingType,
  ['itemReservationMode']: ItemReservationMode,
  ['itemSalesRestriction']: ItemSalesRestriction,
  ['itemTraceType']: ItemTraceType,
  ['language']: Language,
  ['legacyProcessType']: LegacyProcessType,
  ['locaSubProcessType']: LocaSubProcessType,
  ['loginCookieMethod']: LoginCookieMethod,
  ['logKeyType']: LogKeyType,
  ['manualEndoDryingMode']: ManualEndoDryingMode,
  ['minOrderLeadTime']: MinOrderLeadTime,
  ['multiMediaSubType']: MultiMediaSubType,
  ['multiMediaType']: MultiMediaType,
  ['notifyPriority']: NotifyPriority,
  ['operationSearchType']: OperationSearchType,
  ['operationTypeType']: OperationTypeType,
  ['orderBookingMode']: OrderBookingMode,
  ['orderBookingPriority']: OrderBookingPriority,
  ['orderBookingStatus']: OrderBookingStatus,
  ['orderLockMode']: OrderLockMode,
  ['orderOperationSyncMode']: OrderOperationSyncMode,
  ['orderOrgStatus']: OrderOrgStatus,
  ['orderSuppPickMode']: OrderSuppPickMode,
  ['orderSuppPickPriority']: OrderSuppPickPriority,
  ['orderSuppPriority']: OrderSuppPriority,
  ['orderSuppStatus']: OrderSuppStatus,
  ['orderType']: OrderType,
  ['passwordVerificationMethod']: PasswordVerificationMethod,
  ['passwordVerify']: PasswordVerify,
  ['patientAgeUsage']: PatientAgeUsage,
  ['patientGenderUsage']: PatientGenderUsage,
  ['prefListOrderCreateMode']: PrefListOrderCreateMode,
  ['processStatus']: ProcessStatus,
  ['processType']: ProcessType,
  ['prodDispatchRestriction']: ProdDispatchRestriction,
  ['prodOverviewAvailability']: ProdOverviewAvailability,
  ['prodOverviewVisibilityMode']: ProdOverviewVisibilityMode,
  ['prodReservationMode']: ProdReservationMode,
  ['productDifficultyMode']: ProductDifficultyMode,
  ['productLine']: ProductLine,
  ['productLocateSection']: ProductLocateSection,
  ['productReservationType']: ProductReservationType,
  ['productReturnState']: ProductReturnState,
  ['productStockControlMode']: ProductStockControlMode,
  ['productStorageType']: ProductStorageType,
  ['productTraceType']: ProductTraceType,
  ['productType']: ProductType,
  ['repairMode']: RepairMode,
  ['reservationState']: ReservationState,
  ['reservationStatus']: ReservationStatus,
  ['reservFlag']: ReservFlag,
  ['reservLogType']: ReservLogType,
  ['scanUIStartMode']: ScanUIStartMode,
  ['searchAutoPictureMode']: SearchAutoPictureMode,
  ['searchResultOrigin']: SearchResultOrigin,
  ['sexType']: SexType,
  ['specialSearchType']: SpecialSearchType,
  ['stdCommentFreeTextOption']: StdCommentFreeTextOption,
  ['stdCommentShowInProdOption']: StdCommentShowInProdOption,
  ['stockBackOrderMode']: StockBackOrderMode,
  ['stockOrderInMode']: StockOrderInMode,
  ['stockPrnMode']: StockPrnMode,
  ['stockType']: StockType,
  ['substitutionArticleType']: SubstitutionArticleType,
  ['substitutionReason']: SubstitutionReason,
  ['substMode']: SubstMode,
  ['tableTypes']: TableTypes,
  ['TDOCAppType']: TDOCAppType,
  ['TDOCTable']: TDOCTable,
  ['triggerPostponeRight']: TriggerPostponeRight,
  ['uILocaAvailability']: UILocaAvailability,
  ['useOfUnusedSerials']: UseOfUnusedSerials,
  ['useOfUsedSerials']: UseOfUsedSerials,
  ['userPackOnScreen']: UserPackOnScreen,
  ['userPictureOnPack']: UserPictureOnPack,
  ['webDisplayCustomerProductsMode']: WebDisplayCustomerProductsMode,
  ['webDisplayGeneralProductsMode']: WebDisplayGeneralProductsMode,
  ['webDisplayPriceMode']: WebDisplayPriceMode,
  ['webEditOprationArticlesMode']: WebEditOprationArticlesMode,
  ['webFastTrackMode']: WebFastTrackMode,
  ['webLoginMode']: WebLoginMode,
  ['webOrderMode']: WebOrderMode,
  ['webStartStopOperationMode']: WebStartStopOperationMode,
  // begin: Enumerations that are constants in Delphi
  ['unitStatus']: UnitStatus,
  ['endoscopeUnitStatus']: UnitStatus,
  ['operationStatus']: OperationStatus,
  ['eventType']: EventType,
  ['articleStatus']: ArticleStatus,
  ['ordersLeadStatus']: OrdersLeadStatus,
  ['machineType']: MachineType
  // end: Enumerations that are constants in Delphi
}
