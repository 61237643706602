export class UserDesktopOptionsModel {
  display_ProdFiltMethodGeneral = false;
  display_ProdFiltMethodCustomer = false;
  display_ProdFiltMethodCustSite = false;
  mediaFrm_Width = 700;
  mediaFrm_Height = 560;
  identDlg_Width = 600;
  identDlg_Height = 485;
  alterDlg_Width = 600;
  alterDlg_Height = 490;
  search_RowsPerSearchPage = 20;
  search_OrderableFromCurrentFactory = false;
  mediaAutoPlayVideos = false;
}
