import { Params } from '@angular/router';
import { TD_QUERY_PARAMS } from '@core/data-layer/shared/models/td.constants';
import { SearchParam, SearchParamBase, SearchParamTypes } from './search-param.models';

export interface SearchParamDate extends SearchParamBase {
  type: SearchParamTypes;
  value?: Date;
}

// Storing date parameter as a number in milliseconds
export function addDatePropToParams(property: SearchParam, params: Params): Params {
  params[property.urlParamName] = (property.value as Date).valueOf();
  return params;
}

// Getting date parameter from a number in milliseconds to Date
export function extractDateFromStringParam(paramName: string, params: Params): Date {
  return params[TD_QUERY_PARAMS[paramName]] ? new Date(+params[TD_QUERY_PARAMS[paramName]]) : undefined;
}
