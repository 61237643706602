import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, Inject } from '@angular/core';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { PropertyTypes } from '@core/table-info/models/grid-structure.interface';
import { TD_DESKTOP_IDENTIFIERS, TD_MODEL_NAMES, TD_ACTION_FIELDS, TD_DYNAMIC_FIELDS, NO_ERROR_CODE } from '@core/data-layer/shared/models/td.constants';
import { GridColumnConfigurationViewInterface, GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { SearchArgsModel } from '@core/search/models/search-args.model';
import { SpecialSearchType } from '@core/data-layer/shared/models/td.enumerations';
import { SearchManagerService } from '@core/search/services/search-manager.service';
import { UnitFilterService } from '@core/search/filters/unit-filter.service';
import { OperationDataServiceInterface } from '@core/data-layer/operation-data/services/operation-data.service.interface';

@Component({
  selector: 'td-remove-unit',
  templateUrl: 'remove-unit.component.html',
  styleUrls: ['./remove-unit.component.less']
})

export class RemoveUnitComponent extends BaseComponent implements OnInit {

  @Input() articleUpForRemoval: any;
  @Input() operationKeyId: number;

  @Output() popupResolved: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('removeUnitPopupTemplate', { static: false}) removeUnitPopupTemplate: TemplateRef<any>;
  removeUnitGridStructure: GridStructureViewInterface;
  unitsToRemove: any[];
  unitSelected: boolean;

  TD_DESKTOP_IDENTIFIERS = TD_DESKTOP_IDENTIFIERS;
  TD_MODEL_NAMES = TD_MODEL_NAMES;
  TD_DYNAMIC_FIELDS = TD_DYNAMIC_FIELDS;
  constructor(
    @Inject('OPERATION_DATA_SERVICE') private operationService: OperationDataServiceInterface,
    private searchManagerService: SearchManagerService,
    private unitFilterService: UnitFilterService
  ) {
    super();
  }

  ngOnInit() {
    const unitListAsNumbers = this.articleUpForRemoval[TD_DYNAMIC_FIELDS.unitList].map(value => parseInt(value, 10));
    this.getOperationRemoveUnitGridStructure().then(gridStructureLoaded => {
      if (gridStructureLoaded) {
        const searchArgs = this.getOperationRemoveUnitSearchArgs(this.removeUnitGridStructure, unitListAsNumbers);
        this.searchManagerService.requestSearch(searchArgs, '').subscribe(response => {
          this.unitsToRemove = response.value.dataSet.mappedRows;
          this.unitsToRemove.forEach(unit => unit[TD_DYNAMIC_FIELDS.select] = false);
          this.popupService.showTemplate('operationDetails.removeUnit', this.removeUnitPopupTemplate).then(() => {
            this.popupResolved.emit(null);
          });
        });
      }
    });
  }

  getOperationRemoveUnitGridStructure(): Promise<boolean> {
    return new Promise(resolve => {
      this.tableInfoService.loadGridStructure(TD_DESKTOP_IDENTIFIERS.operationRemoveUnitListGrid, TD_MODEL_NAMES.unitModel).subscribe(gridStructure => {
        const selectUnitColumn: GridColumnConfigurationViewInterface = {
          dataField: TD_ACTION_FIELDS.select,
          dataType: PropertyTypes.typeUndefined,
          visible: true,
          visibleIndex: 0,
          width: '30',
          displayName: '',
          style: {
            alignment: 'center'
          }
        };
        this.gridColumnHelperService.addCustomColumn(gridStructure.columnsConfigurations, selectUnitColumn);
        this.removeUnitGridStructure = gridStructure;
        resolve(true);
      });
    });
  }

  getOperationRemoveUnitSearchArgs(gridStructure: GridStructureViewInterface, unitList: number[]): SearchArgsModel {
    return this.searchManagerService.initSearchRequestArgs(
      SpecialSearchType.None,
      TD_MODEL_NAMES.unitModel,
      gridStructure,
      this.unitFilterService.getOperationUnitListFilter(unitList),
      true
    );
  }

  async confirmRemoveUnit() {
    let removeUnitResult: boolean = true;
    const unitIdsToRemove: number[] = this.unitsToRemove.map(entry => {
      return entry.select ? entry.keyId : undefined;
    }).filter(Number);

    for (const entry of unitIdsToRemove) {
      if (removeUnitResult) {
        await this.performRemoveUnit(entry).then(promiseResponse => {
          removeUnitResult = promiseResponse;
          if (promiseResponse && unitIdsToRemove.indexOf(entry) === unitIdsToRemove.length - 1) {
            this.popupResolved.emit('refresh');
            this.popupService.cancelPopup();
          }
        });
      }
    }
  }

  async performRemoveUnit(keyId: number): Promise<boolean> {
    return new Promise(resolve => {
      this.operationService.removeUnit(this.operationKeyId, keyId).subscribe(response => {
        if (response.error === NO_ERROR_CODE) {
          resolve(true);
        }
        else {
          this.popupService.cancelPopup();
          this.popupResolved.emit(response.message);
          resolve(false);
        }
      });
    });
  }

  checkUnitSelected() {
    this.unitSelected = this.unitsToRemove.some(unit => unit[TD_DYNAMIC_FIELDS.select]);
  }
}
