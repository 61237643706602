"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".toolbar-button[_ngcontent-%COMP%]{min-width:92px!important;height:48px!important;border:var(--border-5);font-size:var(--font-small);color:var(--font-primary-color-1)}  .toolbar-button.dx-state-active{color:var(--color-button-tertiary-foreground-active)}.toolbar-button-margin[_ngcontent-%COMP%]{margin-right:.5rem;margin-bottom:.2rem;margin-top:.2rem}  .toolbar-button .dx-button-text{display:block!important;line-height:100%!important}  .toolbar-button .dx-button-content{padding-left:2px!important;padding-right:2px!important}  .toolbar-button .dx-icon{width:24px;height:24px;line-height:100%;margin:0}"];
exports.styles = styles;
