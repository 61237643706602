"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SigninCallbackComponent = /** @class */ (function () {
    function SigninCallbackComponent(authService) {
        this.authService = authService;
        // for the case when user press 'browser-back' button on 'auth-customers' page
        if (this.authService.isAuthenticated()) {
            this.authService.startSignout();
        }
        else {
            this.authService.completeSignin();
        }
    }
    return SigninCallbackComponent;
}());
exports.SigninCallbackComponent = SigninCallbackComponent;
