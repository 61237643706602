"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#searchText[_ngcontent-%COMP%]     .dx-texteditor-input{text-align:left}.tab-title[_ngcontent-%COMP%]{padding:0!important}  .dx-tab{padding:.2rem}.serialNumberHeader[_ngcontent-%COMP%]{border-bottom:1px solid var(--color-gray-5);padding:5px}.serialNumberContent[_ngcontent-%COMP%]{padding:5px;height:auto;overflow-y:auto;white-space:normal}.serialNumberElement[_ngcontent-%COMP%]{white-space:nowrap;display:inline-block;height:20px}#searchTabPanel[_ngcontent-%COMP%]  .dx-tabpanel-container{margin-top:0!important;padding-top:0!important;height:calc(100% - 28px)}"];
exports.styles = styles;
