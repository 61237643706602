import { Component, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { TD_AUTH_ERRORS, TD_QUERY_PARAMS } from '@core/data-layer/shared/models/td.constants';
import { CustomerModel } from '@core/data-layer/customer/models/customer.model';
import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';
import { AppState } from '@core/data-layer/app.state';
import { selectUserCustomers, selectCurrentCustomer } from '@core/data-layer/customer/store/customer.selectors';
import * as custActions from '@core/data-layer/customer/store/customer.actions';
import * as factActions from '@core/data-layer/factory/store/factory.actions';
import * as userSessionActions from '@core/data-layer/user-session/store/user-session.actions';
import { TdLoadPanelService } from '@app/shared/components/td-load-panel/services/td-load-panel.service';
import { LoadIOConfigurations } from '@app/dashboard/data-layer/info-overview/store/info-overview.actions';
import { UtilityService } from '@core/utility/utility.service';
import { OperationDataServiceInterface } from '@core/data-layer/operation-data/services/operation-data.service.interface';
import { ROUTE_PATHS } from '@shared/models/app.constants';
import { OrderServiceInterface } from '@core/data-layer/order/services/order.service.interface';
import { OrderTemplateServiceInterface } from '@core/data-layer/order-template/services/order-template.service.interface';

@Component({
  selector: 'td-auth-customers',
  templateUrl: './auth-customers.component.html',
  styleUrls: ['./auth-customers.component.less']
})
export class AuthCustomersComponent implements OnDestroy {

  customers$: Observable<CustomerModel[]>;
  queryParams$: Observable<Params>;
  subscriptions: Subscription[] = [];

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    @Inject('OPERATION_DATA_SERVICE') private operationService: OperationDataServiceInterface,
    @Inject('ORDER_SERVICE') private orderService: OrderServiceInterface,
    @Inject('ORDER_TEMPLATE_SERVICE') private orderTemplateService: OrderTemplateServiceInterface,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    public loadPanelService: TdLoadPanelService,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.loadPanelService.showLoadPanel('appMessages.acquiringCustomers');
    this.subscriptions.push(
      this.authService.authStatus$().subscribe(async status => {
        if(status) {
          this.dispatchLoadCommandsToStore();
          this.customers$ = this.store.select(selectUserCustomers);          
          this.determineCustomerSelectionFlow(await this.getUserCustomers(), await this.getQueryParams());
          this.redirectToPreviousPage();
        }
      })
    );  
  }
  
  ngOnDestroy() {
    this.loadPanelService.hideLoadPanel();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onCustomerSelected(customer: CustomerModel) {
    this.selectCustomerInStore(customer);
  }

  /** START SECTION: DISPATCHING STORE DATA **/
  private dispatchLoadCommandsToStore(): void {
    this.store.dispatch(new userSessionActions.LoadProfileData());
    this.store.dispatch(new userSessionActions.LoadUserOptions());
    this.store.dispatch(new custActions.LoadUserCustomers());
  }
  /** END SECTION: DISPATCHING STORE DATA **/

  /** START SECTION: COLLECT CUSTOMERS ASSIGNED TO LOGGED IN USER **/
  private async getUserCustomers(): Promise<CustomerModel[]> {
    return new Promise(resolve => {
      this.subscriptions.push(
        this.customers$.subscribe(async customerList => {
          if(this.authService.isAuthenticated() && customerList) {
            resolve(customerList);
          }
        })
      );
    });
  }
  /** END SECTION: COLLECT CUSTOMERS ASSIGNED TO LOGGED IN USER **/

  /** START SECTION: DEALING WITH QUERY PARAMS **/
  private async getQueryParams(): Promise<Params> {
    return new Promise(resolve => {      
      this.subscriptions.push(
        this.activatedRoute.queryParams.subscribe(params => {
          const paramsWithoutCancelProp = this.removeCancelPropFromReturnUrlParam(params);
          this.updateUrlParams(paramsWithoutCancelProp);
          resolve(paramsWithoutCancelProp);
        })
      );
    });
  }

  private removeCancelPropFromReturnUrlParam(params: Params): Params {    
    if (params['returnUrl'] && params['returnUrl'].indexOf('?cancel=') !== -1) {
      params = { 'returnUrl': params['returnUrl'].split('?cancel=')[0] };      
    }
    return params;
  }

  private updateUrlParams(newParams: Params): void {
    this.router.navigate([], {
      queryParams: newParams,
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge'
    });
  }
  /** END SECTION: DEALING WITH QUERY PARAMS **/

  private async determineCustomerSelectionFlow(customerList: CustomerModel[], params: Params): Promise<void> {
    let customerSelectedFromReturnUrlParams = this.selectCustomerFromReturnUrlParams(customerList, params);
    if (!customerSelectedFromReturnUrlParams) {
      let customerSelectedFromEntity = await this.selectCustomerFromSpecificEntity(customerList, params);
      if (!customerSelectedFromEntity) {
        this.selectCustomerBasedOnCustomerList(customerList);    
      }
    }
  }
  
  /** START SECTION: SELECT CUSTOMER BASED ON CUSTOMER ID IN RETURN URL **/
  private selectCustomerFromReturnUrlParams(customerList: CustomerModel[], params?: Params): boolean {
    let customerId: number = this.getCustomerIdFromParams(params);
    return this.selectCustomerInCustomerList(customerList, customerId);
  }

  private getCustomerIdFromParams(params: Params): number {
    if (params['returnUrl']) {
      const targetValue = this.utilityService.extractQueryParamValueFromString(params['returnUrl'], 'customerId');
      return Number(targetValue);
    }
    return 0;
  }
  /** END SECTION: SELECT CUSTOMER BASED ON CUSTOMER ID IN RETURN URL **/


  /** START SECTION: SELECT CUSTOMER BASED ON ENTITY **/

  private selectCustomerFromSpecificEntity(customerList: CustomerModel[], params: Params): Promise<boolean> {  
      return new Promise(resolve => {
        this.getSpecificEntityCustomerId(params).subscribe(customerId => {          
          const customerSelected = this.selectCustomerInCustomerList(customerList, customerId);
          resolve(customerSelected)
        });
    });
  }
  
  private getSpecificEntityCustomerId(params: Params): Observable<number> {    
    let splitParams = params['returnUrl'].split('/');
    const routePath = splitParams[0];
    const entityId = splitParams[1];
    return (routePath && routePath.length > 0) && (entityId && entityId.length > 0) ? this.getEntityRequest(routePath, Number(entityId)) : of(null);
  }

  private getEntityRequest(route: string, entityId: number): Observable<number>  {
    switch (route) {
      case ROUTE_PATHS.operations:      return this.operationService.getOperationCustomer(entityId);
      case ROUTE_PATHS.orders:          return this.orderService.getOrderCustomer(entityId);
      case ROUTE_PATHS.orderTemplates:  return this.orderTemplateService.getOrderTemplateCustomer(entityId);
    }
  }
  /** END SECTION: SELECT CUSTOMER BASED ON ENTITY **/

  /** START SECTION: SELECT CUSTOMER BASED ON CUSTOMER LIST **/  
  private selectCustomerBasedOnCustomerList(customerList: CustomerModel[]): void {
    // no customers linked to current user - execute logout method
    if (customerList.length === 0) {
      this.errorNavigateTo('/', TD_AUTH_ERRORS.noCust);
    }
    // there is only one customer, that can be selected for current user - select this customer and redirect to "search" page
    else if (customerList.length === 1) {
      this.selectCustomerInStore(customerList[0]);
    }
    // there are more then one customers linked to current user - show list of custommers
    else {
      this.loadPanelService.hideLoadPanel();
    }
  }
  
  private errorNavigateTo(url: string, errorCode: string): void {
    this.router.navigate([url], {
      queryParams: {
        error: errorCode
      },
      queryParamsHandling: 'merge'
    });
  }
  /** END SECTION: SELECT CUSTOMER BASED ON CUSTOMER LIST **/

  /** START SECTION: FUNCTIONS SHARED BETWEEN ALL 3 CUSTOMER SELECTION APPROACHES **/  
  private selectCustomerInCustomerList(customerList: CustomerModel[], desiredCustomerId: number): boolean {
    const desiredCustomer = customerList.find(entry => entry.keyId === desiredCustomerId);
    if (desiredCustomer) {
      this.selectCustomerInStore(desiredCustomer);
    }    
    return desiredCustomer !== undefined;
  }
  
  private selectCustomerInStore(cust: CustomerModel): void {
    this.store.dispatch(new factActions.LoadCustomerFactories(cust.keyId));
    this.store.dispatch(new factActions.LoadUserFactories());
    this.store.dispatch(new custActions.SetCurrentCustomer(cust));
    if (cust.cuSiteKeyId) {
      this.store.dispatch(new custActions.LoadSiteCustomers(cust.cuSiteKeyId));
    }
    this.store.dispatch(new LoadIOConfigurations());
  }
  
  private redirectToPreviousPage(): void {   
    this.subscriptions.push(      
      this.store.select(selectCurrentCustomer).subscribe(cust => {
        if (cust !== null) {
          this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams[TD_QUERY_PARAMS.returnUrl]);
        }
      })
    );
  }
  /** END SECTION: FUNCTIONS SHARED BETWEEN ALL 3 CUSTOMER SELECTION APPROACHES **/  
}
