"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
exports.selectFactoryState = function (state) { return state.factory; };
var ɵ0 = function (state) { return state.allFactories; };
exports.ɵ0 = ɵ0;
exports.selectAllFactories = store_1.createSelector(exports.selectFactoryState, ɵ0);
var ɵ1 = function (state) { return state.customerFactories; };
exports.ɵ1 = ɵ1;
exports.selectCustomerFactories = store_1.createSelector(exports.selectFactoryState, ɵ1);
var ɵ2 = function (state) { return state.userFactories; };
exports.ɵ2 = ɵ2;
exports.selectUserFactories = store_1.createSelector(exports.selectFactoryState, ɵ2);
