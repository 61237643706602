import { InfoOverview } from '@app/dashboard/models/info-overview.model';
import * as ioActions from './info-overview.actions';

export interface InfoOverviewState {
  connectedToHub: boolean;
  infoOverviews: InfoOverview[];
}

export const initialState: InfoOverviewState = {
  connectedToHub: false,
  infoOverviews: null
};

export function infoOverviewReducer(state = initialState, action: ioActions.InfoOverviewActions): InfoOverviewState {
  switch (action.type) {
    case ioActions.InfoOverviewActionTypes.LoadIOConfigurations:
      return handleLoadIOConfigurations(state, action);
    case ioActions.InfoOverviewActionTypes.LoadIOConfigurationsSuccess:
      return handleLoadIOConfigurationsSuccess(state, action);
    case ioActions.InfoOverviewActionTypes.GetIOConfigurationByIdSuccess:
      return handleGetIOConfigurationByIdSuccess(state, action);
    case ioActions.InfoOverviewActionTypes.SetIOData:
      return handleSetIOData(state, action);
    case ioActions.InfoOverviewActionTypes.SetIODataSuccess:
      return handleSetIODataSuccess(state, action);
    case ioActions.InfoOverviewActionTypes.SetConnectedToHub:
      return handleSetConnectedToHub(state, action);
    case ioActions.InfoOverviewActionTypes.SetConnectedToJSrv:
      return handleSetConnectedToJSrv(state, action);

    default:
      return state;
  }
}

function handleLoadIOConfigurations(state: InfoOverviewState, action: ioActions.LoadIOConfigurations): InfoOverviewState {
  return state;
}

function handleLoadIOConfigurationsSuccess(state: InfoOverviewState, action: ioActions.LoadIOConfigurationsSuccess): InfoOverviewState {
  return Object.assign({}, state, { infoOverviews: action.payload });
}

function handleGetIOConfigurationByIdSuccess(state: InfoOverviewState, action: ioActions.GetIOConfigurationByIdSuccess): InfoOverviewState {
  const newIOs = !!state.infoOverviews ? state.infoOverviews : Array<InfoOverview>();
  action.payload.data = null;
  if (newIOs.some((io) => io.configId === action.payload.configId)) {
    newIOs.map((io) => (io.configId === action.payload.configId ? action.payload : io));
  }
  else {
    newIOs.push(action.payload);
  }
  return Object.assign({}, state, { infoOverviews: newIOs });
}

function handleSetIOData(state: InfoOverviewState, action: ioActions.SetIOData): InfoOverviewState {
  return state;
}

function handleSetIODataSuccess(state: InfoOverviewState, action: ioActions.SetIODataSuccess): InfoOverviewState {
  if (state.infoOverviews) {
    return Object.assign({}, state, {
      infoOverviews: state.infoOverviews.map((io) => {
        // we just received update for info-overview data
        if (io.configId === action.payload.configId) {
          io.data = action.payload.data;
        }
        return io;
      })
    });
  }
  else {
    return state;
  }
}

function handleSetConnectedToHub(state: InfoOverviewState, action: ioActions.SetConnectedToHub): InfoOverviewState {
  return Object.assign({}, state, { connectedToHub: action.payload });
}

function handleSetConnectedToJSrv(state: InfoOverviewState, action: ioActions.SetConnectedToJSrv): InfoOverviewState {
  if (state.infoOverviews) {
    state.infoOverviews.forEach(io => {
      if (io.data instanceof action.ioDataType) {
        io.data.hasConnection = action.payload;
      }
    });
    return Object.assign({}, state);
  }
  else {
    return state;
  }
}
