"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var td_constants_1 = require("@app/core/data-layer/shared/models/td.constants");
var language_service_1 = require("@app/core/language/services/language.service");
var HelpComponent = /** @class */ (function () {
    function HelpComponent(languageService) {
        this.languageService = languageService;
        this.currentHelpArticleId = td_constants_1.TD_HELP_ARTICLES[0].articleId;
        this.allHelpArticles = td_constants_1.TD_HELP_ARTICLES;
    }
    Object.defineProperty(HelpComponent.prototype, "routerUrl", {
        set: function (url) {
            this.updateHelpArticleTarget(url);
        },
        enumerable: true,
        configurable: true
    });
    HelpComponent.prototype.ngOnInit = function () {
        this.setHelpLanguage();
    };
    HelpComponent.prototype.help = function () {
        window.open("" + this.helpProjectUrl + this.currentHelpArticleId, '_blank');
    };
    HelpComponent.prototype.setHelpLanguage = function () {
        var desiredLanguage = this.languageService.getCurrentLanguage();
        var helpProjectBaseUrl = 'Help';
        var helpProjectDefaultArticle = 'Default.htm#cshid=';
        var helpProjectLanguage = this.selectProjectPathFromCulture(desiredLanguage);
        this.helpProjectUrl = helpProjectBaseUrl + "/" + helpProjectLanguage + "/" + helpProjectDefaultArticle;
    };
    HelpComponent.prototype.selectProjectPathFromCulture = function (culture) {
        switch (culture) {
            case 'en-US': return 'us';
            /**
             * These matches are disabled, as we do not currently have the help projected approved in any other languages.
             * When any new language is accepted and added to the web project, we should append a case for it here
             */
            // case 'da-DK': return 'dk';
            // case 'de-DE': return 'de';
            // case 'fr-FR': return 'fr';
            // case 'sv-SE': return 'se';
            default: return 'us';
        }
    };
    HelpComponent.prototype.updateHelpArticleTarget = function (url) {
        var splitUrl = url.split('/');
        var targetUrl = splitUrl.length > 2 ? splitUrl[1] + "/:id" : splitUrl[1];
        if (targetUrl.indexOf('?')) {
            targetUrl = targetUrl.split('?')[0];
        }
        var newTarget = this.allHelpArticles.find(function (entry) { return entry.url === targetUrl; });
        if (newTarget) {
            this.currentHelpArticleId = newTarget.articleId;
        }
    };
    return HelpComponent;
}());
exports.HelpComponent = HelpComponent;
