"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ErrorReportModel = /** @class */ (function () {
    function ErrorReportModel(message, browserURL, exceptionClass, exceptionMessage, stacktrace, traceId) {
        this.message = message;
        this.time = new Date();
        this.deviceInfo = window.navigator.userAgent;
        this.browserURL = browserURL;
        this.exceptionClass = exceptionClass;
        this.exceptionMessage = exceptionMessage;
        if (stacktrace) {
            this.stacktrace = stacktrace;
        }
        if (traceId) {
            this.traceId = traceId;
        }
    }
    return ErrorReportModel;
}());
exports.ErrorReportModel = ErrorReportModel;
