import { FactoryModel } from '@core/data-layer/factory/models/factory.model';
import { OperationDataModel } from '@core/data-layer/operation-data/models/operation-data.model';
import { OrderModel } from '@core/data-layer/order/models/order.model';
import { OrderTemplateModel } from '@core/data-layer/order-template/models/order-template.model';
import { ProductModel } from '@core/data-layer/product/models/product.model';
import { StocksModel } from '@core/data-layer/stocks/models/stocks.model';
import { UnitModel } from '@core/data-layer/unit/models/unit.model';
import { UserModel } from '@core/data-layer/user/models/user.model';
import {
  CustOrderBookingMode,
  CustPreDispatchMode
} from '@core/data-layer/shared/models/td.enumerations';

export class CustomerModel {
  keyId: number;
  created?: Date;
  createdKeyId?: number;
  modified?: Date;
  modifiedKeyId?: number;
  account?: string;
  address1?: string;
  address2?: string;
  arrivedLGrpKeyId?: number;
  availablelgrpKeyId?: number;
  avgOnSiteStoreTime?: number;
  avgOpDuration?: number;
  barcode?: string;
  calKeyId?: number;
  canOrder: boolean;
  chargeOptions?: number;
  city?: string;
  cntpsKeyId?: number;
  color?: number;
  country?: string;
  cuSiteKeyId?: number;
  customer: string;
  defStokKeyId?: number;
  delAddress1?: string;
  delAddress2?: string;
  delCity?: string;
  delCountry?: string;
  delEmail?: string;
  delFax?: string;
  delGln?: string;
  deliveryNote: boolean;
  delTelephone?: string;
  email?: string;
  endoSETime?: number;
  external: boolean;
  facKeyId?: number;
  fax?: string;
  flags1?: number;
  gln?: string;
  intPickTime?: number;
  intTransTimeArrive?: number;
  intTransTimeDepart?: number;
  invoiceCustKeyId?: number;
  invoiceRebate?: number;
  lineRebate?: number;
  minOrderLeadTime?: number;
  name: string;
  orderBookingMode?: CustOrderBookingMode;
  preDispatchMode?: CustPreDispatchMode;
  remark?: string;
  reordermode?: number;
  shortName?: string;
  status: number;
  stGrKeyId?: number;
  tagsSurvive: boolean;
  telephone?: string;
  traceability: boolean;
  userField01?: string;
  userField02?: string;
  userField03?: string;
  userField04?: string;
  userField05?: string;
  userField06?: string;
  userField07?: string;
  userField08?: string;
  userField09?: string;
  userField10?: string;
  userField11?: string;
  userField12?: string;
  userField13?: string;
  userField14?: string;
  userField15?: string;
  userField16?: string;
  userField17?: string;
  userField18?: string;
  userField19?: string;
  userField20?: string;
  webPassword?: string;
  webSite?: string;
  webUser?: string;

  // One-to-one relationships
  createdUser?: UserModel;
  defStok?: StocksModel;
  fac?: FactoryModel;
  invoiceCust?: CustomerModel;
  modifiedUser?: UserModel;

  // One-to-many relationships
  custOperationDataList?: OperationDataModel[];
  custProductList?: ProductModel[];
  custUnitList?: UnitModel[];
  invoiceCustCustomerList?: CustomerModel[];
  orgInvRefCustOrderList?: OrderModel[];
  orgRefCustOrderList?: OrderModel[];
  orgRefCustOrderTemplateList?: OrderTemplateModel[];
  refCustStocksList?: StocksModel[];
  suppRefCustOrderList?: OrderModel[];

  constructor() {
    this.custOperationDataList = new Array<OperationDataModel>();
    this.custProductList = new Array<ProductModel>();
    this.custUnitList = new Array<UnitModel>();
    this.invoiceCustCustomerList = new Array<CustomerModel>();
    this.orgInvRefCustOrderList = new Array<OrderModel>();
    this.orgRefCustOrderList = new Array<OrderModel>();
    this.orgRefCustOrderTemplateList = new Array<OrderTemplateModel>();
    this.refCustStocksList = new Array<StocksModel>();
    this.suppRefCustOrderList = new Array<OrderModel>();
  }
}
