import { Router } from '@angular/router';

import { Component, OnInit, Input } from '@angular/core';
import { SerialNumberInfoInterface } from '../../models/serial-number-info.interface';
import { TdPopupService } from '../td-popup/services/td-popup.service';

@Component({
  selector: 'td-serial-number-info',
  templateUrl: './td-serial-number-info.component.html',
  styleUrls: ['./td-serial-number-info.component.less'],
})
export class TdSerialNumberInfoComponent implements OnInit {

  @Input() serialInfo: SerialNumberInfoInterface;

  constructor(
    private router: Router,
    private popupService: TdPopupService
  ) { }

  public ngOnInit() { }

  cancel() {
    this.popupService.cancelPopup();
  }

  navigateToUnit() {
    this.cancel();
    this.router.navigate(['/units', this.serialInfo.unit]);
  }
}
