import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ItemModel } from '../models/item.model';
import { ItemViewModel } from '../models/item-view.model';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { ItemTraceType, WebDisplayPriceMode, ArticleStatus } from '@core/data-layer/shared/models/td.enumerations';
import { ViewHelperService } from '@core/data-layer/shared/services/view-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ItemHelperService {

  constructor(
    private translateService: TranslateService,
    private sessionHelperService: SessionHelperService,
    private viewHelperService: ViewHelperService
  ) { }

  /**
   * @description converts [ItemModel][Object] to [ItemViewModel][Object]
   * @param item: [ItemModel][Object]
   * @returns [ItemViewModel][Object]
   */
  convertItemToViewModel(item: ItemModel): ItemViewModel {
    return {
      ...item,
      itemText: item.item + ' - ' + item.text,
      supplierText: item.supp ? item.supp.supplier + ' - ' + item.supp.name : '',
      itemGroupText: item.itGrp ? item.itGrp.itemGroup + ' - ' + item.itGrp.name : '',
      itemSpecialtyText: item.itspc ? item.itspc.specialty + ' - ' + item.itspc.name : '',
      traceTypeText: this.translateService.instant(`itemTraceType.${ItemTraceType[item.traceType].toLowerCase()}`),
      omitted: (item.status === ArticleStatus.Dead),
      omittedText: this.viewHelperService.getOmittedText(item.status, this.translateService.instant('appCommon.item')),
      omittedImgUrl: this.viewHelperService.getOmittedImgUrl(item.status),
      displayPrice: (this.sessionHelperService.serverOptions.displayPriceMode === WebDisplayPriceMode.ShowAll)
    };
  }
}
