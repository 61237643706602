import { Inject, Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Observable } from 'rxjs';

import { SearchArgsModel, GridFilterSearchArgsModel } from '../models/search-args.model';
import { SpecialSearchType, FuzzySearchTextMatchType, SearchAutoPictureMode, IncludeItemSerialUsage, ItemInstrumentType, OperationSearchType, FuzzySearchSortOrder, UseOfUsedSerials, UseOfUnusedSerials } from '@core/data-layer/shared/models/td.enumerations';
import { SearchServiceInterface } from './search.service.interface';
import { SessionHelperService } from '@shared/services/session-helper.service';
import { ApiDataResponse } from '@core/data-layer/shared/models/api-response.model';
import { SearchResultViewModel } from '../models/search-result-view.model';
import { GridDataHelperService } from '@shared/services/grid-data-helper.service';
import { GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { TD_MAX_INT32_VALUE, TD_MODEL_NAMES } from '@core/data-layer/shared/models/td.constants';
import { PaginationArgsModel, OrderByArgsModel } from '@core/data-layer/shared/models/base-grid-args.model';
import { OperationFilterService } from '../filters/operation-filter.service';
import { nameOf } from '@core/utility/utility.service';
import { OperationDataModel } from '@core/data-layer/operation-data/models/operation-data.model';
import { UnitModel } from '@core/data-layer/unit/models/unit.model';
import { ProductModel } from '@core/data-layer/product/models/product.model';
import { ItemModel } from '@core/data-layer/item/models/item.model';
import { RecentSearchInterface } from '../models/recent-search.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchManagerService {

  private defaultShowAllResult = false;
  private defaultFilterIsSet = false;
  private defaultPageRowCount = 25;
  private defaultStartingRow = 0;
  private defaultViewCommonData = true;
  private defaultAutoPictureMode: SearchAutoPictureMode = SearchAutoPictureMode.Off;
  private defaultIdentifier = 0;
  private defaultUseFuzzySearchInFilters = false;
  recentOperationSearch: RecentSearchInterface = {};
  recentOrderSearch: RecentSearchInterface = {};
  recentEntitySearch: RecentSearchInterface = {};
  recentOrderTemplatesSearch: RecentSearchInterface = {};

  constructor(
    @Inject('SEARCH_SERVICE') private searchService: SearchServiceInterface,
    private sessionHelperService: SessionHelperService,
    private gridDataHelperService: GridDataHelperService,
    private operationFilterService: OperationFilterService
  ) { }

  initSearchRequestArgs(
    searchType: SpecialSearchType,
    mainTableModel: string,
    gridStructure: GridStructureViewInterface,
    criterias: any[] = [],
    showAllResult: boolean = this.defaultShowAllResult,
    autoPictureMode: SearchAutoPictureMode = this.defaultAutoPictureMode,
    filterIsSet = this.defaultFilterIsSet
  ): SearchArgsModel {
    const searchArgs = new SearchArgsModel(mainTableModel, gridStructure);
    searchArgs.requestId = 0;
    searchArgs.searchControllerId = 1;
    searchArgs.searchType = searchType;
    searchArgs.currentFactoryKeyId = this.sessionHelperService.currentFactoryKeyId;
    searchArgs.searchText = '';
    searchArgs.showAllResult = showAllResult;
    searchArgs.orderByFields = Object.assign([], []);
    searchArgs.paginationArgs.pageRowCount = this.defaultPageRowCount;
    searchArgs.paginationArgs.startingRow = this.defaultStartingRow;
    searchArgs.autoPictureMode = autoPictureMode;
    searchArgs.viewCommonData = this.defaultViewCommonData;
    searchArgs.criterias = criterias;
    searchArgs.filterIsSet = filterIsSet;
    searchArgs.preDefinedDropDownFilterText = '';
    this.setGridFilterArgsList(searchArgs, '', '');
    searchArgs.useFuzzySearchInFilters = this.defaultUseFuzzySearchInFilters;
    this.setFuzzySearchArgs(searchArgs, '', '', []);
    this.setExtraItemSerialArgs(searchArgs, IncludeItemSerialUsage.Off, UseOfUsedSerials.Off, UseOfUnusedSerials.Off, 0, 0);
    searchArgs.identifier = this.defaultIdentifier;
    return searchArgs;
  }

  initCustomerUnitsSearchRequestArgs(
    mainTableModel: string,
    gridStructure: GridStructureViewInterface,
    customerKeyId: number
  ): SearchArgsModel {
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.CustomerUnits, mainTableModel, gridStructure);
    searchArgs.extraCustomerUnitsArgs.customerKeyId = customerKeyId;
    return searchArgs;
  }

  initProductsBasedOnItemSearchRequestArgs(
    mainTableModel: string,
    gridStructure: GridStructureViewInterface,
    itemKeyId: number,
    composite: boolean,
    directly: boolean
  ): SearchArgsModel {
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.ProductsBasedOnItem, mainTableModel, gridStructure);
    searchArgs.extraProductsBasedOnItemArgs.itemKeyId = itemKeyId;
    searchArgs.extraProductsBasedOnItemArgs.composite = composite;
    searchArgs.extraProductsBasedOnItemArgs.directly = directly;
    return searchArgs;
  }

  initWebProductsBasedOnItemSearchRequestArgs(
    mainTableModel: string,
    gridStructure: GridStructureViewInterface,
    itemKeyId: number,
    composite: boolean,
    directly: boolean,
    factoryKeyId: number
  ): SearchArgsModel {
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.WebProductsBasedOnItem, mainTableModel, gridStructure, [], true, SearchAutoPictureMode.On);
    searchArgs.extraWebProductsBasedOnItemArgs.itemKeyId = itemKeyId;
    searchArgs.extraWebProductsBasedOnItemArgs.composite = composite;
    searchArgs.extraWebProductsBasedOnItemArgs.directly = directly;
    searchArgs.extraWebProductsBasedOnItemArgs.factoryKeyId = factoryKeyId;
    return searchArgs;
  }

  initWebArticlesSearchRequestArgs(
    searchType: SpecialSearchType.WebBaseProdItem | SpecialSearchType.WebProdWithSerials | SpecialSearchType.WebUnits,
    mainTableModel: string,
    gridStructure: GridStructureViewInterface,
    itemInstrumentType: ItemInstrumentType = ItemInstrumentType.Instrument,
    reducedSearch = false,
    productKeyId = 0
  ): SearchArgsModel {
    const searchArgs = this.initSearchRequestArgs(searchType, mainTableModel, gridStructure);

    searchArgs.extraWebArticlesArgs.orderFactoryList = Object.assign([], this.sessionHelperService.orderFactoryKeyIds);
    searchArgs.extraWebArticlesArgs.customerFactoryList = Object.assign([], this.sessionHelperService.customerFactoryKeyIds);

    searchArgs.extraWebArticlesArgs.generalProductsMode = this.sessionHelperService.serverOptions.displayGeneralProductsMode;
    searchArgs.extraWebArticlesArgs.customerProductsMode = this.sessionHelperService.serverOptions.displayCustomerProductsMode;

    this.setUserSearchFilters(searchArgs);
    searchArgs.extraWebArticlesArgs.userCanSeeAllProducts = this.sessionHelperService.profileData.system_CanSeeAllProductsInWeb;

    if (this.sessionHelperService.currentCustomer) {
      searchArgs.extraWebArticlesArgs.userCustomerKeyId = this.sessionHelperService.currentCustomer.keyId;
      searchArgs.extraWebArticlesArgs.userCustomerCustomerSiteKeyId =
        this.sessionHelperService.currentCustomer.cuSiteKeyId ? this.sessionHelperService.currentCustomer.cuSiteKeyId : 0;
    }
    else {
      searchArgs.extraWebArticlesArgs.userCustomerKeyId = 0;
      searchArgs.extraWebArticlesArgs.userCustomerCustomerSiteKeyId = 0;
    }

    searchArgs.extraWebArticlesArgs.itemInstrumentType = itemInstrumentType;
    searchArgs.extraWebArticlesArgs.productKeyId = productKeyId;

    searchArgs.extraWebArticlesArgs.reducedSearch = reducedSearch;
    return searchArgs;
  }

  setUserSearchFilters(searchArgs: SearchArgsModel) {
    searchArgs.extraWebArticlesArgs.orderableFromCurrentFactory = this.sessionHelperService.getUserOptions().search_OrderableFromCurrentFactory;
    searchArgs.extraWebArticlesArgs.productsFilterMethodIsGeneral = this.sessionHelperService.getUserOptions().display_ProdFiltMethodGeneral;
    searchArgs.extraWebArticlesArgs.productsFilterMethodIsCustomer = this.sessionHelperService.getUserOptions().display_ProdFiltMethodCustomer;
    searchArgs.extraWebArticlesArgs.productsFilterMethodIsCustomerSite = this.sessionHelperService.getUserOptions().display_ProdFiltMethodCustSite;
  }

  initIncubatorLogSearchRequestArgs(
    mainTableModel: string,
    gridStructure: GridStructureViewInterface,
    toDate: Date,
    fromDate: Date,
    machineKeyId: number
  ): SearchArgsModel {
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.IncubatorLog, mainTableModel, gridStructure);
    searchArgs.extraIncubatorLogArgs.toDate = toDate;
    searchArgs.extraIncubatorLogArgs.fromDate = fromDate;
    searchArgs.extraIncubatorLogArgs.machineKeyId = machineKeyId;
    return searchArgs;
  }

  initPrefListsSearchRequestArgs(
    mainTableModel: string,
    gridStructure: GridStructureViewInterface,
    specialtyIds: number[],
    preferenceListIds: number[]
  ): SearchArgsModel {
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.PrefLists, mainTableModel, gridStructure);
    searchArgs.extraPrefListsArgs.specialtyIds = Object.assign([], specialtyIds);
    searchArgs.extraPrefListsArgs.preferenceListIds = Object.assign([], preferenceListIds);
    return searchArgs;
  }

  initWebScheduledOperationSearchRequestArgs(
    gridStructure: GridStructureViewInterface,
    fromDate: Date,
    toDate: Date,
    searchType: OperationSearchType,
    showAllResult: boolean = this.defaultShowAllResult
  ): SearchArgsModel {
    const nameOfOperation = nameOf<OperationDataModel>();
    const operationFilter = this.operationFilterService.getScheduledOperationListFilter(fromDate, toDate, searchType, this.sessionHelperService.currentCustomer.keyId);
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.WebScheduledOperation, TD_MODEL_NAMES.operationDataModel, gridStructure, operationFilter, showAllResult);
    searchArgs.extraWebScheduledOperationArgs.userCanChangeDefaults = this.sessionHelperService.profileData.system_CanChangeDefaults;
    // set default order by
    searchArgs.orderByFields = [new OrderByArgsModel(nameOfOperation('startTime'), FuzzySearchSortOrder.Desc)];
    return searchArgs;
  }

  initWebOperationUnitsSearchRequestArgs(
    gridStructure: GridStructureViewInterface,
    operationKeyId: number,
    showAllResult: boolean = this.defaultShowAllResult
  ): SearchArgsModel {
    const nameOfUnit = nameOf<UnitModel>();
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.WebOperationUnits, TD_MODEL_NAMES.unitModel, gridStructure, [], showAllResult);
    searchArgs.extraWebOperationUnitsArgs.operationKeyId = operationKeyId;
    searchArgs.extraWebOperationUnitsArgs.factoryKeyIds = this.sessionHelperService.customerFactoryKeyIds;
    // set default order by
    searchArgs.orderByFields = [new OrderByArgsModel(nameOfUnit('keyId'), FuzzySearchSortOrder.Desc)];
    return searchArgs;
  }

  initWebOperationProductsSearchRequestArgs(
    gridStructure: GridStructureViewInterface,
    operationKeyId: number,
    itemType: ItemInstrumentType = ItemInstrumentType.Instrument
  ): SearchArgsModel {
    const nameOfProduct = nameOf<ProductModel>();
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.WebOperationProducts, TD_MODEL_NAMES.productModel, gridStructure, [], true, SearchAutoPictureMode.On);
    searchArgs.extraWebOperationProductsArgs.operationKeyId = operationKeyId;
    searchArgs.extraWebOperationProductsArgs.itemType = itemType;
    // set default order by
    searchArgs.orderByFields = [new OrderByArgsModel(nameOfProduct('keyId'), FuzzySearchSortOrder.Desc)];
    return searchArgs;
  }

  initWebOperationItemsSearchRequestArgs(
    gridStructure: GridStructureViewInterface,
    operationKeyId: number
  ): SearchArgsModel {
    const nameOfItem = nameOf<ItemModel>();
    const searchArgs = this.initSearchRequestArgs(SpecialSearchType.WebOperationItems, TD_MODEL_NAMES.itemModel, gridStructure, [], true, SearchAutoPictureMode.On);
    searchArgs.extraWebOperationItemsArgs.operationKeyId = operationKeyId;
    // set default order by
    searchArgs.orderByFields = [new OrderByArgsModel(nameOfItem('keyId'), FuzzySearchSortOrder.Desc)];
    return searchArgs;
  }

  setGridFilterArgsList(
    searchArgs: SearchArgsModel,
    fieldName: string,
    fieldValue: any,
    clear = false,
    gridFilterTextMatchType: FuzzySearchTextMatchType = FuzzySearchTextMatchType.Contains
  ) {
    if (clear) {
      searchArgs.gridFilterArgsList.splice(0);
    }
    if (fieldName) {
      searchArgs.gridFilterArgsList.push(new GridFilterSearchArgsModel(fieldName, fieldValue));
    }
    searchArgs.gridFilterTextMatchType = gridFilterTextMatchType;
  }

  setFuzzySearchArgs(
    searchArgs: SearchArgsModel,
    xmlCondition: string,
    currentFilterText: string,
    resultFields: string[]
  ) {
    searchArgs.fuzzySearchArgs.xmlCondition = xmlCondition;
    searchArgs.fuzzySearchArgs.currentFilterText = currentFilterText;
    searchArgs.fuzzySearchArgs.resultFields = Object.assign([], resultFields);
  }

  setExtraItemSerialArgs(
    searchArgs: SearchArgsModel,
    includeItemSerialUsage: IncludeItemSerialUsage,
    allowUsedSerials: UseOfUsedSerials,
    allowUnusedSerials: UseOfUnusedSerials,
    countedUnit: number,
    countedProductSerialKeyId: number
  ) {
    searchArgs.extraItemSerialArgs.includeItemSerialUsage = includeItemSerialUsage;
    searchArgs.extraItemSerialArgs.allowUsedSerials = allowUsedSerials;
    searchArgs.extraItemSerialArgs.allowUnusedSerials = allowUnusedSerials;
    searchArgs.extraItemSerialArgs.countedUnit = countedUnit;
    searchArgs.extraItemSerialArgs.countedProductSerialKeyId = countedProductSerialKeyId;
  }

  requestSearchData(searchArgs: SearchArgsModel, searchText: string, onDataLoaded?: (result) => void, keyField?: any): DataSource {
    searchArgs.searchText = searchText;
    return this.gridDataHelperService.requestGridData(searchArgs, this.searchService.search.bind(this.searchService), null, onDataLoaded, keyField);
  }

  requestSearch(searchArgs: SearchArgsModel, searchText: string): Observable<ApiDataResponse<SearchResultViewModel>> {
    searchArgs.searchText = searchText;
    return this.searchService.search(searchArgs);
  }

  getAllRows(): PaginationArgsModel {
    return {
      pageRowCount: TD_MAX_INT32_VALUE - 1,
      startingRow: 0,
      totalCountOfRows: 0
    };
  }
  setRecentOrderTemplatesSearch(recentSearch: RecentSearchInterface): void {
    this.recentOrderTemplatesSearch = recentSearch;
  }
  setRecentOperationSearch(recentSearch: RecentSearchInterface): void {
    this.recentOperationSearch = recentSearch;
  }
  setRecentOrderSearch(recentSearch: RecentSearchInterface): void {
    this.recentOrderSearch = recentSearch;
  }
  setRecentOrderSearchTab(index: number): void {
    this.recentOrderSearch.tabIndex = index;
  }
  setRecentArticleSearchTab(index: number): void {
    this.recentEntitySearch.tabIndex = index;
  }
  getRecentArticleSearchTab(): number {
    return this.recentEntitySearch.tabIndex ? this.recentEntitySearch.tabIndex : 0;
  }
}
