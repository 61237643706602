"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./add-unit.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@ngx-translate/core");
var i4 = require("../../../../shared/components/td-drop-down-grid/td-drop-down-grid.component.ngfactory");
var i5 = require("../../../../shared/components/td-drop-down-grid/td-drop-down-grid.component");
var i6 = require("../../../../core/search/services/search-manager.service");
var i7 = require("../../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory");
var i8 = require("devextreme-angular/core");
var i9 = require("devextreme-angular/ui/button");
var i10 = require("@angular/platform-browser");
var i11 = require("./add-unit.component");
var i12 = require("../../../../shared/components/td-popup/services/td-popup.service");
var i13 = require("../../../../core/data-layer/shared/services/view-helper.service");
var styles_AddUnitComponent = [i0.styles];
var RenderType_AddUnitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddUnitComponent, data: {} });
exports.RenderType_AddUnitComponent = RenderType_AddUnitComponent;
function View_AddUnitComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { unitsSearchDropDownGrid: 0 }), i1.ɵqud(671088640, 2, { addUnitButton: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "form-group"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "disabled": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "pb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 9, "div", [["id", "unit-search-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "td-drop-down-grid", [["id", "unitsSearchDropDownGrid"]], null, [[null, "searchResultCleared"], [null, "articleSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchResultCleared" === en)) {
        var pd_0 = (_co.onSearchResultCleared() !== false);
        ad = (pd_0 && ad);
    } if (("articleSelected" === en)) {
        var pd_1 = (_co.catchUnitSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_TdDropDownGridComponent_0, i4.RenderType_TdDropDownGridComponent)), i1.ɵdid(12, 573440, [[1, 4], ["unitsSearchDropDownGrid", 4]], 0, i5.TdDropDownGridComponent, ["TABLE_INFO_SERVICE", i6.SearchManagerService], { mainTable: [0, "mainTable"], gridIdentifier: [1, "gridIdentifier"], placeHolder: [2, "placeHolder"], disabled: [3, "disabled"], gridWidth: [4, "gridWidth"], displayExpr: [5, "displayExpr"], initSearchRequestArgs: [6, "initSearchRequestArgs"] }, { searchResultCleared: "searchResultCleared", articleSelected: "articleSelected" }), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 5, "dx-button", [["class", "td-button tertiary-button"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.addUnit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DxButtonComponent_0, i7.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i8.DxTemplateHost, i8.DxTemplateHost, []), i1.ɵprd(512, null, i8.WatcherHelper, i8.WatcherHelper, []), i1.ɵprd(512, null, i8.NestedOptionHost, i8.NestedOptionHost, []), i1.ɵdid(18, 7323648, [[2, 4], ["addUnitButton", 4]], 0, i9.DxButtonComponent, [i1.ElementRef, i1.NgZone, i8.DxTemplateHost, i8.WatcherHelper, i8.NestedOptionHost, i10.TransferState, i1.PLATFORM_ID], { disabled: [0, "disabled"], text: [1, "text"] }, { onClick: "onClick" }), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "form-group"; var currVal_1 = _ck(_v, 5, 0, _co.disabled); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = _co.mainTable; var currVal_4 = _co.gridIdentifier; var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 12, 2, i1.ɵnov(_v, 13).transform("search.enterSearchText")), ""); var currVal_6 = _co.disabled; var currVal_7 = 730; var currVal_8 = _co.unitDisplayExpr; var currVal_9 = _co.initUnitSearchRequestArgs; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.disabled || !_co.isUnitSelected()); var currVal_11 = i1.ɵunv(_v, 18, 1, i1.ɵnov(_v, 19).transform("operationDetails.addUnit")); _ck(_v, 18, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("operationDetails.addUnit")); _ck(_v, 8, 0, currVal_2); }); }
exports.View_AddUnitComponent_0 = View_AddUnitComponent_0;
function View_AddUnitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-add-unit", [], null, null, null, View_AddUnitComponent_0, RenderType_AddUnitComponent)), i1.ɵdid(1, 114688, null, 0, i11.AddUnitComponent, ["OPERATION_DATA_SERVICE", i6.SearchManagerService, i12.TdPopupService, i13.ViewHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AddUnitComponent_Host_0 = View_AddUnitComponent_Host_0;
var AddUnitComponentNgFactory = i1.ɵccf("td-add-unit", i11.AddUnitComponent, View_AddUnitComponent_Host_0, { operationKeyId: "operationKeyId", disabled: "disabled" }, { unitAdded: "unitAdded" }, []);
exports.AddUnitComponentNgFactory = AddUnitComponentNgFactory;
