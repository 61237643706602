"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var item_helper_service_1 = require("./item-helper.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var search_helper_service_1 = require("@core/search/services/search-helper.service");
var external_documents_helper_service_1 = require("@core/data-layer/external-documents/services/external-documents-helper.service");
var HttpItemService = /** @class */ (function () {
    function HttpItemService(http, itemHelperService, searchHelperService, extDocHelperService) {
        this.http = http;
        this.itemHelperService = itemHelperService;
        this.searchHelperService = searchHelperService;
        this.extDocHelperService = extDocHelperService;
    }
    HttpItemService.prototype.itemsUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.itemsCtrl;
    };
    HttpItemService.prototype.getItem = function (itemKeyId) {
        var _this = this;
        return this.http.get(this.itemsUrl() + "(" + itemKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.details).pipe(operators_1.map(function (response) { return (_this.itemHelperService.convertItemToViewModel(response)); }));
    };
    HttpItemService.prototype.locateItem = function (requestArgs, itemKeyId) {
        var _this = this;
        this.searchHelperService.applyColumnsConfigurations(requestArgs);
        return this.http.post(this.itemsUrl() + "(" + itemKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.locate + "?maxLevelOfNestedTables=" + td_constants_1.TD_MAX_LEVEL_OF_NESTED_TABLES, requestArgs).pipe(operators_1.map(function (response) { return (_this.searchHelperService.convertExtendedSearchResultToViewModel(requestArgs, response)); }));
    };
    HttpItemService.prototype.getExternalDocumentList = function (requestArgs, itemKeyId) {
        var _this = this;
        return this.http.post(this.itemsUrl() + "(" + itemKeyId + ")" + api_hub_constants_1.API_HUB_PATHS.externalDocumentList, requestArgs).pipe(operators_1.map(function (response) {
            _this.extDocHelperService.convertExternalDocumentsToViewModel(response);
            return response;
        }));
    };
    return HttpItemService;
}());
exports.HttpItemService = HttpItemService;
