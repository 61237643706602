"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Services
var language_service_1 = require("@core/language/services/language.service");
var OperationsModule = /** @class */ (function () {
    function OperationsModule(languageService) {
        languageService.loadLanguageFiles(['operations']);
    }
    return OperationsModule;
}());
exports.OperationsModule = OperationsModule;
