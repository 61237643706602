"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserDesktopOptionsModel = /** @class */ (function () {
    function UserDesktopOptionsModel() {
        this.display_ProdFiltMethodGeneral = false;
        this.display_ProdFiltMethodCustomer = false;
        this.display_ProdFiltMethodCustSite = false;
        this.mediaFrm_Width = 700;
        this.mediaFrm_Height = 560;
        this.identDlg_Width = 600;
        this.identDlg_Height = 485;
        this.alterDlg_Width = 600;
        this.alterDlg_Height = 490;
        this.search_RowsPerSearchPage = 20;
        this.search_OrderableFromCurrentFactory = false;
        this.mediaAutoPlayVideos = false;
    }
    return UserDesktopOptionsModel;
}());
exports.UserDesktopOptionsModel = UserDesktopOptionsModel;
