import { Component, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';

import { TdPopupService } from './services/td-popup.service';
import { PopupType, TdPopupModel } from './models/td-popup.model';
import { TdPopupButtonType } from './models/td-popup-button.interface';

@Component({
  selector: 'td-popup',
  templateUrl: './td-popup.component.html',
  styleUrls: ['./td-popup.component.less']
})

export class TdPopupComponent {

  PopupType = PopupType;
  PopupButtonType = TdPopupButtonType;
  displayPopup = false;
  @ViewChild(DxPopupComponent, {static: false}) popup: DxPopupComponent;
  closeOnOutsideClick: boolean = false;
  popupIconPath: string;

  constructor(
    public popupService: TdPopupService) { 
      this.popupIconPath = this.determinePopupIcon(this.popupService.popupParams);
    this.popupService.displayPopup.subscribe(change => {
      this.displayPopup = change;
    });
  }

  popupContentReady() {
    setTimeout(() => {
      this.closeOnOutsideClick = true;
    }, 0);
    const searchGridDropdown = document.getElementById('strictArticlesSearchDropDownGrid');
    if (searchGridDropdown) {
      const inputElement = searchGridDropdown.getElementsByClassName('dx-texteditor-input').item(0);
      setTimeout(() => {
        (inputElement as HTMLElement).focus();        
      }, 1000);
    }
  }

  private determinePopupIcon(popupParams: TdPopupModel): string {
    if (popupParams.type === PopupType.CustomWithButtons) {
      return this.getImageUrl(popupParams.iconType);
    }
    else {
      return this.getImageUrl(popupParams.type);
    }
  }
  
  private getImageUrl(popupType: PopupType): string {
    switch (popupType) {
      case PopupType.Information:
        return './assets/img/Information.svg';
      case PopupType.Confirmation:
        return './assets/img/Warning Orange.svg';
      case PopupType.Error:
        return './assets/img/Error.svg';
    }
  }
}
