import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { LoadClientConfiguration } from '@core/data-layer/server-session/store/server-session.actions';
import { selectClientConfiguration } from '@core/data-layer/server-session/store/server-session.selectors';
import { ClientConfigurationModel } from '@core/client-configuration/models/client-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigurationInitializer {

  constructor(
    private store: Store<AppState>
  ) { }

  initializeClientConfiguration(): Promise<ClientConfigurationModel> {
    return new Promise(resolve => {
      this.store.dispatch(new LoadClientConfiguration());
      this.store.pipe(select(selectClientConfiguration)).subscribe(configuration => {
        if (configuration) {
          resolve(configuration);
        }
      });
    });
  }

}
