import { Injectable } from '@angular/core';
import { ExternalDocumentFileType, ICONTYPE } from '../models/external-document.enumerations';
import { ExternalDocumentInfoListViewModel } from '../models/external-document-list-view.model';

@Injectable({providedIn: 'root'})
export class ExternalDocumentsHelperService {
  constructor() { }

  getExtDocIconType(fileExtension: string): string {
    const value = ExternalDocumentFileType[fileExtension.toUpperCase()];
    if (value >= 1 && value <= 20) {
      return ICONTYPE.IMG;
    }
    else if (value >= 21 && value <= 40) {
      return ICONTYPE.WORD;
    }
    else if (value >= 41 && value <= 60) {
      return ICONTYPE.TEXT;
    }
    else if (value >= 61 && value <= 80) {
      return ICONTYPE.EXCEL;
    }
    else if (value >= 81 && value <= 100) {
      return ICONTYPE.POWERPOINT;
    }
    else if (value >= 101 && value <= 120) {
      return ICONTYPE.VIDEO;
    }
    else if (value >= 141 && value <= 160) {
      return ICONTYPE.ARCHIVE;
    }
    else if (value >= 161 && value <= 180) {
      return ICONTYPE.AUDIO;
    }
    else if (value === ExternalDocumentFileType.URL) {
      return ICONTYPE.URL;
    }
    else if (value === ExternalDocumentFileType.PDF) {
      return ICONTYPE.PDF;
    }
    else {
      return ICONTYPE.OTHER;
    }
  }

  convertExternalDocumentsToViewModel(infoList: ExternalDocumentInfoListViewModel): void {
    infoList.dataSet.forEach(entry => {
      let entryFileExtension = 'url';
      if (entry.storeKeyId) {
        const entryPathSplitByPeriod = entry.path.split('.'); 
        const arrayLength = entryPathSplitByPeriod.length;
        entryFileExtension = entryPathSplitByPeriod[arrayLength - 1].toLowerCase();
      }
      entry.iconType = this.getExtDocIconType(entryFileExtension);
    });
  }
  
}
