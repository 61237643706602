import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { SessionHelperService } from '@shared/services/session-helper.service';
import { AppInjector } from '@app/app-injector.service';
import { UtilityService } from '@core/utility/utility.service';
import { FactoryServiceInterface } from '@core/data-layer/factory/services/factory.service.interface';
import { GridColumnHelperService } from '@shared/services/grid-column-helper.service';
import { TableInfoServiceInterface } from '@core/table-info/services/table-info.service.interface';
import { MEDIA_WRAPPER_WIDTH } from '@shared/models/app.constants';
import { TdPopupService } from '../td-popup/services/td-popup.service';
import { TdLoadPanelService } from '../td-load-panel/services/td-load-panel.service';
import { TableTypes } from '@core/data-layer/shared/models/td.enumerations';
import { MediaServiceInterface } from '@core/media/services/media.service.interface';
import { GridDataHelperService } from '@shared/services/grid-data-helper.service';

@Component({
  template: ''
})
export class BaseComponent implements OnInit {

  private datePipe: DatePipe;
  protected router: Router;

  private dataModified: boolean;


  private translateService: TranslateService;
  public tableTypes = TableTypes;
  public sessionHelperService: SessionHelperService;
  protected gridColumnHelperService: GridColumnHelperService;
  protected gridDataHelperService: GridDataHelperService;
  protected utilityService: UtilityService;
  protected popupService: TdPopupService;
  protected loadPanelService: TdLoadPanelService;
  protected mediaService: MediaServiceInterface;
  protected tableInfoService: TableInfoServiceInterface;
  protected factoryService: FactoryServiceInterface;
  public mediaWrapperWidth: number;
  public mediaWrapperWidthConst = MEDIA_WRAPPER_WIDTH;
  public titleService: Title;  

  constructor() {
    // Manually retrieve the dependencies from the injector
    // so that constructor has no dependencies that must be passed in from child
    const injector = AppInjector.getInjector();

    this.datePipe = injector.get(DatePipe);
    this.router = injector.get(Router);

    this.translateService = injector.get(TranslateService);
    this.sessionHelperService = injector.get(SessionHelperService);
    this.gridColumnHelperService = injector.get(GridColumnHelperService);
    this.gridDataHelperService = injector.get(GridDataHelperService);
    this.utilityService = injector.get(UtilityService);
    this.popupService = injector.get(TdPopupService);
    this.loadPanelService = injector.get(TdLoadPanelService);
    this.mediaService = injector.get('MEDIA_SERVICE');
    this.tableInfoService = injector.get('TABLE_INFO_SERVICE');
    this.factoryService = injector.get('FACTORY_SERVICE');
    this.titleService = injector.get(Title);
  }

  public ngOnInit(): void {
    this.titleService.setTitle(this.translateInstant(this.getTitleIdentifier()));
  }

  protected getTitleIdentifier(): string {
    return '';
  }

  protected transformDate(value: Date, format = 'medium'): string {
    return this.datePipe.transform(value, format);
  }

  public transformDateAsShortDate = (value) => { // set to public because it is used in template
    const formattedDate = this.transformDate(value, 'shortDate');
    return formattedDate;
  }

  public transformDateAsShort = (value) => { // set to public because it is used in template
    const formattedDate = this.transformDate(value, 'short');
    return formattedDate;
  }

  get formatNumbersOnly(): string {
    return '#0;#0';
  }

  protected translateInstant(value: string): string {
    return this.translateService.instant(value);
  }

  protected navigateTo(url: string) {
    this.router.navigate([url]);
  }

  get dataChanged(): boolean {
    return this.dataModified;
  }

  set dataChanged(state: boolean) {
    this.dataModified = state;
    this.onSetDataChanged(state);
  }

  protected onSetDataChanged(state: boolean): void { }

  public valueUpdated(e: any): void {
    if ((e.event !== undefined) && !this.dataChanged) {
      this.dataChanged = true;
    }
  }

  public getPictureKeyId(keyId: number, tableType: TableTypes, mediaSeries?: number, productKeyId?: number, itemKeyId?: number): Observable<number> {
    const $subject = new Subject<number>();
    const mediaSeriesValue = mediaSeries ? mediaSeries : null;
    if (!this.sessionHelperService.canViewMedia()) {
      $subject.next(null);
      return $subject.asObservable();
    }
    this.mediaService.loadMediaInfoList(tableType, mediaSeriesValue, keyId).subscribe(response => {
      if (response.length === 0) {
        if (tableType === TableTypes.Unit) {
          this.mediaService.loadMediaInfoList(this.tableTypes.Product, mediaSeriesValue, productKeyId).subscribe(productResponse => {
            if (productResponse.length === 0) {
              this.mediaService.loadMediaInfoList(this.tableTypes.Item, mediaSeriesValue, itemKeyId).subscribe(itemResponse => {
                if (itemResponse.length === 0) {
                  $subject.next(null);
                } else {
                  $subject.next(itemResponse[0].keyId);
                  $subject.complete();
                }
              });
            } else {
              if (productResponse.length === 0) {
                $subject.next(null);
              } else {
                $subject.next(productResponse[0].keyId);
                $subject.complete();
              }
            }
          });
        }
        else if (tableType === TableTypes.Product) {
          this.mediaService.loadMediaInfoList(this.tableTypes.Item, mediaSeriesValue, itemKeyId).subscribe(itemResponse => {
            if (itemResponse.length === 0) {
              $subject.next(null);
            } else {
              $subject.next(itemResponse[0].keyId);
              $subject.complete();
            }            
          });
        }        
      } else {
        $subject.next(response[0].keyId);
        $subject.complete();
      }
    });
    return $subject.asObservable();
  }

  setMultipleMedia(event: any): void {
    this.mediaWrapperWidth = event ? MEDIA_WRAPPER_WIDTH.Multiple : MEDIA_WRAPPER_WIDTH.Single;
  }
}
