"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var WebOrderState;
(function (WebOrderState) {
    WebOrderState[WebOrderState["New"] = 0] = "New";
    WebOrderState[WebOrderState["ExistingEdit"] = 1] = "ExistingEdit";
    WebOrderState[WebOrderState["Existing"] = 2] = "Existing";
})(WebOrderState = exports.WebOrderState || (exports.WebOrderState = {}));
var OrderLineState;
(function (OrderLineState) {
    OrderLineState[OrderLineState["Current"] = 0] = "Current";
    OrderLineState[OrderLineState["Edited"] = 1] = "Edited";
    OrderLineState[OrderLineState["Deleted"] = 2] = "Deleted";
    OrderLineState[OrderLineState["New"] = 3] = "New";
})(OrderLineState = exports.OrderLineState || (exports.OrderLineState = {}));
var WebOrderModel = /** @class */ (function () {
    function WebOrderModel() {
        this.details = new WebOrderDetailsModel();
        this.lines = new Array();
    }
    return WebOrderModel;
}());
exports.WebOrderModel = WebOrderModel;
var WebOrderDetailsModel = /** @class */ (function () {
    function WebOrderDetailsModel() {
    }
    return WebOrderDetailsModel;
}());
exports.WebOrderDetailsModel = WebOrderDetailsModel;
var WebOrderLineModel = /** @class */ (function () {
    function WebOrderLineModel() {
    }
    return WebOrderLineModel;
}());
exports.WebOrderLineModel = WebOrderLineModel;
