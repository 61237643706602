"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./td-tab-panel-title.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@ngx-translate/core");
var i4 = require("devextreme-angular/core");
var i5 = require("./td-tab-panel-title.component");
var styles_TdTabPanelTitleComponent = [i0.styles];
var RenderType_TdTabPanelTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TdTabPanelTitleComponent, data: {} });
exports.RenderType_TdTabPanelTitleComponent = RenderType_TdTabPanelTitleComponent;
function View_TdTabPanelTitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tab-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tabs.indexOf(_v.context.$implicit) === _co.selectedIndex) ? "active-tab" : "inactive-tab"); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.translateTitle ? i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.context.$implicit.title)) : _v.context.$implicit.title); _ck(_v, 4, 0, currVal_1); }); }
function View_TdTabPanelTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdTabPanelTitleComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i4.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null)], function (_ck, _v) { var currVal_0 = "title"; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_TdTabPanelTitleComponent_0 = View_TdTabPanelTitleComponent_0;
function View_TdTabPanelTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-tab-panel-title", [], null, null, null, View_TdTabPanelTitleComponent_0, RenderType_TdTabPanelTitleComponent)), i1.ɵdid(1, 114688, null, 0, i5.TdTabPanelTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TdTabPanelTitleComponent_Host_0 = View_TdTabPanelTitleComponent_Host_0;
var TdTabPanelTitleComponentNgFactory = i1.ɵccf("td-tab-panel-title", i5.TdTabPanelTitleComponent, View_TdTabPanelTitleComponent_Host_0, { tabs: "tabs", selectedIndex: "selectedIndex", translateTitle: "translateTitle" }, {}, []);
exports.TdTabPanelTitleComponentNgFactory = TdTabPanelTitleComponentNgFactory;
