import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { AppState } from '../data-layer/app.state';
import { selectCurrentCustomer } from '../data-layer/customer/store/customer.selectors';
import { UtilityService } from '../utility/utility.service';
import { AuthenticationServiceInterface } from '../authentication/services/authentication.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  customerSelected: boolean = false;

  constructor(
    private router: Router,
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    private store: Store<AppState>,
    private utilityService: UtilityService
  ) {
    this.store.pipe(select(selectCurrentCustomer)).subscribe(cust => {
      this.customerSelected = !this.utilityService.objIsEmpty(cust);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // we allow to navigate to page only when:
    // 1. user was logged in
    // 2. customer was selected
    if (this.authService.isAuthenticated() && ((route.routeConfig.path === 'auth-customers') || this.customerSelected)) {
      // check if route is restricted by role
      /*if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }*/
      return true;
    }
    else {
      return false;
    }
  }

}
