import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { LoadServerDesktopOptions } from '@core/data-layer/server-session/store/server-session.actions';
import { ServerDesktopOptionsModel } from '@core/data-layer/server-session/models/server-desktop-options.model';
import { selectServerDesktopOptions } from '@core/data-layer/server-session/store/server-session.selectors';


@Injectable({
  providedIn: 'root'
})
export class ServerDesktopOptionsInitializer {

  constructor(
    private store: Store<AppState>
  ) { }

  initializeServerDesktopOptions(): Promise<ServerDesktopOptionsModel> {
    return new Promise(resolve => {
      this.store.dispatch(new LoadServerDesktopOptions());
      this.store.pipe(select(selectServerDesktopOptions)).subscribe(serverDesktopOptions => {
        if (serverDesktopOptions) {
          resolve(serverDesktopOptions);
        }
      });
    });
  }

}
