"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var user_desktop_options_model_1 = require("@core/data-layer/user-session/models/user-desktop-options.model");
var user_session_actions_1 = require("@core/data-layer/user-session/store/user-session.actions");
var user_session_selectors_1 = require("@core/data-layer/user-session/store/user-session.selectors");
var search_filters_helper_service_1 = require("@shared/services/search-filters-helper.service");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var TdSearchFiltersComponent = /** @class */ (function () {
    function TdSearchFiltersComponent(userSessionService, sessionHelperService, store, searchFiltersHelpeService) {
        this.userSessionService = userSessionService;
        this.sessionHelperService = sessionHelperService;
        this.store = store;
        this.searchFiltersHelpeService = searchFiltersHelpeService;
        this.filtersChangedChange = new core_1.EventEmitter();
        this.systemDefaultFilters = new user_desktop_options_model_1.UserDesktopOptionsModel();
    }
    // Get user options depending on defaultFilters property and set visible controls state
    TdSearchFiltersComponent.prototype.ngOnInit = function () {
        this.userOptions = tslib_1.__assign({}, (this.defaultFilters ? this.sessionHelperService.defaultUserOptions : this.sessionHelperService.userOptions));
        var userCanSeeAllProducts = this.sessionHelperService.profileData.system_CanSeeAllProductsInWeb;
        var generalProductsMode = this.sessionHelperService.serverOptions.displayGeneralProductsMode;
        var customerProductsMode = this.sessionHelperService.serverOptions.displayCustomerProductsMode;
        this.noSpecifiedCustomerVisible = userCanSeeAllProducts || generalProductsMode === td_enumerations_1.WebDisplayGeneralProductsMode.Allowed;
        this.ownCustomerVisible = userCanSeeAllProducts || !(generalProductsMode === td_enumerations_1.WebDisplayGeneralProductsMode.Blocked &&
            customerProductsMode === td_enumerations_1.WebDisplayCustomerProductsMode.OwnOnly);
        this.ownCustomerSiteVisible = userCanSeeAllProducts || customerProductsMode !== td_enumerations_1.WebDisplayCustomerProductsMode.OwnOnly;
    };
    TdSearchFiltersComponent.prototype.setDefaultOrderableFromCurrentFactory = function (setDefault) {
        this.userOptions.search_OrderableFromCurrentFactory = setDefault ?
            this.systemDefaultFilters.search_OrderableFromCurrentFactory : !this.systemDefaultFilters.search_OrderableFromCurrentFactory;
    };
    TdSearchFiltersComponent.prototype.setDefaultProdFiltMethodGeneral = function (setDefault) {
        this.userOptions.display_ProdFiltMethodGeneral = setDefault ?
            this.systemDefaultFilters.display_ProdFiltMethodGeneral : !this.systemDefaultFilters.display_ProdFiltMethodGeneral;
    };
    TdSearchFiltersComponent.prototype.setDefaultProdFiltMethodCustomer = function (setDefault) {
        this.userOptions.display_ProdFiltMethodCustomer = setDefault ?
            this.systemDefaultFilters.display_ProdFiltMethodCustomer : !this.systemDefaultFilters.display_ProdFiltMethodCustomer;
    };
    TdSearchFiltersComponent.prototype.setDefaultProdFiltMethodCustSite = function (setDefault) {
        this.userOptions.display_ProdFiltMethodCustSite = setDefault ?
            this.systemDefaultFilters.display_ProdFiltMethodCustSite : !this.systemDefaultFilters.display_ProdFiltMethodCustSite;
    };
    TdSearchFiltersComponent.prototype.setChanged = function () {
        this.filtersChanged = true;
        this.filtersChangedChange.emit(true);
    };
    TdSearchFiltersComponent.prototype.resetToDefault = function () {
        var newOptions = this.defaultFilters ? this.systemDefaultFilters : this.sessionHelperService.defaultUserOptions;
        this.userOptions = tslib_1.__assign({}, this.userOptions, this.searchFiltersHelpeService.getSearchFiltersOptions(newOptions));
        this.setChanged();
    };
    TdSearchFiltersComponent.prototype.clear = function () {
        this.userOptions = tslib_1.__assign({}, this.userOptions, { search_OrderableFromCurrentFactory: false, display_ProdFiltMethodGeneral: false, display_ProdFiltMethodCustomer: false, display_ProdFiltMethodCustSite: false });
        this.setChanged();
    };
    // Save filters for default or user options depending on defaultFilters property
    // Wait until options are saved and loaded to the store before resolving the Promise
    // skip(1) in order to omit the initial value from store and wait for the new one
    TdSearchFiltersComponent.prototype.saveSearchFilters = function () {
        var _this = this;
        var saveOptions = this.defaultFilters ? this.userSessionService.saveDefaultUserOptions.bind(this.userSessionService) :
            this.userSessionService.saveUserOptions.bind(this.userSessionService);
        return new Promise(function (resolve) {
            saveOptions(_this.userOptions).subscribe(function () {
                _this.store.dispatch(new (_this.defaultFilters ? user_session_actions_1.LoadDefaultUserOptions : user_session_actions_1.LoadUserOptions)());
                _this.selectOptionsSubscription = _this.store.pipe(store_1.select(_this.defaultFilters ? user_session_selectors_1.selectDefaultUserOptions : user_session_selectors_1.selectUserOptions), operators_1.skip(1))
                    .subscribe(function (options) {
                    if (options) {
                        resolve();
                    }
                });
            });
        });
    };
    TdSearchFiltersComponent.prototype.ngOnDestroy = function () {
        if (this.selectOptionsSubscription) {
            this.selectOptionsSubscription.unsubscribe();
        }
    };
    return TdSearchFiltersComponent;
}());
exports.TdSearchFiltersComponent = TdSearchFiltersComponent;
