"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InfoOverviewActionTypes;
(function (InfoOverviewActionTypes) {
    InfoOverviewActionTypes["LoadIOConfigurations"] = "[InfoOverview] Load IO configurations";
    InfoOverviewActionTypes["LoadIOConfigurationsSuccess"] = "[InfoOverview] Load IO configurations (success)";
    InfoOverviewActionTypes["LoadIOConfigurationsError"] = "[InfoOverview] Load IO configurations (error)";
    InfoOverviewActionTypes["GetIOConfigurationById"] = "[InfoOverview] Get IO configuration by id";
    InfoOverviewActionTypes["GetIOConfigurationByIdSuccess"] = "[InfoOverview] Get IO configuration by id (success)";
    InfoOverviewActionTypes["SetIOData"] = "[InfoOverview] Set IO data";
    InfoOverviewActionTypes["SetIODataSuccess"] = "[InfoOverview] Set IO data (success)";
    InfoOverviewActionTypes["SetConnectedToHub"] = "[InfoOverview] Set connected to hub";
    InfoOverviewActionTypes["SetConnectedToJSrv"] = "[InfoOverview] Set connected to job server";
})(InfoOverviewActionTypes = exports.InfoOverviewActionTypes || (exports.InfoOverviewActionTypes = {}));
// info-overview configurations
var LoadIOConfigurations = /** @class */ (function () {
    function LoadIOConfigurations() {
        this.type = InfoOverviewActionTypes.LoadIOConfigurations;
    }
    return LoadIOConfigurations;
}());
exports.LoadIOConfigurations = LoadIOConfigurations;
var LoadIOConfigurationsSuccess = /** @class */ (function () {
    function LoadIOConfigurationsSuccess(payload) {
        this.payload = payload;
        this.type = InfoOverviewActionTypes.LoadIOConfigurationsSuccess;
    }
    return LoadIOConfigurationsSuccess;
}());
exports.LoadIOConfigurationsSuccess = LoadIOConfigurationsSuccess;
var LoadIOConfigurationsError = /** @class */ (function () {
    function LoadIOConfigurationsError() {
        this.type = InfoOverviewActionTypes.LoadIOConfigurationsError;
    }
    return LoadIOConfigurationsError;
}());
exports.LoadIOConfigurationsError = LoadIOConfigurationsError;
var GetIOConfigurationById = /** @class */ (function () {
    function GetIOConfigurationById(payload) {
        this.payload = payload;
        this.type = InfoOverviewActionTypes.GetIOConfigurationById;
    }
    return GetIOConfigurationById;
}());
exports.GetIOConfigurationById = GetIOConfigurationById;
var GetIOConfigurationByIdSuccess = /** @class */ (function () {
    function GetIOConfigurationByIdSuccess(payload) {
        this.payload = payload;
        this.type = InfoOverviewActionTypes.GetIOConfigurationByIdSuccess;
    }
    return GetIOConfigurationByIdSuccess;
}());
exports.GetIOConfigurationByIdSuccess = GetIOConfigurationByIdSuccess;
// info-overview data
var SetIOData = /** @class */ (function () {
    function SetIOData(payload) {
        this.payload = payload;
        this.type = InfoOverviewActionTypes.SetIOData;
    }
    return SetIOData;
}());
exports.SetIOData = SetIOData;
var SetIODataSuccess = /** @class */ (function () {
    function SetIODataSuccess(payload) {
        this.payload = payload;
        this.type = InfoOverviewActionTypes.SetIODataSuccess;
    }
    return SetIODataSuccess;
}());
exports.SetIODataSuccess = SetIODataSuccess;
var SetConnectedToHub = /** @class */ (function () {
    function SetConnectedToHub(payload) {
        this.payload = payload;
        this.type = InfoOverviewActionTypes.SetConnectedToHub;
    }
    return SetConnectedToHub;
}());
exports.SetConnectedToHub = SetConnectedToHub;
var SetConnectedToJSrv = /** @class */ (function () {
    function SetConnectedToJSrv(ioDataType, payload) {
        this.ioDataType = ioDataType;
        this.payload = payload;
        this.type = InfoOverviewActionTypes.SetConnectedToJSrv;
    }
    return SetConnectedToJSrv;
}());
exports.SetConnectedToJSrv = SetConnectedToJSrv;
