"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var external_document_enumerations_1 = require("../models/external-document.enumerations");
var i0 = require("@angular/core");
var ExternalDocumentsHelperService = /** @class */ (function () {
    function ExternalDocumentsHelperService() {
    }
    ExternalDocumentsHelperService.prototype.getExtDocIconType = function (fileExtension) {
        var value = external_document_enumerations_1.ExternalDocumentFileType[fileExtension.toUpperCase()];
        if (value >= 1 && value <= 20) {
            return external_document_enumerations_1.ICONTYPE.IMG;
        }
        else if (value >= 21 && value <= 40) {
            return external_document_enumerations_1.ICONTYPE.WORD;
        }
        else if (value >= 41 && value <= 60) {
            return external_document_enumerations_1.ICONTYPE.TEXT;
        }
        else if (value >= 61 && value <= 80) {
            return external_document_enumerations_1.ICONTYPE.EXCEL;
        }
        else if (value >= 81 && value <= 100) {
            return external_document_enumerations_1.ICONTYPE.POWERPOINT;
        }
        else if (value >= 101 && value <= 120) {
            return external_document_enumerations_1.ICONTYPE.VIDEO;
        }
        else if (value >= 141 && value <= 160) {
            return external_document_enumerations_1.ICONTYPE.ARCHIVE;
        }
        else if (value >= 161 && value <= 180) {
            return external_document_enumerations_1.ICONTYPE.AUDIO;
        }
        else if (value === external_document_enumerations_1.ExternalDocumentFileType.URL) {
            return external_document_enumerations_1.ICONTYPE.URL;
        }
        else if (value === external_document_enumerations_1.ExternalDocumentFileType.PDF) {
            return external_document_enumerations_1.ICONTYPE.PDF;
        }
        else {
            return external_document_enumerations_1.ICONTYPE.OTHER;
        }
    };
    ExternalDocumentsHelperService.prototype.convertExternalDocumentsToViewModel = function (infoList) {
        var _this = this;
        infoList.dataSet.forEach(function (entry) {
            var entryFileExtension = 'url';
            if (entry.storeKeyId) {
                var entryPathSplitByPeriod = entry.path.split('.');
                var arrayLength = entryPathSplitByPeriod.length;
                entryFileExtension = entryPathSplitByPeriod[arrayLength - 1].toLowerCase();
            }
            entry.iconType = _this.getExtDocIconType(entryFileExtension);
        });
    };
    ExternalDocumentsHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExternalDocumentsHelperService_Factory() { return new ExternalDocumentsHelperService(); }, token: ExternalDocumentsHelperService, providedIn: "root" });
    return ExternalDocumentsHelperService;
}());
exports.ExternalDocumentsHelperService = ExternalDocumentsHelperService;
