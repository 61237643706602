import { Injectable } from '@angular/core';

import { TdLoadPanelModel } from '../models/td-load-panel.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TdLoadPanelService {

  public loadPanelParams: TdLoadPanelModel = new TdLoadPanelModel();

  constructor(private translateService: TranslateService) { }

  showLoadPanel(message?: string): boolean {
    const loadingPanelVisible = this.loadPanelParams.visible;
    if (!loadingPanelVisible) {
      if (!message) {
        message = 'appMessages.loading';
      }
      message = this.translateService.instant(message).replace('|', '<br/>');
      this.loadPanelParams = new TdLoadPanelModel(true, message);
    }
    return loadingPanelVisible;
  }

  hideLoadPanel(): void {
    if (this.loadPanelParams.visible) {
      this.loadPanelParams = new TdLoadPanelModel();
    }
  }
}
