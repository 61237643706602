"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var web_order_view_model_1 = require("@core/data-layer/order/models/web-order-view.model");
var session_helper_service_1 = require("./session-helper.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var web_order_model_1 = require("@core/data-layer/order/models/web-order.model");
var td_popup_service_1 = require("../components/td-popup/services/td-popup.service");
var td_load_panel_service_1 = require("../components/td-load-panel/services/td-load-panel.service");
var i0 = require("@angular/core");
var i1 = require("./session-helper.service");
var i2 = require("../components/td-popup/services/td-popup.service");
var i3 = require("../components/td-load-panel/services/td-load-panel.service");
var i4 = require("@angular/router");
var OrderManagerService = /** @class */ (function () {
    function OrderManagerService(orderService, sessionHelperService, popupService, loadPanelService, router) {
        this.orderService = orderService;
        this.sessionHelperService = sessionHelperService;
        this.popupService = popupService;
        this.loadPanelService = loadPanelService;
        this.router = router;
    }
    OrderManagerService.prototype.updateLineQuantity = function (quantity, orderLine) {
        var ordered = quantity * orderLine.oLnOrgStdCount;
        if (this.sessionHelperService.quantityIsValid(ordered, td_constants_1.TD_MAX_INT32_VALUE / orderLine.oLnOrgStdCount)) {
            orderLine.oLnOrgUnitCount = quantity;
            orderLine.oLnOrgOrdered = ordered;
            if (orderLine.lineState !== web_order_model_1.OrderLineState.New) {
                orderLine.lineState = web_order_model_1.OrderLineState.Edited;
            }
            return true;
        }
        else {
            return false;
        }
    };
    OrderManagerService.prototype.addLineIntoOrder = function (articleInfo, order, factoryId, onLineAdded) {
        var _this = this;
        if (!this.loadPanelService.showLoadPanel()) {
            var orderLine = order.lines.find(function (entry) { return ((entry.oLnRefType === articleInfo.refType) && (entry.oLnRefKeyId === articleInfo.refKeyId)); });
            // update existing order line
            if (orderLine) {
                var newLine = orderLine.lineState === web_order_model_1.OrderLineState.Deleted;
                if (this.updateLineQuantity(orderLine.oLnOrgUnitCount + articleInfo.quantity, orderLine)) {
                    onLineAdded(newLine ? orderLine : null);
                }
            }
            // insert new order line
            else {
                var ordLineInfoArgs = {
                    refType: articleInfo.refType,
                    refKeyId: articleInfo.refKeyId,
                    quantity: articleInfo.quantity,
                    customerKeyId: this.sessionHelperService.currentCustomer.keyId,
                    customerChargeOptions: this.sessionHelperService.currentCustomer.chargeOptions,
                    supplierFactoryKeyId: factoryId
                };
                this.orderService.getOrderLine(ordLineInfoArgs).subscribe(function (response) {
                    if (response.error === td_constants_1.NO_ERROR_CODE) {
                        var ordLine = response.value;
                        ordLine.lineState = web_order_model_1.OrderLineState.New;
                        // get max order line number
                        var maxLineNo_1 = 0;
                        order.lines.forEach(function (entry) {
                            if (entry.oLnLine > maxLineNo_1) {
                                maxLineNo_1 = entry.oLnLine;
                            }
                        });
                        // set next order line number
                        ordLine.oLnLine = maxLineNo_1 + 1;
                        order.lines.push(ordLine);
                        onLineAdded(ordLine);
                    }
                    else {
                        // there occurred an error while trying to get order line details
                        _this.popupService.showInfo(response.message);
                    }
                });
            }
        }
    };
    OrderManagerService.prototype.initializeNewOrder = function (operationKeyId, custStokKeyId) {
        var order = new web_order_view_model_1.WebOrderViewModel();
        order.state = web_order_model_1.WebOrderState.New;
        order.details.orgRefCustKeyId = this.sessionHelperService.currentCustomer.keyId;
        order.details.suppRefFacKeyId = this.sessionHelperService.customerFactories[0].keyId;
        if (operationKeyId) {
            order.details.refOpDKeyId = operationKeyId;
            order.details.orgStokKeyId = custStokKeyId ? custStokKeyId : 0;
        }
        return order;
    };
    OrderManagerService.prototype.createOrderFromTemplate = function (keyId) {
        var _this = this;
        this.orderService.createOrderFromTemplate(keyId, this.sessionHelperService.currentFactoryKeyId, this.sessionHelperService.currentCustomer.keyId).subscribe(function (response) {
            if (response.error === td_constants_1.NO_ERROR_CODE) {
                // order created successfully
                _this.router.navigate(['/orders/' + response.value]);
            }
            else {
                // there occurred an error while trying to create order from template
                _this.popupService.showInfo(response.message);
            }
        });
    };
    OrderManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderManagerService_Factory() { return new OrderManagerService(i0.ɵɵinject("ORDER_SERVICE"), i0.ɵɵinject(i1.SessionHelperService), i0.ɵɵinject(i2.TdPopupService), i0.ɵɵinject(i3.TdLoadPanelService), i0.ɵɵinject(i4.Router)); }, token: OrderManagerService, providedIn: "root" });
    return OrderManagerService;
}());
exports.OrderManagerService = OrderManagerService;
