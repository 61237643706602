"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var td_enumerations_1 = require("@app/core/data-layer/shared/models/td.enumerations");
var OperationsViewModelService = /** @class */ (function () {
    function OperationsViewModelService() {
    }
    /** BEGIN: Search Operation Orders: Private methods **/
    /**Converts OperationOrderInterface to OPerationOrderViewModelInterface
     * @param order - OperationOrderInterface object
     * @param operationLeadStatus - The OrdersLeadStatus of the operation which the orders belong to
     * @returns OperationOrderViewModelInterface - the converted view model
     */
    OperationsViewModelService.prototype.convertOrderToViewModel = function (order, operationLeadStatus) {
        return tslib_1.__assign({}, order, { operationLeadStatus: operationLeadStatus, orderLeadStatus: this.extractOrderLeadStatus(order.suppStatusEnumValue, operationLeadStatus) });
    };
    /**Extracts the relevant order lead status based on OrderSuppStatus
     * @param suppStatus - The supp status of the order
     * @param operationLeadStatus - The lead status for the operation containing the order
     * @returns OrdersLeadStatus - the Lead status that should be assigned to the order
     */
    OperationsViewModelService.prototype.extractOrderLeadStatus = function (suppStatus, operationLeadStatus) {
        switch (suppStatus) {
            case td_enumerations_1.OrderSuppStatus.Cancelled: return td_enumerations_1.OrdersLeadStatus.Neutral;
            case td_enumerations_1.OrderSuppStatus.Delivered: return td_enumerations_1.OrdersLeadStatus.Done;
            default: return operationLeadStatus;
        }
    };
    return OperationsViewModelService;
}());
exports.OperationsViewModelService = OperationsViewModelService;
