export enum PropertyTypes {
  // Type "boolean".
  typeBoolean = "boolean",
  // Type "number".
  typeNumber = "number",
  // Type "string".
  typeString = "string",
  // Type "Date".
  typeDate = "Date",
  // Type "Object". (Reference to other DBModel)
  typeObject = "Object",
  // Type "undefined". (For example, can be "Media", "Quantity" columns)
  typeUndefined = "undefined",
  // Type "enum". We are using this to convert enum-value into translated text-value.
  typeEnum = "enum"
}

export interface GridColumnBaseInterface {
  // data field name
  dataField: string;
  // data field type
  dataType: PropertyTypes;
  // "translated-field" or "user-field" display name
  displayName?: string;
  // field is calculated
  calculated?: boolean;
  // field is userField
  userField?: boolean;
  // "enum name" for field
  enumName?: string;
}

export interface GridColumnInterface extends GridColumnBaseInterface {
  // reference to sql sub table name
  referenceTo?: string;
}

export interface GridTableInterface {
  // sql table name
  tableName: string;
  // all possible columns for current table
  columns: GridColumnInterface[];
}

export interface GridColumnConfigurationInterface extends GridColumnBaseInterface {
  visible: boolean;
  visibleIndex?: number;
  width?: string;
  // it is required always include current field in sql sequel
  required?: boolean;
}

export interface GridStructureInterface {
  tables: GridTableInterface[];
  columnsConfigurations: GridColumnConfigurationInterface[];
}
