"use strict";
var _this = this;
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var localization_1 = require("devextreme/localization");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var environment_1 = require("./environments/environment");
var __NgCli_bootstrap_1 = require("./app/app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
if (environment_1.environment.production) {
    core_1.enableProdMode();
}
var ɵ0 = new http_1.HttpXhrBackend({ build: function () { return new XMLHttpRequest(); } });
exports.ɵ0 = ɵ0;
// Bootstrap AppModule only after locale is fetched from the backend, registered and provided
// ToDo: In the Angular 8 LOCALE_ID is provided before any APP_INITIALIZER completed and it is not possibe to load LOCALE_ID asynchronosly
// The issue is fixed in Angular 9, so after upgrading to 9 version LOCALE_ID preloading and providing should be moved to the core module
var injector = core_1.Injector.create({
    providers: [
        { provide: http_1.HttpClient, deps: [http_1.HttpHandler] },
        { provide: http_1.HttpHandler, useValue: ɵ0 },
    ]
});
var http = injector.get(http_1.HttpClient);
(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    var localeName, localeFileName, res;
    var _this = this;
    return tslib_1.__generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, new Promise(function (resolve) {
                    http.get(api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.clientConfigurationCtrl + api_hub_constants_1.API_HUB_PATHS.locale, { responseType: 'text' })
                        .subscribe(function (response) {
                        if (response) {
                            resolve(response);
                        }
                        else {
                            // Unlikely to happen, but to be sure we set default
                            console.error('Web server locale was not fetched from the web server.');
                            resolve('en-US');
                        }
                    });
                })];
            case 1:
                localeName = _a.sent();
                localeFileName = localeName;
                return [4 /*yield*/, Promise.resolve().then(function () { return require("@angular/common/locales/" + localeFileName + ".js"); }).catch(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    localeFileName = localeName.substring(0, 2);
                                    return [4 /*yield*/, Promise.resolve().then(function () { return require("@angular/common/locales/" + localeFileName + ".js"); })];
                                case 1: return [2 /*return*/, _a.sent()];
                            }
                        });
                    }); })];
            case 2:
                res = _a.sent();
                common_1.registerLocaleData(res.default, localeFileName);
                // DevExpress
                localization_1.locale(localeFileName);
                __NgCli_bootstrap_2.platformBrowser([{ provide: core_1.LOCALE_ID, useValue: localeName }]).bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
                    .then(function (moduleRef) { })
                    .catch(function (err) { return console.error(err); });
                return [2 /*return*/];
        }
    });
}); })();
