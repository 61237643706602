import { FuzzySearchSortOrder } from './td.enumerations';
import { GridStructureViewInterface } from '@core/table-info/models/grid-structure-view.interface';

export class PaginationArgsModel {
  // Page row count
  pageRowCount: number;
  // Starting row
  startingRow: number;
  // Total count of rows
  totalCountOfRows: number;
}

export class OrderByArgsModel {
  // Field name
  fieldName: string;
  // Sort order
  sortOrder: FuzzySearchSortOrder;

  constructor(fieldName: string, sortOrder: FuzzySearchSortOrder) {
    this.fieldName = fieldName;
    this.sortOrder = sortOrder;
  }
}

export class BaseGridArgsModel {
  // The main table that should be used in grid. All qualified fields must be based on this table.
  mainTable: string;
  // Fields that must be returned! Search engine can add fields and put these in the response. Any KeyId and MasterTxt field is always added if not present.
  selectedFields: string[];
  // The fields that must be "fuzzy" or "normal" searched for "SearchText" text.
  // Fields listed here that are not listed in SelectFields will be "force" added to SelectFields and will also be returned in the dataset.
  searchTextFields: string[];
  // The fields the resulting dataset must be ordered by. If SortOrder is 1 then the field must be searched in decending order. If 0 then normal.
  orderByFields: OrderByArgsModel[];
  // Pagination information.
  paginationArgs: PaginationArgsModel;
  // Fields that must be returned! Search engine can add fields and put these in the response. Any KeyId and MasterTxt field is always added if not present.
  gridStructure?: GridStructureViewInterface;

  constructor(mainTable: string, gridStructure: GridStructureViewInterface) {
    this.mainTable = mainTable;
    this.selectedFields = new Array<string>();
    this.searchTextFields = new Array<string>();
    this.orderByFields = new Array<OrderByArgsModel>();
    this.paginationArgs = new PaginationArgsModel();
    this.gridStructure = gridStructure;
  }
}
