import { Injectable, Inject } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { FactoryActionTypes, LoadAllFactories, LoadCustomerFactories, LoadUserFactories } from './factory.actions';
import { FactoryServiceInterface } from '../services/factory.service.interface';

@Injectable()
export class FactoryEffects {

  constructor(
    private actions$: Actions,
    @Inject('FACTORY_SERVICE') private factoryService: FactoryServiceInterface) {
  }

  @Effect()
  loadFactories$ = this.actions$
    .pipe(
      ofType(FactoryActionTypes.LoadAllFactories),
      mergeMap((act: LoadAllFactories) => {
        return this.factoryService.getFactories()
          .pipe(
            map(response => ({ type: FactoryActionTypes.SetAllFactories, payload: response })),
            catchError(() => of({ type: FactoryActionTypes.SetAllFactories }))
          );
      })
    );

  @Effect()
  loadFactoriesByCustomer$ = this.actions$
    .pipe(
      ofType(FactoryActionTypes.LoadCustomerFactories),
      mergeMap((act: LoadCustomerFactories) => {
        return this.factoryService.getFactoriesByCustomer(act.payload)
          .pipe(
            map(response => {
              if ((response) && (response.length > 0)) {
                return { type: FactoryActionTypes.SetCustomerFactories, payload: response };
              }
              else {
                return { type: FactoryActionTypes.SetCustomerFactoriesFromAll };
              }
            }),
            catchError(() => of({ type: FactoryActionTypes.SetCustomerFactoriesFromAll }))
          );
      })
    );

  @Effect()
  loadUserFactories$ = this.actions$
    .pipe(
      ofType(FactoryActionTypes.LoadUserFactories),
      mergeMap((act: LoadUserFactories) => {
        return this.factoryService.getUserFactories()
          .pipe(
            map(response => ({ type: FactoryActionTypes.SetUserFactories, payload: response })),
            catchError(() => of({ type: FactoryActionTypes.SetUserFactories }))
          );
      })
    );
}
