"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
// Services
var search_manager_service_1 = require("@core/search/services/search-manager.service");
var order_filter_service_1 = require("@core/search/filters/order-filter.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var recent_search_interface_1 = require("@core/search/models/recent-search.interface");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var operations_viewmodel_helper_service_1 = require("./operations-viewmodel-helper.service");
var operators_1 = require("rxjs/operators");
var OperationsSearchService = /** @class */ (function () {
    function OperationsSearchService(searchManagerService, orderFilterService, operationsViewModelService) {
        this.searchManagerService = searchManagerService;
        this.orderFilterService = orderFilterService;
        this.operationsViewModelService = operationsViewModelService;
    }
    /** BEGIN: Search Operations: Public methods **/
    /**
     * Performs search for operations
     * @param gridStructure - the grid structure used in the table that holds the results
     * @param searchArgFromDate the - from date used in search
     * @param searchArgToDate - the to date used in search
     * @param searchArgText - the text used in search
     * @param searchType - the search type enum (either date or text)
     * @returns DataSource - the data source object used in the table that display the info
     */
    OperationsSearchService.prototype.createSearchOperationsDataSource = function (gridStructure, searchArgFromDate, searchArgToDate, searchArgText, searchType) {
        if (searchArgText === void 0) { searchArgText = ''; }
        var nextSearchType = (searchType != null) ? searchType : this.lastSearchType;
        this.lastSearchType = nextSearchType;
        this.searchManagerService.setRecentOperationSearch(this.generateRecentSearch(searchArgFromDate, searchArgToDate, searchArgText, nextSearchType));
        var operationsSearchArgs = this.searchManagerService.initWebScheduledOperationSearchRequestArgs(gridStructure, searchArgFromDate, searchArgToDate, nextSearchType, true);
        return this.searchManagerService.requestSearchData(operationsSearchArgs, searchArgText, null, td_constants_1.TD_DYNAMIC_FIELDS.keyId);
    };
    /** END: Search Operations: Public methods **/
    /** BEGIN: Search Operation Orders: Public methods **/
    /**Get's the orders associated with an operation
     * @param keyId - keyId of the operation
     * @param gridStructure - grid structure of the table that will hold the data
     * @param operationsData - The datasource of the table that holds the data
     * @returns Subject of OperationOrderViewModelInterface[]
     */
    OperationsSearchService.prototype.getOperationOrders = function (keyId, gridStructure, operationsData) {
        var _this = this;
        var operationOrdersSearchArgs = this.searchManagerService.initSearchRequestArgs(td_enumerations_1.SpecialSearchType.None, td_constants_1.TD_MODEL_NAMES.orderModel, gridStructure, this.orderFilterService.getOperationOrderListFilter(keyId), true);
        var items = operationsData.items();
        var ordersLeadStatus = items.find(function (entry) { return entry.keyId === keyId; }).OrdersLeadStatus;
        var result = new rxjs_1.BehaviorSubject(null);
        this.searchManagerService.requestSearch(operationOrdersSearchArgs, '').pipe(operators_1.take(1)).subscribe(function (response) {
            var dataValues = response.value.dataSet.mappedRows.map(function (entry) {
                return _this.operationsViewModelService.convertOrderToViewModel(entry, ordersLeadStatus);
            });
            result.next(dataValues);
        });
        return result;
    };
    /** END: Search Operation Orders: Public methods **/
    /** BEGIN: Search Operations: Private methods **/
    /**Generates recent search filter for operations
     * @param searchArgFromDate the - from date used in search
     * @param searchArgToDate - the to date used in search
     * @param searchArgText - the text used in search
     * @param searchType - the search type enum (either date or text)
     * @returns RecentSearchInterface
     */
    OperationsSearchService.prototype.generateRecentSearch = function (searchArgFromDate, searchArgToDate, searchArgText, searchType) {
        if (searchArgText === void 0) { searchArgText = ''; }
        var type = searchType === td_enumerations_1.OperationSearchType.Date ? recent_search_interface_1.RecentSearchType.Date : recent_search_interface_1.RecentSearchType.Text;
        return {
            type: type,
            operationFilters: this.getOperationFilters(searchArgFromDate, searchArgToDate, searchArgText)
        };
    };
    /**Generates filters used for searching operation
     * @param searchArgFromDate the - from date used in search
     * @param searchArgToDate - the to date used in search
     * @param searchArgText - the text used in search
     * @returns OperationFilterInterface
     */
    OperationsSearchService.prototype.getOperationFilters = function (searchArgFromDate, searchArgToDate, searchArgText) {
        if (searchArgText === void 0) { searchArgText = ''; }
        var filters = {
            dateInterval: {
                from: searchArgFromDate,
                to: searchArgToDate
            },
            text: searchArgText
        };
        return filters;
    };
    return OperationsSearchService;
}());
exports.OperationsSearchService = OperationsSearchService;
