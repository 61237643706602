"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TdTabPanelTitleComponent = /** @class */ (function () {
    function TdTabPanelTitleComponent() {
        this.translateTitle = false;
    }
    TdTabPanelTitleComponent.prototype.ngOnInit = function () {
    };
    return TdTabPanelTitleComponent;
}());
exports.TdTabPanelTitleComponent = TdTabPanelTitleComponent;
