"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_1 = require("devextreme-angular/ui/list");
var popup_1 = require("devextreme-angular/ui/popup");
var data_grid_1 = require("devextreme-angular/ui/data-grid");
var tree_list_1 = require("devextreme-angular/ui/tree-list");
var core_2 = require("@ngx-translate/core");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var grid_structure_interface_1 = require("@core/table-info/models/grid-structure.interface");
var platform_browser_1 = require("@angular/platform-browser");
var base_grid_args_model_1 = require("@core/data-layer/shared/models/base-grid-args.model");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var utility_service_1 = require("@core/utility/utility.service");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var app_constants_1 = require("@shared/models/app.constants");
var TdDataGridComponent = /** @class */ (function () {
    /*
      Storing grid settings externally is not possible yet. Still awaiting response from DevExpress Support team on how this is intended to work in new version of Angular.
      For now, we store grid settings only in localStorage. Outcommented lines are related to external storing and should not be deleted
    */
    function TdDataGridComponent(sanitizer, tableInfoService, sessionHelperService, utilityService, translateService) {
        this.sanitizer = sanitizer;
        this.tableInfoService = tableInfoService;
        this.sessionHelperService = sessionHelperService;
        this.utilityService = utilityService;
        this.translateService = translateService;
        this.scrolling = { mode: 'standard' }; // mode: 'standard' | 'virtual' | 'infinite'
        this.selection = { mode: 'none' }; // mode: 'single' | 'multiple' | 'none'
        this.enableStateStoring = true;
        this.hoverStateEnabled = false;
        this.wordWrapEnabled = false;
        this.showBorders = false;
        this.disabled = false;
        this.noDataTranslationTerm = 'appCommon.noData';
        // Inputs related to keyboard navigation
        this.focusRowEnabled = false;
        this.refreshGridData = new core_1.EventEmitter();
        this.selectedRowChange = new core_1.EventEmitter();
        this.rowPrepared = new core_1.EventEmitter();
        this.contentReady = new core_1.EventEmitter();
        this.rowExpand = new core_1.EventEmitter();
        this.enterPressedOnFocusedRow = new core_1.EventEmitter();
        this.escapeKeyUpWhileGridFocused = new core_1.EventEmitter();
        this.displaySettings = false;
        this.propertyTypes = grid_structure_interface_1.PropertyTypes;
        this.GRID_TEMPLATES = app_constants_1.GRID_TEMPLATES;
        // loadGridStateFunc;
        // saveGridStateFunc;
        this.loadGridStateFunc = this.loadGridState.bind(this);
        this.saveGridStateFunc = this.saveGridState.bind(this);
        this.cellTemplateHighlights = this.cellTemplateHighlights.bind(this);
    }
    TdDataGridComponent.prototype.ngOnInit = function () {
        this.determinePageSize();
        this.hideColumnHeaders = this.hideColumnHeaders ? this.hideColumnHeaders : false;
    };
    TdDataGridComponent.prototype.ngAfterViewInit = function () { };
    TdDataGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes['reload'] && this.grid) {
            this.grid.instance.repaint();
        }
        if (changes['gridStructure'] && changes['gridStructure'].currentValue) {
            this.checkForCustomTemplate();
        }
        if (changes['data']) {
            if (this.grid && this.grid.instance) {
                this.grid.instance.collapseAll(-1);
            }
        }
        if (changes['focusRowEnabled'] && this.focusRowEnabled) {
            this.focusedRowKeyForDataGrid = this.focusedRowKey;
            if (this.grid && this.grid.instance && this.grid.instance.isRowFocused(this.focusedRowKey)) {
                this.grid.instance.refresh();
            }
            if (this.grid && this.grid.instance) {
                this.grid.instance.focus();
            }
        }
        if (changes['disabled'] && this.grid && this.grid.instance) {
            var element = this.grid.instance.element();
            if (this.disabled) {
                element.classList.add('disabled-overlay');
            }
            else {
                element.classList.remove('disabled-overlay');
            }
        }
    };
    TdDataGridComponent.prototype.gridContentReady = function (e) {
        if (this.columnSelectorContainer) {
            var pagerElement = e.element.getElementsByClassName('dx-pages');
            var size = this.columnSelectorContainer.nativeElement.offsetWidth + 11;
            // there should be only one 'dx-pages' per each grid
            if (pagerElement && pagerElement.length === 1) {
                pagerElement[0].style.marginRight = size + 'px';
            }
        }
        if (this.contentReady) {
            this.contentReady.emit(e);
        }
    };
    TdDataGridComponent.prototype.gridSelectionChanged = function (selectedRows) {
        if (this.selectedRowChange) {
            this.selectedRowChange.emit(selectedRows.selectedRowKeys);
        }
    };
    TdDataGridComponent.prototype.gridRowPrepared = function (e) {
        if (this.rowPrepared) {
            this.rowPrepared.emit(e);
        }
    };
    TdDataGridComponent.prototype.checkForCustomTemplate = function () {
        var templateKeys;
        if (this.templates) {
            templateKeys = Object.keys(this.templates);
        }
        this.gridStructure.columnsConfigurations.forEach(function (columnConfig) {
            if (templateKeys) {
                columnConfig.cellTemplate = templateKeys.indexOf(columnConfig.dataField) > -1 ? app_constants_1.GRID_TEMPLATES.customTemplate : null;
            }
            if (!columnConfig.cellTemplate && (columnConfig.dataType === grid_structure_interface_1.PropertyTypes.typeDate)) {
                columnConfig.cellTemplate = 'dateTemplate';
            }
        });
    };
    TdDataGridComponent.prototype.refreshData = function () {
        if (this.refreshGridData) {
            this.refreshGridData.emit();
        }
    };
    TdDataGridComponent.prototype.openSettings = function () {
        this.displaySettings = true;
    };
    TdDataGridComponent.prototype.columnChooserClosed = function () {
        this.displaySettings = false;
    };
    TdDataGridComponent.prototype.loadGridState = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.tableInfoService.getComponentState(_this.gridAlias).subscribe(function (response) {
                resolve();
            });
        });
    };
    TdDataGridComponent.prototype.saveGridState = function (state) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.tableInfoService.saveComponentState(_this.gridAlias, JSON.stringify(state)).subscribe(function () {
                resolve();
            });
        });
    };
    TdDataGridComponent.prototype.getSortedColumns = function () {
        var orderByArgsList = [];
        var state = this.grid.instance.state();
        state.columns.forEach(function (column) {
            if (column.sortOrder) {
                if (column.sortOrder === 'asc') {
                    orderByArgsList.push(new base_grid_args_model_1.OrderByArgsModel(column.dataField, td_enumerations_1.FuzzySearchSortOrder.Asc));
                }
                else {
                    orderByArgsList.push(new base_grid_args_model_1.OrderByArgsModel(column.dataField, td_enumerations_1.FuzzySearchSortOrder.Desc));
                }
            }
        });
        return orderByArgsList;
    };
    TdDataGridComponent.prototype.onExpandClick = function (e, data) {
        if (this.grid.instance.isRowExpanded(data.data[this.keyExpression])) {
            this.grid.instance.collapseRow(data.data[this.keyExpression]);
        }
        else {
            if (this.rowExpand) {
                this.rowExpand.emit(data.data[this.keyExpression]);
            }
            this.grid.instance.expandRow(data.data[this.keyExpression]);
        }
    };
    TdDataGridComponent.prototype.cellTemplateHighlights = function (cellElement, cellInfo) {
        var textWithHighlights = this.utilityService.highlightContent(cellInfo.text, this.highlightText);
        cellElement.innerHTML = this.sanitizer.sanitize(core_1.SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(textWithHighlights));
    };
    TdDataGridComponent.prototype.getDataPropertyValue = function (data, propertyToRetrieve) {
        return propertyToRetrieve.split('.').reduce(function (previous, current) {
            return previous && previous[current]; // get inner property if `previous` is defined else get `previous` and return
        }, data); // set initial value as object
    };
    TdDataGridComponent.prototype.valueIsDateType = function (data, propertyToRetrieve) {
        var value = this.getDataPropertyValue(data, propertyToRetrieve);
        return value instanceof Date;
    };
    /*
      When the enter key is released, the focused row is emitted to the parent
    */
    TdDataGridComponent.prototype.enterKeyUp = function () {
        if (this.focusRowEnabled) {
            this.enterPressedOnFocusedRow.emit(this.focusedRow);
        }
    };
    /*
      When the escape key is released, focus row is disabled and event emitted
    */
    TdDataGridComponent.prototype.escapeKeyUp = function () {
        if (this.focusRowEnabled) {
            this.escapeKeyUpWhileGridFocused.emit();
        }
    };
    /*
      When a new row is focused, it's value is stored in the "focusedRow" property
    */
    TdDataGridComponent.prototype.focusedRowChanged = function (e) {
        if (e.row) {
            this.focusedRow = e.row.data;
        }
    };
    /*
      Determines the page size, depending on the identifier of the grid
    */
    TdDataGridComponent.prototype.determinePageSize = function () {
        var pageSize;
        switch (this.gridAlias) {
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.allOrdersListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.openOrdersListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.sentOrdersListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.deliveredOrdersListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.productListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.endoscopeListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.itemListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.unitListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.orderTemplateListGrid:
            case td_constants_1.TD_DESKTOP_IDENTIFIERS.scheduledOperationListGrid:
                pageSize = td_constants_1.TD_VALUES.pureGridRowsPerPage;
                break;
            default:
                pageSize = td_constants_1.TD_VALUES.detailsGridRowsPerPage;
                break;
        }
        this.pagination = this.pagination ? this.pagination : { enabled: true, pageIndex: 0, pageSize: pageSize };
    };
    TdDataGridComponent.prototype.formatPagerTranslation = function (text) {
        return text.replace('%s', '{0}').replace('%s', '{1}').replace('%s', '{2}');
    };
    return TdDataGridComponent;
}());
exports.TdDataGridComponent = TdDataGridComponent;
