import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { CustomerModel } from '@core/data-layer';
import { AppState } from '@core/data-layer/app.state';
import { selectUserCustomers } from '@core/data-layer/customer/store/customer.selectors';
import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';
import * as custActions from '@core/data-layer/customer/store/customer.actions';
import * as factActions from '@core/data-layer/factory/store/factory.actions';
import { LoadIOConfigurations } from '@app/dashboard/data-layer/info-overview/store/info-overview.actions';
import { TdPopupService } from '../td-popup/services/td-popup.service';
import { JumpErrorCodes } from './models/td-deep-link.enumerations';
import { TdLoadPanelService } from '../td-load-panel/services/td-load-panel.service';
import { CUSTOMER_ID, ERROR_CODE, ERROR_MESSAGE, LANDING_PAGE_URL, OPERATIONS_URL, OPERATION_ID, ORDER_ID } from './models/deep-link.constants';
import { TdPopupButtonType } from '../td-popup/models/td-popup-button.interface';
import { PopupType } from '../td-popup/models/td-popup.model';

@Component({
  selector: 'td-deep-link',
  template: ''
})

export class DeepLinkComponent {

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    private popupService: TdPopupService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private loadPanelService: TdLoadPanelService
  ) {}

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      if(params[ERROR_MESSAGE]) {
        this.handleDeepLinkError(params);
      }
      else {
        this.handleDeepLinkSuccess(params);
      }
    });
  }

  /**
   * Decodes base64 error message, and displays any error that occured during deep linking and redirects if needed
   *
   * @param params - Current params in URL
   * @returns void
   */
  private handleDeepLinkError(params: Params): void {
    const errorCode = Number(params[ERROR_CODE]);
    const errorMessage = atob(params[ERROR_MESSAGE]);
    const redirectUrl = this.determineErrorRedirectUrl(errorCode);
    if (errorCode === JumpErrorCodes.WrongUserLoggedIn) {
      this.showCustomPopup(errorMessage);
    }
    else {
      this.popupService.showError(errorMessage).then(() => {   
        if (errorCode === JumpErrorCodes.UnableAutoLogin || errorCode === JumpErrorCodes.OperationNotFound) {
          this.loadPanelService.showLoadPanel();
          this.authService.startSignin(redirectUrl);
        }
        else {
          this.router.navigateByUrl(redirectUrl);
        }
      });
    }
  }

  /**
   * Returns a URL to navigate to while displaying the error to the user
   *
   * @remarks
   *    Should we introduce more JumpErrorCodes that require specific return URL's, introduce them as cases here,
   *     - ie: case JumpErrorCodes.OrderNotFound: return '/orders';
   * @param errorCode - number that represents a value in the JumpErrorCodes enum
   * @returns URL to redirect to
   */
  private determineErrorRedirectUrl(errorCode: JumpErrorCodes): string {
    switch (Number(errorCode)) {
      case JumpErrorCodes.OperationNotFound: return OPERATIONS_URL;
      default: return LANDING_PAGE_URL;
    }
  }
  /**
   * Handles the redirection that occurs when a deep link has successfully been performed
   * @remarks
   *   If a user tries to use a deep link URL, but is not authenticated, we will redirect to login page, and after successful login, we redirect back to desired route
   * @param params - Current params in URL
   * @returns void
   */
  private handleDeepLinkSuccess(params: Params): void {
    const customerId = Number(params[CUSTOMER_ID]);
    const redirectUrl = this.determineSuccessRedirectUrl(params);
    if (this.authService.isAuthenticated()) {
      this.setCustomerAndRedirect(customerId, redirectUrl);
    }
    else {
      this.authService.startSignin();
    }
  }

  /**
   * Returns a URL to redirect to, after receiving a succesful deeplink
   *
   * @remarks
   *   Currently, we only use redirect to operation details. However, we also support redirecting to order details.
   *   To redirect to order details, make sure the deep link URL contains a "orderId" property
   * @param params - Current params in URL
   * @returns URL to redirect to
   */
  private determineSuccessRedirectUrl(params: Params): string {
    if (params[OPERATION_ID]) {
      return `operations/${params[OPERATION_ID]}`;
    }
    else if (params[ORDER_ID]) {
      return `orders/${params[ORDER_ID]}`;
    }
  }

  /**
   * Triggers the "setCustomer()" function and redirects based on parameters
   *
   * @param customerId - The keyId of the customer desired
   * @param url - The URL desired to redirect to
   * @returns void
   */
  private setCustomerAndRedirect(customerId: number, url: string): void {
    this.setCustomer(customerId).then(customerAssociatedWithUser => {
      if (customerAssociatedWithUser) {
        this.navigateTo(url);
      }
      else {
        this.navigateTo(LANDING_PAGE_URL);
      }
    });
  }

  /**
   * Attempts to trigger a customer selection in the store, if the desired customer is linked to the authenticated user
   * Should the user not be associated with the desired customer, a popup error should be displayed
   * @param customerId - The keyid of the customer desired
   * @returns promise - if it was possible to find a matching customer, resolve(true) else resolve(false)
   */
  private setCustomer(customerId: number): Promise<boolean> {
    const customers$ = this.store.pipe(select(selectUserCustomers));
    return new Promise(resolve => {
      customers$.subscribe(customerList => {
        const wantedCustomer = customerList.find(entry => entry.keyId === customerId);
        if (wantedCustomer) {
          this.selectCustomer(wantedCustomer);
          resolve(true);
        }
        else {
          this.popupService.showError('appMessages.userNotAssociatedWithCustomer', true);
          resolve(false);
        }
      });
    });
  }

  /**
   * Selects the customer in the store, based on customer parameter
   *
   * @remark this logic is equal to the logic used in auth-customers component
   * @param customer - The customer desired to be selected
   * @returns void
   */
  private selectCustomer(customer: CustomerModel): void {
    this.store.dispatch(new factActions.LoadCustomerFactories(customer.keyId));
    this.store.dispatch(new factActions.LoadUserFactories());
    this.store.dispatch(new custActions.SetCurrentCustomer(customer));
    if (customer.cuSiteKeyId) {
      this.store.dispatch(new custActions.LoadSiteCustomers(customer.cuSiteKeyId));
    }
    this.store.dispatch(new LoadIOConfigurations());
  }

  private navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  private showCustomPopup(errorMessage: string): void {
    this.popupService.showCustomWithButtons(
      'appMessages.logout',
      errorMessage,
      PopupType.Confirmation,
      [
        {
          text: 'button.cancel',
          type: TdPopupButtonType.Secondary
        },
        {
          text: 'appMessages.logout',
          type: TdPopupButtonType.Danger,
          callback: () => {
            this.authService.startSignout();
          }
        }
      ]
    ).then(popupResolved => {
      if (!popupResolved) {
        this.navigateTo('');
      };
    })
  }
}
