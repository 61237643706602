import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { TdLanguageComponent } from './components/td-language/td-language.component';
import { TdDataGridComponent } from './components/td-data-grid/td-data-grid.component';
import { TdGridColumnChooserComponent } from './components/td-grid-column-chooser/td-grid-column-chooser.component';
import { BaseComponent } from './components/base/base.component';
import { TdToolbarButtonComponent } from './components/td-toolbar-button/td-toolbar-button.component';
import { TdMediaViewerComponent } from './components/td-media-viewer/td-media-viewer.component';
import { TdDevExtremeModule } from './td-devextreme.module';
import { TdPopupComponent } from './components/td-popup/td-popup.component';
import { TdLoadPanelComponent } from './components/td-load-panel/td-load-panel.component';
import { TdOrderCartComponent } from './components/td-order-cart/td-order-cart.component';
import { TdAddArticleToCartComponent } from './components/td-add-article-to-cart/td-add-article-to-cart.component';
import { TdDetailsPropertyComponent } from './components/td-details-property/td-details-property.component';
import { TdMediaWrapperComponent } from './components/td-media-wrapper/td-media-wrapper.component';
import { TdGridMediaWrapperComponent } from './components/td-grid-media-wrapper/td-grid-media-wrapper.component';
import { TdSerialNumberInfoComponent } from './components/td-serial-number-info/td-serial-number-info.component';
import { TdTabPanelTitleComponent } from './components/td-tab-panel-title/td-tab-panel-title.component';
import { TdRemarkTabComponent } from './components/td-remark-tab/td-remark-tab.component';
import { TdItemListComponent } from './components/td-item-list/td-item-list.component';
import { TdPreviewPopupComponent } from './components/td-preview-popup/td-preview-popup.component';
import { TdExternalDocumentsListComponent } from './components/td-external-documents/td-external-documents.component';
import { TdDropDownGridComponent } from './components/td-drop-down-grid/td-drop-down-grid.component';
import { TdConfirmButtonPanelComponent } from './components/td-confirm-button-panel/td-confirm-button-panel.component';
import { MaskableFieldPipe } from './pipes/maskable-field.pipe';
import { TdSearchFiltersButtonComponent } from './components/td-search-filters-button/td-search-filters-button.component';
import { TdSearchFiltersComponent } from './components/td-search-filters/td-search-filters.component';
import { LoadingComponent } from './components/td-loading/td-loading.component';
import { ChangePasswordSuccessComponent } from './components/td-change-password-success/td-change-password-success.component';
import { DeepLinkComponent } from './components/td-deep-link/td-deep-link.component';
import { TdSwitchComponent } from './components/td-switch/td-switch.component';
import { UrlSearchService } from './services/url-search.service';

@NgModule({
  declarations: [
    BaseComponent,
    TdDataGridComponent,
    TdLanguageComponent,
    TdGridColumnChooserComponent,
    TdToolbarButtonComponent,
    TdMediaViewerComponent,
    TdMediaWrapperComponent,
    TdPopupComponent,
    TdLoadPanelComponent,
    TdOrderCartComponent,
    TdAddArticleToCartComponent,
    TdDetailsPropertyComponent,
    TdGridMediaWrapperComponent,
    TdSearchFiltersButtonComponent,
    TdSearchFiltersComponent,
    TdSerialNumberInfoComponent,
    TdTabPanelTitleComponent,
    TdRemarkTabComponent,
    TdItemListComponent,
    TdPreviewPopupComponent,
    TdExternalDocumentsListComponent,
    TdDropDownGridComponent,
    TdConfirmButtonPanelComponent,
    LoadingComponent,
    ChangePasswordSuccessComponent,
    DeepLinkComponent,   
    TdSwitchComponent, 
    MaskableFieldPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SwiperModule,
    TdDevExtremeModule,
    TranslateModule
  ],
  exports: [
    SwiperModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    TdDevExtremeModule,
    TdLanguageComponent,
    TdDataGridComponent,
    TdGridColumnChooserComponent,
    TdToolbarButtonComponent,
    TdMediaViewerComponent,
    TdMediaWrapperComponent,
    TdPopupComponent,
    TdLoadPanelComponent,
    TdOrderCartComponent,
    TdAddArticleToCartComponent,
    TdDetailsPropertyComponent,
    TdGridMediaWrapperComponent,
    TdSearchFiltersButtonComponent,
    TdSearchFiltersComponent,
    TdSerialNumberInfoComponent,
    TdTabPanelTitleComponent,
    TdRemarkTabComponent,
    TdItemListComponent,
    TdPreviewPopupComponent,
    TdExternalDocumentsListComponent,
    TdDropDownGridComponent,
    TdConfirmButtonPanelComponent,
    LoadingComponent,
    ChangePasswordSuccessComponent,
    DeepLinkComponent,
    TdSwitchComponent,
    MaskableFieldPipe
  ],
  providers: [
    DatePipe,
    MaskableFieldPipe,
    UrlSearchService
  ]
})
export class SharedModule { }
