"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var logger_service_1 = require("@core/logger/logger.service");
var signal_r_service_1 = require("@core/signal-r/signal-r.service");
var server_session_actions_1 = require("@core/data-layer/server-session/store/server-session.actions");
var server_session_selectors_1 = require("@core/data-layer/server-session/store/server-session.selectors");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
// signal-r server method(s)
var HUB_SRV_METHOD_CREATE_LICENSE_BINDING = 'CreateLicenseBinding';
var SignalRLicenseBindingService = /** @class */ (function (_super) {
    tslib_1.__extends(SignalRLicenseBindingService, _super);
    function SignalRLicenseBindingService(loggerService, store, router) {
        var _this = _super.call(this, loggerService) || this;
        _this.store = store;
        _this.router = router;
        return _this;
    }
    // override the protected method
    SignalRLicenseBindingService.prototype.getHubServerUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.serverUrl + api_hub_constants_1.API_HUB_PATHS.licenseBindingHub;
    };
    // override the protected method
    SignalRLicenseBindingService.prototype.beforeInitialize = function () {
        var _this = this;
        _super.prototype.beforeInitialize.call(this);
        this.store.pipe(store_1.select(server_session_selectors_1.selectClientId)).subscribe(function (clientId) {
            if (clientId) {
                _this.clientId = clientId;
            }
            else {
                _this.store.dispatch(new server_session_actions_1.InitializeClientId());
            }
        });
    };
    // override the protected method
    SignalRLicenseBindingService.prototype.startConnectionAndLoadLicense = function () {
        _super.prototype.initializeConnection.call(this);
    };
    // override the protected method
    SignalRLicenseBindingService.prototype.stopConnection = function () {
        _super.prototype.stopConnection.call(this);
        this.store.dispatch(new server_session_actions_1.SetLicense(null));
    };
    // override the protected method
    SignalRLicenseBindingService.prototype.onConnectionEstablished = function () {
        _super.prototype.onConnectionEstablished.call(this);
        this.createLicenseBinding();
    };
    SignalRLicenseBindingService.prototype.createLicenseBinding = function () {
        var _this = this;
        if (this.connectedToHub()) {
            this.hubConnection.invoke(HUB_SRV_METHOD_CREATE_LICENSE_BINDING, this.clientId)
                .then(function (response) {
                if (response) {
                    _this.store.dispatch(new server_session_actions_1.LoadLicense());
                    _this.loggerService.log('Create license binding [' + _this.clientId + ']');
                }
                else {
                    _this.stopConnection();
                    _this.router.navigate(['/error'], { skipLocationChange: true, queryParams: { type: td_constants_1.TD_ERROR_TYPES.noAvailableLicense } });
                    _this.loggerService.log('No available license [' + _this.clientId + ']');
                }
            })
                .catch(function (err) { _this.loggerService.error(HUB_SRV_METHOD_CREATE_LICENSE_BINDING + ' error: ' + err); });
        }
    };
    return SignalRLicenseBindingService;
}(signal_r_service_1.SignalRService));
exports.SignalRLicenseBindingService = SignalRLicenseBindingService;
