import { SearchParamDate, SearchParamNumber, SearchParamString, SearchParamBoolean } from './index';

export type SearchParam = SearchParamDate | SearchParamNumber | SearchParamString | SearchParamBoolean;

export interface SearchParamsModel {
  url: string;
  properties: SearchParam[];
}

export interface SearchParamBase {
  urlParamName: string;
}

export enum SearchParamTypes {
  Date,
  Number,
  String,
  Boolean
}
