import { Injectable } from '@angular/core';
import { DxTabPanelComponent } from 'devextreme-angular';

import { TabsInterface } from '../models/tabs.interface';

@Injectable({
  providedIn: 'root'
})
export class TabHelperService {

  constructor() { }

  setTabState(tabs: TabsInterface[], templateName: string, disabled: boolean, tabPanel: DxTabPanelComponent, desiredActiveTabIndex?: number) {
    tabs.find(tab => tab.template === templateName).disabled = disabled;
    this.setActiveTab(tabs, tabPanel, desiredActiveTabIndex);
  }

  setTabStateFromDesktopIdentifier(tabs: TabsInterface[], identifier: string, disabled: boolean, tabPanel: DxTabPanelComponent, desiredActiveTabIndex?: number): void {
    tabs.find(tab => tab.desktopIdentifier === identifier).disabled = disabled;
    this.setActiveTab(tabs, tabPanel, desiredActiveTabIndex);
  }

  setActiveTab(tabs: TabsInterface[], tabPanel: DxTabPanelComponent, desiredActiveTabIndex?: number) {
    if (desiredActiveTabIndex !== undefined) {
      tabPanel.selectedIndex = desiredActiveTabIndex;
    }
    else {
      if (tabs[tabPanel.selectedIndex].disabled) {
        const enabledTabIndex = tabs.findIndex(tab => !tab.disabled);
        if (enabledTabIndex >= 0) {
          tabPanel.selectedIndex = enabledTabIndex;
        }
      }
    }
  }
}
