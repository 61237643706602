import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UnitServiceInterface } from '@core/data-layer/unit/services/unit.service.interface';
import { TdPopupService } from '@shared/components/td-popup/services/td-popup.service';

@Injectable({
  providedIn: 'root'
})
export class UnitHelperService {

  constructor(
    @Inject('UNIT_SERVICE') private unitService: UnitServiceInterface,
    private translateService: TranslateService,
    private popupService: TdPopupService
  ) { }

  showFastTrackInfo(unitKeyId: number) {
    this.unitService.getFastTrackInfo(unitKeyId).subscribe(fastTrackInfo => {
      let fastTrackMessage = '';
      if (fastTrackInfo.fastTrackCodes && fastTrackInfo.fastTrackCodes.length > 0) {
        fastTrackMessage = this.translateService.instant('appCommon.codes') + ':';
        fastTrackInfo.fastTrackCodes.forEach(fastTrackValue => {
          fastTrackMessage = fastTrackMessage + '<br/>' + fastTrackValue;
        });
      }
      if (fastTrackInfo.fastTrackPlans && fastTrackInfo.fastTrackPlans.length > 0) {
        if (fastTrackMessage) {
          fastTrackMessage = fastTrackMessage + '<br/><br/>';
        }
        fastTrackMessage = fastTrackMessage + this.translateService.instant('appCommon.plans') + ':';
        fastTrackInfo.fastTrackPlans.forEach(fastTrackValue => {
          fastTrackMessage = fastTrackMessage + '<br/>' + fastTrackValue;
        });
      }
      this.popupService.showCustomInfo('appMessages.unitIsFastTracked', fastTrackMessage);
    });
  }
}
