import { Component, OnInit, Inject, Input } from '@angular/core';

import { MediaServiceInterface } from '@core/media/services/media.service.interface';
import { TableTypes } from '@core/data-layer/shared/models/td.enumerations';

@Component({
  selector: 'td-grid-media-wrapper',
  templateUrl: 'td-grid-media-wrapper.component.html',
  styleUrls: ['./td-grid-media-wrapper.component.less']
})

export class TdGridMediaWrapperComponent implements OnInit {

  @Input() picsKeyId: number;
  @Input() mediaIndex: number;
  @Input() mediaItem: any;
  @Input() mediaTitle?: string;
  @Input() mediaSeries: any;
  @Input() tableType: TableTypes;
  @Input() keyId?: number;
  @Input() tabIndex?: number;  

  popOverTarget: any;
  popOverVisible: boolean;
  popOverImg: any;
  popOverIsLoading: boolean;  
  displayMediaViewer: boolean;
  buttonId: string;
  noThumbnailExists: boolean;

  constructor(
    @Inject('MEDIA_SERVICE') private mediaService: MediaServiceInterface
  ) { }

  ngOnInit() {
    this.keyId = this.keyId ? this.keyId : this.mediaItem.keyId;
    this.mediaTitle = this.mediaTitle ? this.mediaTitle : this.determineMediaTitle();
    this.buttonId = this.generateIdForMedia();
   }  

  mediaHoverEnter(): void {    
    if (!this.popOverIsLoading) {
      this.popOverTarget = `#${this.buttonId}`;
      this.popOverVisible = true;
      this.popOverIsLoading = true;
      this.mediaService.getMediaThumbnail(this.picsKeyId).subscribe(thumbnail => {     
        if (thumbnail) {
          this.popOverImg = thumbnail;
        }
        else {
          this.noThumbnailExists = true;
        }        
        this.popOverIsLoading = false;
      });
    }
  }

  mediaHoverExit(): void {
    this.popOverVisible = false;
  }

  showMediaViewer(): void {
    this.displayMediaViewer = true;
  }

  determineMediaTitle(): string {
    if (this.mediaItem.product) {
      return `${this.mediaItem.product} - ${this.mediaItem.item.text}`;
    } 
    else if (this.mediaItem.item) {
      return `${this.mediaItem.item} - ${this.mediaItem.text}`;
    }
    else if (this.mediaItem.oLnLine) {
      return `${this.mediaItem.oLnArticle} - ${this.mediaItem.oLnArticleText}`;
    }
    else if (this.mediaItem.refItem) {
      return `${this.mediaItem.refItem.item} - ${this.mediaItem.refItem.text}`;
    }
  }

  generateIdForMedia(): string {
    return `mediaButton-${this.tabIndex}-${this.mediaIndex}-${this.keyId}-${this.picsKeyId}`;
  }
}
