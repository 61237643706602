"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var user_session_actions_1 = require("./user-session.actions");
var UserSessionEffects = /** @class */ (function () {
    function UserSessionEffects(actions$, userSessionService) {
        var _this = this;
        this.actions$ = actions$;
        this.userSessionService = userSessionService;
        this.loadProfileData$ = this.actions$
            .pipe(effects_1.ofType(user_session_actions_1.UserSessionActionTypes.LoadProfileData), operators_1.mergeMap(function () {
            return _this.userSessionService.getProfileData()
                .pipe(operators_1.map(function (profileData) { return ({ type: user_session_actions_1.UserSessionActionTypes.SetProfileData, payload: profileData }); }), operators_1.catchError(function () { return rxjs_1.of({ type: user_session_actions_1.UserSessionActionTypes.SetProfileData }); }));
        }));
        this.loadUserOptions$ = this.actions$
            .pipe(effects_1.ofType(user_session_actions_1.UserSessionActionTypes.LoadUserOptions), operators_1.mergeMap(function () {
            return _this.userSessionService.getUserOptions()
                .pipe(operators_1.map(function (response) { return ({ type: user_session_actions_1.UserSessionActionTypes.SetUserOptions, payload: response }); }), // TODO: handle error that comes from backend (response.error)
            operators_1.catchError(function () { return rxjs_1.of({ type: user_session_actions_1.UserSessionActionTypes.SetUserOptions }); }));
        }));
        this.loadDefaultUserOptions$ = this.actions$
            .pipe(effects_1.ofType(user_session_actions_1.UserSessionActionTypes.LoadDefaultUserOptions), operators_1.mergeMap(function () {
            return _this.userSessionService.getDefaultUserOptions()
                .pipe(operators_1.map(function (response) { return ({ type: user_session_actions_1.UserSessionActionTypes.SetDefaultUserOptions, payload: response }); }), // TODO: handle error that comes from backend (response.error)
            operators_1.catchError(function () { return rxjs_1.of({ type: user_session_actions_1.UserSessionActionTypes.SetDefaultUserOptions }); }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserSessionEffects.prototype, "loadProfileData$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserSessionEffects.prototype, "loadUserOptions$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UserSessionEffects.prototype, "loadDefaultUserOptions$", void 0);
    return UserSessionEffects;
}());
exports.UserSessionEffects = UserSessionEffects;
