import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationServiceInterface } from '../authentication/services/authentication.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(
    private router: Router,
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // we allow to navigate to page only when:
    // 1. user was logged in
    if (this.authService.isAuthenticated()) {
      if (route.routeConfig.path === 'changePassword') {
        const url = `/Account/ChangePassword?returnUrl=${route.queryParams.returnUrl}`;
        window.location.href = url;
        return true;
      }
    }
    else {
      return false;
    }
  }

}
