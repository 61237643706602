"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PopupType;
(function (PopupType) {
    PopupType[PopupType["Information"] = 0] = "Information";
    PopupType[PopupType["Confirmation"] = 1] = "Confirmation";
    PopupType[PopupType["Error"] = 2] = "Error";
    PopupType[PopupType["Template"] = 3] = "Template";
    PopupType[PopupType["CustomWithButtons"] = 4] = "CustomWithButtons";
})(PopupType = exports.PopupType || (exports.PopupType = {}));
var TdPopupModel = /** @class */ (function () {
    function TdPopupModel(visible, title, message, template, type, titleIconPath, buttons, iconType) {
        var _this = this;
        if (visible === void 0) { visible = false; }
        if (title === void 0) { title = ''; }
        if (message === void 0) { message = ''; }
        if (template === void 0) { template = null; }
        if (type === void 0) { type = PopupType.Information; }
        if (titleIconPath === void 0) { titleIconPath = null; }
        if (buttons === void 0) { buttons = null; }
        if (iconType === void 0) { iconType = null; }
        this.visible = visible;
        this.title = title;
        this.message = message;
        this.template = template;
        this.type = type;
        this.titleIconPath = titleIconPath;
        this.customButtons = buttons;
        this.iconType = iconType;
        this.response = new Promise(function (resolve) {
            _this.resolve = resolve;
        });
    }
    TdPopupModel.prototype.comfirm = function () {
        this.result = true;
    };
    TdPopupModel.prototype.cancel = function () {
        this.result = false;
    };
    TdPopupModel.prototype.resolvePopup = function () {
        this.resolve(this.result);
    };
    return TdPopupModel;
}());
exports.TdPopupModel = TdPopupModel;
