import { Injectable } from '@angular/core';

import { IMAGES_TO_PRELOAD  } from '../models/preload-image-paths.constants';

@Injectable({providedIn: 'root'})
export class PreloadMediaService {
  
  constructor() { }

  preloadAllImages(): void {
    IMAGES_TO_PRELOAD.forEach(imagePath => {
      const img = new Image();
      img.src = imagePath;
    });
  }
}