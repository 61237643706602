import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { RecentSearchInterface } from '@core/search/models/recent-search.interface';
import { SearchManagerService } from '@core/search/services/search-manager.service';
import { UtilityService } from '@core/utility/utility.service';
import {
  SearchParamsModel, SearchParamTypes,
  extractDateFromStringParam, addDatePropToParams,
  extractNumberFromParams, addStringPropToParams,
  extractStringFromParams, addNumberPropToParams } from '../models/url-search-models/param-models';
import { addBooleanPropToParams, extractBooleanFromParams } from '../models/url-search-models/param-models/boolean-param.models';
import { ParamSearchFactoryInterface } from '../models/url-search-models/param-search-factory.interface';

export enum SearchType {
  ArticleSearch,
  OperationSearch,
  OrderSearch
}

@Injectable()
export class UrlSearchService {

  constructor(
    private searchManagerService: SearchManagerService,
    private utilityService: UtilityService,
    private router: Router
  ) {}

  onQueryParamChanges(params: Params, model: SearchParamsModel, searchObject: ParamSearchFactoryInterface, firstSearch: boolean): SearchParamsModel {
    if (this.utilityService.objIsEmpty(params)) {
      this.triggerRecentOrDefaultSearch(searchObject, firstSearch);
      return null;
    }
    else {
      return this.extractPropertiesFromUrlParams(model, params);
    }
  }

  setSearchParamsAndNavigate(model: SearchParamsModel, firstSearch: boolean): void {
    const params = this.convertPropertiesToUrlParams(model);
    this.routeNavigate(params, model, firstSearch);
  }

  private triggerRecentOrDefaultSearch(searchObject: ParamSearchFactoryInterface, firstSearch: boolean): void {
    const emptyParamsSearchModel = searchObject.getEmptyParamsSearchModel();
    const recentSearch = this.getRecentSearch(emptyParamsSearchModel.url);
    if (!firstSearch || this.utilityService.objIsEmpty(recentSearch)) {
      this.addParamsForDefaultSearch(emptyParamsSearchModel);
    }
    else {
      this.addParamsForRecentSearch(emptyParamsSearchModel, searchObject);
    }
  }

  private extractPropertiesFromUrlParams(model: SearchParamsModel, queryParams: Params): SearchParamsModel {
    model.properties.forEach(prop => {
      switch (prop.type) {
        case SearchParamTypes.Date    :  {
          prop.value = extractDateFromStringParam(prop.urlParamName, queryParams);
          break;
        }
        case SearchParamTypes.Number  : {
          prop.value = extractNumberFromParams(prop.urlParamName, queryParams);
          break;
        }
        case SearchParamTypes.String  : {
          prop.value = extractStringFromParams(prop.urlParamName, queryParams);
          break;
        }
        case SearchParamTypes.Boolean : {
          prop.value = extractBooleanFromParams(prop.urlParamName, queryParams);
          break;
        }
      }
    });
    return model;
  }

  private convertPropertiesToUrlParams(model: SearchParamsModel): Params {
    let params: Params = {};
    model.properties.forEach(prop => {
      if (prop.value !== null && prop.value !== undefined) {
        switch (prop.type) {
          case SearchParamTypes.Date  : {
            params = addDatePropToParams(prop, params)
            break;
          }
          case SearchParamTypes.Number  : {
            params = addNumberPropToParams(prop, params)
            break;
          }
          case SearchParamTypes.String  : {
            params = addStringPropToParams(prop, params)
            break;
          }
          case SearchParamTypes.Boolean  : {
            params = addBooleanPropToParams(prop, params)
            break;
          }
        }
      }
    });
    return params;
  }

  private routeNavigate(params: Params, searchModel: SearchParamsModel, firstSearch: boolean): void {
    this.router.navigate([searchModel.url], this.setupNavigationExtras(params, firstSearch));
  }

  private addParamsForDefaultSearch(searchModel: SearchParamsModel): void {
    const params: Params = this.convertPropertiesToUrlParams(searchModel);
    this.routeNavigate(params, searchModel, true);
  }

  private addParamsForRecentSearch(searchModel: SearchParamsModel, searchClass: ParamSearchFactoryInterface): void {
    const recentSearch = this.getRecentSearch(searchModel.url);
    const params: Params = searchClass.getRecentSearchAsParams(recentSearch);
    this.routeNavigate(params, searchModel, true);

  }

  private getRecentSearch(url: string): RecentSearchInterface {
    switch (url) {
      case '/orders':           return this.searchManagerService.recentOrderSearch;
      case '/operations':       return this.searchManagerService.recentOperationSearch;
      case '/order-templates':  return this.searchManagerService.recentOrderTemplatesSearch;
    }
  }

  private setupNavigationExtras(params: Params, firstSearch: boolean): NavigationExtras {
    const navigationExtras: NavigationExtras = {
      queryParams: params,
      replaceUrl: firstSearch
    }
    return navigationExtras;
  }
}
