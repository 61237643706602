"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var HttpExternalDocumentsService = /** @class */ (function () {
    function HttpExternalDocumentsService(http) {
        this.http = http;
    }
    HttpExternalDocumentsService.prototype.externalDocumentsUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.externalDocumentsCtrl;
    };
    HttpExternalDocumentsService.prototype.getExternalDocument = function (keyId) {
        return this.http.get(this.externalDocumentsUrl() + "(" + keyId + ")" + api_hub_constants_1.API_HUB_PATHS.file, { responseType: 'blob' });
    };
    return HttpExternalDocumentsService;
}());
exports.HttpExternalDocumentsService = HttpExternalDocumentsService;
