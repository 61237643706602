import { Injectable } from '@angular/core';

import { nameOf } from '@core/utility/utility.service';
import { UnitModel } from '@core/data-layer/unit/models/unit.model';

@Injectable({
  providedIn: 'root'
})
export class UnitFilterService {

  getOperationUnitListFilter(keyIds: number[]): any[] {
    const nameOfOrder = nameOf<UnitModel>();
    return [nameOfOrder('keyId'), 'in', keyIds];
  }

}
