"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var devextreme_angular_1 = require("devextreme-angular");
var search_manager_service_1 = require("@core/search/services/search-manager.service");
var td_data_grid_component_1 = require("@shared/components/td-data-grid/td-data-grid.component");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var TdDropDownGridComponent = /** @class */ (function () {
    function TdDropDownGridComponent(tableInfoService, searchManagerService) {
        this.tableInfoService = tableInfoService;
        this.searchManagerService = searchManagerService;
        this.searchCriterias = [];
        this.searchResultCleared = new core_1.EventEmitter();
        this.articleSelected = new core_1.EventEmitter();
        this.dropDownWidth = 300;
        // properties for navigating with keyboard
        this.allSearchResultData = [];
        this.preventSearchOnEnter = false;
        this.focusSearchGrid = false;
        this.focusRowEnabled = false;
        if (!this.gridWidth) {
            this.gridWidth = this.dropDownWidth + 100; // a little bit wide
        }
        this.inputBoxDisplayExpr = this.inputBoxDisplayExpr.bind(this);
        this.onInputSearchText = this.onInputSearchText.bind(this);
        this.onOpenedSearchResult = this.onOpenedSearchResult.bind(this);
        this.onClosedSearchResult = this.onClosedSearchResult.bind(this);
        this.onChangedSearchValue = this.onChangedSearchValue.bind(this);
    }
    TdDropDownGridComponent.prototype.ngOnChanges = function (changes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(changes['gridIdentifier'] && changes['gridIdentifier'].currentValue)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.initGridSearch()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TdDropDownGridComponent.prototype.initGridSearch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.tableInfoService.loadGridStructure(_this.gridIdentifier, _this.mainTable).subscribe(function (gridStructure) {
                            _this.gridStructure = gridStructure;
                            // init search args
                            _this.searchArgs = _this.initSearchRequestArgs(_this.gridStructure);
                            _this.searchArgs.criterias = _this.searchCriterias;
                            _this.clearSearchResult();
                            resolve(true);
                        });
                    })];
            });
        });
    };
    TdDropDownGridComponent.prototype.clearSearchResult = function () {
        this.searchResultCleared.emit();
        this.searchResultData = null;
        this.searchInputBox.instance.reset();
        if (this.searchGrid) {
            this.searchGrid.grid.instance.repaint();
        }
    };
    Object.defineProperty(TdDropDownGridComponent.prototype, "selectedData", {
        get: function () {
            return this.searchValue;
        },
        enumerable: true,
        configurable: true
    });
    TdDropDownGridComponent.prototype.requestSearch = function () {
        this.searchArgs.criterias = this.searchCriterias;
        // due to search filters can be changed from parent component we are updating them before calling search
        this.searchManagerService.setUserSearchFilters(this.searchArgs);
        this.searchResultData = this.searchManagerService.requestSearchData(this.searchArgs, this.searchValue, this.afterDataLoaded(), td_constants_1.TD_DYNAMIC_FIELDS.keyId);
    };
    TdDropDownGridComponent.prototype.executeSearch = function (enterPressed) {
        if (enterPressed === void 0) { enterPressed = false; }
        if ((!enterPressed || this.searchRowSelected()) && (this.searchInputBox.text !== '')) {
            this.searchValue = this.searchInputBox.text;
            this.requestSearch();
        }
        else if (!enterPressed) {
            this.clearSearchResult();
        }
        else if (this.searchValue) {
            this.requestSearch();
        }
        if (this.onExecuteSearch) {
            this.onExecuteSearch();
        }
    };
    /*
      Every time a new page of data is loaded, it is pushed to the "allSearchResultData" array, so it contains data of all pages
      This array is used to find a match, if it's not from the latest searched page.
      Furthermore, we store the keyId of the first row in the dataset, used to determine which row to focus initially
    */
    TdDropDownGridComponent.prototype.afterDataLoaded = function () {
        var _this = this;
        return function (loadResult) {
            _this.focusedRowKey = loadResult.totalCount > 0 ? loadResult.data[0][td_constants_1.TD_DYNAMIC_FIELDS.keyId] : null;
            loadResult.data.forEach(function (entry) { return _this.allSearchResultData.push(entry); });
            if (_this.afterSearchDataLoaded) {
                _this.afterSearchDataLoaded(loadResult.data, _this.gridStructure);
            }
        };
    };
    TdDropDownGridComponent.prototype.searchRowSelected = function () {
        return this.searchValue && (typeof this.searchValue !== 'string');
    };
    TdDropDownGridComponent.prototype.inputBoxDisplayExpr = function (selectedRow) {
        if (this.searchRowSelected()) {
            return this.displayExpr(selectedRow);
        }
        else {
            return selectedRow;
        }
    };
    TdDropDownGridComponent.prototype.onInputSearchText = function () {
        var _this = this;
        clearTimeout(this.searchTimer);
        if (this.searchInputBox.text === '') {
            this.clearSearchResult();
        }
        this.searchTimer = setTimeout(function () {
            if (_this.searchInputBox.opened) {
                _this.executeSearch();
            }
            else {
                _this.searchInputBox.instance.open();
            }
        }, 1000);
    };
    TdDropDownGridComponent.prototype.onOpenedSearchResult = function (e) {
        // Since we can't set different width for autocreated overlay dropdown popup using css we set it here
        e.component._popup.option('width', 'auto');
        this.executeSearch();
    };
    TdDropDownGridComponent.prototype.onClosedSearchResult = function () {
        clearTimeout(this.searchTimer);
    };
    TdDropDownGridComponent.prototype.onChangedSearchValue = function () {
        this.allSearchResultData = [];
        clearTimeout(this.searchTimer);
        this.searchInputBox.instance.focus();
    };
    /*
      When a row is selected by either mouse click or enter press, we match the keyId with the entries in the "allSearchResultData" array
    */
    TdDropDownGridComponent.prototype.onChangeSelectedSearchRow = function (selectedRows) {
        if (selectedRows && (selectedRows.length > 0)) {
            var match = this.allSearchResultData.find(function (entry) { return entry[td_constants_1.TD_DYNAMIC_FIELDS.keyId] === selectedRows[0]; });
            this.searchValue = match;
            this.closeDropdown();
        }
    };
    /*
      The dropdown box will keep track of when enter is pressed, even when it's done within the drop down data grid in it's template.
      Therefore it's important that we don't trigger a new search, when the user is trying to select an article
    */
    TdDropDownGridComponent.prototype.enterKeyDown = function () {
        if (!this.preventSearchOnEnter && this.searchValue && this.searchValue !== '') {
            clearTimeout(this.searchTimer);
            this.searchInputBox.instance.focus();
            if (!this.searchInputBox.opened) {
                this.searchInputBox.instance.open();
            }
            else {
                this.executeSearch(true);
            }
        }
    };
    /*
      Triggered when the user presses enter on a row they want to focus.
      It sets the "preventSearchOnEnter" property, to ensure we don't trigger a new search when enter is being pressed
    */
    TdDropDownGridComponent.prototype.enterPressedOnFocusedRow = function (e) {
        this.preventSearchOnEnter = true;
        this.onChangeSelectedSearchRow([e[td_constants_1.TD_DYNAMIC_FIELDS.keyId]]);
    };
    /*
      Closes the search result grid, and allows enter to be used for searching for new articles again, after a 10 ms delay.
      This is to ensure that selecting a row, doesn't also trigger a new search
    */
    TdDropDownGridComponent.prototype.closeDropdown = function () {
        var _this = this;
        setTimeout(function () {
            _this.searchInputBox.instance.close();
            _this.preventSearchOnEnter = false;
            _this.articleSelected.emit(_this.searchValue);
        }, 10);
    };
    /*
      When the arrow down key is released, we want to focus the search grid and enable focusRow
    */
    TdDropDownGridComponent.prototype.arrowDownKeyUp = function () {
        this.focusSearchGrid = true;
    };
    /*
      When the data grid is focused, and the user presses escape, this method will set focus to the searchInputBox, and cancel grid focus
    */
    TdDropDownGridComponent.prototype.focusDropdownInput = function () {
        this.focusSearchGrid = false;
        this.searchInputBox.instance.focus();
    };
    /*
      When the searchInputBox is clicked with mouse, we will remove focus from data grid
    */
    TdDropDownGridComponent.prototype.mouseFocusDropdownInput = function () {
        this.focusSearchGrid = false;
    };
    return TdDropDownGridComponent;
}());
exports.TdDropDownGridComponent = TdDropDownGridComponent;
