import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { OrderTemplateModel } from '../models/order-template.model';
import { OrderTemplateViewModel } from '../models/order-template-view.model';

@Injectable({
  providedIn: 'root'
})
export class OrderTemplateHelperService {

  constructor(
    private translateService: TranslateService
  ) { }

  /**
   * @description converts [OrderTemplateModel][Object] to [OrderTemplateViewModel][Object]
   * @param orderTemplate: [OrderTemplateModel][Object]
   * @returns [OrderTemplateViewModel][Object]
   */
  convertOrderTemplateToViewModel(orderTemplate: OrderTemplateModel): OrderTemplateViewModel {
    return {
      ...orderTemplate,
      fromCaption: this.getFromCaption(orderTemplate),
      fromText: this.getFromText(orderTemplate),
      toCaption: this.getToCaption(orderTemplate),
      toText: this.getToText(orderTemplate)
    };
  }

  getFromCaption(orderTemplate: OrderTemplateModel): string {
    const caption = this.translateService.instant('templateDetails.from');
    if (orderTemplate.orgRefCustKeyId) {
      return `${caption} ${this.translateService.instant('appCommon.customer').toLowerCase()}:`;
    } else if (orderTemplate.orgRefFacKeyId) {
      return `${caption} ${this.translateService.instant('appCommon.factory').toLowerCase()}:`;
    } else if (orderTemplate.orgRefSuppKeyId) {
      return `${caption} ${this.translateService.instant('appCommon.supplier').toLowerCase()}:`;
    } else {
      return `${caption}:`;
    }
  }

  getFromText(orderTemplate: OrderTemplateModel): string  {
    if (orderTemplate.orgRefCustKeyId) {
      return `${orderTemplate.orgRefCust.customer} - ${orderTemplate.orgRefCust.name}`;
    } else if (orderTemplate.orgRefFacKeyId) {
      return `${orderTemplate.orgRefFac.factory} - ${orderTemplate.orgRefFac.name}`;
    } else if (orderTemplate.orgRefSuppKeyId) {
      return `${orderTemplate.orgRefSupp.supplier} - ${orderTemplate.orgRefSupp.name}`;
    } else {
      return '';
    }
  }

  getToCaption(orderTemplate: OrderTemplateModel): string  {
    const caption = this.translateService.instant('templateDetails.to');
    if (orderTemplate.suppRefSuppKeyId) {
      return `${caption} ${this.translateService.instant('appCommon.supplier').toLowerCase()}:`;
    } else if (orderTemplate.suppRefFacKeyId) {
      return `${caption} ${this.translateService.instant('appCommon.factory').toLowerCase()}:`;
    } else {
      return `${caption}:`;
    }
  }

  getToText(orderTemplate: OrderTemplateModel): string  {
    if (orderTemplate.orgRefSuppKeyId) {
      return `${orderTemplate.suppRefSupp.supplier} - ${orderTemplate.suppRefSupp.name}`;
    } else if (orderTemplate.suppRefFacKeyId) {
      return `${orderTemplate.suppRefFac.factory} - ${orderTemplate.suppRefFac.name}`;
    } else {
      return '';
    }
  }

}
