"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_grid_args_model_1 = require("@core/data-layer/shared/models/base-grid-args.model");
var FuzzySearchArgsModel = /** @class */ (function () {
    function FuzzySearchArgsModel() {
    }
    return FuzzySearchArgsModel;
}());
exports.FuzzySearchArgsModel = FuzzySearchArgsModel;
var GridFilterSearchArgsModel = /** @class */ (function () {
    function GridFilterSearchArgsModel(fieldName, fieldValue) {
        this.fieldName = fieldName;
        this.fieldValue = fieldValue;
    }
    return GridFilterSearchArgsModel;
}());
exports.GridFilterSearchArgsModel = GridFilterSearchArgsModel;
// These properties are used when searching for item serial numbers in Scanner Client
// Set when searching for item serial numbers in Scanner Client. Used when MainTable = TSerial.
var ExtraItemSerialSearchArgsModel = /** @class */ (function () {
    function ExtraItemSerialSearchArgsModel() {
    }
    return ExtraItemSerialSearchArgsModel;
}());
exports.ExtraItemSerialSearchArgsModel = ExtraItemSerialSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.CustomerUnits"
var ExtraCustomerUnitsSearchArgsModel = /** @class */ (function () {
    function ExtraCustomerUnitsSearchArgsModel() {
    }
    return ExtraCustomerUnitsSearchArgsModel;
}());
exports.ExtraCustomerUnitsSearchArgsModel = ExtraCustomerUnitsSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.ProductsBasedOnItem".
var ExtraProductsBasedOnItemSearchArgsModel = /** @class */ (function () {
    function ExtraProductsBasedOnItemSearchArgsModel() {
    }
    return ExtraProductsBasedOnItemSearchArgsModel;
}());
exports.ExtraProductsBasedOnItemSearchArgsModel = ExtraProductsBasedOnItemSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.WebProductsBasedOnItem".
var ExtraWebProductsBasedOnItemSearchArgsModel = /** @class */ (function (_super) {
    tslib_1.__extends(ExtraWebProductsBasedOnItemSearchArgsModel, _super);
    function ExtraWebProductsBasedOnItemSearchArgsModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExtraWebProductsBasedOnItemSearchArgsModel;
}(ExtraProductsBasedOnItemSearchArgsModel));
exports.ExtraWebProductsBasedOnItemSearchArgsModel = ExtraWebProductsBasedOnItemSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.WebBaseProdItem" and "SpecialSearchType.WebProdWithSerials".
var ExtraWebArticlesSearchArgsModel = /** @class */ (function () {
    function ExtraWebArticlesSearchArgsModel() {
    }
    return ExtraWebArticlesSearchArgsModel;
}());
exports.ExtraWebArticlesSearchArgsModel = ExtraWebArticlesSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.IncubatorLog".
var ExtraIncubatorLogSearchArgsModel = /** @class */ (function () {
    function ExtraIncubatorLogSearchArgsModel() {
    }
    return ExtraIncubatorLogSearchArgsModel;
}());
exports.ExtraIncubatorLogSearchArgsModel = ExtraIncubatorLogSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.PrefLists"
var ExtraPrefListsSearchArgsModel = /** @class */ (function () {
    function ExtraPrefListsSearchArgsModel() {
    }
    return ExtraPrefListsSearchArgsModel;
}());
exports.ExtraPrefListsSearchArgsModel = ExtraPrefListsSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.WebScheduledOperation"
var ExtraWebScheduledOperationSearchArgsModel = /** @class */ (function () {
    function ExtraWebScheduledOperationSearchArgsModel() {
    }
    return ExtraWebScheduledOperationSearchArgsModel;
}());
exports.ExtraWebScheduledOperationSearchArgsModel = ExtraWebScheduledOperationSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.WebOperationUnits"
var ExtraWebOperationUnitsSearchArgsModel = /** @class */ (function () {
    function ExtraWebOperationUnitsSearchArgsModel() {
    }
    return ExtraWebOperationUnitsSearchArgsModel;
}());
exports.ExtraWebOperationUnitsSearchArgsModel = ExtraWebOperationUnitsSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.WebOperationProducts"
var ExtraWebOperationProductsSearchArgsModel = /** @class */ (function () {
    function ExtraWebOperationProductsSearchArgsModel() {
    }
    return ExtraWebOperationProductsSearchArgsModel;
}());
exports.ExtraWebOperationProductsSearchArgsModel = ExtraWebOperationProductsSearchArgsModel;
// Extra parameter(s) for "SpecialSearchType.WebOperationItems"
var ExtraWebOperationItemsSearchArgsModel = /** @class */ (function () {
    function ExtraWebOperationItemsSearchArgsModel() {
    }
    return ExtraWebOperationItemsSearchArgsModel;
}());
exports.ExtraWebOperationItemsSearchArgsModel = ExtraWebOperationItemsSearchArgsModel;
var SearchArgsModel = /** @class */ (function (_super) {
    tslib_1.__extends(SearchArgsModel, _super);
    function SearchArgsModel(mainTable, gridStructure) {
        var _this = _super.call(this, mainTable, gridStructure) || this;
        // We are using this "FilterIsSet = true" for case when we set UI filter dirrectly in "WhereExtra" property.
        _this.filterIsSet = false;
        _this.fuzzySearchArgs = new FuzzySearchArgsModel();
        _this.gridFilterArgsList = new Array();
        _this.extraItemSerialArgs = new ExtraItemSerialSearchArgsModel();
        _this.extraCustomerUnitsArgs = new ExtraCustomerUnitsSearchArgsModel();
        _this.extraProductsBasedOnItemArgs = new ExtraProductsBasedOnItemSearchArgsModel();
        _this.extraWebProductsBasedOnItemArgs = new ExtraWebProductsBasedOnItemSearchArgsModel();
        _this.extraWebArticlesArgs = new ExtraWebArticlesSearchArgsModel();
        _this.extraIncubatorLogArgs = new ExtraIncubatorLogSearchArgsModel();
        _this.extraPrefListsArgs = new ExtraPrefListsSearchArgsModel();
        _this.extraWebScheduledOperationArgs = new ExtraWebScheduledOperationSearchArgsModel();
        _this.extraWebOperationUnitsArgs = new ExtraWebOperationUnitsSearchArgsModel();
        _this.extraWebOperationProductsArgs = new ExtraWebOperationProductsSearchArgsModel();
        _this.extraWebOperationItemsArgs = new ExtraWebOperationItemsSearchArgsModel();
        return _this;
    }
    return SearchArgsModel;
}(base_grid_args_model_1.BaseGridArgsModel));
exports.SearchArgsModel = SearchArgsModel;
