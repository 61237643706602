"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ServerSessionActionTypes;
(function (ServerSessionActionTypes) {
    ServerSessionActionTypes["InitializeClientId"] = "[ServerSession] Initialize Client Id";
    ServerSessionActionTypes["SetSessionEnded"] = "[ServerSession] Set Session Ended";
    ServerSessionActionTypes["SetReturnUrl"] = "[ServerSession] Set Return Url";
    ServerSessionActionTypes["LoadLicense"] = "[ServerSession] Load License";
    ServerSessionActionTypes["SetLicense"] = "[ServerSession] Set License";
    ServerSessionActionTypes["LoadServerDesktopOptions"] = "[ServerSession] Load Server Desktop Options";
    ServerSessionActionTypes["SetServerDesktopOptions"] = "[ServerSession] Set Server Desktop Options";
    ServerSessionActionTypes["LoadClientConfiguration"] = "[ServerSession] Load Client Configuration";
    ServerSessionActionTypes["SetClientConfiguration"] = "[ServerSession] Set Client Configuration";
})(ServerSessionActionTypes = exports.ServerSessionActionTypes || (exports.ServerSessionActionTypes = {}));
var InitializeClientId = /** @class */ (function () {
    function InitializeClientId() {
        this.type = ServerSessionActionTypes.InitializeClientId;
    }
    return InitializeClientId;
}());
exports.InitializeClientId = InitializeClientId;
var SetSessionEnded = /** @class */ (function () {
    function SetSessionEnded(payload) {
        this.payload = payload;
        this.type = ServerSessionActionTypes.SetSessionEnded;
    }
    return SetSessionEnded;
}());
exports.SetSessionEnded = SetSessionEnded;
var SetReturnUrl = /** @class */ (function () {
    function SetReturnUrl(payload) {
        this.payload = payload;
        this.type = ServerSessionActionTypes.SetReturnUrl;
    }
    return SetReturnUrl;
}());
exports.SetReturnUrl = SetReturnUrl;
var LoadLicense = /** @class */ (function () {
    function LoadLicense() {
        this.type = ServerSessionActionTypes.LoadLicense;
    }
    return LoadLicense;
}());
exports.LoadLicense = LoadLicense;
var SetLicense = /** @class */ (function () {
    function SetLicense(payload) {
        this.payload = payload;
        this.type = ServerSessionActionTypes.SetLicense;
    }
    return SetLicense;
}());
exports.SetLicense = SetLicense;
var LoadServerDesktopOptions = /** @class */ (function () {
    function LoadServerDesktopOptions() {
        this.type = ServerSessionActionTypes.LoadServerDesktopOptions;
    }
    return LoadServerDesktopOptions;
}());
exports.LoadServerDesktopOptions = LoadServerDesktopOptions;
var SetServerDesktopOptions = /** @class */ (function () {
    function SetServerDesktopOptions(payload) {
        this.payload = payload;
        this.type = ServerSessionActionTypes.SetServerDesktopOptions;
    }
    return SetServerDesktopOptions;
}());
exports.SetServerDesktopOptions = SetServerDesktopOptions;
var LoadClientConfiguration = /** @class */ (function () {
    function LoadClientConfiguration() {
        this.type = ServerSessionActionTypes.LoadClientConfiguration;
    }
    return LoadClientConfiguration;
}());
exports.LoadClientConfiguration = LoadClientConfiguration;
var SetClientConfiguration = /** @class */ (function () {
    function SetClientConfiguration(payload) {
        this.payload = payload;
        this.type = ServerSessionActionTypes.SetClientConfiguration;
    }
    return SetClientConfiguration;
}());
exports.SetClientConfiguration = SetClientConfiguration;
