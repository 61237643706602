import { Action } from '@ngrx/store';

import { InfoOverview, InfoOverviewData } from '@app/dashboard/models/info-overview.model';
import { ProdOverviewData } from '@app/dashboard/models/po-data.model';

export enum InfoOverviewActionTypes {
  LoadIOConfigurations = '[InfoOverview] Load IO configurations',
  LoadIOConfigurationsSuccess = '[InfoOverview] Load IO configurations (success)',
  LoadIOConfigurationsError = '[InfoOverview] Load IO configurations (error)',
  GetIOConfigurationById = '[InfoOverview] Get IO configuration by id',
  GetIOConfigurationByIdSuccess = '[InfoOverview] Get IO configuration by id (success)',
  SetIOData = '[InfoOverview] Set IO data',
  SetIODataSuccess = '[InfoOverview] Set IO data (success)',
  SetConnectedToHub = '[InfoOverview] Set connected to hub',
  SetConnectedToJSrv = '[InfoOverview] Set connected to job server'
}

// info-overview configurations
export class LoadIOConfigurations implements Action {
  readonly type = InfoOverviewActionTypes.LoadIOConfigurations;
}

export class LoadIOConfigurationsSuccess implements Action {
  readonly type = InfoOverviewActionTypes.LoadIOConfigurationsSuccess;

  constructor(public payload: InfoOverview[]) { }
}

export class LoadIOConfigurationsError implements Action {
  readonly type = InfoOverviewActionTypes.LoadIOConfigurationsError;
}

export class GetIOConfigurationById implements Action {
  readonly type = InfoOverviewActionTypes.GetIOConfigurationById;

  constructor(public payload: number) { }
}

export class GetIOConfigurationByIdSuccess implements Action {
  readonly type = InfoOverviewActionTypes.GetIOConfigurationByIdSuccess;

  constructor(public payload: InfoOverview) { }
}

// info-overview data
export class SetIOData implements Action {
  readonly type = InfoOverviewActionTypes.SetIOData;

  constructor(public payload: InfoOverviewData) { }
}

export class SetIODataSuccess implements Action {
  readonly type = InfoOverviewActionTypes.SetIODataSuccess;

  constructor(public payload: InfoOverviewData) { }
}

export class SetConnectedToHub implements Action {
  readonly type = InfoOverviewActionTypes.SetConnectedToHub;

  constructor(public payload: boolean) { }
}

export class SetConnectedToJSrv implements Action {
  readonly type = InfoOverviewActionTypes.SetConnectedToJSrv;

  constructor(public ioDataType: typeof ProdOverviewData, public payload: boolean) { }
}

export type InfoOverviewActions =
  LoadIOConfigurations | LoadIOConfigurationsSuccess | LoadIOConfigurationsError |
  GetIOConfigurationById | GetIOConfigurationByIdSuccess |
  SetIOData | SetIODataSuccess |
  SetConnectedToHub | SetConnectedToJSrv;
