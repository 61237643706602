import * as orderActions from './order.actions';

import { OrderModel } from '../models/order.model';
import { WebOrderViewModel } from '../models/web-order-view.model';

export interface OrderState {
  customerOpenOrders: OrderModel[];
  userLastOpenOrder: WebOrderViewModel;
}

export const initialOrderState: OrderState = {
  customerOpenOrders: null,
  userLastOpenOrder: null
};

export function orderReducer(state = initialOrderState, action: orderActions.OrderActions): OrderState {
  switch (action.type) {
    case orderActions.OrderActionTypes.LoadCustomerOpenOrders:
      return handleLoadCustomerOpenOrders(state, action);
    case orderActions.OrderActionTypes.SetCustomerOpenOrders:
      return handleSetCustomerOpenOrders(state, action);
    case orderActions.OrderActionTypes.LoadUserLastOpenOrder:
      return handleLoadUserLastOpenOrder(state, action);
    case orderActions.OrderActionTypes.SetUserLastOpenOrder:
      return handleSetUserLastOpenOrder(state, action);
    default:
      return state;
  }
}

function handleLoadCustomerOpenOrders(state: OrderState, action: orderActions.LoadCustomerOpenOrders): OrderState {
  return state;
}

function handleSetCustomerOpenOrders(state: OrderState, action: orderActions.SetCustomerOpenOrders): OrderState {
  return Object.assign({}, state, { customerOpenOrders: action.payload });
}

function handleLoadUserLastOpenOrder(state: OrderState, action: orderActions.LoadUserLastOpenOrder): OrderState {
  return state;
}

function handleSetUserLastOpenOrder(state: OrderState, action: orderActions.SetUserLastOpenOrder): OrderState {
  return Object.assign({}, state, { userLastOpenOrder: action.payload });
}
