"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var customer_actions_1 = require("./customer.actions");
var CustomerEffects = /** @class */ (function () {
    function CustomerEffects(actions$, customerService) {
        var _this = this;
        this.actions$ = actions$;
        this.customerService = customerService;
        this.LoadUserCustomers$ = this.actions$
            .pipe(effects_1.ofType(customer_actions_1.CustomerActionTypes.LoadUserCustomers), operators_1.mergeMap(function (act) { return _this.customerService.getUserCustomers()
            .pipe(operators_1.map(function (customers) { return ({ type: customer_actions_1.CustomerActionTypes.SetUserCustomers, payload: customers }); }), operators_1.catchError(function () { return rxjs_1.of({ type: customer_actions_1.CustomerActionTypes.SetUserCustomers }); })); }));
        this.LoadSiteCustomers$ = this.actions$
            .pipe(effects_1.ofType(customer_actions_1.CustomerActionTypes.LoadSiteCustomers), operators_1.mergeMap(function (act) { return _this.customerService.getSiteCustomers(act.payload)
            .pipe(operators_1.map(function (customers) { return ({ type: customer_actions_1.CustomerActionTypes.SetSiteCustomers, payload: customers }); }), operators_1.catchError(function () { return rxjs_1.of({ type: customer_actions_1.CustomerActionTypes.SetSiteCustomers }); })); }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CustomerEffects.prototype, "LoadUserCustomers$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CustomerEffects.prototype, "LoadSiteCustomers$", void 0);
    return CustomerEffects;
}());
exports.CustomerEffects = CustomerEffects;
