import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_HUB_PATHS } from '@core/data-layer/shared/models/api-hub.constants';
import { TableInfoServiceInterface, DesktopDataComponentStateArgsInterface } from './table-info.service.interface';
import { GridStructureInterface, GridColumnConfigurationInterface } from '../models/grid-structure.interface';
import { TableInfoHelperService } from './table-info-helper.service';
import { TD_MAX_LEVEL_OF_NESTED_TABLES } from '@core/data-layer/shared/models/td.constants';
import { GridStructureViewInterface } from '../models/grid-structure-view.interface';
import { GridConfigurationInterface } from '../models/grid-configuration.interface';

@Injectable()
export class HttpTableInfoService implements TableInfoServiceInterface {

  constructor(
    private http: HttpClient,
    private tableInfoHelperService: TableInfoHelperService
  ) { }

  desktopDataUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.desktopDataCtrl;
  }

  scrollingDataUrl(): string {
    return API_HUB_PATHS.apiUrl + API_HUB_PATHS.scrollingDataUrl;
  }

  // save component desktop state
  saveComponentState(identifier: string, state: string): Observable<null> {
    const params: DesktopDataComponentStateArgsInterface = { identifier, desktopState: state };
    return this.http.post<null>(this.desktopDataUrl() + API_HUB_PATHS.saveComponentState, params);
  }

  // load component desktop states
  getComponentState(identifier: string): Observable<string> {
    return this.http.get<string>(this.desktopDataUrl() + API_HUB_PATHS.getComponentState + `(${identifier})`);
  }

  // load grid structure: all relative tables and columns
  // all possible identifiers are in TD_DESKTOP_IDENTIFIERS from @core\data-layer\shared\td.constants.ts
  loadGridStructure(identifier: string, mainTable: string): Observable<GridStructureViewInterface> {
    const gridConfigurationsData = localStorage.getItem(identifier);
    const gridConfigurations: GridConfigurationInterface = gridConfigurationsData ? JSON.parse(gridConfigurationsData) : null;
    const params: GridColumnConfigurationInterface[] = [];
    if (gridConfigurations && gridConfigurations.columns) {
      gridConfigurations.columns.forEach(column => {
        if (column.dataField) {
          params.push(column);
        }
      });
    }
    return this.http.post<GridStructureInterface>(this.desktopDataUrl() + API_HUB_PATHS.getGridStructure + `(${mainTable})?identifier=${identifier}&maxLevelOfNestedTables=${TD_MAX_LEVEL_OF_NESTED_TABLES}`, params)
      .pipe(
        map(gridStructure => {
          const gridStructureView: GridStructureViewInterface = {
            tables: this.tableInfoHelperService.convertGridTablesToViewModel(gridStructure.tables),
            columnsConfigurations: this.tableInfoHelperService.convertGridColumnsConfigurationsToViewModel(gridStructure.columnsConfigurations)
          };
          return gridStructureView;
        })
      );
  }

  findPreviousNextKeyId(modelName: string, keyId: number, isPrevious: boolean): Observable<number> {
    const params = new HttpParams().set("isPrevious", isPrevious.toString())
    return this.http.get<number>(`${this.scrollingDataUrl()}(${modelName})${API_HUB_PATHS.findPreviousNextKeyId}(${keyId})`, { params });
  }

  findFirstLastKeyId(modelName: string, isLast: boolean): Observable<number> {
    const params = new HttpParams().set("isLast", isLast.toString())
    return this.http.get<number>(`${this.scrollingDataUrl()}(${modelName})${API_HUB_PATHS.findFirstLastKeyId}`, { params });
  }

}
