import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TD_MIN_DATE_VALUE } from '../data-layer/shared/models/td.constants';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private datePipe: DatePipe;
  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.datePipe = new DatePipe(this.locale);
  }

  // IMPORTANT: You need initialize properties in child classes, to generate constructor code
  fillFromJSON(obj: any, jsonObj: any) {
    // get all property names from current object
    const ownPropNames = Object.getOwnPropertyNames(obj).toString();
    const getOwnPropName = (propName) => {
      const match = new RegExp('\\b' + propName + '\\b', 'i').exec(ownPropNames);
      return match && match.length > 0 ? match[0] : '';
    };
    for (const jsonPropName in jsonObj) {
      if (jsonObj.hasOwnProperty(jsonPropName)) {
        const propName = getOwnPropName(jsonPropName);
        if (propName !== '') {
          obj[propName] = jsonObj[jsonPropName];
        }
      }
    }
  }

  tryParseInt(data: any, defaultValue: number): number {
    let returnValue = defaultValue;
    if (data) {
      if (data.length > 0) {
        if (!isNaN(data)) {
          returnValue = parseInt(data, 10);
        }
      }
    }
    return returnValue;
  }

  objIsEmpty(obj: object): boolean {
    return (!obj) || (Object.keys(obj).length === 0); // returns 0 if empty or an integer > 0 if non-empty
  }

  valueIsEmpty(value: any): boolean {
    return (value === undefined) || (value === null) || (value === '');
  }

  getTextWidth = function (text: string, font: string, minWidth: number, maxWidth: number, extraWidth: number): number {
    // if given, use cached canvas for better performance
    // else, create new canvas
    const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);

    let calcWidth = metrics.width + extraWidth;
    if (calcWidth < minWidth) {
      calcWidth = minWidth;
    }
    else if (calcWidth > maxWidth) {
      calcWidth = maxWidth;
    }
    return calcWidth;
  };

  getFormatedDateTime(date: Date): string {
    if (date) {
      return this.datePipe.transform(date, 'short');
    }
    else {
      return '';
    }
  }

  convertValueToDate(value: string | number | Date): Date {
    return new Date(value);
  }

  addElClass(elementId: string, className: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.classList.add(className);
    }
  }

  removeElClass(elementId: string, className: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.classList.remove(className);
    }
  }

  getElOuterHeight(elementId: string): number {
    if (document.getElementById(elementId)) {
      return document.getElementById(elementId).offsetHeight;
    }
    else {
      return 0;
    }
  }

  getElementsHeightSum(elementIds: string[]): number {
    return elementIds.map((value) => this.getElOuterHeight(value)).reduce((heightSum, value) => heightSum + value, 0);
  }

  setElOuterHeight(elementId: string, value: string) {
    if (document.getElementById(elementId)) {
      document.getElementById(elementId).style.height = value;
    }
  }

  setElOverflowX(value: string) {
    document.documentElement.style.overflowX = value;
  }

  setElOverflowY(value: string) {
    document.documentElement.style.overflowY = value;
  }

  copyPropertyValues(objFrom: object, objTo: object, forceAdd?: boolean): void {
    for (const [key, value] of Object.entries(objFrom)) {
      if (objTo.hasOwnProperty(key) || forceAdd) {
        objTo[key] = value;
      }
    }
  }

  compareObjects(obj1: object, obj2: object): boolean {
    for (const propName in obj1) {
      if ({}.hasOwnProperty.call(obj1, propName)) {
        if (obj1[propName] !== obj2[propName]) {
          return false;
        }
      }
    }
    for (const propName in obj2) {
      if ({}.hasOwnProperty.call(obj2, propName)) {
        if (obj1[propName] !== obj2[propName]) {
          return false;
        }
      }
    }
    return true;
  }

  applyUTCTimeZone(date: Date): Date {
    if (date) {
      const nowUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
        date.getHours(), date.getMinutes(), date.getSeconds());
      return date = new Date(nowUTC);
    }
    else {
      return null;
    }
  }

  highlightContent(sourceText: string, highlightText: string): string {
    if (sourceText && highlightText && (highlightText.length > 0)) {
      const highlightWords = highlightText.split(" ").filter(word => word !== "");
      let index = 0;
      const highlightStrings =
        highlightWords
          .map(word => {
            const highlightString = [sourceText.toLowerCase().indexOf(word.toLowerCase(), index), word.length];
            index = index + word.length;
            return highlightString;
          })
          .filter(word => { return word[0] >= 0; })
          .sort(word => { return word[0]; });
      if (!highlightStrings.length) {
        return sourceText;
      }
      else {
        // TODO: investigate how to apply "class" in "innerHTML"
        const span = '<b>'; //"<span class='highlight-search-text'>";
        const highlights = [];
        highlightStrings.forEach((str, index) => {
          if (index === 0) {
            highlights.push(sourceText.slice(0, str[0]));
          }
          highlights.push(span);
          highlights.push(sourceText.slice(str[0], str[0] + str[1]));
          highlights.push('</b>'/*'</span>'*/);
          if (index === highlightStrings.length - 1) {
            highlights.push(sourceText.slice(str[0] + str[1]));
          }
          else {
            highlights.push(sourceText.slice(str[0] + str[1], highlightStrings[index + 1][0]));
          }
        });
        return highlights.join('');
      }
    }
    else {
      return sourceText;
    }
  }

  extractQueryParamValueFromString(string: string, queryParam: string): string {
    let result: string = null;
    if (string.includes('=') && string.includes(queryParam)) {
      const queryParamString = string.split('?')[1];
      const queryParams =  queryParamString.split('&');
      const targetParam = queryParams.find(entry => entry.startsWith(queryParam));
      result = targetParam.split('=')[1];
    }
    return result;
  }

  dateRangeAllowed(date: Date): boolean{
    return date >= TD_MIN_DATE_VALUE;
  }
}

// to guarantee type safety of "stringly typed" property names in code
export const nameOf = <T>() => (name: keyof T) => name;
