"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TdLoadPanelModel = /** @class */ (function () {
    function TdLoadPanelModel(visible, message) {
        if (visible === void 0) { visible = false; }
        if (message === void 0) { message = ''; }
        this.visible = visible;
        this.message = message;
    }
    return TdLoadPanelModel;
}());
exports.TdLoadPanelModel = TdLoadPanelModel;
