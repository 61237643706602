"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var HttpLicenseService = /** @class */ (function () {
    function HttpLicenseService(http) {
        this.http = http;
    }
    HttpLicenseService.prototype.licenseUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.licenseCtrl;
    };
    HttpLicenseService.prototype.getLicense = function () {
        return this.http.get(this.licenseUrl());
    };
    HttpLicenseService.prototype.getLicenseBindings = function () {
        return this.http.get(this.licenseUrl() + api_hub_constants_1.API_HUB_PATHS.bindings);
    };
    return HttpLicenseService;
}());
exports.HttpLicenseService = HttpLicenseService;
