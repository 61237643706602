import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AppState } from '@core/data-layer/app.state';
import { selectLicense } from '@core/data-layer/server-session/store/server-session.selectors';
import { LanguageService } from '@core/language/services/language.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageInitializer {

  licenseSubscription: Subscription;

  constructor(
    private store: Store<AppState>
  ) { }

  initializeLanguage(languageService: LanguageService): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.licenseSubscription = this.store.pipe(select(selectLicense)).subscribe(async license => {
        if (license) {
          if (this.licenseSubscription) {
            this.licenseSubscription.unsubscribe();
          }
          return languageService.initializeLanguage(license.languages, license.defaultLanguage.culture, resolve);
        }
      });
    });
  }

}
