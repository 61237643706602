"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var platform_browser_1 = require("@angular/platform-browser");
var session_helper_service_1 = require("@shared/services/session-helper.service");
var app_injector_service_1 = require("@app/app-injector.service");
var utility_service_1 = require("@core/utility/utility.service");
var grid_column_helper_service_1 = require("@shared/services/grid-column-helper.service");
var app_constants_1 = require("@shared/models/app.constants");
var td_popup_service_1 = require("../td-popup/services/td-popup.service");
var td_load_panel_service_1 = require("../td-load-panel/services/td-load-panel.service");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var grid_data_helper_service_1 = require("@shared/services/grid-data-helper.service");
var BaseComponent = /** @class */ (function () {
    function BaseComponent() {
        var _this = this;
        this.tableTypes = td_enumerations_1.TableTypes;
        this.mediaWrapperWidthConst = app_constants_1.MEDIA_WRAPPER_WIDTH;
        this.transformDateAsShortDate = function (value) {
            var formattedDate = _this.transformDate(value, 'shortDate');
            return formattedDate;
        };
        this.transformDateAsShort = function (value) {
            var formattedDate = _this.transformDate(value, 'short');
            return formattedDate;
        };
        // Manually retrieve the dependencies from the injector
        // so that constructor has no dependencies that must be passed in from child
        var injector = app_injector_service_1.AppInjector.getInjector();
        this.datePipe = injector.get(common_1.DatePipe);
        this.router = injector.get(router_1.Router);
        this.translateService = injector.get(core_2.TranslateService);
        this.sessionHelperService = injector.get(session_helper_service_1.SessionHelperService);
        this.gridColumnHelperService = injector.get(grid_column_helper_service_1.GridColumnHelperService);
        this.gridDataHelperService = injector.get(grid_data_helper_service_1.GridDataHelperService);
        this.utilityService = injector.get(utility_service_1.UtilityService);
        this.popupService = injector.get(td_popup_service_1.TdPopupService);
        this.loadPanelService = injector.get(td_load_panel_service_1.TdLoadPanelService);
        this.mediaService = injector.get('MEDIA_SERVICE');
        this.tableInfoService = injector.get('TABLE_INFO_SERVICE');
        this.factoryService = injector.get('FACTORY_SERVICE');
        this.titleService = injector.get(platform_browser_1.Title);
    }
    BaseComponent.prototype.ngOnInit = function () {
        this.titleService.setTitle(this.translateInstant(this.getTitleIdentifier()));
    };
    BaseComponent.prototype.getTitleIdentifier = function () {
        return '';
    };
    BaseComponent.prototype.transformDate = function (value, format) {
        if (format === void 0) { format = 'medium'; }
        return this.datePipe.transform(value, format);
    };
    Object.defineProperty(BaseComponent.prototype, "formatNumbersOnly", {
        get: function () {
            return '#0;#0';
        },
        enumerable: true,
        configurable: true
    });
    BaseComponent.prototype.translateInstant = function (value) {
        return this.translateService.instant(value);
    };
    BaseComponent.prototype.navigateTo = function (url) {
        this.router.navigate([url]);
    };
    Object.defineProperty(BaseComponent.prototype, "dataChanged", {
        get: function () {
            return this.dataModified;
        },
        set: function (state) {
            this.dataModified = state;
            this.onSetDataChanged(state);
        },
        enumerable: true,
        configurable: true
    });
    BaseComponent.prototype.onSetDataChanged = function (state) { };
    BaseComponent.prototype.valueUpdated = function (e) {
        if ((e.event !== undefined) && !this.dataChanged) {
            this.dataChanged = true;
        }
    };
    BaseComponent.prototype.getPictureKeyId = function (keyId, tableType, mediaSeries, productKeyId, itemKeyId) {
        var _this = this;
        var $subject = new rxjs_1.Subject();
        var mediaSeriesValue = mediaSeries ? mediaSeries : null;
        if (!this.sessionHelperService.canViewMedia()) {
            $subject.next(null);
            return $subject.asObservable();
        }
        this.mediaService.loadMediaInfoList(tableType, mediaSeriesValue, keyId).subscribe(function (response) {
            if (response.length === 0) {
                if (tableType === td_enumerations_1.TableTypes.Unit) {
                    _this.mediaService.loadMediaInfoList(_this.tableTypes.Product, mediaSeriesValue, productKeyId).subscribe(function (productResponse) {
                        if (productResponse.length === 0) {
                            _this.mediaService.loadMediaInfoList(_this.tableTypes.Item, mediaSeriesValue, itemKeyId).subscribe(function (itemResponse) {
                                if (itemResponse.length === 0) {
                                    $subject.next(null);
                                }
                                else {
                                    $subject.next(itemResponse[0].keyId);
                                    $subject.complete();
                                }
                            });
                        }
                        else {
                            if (productResponse.length === 0) {
                                $subject.next(null);
                            }
                            else {
                                $subject.next(productResponse[0].keyId);
                                $subject.complete();
                            }
                        }
                    });
                }
                else if (tableType === td_enumerations_1.TableTypes.Product) {
                    _this.mediaService.loadMediaInfoList(_this.tableTypes.Item, mediaSeriesValue, itemKeyId).subscribe(function (itemResponse) {
                        if (itemResponse.length === 0) {
                            $subject.next(null);
                        }
                        else {
                            $subject.next(itemResponse[0].keyId);
                            $subject.complete();
                        }
                    });
                }
            }
            else {
                $subject.next(response[0].keyId);
                $subject.complete();
            }
        });
        return $subject.asObservable();
    };
    BaseComponent.prototype.setMultipleMedia = function (event) {
        this.mediaWrapperWidth = event ? app_constants_1.MEDIA_WRAPPER_WIDTH.Multiple : app_constants_1.MEDIA_WRAPPER_WIDTH.Single;
    };
    return BaseComponent;
}());
exports.BaseComponent = BaseComponent;
