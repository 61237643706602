"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var app_injector_service_1 = require("./app-injector.service");
var app_state_1 = require("./core/data-layer/app.state");
var ɵ0 = app_state_1.metaReducerFactory;
exports.ɵ0 = ɵ0;
var AppModule = /** @class */ (function () {
    function AppModule(injector) {
        app_injector_service_1.AppInjector.setInjector(injector);
    }
    return AppModule;
}());
exports.AppModule = AppModule;
