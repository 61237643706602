<div id="serialContainer" class="row">
  <div class="col-sm-12 d-flex flex-column justify-content-between">
    <div>
      <div class="row">
        <div class="col-sm-12">
          <label>
            {{ 'appCommon.productSerialNo' | translate }}:
            {{ serialInfo.serialNo }}
          </label>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-sm-12">
          {{ 'appCommon.unit' | translate }}:
          <ng-container *ngIf="serialInfo.unit > 0">
            <a [routerLink]="" (click)="navigateToUnit()">{{serialInfo.unit}}</a>
          </ng-container>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-sm-12">
          {{ 'appCommon.location' | translate }}:
          {{ serialInfo.location }}
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-sm-12">
          {{ 'appCommon.placement' | translate }}:
          {{ serialInfo.placement }}
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-sm-12">
          {{ 'appCommon.remark' | translate }}:
          <dx-text-area [readOnly]="true"
                        [value]="serialInfo.remark"
                        [height]="100">
          </dx-text-area>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-sm-12">
        <dx-button class="td-button success-button float-right" (onClick)="cancel()">
          {{ 'button.ok' | translate }}
        </dx-button>
      </div>
    </div>
  </div>
</div>
