import { Action } from '@ngrx/store';

import { OrderModel } from '../models/order.model';
import { WebOrderViewModel } from '../models/web-order-view.model';

export enum OrderActionTypes {
  LoadCustomerOpenOrders = '[Order] Load Customer Open Orders',
  SetCustomerOpenOrders = '[Order] Set Customer Open Orders',
  LoadUserLastOpenOrder = '[Order] Load User Last Open Order',
  SetUserLastOpenOrder = '[Order] Set User Last Open Order'
}

export class LoadCustomerOpenOrders implements Action {
  readonly type = OrderActionTypes.LoadCustomerOpenOrders;

  // "payload" keeps Customer Key Id
  constructor(public payload: number) { }
}

export class SetCustomerOpenOrders implements Action {
  readonly type = OrderActionTypes.SetCustomerOpenOrders;

  constructor(public payload: OrderModel[]) { }
}

export class LoadUserLastOpenOrder implements Action {
  readonly type = OrderActionTypes.LoadUserLastOpenOrder;

  constructor(public orderKeyId: number, public customerKeyId: number) { }
}

export class SetUserLastOpenOrder implements Action {
  readonly type = OrderActionTypes.SetUserLastOpenOrder;

  // "payload" keeps OrderView model
  constructor(public payload: WebOrderViewModel) { }
}

export type OrderActions = LoadCustomerOpenOrders | SetCustomerOpenOrders | LoadUserLastOpenOrder | SetUserLastOpenOrder;
