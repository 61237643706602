import { Injectable } from '@angular/core';

import { UtilityService } from '@core/utility/utility.service';
import { GridColumnConfigurationViewInterface } from '@core/table-info/models/grid-structure-view.interface';
import { TD_ACTION_FIELDS, TD_PRICE_FIELD_NAMES } from '@core/data-layer/shared/models/td.constants';
import { TranslateService } from '@ngx-translate/core';
import { SessionHelperService } from './session-helper.service';
import { PropertyTypes } from '@core/table-info/models/grid-structure.interface';
import { WebDisplayPriceMode } from '@core/data-layer/shared/models/td.enumerations';
import { GRID_SIZES, GRID_TEMPLATES } from '../models/app.constants';
import { GridOptionsInterface } from '../models/grid-data-request.interface';

@Injectable({
  providedIn: 'root'
})
export class GridColumnHelperService {

  constructor(
    private utilityService: UtilityService,
    private translateService: TranslateService,
    private sessionHelperService: SessionHelperService
  ) {}

  // TODO: in future should be able to include "calculated" fields to be send to BackEnd
  isSelectedColumn(columnConfig: GridColumnConfigurationViewInterface): boolean {
    return columnConfig.required || (columnConfig.visible && !columnConfig.calculated && (columnConfig.dataType !== PropertyTypes.typeUndefined) && (columnConfig.dataType !== PropertyTypes.typeObject));
  }

  addCustomColumn(columnsConfigurations: GridColumnConfigurationViewInterface[], newColumnConfig: GridColumnConfigurationViewInterface) {
    if (columnsConfigurations) {
      const columnConfigIndex = columnsConfigurations.findIndex(column => column.dataField === newColumnConfig.dataField);
      if (columnConfigIndex === -1) {
        if (newColumnConfig.visibleIndex || newColumnConfig.visibleIndex === 0) {
          // adjust column visible index by [+1] for all the elements after one that are inserting now
          columnsConfigurations.forEach(entry => {
            if (entry.visibleIndex >= newColumnConfig.visibleIndex) {
              entry.visibleIndex++;
            }
          });
          // INSERT at the concrete POSITION in array
          columnsConfigurations.splice(newColumnConfig.visibleIndex, 0, newColumnConfig);
        }
        else {
          // adjust column visible index to be the last one
          if (newColumnConfig.visible) {
            newColumnConfig.visibleIndex = columnsConfigurations.length;
          }
          // INSERT at the END of array
          columnsConfigurations.push(newColumnConfig);
        }
      }
      else {
        // UPDATE EXISTING element in array
        columnsConfigurations[columnConfigIndex] = newColumnConfig;
        // re-sort all elements in array by visible index
        columnsConfigurations.sort((a, b) => a.visibleIndex - b.visibleIndex);
      }
    }
  }

  removeColumn(columnsConfigurations: GridColumnConfigurationViewInterface[], columnName: string) {
    if (columnsConfigurations) {
      const columnConfigIndex = columnsConfigurations.findIndex(column => column.dataField === columnName);
      if (columnConfigIndex > -1) {
        columnsConfigurations.splice(columnConfigIndex, 1);
      }
    }
  }

  setColumnProperty(columnsConfigurations: GridColumnConfigurationViewInterface[], columnName: string, property: object) {
    const columnConfig = this.getColumnConfigurationByName(columnsConfigurations, columnName);
    if (columnConfig) {
      this.utilityService.copyPropertyValues(property, columnConfig, true);
    }
  }

  setColumnDisplayName(columnsConfigurations: GridColumnConfigurationViewInterface[], columnName: string, displayName: string) {
    const columnConfig = this.getColumnConfigurationByName(columnsConfigurations, columnName);
    if (columnConfig) {
      columnConfig.displayName = displayName;
    }
  }

  private addActionColumn(columnsConfigurations: GridColumnConfigurationViewInterface[], dataField: string, displayName: string, width: string, visibleIndex?: number) {
    let actionColumn = this.getColumnConfigurationByName(columnsConfigurations, dataField);
    if (!actionColumn) {
      actionColumn = {
        dataField: dataField,
        dataType: PropertyTypes.typeUndefined,
        visible: true,
        visibleIndex: visibleIndex,
        displayName: displayName,
        width: width,
        cellTemplate: GRID_TEMPLATES.customTemplate,
        style: {
          alignment: 'center',
        }
      };
    }
    else {
      actionColumn.cellTemplate = GRID_TEMPLATES.customTemplate;
      actionColumn.displayName = displayName;
      actionColumn.dataType = PropertyTypes.typeUndefined;
    }
    this.addCustomColumn(columnsConfigurations, actionColumn);
  }

  // add custom "media" column
  addCustomMediaColumn(columnsConfigurations: GridColumnConfigurationViewInterface[]) {
    if (this.sessionHelperService.license.picture) {
      this.addActionColumn(columnsConfigurations, TD_ACTION_FIELDS.media, this.translateService.instant('calcColumns.media'), '60', 0);
    }
  }

  // add custom "fast track" column
  addCustomFastTrackColumn(columnsConfigurations: GridColumnConfigurationViewInterface[]) {
    if (this.sessionHelperService.showFastTrack()) {
      this.addActionColumn(columnsConfigurations, TD_ACTION_FIELDS.fastTrack, this.translateService.instant('calcColumns.fastTrack'), undefined, 0);
    }
  }

  // add custom "view substitutions" column
  addCustomSubstitutionsColumn(columnsConfigurations: GridColumnConfigurationViewInterface[], visibleIndex?: number) {

    // If substituteIcons column already exists, remove it
    if (columnsConfigurations.findIndex(entry => entry.dataField === TD_ACTION_FIELDS.substituteIcons) !== -1) {
      this.removeColumn(columnsConfigurations, TD_ACTION_FIELDS.substituteIcons);
    }
    // find the number column
    const numberColumnEntry = columnsConfigurations.find(entry => entry.displayName === 'Number');

    // used for setting visibleIndex of substituteIcons column
    let targetVisibleIndex: number;

    // set target visibleIndex for sub icons column to numberColumn index + 1
    targetVisibleIndex = numberColumnEntry ? numberColumnEntry.visibleIndex + 1 : visibleIndex;

    // add the column
    this.addActionColumn(columnsConfigurations, TD_ACTION_FIELDS.substituteIcons, '', '60', targetVisibleIndex);
    // re-sort all elements in array by visible index
    columnsConfigurations.sort((a, b) => a.visibleIndex - b.visibleIndex);
  }

  // add custom "manage substitutions" column
  addCustomManageSubstitutionsColumn(columnsConfigurations: GridColumnConfigurationViewInterface[]) {
    this.addActionColumn(columnsConfigurations, TD_ACTION_FIELDS.manageSubstitutions, '', '30', 0);
  }

  getColumnConfigurationByName(columnsConfigurations: GridColumnConfigurationViewInterface[], dataField: string): GridColumnConfigurationViewInterface {
    return columnsConfigurations ? columnsConfigurations.find(columnConfig => columnConfig.dataField === dataField) : null;
  }

  hidePriceColumn(dataField: string): boolean {
    let hidePrice = this.sessionHelperService.serverOptions.displayPriceMode === WebDisplayPriceMode.HideAll;
    if (hidePrice) {
      hidePrice = dataField.toLowerCase().includes(TD_PRICE_FIELD_NAMES.price) ||
        dataField.toLowerCase().includes(TD_PRICE_FIELD_NAMES.pricing) ||
        dataField.toLowerCase().includes(TD_PRICE_FIELD_NAMES.priGrKeyId) ||
        dataField.toLowerCase().includes(TD_PRICE_FIELD_NAMES.priGr);
    }
    return hidePrice;
  }

  getGridOptions(
    maxAllowedHeight: number,
    rowCount: number,
    excludeElementsIds: string[] = [],
    excludeAdditionalHeight = 0,
    setParentComponentHeight = false
  ): GridOptionsInterface {
    const borderHeight = 1;
    const excludingHeight = this.utilityService.getElementsHeightSum(excludeElementsIds) + excludeAdditionalHeight;
    const maxAllowedGridHeight = maxAllowedHeight ? (maxAllowedHeight - excludingHeight) : GRID_SIZES.maxPopupGridHeight;
    const maxGridHeight = (rowCount ? ((rowCount * GRID_SIZES.rowHeight) + (2 * borderHeight)) : GRID_SIZES.noArticlesHeight) +
      GRID_SIZES.gridHeaderHeight;
    let gridOptions: GridOptionsInterface;
    if (maxGridHeight > maxAllowedGridHeight) {
      gridOptions = {
        height: maxAllowedGridHeight,
        showScrollbar: 'always'
      };
    }
    else {
      gridOptions = {
        height: maxGridHeight,
        showScrollbar: 'never'
      };
    }
    if (setParentComponentHeight) {
      gridOptions.parentComponentHeight = gridOptions.height + excludingHeight;
    }
    return gridOptions;
  }

  doesColumnExist(columnConfiguration: GridColumnConfigurationViewInterface[], column: string) {
    return columnConfiguration.some(configuration =>
      configuration.dataField === column && configuration.visible);
  }
}
