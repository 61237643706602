"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var factory_actions_1 = require("./factory.actions");
var FactoryEffects = /** @class */ (function () {
    function FactoryEffects(actions$, factoryService) {
        var _this = this;
        this.actions$ = actions$;
        this.factoryService = factoryService;
        this.loadFactories$ = this.actions$
            .pipe(effects_1.ofType(factory_actions_1.FactoryActionTypes.LoadAllFactories), operators_1.mergeMap(function (act) {
            return _this.factoryService.getFactories()
                .pipe(operators_1.map(function (response) { return ({ type: factory_actions_1.FactoryActionTypes.SetAllFactories, payload: response }); }), operators_1.catchError(function () { return rxjs_1.of({ type: factory_actions_1.FactoryActionTypes.SetAllFactories }); }));
        }));
        this.loadFactoriesByCustomer$ = this.actions$
            .pipe(effects_1.ofType(factory_actions_1.FactoryActionTypes.LoadCustomerFactories), operators_1.mergeMap(function (act) {
            return _this.factoryService.getFactoriesByCustomer(act.payload)
                .pipe(operators_1.map(function (response) {
                if ((response) && (response.length > 0)) {
                    return { type: factory_actions_1.FactoryActionTypes.SetCustomerFactories, payload: response };
                }
                else {
                    return { type: factory_actions_1.FactoryActionTypes.SetCustomerFactoriesFromAll };
                }
            }), operators_1.catchError(function () { return rxjs_1.of({ type: factory_actions_1.FactoryActionTypes.SetCustomerFactoriesFromAll }); }));
        }));
        this.loadUserFactories$ = this.actions$
            .pipe(effects_1.ofType(factory_actions_1.FactoryActionTypes.LoadUserFactories), operators_1.mergeMap(function (act) {
            return _this.factoryService.getUserFactories()
                .pipe(operators_1.map(function (response) { return ({ type: factory_actions_1.FactoryActionTypes.SetUserFactories, payload: response }); }), operators_1.catchError(function () { return rxjs_1.of({ type: factory_actions_1.FactoryActionTypes.SetUserFactories }); }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FactoryEffects.prototype, "loadFactories$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FactoryEffects.prototype, "loadFactoriesByCustomer$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FactoryEffects.prototype, "loadUserFactories$", void 0);
    return FactoryEffects;
}());
exports.FactoryEffects = FactoryEffects;
