import { createSelector } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { ServerSessionState } from './server-session.reducer';

export const selectServerSessionState = (state: AppState) => state.serverSession;

export const selectClientId = createSelector(
  selectServerSessionState,
  (state: ServerSessionState) => state.clientId
);

export const selectSessionEnded = createSelector(
  selectServerSessionState,
  (state: ServerSessionState) => state.sessionEnded
);

export const selectReturnUrl = createSelector(
  selectServerSessionState,
  (state: ServerSessionState) => state.returnUrl
);

export const selectServerDesktopOptions = createSelector(
  selectServerSessionState,
  (state: ServerSessionState) => state.desktopOptions
);

export const selectClientConfiguration = createSelector(
  selectServerSessionState,
  (state: ServerSessionState) => state.clientConfiguration
);

export const selectLicense = createSelector(
  selectServerSessionState,
  (state: ServerSessionState) => state.license
);
