"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var custActions = require("./customer.actions");
exports.initialCustState = {
    userCustomers: null,
    currentCustomer: null,
    siteCustomers: null
};
function customerReducer(state, action) {
    if (state === void 0) { state = exports.initialCustState; }
    switch (action.type) {
        case custActions.CustomerActionTypes.LoadUserCustomers:
            return handleLoadUserCustomers(state, action);
        case custActions.CustomerActionTypes.SetUserCustomers:
            return handleSetUserCustomers(state, action);
        case custActions.CustomerActionTypes.SetCurrentCustomer:
            return handleSetCurrentCustomer(state, action);
        case custActions.CustomerActionTypes.LoadSiteCustomers:
            return handleLoadSiteCustomers(state, action);
        case custActions.CustomerActionTypes.SetSiteCustomers:
            return handleSetSiteCustomers(state, action);
        default:
            return state;
    }
}
exports.customerReducer = customerReducer;
function handleLoadUserCustomers(state, action) {
    return state;
}
function handleSetUserCustomers(state, action) {
    return Object.assign({}, state, { userCustomers: action.payload });
}
function handleSetCurrentCustomer(state, action) {
    return Object.assign({}, state, { currentCustomer: action.payload });
}
function handleLoadSiteCustomers(state, action) {
    return state;
}
function handleSetSiteCustomers(state, action) {
    return Object.assign({}, state, { siteCustomers: action.payload });
}
