"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./auth-customers.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@ngx-translate/core");
var i4 = require("./auth-customers.component");
var i5 = require("@angular/router");
var i6 = require("@ngrx/store");
var i7 = require("../../shared/components/td-load-panel/services/td-load-panel.service");
var i8 = require("../../core/utility/utility.service");
var styles_AuthCustomersComponent = [i0.styles];
var RenderType_AuthCustomersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthCustomersComponent, data: {} });
exports.RenderType_AuthCustomersComponent = RenderType_AuthCustomersComponent;
function View_AuthCustomersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCustomerSelected(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_AuthCustomersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "list-group d-inline-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AuthCustomersComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.customers$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AuthCustomersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "margin10 auth-customers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "pl-4 pr-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "paddingB20 page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthCustomersComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.loadPanelService.loadPanelParams.visible; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("appCommon.selectCustomer")); _ck(_v, 3, 0, currVal_0); }); }
exports.View_AuthCustomersComponent_0 = View_AuthCustomersComponent_0;
function View_AuthCustomersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-auth-customers", [], null, null, null, View_AuthCustomersComponent_0, RenderType_AuthCustomersComponent)), i1.ɵdid(1, 245760, null, 0, i4.AuthCustomersComponent, ["AUTH_SERVICE", "OPERATION_DATA_SERVICE", "ORDER_SERVICE", "ORDER_TEMPLATE_SERVICE", i5.Router, i5.ActivatedRoute, i6.Store, i7.TdLoadPanelService, i8.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AuthCustomersComponent_Host_0 = View_AuthCustomersComponent_Host_0;
var AuthCustomersComponentNgFactory = i1.ɵccf("td-auth-customers", i4.AuthCustomersComponent, View_AuthCustomersComponent_Host_0, {}, {}, []);
exports.AuthCustomersComponentNgFactory = AuthCustomersComponentNgFactory;
