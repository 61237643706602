import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DxButtonModule,
  DxPopupModule,
  DxPopoverModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
  DxTreeListModule,
  DxDataGridModule,
  DxFormModule,
  DxTabPanelModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxTextAreaModule,
  DxNumberBoxModule,
  DxCheckBoxModule,
  DxLoadPanelModule,
  DxListModule,
  DxDropDownBoxModule,
  DxRadioGroupModule,
  DxAutocompleteModule,
  DxLookupModule  
} from 'devextreme-angular';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DxButtonModule,
    DxPopupModule,
    DxPopoverModule,
    DxDropDownButtonModule,
    DxScrollViewModule,
    DxTreeListModule,
    DxDataGridModule,
    DxFormModule,
    DxTabPanelModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    DxLoadPanelModule,
    DxListModule,
    DxDropDownBoxModule,
    DxRadioGroupModule,
    DxAutocompleteModule,
    DxLookupModule
  ],
  exports: [
    CommonModule,
    DxButtonModule,
    DxPopupModule,
    DxPopoverModule,
    DxDropDownButtonModule,
    DxScrollViewModule,
    DxTreeListModule,
    DxDataGridModule,
    DxFormModule,
    DxTabPanelModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    DxLoadPanelModule,
    DxListModule,
    DxDropDownBoxModule,
    DxRadioGroupModule,
    DxAutocompleteModule,
    DxLookupModule
  ]
})
export class TdDevExtremeModule { }
