import { createSelector } from '@ngrx/store';

import { AppState } from '@core/data-layer/app.state';
import { CustomerState } from './customer.reducer';
import { CustomerModel } from '../models/customer.model';

export const selectCustomerState = (state: AppState) => state.customer;
export const selectUserCustomers = createSelector(  
  selectCustomerState,
  (state: CustomerState) => state.userCustomers
);
export const selectCurrentCustomer = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.currentCustomer  
);
export const selectCustomerById = createSelector(
  selectCustomerState,
  (state: CustomerState, props) => state.userCustomers ? state.userCustomers.filter((cust) => cust.keyId === props.id)[0] : new CustomerModel()
);
export const selectSiteCustomers = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.siteCustomers
);
