"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var store_1 = require("@ngrx/store");
var angular_user_idle_1 = require("angular-user-idle");
var session_helper_service_1 = require("./shared/services/session-helper.service");
var server_session_selectors_1 = require("@core/data-layer/server-session/store/server-session.selectors");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var production_overview_constants_1 = require("./dashboard/models/production-overview.constants");
var server_session_actions_1 = require("@core/data-layer/server-session/store/server-session.actions");
var td_popup_service_1 = require("./shared/components/td-popup/services/td-popup.service");
var preload_media_service_1 = require("@core/media/services/preload-media.service");
var AppComponent = /** @class */ (function () {
    function AppComponent(authService, licenseBindingService, sessionHelperService, router, store, userIdle, popupService, cdRef, preloadMediaService) {
        var _this = this;
        this.authService = authService;
        this.licenseBindingService = licenseBindingService;
        this.sessionHelperService = sessionHelperService;
        this.router = router;
        this.store = store;
        this.userIdle = userIdle;
        this.popupService = popupService;
        this.cdRef = cdRef;
        this.preloadMediaService = preloadMediaService;
        this.sessionEnded = false;
        this.authenticationPage = false;
        this.allowOrderCart = false;
        this.licenseBinded = false;
        this.displayPopup = false;
        this.sessionEndedSubscription = this.store.pipe(store_1.select(server_session_selectors_1.selectSessionEnded)).subscribe(function (ended) {
            _this.sessionEnded = ended;
        });
        router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationStart; })).subscribe(function (event) {
            _this.authenticationPage = event.url.includes('/signin-callback') || event.url.includes('/signout-callback');
            _this.setupLicenseBinding(event.url);
        });
        router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; })).subscribe(function (event) {
            _this.allowOrderCart = _this.sessionHelperService.urlAllowsOrderCart(event.url);
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.initUserManager(this.sessionHelperService.clientConfiguration.authPopup);
        this.preloadImages();
        this.popupService.displayPopup.subscribe(function (change) {
            _this.displayPopup = change;
            _this.cdRef.detectChanges();
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.sessionEndedSubscription) {
            this.sessionEndedSubscription.unsubscribe();
        }
        if (this.licenseSubscription) {
            this.licenseSubscription.unsubscribe();
        }
    };
    AppComponent.prototype.setupLicenseBinding = function (url) {
        // we don't setup license binding for:
        // - authentication page(s);
        // - production-overview page shown from TDAdmin and ScannerClient.
        if (!this.licenseBinded && !this.authenticationPage && !url.includes(production_overview_constants_1.POConst.TDOC_APP_TYPE + '=')) {
            this.licenseBinded = true;
            this.licenseBindingService.startConnectionAndLoadLicense();
            this.checkAvailabilityOfLicense();
            this.setupUserIdleConfiguration();
            this.setupUserIdleWatching();
        }
    };
    AppComponent.prototype.setupUserIdleConfiguration = function () {
        if (this.sessionHelperService.clientConfiguration.sessionTimeout > 0) {
            var idleConfig = new angular_user_idle_1.UserIdleConfig();
            // set session timeout (in seconds)
            idleConfig.idle = this.sessionHelperService.clientConfiguration.sessionTimeout * 60;
            // how often system should check end of session (in seconds)
            idleConfig.ping = idleConfig.idle / td_constants_1.TD_DEFAULT_USER_IDLE_CONFIG.frequencyOfPing;
            // how long should we wait after "idle-time" passed and only then redirect to SessionEnded page (in seconds)
            idleConfig.timeout = td_constants_1.TD_DEFAULT_USER_IDLE_CONFIG.timeout; // we should set value bigger then 0, otherwise it will take default not 0
            this.userIdle.setConfigValues(idleConfig);
        }
    };
    AppComponent.prototype.setupUserIdleWatching = function () {
        var _this = this;
        // start watching for user inactivity
        this.userIdle.startWatching();
        // start watching when user idle is starting
        this.userIdle.onTimerStart().subscribe(function (count) {
            // TODO: we should show popup with counter that inform user how many seconds left till user will be auto-logout
        });
        // start watch when time is up.
        this.userIdle.onTimeout().subscribe(function () {
            if (_this.authService.isAuthenticated()) {
                _this.authService.startSignout();
            }
            _this.navigateToSessionEndedPage();
        });
    };
    AppComponent.prototype.checkAvailabilityOfLicense = function () {
        var _this = this;
        // check availability of license
        this.licenseSubscription = this.store.pipe(store_1.select(server_session_selectors_1.selectLicense)).subscribe(function (license) {
            if (license) {
                // we are redirecting to home page only when it is NOT related to "missed module in license"
                if (_this.router.url.includes('/error') && !_this.router.url.includes(td_constants_1.TD_QUERY_PARAMS.missedModule + '=')) {
                    _this.router.navigate(['/']);
                }
            }
            else if (_this.sessionEnded) {
                _this.store.dispatch(new server_session_actions_1.SetSessionEnded(false));
                _this.navigateToSessionEndedPage();
            }
        });
    };
    AppComponent.prototype.navigateToSessionEndedPage = function () {
        this.licenseBindingService.stopConnection();
        this.router.navigate(['/error'], { skipLocationChange: true, queryParams: { type: td_constants_1.TD_ERROR_TYPES.sessionEnded } });
    };
    AppComponent.prototype.preloadImages = function () {
        this.preloadMediaService.preloadAllImages();
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
