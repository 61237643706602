import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { Logger } from '@core/logger/logger.service';
import { AuthenticationServiceInterface } from '@core/authentication/services/authentication.service.interface';
import { TdPopupService } from '@shared/components/td-popup/services/td-popup.service';
import { TdLoadPanelService } from '@shared/components/td-load-panel/services/td-load-panel.service';
import { SKIP_HEADER } from '@shared/models/app.constants';
import { ErrorReportManagerService } from '@core/error-report/services/error-report-manager.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    @Inject('AUTH_SERVICE') private authService: AuthenticationServiceInterface,
    private errorReportManagerService: ErrorReportManagerService,
    private router: Router,
    private popupService: TdPopupService,
    private loadPanelService: TdLoadPanelService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get(SKIP_HEADER) !== null) {
      const newHeaders = request.headers.delete(SKIP_HEADER);
      const newRequest = request.clone({ headers: newHeaders });
      return next.handle(newRequest);
    }
    else {
      return next.handle(request).pipe(catchError(error => this.errorHandler(error, request.method, request.body)));
    }

  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpErrorResponse, method: string, body: any): Observable<HttpEvent<any>> {
    this.loadPanelService.hideLoadPanel();
    if (environment.logHttpErrors) {
      this.errorReportManagerService.handleError(
        response.error,
        response.error ? ('Error code: ' + response.error.code + '; Error message: ' + response.error.message) : '',
        this.router.url,
        response.name,
        response.message,
        response.error ? response.error.traceId : '');
      log.error('Request error', response);
      if ((method === 'POST') && (body !== null)) {
        log.error('Error with params:', body);
      }
    }
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      this.authService.startSignout();
      location.reload(true);
    }
    else if (response.status === 400 && response.url.indexOf('mediaIdentifier=thumbnail') !== -1) {
      return throwError(response);
    }
    else if ((response.status >= 400) && (response.status <= 500)) {
      this.popupService.showError('appMessages.technicalError', true, response.error ? response.error.traceId : '');
    }
    else if (response.status === 0) {
      // show error popup if there is no connection to the backend
      this.popupService.showError('appMessages.communicationError', true, response.error ? response.error.traceId : '');
    }
    return throwError(response);
  }

}
