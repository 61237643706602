"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var td_constants_1 = require("@core/data-layer/shared/models/td.constants");
var app_constants_1 = require("@app/shared/models/app.constants");
var HttpMediaService = /** @class */ (function () {
    function HttpMediaService(http) {
        this.http = http;
    }
    HttpMediaService.prototype.mediaUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.mediaCtrl;
    };
    HttpMediaService.prototype.loadMediaInfoList = function (tableType, mediaSeries, refKeyId) {
        var stringType = td_enumerations_1.TableTypes[tableType].toLowerCase();
        var url = "" + this.mediaUrl() + api_hub_constants_1.API_HUB_PATHS.getMediaInfoList + "?keyId=" + refKeyId + "&linkType=" + stringType;
        url = mediaSeries ? url + ("&seriesType=" + mediaSeries) : url;
        return this.http.get(url);
    };
    // Get Media Content
    HttpMediaService.prototype.getMediaText = function (keyId) {
        var url = this.getMediaTextUrl(keyId);
        return this.http.get(url, { responseType: 'text' });
    };
    HttpMediaService.prototype.getMediaImage = function (keyId) {
        var url = this.getMediaImageUrl(keyId);
        return this.createImageObservable(url);
    };
    HttpMediaService.prototype.getMediaVideo = function (keyId) {
        return this.getMediaVideoUrl(keyId);
    };
    HttpMediaService.prototype.getMediaThumbnail = function (keyId) {
        var url = this.getMediaThumbnailUrl(keyId);
        return this.createImageObservable(url);
    };
    HttpMediaService.prototype.getMediaSeries = function (keyId, linkType) {
        var url = this.getMediaSeriesUrl(keyId, linkType);
        return this.http.get(url);
    };
    // Get Media URL's
    HttpMediaService.prototype.getMediaImageUrl = function (refKeyId) {
        return "" + this.mediaUrl() + api_hub_constants_1.API_HUB_PATHS.getImage + "?keyId=" + refKeyId;
    };
    HttpMediaService.prototype.getMediaThumbnailUrl = function (refKeyId) {
        return "" + this.mediaUrl() + api_hub_constants_1.API_HUB_PATHS.getImage + "?keyId=" + refKeyId + "&" + td_constants_1.TD_MEDIA_THUMBNAIL;
    };
    HttpMediaService.prototype.getMediaTextUrl = function (refKeyId) {
        return "" + this.mediaUrl() + api_hub_constants_1.API_HUB_PATHS.getText + "?keyId=" + refKeyId;
    };
    HttpMediaService.prototype.getMediaVideoUrl = function (refKeyId) {
        return "" + this.mediaUrl() + api_hub_constants_1.API_HUB_PATHS.getVideo + "?keyId=" + refKeyId;
    };
    HttpMediaService.prototype.getMediaSeriesUrl = function (refKeyId, linkType) {
        return "" + this.mediaUrl() + api_hub_constants_1.API_HUB_PATHS.getSeries + "?keyId=" + refKeyId + "&linktype=" + linkType;
    };
    HttpMediaService.prototype.createImageFromBlob = function (image) {
        var subject = new rxjs_1.Subject();
        var reader = new FileReader();
        reader.onload = function () {
            var content = reader.result;
            subject.next(content);
            subject.complete;
        };
        if (image) {
            reader.readAsDataURL(image);
            return subject.asObservable();
        }
    };
    HttpMediaService.prototype.createImageObservable = function (url) {
        var _this = this;
        var subject = new rxjs_1.Subject();
        this.http.get(url, { responseType: 'blob' }).subscribe(function (response) {
            if (response.size < 1) {
                subject.next();
                subject.complete();
            }
            else {
                _this.createImageFromBlob(response).subscribe(function (img) {
                    subject.next(img);
                    subject.complete;
                });
            }
        }, function (error) {
            subject.next(null);
            subject.complete;
        });
        return subject.asObservable();
    };
    HttpMediaService.prototype.getLinkType = function (tableType) {
        switch (tableType) {
            case td_enumerations_1.TableTypes.Product: {
                return app_constants_1.PRODUCT_AS_STRING;
            }
            case td_enumerations_1.TableTypes.Unit: {
                return app_constants_1.UNIT_AS_STRING;
            }
            case td_enumerations_1.TableTypes.Item: {
                return app_constants_1.ITEM_AS_STRING;
            }
        }
    };
    return HttpMediaService;
}());
exports.HttpMediaService = HttpMediaService;
