"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_hub_constants_1 = require("@core/data-layer/shared/models/api-hub.constants");
var HttpFactoryService = /** @class */ (function () {
    function HttpFactoryService(http) {
        this.http = http;
    }
    HttpFactoryService.prototype.factoriesUrl = function () {
        return api_hub_constants_1.API_HUB_PATHS.apiUrl + api_hub_constants_1.API_HUB_PATHS.factoriesCtrl;
    };
    HttpFactoryService.prototype.getFactory = function (factKeyId) {
        return this.http.get(this.factoriesUrl() + "(" + factKeyId + ")");
    };
    HttpFactoryService.prototype.getFactories = function () {
        return this.http.get(this.factoriesUrl());
    };
    HttpFactoryService.prototype.getFactoriesByCustomer = function (custKeyId) {
        return this.http.get("" + this.factoriesUrl() + api_hub_constants_1.API_HUB_PATHS.getByCustomer + "(" + custKeyId + ")");
    };
    HttpFactoryService.prototype.getUserFactories = function () {
        return this.http.get(this.factoriesUrl() + api_hub_constants_1.API_HUB_PATHS.getByLoggedInUser);
    };
    return HttpFactoryService;
}());
exports.HttpFactoryService = HttpFactoryService;
