import { Injectable } from '@angular/core';

import { nameOf } from '@core/utility/utility.service';
import { OrderOrgStatus, OrderSuppStatus, OrderType } from '@core/data-layer/shared/models/td.enumerations';
import { OrderTabType } from '@app/orders/models/order.enumerations';
import { OrderFilterDataInterface } from '@core/data-layer/order/models/order-filter-data.interface';
import { OrderModel } from '@core/data-layer/order/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class OrderFilterService {

  getOrderListFilter(tab: number, custKeyId: number, filterData: OrderFilterDataInterface): any[] {
    const nameOfOrder = nameOf<OrderModel>();
    let filter: any[] = [[nameOfOrder('type'), '=', OrderType.Order], 'and', [nameOfOrder('orgRefCustKeyId'), '=', custKeyId]];
    const fldTime = tab === OrderTabType.Delivered ? nameOfOrder('suppTime') : nameOfOrder('orgTime');
    if (filterData.dateInterval) {
      if (filterData.dateInterval.from) {
        filter = [...filter, 'and', [[fldTime, '<>', null], 'and', [fldTime, '>=', filterData.dateInterval.from.toISOString()]]];
      }
      if (filterData.dateInterval.to) {
        filter = [...filter, 'and', [[fldTime, '=', null], 'or', [fldTime, '<', filterData.dateInterval.to.toISOString()]]];
      }
    }
    if (filterData.orderNumber) {
      if (filterData.orderNumber.from) {
        filter = [...filter, 'and', [nameOfOrder('orgRefNo'), '>=', filterData.orderNumber.from]];
      }
      if (filterData.orderNumber.to) {
        filter = [...filter, 'and', [nameOfOrder('orgRefNo'), '<=', filterData.orderNumber.to]];
      }
    }
    switch (tab) {
      case OrderTabType.All: {
        filter = [...filter, 'and', [nameOfOrder('orgStatus'), '<>', OrderOrgStatus.Cancelled]];
        filter = [...filter, 'and', [nameOfOrder('suppStatus'), '<>', OrderSuppStatus.Cancelled]];
        break;
      }
      case OrderTabType.Open: {
        filter = [...filter, 'and', [nameOfOrder('orgStatus'), '=', OrderOrgStatus.Open]];
        break;
      }
      case OrderTabType.Delivered: {
        filter = [...filter, 'and', [nameOfOrder('suppStatus'), '=', OrderSuppStatus.Delivered]];
        break;
      }
      case OrderTabType.Sent: {
        filter = [...filter, 'and', [nameOfOrder('orgStatus'), '=', OrderOrgStatus.Sent]];
        filter = [...filter, 'and', [nameOfOrder('suppStatus'), '<>', OrderSuppStatus.Delivered]];
        filter = [...filter, 'and', [nameOfOrder('suppStatus'), '<>', OrderSuppStatus.Cancelled]];
      }
    }
    return filter;
  }

  getOperationOrderListFilter(keyId: number): any[] {
    const nameOfOrder = nameOf<OrderModel>();
    return [nameOfOrder('refOpDKeyId'), '=', keyId];
  }

}
