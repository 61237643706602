"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@ngx-translate/core");
var td_enumerations_1 = require("@core/data-layer/shared/models/td.enumerations");
var view_helper_service_1 = require("@core/data-layer/shared/services/view-helper.service");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("../../shared/services/view-helper.service");
var UnitHelperService = /** @class */ (function () {
    function UnitHelperService(translateService, viewHelperService) {
        this.translateService = translateService;
        this.viewHelperService = viewHelperService;
    }
    /**
     * @description converts [UnitModel][Object] to [UnitViewModel][Object]
     * @param unit: [UnitModel][Object]
     * @returns [UnitViewModel][Object]
     */
    UnitHelperService.prototype.convertUnitToViewModel = function (unit) {
        var isEndoscope = this.viewHelperService.getItemType(unit.prod.item) === td_enumerations_1.ItemInstrumentType.Endoscope;
        return tslib_1.__assign({}, unit, { productText: unit.prod.product + ' - ' + unit.prod.item.text, customerText: unit.cust ? unit.cust.customer + ' ' + unit.cust.name : '', errText: unit.errorText ? unit.errorText.text : this.translateService.instant('unitDetails.none'), statusText: isEndoscope ?
                this.translateService.instant("endoscopeUnitStatus." + td_enumerations_1.UnitStatus[unit.status].toLowerCase())
                : this.translateService.instant("unitStatus." + td_enumerations_1.UnitStatus[unit.status].toLowerCase()), expire: unit.expire ? new Date(unit.expire) : null, isEndoscope: isEndoscope });
    };
    UnitHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnitHelperService_Factory() { return new UnitHelperService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.ViewHelperService)); }, token: UnitHelperService, providedIn: "root" });
    return UnitHelperService;
}());
exports.UnitHelperService = UnitHelperService;
