"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var preload_image_paths_constants_1 = require("../models/preload-image-paths.constants");
var i0 = require("@angular/core");
var PreloadMediaService = /** @class */ (function () {
    function PreloadMediaService() {
    }
    PreloadMediaService.prototype.preloadAllImages = function () {
        preload_image_paths_constants_1.IMAGES_TO_PRELOAD.forEach(function (imagePath) {
            var img = new Image();
            img.src = imagePath;
        });
    };
    PreloadMediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PreloadMediaService_Factory() { return new PreloadMediaService(); }, token: PreloadMediaService, providedIn: "root" });
    return PreloadMediaService;
}());
exports.PreloadMediaService = PreloadMediaService;
