"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var td_load_panel_service_1 = require("./services/td-load-panel.service");
var TdLoadPanelComponent = /** @class */ (function () {
    function TdLoadPanelComponent(loadPanelService) {
        this.loadPanelService = loadPanelService;
    }
    return TdLoadPanelComponent;
}());
exports.TdLoadPanelComponent = TdLoadPanelComponent;
